// @flow
import isNil from 'lodash/isNil'

import type { Action } from 'types/actions'

export type State = {
  +[string]: boolean,
}

const initialState: State = {}

const reducer = (state?: State = initialState, action: Action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)

  if (isNil(matches)) {
    return state
  }

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  }
}

export default reducer
