// @flow
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, generatePath } from 'react-router-dom'
import useGoBack from 'hooks/useGoBack'
import { PaymentWithCalculator } from '@edison/webmail-ui/components/Stripe'
import BillCalculator from 'common/BillCalculator'
import * as premium from 'core/premium'
import * as customDomains from 'core/custom-domains'
import { usePlanDescription } from 'core/premium/hooks'
import { useOrderId } from 'core/auth/hooks'
import stripeInit from './stripe'
import { useToast, toastVariants } from 'common/toasts'
import { toastTypes, routePaths, NAVS } from 'utils/constants'

import type { Dispatch } from 'types/redux'

type Props = {
  planId: string,
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ planId, isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { showToast } = useToast(toastTypes.notification)
  const userId = useOrderId()
  const isPurchaseLoading = useSelector(premium.selectors.getPurchaseLoading)
  const isPurchaseDomainLoading = useSelector(
    customDomains.selectors.getPurchaseLoading
  )
  const isPreviewLoading = useSelector(premium.selectors.getPreviewLoading)
  const previewsById = useSelector(premium.selectors.getPremiumPreviewById)

  const preview = previewsById[planId]
  const domain: ?string = get(location, 'state.domain', null)
  const domainPrice: ?string = get(location, 'state.domainPrice', null)
  const domainCurrency: ?string = get(location, 'state.domainCurrency', null)
  const isLoading =
    isPreviewLoading || isPurchaseLoading || isPurchaseDomainLoading
  const description = usePlanDescription(
    preview && preview.plan,
    preview && preview.expiration
  )
  const goBack = useGoBack(
    generatePath(routePaths.settingsSection, {
      userId,
      section: NAVS.account.value,
    })
  )
  const onSubmit = ({ stripe, cardElement, name, postalCode } = {}) => {
    const onSuccess = async () => {
      // Purchase a domain if included inside route state
      if (!isNil(domain) && !isNil(domainPrice) && !isNil(domainCurrency)) {
        await dispatch(
          customDomains.actions.purchaseDomain(
            domain,
            domainPrice,
            domainCurrency
          )
        ).then(res => {
          if (!res) {
            showToast(t('customDomain.purchaseError'), toastVariants.error)
          }
          history.push(
            generatePath(routePaths.settingsSection, {
              userId,
              section: NAVS.domain.value,
            })
          )
        })
        return
      }

      // Go back to settings account section
      goBack()
    }

    dispatch(
      premium.actions.purchasePlan(
        planId,
        name,
        postalCode,
        stripe,
        cardElement
      )
    ).then(res => {
      if (res) {
        onSuccess().then(() => toggle())
      }
    })
  }

  useEffect(() => {
    dispatch(premium.actions.premiumPreview(planId))
  }, [planId])

  return (
    <PaymentWithCalculator
      stripe={stripeInit}
      interval={get(preview, 'plan.interval')}
      description={description}
      onSubmit={onSubmit}
      isOpen={isOpen}
      onCancel={() => !isLoading && isOpen && toggle()}
      toggle={toggle}
      isLoading={isLoading}
      calculator={<BillCalculator planId={planId} />}
    />
  )
}

View.displayName = 'PaymentPurchase'

export default View
