// @flow
import * as actions from 'core/search/actions'
import { getSearchCondition } from 'core/search/selectors'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountPill } from './AccountPill'
import { AttachmentPill } from './AttachmentPill'
import { ClearAllPill } from './ClearAllPill'
import { DatePill } from './DatePill'
import { FromEmailPill, ToEmailPill } from './EmailPill'
import { ExcludeUpdatePill } from './ExcludeUpdatePill'
import { LabelPill } from './LabelPill'
import { SplitInboxPill } from './SpiltInboxPill'
import { UnreadPill } from './UnreadPill'

export function PillList() {
  const dispatch = useDispatch()
  const [isClearAll, setIsClearAll] = useState(false)

  function onSelect(nextCondition, isNeedClearAll = false) {
    setIsClearAll(isNeedClearAll)
    dispatch(actions.setCondition({ condition: nextCondition }))
  }

  return <PillItemlist isClearAll={isClearAll} onSelect={onSelect} />
}

function PillItemlist(props) {
  const { isClearAll, onSelect } = props
  const condition = useSelector(getSearchCondition())
  return (
    <div className="flex items-center pl-13 mt-8 disable-scrollbars overflow-x-scroll">
      <ClearAllPill condition={condition} onSelect={onSelect} />
      <AccountPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <FromEmailPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <ToEmailPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <AttachmentPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <DatePill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <LabelPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <SplitInboxPill
        isClearAll={isClearAll}
        condition={condition}
        onSelect={onSelect}
      />
      <UnreadPill
        isClearAll={isClearAll}
        condition={condition}
        onClick={onSelect}
      />
      <ExcludeUpdatePill
        isClearAll={isClearAll}
        condition={condition}
        onClick={onSelect}
      />
    </div>
  )
}
