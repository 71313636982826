// @flow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useShowContactDetail } from 'hooks/useHandleDetailModal'
import * as selectors from 'core/contacts/selectors'
import { getFormattedContactById } from 'core/contacts/helpers'

import Contacts, { ApprovedContact } from '@edison/webmail-ui/screens/Contacts'
import EmptyList from '@edison/webmail-ui/screens/Settings/components/EmptyList'

type Props = {
  ids: $ReadOnlyArray<string>,
}
export default ({ ids }: Props) => {
  const { t } = useTranslation()
  const contactsById = useSelector(selectors.getContactsById)
  const showContactDetail = useShowContactDetail()
  const renderItem = useCallback(
    ({ index, style, key }) => {
      const { id, email, name, avatar } = getFormattedContactById(
        contactsById,
        ids[index]
      )
      return (
        <ApprovedContact
          key={id}
          name={name}
          style={style}
          email={email}
          avatar={avatar}
          onClick={() => showContactDetail(id)}
        />
      )
    },
    [contactsById, ids, showContactDetail]
  )

  const count = ids.length

  if (count === 0) {
    return (
      <EmptyList
        image={t('empty-settings-contacts.png')}
        title={t('contacts.approved.empty.title')}
        subtitle={t('contacts.approved.empty.subtitle')}
      />
    )
  }

  return <Contacts count={count} rowRenderer={renderItem} />
}
