// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { nudgeTypes, nudgeStatus } from '@edison/webmail-core/utils/constants'
import { getEmailNudgesById } from 'core/email-nudges/selectors'
import { updateMessageNudge } from 'core/email-nudges/actions'

import { NudgePrompt } from '@edison/webmail-ui/components/EmailNudge'

import type { Dispatch } from 'types/redux'

export default ({
  threadId,
  onDismiss,
  className,
}: {
  threadId: string,
  className?: string,
  onDismiss?: () => void,
}) => {
  const dispatch: Dispatch = useDispatch()
  const nudgesById = useSelector(getEmailNudgesById)
  const nudge = nudgesById[threadId]

  if (!nudge || nudge.suggestionType === nudgeTypes.NONE) return null

  return (
    <NudgePrompt
      className={className}
      type={nudge.suggestionType}
      emailTime={nudge.date}
      onClick={() => {
        dispatch(
          updateMessageNudge(nudgeStatus.DISMISSED, {
            threadId,
          })
        ).then(() => onDismiss && onDismiss())
      }}
    />
  )
}
