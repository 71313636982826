// @flow
import moment from 'moment'
import omit from 'lodash/omit'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import toPairs from 'lodash/toPairs'
import mapValues from 'lodash/mapValues'

import { combineReducers } from 'redux'
import { createReducer } from 'utils/redux'
import { labelNames } from 'utils/constants'
import * as helpers from './helpers'
import * as actions from './actions'
import * as threadActions from '../threads/actions'
import * as composeActions from '../compose/actions'
import * as messageActions from '../messages/actions'
import * as labelActions from '../labels/actions'
import * as retrofitActions from '../retrofit/actions'

import type {
  MessagesState,
  ThreadMessagesState,
  ExtraInfoState,
  MessageMetadataSuccess,
  MetadataAction,
  MessageLabelsBatchUpdate,
  MetadataHistory,
  ThreadBatchDelete,
  ThreadBatchUpdateSuccess,
  MessageBatchUpdateSuccess,
  CleanupLabelChanged,
  LabelStatisticsSuccess,
} from './types'
import type {
  ThreadListRequest,
  ThreadListSuccess,
  ThreadSetActiveLabel,
  ThreadBatchGetSuccess,
} from '../threads/types'
import type { LabelDeleteSuccess } from '../labels/types'
import type {
  BeforeSendDraft,
  BeforeDeleteDraft,
  SaveDraftSuccess,
  SendDraftSuccess,
  DeleteDraftSuccess,
  BatchDeleteDraftRequest,
  BatchDeleteDraftSuccess,
  UndoSendDraftSuccess,
} from '../compose/types'
import type { SetRetrofitAccount } from '../retrofit/types'
import type { MessageListSuccess } from '../messages/types'

const initialState = {}

const messagesReducer = createReducer<MessagesState, MetadataAction>(
  initialState,
  {
    [actions.batchUpdateMessageLabels.toString()]: batchUpdateMessageLabels,
    [actions.batchDeleteThreadsAction.toString()]: deleteMessage,
    [actions.metadataHistory.toString()]: messagesHistory,

    // Draft
    [composeActions.saveDraftActions.success.toString()]: saveDraft,
    [composeActions.deleteDraftActions.success.toString()]: deleteDraft,
    [composeActions.sendDraftActions.success.toString()]: sendDraft,

    // Batch get
    [threadActions.fetchThreadsActions.success.toString()]: updateMessagesFromThreads,
    [threadActions.batchGetThreadsActions.success.toString()]: updateMessagesFromThreads,
    [messageActions.fetchThreadActions.success.toString()]: fetchThread,
  }
)

function batchUpdateMessageLabels(
  state: MessagesState,
  action: MessageLabelsBatchUpdate
) {
  const { threads, add, remove } = action.payload
  const messages = threads.flatMap(({ messageIds }) => messageIds)

  let next = { ...state }
  for (let id of messages) {
    if (id in next) {
      const message = next[id]

      next[id] = {
        ...message,
        labelIds: [...message.labelIds, ...add].filter(
          labelId => !remove.includes(labelId)
        ),
      }
    }
  }
  return next
}

function deleteMessage(state: MessagesState, action: ThreadBatchDelete) {
  const { messageIds } = action.payload

  return omit(state, [...messageIds])
}

function messagesHistory(state: MessagesState, action: MetadataHistory) {
  const { messages, deletedMessages } = action.payload

  return omit(
    {
      ...state,
      ...messages,
    },
    [...deletedMessages]
  )
}

function saveDraft(state: MessagesState, action: SaveDraftSuccess) {
  const {
    draft: { messageId, threadId },
    date,
    oldMessageId,
  } = action.payload
  const { [oldMessageId]: _, ...next } = state
  return {
    ...next,
    [messageId]: {
      threadId,
      id: messageId,
      date,
      labelIds: [labelNames.drafts],
    },
  }
}

function sendDraft(state: MessagesState, action: SendDraftSuccess) {
  const { messageId, threadId } = action.payload
  return {
    ...state,
    [messageId]: {
      id: messageId,
      threadId,
      labelIds: [labelNames.sent],
      date: Math.round(Date.now() / 1000),
    },
  }
}

function deleteDraft(state: MessagesState, action: DeleteDraftSuccess) {
  const { messageId } = action.payload
  const { [messageId]: _, ...next } = state
  return next
}

function fetchThread(state: MessagesState, action: MessageListSuccess) {
  const {
    thread: { id: threadId },
    messages,
  } = action.payload
  return {
    ...state,

    ...messages.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: {
          id: curr.id,
          threadId: threadId,
          labelIds: curr.labelIds || [],
          date: curr.date,
        },
      }),
      {}
    ),
  }
}

function updateMessagesFromThreads(
  state: MessagesState,
  action: ThreadBatchGetSuccess | ThreadListSuccess
) {
  const { threads } = action.payload
  const messages = threads
    .flatMap(({ id, messages }) =>
      messages.map(message => ({ ...message, threadId: id }))
    )
    .map(({ id, threadId, labelIds, date }) => ({
      id,
      threadId,
      date,
      labelIds: labelIds || [],
    }))

  let next = {}

  for (let message of messages) {
    next[message.id] = message
  }

  return {
    ...state,
    ...next,
  }
}

const threadMessagesReducer = createReducer<
  ThreadMessagesState,
  MetadataAction
>(initialState, {
  [actions.metadataHistory.toString()]: threadMessagesHistory,
  [actions.batchDeleteThreadsAction.toString()]: deleteThreadMessages,
  // Draft
  [composeActions.saveDraftActions.success.toString()]: saveDraftInThreadMessages,
  [composeActions.deleteDraftActions.success.toString()]: deleteDraftInThreadMessages,
  [composeActions.sendDraftActions.success.toString()]: sendDraftInThreadMessages,
  [composeActions.batchDeleteDraftActions.success.toString()]: batchDeleteDraftsInThreadMessages,
  // Messages
  [messageActions.fetchThreadActions.success.toString()]: fetchThreadInThreadMessages,
  [threadActions.fetchThreadsActions.success.toString()]: updateThreadMessagesFromThreads,
  [threadActions.batchGetThreadsActions.success.toString()]: updateThreadMessagesFromThreads,
})

function threadMessagesHistory(
  state: ThreadMessagesState,
  action: MetadataHistory
) {
  const { threadMessages } = action.payload
  const deletedThreads: string[] = values(threadMessages)
    .filter(item => item.messageIds.length === 0)
    .map(item => item.id)

  return omit(
    {
      ...state,
      ...threadMessages,
    },
    deletedThreads
  )
}

function deleteThreadMessages(
  state: ThreadMessagesState,
  action: ThreadBatchDelete
) {
  const { messageIds, threads } = action.payload
  const deletedMessages = new Set(messageIds)

  let next = state

  for (let { threadId } of threads) {
    const thread = next[threadId]
    if (!thread) {
      continue
    }

    const nextMessageIds = thread.messageIds.filter(
      id => !deletedMessages.has(id)
    )

    if (nextMessageIds.length > 0) {
      next[threadId] = {
        ...thread,
        messageIds: nextMessageIds,
      }
    } else {
      next = omit(next, [threadId])
    }
  }

  return next
}

function saveDraftInThreadMessages(
  state: ThreadMessagesState,
  action: SaveDraftSuccess
) {
  const {
    draft: { messageId, threadId },
    oldMessageId,
  } = action.payload

  if (threadId in state) {
    const { messageIds, ...theRest } = state[threadId]
    return {
      ...state,
      [threadId]: {
        ...theRest,
        messageIds: [
          ...messageIds.filter(id => id !== oldMessageId),
          messageId,
        ],
      },
    }
  } else
    return {
      ...state,
      [threadId]: {
        id: threadId,
        messageIds: [messageId],
      },
    }
}

function deleteDraftInThreadMessages(
  state: ThreadMessagesState,
  action: DeleteDraftSuccess
) {
  const { messageId, threadId } = action.payload

  const { [threadId]: target, ...theRest } = state
  if (!isNil(target)) {
    const { messageIds, ...theRestOfThread } = target
    const nextMessageIds = messageIds.filter(id => id !== messageId)
    if (nextMessageIds.length > 0)
      return {
        ...theRest,
        [threadId]: {
          ...theRestOfThread,
          messageIds: nextMessageIds,
        },
      }
  }
  return state
}

function sendDraftInThreadMessages(
  state: ThreadMessagesState,
  action: SendDraftSuccess
) {
  const {
    messageId,
    threadId,
    draft: { messageId: oldMessageId },
  } = action.payload
  let nextState = state
  //need delete draft if reply or forward
  nextState = helpers.deleteThreadMessage(nextState, [
    {
      id: oldMessageId,
      threadId,
    },
  ])

  return helpers.threadMessagesIndex(nextState, [
    {
      id: messageId,
      threadId,
    },
  ])
}

function fetchThreadInThreadMessages(
  state: ThreadMessagesState,
  action: MessageListSuccess
) {
  const {
    thread: { id: threadId, messages },
  } = action.payload
  return {
    ...state,
    [threadId]: {
      id: threadId,
      messageIds: messages.map(item => item.id),
    },
  }
}

function batchDeleteDraftsInThreadMessages(
  state: ThreadMessagesState,
  action: BatchDeleteDraftSuccess
) {
  const { threadMessageIds } = action.payload
  return {
    ...state,
    ...mapValues(threadMessageIds, (messageIds, id) => ({ id, messageIds })),
  }
}

function updateThreadMessagesFromThreads(
  state: ThreadMessagesState,
  action: ThreadBatchGetSuccess | ThreadListSuccess
) {
  const { threads } = action.payload
  const next = { ...state }

  for (let { id, messages } of threads) {
    next[id] = {
      id,
      messageIds: messages.map(({ id }) => id),
    }
  }

  return next
}

export type ViewThreadsState = {
  [labelId: string]: string[],
}

const viewThreadsReducer = createReducer<
  ViewThreadsState,
  MessageMetadataSuccess
>(initialState, {
  [actions.batchUpdateMessageLabels.toString()]: updateViewThreads,
  [actions.batchDeleteThreadsAction.toString()]: threadBatchDeleteInViewThreads,
  [actions.metadataHistory.toString()]: updateViewThreads,
  // Draft
  [composeActions.saveDraftActions.success.toString()]: saveDraftInViewThreads,
  [composeActions.deleteDraftActions.success.toString()]: saveDraftInViewThreads,
  [composeActions.batchDeleteDraftActions.success.toString()]: batchUpdateDraftsInViewThreads,
  // Label
  [labelActions.deleteLabelActions.success.toString()]: deleteLabelInViewThreads,

  [retrofitActions.setFilterAccountAction.toString()]: setFilterAccount,

  [threadActions.fetchThreadsActions.success.toString()]: fetchThreadsSuccess,
  [threadActions.batchGetThreadsActions.success.toString()]: batchGetThreadsSuccess,
})

function batchGetThreadsSuccess(
  state: ViewThreadsState,
  action: ThreadBatchGetSuccess
) {
  const { viewThreads } = action.payload

  return helpers.updateViewThreads(state, viewThreads)
}

function fetchThreadsSuccess(
  state: ViewThreadsState,
  action: ThreadListSuccess
) {
  const { ids, labelId, overwrite } = action.payload

  return {
    ...state,
    [labelId]: overwrite ? ids : [...(state[labelId] || []), ...ids],
  }
}

function setFilterAccount(state: ViewThreadsState, action: SetRetrofitAccount) {
  const { activeLabel } = action.payload

  return {
    // Keep the current thread list
    // Will update it when refresh the list with the new account label
    [activeLabel]: state[activeLabel],
  }
}

function updateViewThreads(
  state: ViewThreadsState,
  action: MessageLabelsBatchUpdate | MetadataHistory
) {
  const { labelId, threads } = action.payload

  let next = helpers.updateViewThreads(state, threads)

  const removed = new Set(
    [...threads]
      .filter(({ visible }) => !visible)
      .map(({ threadId }) => threadId)
  )

  return {
    ...next,
    [labelId]: (next[labelId] || []).filter(id => !removed.has(id)),
  }
}

function threadBatchDeleteInViewThreads(
  state: ViewThreadsState,
  action: ThreadBatchDelete
) {
  const { threads } = action.payload

  return helpers.updateViewThreads(state, threads)
}

function saveDraftInViewThreads(
  state: ViewThreadsState,
  action: SaveDraftSuccess
) {
  const { changedThread } = action.payload

  return helpers.updateViewThreads(state, [changedThread])
}

function batchUpdateDraftsInViewThreads(
  state: ViewThreadsState,
  action: BatchDeleteDraftRequest
) {
  const { changedThreads } = action.payload

  return helpers.updateViewThreads(state, changedThreads)
}

function deleteLabelInViewThreads(
  state: ViewThreadsState,
  action: LabelDeleteSuccess
) {
  const { id } = action.payload
  const { [id]: _, ...theRest } = state
  return theRest
}

const initialExtraInfo = {
  historyId: null,
  newMessageHistoryId: null,
  changedMessages: {},
  changed: [],
  unread: {},
  total: {},
}
const extraInfoReducer = createReducer<ExtraInfoState, MetadataAction>(
  initialExtraInfo,
  {
    [actions.metadataHistory.toString()]: metadataHistoryInExtraInfo,
    [actions.batchUpdateMessageLabels.toString()]: updateMetadatdChanged,
    [actions.batchUpdateMessagesActions.success.toString()]: updateMessagesChanged,
    [actions.batchUpdateThreadsActions.success.toString()]: updateThreadsChanged,
    [actions.cleanupLabelChanged.toString()]: cleanupMetadataChanged,
    [actions.batchDeleteThreadsAction.toString()]: batchUpdateInExtraInfo,
    [actions.labelStatisticsActions.success.toString()]: labelStatisticsSuccess,
    [threadActions.fetchThreadsActions.request.toString()]: resetHistoryId,
    [threadActions.fetchThreadsActions.success.toString()]: fetchThreadsInExtraInfo,
    [retrofitActions.setFilterAccountAction.toString()]: resetExtraInfo,
    [threadActions.setActiveThreadLabel.toString()]: resetChangedMessages,
    [threadActions.batchGetThreadsActions.success.toString()]: batchGetThreadsSuccessInExtraInfo,

    // Compose
    [composeActions.saveDraftActions.success.toString()]: saveDraftInExtraInfo,
    [composeActions.deleteDraftActions.success.toString()]: saveDraftInExtraInfo,
    [composeActions.batchDeleteDraftActions.success.toString()]: batchUpdateDraftsInExtraInfo,
  }
)

function batchGetThreadsSuccessInExtraInfo(
  state: ExtraInfoState,
  action: ThreadBatchGetSuccess
) {
  const { viewThreads } = action.payload

  const { unread, total } = helpers.updateLabelStatistics(state, viewThreads)

  return {
    ...state,
    unread,
    total,
  }
}

function resetChangedMessages(
  state: ExtraInfoState,
  action: ThreadSetActiveLabel
) {
  return {
    ...state,
    changedMessages: initialExtraInfo.changedMessages,
  }
}

function saveDraftInExtraInfo(
  state: ExtraInfoState,
  action:
    | SaveDraftSuccess
    | BeforeSendDraft
    | BeforeDeleteDraft
    | UndoSendDraftSuccess
) {
  const { changedThread } = action.payload

  const { unread, total } = helpers.updateLabelStatistics(state, [
    changedThread,
  ])

  return {
    ...state,
    unread,
    total,
  }
}

function batchUpdateDraftsInExtraInfo(
  state: ExtraInfoState,
  action: BatchDeleteDraftSuccess
) {
  const { changedThreads } = action.payload

  const { unread, total } = helpers.updateLabelStatistics(state, changedThreads)

  return {
    ...state,
    unread,
    total,
  }
}

function resetExtraInfo(state: ExtraInfoState, action: SetRetrofitAccount) {
  const { activeLabel } = action.payload

  return {
    ...initialExtraInfo,
    unread: {
      [activeLabel]: state.unread[activeLabel] || 0,
    },
    total: {
      [activeLabel]: state.total[activeLabel] || 0,
    },
  }
}

function resetHistoryId(state: ExtraInfoState, action: ThreadListRequest) {
  const { overwrite } = action.payload

  let nextHistoryId = {}

  if (overwrite) {
    nextHistoryId = {
      historyId: null,
      newMessageHistoryId: null,
    }
  }

  return {
    ...state,
    ...nextHistoryId,
  }
}

function fetchThreadsInExtraInfo(
  state: ExtraInfoState,
  action: ThreadListSuccess
) {
  const {
    labelId,
    historyId,
    unreadCount,
    pagination,
    overwrite,
  } = action.payload

  let nextHistoryId = {}

  if (overwrite) {
    nextHistoryId = {
      historyId,
      newMessageHistoryId: historyId,
    }
  }

  return {
    ...state,
    ...nextHistoryId,
    unread: {
      ...state.unread,
      [labelId]: (~unreadCount ? unreadCount : state[labelId]) || 0,
    },
    total: {
      ...state.total,
      [labelId]: pagination.total,
    },
  }
}

function metadataHistoryInExtraInfo(
  state: ExtraInfoState,
  action: MetadataHistory
) {
  const { lastHistoryId, lastMessageHistoryId, markedMessages } = action.payload
  const changedMessages = { ...state.changedMessages }

  for (let [messageId, changedTimes] of toPairs(markedMessages)) {
    const next = Math.max(0, (changedMessages[messageId] || 0) - changedTimes)
    if (next === 0) {
      delete changedMessages[messageId]
    } else {
      changedMessages[messageId] = next
    }
  }

  return {
    ...batchUpdateInExtraInfo(state, action),
    changedMessages,
    historyId: lastHistoryId,
    newMessageHistoryId: lastMessageHistoryId,
  }
}

function batchUpdateInExtraInfo(
  state: ExtraInfoState,
  action: MessageLabelsBatchUpdate | ThreadBatchDelete | MetadataHistory
) {
  const { threads } = action.payload

  const { total, unread } = helpers.updateLabelStatistics(state, threads)

  return {
    ...state,
    total,
    unread,
  }
}

function updateMetadatdChanged(
  state: ExtraInfoState,
  action: MessageLabelsBatchUpdate
) {
  const { threads, add, remove, updateAsync = false } = action.payload
  const { action: actionName, type, undoable } = action.meta

  const threadIds = threads.map(({ threadId }) => threadId)
  const messageIds = threads.flatMap(({ messageIds }) => messageIds)
  const ids = type === 'thread' ? threadIds : messageIds

  return {
    ...batchUpdateInExtraInfo(state, action),
    changed: [
      {
        ids,
        type,
        add,
        remove,
        undoable,
        updateAsync,
        action: actionName,
        timestamp: moment().unix(),
      },
      ...state.changed,
    ],
  }
}

function updateMessagesChanged(
  state: ExtraInfoState,
  action: MessageBatchUpdateSuccess
) {
  const { messageIds, timestamp } = action.payload

  const changedMessages = { ...state.changedMessages }

  for (let messageId of messageIds) {
    changedMessages[messageId] = (changedMessages[messageId] || 0) + 1
  }

  return {
    ...state,
    changedMessages,
    changed: state.changed.filter(
      each => each.type === 'thread' || each.timestamp > timestamp
    ),
  }
}

function updateThreadsChanged(
  state: ExtraInfoState,
  action: ThreadBatchUpdateSuccess
) {
  const { timestamp } = action.payload

  return {
    ...state,
    changed: state.changed.filter(
      each => each.type === 'message' || each.timestamp > timestamp
    ),
  }
}

function cleanupMetadataChanged(
  state: ExtraInfoState,
  action: CleanupLabelChanged
) {
  const { startTimestamp, endTimestamp, type } = action.payload

  return {
    ...state,
    changed: state.changed.filter(
      each =>
        (!!type ? each.type === type : true) &&
        (each.timestamp < startTimestamp || each.timestamp > endTimestamp)
    ),
  }
}

function labelStatisticsSuccess(
  state: ExtraInfoState,
  action: LabelStatisticsSuccess
) {
  const statistics = action.payload
  const [nextUnread, nextTotal] = [{}, {}]

  for (let { id, unread, total } of values(statistics)) {
    nextUnread[id] = unread
    nextTotal[id] = total
  }

  return {
    ...state,
    unread: nextUnread,
    total: nextTotal,
  }
}

export type State = {
  messages: MessagesState,
  threadMessages: ThreadMessagesState,
  viewThreads: ViewThreadsState,
  extraInfo: ExtraInfoState,
}
export default combineReducers<_, MetadataAction>({
  extraInfo: extraInfoReducer,
  messages: messagesReducer,
  threadMessages: threadMessagesReducer,
  viewThreads: viewThreadsReducer,
})
