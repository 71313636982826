// @flow

const AUTH_PREFIX = '/u/:userId'

function makeRoute(prefix: string) {
  return (path: string) => prefix + path
}
const authRoute = makeRoute(AUTH_PREFIX)

const loginRoute = makeRoute('/login')
const oauthRoute = makeRoute('/oauth')
const signupRoute = makeRoute('/signup')
const mainRoute = makeRoute(authRoute('/:label'))
const printRoute = makeRoute(authRoute('/print'))
const settingsRoute = makeRoute(authRoute('/settings'))
const retrofitRoute = makeRoute(authRoute('/retrofit'))
const retrofitAddRoute = makeRoute(retrofitRoute('/add'))
const hostedDomainRoute = makeRoute(authRoute('/add-domain'))
const addUserDomainRoute = makeRoute(hostedDomainRoute('/user'))
const calendarRoute = makeRoute(authRoute('/calendar'))
const attachmentPreviewRoute = makeRoute(authRoute('/preview'))

const protectedRoutes = {
  // Retrofit
  retrofitProgress: retrofitRoute('/progress'),
  retrofitError: retrofitRoute('/error'),
  retrofitSuccess: retrofitRoute('/importing'),
  retrofitAdd: retrofitAddRoute(''),
  retrofitIMAP: retrofitAddRoute('/imap'),
  retrofitIMAPProvider: retrofitAddRoute('/imap/:provider'),
  retrofitIMAPInstructions: retrofitAddRoute('/imap/instructions'),

  // Hosted domain
  domainStatus: hostedDomainRoute('/status'),
  addHostedDomain: hostedDomainRoute('/hosted'),
  addUserDomain: addUserDomainRoute(''),
  addUserDomainSetup: addUserDomainRoute('/setup'),
  addUserDomainSelect: addUserDomainRoute('/domain'),

  // Print email
  threadPrintPreview: printRoute('/thread/:thread'),
  messagePrintPreview: printRoute('/message/:message'),

  // // Calendar route
  calendar: calendarRoute(''),
  calendarView: calendarRoute('/:resolution/:year/:month/:date'),

  main: mainRoute(''),
  splitInbox: mainRoute('/:splitInboxId'),
  search: authRoute('/search'),
  pricing: authRoute('/pricing'),
  exportData: authRoute('/export'),
  contacts: authRoute('/contacts'),
  contactSection: authRoute('/contacts/:activeTab'),
  attachments: authRoute('/attachments'),

  settings: settingsRoute(''),
  settingsSection: settingsRoute('/:section'),
  settingsSubsection: settingsRoute('/:section/:subsection'),
  settingsDomain: settingsRoute('/domain/:subsection'),

  attachmentPreview: attachmentPreviewRoute('/attachment/:message/:attachment'),
  largeAttachmentPreview: attachmentPreviewRoute(
    '/large-attachment/:message/:attachment'
  ),
}

const publicRoutes = {
  // Login
  login: loginRoute(''),

  // Signup
  subaccountSignUp: '/invite',
  signup: signupRoute(''),
  inviteSignUp: signupRoute('/invite'),
  domainSignUp: signupRoute('/custom-domain'),

  // Unsubscription
  welcomeEmailsUnsubscribe: '/unsubscribe/welcome-emails/:auth',
}

const defaultRoutes = {
  // OAuth
  oauthInit: oauthRoute('/init'),
  oauth: oauthRoute(''),

  // Login
  addAccountLogin: loginRoute('/add-account'),
  domainLogin: '/domain/login',

  // Logout
  logoutAll: '/logout',
  logout: authRoute('/logout') /* No auth required */,

  forgotPassword: '/forgot',
  resetPassword: '/reset',
  verify: '/verify',
  forwardEmailVerify: '/forward_verify',
  download: '/download/:key',
  exportAuth: '/export',
  goodbye: '/goodbye',
}

export default {
  AUTH_PREFIX,
  ...protectedRoutes,
  ...publicRoutes,
  ...defaultRoutes,
}
