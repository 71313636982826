// @flow
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { readFile } from '@edison/webmail-core/utils/file'

import { getAuth } from 'core/auth/selectors'
import { useExternalImage } from 'core/external/hooks'
import { getExternalAssetsLoading } from 'core/external/selectors'
import { getInboxZeroBackgroundSettings } from 'core/settings/selectors'

import InboxZero from '@edison/webmail-ui/components/InboxZero'

const authSelector = getAuth()

type Props = {
  // The dependency to trigger the refrehs of the background gradient
  refresh: any,
}

const InboxZeroBackground = ({ refresh }: Props) => {
  const [customBackground, setCustomBackground] = useState('')
  // Status for the custom inbox zero background
  const [valid, setValid] = useState(false)

  const auth = useSelector(authSelector)
  const { enable, image } = useSelector(getInboxZeroBackgroundSettings)

  const { viewports, src } = useExternalImage()

  const isExternalAssetsLoading = useSelector(getExternalAssetsLoading)

  useEffect(() => {
    if (!enable || !image.full || !auth) {
      return
    }

    setValid(true)

    readFile(image.full, { auth }, { cache: 'force-cache' })
      .then(blob => {
        setCustomBackground(URL.createObjectURL(blob))
      })
      .catch(() => {
        setValid(false)
      })

    return () => {
      if (customBackground) {
        URL.revokeObjectURL(customBackground)
      }
    }
  }, [enable, image.full])

  if (!enable || isExternalAssetsLoading) return null
  if (valid && !customBackground) return null

  return (
    <InboxZero
      src={valid ? customBackground : src}
      srcs={valid ? [] : viewports}
      refresh={refresh}
    />
  )
}

export default InboxZeroBackground
