// @flow
import { compose, createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import thunkMiddleware from 'redux-thunk'
import debounceMiddleware from 'middlewares/debounce'
import refreshMiddleware from 'middlewares/refresh'

import { createRootReducer } from './core'

import type { Store } from 'types/redux'

let composeEnhancers = compose
let middlewares = [debounceMiddleware, refreshMiddleware, thunkMiddleware]
if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger')
  middlewares.push(logger)
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

export const history = createBrowserHistory()

const store: Store = createStore(
  createRootReducer(history),
  // $FlowFixMe
  composeEnhancers(applyMiddleware(...middlewares))
)

export default store

if (process.env.NODE_ENV !== 'production' && module.hot) {
  // $FlowFixMe
  module.hot.accept('./core', () => {
    const { reducer } = require('./core')

    store.replaceReducer(reducer)
  })
}
