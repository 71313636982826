// @flow
import { createAction } from 'utils/redux'
import { modalTypes } from 'utils/constants'

import * as analytics from 'core/analytics'

import type { ShowModal, HideModal, RemoveModal, HideAllModal } from './types'
import type { PaywallType } from 'utils/constants'
import type { ThunkAction, ActionCreator } from 'types/redux'

const timeoutMap = new Map()
// Reference:
// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/styles/createTransitions.js#L28
const MODAL_TRANSITION_END = 200

export const showAction: ActionCreator<ShowModal> = createAction('MODAL_SHOW')
export const hideAction: ActionCreator<HideModal> = createAction('MODAL_HIDE')
export const hideAllModalsAction: ActionCreator<HideAllModal> = createAction(
  'MODAL_HIDE_ALL'
)
export const removeAction: ActionCreator<RemoveModal> = createAction(
  'MODAL_REMOVE'
)
export const removeAllModalsAction: ActionCreator<RemoveModal> = createAction(
  'MODAL_REMOVE_ALL'
)

export function show({ key, ...rest }: { key: string }): ThunkAction {
  return dispatch => {
    if (timeoutMap.has(key)) {
      clearTimeout(timeoutMap.get(key))
      timeoutMap.delete(key)
    }

    dispatch(showAction({ key, ...rest }))
  }
}

export function hideAll(exceptionKeys: string[] = []): ThunkAction {
  return dispatch => {
    dispatch(hideAllModalsAction({ exceptionKeys }))
    const key = 'allModals'
    if (timeoutMap.has(key)) {
      clearTimeout(timeoutMap.get(key))
    }
    timeoutMap.set(
      key,
      setTimeout(
        () => dispatch(removeAllModalsAction({ exceptionKeys })),
        MODAL_TRANSITION_END
      )
    )
  }
}

export function hide({ key, ...rest }: { key: string }): ThunkAction {
  return dispatch => {
    dispatch(hideAction({ key, ...rest }))

    if (timeoutMap.has(key)) {
      clearTimeout(timeoutMap.get(key))
    }

    timeoutMap.set(
      key,
      setTimeout(() => dispatch(removeAction({ key })), MODAL_TRANSITION_END)
    )
  }
}

export const showPaywallModal = (
  type: PaywallType,
  props: Object = {}
): ThunkAction => {
  return (dispatch, getState) => {
    dispatch(analytics.actions.premium.userPaywallTrigger(type))
    dispatch(
      show({
        key: modalTypes.paywall,
        props: {
          type,
          ...props,
        },
      })
    )
  }
}
