import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

export const useShowCompose = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(
    (threadId, mailto) => {
      const query = qs.parse(search.slice(1))
      if (query.compose === (threadId || 'new')) {
        return
      }
      query.compose = threadId || 'new'
      mailto && (query.to = `mailto:${mailto}`)
      history[action]({ search: `?${qs.stringify(query)}` })
    },
    [search]
  )
}

export const useHiddenCompose = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(() => {
    const query = qs.parse(search.slice(1))
    delete query.compose
    history[action]({ search: `?${qs.stringify(query)}` })
  }, [search])
}
export const useShowThreadDetail = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(
    threadId => {
      const query = qs.parse(search.slice(1))
      if (query.thread === threadId) return
      query.thread = threadId
      history[action]({ search: `?${qs.stringify(query)}` })
    },
    [search]
  )
}
export const useHiddenThreadDetail = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(() => {
    const query = qs.parse(search.slice(1))
    delete query.thread
    history[action]({ search: `?${qs.stringify(query)}` })
  }, [search])
}

export const useShowContactDetail = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(
    contactId => {
      const query = qs.parse(search.slice(1))
      query.contact = contactId
      history[action]({ search: `?${qs.stringify(query)}` })
    },
    [search]
  )
}

export const useHiddenContactDetail = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(() => {
    const query = qs.parse(search.slice(1))
    delete query.contact
    history[action]({ search: `?${qs.stringify(query)}` })
  }, [search])
}

export const useShowContactAttachments = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(
    (contactId, attachmentType) => {
      const query = qs.parse(search.slice(1))
      query.contact = contactId
      query.attachmentType = attachmentType
      history[action]({ search: `?${qs.stringify(query)}` })
    },
    [search]
  )
}

export const useHiddenContactAttachments = (action = 'push') => {
  const { search } = useLocation()
  const history = useHistory()
  return useCallback(
    (contactId, attachmentType) => {
      const query = qs.parse(search.slice(1))
      delete query.contact
      delete query.attachmentType
      history[action]({ search: `?${qs.stringify(query)}` })
    },
    [search]
  )
}
