// @flow
import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import i18next from 'i18next'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'

import NewAccount, {
  EmailFormSection,
  InvitationLinkSection,
} from '@edison/webmail-ui/screens/Settings/components/CustomDomain/NewAccount'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'
import * as customDomains from 'core/custom-domains'
import * as premium from 'core/premium'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'
import { formatPrice } from 'utils'

import type { Dispatch } from 'types/redux'

type Props = { id?: string, isOpen: boolean, toggle: () => void }

const View = ({ id, isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const [subaccountId, setSubaccountId] = useState(id)
  const current = useSelector(customDomains.selectors.getCurrentDomain)
  const subaccounts = useSelector(customDomains.selectors.getSubaccountsById)
  const isLoading = useSelector(
    customDomains.selectors.getCreateSubaccountLoading
  )
  const isGeneratingLink = useSelector(
    customDomains.selectors.getSubaccountInvitationLinkLoading
  )
  const currentPlan = useSelector(premium.selectors.getCurrentPlan)
  const confirmation = useModal(modalTypes.confirm)

  useEffect(() => {
    if (isOpen && id) {
      setSubaccountId(id)
      const _subaccount = get(subaccounts, id, null)
      if (_subaccount) {
        dispatch(
          customDomains.actions.fetchSubaccountInvitationLink(_subaccount.id)
        )
      }
    } else if (subaccountId) {
      setSubaccountId(undefined)
    }
  }, [isOpen, id])

  if (isNil(current)) {
    // TODO: Error state when modal is opened before domain detail
    // is loaded
    return null
  }

  const subaccount = get(subaccounts, subaccountId, null)
  const { domain } = current

  const onCreate = (accountName, formikBag) => {
    if (isNil(currentPlan)) {
      // Fail early
      throw new Error(
        'User is seeing create subaccount without an active premium plan.'
      )
    }

    // Show a confirmation modal with a bill preview before the actual creation
    confirmation.showModal({
      message: i18next.t('signup.subaccount.confirm.message', {
        price: formatPrice(currentPlan.unitPrice, currentPlan.currency),
        interval: currentPlan.interval,
      }),
      onSure: () => {
        dispatch(customDomains.actions.createSubaccount(accountName)).then(
          ({ isSuccess, message, res }) => {
            if (isSuccess) {
              setSubaccountId(res.id)
            } else {
              formikBag.setFieldError('email', message)
            }
          }
        )
      },
    })
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      <NewAccount onClose={toggle} isSuccess={!!subaccount}>
        <EmailFormSection
          email={!!subaccount ? subaccount.email : undefined}
          isCreated={!!subaccount}
          domain={domain}
          onSubmit={onCreate}
          isLoading={isLoading}
        />
        {subaccount &&
          (isGeneratingLink ? (
            <CircularLoader fullWidth />
          ) : (
            <InvitationLinkSection link={subaccount.invitationLink} />
          ))}
      </NewAccount>
    </Dialog>
  )
}

View.displayName = 'NewSubAccount'

export default View
