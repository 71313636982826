import { Kochava } from 'kochava'

const KOCHAVA_APP_GUID = 'koonmail-web-2x4s3'

export function initializeKochava() {
  return new Promise((resolve, reject) => {
    try {
      const kochava = Kochava.createForReact()
      kochava.startWithAppGuid(KOCHAVA_APP_GUID)
      resolve(kochava)
    } catch (e) {
      reject(e)
    }
  })
}
