import React, { useMemo, useEffect, useRef } from 'react'
import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import ForwardIcon from '@material-ui/icons/Forward'
import {
  isShowReplyAll,
  getMessage,
  isShowSmartReply,
} from 'core/messages/selectors'
import { getDraft } from 'core/compose/selectors'
import {
  reply,
  replyAll,
  forward,
  checkAndFetchMessageSmartReply,
  feedbackSmartReply,
} from 'core/messages/actions'
import { useTranslation } from 'react-i18next'
import type { Dispatch } from 'types/redux'
import { useSelector, useDispatch } from 'react-redux'
import {
  composeStatus,
  smartReplyTypes,
  smartReplyFeedbackTypes,
} from 'utils/constants'
import SmartReplyFeedbackButton from '@edison/webmail-ui/components/SmartReplyFeedbackButton'
import { useParams } from 'react-router-dom'

import { getLastestLoadedMessageIdByThreadId } from 'core/messages/selectors'

import { hasTrashMessage } from 'core/metadata/selectors'
import { labelNames } from 'utils/constants'

function SmartReplyButton(props) {
  return (
    <button
      {...props}
      className="whitespace-wrap py-2 px-4 m-2 text-sm font-semibold bg-app dark:bg-card shadow rounded-lg disabled:cursor-not-allowed disabled:opacity-75 hover:bg-secondary dark:hover:bg-secondary text-primary "
      style={{
        boxShadow:
          '0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.06)',
      }}
    />
  )
}

function ResponseButton(props) {
  return (
    <button
      {...props}
      className="inline-flex items-center p-2 px-4 ml-2 rounded text-gray-light disabled:opacity-75 disabled:cursor-not-allowed hover:bg-secondary first:ml-0"
    />
  )
}

function SmartReplyFeedback({ messageId }) {
  const { smartReply } = useSelector(
    useMemo(() => getMessage(messageId), [messageId])
  )
  const feedbackedRef = useRef(smartReply.feedbacked)

  const dispatch = useDispatch()

  if (feedbackedRef.current) return null
  return (
    <SmartReplyFeedbackButton
      className="m-2 "
      onSure={helpful =>
        dispatch(
          feedbackSmartReply(
            messageId,
            helpful
              ? smartReplyFeedbackTypes.GOOD_CASE
              : smartReplyFeedbackTypes.BAD_CASE
          )
        )
      }
    />
  )
}

function SmartReplyView({ messageId }) {
  const { smartReply } = useSelector(
    useMemo(() => getMessage(messageId), [messageId])
  )

  const dispatch: Dispatch = useDispatch()
  useEffect(() => {
    messageId && dispatch(checkAndFetchMessageSmartReply(messageId))
  }, [messageId])

  if (!smartReply || !smartReply.replyable) return null
  const filteredSmartReply = [
    smartReplyTypes.POS,
    smartReplyTypes.NEU,
    smartReplyTypes.NEG,
  ].filter(item => smartReply[item])
  if (!filteredSmartReply.length) return null

  return (
    <div className="m-2 items-center flex flex-unwrap">
      {filteredSmartReply.map(item => (
        <SmartReplyButton
          key={item}
          onClick={() => {
            dispatch(
              feedbackSmartReply(
                messageId,
                smartReplyFeedbackTypes[`SELECT_${item.toUpperCase()}`]
              )
            )
            dispatch(replyAll(messageId, smartReply[item]))
          }}
        >
          {smartReply[item]}
        </SmartReplyButton>
      ))}
      <SmartReplyFeedback messageId={messageId} />
    </div>
  )
}

function ResponseBar({ messageId }) {
  const showReplyAll = useSelector(
    useMemo(() => isShowReplyAll(messageId), [messageId])
  )
  const showSmartReply = useSelector(
    useMemo(() => isShowSmartReply(messageId), [messageId])
  )
  const { t } = useTranslation()

  const dispatch: Dispatch = useDispatch()

  return (
    <>
      {showSmartReply && <SmartReplyView messageId={messageId} />}
      <div className="border-t text-sm text-gray-medium p-4 py-2">
        <div>
          <ResponseButton
            onClick={() => {
              dispatch(reply(messageId))
            }}
          >
            <ReplyIcon fontSize="small" />
            <span className="ml-2">{t('button.reply')}</span>
          </ResponseButton>

          {showReplyAll && (
            <ResponseButton
              onClick={() => {
                dispatch(replyAll(messageId))
              }}
            >
              <ReplyAllIcon fontSize="small" />
              <span className="ml-2">{t('button.replyAll')}</span>
            </ResponseButton>
          )}
          <ResponseButton
            onClick={() => {
              dispatch(forward(messageId))
            }}
          >
            <ForwardIcon fontSize="small" />
            <span className="ml-2">{t('button.forward')}</span>
          </ResponseButton>
        </div>
      </div>
    </>
  )
}

export default ({ threadId }) => {
  const params = useParams()

  const hasTrash = useSelector(
    useMemo(() => hasTrashMessage(threadId), [threadId])
  )

  const onlyTrash = params.label === 'trash' && hasTrash

  const messageId = useSelector(
    useMemo(
      () =>
        getLastestLoadedMessageIdByThreadId(threadId, {
          [labelNames.trash]: onlyTrash,
          [labelNames.drafts]: false,
        }),
      [threadId, onlyTrash]
    )
  )

  const draft = useSelector(useMemo(() => getDraft(messageId), [messageId]))

  return (
    !!messageId &&
    (!draft ||
      draft.status === composeStatus.deleted ||
      draft.status === composeStatus.sended) && (
      <ResponseBar messageId={messageId} />
    )
  )
}
