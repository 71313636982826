// @flow
import { useState, useContext } from 'react'
import { useThemePrefer } from '@edison/webmail-ui/hooks/themePrefer'
import { OnMailThemeContext } from '@edison/webmail-ui/styles/theme'

import { themeMode } from '@edison/webmail-ui/utils/constants'
import {
  setThemePrefer,
  getThemePrefer,
  clearThemePrefer,
} from 'common/storage'

import type { ThemeMode } from '@edison/webmail-ui/utils/constants'

export default () => {
  const systemPrefer = useThemePrefer()
  const context = useContext(OnMailThemeContext)

  const [prefer, setPrefer] = useState(() => {
    const userPrefer = getThemePrefer()

    if (userPrefer === themeMode.DARK || userPrefer === themeMode.LIGHT) {
      return userPrefer
    } else {
      return themeMode.AUTO
    }
  })

  function getThemeChangedHandler(theme) {
    return () => {
      const nextTheme = theme === themeMode.AUTO ? systemPrefer : theme

      if (nextTheme === themeMode.LIGHT) {
        context.setLightTheme()
      } else {
        context.setDarkTheme()
      }

      if ([themeMode.LIGHT, themeMode.DARK].includes(theme)) {
        setThemePrefer(theme)
      } else {
        clearThemePrefer()
      }

      setPrefer(theme)
    }
  }

  return {
    prefer,
    mode: context.mode,
    setTheme: (theme: ThemeMode) => getThemeChangedHandler(theme)(),
    setAutoTheme: getThemeChangedHandler(themeMode.AUTO),
    setDarkTheme: getThemeChangedHandler(themeMode.DARK),
    setLightTheme: getThemeChangedHandler(themeMode.LIGHT),
    isDarkTheme: context.mode === themeMode.DARK,
    isLightTheme: context.mode === themeMode.LIGHT,
  }
}
