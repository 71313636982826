// @flow
import { nudgeStatus } from '@edison/webmail-core/utils/constants'
import CardModalContent, {
  NudgeCardBottom,
} from '@edison/webmail-ui/components/Cards/CardModal'
import type { GalleryView } from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import { useCard } from 'core/contacts/hooks'
import { getPendingContacts } from 'core/contacts/selectors'
import { updateMessageNudge } from 'core/email-nudges/actions'
import { checkAndFetchThread, reply } from 'core/messages/actions'
import { useShowThreadDetail } from 'hooks/useHandleDetailModal'
import { useKeyboardNavigation } from 'hooks/useKeyboardNavigation'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadedMessageList } from 'screens/ThreadDetail'
import { cardTypes } from 'utils/constants'

type Props = {
  onClose: () => any,
  threadId: string,
  messageId: string,
  onNextNavigate: (view: GalleryView, data: any) => void,
  total: number,
}

export default function EmailNudgeCardModal({
  threadId: id,
  messageId,
  onClose,
  onNextNavigate,
  total,
  ...rest
}: Props) {
  const [threadId, setThreadId] = useState(id)
  const [newMessageId, setNewMessageId] = useState(messageId)
  const { onNextCard, onPreviousCard, currIdx, hasNext, hasPrevious } = useCard(
    cardTypes.nudges,
    threadId,
    onNextNavigate,
    ({ threadId: nextThreadId, id: msgId }) => {
      setThreadId(nextThreadId)
      setNewMessageId(msgId)
    }
  )
  const pending = useSelector(getPendingContacts())
  const showThreadDetail = useShowThreadDetail()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkAndFetchThread(threadId))
  }, [threadId])

  useKeyboardNavigation(
    {
      onPrevious: () => hasPrevious && onPreviousCard(),
      onNext: () => hasNext && onNextCard(),
    },
    [hasPrevious, hasNext, onPreviousCard, onNextCard]
  )

  const onDismiss = () => {
    dispatch(updateMessageNudge(nudgeStatus.DISMISSED, { threadId }))
  }

  const onReply = () => {
    dispatch(reply(newMessageId)).then(() => {
      showThreadDetail(threadId)
      onClose()
    })
  }

  return (
    <CardModalContent
      total={total}
      isNeedShadow={false}
      listClassName="bg-card overflow-y-auto"
      currIdx={currIdx + pending.length}
      className="relative z-10"
      onClose={onClose}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      onNext={onNextCard}
      onPrevious={onPreviousCard}
      bottom={<NudgeCardBottom onDismiss={onDismiss} onReply={onReply} />}
    >
      <LoadedMessageList
        onDismiss={onNextCard}
        onSelectRecipient={() => {}}
        isModalMode
        threadId={threadId}
        key={threadId}
      />
    </CardModalContent>
  )
}
