import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getContactByEmail } from 'core/contacts/selectors'
import { UNKNOWN_SENDER } from 'utils/constants'
import { getSenderNameSelector } from 'core/settings/selectors'
import { isCurrentUser } from 'core/custom-domains/selectors'

function ContactSender({ email, trimed }) {
  const contact = useSelector(useMemo(() => getContactByEmail(email), [email]))
  if (contact) {
    return trimed ? contact.name.split(' ')[0] : contact.name
  }
  return email.split('@')[0] || UNKNOWN_SENDER
}

export default function Sender({ name, email, trimed, noMe = false }) {
  const sameUser = useSelector(useMemo(() => isCurrentUser(email), [email]))
  const senderName = useSelector(getSenderNameSelector)

  if (sameUser) {
    if (senderName && noMe) {
      if (trimed) {
        return senderName
      }
      return (
        <span className="inline-flex items-center">
          <span>{senderName}</span>&nbsp;
          <span className="text-gray-medium text-xs font-normal">
            &lt;{email}&gt;
          </span>
        </span>
      )
    }

    if (!noMe) {
      return 'me'
    }
  }
  if (name) return trimed ? name.split(' ')[0] : name

  return <ContactSender email={email} trimed={trimed} />
}
