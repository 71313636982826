// @flow
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { update } from 'core/auth/actions'
import { getLoadingStatus } from 'core/loading/selectors'

import BasicModal from '@edison/webmail-ui/components/BasicModal'
import SetPassword from '@edison/webmail-ui/screens/Settings/sections/Account/SetPassword'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
}

const View = ({ isOpen }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const { hideModal } = useModal(modalTypes.setPassword)

  const isLoading = useSelector(getLoadingStatus('AUTH_UPDATE'))

  const mediaQuery = useMemo(() => window.matchMedia('(max-width: 640px)'), [])
  return (
    <BasicModal
      fullScreen={mediaQuery.matches}
      size="sm"
      hideModal={hideModal}
      isOpen={isOpen}
    >
      <SetPassword
        isLoading={isLoading}
        onSubmit={(currentPassword, newPassword) => {
          dispatch(update(currentPassword, newPassword))
        }}
      />
    </BasicModal>
  )
}

export default View
