import React, { useMemo, useRef, useCallback, useContext } from 'react'
import uniq from 'lodash/uniq'
import { show as showModal } from 'core/modals/actions'
import { modalTypes } from 'utils/constants'
import * as client from '@edison/webmail-core/api'
import { useSelector, useDispatch } from 'react-redux'
import {
  getDraft,
  isDraftDisabled,
  getResponseType,
  getDraftFromEmail,
} from 'core/compose/selectors'
import {
  updateDraft,
  debouncedSaveDraft,
  uploadFiles,
  insertLargeAttachments,
} from 'core/compose/actions'
import { insertInlineImage, loadInlineImage } from 'core/attachments/actions'
import { getAccountSignatureContent } from 'core/settings/selectors'

import {
  RESPONSE_TYPE_REPLY_ALL,
  RESPONSE_TYPE_REPLY,
  WM_API_HOST,
} from 'utils/constants'
import { betaFeatures } from '@edison/webmail-core/utils/constants'
import SendButton from './SendButton'

import { EditorContextProvider } from '@edison/webmail-ui/components/TinymceEditor/context'
import { ActiveToolContextProvider } from '@edison/webmail-ui/components/TinymceComposeEditor/contexts'
import TinymceComposeEditor from '@edison/webmail-ui/components/TinymceComposeEditor'
import Toolbar from '@edison/webmail-ui/components/TinymceComposeEditor/Toolbar'
import { TOOL_NONE } from '@edison/webmail-ui/components/TinymceComposeEditor/constants'
import ActiveTool from '@edison/webmail-ui/components/TinymceComposeEditor/ActiveTool'

import { genProxySrc } from '@edison/webmail-ui/hooks/proxyImage'
import DeleteButton from './DeleteButton'
import AttachmentView from './AttachmentView'
import { getAuth } from 'core/auth/selectors'
import { activeDraft } from 'core/compose/actions'

import { useBetaFeature } from 'core/beta-features/hooks'
import * as yup from 'yup'
import { OnMailThemeContext } from '@edison/webmail-ui/styles/theme'

const emailSchema = yup.string().email()
export default ({ draftId, headerRef }) => {
  const {
    html,
    actived,
    to,

    cc,
    bcc,
    subject,
    // focusEnd,
    responseMessageId,
  } = useSelector(useMemo(() => getDraft(draftId), [draftId]))
  const { mode } = useContext(OnMailThemeContext)
  const from = useSelector(useMemo(() => getDraftFromEmail(draftId), [draftId]))

  const auth = useSelector(useMemo(() => getAuth(), []))
  const { isEnable: spellCheck } = useBetaFeature(betaFeatures.spellCheck)
  const defaultHTML = useMemo(() => html, [])

  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )
  const responseType = useSelector(
    useMemo(() => getResponseType(draftId), [draftId])
  )

  const signature = useSelector(
    useMemo(() => getAccountSignatureContent(from), [from])
  )

  const dispatch = useDispatch()
  const attachmentNodeRef = useRef()
  const linkDialogRef = useRef()
  const bottomRef = useRef()
  const bodyRef = useRef()
  const editorRef = useRef()

  const suggestions = useMemo(
    () =>
      uniq(
        to
          .map(item => item.name)
          .filter(item => item && item.trim())
          .filter(item => !emailSchema.isValidSync(item))
          .map(item => {
            const name = item.split(' ')[0]
            return name.charAt(0).toUpperCase() + name.slice(1)
          })
      ),
    [to]
  )

  const autoFocus = useMemo(
    () => !![...to, ...cc, ...bcc].length && !!subject.trim().length && actived,
    []
  )

  const handleUploadFiles = files => {
    const result = dispatch(uploadFiles(draftId, files))
    if (result && result.then) {
      result.then(() => {
        //the reply/forward compose scroll has some issue.
        !responseMessageId &&
          attachmentNodeRef.current &&
          attachmentNodeRef.current.scrollIntoView()
      })
    }
  }

  const handleUploadInlineImage = file =>
    dispatch(insertInlineImage(draftId, file))

  const handleFocus = () => {
    dispatch(activeDraft(draftId))
  }

  const spellCheckWhitelist = useMemo(
    () =>
      uniq(
        [...to, ...cc, ...bcc]
          .map(item => item.name)
          .filter(name => name && name.trim())
          .map(name =>
            emailSchema.isValidSync(name)
              ? [name.split('@')[0]]
              : name.split(' ')
          )
          .reduce((prev, curr) => [...prev, ...curr], [])
      ),
    [to, cc, bcc]
  )

  const handleSpellCheck = useCallback(
    texts =>
      client.compose
        .spellCheck(
          { texts, whitelist: spellCheckWhitelist },
          auth,
          WM_API_HOST
        )
        .then(res => res.result),
    [auth, spellCheckWhitelist]
  )

  return (
    <EditorContextProvider>
      <div
        className=" flex flex-col flex-1 px-4 pt-4"
        id={`Compose-${draftId}-body`}
        ref={bodyRef}
      >
        <TinymceComposeEditor
          autoLink
          appearanceMode={mode}
          ref={editorRef}
          autoFocus={autoFocus}
          // focusEnd={
          //   !![...to, ...cc, ...bcc].length &&
          //   !!subject.trim().length &&
          //   actived
          // }
          onFocus={handleFocus}
          linkDialogRef={linkDialogRef}
          hiddenQuoteButton={!actived}
          bottomRef={bottomRef}
          headerRef={headerRef}
          defaultHTML={defaultHTML}
          signature={signature}
          disabled={disabled}
          suggestions={suggestions}
          spellCheck={spellCheck}
          onEditorChange={html => {
            dispatch(
              updateDraft({
                id: draftId,
                html,
              })
            )
            dispatch(debouncedSaveDraft(draftId))
          }}
          onLoadExternalImage={src => genProxySrc(src, auth.token)}
          onUploadInlineImage={handleUploadInlineImage}
          onUploadAttachments={files => handleUploadFiles(files)}
          onLoadInlineImage={cid => dispatch(loadInlineImage(draftId, cid))}
          onSpellCheck={handleSpellCheck}
          enableSplitQuotation={
            responseType === RESPONSE_TYPE_REPLY ||
            responseType === RESPONSE_TYPE_REPLY_ALL
          }
        />
        <AttachmentView draftId={draftId} ref={attachmentNodeRef} />
      </div>
      <div className="flex-grow-0 sticky bottom-0 z-10" ref={bottomRef}>
        {actived && (
          <ActiveToolContextProvider
            defaultActiveTool={TOOL_NONE}
            linkDialogRef={linkDialogRef}
          >
            <ActiveTool appearanceMode={mode} />

            <div className="flex items-center justify-between border-t p-4 bg-app">
              <div className="flex">
                <SendButton draftId={draftId} />
                <Toolbar
                  className="pl-2"
                  onShowUploadDialog={async () => {
                    dispatch(
                      showModal({
                        key: modalTypes.upload,
                        props: {
                          draftId,
                          onUploadFiles: handleUploadFiles,

                          onSure: attachments =>
                            dispatch(
                              insertLargeAttachments(draftId, attachments)
                            ),
                        },
                      })
                    )
                  }}
                  disabled={disabled}
                />
              </div>
              <DeleteButton draftId={draftId} />
            </div>
          </ActiveToolContextProvider>
        )}
      </div>
    </EditorContextProvider>
  )
}
