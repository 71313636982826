//@flow

type CircularCacheContent<ContentType> = {
  val: null | ContentType,
  lastAccess: number,
  key: string,
}
export class CircularCache<ContentType> {
  static LRU<T>(
    cacheArray: CircularCacheContent<T>[],
    currentNextAvailableIndex: number
  ): number {
    let newIndex = currentNextAvailableIndex
    let previousLastAccess = cacheArray[currentNextAvailableIndex].lastAccess
    for (let i = 0; i < cacheArray.length; i++) {
      if (cacheArray[i].lastAccess === 0) {
        return i
      } else if (cacheArray[i].lastAccess < previousLastAccess) {
        newIndex = i
        previousLastAccess = cacheArray[i].lastAccess
      }
    }
    return newIndex
  }
  cacheLength: number
  cacheMethod: 'LRU'
  cacheContents: CircularCacheContent<ContentType>[]
  nextAvailableCacheIndex: number
  constructor(maxItems: number, cacheMethod?: 'LRU') {
    if (typeof maxItems !== 'number') {
      maxItems = 1
    }
    if (maxItems < 1) {
      maxItems = 1
    }
    maxItems = Math.floor(maxItems)
    this.cacheLength = maxItems
    this.cacheMethod = cacheMethod || 'LRU'
    this.cacheContents = []
    this.nextAvailableCacheIndex = 0
    for (let i = 0; i < maxItems; i++) {
      this.cacheContents.push({ val: null, lastAccess: 0, key: '' })
    }
  }
  get(cacheKey: string): ContentType | null {
    if (typeof cacheKey !== 'string' || cacheKey.length === 0) {
      console.error(`No cacheKey set ${cacheKey}`)
      return null
    }
    const tmp = this._findCacheByKey(cacheKey)
    if (tmp) {
      return tmp.val
    }
    return null
  }
  getByPartialCacheKey(
    partialCacheKey: string
  ): Array<{ key: string, cache: ContentType }> {
    if (typeof partialCacheKey !== 'string' || partialCacheKey.length === 0) {
      console.error(
        `getByPartialCacheKey:No partialCacheKey set ${partialCacheKey}`
      )
      return null
    }
    const ret = []
    const cacheIndexes = this._findCacheByPartialKey(partialCacheKey)
    cacheIndexes.forEach(index => {
      ret.push({
        key: this.cacheContents[index].key,
        cache: this.cacheContents[index].val,
      })
    })
    return ret
  }
  updateByPartialCacheKey(partialCacheKey: string, cacheContent: ContentType) {
    if (typeof partialCacheKey !== 'string' || partialCacheKey.length === 0) {
      console.error(`No cacheKey set ${partialCacheKey}`)
      return null
    }
    const cacheIndexes = this._findCacheByPartialKey(partialCacheKey)
    cacheIndexes.forEach(index => {
      this.cacheContents[index].val = cacheContent
    })
  }
  _findCacheByPartialKey = (partialKey: string) => {
    const ret = []
    for (let i = 0; i < this.cacheLength; i++) {
      if (this.cacheContents[i].key.startsWith(partialKey)) {
        this.cacheContents[i].lastAccess = Date.now()
        ret.push(i)
      }
    }
    return ret
  }
  _findCacheByKey = (cacheKey: string) => {
    for (let i = 0; i < this.cacheLength; i++) {
      if (cacheKey === this.cacheContents[i].key) {
        this.cacheContents[i].lastAccess = Date.now()
        return this.cacheContents[i]
      }
    }
    return null
  }
  clearByKey(cacheKey: string) {
    if (typeof cacheKey !== 'string' || cacheKey.length === 0) {
      console.error(`ClearByKey: No cacheKey set ${cacheKey}`)
      return
    }
    const currentCache = this._findCacheByKey(cacheKey)
    if (currentCache) {
      currentCache.key = ''
      currentCache.lastAccess = 0
      currentCache.val = null
    }
  }
  set(cacheKey: string, cacheContent: ContentType | null) {
    if (typeof cacheKey !== 'string' || cacheKey.length === 0) {
      console.error(`No cacheKey set ${cacheKey}`)
      return
    }
    const currentCache = this._findCacheByKey(cacheKey)
    if (currentCache) {
      currentCache.val = cacheContent
      return
    }
    const index = this._findNextAvailableCacheIndex()
    this.cacheContents[index] = {
      val: cacheContent,
      lastAccess: Date.now(),
      key: cacheKey,
    }
    console.log(`cache set at index ${index}`)
  }

  _findNextAvailableCacheIndex() {
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (this.cacheMethod === 'LRU') {
      this.nextAvailableCacheIndex = CircularCache.LRU<ContentType>(
        this.cacheContents,
        this.nextAvailableCacheIndex
      )
      // eslint-disable-next-line sonarjs/no-duplicated-branches
    } else {
      // Currently, Only LRU is supported;
      this.nextAvailableCacheIndex = CircularCache.LRU<ContentType>(
        this.cacheContents,
        this.nextAvailableCacheIndex
      )
    }
    console.log(`New Cache next Index ${this.nextAvailableCacheIndex}`)
    return this.nextAvailableCacheIndex
  }
}
export function createCircularBuffer<T>(maxItems: number): CircularCache<T> {
  return new CircularCache<T>(maxItems)
}
