// @flow
// $FlowFixMe
import firebase from 'firebase/app'
import { createSelector } from 'reselect'

import { remoteConfigKeys } from './constants'

import type { Selector } from 'types/state'

export const selectRemoteConfig = () => firebase.remoteConfig()

export const getSMSEnabledCountries: Selector<
  $ReadOnlyArray<string>
> = createSelector(selectRemoteConfig, state => {
  const val = state.getString(remoteConfigKeys.smsEnabledCountryCodes)
  try {
    return JSON.parse(val)
  } catch (e) {
    console.error('invalid "sms_enabled_country_codes" from firebase')
    return []
  }
})
