// @flow
import difference from 'lodash/difference'
import type { AddImages, DeleteImages } from './types'
import * as client from '@edison/webmail-core/api/signature-images'
import { getAuth } from 'core/auth/selectors'
import {
  getSignatureContent,
  getVacationResponderContent,
  getAllSignatureExtensions,
} from 'core/settings/selectors'
import { getAllSignatureImagesById } from 'core/signature-images/selectors'
import { createAction } from 'utils/redux'
import type { ThunkAction, ActionCreator } from 'types/redux'

import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'
import { getSignatureImages } from '@edison/webmail-ui/utils'
import { SIGNATURE_IMAGE_MAX_NUMBER } from 'utils/constants'

export const addImages: ActionCreator<AddImages> = createAction('ADD_IMAGES')
export const deleteImages: ActionCreator<DeleteImages> = createAction(
  'DELETE_IMAGES'
)

export const fetchSignatureImages = (): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      return
    }
    const res = await client.fetchSignatureImages(auth)
    res.result?.length && dispatch(addImages(res.result || []))

    return res.result || []
  }
}

export const uploadSignatureImage = (file: File): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return
    }

    try {
      const res = await client.uploadSignatureImage(file, auth)

      dispatch(addImages([res.result]))
      return res.result.url
    } catch (e) {
      dispatch(showNotification(e.message, toastVariants.error))
      throw e
    }
  }
}

export const batchDeleteSignatureImages = (
  imageIds: Array<string>
): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return
    }

    if (imageIds.length === 0) return

    try {
      await client.batchDeleteSignatureImages(imageIds, auth)
      dispatch(deleteImages(imageIds))
      return true
    } catch (e) {
      return false
    }
  }
}

export const deleteNonExistingSignatureImages = (imageIds): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return
    }

    const state = getState()
    const signature = getSignatureContent()(state)
    const signatureExtensions = getAllSignatureExtensions()(state)
    const vacationResponder = getVacationResponderContent()(state)
    const allSignatureImages = getAllSignatureImagesById()(state)
    if (allSignatureImages.length < SIGNATURE_IMAGE_MAX_NUMBER) {
      return dispatch(batchDeleteSignatureImages(imageIds))
    }

    const currentSignatureImages = getSignatureImages(
      signature + signatureExtensions + vacationResponder
    )

    const imagesToDelete = difference(
      allSignatureImages,
      currentSignatureImages
    )

    return dispatch(batchDeleteSignatureImages(imagesToDelete))
  }
}
