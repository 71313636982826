// @flow
import moment from 'moment'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'

import {
  getTokenExpiry,
  getTokenRefreshLoading,
  getOrderId,
} from 'core/auth/selectors'
import { refreshToken } from 'core/auth/actions'
import { showNotification } from 'core/toasts/actions'
import { getUser } from 'common/storage'
import { toastVariants } from 'common/toasts'
import { authStatus } from 'utils/constants'

import type { Store, Dispatch, ActionMeta } from 'types/redux'

// 1 minute threshold
const REFRESH_THRESHOLD = 60

export default (store: Store) => (dispatch: Dispatch) => (action: {
  type: string,
  payload: mixed,
  meta: ActionMeta,
}) => {
  // None thunk (API) actions will not trigger this
  if (!isFunction(action) || action === refreshToken) {
    return dispatch(action)
  }

  const state = store.getState()
  const expiry = getTokenExpiry(state)
  const isLoading = getTokenRefreshLoading(state)
  const orderId = getOrderId(state)

  // No expiry set, no refresh logic needed
  if (!expiry || isNil(orderId)) {
    return dispatch(action)
  }

  // Refresh token first before continuing
  const now = moment().unix()
  if (expiry - now < REFRESH_THRESHOLD && !isLoading) {
    return dispatch(refreshToken({ orderId })).then(resp => {
      // If refresh success
      if (resp.success) {
        return dispatch(action)
      } else if (resp.code === authStatus.INVALID) {
        const user = getUser(orderId)
        if (!isNil(user)) {
          dispatch(
            showNotification(
              `Authentication of ${user} is expired. Please login again.`,
              toastVariants.error
            )
          )
        }
        // When refresh token expired, reload the page
        // Let initialization handle it
        window.location.reload()
      }
    })
  } else {
    return dispatch(action)
  }
}
