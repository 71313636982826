// @flow
import get from 'lodash/get'
import union from 'lodash/union'
import isNil from 'lodash/isNil'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import pullAll from 'lodash/pullAll'
import mapValues from 'lodash/mapValues'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import { compose } from '@edison/functools'

import { labelNames, labelTypes, siftLabelsGroup } from 'utils/constants'

import type { MessageSnapshot } from '@edison/webmail-core/types/threads'
import type {
  ViewChangedThread,
  LabelMessagesState,
  ThreadMessagesState,
  LabelThreadsState,
  ViewThreadsState,
  ExtraInfoState,
} from './types'
import type { State as LabelState } from '../labels/types'

export function labelMessagesUpdate(
  state: LabelMessagesState,
  labels: $ReadOnlyArray<string>,
  updateFunc: (
    label: string,
    messageIds: $ReadOnlyArray<string>
  ) => $ReadOnlyArray<string>
) {
  return labels.reduce((prev, curr) => {
    const label = get(prev, curr)
    if (isNil(label)) return prev
    const { messageIds, ...rest } = label
    return {
      ...prev,
      [curr]: {
        ...rest,
        messageIds: updateFunc(label, messageIds),
      },
    }
  }, state)
}

export function batchUpdateLabelMessages(
  state: LabelMessagesState,
  messages: $ReadOnlyArray<{
    messageIds: $ReadOnlyArray<string>,
    add: $ReadOnlyArray<string>,
    remove: $ReadOnlyArray<string>,
  }>
) {
  return messages.reduce((prev, curr) => {
    const { messageIds: toUpdateMessages = [], add = [], remove = [] } = curr
    return compose(
      input =>
        labelMessagesUpdate(input, remove, (_, messageIds) =>
          messageIds.filter(item => !toUpdateMessages.includes(item))
        ),
      input =>
        labelMessagesUpdate(input, add, (_, messageIds) => [
          ...messageIds,
          ...toUpdateMessages,
        ])
    )(prev)
  }, state)
}

// Thread Messages
export function threadMessagesIndex(
  state: ThreadMessagesState,
  messages: $ReadOnlyArray<{
    id: string,
    threadId: string,
  }>
) {
  let threadMessages = { ...state }

  console.time('Thread Messages Indexing')
  for (const { id, threadId } of messages) {
    if (threadId in threadMessages) {
      const { messageIds, ...rest } = threadMessages[threadId]
      threadMessages[threadId] = {
        ...rest,
        messageIds: Array.from(new Set([...messageIds, id])),
      }
    } else {
      threadMessages[threadId] = {
        id: threadId,
        messageIds: [id],
      }
    }
  }
  console.timeEnd('Thread Messages Indexing')

  return threadMessages
}

export function deleteThreadMessage(
  state: ThreadMessagesState,
  messages: $ReadOnlyArray<{
    id: string,
    threadId: string,
  }>
) {
  let next = { ...state }
  for (let { id, threadId } of messages) {
    const { [threadId]: thread, ...theRest } = next
    if (isNil(thread)) return state
    else {
      const newMessageIds = thread.messageIds.filter(item => item !== id)
      if (newMessageIds.length === 0) next = theRest
      else
        next = {
          ...theRest,
          [threadId]: {
            ...thread,
            messageIds: newMessageIds,
          },
        }
    }
  }
  return next
}

// Label Threads
export function labelThreadIndex(
  state: LabelThreadsState,
  messages: $ReadOnlyArray<{
    threadId: string,
    labelIds: $ReadOnlyArray<string>,
  }>
) {
  console.time('Label Threads Indexing')
  const sets = messages.reduce((prev, curr) => {
    const { threadId, labelIds } = curr
    if (isNil(labelIds)) {
      return prev
    }

    return labelIds.reduce((p, labelId) => {
      const prevThreadIds = get(prev, `${labelId}.threadIds`, new Set<string>())
      const threadIds: Set<string> = Array.isArray(prevThreadIds)
        ? new Set<string>(prevThreadIds)
        : prevThreadIds
      threadIds.add(threadId)

      return {
        ...p,
        [labelId]: {
          id: labelId,
          threadIds,
        },
      }
    }, prev)
  }, state)

  console.timeEnd('Label Threads Indexing')
  return mapValues(sets, item => ({
    ...item,
    threadIds: Array.from(item.threadIds),
  }))
}

export function updateThreadLabels(
  state: LabelThreadsState,
  threads: $ReadOnlyArray<{
    threadId: string,
    add: $ReadOnlyArray<string>,
    remove: $ReadOnlyArray<string>,
  }>
) {
  return threads.reduce((prev, curr) => {
    const { threadId, add, remove } = curr

    // We assume here that one label cannot exist in both add AND remove
    const added = keyBy(
      add.map(labelId => prev[labelId] || { id: labelId, threadIds: [] }),
      'id'
    )
    const removed = keyBy(
      remove.map(labelId => prev[labelId] || { id: labelId, threadIds: [] }),
      'id'
    )

    return {
      ...prev,
      ...mapValues(added, ({ threadIds, ...rest }) => ({
        ...rest,
        threadIds: union(threadIds, [threadId]),
      })),
      ...mapValues(removed, ({ threadIds, ...rest }) => ({
        ...rest,
        threadIds: threadIds.filter(id => id !== threadId),
      })),
    }
  }, state)
}

export function deleteLabelThread(
  state: LabelThreadsState,
  threads: $ReadOnlyArray<{
    threadId: string,
    labelIds: $ReadOnlyArray<string>,
  }>
) {
  return threads.reduce<LabelThreadsState>((prev, curr) => {
    const { threadId, labelIds } = curr

    return labelIds.reduce<LabelThreadsState>((p, labelId) => {
      const labelThreads = p[labelId]
      if (isNil(labelThreads)) return p
      else {
        const { threadIds } = labelThreads
        return {
          ...p,
          [labelId]: {
            ...labelThreads,
            threadIds: threadIds.filter(item => item !== threadId),
          },
        }
      }
    }, prev)
  }, state)
}

// View Threads
export function viewThreadsIndex(
  state: ViewThreadsState,
  threads: Array<{
    id: string,
    labelIds: $ReadOnlyArray<string>,
  }>,
  labels: LabelState
) {
  console.time('View Threads Indexing')

  // Assign each individual thread into a view
  let next = { ...state }
  for (const { id, labelIds } of threads) {
    assignThreadToLabels(next, id, getAssignedLabels(labels, labelIds))
  }
  console.timeEnd('View Threads Indexing')
  return next
}

export function removeViewThreadsIndex(
  state: ViewThreadsState,
  threads: $ReadOnlyArray<{
    id: string,
    prevLabels: $ReadOnlyArray<string>,
    currLabels: $ReadOnlyArray<string>,
  }>,
  labels: LabelState = {}
) {
  let next = { ...state }
  let revoked = {}
  for (let { id, prevLabels, currLabels } of threads) {
    assignThreadToLabels(
      revoked,
      id,
      getRevokedLabels(labels, prevLabels, currLabels)
    )
  }

  revokeThreadFromLabels(next, revoked)
  return next
}

/**
 * Clean up each threads in view threads index by iterating each element
 * in view threads index
 *
 * !!Only use for the actions which might not easy to access label state
 */
export function cleanupViewThreadsIndex(
  state: ViewThreadsState,
  threadIds: $ReadOnlyArray<string>
) {
  let revoked = {}
  let next = { ...state }

  for (let label in state) {
    const assigned = intersection(threadIds, state[label])

    if (assigned.length > 0) {
      if (!(label in revoked)) {
        revoked[label] = []
      }
      revoked[label].push(...assigned)
    }
  }

  revokeThreadFromLabels(next, revoked)
  return next
}

export function updateViewThreads(
  state: ViewThreadsState,
  threads: $ReadOnlyArray<ViewChangedThread>
) {
  const removedThreads = {}
  const addedThreads = {}

  for (let { threadId, prevViewLabels, currViewLabels } of threads) {
    const removed = difference(prevViewLabels, currViewLabels)
    const added = difference(currViewLabels, prevViewLabels)

    for (let labelId of removed) {
      if (labelId in removedThreads) {
        removedThreads[labelId].push(threadId)
      } else {
        removedThreads[labelId] = [threadId]
      }
    }

    for (let labelId of added) {
      if (labelId in addedThreads) {
        addedThreads[labelId].push(threadId)
      } else {
        addedThreads[labelId] = [threadId]
      }
    }
  }

  const next = { ...state }

  for (let labelId in removedThreads) {
    if (labelId in next) {
      next[labelId] = pullAll(next[labelId], removedThreads[labelId])
    }
  }

  for (let labelId in addedThreads) {
    next[labelId] = union(next[labelId] || [], addedThreads[labelId])
  }

  return next
}

function getAssignedLabelMapping(
  labels: LabelState,
  labelIds: $ReadOnlyArray<string>
): { [labelId: string]: boolean } {
  const labelSet = new Set<string>(labelIds)
  const spamPendingStaged =
    labelSet.has(labelNames.spam) ||
    labelSet.has(labelNames.pending) ||
    labelSet.has(labelNames.inboxStaged)

  let splits = labelIds
    .filter(id => labels[id])
    .filter(id => labels[id].type === labelTypes.SPLIT_INBOXES)
    .reduce((prev, curr) => ({ ...prev, [curr]: true }), {})

  let customs = labelIds
    .filter(id => labels[id])
    .filter(id => labels[id].type === labelTypes.CUSTOM)
    .reduce((prev, curr) => ({ ...prev, [curr]: true }), {})

  function isInbox() {
    // Trashed threads that gets replied to comes back into inbox, but not for spam
    if (spamPendingStaged) {
      return false
    }

    return labelSet.has(labelNames.inbox)
  }

  function isPrimary() {
    return isInbox() && labelSet.has(labelNames.primary) && isEmpty(splits)
  }

  function isOther() {
    // In inbox, with other label, and does not belong to any split inbox
    return isInbox() && !isPrimary() && isEmpty(splits)
  }

  function isArchive() {
    if (spamPendingStaged) {
      return false
    }

    return labelSet.has(labelNames.archive) && !labelSet.has(labelNames.inbox)
  }

  function isUnread() {
    if (spamPendingStaged) {
      return false
    }

    return labelSet.has(labelNames.unread)
  }

  function isDrafts() {
    if (spamPendingStaged) {
      return false
    }

    return labelSet.has(labelNames.drafts)
  }

  function isSent() {
    if (
      spamPendingStaged &&
      !(labelSet.has(labelNames.inbox) && labelSet.has(labelNames.trash))
    ) {
      return false
    }

    return labelSet.has(labelNames.sent)
  }

  function isPending() {
    return labelSet.has(labelNames.pending)
  }

  function isInboxStaged() {
    return labelSet.has(labelNames.inboxStaged)
  }

  function isSpam() {
    return !isPending() && !isInboxStaged() && labelSet.has(labelNames.spam)
  }

  function isTrash() {
    return !isPending() && !isInboxStaged() && labelSet.has(labelNames.trash)
  }

  function isTravel() {
    if (spamPendingStaged) {
      return false
    }

    return siftLabelsGroup[labelNames.travel].some(label => labelSet.has(label))
  }

  function isBillAndReceipt() {
    if (spamPendingStaged) {
      return false
    }

    return siftLabelsGroup[labelNames.billAndReceipts].some(label =>
      labelSet.has(label)
    )
  }

  function isPackages() {
    if (spamPendingStaged) {
      return false
    }

    return siftLabelsGroup[labelNames.packages].some(label =>
      labelSet.has(label)
    )
  }

  function isEvents() {
    if (spamPendingStaged) {
      return false
    }

    return siftLabelsGroup[labelNames.events].some(label => labelSet.has(label))
  }

  function isPriceTracking() {
    if (spamPendingStaged) {
      return false
    }

    return labelSet.has(labelNames.priceAlert)
  }

  function isRawUnread() {
    return labelSet.has(labelNames.unread)
  }

  const system = {
    [labelNames.inbox]: isInbox(),
    [labelNames.primary]: isPrimary(),
    [labelNames.other]: isOther(),
    [labelNames.archive]: isArchive(),
    [labelNames.unread]: isUnread(),
    [labelNames.rawUnread]: isRawUnread(),
    [labelNames.drafts]: isDrafts(),
    [labelNames.sent]: isSent(),
    [labelNames.spam]: isSpam(),
    [labelNames.trash]: isTrash(),
    [labelNames.pending]: isPending(),
    [labelNames.inboxStaged]: isInboxStaged(),
  }

  const smartFolders = {
    // Sift labels
    [labelNames.travel]: isTravel(),
    [labelNames.billAndReceipts]: isBillAndReceipt(),
    [labelNames.packages]: isPackages(),
    [labelNames.events]: isEvents(),

    // Price Alert
    [labelNames.priceTracking]: isPriceTracking(),
  }

  if (isSpam() || isPending() || isInboxStaged()) {
    return system
  }

  if (isTrash() && !isInbox() && !isArchive()) {
    return {
      [labelNames.trash]: true,
      [labelNames.rawUnread]: isRawUnread(),
    }
  }

  if (isArchive()) {
    return { ...system, ...smartFolders, ...customs }
  }

  return {
    ...system,
    ...smartFolders,
    ...customs,
    // Splits only assign to the threads in Inbox
    ...mapValues(splits, assigned => (isInbox() ? assigned : false)),
  }
}

export function getAssignedLabels(
  labels: LabelState,
  labelIds: $ReadOnlyArray<string>
): Set<string> {
  if (labelIds.length === 0) {
    // Skip the assignning process
    return new Set()
  }

  const mapping = getAssignedLabelMapping(labels, labelIds)
  const assigned = Object.keys(mapping).filter(label => mapping[label])

  return new Set<string>(assigned)
}

export function getRevokedLabels(
  labels: LabelState,
  prevLabels: $ReadOnlyArray<string>,
  currLabels: $ReadOnlyArray<string>
): Set<string> {
  let [prev, curr] = [
    getAssignedLabels(labels, prevLabels),
    getAssignedLabels(labels, currLabels),
  ]

  return new Set<string>(difference(Array.from(prev), Array.from(curr)))
}

export function assignThreadToLabels(
  currentState: ViewThreadsState,
  threadId: string,
  assigned: Set<string>
) {
  assigned.forEach(labelId => {
    if (!(labelId in currentState)) {
      currentState[labelId] = []
    }

    currentState[labelId] = [...currentState[labelId], threadId]
  })
}

export function revokeThreadFromLabels(
  currentState: ViewThreadsState,
  revoked: { [labelId: string]: $ReadOnlyArray<string> }
) {
  for (const labelId in revoked) {
    if (labelId in currentState) {
      currentState[labelId] = currentState[labelId].filter(
        id => !revoked[labelId].includes(id)
      )
    }
  }
}

export function updateLabelStatistics(
  state: ExtraInfoState,
  threads: $ReadOnlyArray<ViewChangedThread>
) {
  const increase = input => (input || 0) + 1
  const decrease = input => Math.max(0, (input || 0) - 1)

  let [total, unread] = [{ ...state.total }, { ...state.unread }]

  for (let { prevViewLabels, currViewLabels } of threads) {
    const removed = difference(prevViewLabels, currViewLabels)
    const added = difference(currViewLabels, prevViewLabels)
    const unchanged = intersection(prevViewLabels, currViewLabels)

    const prevUnreadIncluded = prevViewLabels.includes(labelNames.rawUnread)
    const currUnreadIncluded = currViewLabels.includes(labelNames.rawUnread)
    const isUnreadRemoved = removed.includes(labelNames.rawUnread)
    const isUnreadAdded = added.includes(labelNames.rawUnread)

    for (let labelId of unchanged) {
      if (isUnreadAdded) {
        unread[labelId] = increase(unread[labelId])
      } else if (isUnreadRemoved) {
        unread[labelId] = decrease(unread[labelId])
      }
    }

    for (let labelId of removed) {
      total[labelId] = decrease(total[labelId])
      if (prevUnreadIncluded) {
        unread[labelId] = decrease(unread[labelId])
      }
    }

    for (let labelId of added) {
      total[labelId] = increase(total[labelId])
      if (currUnreadIncluded) {
        unread[labelId] = increase(unread[labelId])
      }
    }
  }

  return {
    total,
    unread,
  }
}

/**
 * Verify the message in history is existed or not
 *
 * @public
 * @param history {Object} - History from backend
 * @param labels {Object} - Labels state with retrofit
 * @param validateRetrofit {boolean} - Validate retrofit label or not
 * @returns {Boolean}
 */
export function isValidatedHistory(history: { +messages: MessageSnapshot }) {
  const {
    messages: { id, threadId, labelIds },
  } = history

  return !(isNil(id) || isNil(threadId) || !Array.isArray(labelIds))
}

/**
 * Returns whether the message is from the current active account
 *
 * @param {Object} history
 * @param {Object} labels
 * @returns {boolean}
 */
export function isFromCurrentAccount(
  labelIds: $ReadOnlyArray<string>,
  labelState: LabelState
) {
  if (!Array.isArray(labelIds) || labelIds.length === 0) {
    return false
  }

  const isIncludedAll = labelNames.allAccounts in labelState
  const isIncludeRetrofits = !(labelNames.onmail in labelState)

  const retrofitLabels = labelIds
    .filter(id => labelState[id])
    .filter(id => labelState[id].type === labelTypes.RETROFIT)

  const isDraft = labelIds.includes(labelNames.draft)

  if (!isIncludedAll && !isDraft) {
    if (isIncludeRetrofits && retrofitLabels.length === 0) {
      return false
    } else if (!isIncludeRetrofits && retrofitLabels.length > 0) {
      return false
    }
  }

  return true
}

export function getThreadLabels(
  messageLabels: $ReadOnlyArray<$ReadOnlyArray<string>>
): $ReadOnlyArray<string> {
  let normalLabels: string[] = []
  let inBreakLabels: string[] = []

  for (let labels of messageLabels) {
    // Filter out the messages that contain inbox break label
    if (labels.find(item => item === labelNames.inboxStaged)) {
      inBreakLabels = [...inBreakLabels, ...labels]
    } else {
      normalLabels = [...normalLabels, ...labels]
    }
  }

  // Two cases here
  // 1. If the thread contains the messages not received in break time,
  //    we will build the index only according the labels from them
  // 2. If the thread only contains the messages received in break time,
  //    we will use the those messages to build index
  //
  if (normalLabels.length === 0) {
    return [...new Set(inBreakLabels)]
  } else {
    return [...new Set(normalLabels)]
  }
}
