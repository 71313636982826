// @flow
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from 'core/search/actions'
import * as selectors from 'core/search/selectors'

import MoreInTips from '@edison/webmail-ui/components/Search/MoreInTips'
import Collapse from '@material-ui/core/Collapse'
import { hiddenSearchPrefixes, labelNames } from 'utils/constants'

import type { Dispatch } from 'types/redux'

const getSearchQuery = selectors.getSearchQuery()
const getSearchCondition = selectors.getSearchCondition()

const View = () => {
  const dispatch: Dispatch = useDispatch()
  const query = useSelector(getSearchQuery)
  const moreInFlags = useSelector(selectors.getSearchMoreInFlags)
  const condition = useSelector(getSearchCondition)
  const [showTips, setTips] = useState(true)

  useEffect(() => {
    setTips(query.trim().length > 0)
  }, [moreInFlags])

  function handleOnClick() {
    let matchLabel = {}
    if (moreInFlags[labelNames.spam]) {
      matchLabel = hiddenSearchPrefixes.spam
    } else if (moreInFlags[labelNames.trash]) {
      matchLabel = hiddenSearchPrefixes.trash
    }

    if (!isEmpty(matchLabel)) {
      dispatch(
        actions.setSelectedLabels({
          name: matchLabel.label,
          id: labelNames[matchLabel.label.toLowerCase()],
        })
      )
      dispatch(actions.searchThreads(query, {}, condition))
    }
  }

  const isShow =
    showTips && (moreInFlags[labelNames.spam] || moreInFlags[labelNames.trash])

  return isShow ? (
    <Collapse in={isShow}>
      <MoreInTips onClick={handleOnClick} onClose={() => setTips(false)} />
    </Collapse>
  ) : null
}

View.displayName = 'MoreInTips'

export default View
