// @flow
import React, { useMemo, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router-dom'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'

import { useShowCompose } from 'hooks/useHandleDetailModal'
import { routePaths, labelNames, displayTitles } from 'utils/constants'
import { withRetrofitSyncProgress } from 'screens/App'

import Search from '@material-ui/icons/Search'
import Create from '@material-ui/icons/Create'
import Layout from '@edison/webmail-ui/components/Layout'
import Header, { HeaderItem } from '@edison/webmail-ui/components/Header'
import HeaderAction from '@edison/webmail-ui/components/HeaderAction'
import ThreadActionBar from 'common/ThreadActionBar'
import Banner from 'common/Banner'
import Cards from 'screens/Cards'
import InboxZero from 'screens/InboxZero'
import _Navigation from 'screens/Navigation'
import ThreadList from 'screens/ThreadList'
import SearchPage from 'screens/Search'
import Contacts from 'screens/Contacts'
import Attachments from 'screens/Attachments'
import ThreadFeedView from 'screens/ThreadFeedView'
import InboxHelmet from 'screens/Helmets/InboxHelmet'
import AttachmentBox from './components/AttachmentBox'
import AccountMenu from './components/AccountMenu'
import InboxHeader from './components/InboxHeader'

import { useCardListCondition, useInboxZeroConditon } from 'core/hooks'
import { useOrderId } from 'core/auth/hooks'
import { useLabelMeta, useRouteLabel } from 'core/labels/hooks'
import { useCurrentBreak } from 'core/inbox-break/hooks'
import { usePreviewableSplits } from 'core/split-inboxes/hooks'
import { fetchBreakStatus } from 'core/inbox-break/actions'
import { fetchLabelStatistics } from 'core/metadata/actions'
import {
  setActiveThreadLabel,
  refreshThreads,
  resetSelectThread,
} from 'core/threads/actions'

import { ForceOnMailDarkTheme } from '@edison/webmail-ui/styles/theme'

import type { Node } from 'react'
import type { Dispatch } from 'types/redux'
import EmptyTrashBar from '../../common/EmptyTrashBar'

const FETCH_DEBOUNCE = 3000
const [debouncedFetchLabelUnread, debouncedFetchBreakStatus] = [
  fetchLabelStatistics,
  fetchBreakStatus,
].map(action =>
  debounce(action(), FETCH_DEBOUNCE, {
    leading: true,
  })
)

const Navigation = withRetrofitSyncProgress(_Navigation)
const Calendar = React.lazy(() =>
  import(
    /* webpackChunkName: "calendar" */ /* webpackPrefetch: true */ 'screens/Calendar'
  )
)

const Main = ({ header }: { header: Node }) => {
  const userId = useOrderId()
  const { label }: { label: ?string } = useParams()

  const previewableSplits = usePreviewableSplits()

  const currBreak = useCurrentBreak()

  useEffect(() => {
    if (currBreak.shouldShowFullscreenAlert) {
      currBreak.onShowFullscreenAlert()
    }
  }, [])

  if (isNil(label)) {
    return (
      <Redirect
        to={generatePath(routePaths.main, { userId, label: 'inbox' })}
      />
    )
  }

  return (
    <Switch>
      {previewableSplits.length && (
        <Route
          path={`${routePaths.splitInbox}(${previewableSplits.join('|')})`}
        >
          <InboxHelmet />
          <ThreadFeedView header={header} />
        </Route>
      )}
      <Route path={routePaths.search} component={SearchPage} />
      <Route path={routePaths.contacts} component={Contacts} />
      <Route path={routePaths.attachments} component={Attachments} />
      <Route path={routePaths.calendar} component={Calendar} />
      <Route>
        <InboxHelmet />
        <ThreadList header={header} />
      </Route>
    </Switch>
  )
}

export default () => {
  const userId = useOrderId()
  const history = useHistory()
  const location = useLocation()
  const dispatch: Dispatch = useDispatch()
  const { label: routeLabel }: { label: ?string } = useParams()
  const showCompose = useShowCompose()
  const { hasCard: hasCardList } = useCardListCondition()

  const isInboxZero = useInboxZeroConditon()

  const { label, displayName } = useLabelMeta(routeLabel || '')

  const search = useCallback(() => {
    history.push({
      ...location,
      pathname: generatePath(routePaths.search, { userId }),
    })
  }, [location, userId])

  const header = useMemo(() => {
    return (
      <ForceOnMailDarkTheme enable={isInboxZero}>
        <Header
          title={
            label === labelNames.inbox ? (
              <InboxHeader />
            ) : (
              <HeaderItem name={displayTitles[label] || displayName} />
            )
          }
          actions={
            <>
              <HeaderAction
                invert={isInboxZero}
                title="Compose"
                icon={<Create />}
                onClick={() => showCompose()}
              />
              <HeaderAction
                invert={isInboxZero}
                title="Search"
                icon={<Search />}
                onClick={search}
              />
              <AccountMenu />
            </>
          }
        />
        {hasCardList && <Cards />}
        <EmptyTrashBar />
      </ForceOnMailDarkTheme>
    )
  }, [isInboxZero, label, showCompose, hasCardList])

  const activeLabel = useRouteLabel()

  const dispatchFetchThreads = useCallback(() => {
    if (activeLabel) {
      return Promise.all([
        dispatch(debouncedFetchLabelUnread),
        dispatch(debouncedFetchBreakStatus),
        dispatch(refreshThreads(activeLabel)),
      ])
    }
  }, [activeLabel])

  useEffect(() => {
    if (activeLabel) {
      dispatch(setActiveThreadLabel({ labelId: activeLabel }))
      dispatchFetchThreads()
    } else {
      dispatch(resetSelectThread())
    }
  }, [activeLabel])

  return (
    <>
      <Layout
        inboxZero={isInboxZero}
        banner={<Banner />}
        left={<Navigation />}
        background={<InboxZero refresh={activeLabel} />}
      >
        <Main header={header} />
      </Layout>
      <AttachmentBox />
      <ThreadActionBar />
    </>
  )
}
