// @flow
import React from 'react'

import { useQuickAction } from 'core/settings/hooks'
import { useSenderAvatar, useSenderName } from 'core/threads/hooks'
import * as threadActions from '@edison/webmail-ui/components/ThreadListItem/thread-actions'

import AuthAvatar from 'common/AuthAvatar'
import QuickActionModal from '@edison/webmail-ui/components/QuickActionModal'

type Position = {
  x: number,
  y: number,
}

type Props = {
  threadId: string,
  position: Position,
  isOpen: boolean,
  onClose: () => void,
}

export default ({ threadId, position, isOpen, onClose }: Props) => {
  const avatar = useSenderAvatar(threadId)
  const from = useSenderName(threadId)
  const { onChange, value } = useQuickAction()

  return (
    <QuickActionModal
      isOpen={isOpen}
      selected={value || threadActions.archive.name}
      position={position}
      onClose={onClose}
      onChange={next => onChange(next)}
      avatar={<AuthAvatar src={avatar} alt={from.name} />}
    />
  )
}
