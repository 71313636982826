//@flow
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { ReduxColors } from './modal-redux-colors'
import type {
  CalendarACLRole,
  CalendarCalendarListResource,
  CalendarCalendarListResourceData,
  CalendarCalendarsResource,
  CalendarNotification,
  CalendarReminder,
} from '@edison/webmail-core/types/calendar'
import type { $MakeMaybe } from '@edison/webmail-core/utils/type'

export type CalendarInitType = {
  calendar: ?CalendarCalendarsResource,
  metaData: ?CalendarCalendarListResource,
  colors: ReduxColors,
}
export type ReduxCalendarUpdateData = {
  summary?: string,
  description?: string,
  timeZone?: string,
  location?: string,
} & $MakeMaybe<CalendarCalendarListResourceData>

export class ReduxCalendar {
  calendar: ?CalendarCalendarsResource
  metaData: ?CalendarCalendarListResource
  colors: ReduxColors

  constructor(data?: CalendarInitType) {
    this.calendar = data ? data.calendar : undefined
    this.metaData = data ? data.metaData : undefined
    this.colors = data ? data.colors : new ReduxColors()
  }

  get userScopedId() {
    if (this.metaData) {
      return this.metaData.id
    }
    return ''
  }

  get globalId() {
    if (this.calendar) {
      return this.calendar.id
    }
    return ''
  }

  get isPrimary() {
    if (this.metaData) {
      return !!this.metaData.primary
    }
    return false
  }

  get id() {
    return this.globalId || this.userScopedId
  }

  isCalendarById(globalOrUserScopedId: string) {
    return (
      globalOrUserScopedId.length > 0 &&
      (this.globalId === globalOrUserScopedId ||
        this.userScopedId === globalOrUserScopedId)
    )
  }

  isEmpty() {
    return !this.calendar || !this.metaData || this.colors.isEmpty()
  }

  hasWriteAccess() {
    return this.isOwner() || this.isWriter()
  }

  isWriter() {
    return (
      this.isPrimary || (this.metaData && this.metaData.accessRole === 'writer')
    )
  }

  isOwner() {
    return (
      this.isPrimary || (this.metaData && this.metaData.accessRole === 'owner')
    )
  }

  get timeZoneEditDisabled() {
    return !this.hasWriteAccess()
  }

  get timeZone() {
    if (this.metaData && this.metaData.timeZone) {
      return this.metaData.timeZone
    }
    if (this.calendar && this.calendar.timeZone) {
      return this.calendar.timeZone
    }
    return ''
  }

  set timeZone(str: string | void) {
    if (typeof str !== 'string') {
      str = ''
    }
    if (this.metaData) {
      this.metaData.timeZone = str
      this._updateMetaUpdated()
    }
    if (this.isWriter() && this.calendar) {
      this.calendar.timeZone = str
      this._updateCalendarUpdated()
    } else {
      throw new Error('Access Denied')
    }
  }

  get summary() {
    if (this.metaData) {
      if (this.metaData.summaryOverride) {
        return this.metaData.summaryOverride
      }
      if (this.metaData.summary) {
        return this.metaData.summary
      }
    }
    if (this.calendar && this.calendar.summary) {
      return this.calendar.summary
    }
    return ''
  }

  get summaryEditDisabled() {
    return !this.hasWriteAccess()
  }

  set summary(text?: string) {
    if (typeof text !== 'string') {
      text = ''
    }
    if (this.metaData) {
      this.metaData.summaryOverride = text
      this._updateMetaUpdated()
    }
    if (this.isWriter() && this.calendar) {
      this.calendar.summary = text
      this._updateCalendarUpdated()
    }
  }

  get descriptionEditDisabled() {
    return !this.hasWriteAccess()
  }

  get description() {
    if (this.metaData && this.metaData.description) {
      return this.metaData.description
    }
    if (this.calendar && this.calendar.description) {
      return this.calendar.description
    }
    return ''
  }

  set description(text: string | void) {
    if (typeof text !== 'string') {
      text = ''
    }
    if (this.metaData) {
      this.metaData.description = text
      this._updateMetaUpdated()
    }
    if (this.isWriter() && this.calendar) {
      this.calendar.description = text
      this._updateCalendarUpdated()
    } else {
      throw new Error('Access Denied')
    }
  }

  get locationEditDisabled() {
    return !this.hasWriteAccess()
  }

  get location() {
    if (this.metaData && this.metaData.location) {
      return this.metaData.location
    }
    if (this.calendar && this.calendar.location) {
      return this.calendar.location
    }
    return ''
  }

  set location(text: string | void) {
    if (typeof text !== 'string') {
      text = ''
    }
    if (this.metaData) {
      this.metaData.location = text
      this._updateMetaUpdated()
    }
    if (this.isWriter() && this.calendar) {
      this.calendar.location = text
      this._updateCalendarUpdated()
    } else {
      throw new Error('Access Denied')
    }
  }

  get deleted() {
    if (this.metaData) {
      return this.metaData.deleted
    }
    return false
  }

  get accessRole(): CalendarACLRole {
    if (this.metaData) {
      return this.metaData.accessRole
    }
    return 'none'
  }

  get selected() {
    if (this.metaData) {
      return !!this.metaData.selected
    }
    return false
  }

  get hidden() {
    if (this.metaData) {
      return !!this.metaData.hidden
    }
    return false
  }

  get notificationSettings(): { notifications: CalendarNotification[] } {
    if (this.metaData) {
      return this.metaData.notificationSettings
    }
    return { notifications: [] }
  }

  get defaultReminders(): CalendarReminder[] {
    if (this.metaData) {
      return this.metaData.defaultReminders
    }
    return []
  }

  getBackgroundColor(isLight: boolean = true) {
    return this._getBackgroundColor(this.colors, isLight)
  }

  // get foregroundColor() {
  //   return this.getForegroundColor(this.colors)
  // }

  getEventDefaultBackgroundColor(isLight: boolean = true) {
    if (this.metaData && this.metaData.colorId && this.colors) {
      return this.colors.getEventColorById(this.metaData.colorId, isLight)
    }
    return ''
  }
  _getBackgroundColor(colors: ReduxColors, isLight: boolean = true) {
    if (this.metaData) {
      if (this.metaData.backgroundColor) {
        return this.metaData.backgroundColor
      }
      if (this.metaData.colorId) {
        return colors.getCalendarColorById(this.metaData.colorId, isLight)
      }
    }
  }
  get updated() {
    const calendarUpdated = this.calendar
      ? this.calendar.updated
        ? this.calendar.updated
        : 0
      : 0
    const calendarMetaUpdated = this.metaData
      ? this.metaData.updated
        ? this.metaData.updated
        : 0
      : 0
    if (calendarMetaUpdated > calendarUpdated) {
      return calendarMetaUpdated
    }
    return calendarUpdated
  }
  _updateMetaUpdated() {
    if (this.metaData) {
      this.metaData.updated = Date.now()
    }
  }
  _updateCalendarUpdated() {
    if (this.calendar) {
      this.calendar.updated = Date.now()
    }
  }

  toJSON(): CalendarInitType {
    return {
      calendar: cloneDeep(this.calendar),
      metaData: cloneDeep(this.metaData),
      colors: this.colors.toJSON(),
    }
  }

  updateAndSortByAPI(
    data: ReduxCalendarUpdateData
  ): {
    calendarAPI: { dataChanged: boolean },
    calendarListAPI: { dataChanged: boolean },
  } {
    const calendarAPI = {}
    const calendarListAPI = {}
    const keys = Object.keys(data)
    for (const key of keys) {
      if (key === 'summary') {
        try {
          this.summary = data.summary
          calendarListAPI['summary'] = data.summary
          if (this.isWriter()) {
            calendarAPI['summary'] = data.summary
          }
        } catch (e) {
          console.log(e)
        }
      } else if (key === 'description') {
        try {
          this.description = data.description
          if (this.isOwner()) {
            calendarAPI['description'] = data.description
          }
        } catch (e) {
          console.log(e)
        }
      } else if (key === 'location') {
        try {
          this.location = data.location
          if (this.isOwner()) {
            calendarAPI['location'] = data.location
          }
        } catch (e) {
          console.log(e)
        }
      } else if (key === 'timeZone') {
        try {
          this.timeZone = data.timeZone
          if (this.isOwner()) {
            calendarAPI['timeZone'] = data.timeZone
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        if (this.metaData) {
          this.metaData[key] = data[key]
          this._updateMetaUpdated()
        }
        calendarListAPI[key] = data[key]
      }
    }
    return {
      calendarAPI: {
        ...(this.calendar || {}),
        ...calendarAPI,
        ...{ dataChanged: Object.keys(calendarAPI).length > 0 },
      },
      calendarListAPI: {
        ...(this.metaData || {}),
        ...calendarListAPI,
        ...{ dataChanged: Object.keys(calendarListAPI).length > 0 },
      },
    }
  }

  clone() {
    const data = this.toJSON()
    data.colors = new ReduxColors(data.colors)
    return new ReduxCalendar(data)
  }

  static isCalendarNew({
    oldData,
    newData,
  }: {
    oldData: ?CalendarCalendarsResource,
    newData: CalendarCalendarsResource,
  }) {
    return !isEqual(oldData, newData)
  }

  static isMetaDataNew({
    oldData,
    newData,
  }: {
    oldData: ?CalendarCalendarListResource,
    newData: CalendarCalendarListResource,
  }) {
    return !isEqual(oldData, newData)
  }

  static ifNewerReturnNewCalendar(
    oldSelf: ReduxCalendar,
    { calendar, metaData, colors }: CalendarInitType
  ) {
    if (!calendar || !metaData) {
      return
    }
    if (
      oldSelf.isEmpty() ||
      ReduxCalendar.isCalendarNew({
        oldData: oldSelf.calendar,
        newData: calendar,
      }) ||
      ReduxCalendar.isMetaDataNew({
        oldData: oldSelf.metaData,
        newData: metaData,
      })
    ) {
      return new ReduxCalendar({ calendar, metaData, colors })
    }
  }
}
