// @flow
import { useAuthCallback } from 'core/retrofit/hooks'

/**
 * Handles all OAuth callbacks and redirects.
 */
const AuthCallback = () => {
  useAuthCallback()

  // Complete redirect handled inside hooks
  return null
}

export default AuthCallback
