// @flow
import { createSelector } from 'reselect'
import { selectCalendarState } from './selector-common'
import type { Selector } from 'types/state'
import type { CalendarState } from './types'
import type { SettingsState } from './types-settings'
import type {
  CalendarSettingsResource,
  CalendarSettingKeys,
} from '@edison/webmail-core/types/calendar'
import type { ReduxSettingsResult } from './modal-redux-settings-result'

export const getCalendarSettingsSelector: Selector<SettingsState> = createSelector(
  selectCalendarState,
  (state: CalendarState) => state.settings
)

export const getCalendarSettingByIdSelector = (
  settingId: CalendarSettingKeys
): Selector<CalendarSettingsResource | void> =>
  createSelector(
    getCalendarSettingsSelector,
    (settings: ReduxSettingsResult) => {
      return settings.getSettingById(settingId)
    }
  )
