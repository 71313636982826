// @flow
import React from 'react'
import {
  BlockDomainModal,
  UnblockDomailModal,
  UnblockSenderModal,
} from '@edison/webmail-ui/components/DomainModal'
import { useDispatch } from 'react-redux'
import {
  unblockDomain,
  blockDomain,
  unblockSender,
} from 'core/contacts/actions'
type Props = {
  isOpen: boolean,
  email: $ReadOnlyArray<string>,
  domain: string,
  ids: string[],
}
export default ({ isOpen, toggle, email, handleOnConfirm, ...rest }: Props) => {
  const dispatch: Dispatch = useDispatch()
  function onConfirm() {
    toggle()
    dispatch(blockDomain(email))
    handleOnConfirm && handleOnConfirm()
  }
  return (
    <BlockDomainModal
      isOpen={isOpen}
      email={email}
      onClose={toggle}
      onConfirm={onConfirm}
      {...rest}
    />
  )
}

export function UnblockWithDomailModal({
  isOpen,
  toggle,
  email,
  handleOnConfirm,
  relatedContacts,
  domain,
  ids,
  ...rest
}: Props) {
  const dispatch: Dispatch = useDispatch()
  function onConfirm() {
    toggle()
    handleOnConfirm && handleOnConfirm()
    dispatch(unblockDomain(ids))
  }
  return (
    <UnblockDomailModal
      isOpen={isOpen}
      email={email}
      domain={domain}
      relatedContacts={relatedContacts}
      onClose={toggle}
      onConfirm={onConfirm}
      {...rest}
    />
  )
}

export function UnblockSenderWithDomainModal({
  isOpen,
  toggle,
  email,
  handleOnConfirm,
  domain,
  ...rest
}: Props) {
  const dispatch: Dispatch = useDispatch()
  function onConfirm() {
    toggle()
    handleOnConfirm && handleOnConfirm()
    dispatch(unblockSender(email))
  }
  return (
    <UnblockSenderModal
      isOpen={isOpen}
      email={email}
      domain={domain}
      onClose={toggle}
      onConfirm={onConfirm}
      {...rest}
    />
  )
}
