// @flow
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'

import { getPreviewProps } from 'core/large-attachments/helpers'
import { fetchContactAttachments } from 'core/contacts/actions'
import { getImageAttachmentsById } from 'core/contacts/selectors'
import { usePreviewModal } from 'core/previews/hooks'

import GridPhotos from '@edison/webmail-ui/components/ContactDetail/PhotosView'

type Props = {
  id: string,
  idType?: 'ID' | 'EMAIL',
}

export default ({ id, idType = 'ID' }: Props) => {
  const dispatch = useDispatch()
  const previews = usePreviewModal()
  const attachments = useSelector(getImageAttachmentsById(id))

  useEffect(() => {
    if (!isNil(id)) {
      dispatch(fetchContactAttachments(id, idType, 'image'))
    }
  }, [id])

  const images = useMemo(() => {
    return attachments.map((each, index) => {
      // $FlowFixMe
      const { name, image } = getPreviewProps(dispatch, each)
      const onClick = () => {
        previews.showModal({ attachments, index })
      }
      return { name, image, onClick }
    })
  }, [attachments])

  return <GridPhotos cellHeight={100} images={images} />
}
