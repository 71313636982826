// @flow
import type { StackedProps } from '@edison/webmail-ui/components/Cards/Card'
import Card, {
  CardAction,
  CardSubject,
  INITIAL_CARD_PROPS,
} from '@edison/webmail-ui/components/Cards/Card'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import { useModal } from 'common/modals'
import { feedbackSuggestedBlock, showBlockPrompt } from 'core/contacts/actions'
import { isFeedbackSuggestedBlockLoading } from 'core/contacts/selectors'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { Dispatch } from 'types/redux'
import { UnicodeString } from 'utils'
import { modalTypes, suggestedBlockStatus } from 'utils/constants'

type Props = {
  id: string,
  name: string,
  email: string,
} & StackedProps

export default ({ id, name, email, ...stackedProps }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const isLoading = useSelector(isFeedbackSuggestedBlockLoading)
  const contactsGalleryModal = useModal(modalTypes.contactsGallery)
  const trimedName = UnicodeString.truncate(name.trim() || email, {
    length: 16,
  })

  const actions = useMemo(
    () => [
      <CardAction
        disabled={isLoading}
        color="secondary"
        onClick={() =>
          dispatch(feedbackSuggestedBlock(id, suggestedBlockStatus.dismissed))
        }
      >
        {t('button.ignore')}
      </CardAction>,
      <CardAction
        disabled={isLoading}
        onClick={() => dispatch(showBlockPrompt(email, true))}
      >
        {t('button.block')}
      </CardAction>,
    ],
    [isLoading, id, email]
  )

  function handleOnReview() {
    contactsGalleryModal.showModal({
      suggestBlockContact: {
        id,
        email,
      },
      cardView: 'suggestedBlock',
    })
  }

  const props = useMemo(
    () => ({
      ...INITIAL_CARD_PROPS,
      actions,
      placeholder: null,
      onClick: handleOnReview,
      subject: (
        <CardSubject size="small">
          {t('contacts.suggestedBlock.description', {
            name: trimedName,
          })}
        </CardSubject>
      ),
      info: {
        avatar: <AuthEmailAvatar email={email} />,
        subtitle: email,
        title: t('contacts.suggestedBlock.title'),
      },
    }),
    [id, name, email, actions]
  )

  return <Card {...stackedProps} {...props} />
}
