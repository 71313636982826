// @flow
import i18next from 'i18next'
import set from 'lodash/set'
import isNil from 'lodash/isNil'
import { RequestError } from '@edison/requests'
import * as authClient from '@edison/webmail-core/api/auth'
import * as client from '@edison/webmail-core/api/settings'
import * as mfaClient from '@edison/webmail-core/api/mfa'
import { base64ToFile } from '@edison/webmail-core/utils/file'
import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import { getAuth } from 'core/auth/selectors'
import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'

import { createAction } from 'utils/redux'
import { authStatus } from 'utils/constants'
import { getMfaSelector } from './selectors'
import moment from 'moment'

import type {
  SettingsRequest,
  SettingsSuccess,
  SettingsFailure,
  SettingsPatchRequest,
  SettingsPatchSuccess,
  SettingsPatchFailure,
  SettingsUpdateRequest,
  SettingsUpdateSuccess,
  SettingsUpdateFailure,
  SettingsResetRequest,
  SettingsResetSuccess,
  SettingsResetFailure,
  SettingsUploadAvatarRequest,
  SettingsUploadAvatarSuccess,
  SettingsUploadAvatarFailure,
  FetchForwardEmailsRequest,
  FetchForwardEmailsSuccess,
  FetchForwardEmailsFailure,
  AddForwardEmailRequest,
  AddForwardEmailSuccess,
  AddForwardEmailFailure,
  DeleteForwardEmailRequest,
  DeleteForwardEmailSuccess,
  DeleteForwardEmailFailure,
  UpdateRecoveryEmailRequest,
  UpdateRecoveryEmailSuccess,
  UpdateRecoveryEmailFailure,
  FetchMfaRequest,
  FetchMfaSuccess,
  FetchMfaFailure,
  UpdateMfa,
  ResendRecoveryVerifyEmailRequest,
  ResendRecoveryVerifyEmailSuccess,
  ResendRecoveryVerifyEmailFailure,
  UploadInboxZeroBackgroundRequest,
  UploadInboxZeroBackgroundSuccess,
  UploadInboxZeroBackgroundFailure,
  DeleteInboxZeroBackgroundRequest,
  DeleteInboxZeroBackgroundSuccess,
  DeleteInboxZeroBackgroundFailure,
  RecoveryMethodUpdateRequest,
  RecoveryMethodUpdateSuccess,
  RecoveryMethodUpdateFailure,
  RecoveryMethodRemoveRequest,
  RecoveryMethodRemoveSuccess,
  RecoveryMethodRemoveFailure,
} from './types'
import type { ExtraAuth } from '@edison/webmail-core/types'
import type { Settings } from '@edison/webmail-core/types/settings'
import type { RecoveryMethod } from '@edison/webmail-ui/utils/constants'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchSettingsActions: {
  request: ActionCreator<SettingsRequest>,
  success: ActionCreator<SettingsSuccess>,
  failure: ActionCreator<SettingsFailure>,
} = {
  request: createAction('SETTINGS_REQUEST'),
  success: createAction('SETTINGS_SUCCESS'),
  failure: createAction('SETTINGS_FAILURE'),
}

/**
 * Fetch data for all settings configuration from backend API
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchSettings(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchSettingsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchSettingsActions.request())
      const res = await client.all({ auth })
      dispatch(fetchSettingsActions.success(res.result))
    } catch (e) {
      dispatch(fetchSettingsActions.failure({ message: e.message }))
    }
  }
}

export const patchSettingsActions: {
  request: ActionCreator<SettingsPatchRequest>,
  success: ActionCreator<SettingsPatchSuccess>,
  failure: ActionCreator<SettingsPatchFailure>,
} = {
  request: createAction('SETTINGS_PATCH_REQUEST'),
  success: createAction('SETTINGS_PATCH_SUCCESS'),
  failure: createAction('SETTINGS_PATCH_FAILURE'),
}

export function patchSettings(
  toPatch: $ReadOnlyArray<{ key: string, value: any }>
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(patchSettingsActions.failure({ message: 'User not logged in' }))
      return false
    }

    try {
      if (toPatch.length === 0) {
        return true
      }

      let partialSettings = {}

      for (let { key, value } of toPatch) {
        set(partialSettings, key, value)
      }
      dispatch(patchSettingsActions.request(toPatch))
      await client.patch(partialSettings, { auth })
      dispatch(patchSettingsActions.success(toPatch))

      return true
    } catch (e) {
      dispatch(patchSettingsActions.failure({ message: e.message }))
      dispatch(
        showNotification(
          i18next.t('settings.update.error'),
          toastVariants.error
        )
      )
      return false
    }
  }
}
export const updateSettingsActions: {
  request: ActionCreator<SettingsUpdateRequest>,
  success: ActionCreator<SettingsUpdateSuccess>,
  failure: ActionCreator<SettingsUpdateFailure>,
} = {
  request: createAction('SETTINGS_UPDATE_REQUEST'),
  success: createAction('SETTINGS_UPDATE_SUCCESS'),
  failure: createAction('SETTINGS_UPDATE_FAILURE'),
}

/**
 * Update by given settings
 *
 * @public
 * @param {Settings} settings - Settings to update
 * @returns {ThunkAction}
 */
export function updateSettings(settings: Settings): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchSettingsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(updateSettingsActions.request())
      const res = await client.update(settings, { auth })
      dispatch(updateSettingsActions.success(res.result))
    } catch (e) {
      dispatch(fetchSettingsActions.failure({ message: e.message }))
    }
  }
}

export const resetSettingsActions: {
  request: ActionCreator<SettingsResetRequest>,
  success: ActionCreator<SettingsResetSuccess>,
  failure: ActionCreator<SettingsResetFailure>,
} = {
  request: createAction('SETTINGS_RESET_REQUEST'),
  success: createAction('SETTINGS_RESET_SUCCESS'),
  failure: createAction('SETTINGS_RESET_FAILURE'),
}

/**
 * Reset the whole settings
 *
 * @public
 * @returns {ThunkAction}
 */
export function resetSettings(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchSettingsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(resetSettingsActions.request())
      const res = await client.reset({ auth })
      dispatch(resetSettingsActions.success(res.result))
    } catch (e) {
      dispatch(resetSettingsActions.failure({ message: e.message }))
    }
  }
}

export const uploadAvatarActions: {
  request: ActionCreator<SettingsUploadAvatarRequest>,
  success: ActionCreator<SettingsUploadAvatarSuccess>,
  failure: ActionCreator<SettingsUploadAvatarFailure>,
} = {
  request: createAction('SETTINGS_UPLOAD_AVATAR_REQUEST'),
  success: createAction('SETTINGS_UPLOAD_AVATAR_SUCCESS'),
  failure: createAction('SETTINGS_UPLOAD_AVATAR_FAILURE'),
}

export function uploadAvatar(attachment: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(uploadAvatarActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(uploadAvatarActions.request())
      const file = await base64ToFile(attachment, `${auth.user}-avatar.jpg`)
      const res = await client.uploadAvatar(file, { auth })
      dispatch(uploadAvatarActions.success())

      return res
    } catch (e) {
      dispatch(uploadAvatarActions.failure({ message: e.message }))
    }
  }
}

export const fetchForwardEmailsActions: {
  request: ActionCreator<FetchForwardEmailsRequest>,
  success: ActionCreator<FetchForwardEmailsSuccess>,
  failure: ActionCreator<FetchForwardEmailsFailure>,
} = {
  request: createAction('FORWARD_EMAILS_REQUEST'),
  success: createAction('FORWARD_EMAILS_SUCCESS'),
  failure: createAction('FORWARD_EMAILS_FAILURE'),
}

export function fetchForwardEmails(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchForwardEmailsActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(fetchForwardEmailsActions.request())
      const res = await client.listForwardEmail({ auth })
      dispatch(fetchForwardEmailsActions.success(res.result))
    } catch (e) {
      dispatch(fetchForwardEmailsActions.failure({ message: e.message }))
    }
  }
}

export const addForwardEmailActions: {
  request: ActionCreator<AddForwardEmailRequest>,
  success: ActionCreator<AddForwardEmailSuccess>,
  failure: ActionCreator<AddForwardEmailFailure>,
} = {
  request: createAction('ADD_FORWARD_EMAIL_REQUEST'),
  success: createAction('ADD_FORWARD_EMAIL_SUCCESS'),
  failure: createAction('ADD_FORWARD_EMAIL_FAILURE'),
}

export function addForwardEmail(forwardEmail: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        addForwardEmailActions.failure({ message: 'User not logged in' })
      )
      return false
    }

    try {
      dispatch(addForwardEmailActions.request())
      const email = forwardEmail.trim().toLowerCase()
      await client.addForwardEmail({ email }, { auth })
      dispatch(
        addForwardEmailActions.success({
          email,
          verified: false,
        })
      )
      return true
    } catch (e) {
      dispatch(addForwardEmailActions.failure({ message: e.message }))
      return false
    }
  }
}

export const deleteForwardEmailActions: {
  request: ActionCreator<DeleteForwardEmailRequest>,
  success: ActionCreator<DeleteForwardEmailSuccess>,
  failure: ActionCreator<DeleteForwardEmailFailure>,
} = {
  request: createAction('DELETE_FORWARD_EMAIL_REQUEST'),
  success: createAction('DELETE_FORWARD_EMAIL_SUCCESS'),
  failure: createAction('DELETE_FORWARD_EMAIL_FAILURE'),
}

export function deleteForwardEmail(forwardEmail: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        addForwardEmailActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(deleteForwardEmailActions.request())
      const email = forwardEmail.trim().toLowerCase()
      await client.deleteForwardEmail({ email }, { auth })
      dispatch(deleteForwardEmailActions.success(email))
    } catch (e) {
      dispatch(deleteForwardEmailActions.failure({ message: e.message }))
    }
  }
}

export const updateRecoveryEmailActions: {
  request: ActionCreator<UpdateRecoveryEmailRequest>,
  success: ActionCreator<UpdateRecoveryEmailSuccess>,
  failure: ActionCreator<UpdateRecoveryEmailFailure>,
} = {
  request: createAction('UPDATE_RECOVERY_EMAIL_REQUEST'),
  success: createAction('UPDATE_RECOVERY_EMAIL_SUCCESS'),
  failure: createAction('UPDATE_RECOVERY_EMAIL_FAILURE'),
}

export function updateRecoveryEmail(
  emailAddress: string,
  { auth }: { auth: ExtraAuth }
): ThunkAction {
  return async (dispatch, getState) => {
    let res = {
      success: false,
      code: 0,
    }

    if (isNil(auth)) {
      dispatch(
        updateRecoveryEmailActions.failure({ message: 'User not logged in' })
      )
      return {
        ...res,
        code: authStatus.INVALID,
      }
    }

    try {
      dispatch(updateRecoveryEmailActions.request())
      const email = emailAddress.trim().toLowerCase()
      await client.updateRecoveryEmail({ email }, { auth })
      dispatch(updateRecoveryEmailActions.success({ email, verified: false }))

      return {
        ...res,
        success: true,
      }
    } catch (e) {
      dispatch(updateRecoveryEmailActions.failure({ message: e.message }))
      if (e.status === authStatus.INVALID) {
        dispatch(
          showNotification(
            i18next.t('settings.passwordAuth.expired'),
            toastVariants.error
          )
        )
        return { ...res, code: authStatus.INVALID }
      }

      dispatch(
        showNotification(
          i18next.t('settings.recovery.email.update.failure'),
          toastVariants.error
        )
      )
      return res
    }
  }
}

export const resendRecoveryVerifyEmailActions: {
  request: ActionCreator<ResendRecoveryVerifyEmailRequest>,
  success: ActionCreator<ResendRecoveryVerifyEmailSuccess>,
  failure: ActionCreator<ResendRecoveryVerifyEmailFailure>,
} = {
  request: createAction('RESEND_RECOVERY_VERIFY_EMAIL_REQUEST'),
  success: createAction('RESEND_RECOVERY_VERIFY_EMAIL_SUCCESS'),
  failure: createAction('RESEND_RECOVERY_VERIFY_EMAIL_FAILURE'),
}

export function resendReocveryVerifyEmail(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        resendRecoveryVerifyEmailActions.failure({
          message: 'User not logged in',
        })
      )
    }

    try {
      dispatch(resendRecoveryVerifyEmailActions.request())
      await client.resendRecoveryVerifyEmail({ auth })
      dispatch(resendRecoveryVerifyEmailActions.success())

      return true
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.recovery.email.resend.failure'),
          toastVariants.error
        )
      )

      dispatch(resendRecoveryVerifyEmailActions.failure({ message: e.message }))

      return false
    }
  }
}

export const fetchMfaActions: {
  request: ActionCreator<FetchMfaRequest>,
  success: ActionCreator<FetchMfaSuccess>,
  failure: ActionCreator<FetchMfaFailure>,
} = {
  request: createAction('FETCH_MFA_REQEUST'),
  success: createAction('FETCH_MFA_SUCCESS'),
  failure: createAction('FETCH_MFA_FAILURE'),
}

export function fetchMfa(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchMfaActions.failure({ message: 'User not logged in' }))
      return false
    }

    try {
      dispatch(fetchMfaActions.request())

      const res = await mfaClient.fetchMfa(auth)
      dispatch(fetchMfaActions.success(res.result))
    } catch (e) {
      dispatch(fetchMfaActions.failure({ message: e.message }))
    }
  }
}

export function fetchExtraAuth(password): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    return authClient.passwordAuth(password, { auth })
  }
}
export function updateMfaRequest(enable, password): ThunkAction {
  return (dispatch, getState) => {
    const auth = getAuth()(getState())
    return mfaClient.updateMfa(enable, password, auth)
  }
}
export const updateMfa: ActionCreator<UpdateMfa> = createAction('UPDATE_MFA')

export function generateRecovery(extraAuth): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const mfa = getMfaSelector(state)
    const auth = getAuth()(getState())
    const res = await mfaClient.fetchRecovery(extraAuth)
    const now = moment().unix()
    dispatch(
      updateMfa({
        ...mfa,
        hasActiveRecoveryCodes: true,
        codeGenerationTime: now,
      })
    )
    return {
      codes: res.result.codes,
      email: auth.user,
      time: now,
    }
  }
}

export function deleteRecovery(extraAuth): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const mfa = getMfaSelector(state)

    await mfaClient.deleteRecovery(extraAuth)

    dispatch(updateMfa({ ...mfa, hasActiveRecoveryCodes: false }))
  }
}

export const uploadInboxZeroBackgroundActions: {
  request: ActionCreator<UploadInboxZeroBackgroundRequest>,
  success: ActionCreator<UploadInboxZeroBackgroundSuccess>,
  failure: ActionCreator<UploadInboxZeroBackgroundFailure>,
} = {
  request: createAction('UPLOAD_INBOX_ZERO_BACKGROUND_REQUEST'),
  success: createAction('UPLOAD_INBOX_ZERO_BACKGROUND_SUCCESS'),
  failure: createAction('UPLOAD_INBOX_ZERO_BACKGROUND_FAILURE'),
}

export function uploadInboxZeroBackground(file: File): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        uploadInboxZeroBackgroundActions.failure({
          message: 'User not logged in',
        })
      )
      return
    }

    try {
      dispatch(uploadInboxZeroBackgroundActions.request())

      const res = await client.uploadInboxZeroBackground(file, { auth })

      const { backgroundImageMode, backgroundImageFilename } = res.result

      dispatch(
        uploadInboxZeroBackgroundActions.success({
          backgroundImageMode,
          backgroundImageFilename,
        })
      )
    } catch (e) {
      const ext =
        '.' +
        file.name
          .split('.')
          .pop()
          .toLowerCase()

      if (e.status === 400) {
        dispatch(
          showNotification(
            i18next.t('settings.general.uploadBackgroundImage.error.filetype', {
              extension: ext,
            }),
            toastVariants.error
          )
        )
      } else {
        dispatch(
          showNotification(
            i18next.t('settings.general.uploadBackgroundImage.error.failed'),
            toastVariants.error
          )
        )
      }

      dispatch(uploadInboxZeroBackgroundActions.failure({ message: e.message }))
    }
  }
}

export const deleteInboxZeroBackgroundActions: {
  request: ActionCreator<DeleteInboxZeroBackgroundRequest>,
  success: ActionCreator<DeleteInboxZeroBackgroundSuccess>,
  failure: ActionCreator<DeleteInboxZeroBackgroundFailure>,
} = {
  request: createAction('DELETE_INBOX_ZERO_BACKGROUND_REQUEST'),
  success: createAction('DELETE_INBOX_ZERO_BACKGROUND_SUCCESS'),
  failure: createAction('DELETE_INBOX_ZERO_BACKGROUND_FAILURE'),
}

export function deleteInboxZeroBackground(name: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        deleteInboxZeroBackgroundActions.failure({
          message: 'User not logged in',
        })
      )
      return
    }

    try {
      dispatch(deleteInboxZeroBackgroundActions.request())

      await client.deleteInboxZeroBackground(name, { auth })

      dispatch(deleteInboxZeroBackgroundActions.success())
    } catch (e) {
      dispatch(deleteInboxZeroBackgroundActions.failure({ message: e.message }))
    }
  }
}

export const updateRecoveryMethodActions: {
  request: ActionCreator<RecoveryMethodUpdateRequest>,
  success: ActionCreator<RecoveryMethodUpdateSuccess>,
  failure: ActionCreator<RecoveryMethodUpdateFailure>,
} = {
  request: createAction('RECOVERY_METHOD_UPDATE_REQUEST'),
  success: createAction('RECOVERY_METHOD_UPDATE_SUCCESS'),
  failure: createAction('RECOVERY_METHOD_UPDATE_FAILURE'),
}

export function updateRecoveryMethod(
  method: RecoveryMethod,
  verifiedToken: string,
  { auth }: { auth: ExtraAuth }
): ThunkAction {
  return async (dispatch, getState) => {
    if (auth === null) {
      dispatch(
        updateRecoveryMethod.failure({ message: 'Invalid password auth' })
      )
      return false
    }

    try {
      dispatch(updateRecoveryMethodActions.request())
      if (method.type === recoveryMethods.phone) {
        await client.updateRecoveryPhone(
          {
            phoneNumber: method.value,
          },
          { auth, token: verifiedToken }
        )
      } else if (method.type === recoveryMethods.email) {
        await client.updateRecoveryEmail(
          { email: method.value },
          { auth, token: verifiedToken }
        )
      } else {
        throw new Error('Invalid recovery method')
      }

      dispatch(updateRecoveryMethodActions.success(method))
      return true
    } catch (e) {
      dispatch(updateRecoveryMethodActions.failure({ message: e.message }))

      dispatch(
        showNotification(
          i18next.t('recoveryMethod.error.update.default'),
          toastVariants.error
        )
      )
      return false
    }
  }
}

export const removeRecoveryMethodActions: {
  request: ActionCreator<RecoveryMethodRemoveRequest>,
  success: ActionCreator<RecoveryMethodRemoveSuccess>,
  failure: ActionCreator<RecoveryMethodRemoveFailure>,
} = {
  request: createAction('RECOVERY_METHOD_REMOVE_REQUEST'),
  success: createAction('RECOVERY_METHOD_REMOVE_SUCCESS'),
  failure: createAction('RECOVERY_METHOD_REMOVE_FAILURE'),
}

export function removeRecoveryMethod(
  method: $PropertyType<RecoveryMethod, 'type'>,
  { auth }: { auth: ExtraAuth }
) {
  return async (dispatch, getState) => {
    if (auth === null) {
      dispatch(
        removeRecoveryMethodActions.failure({
          message: 'Invalid password auth',
        })
      )
    }

    try {
      dispatch(removeRecoveryMethodActions.request())

      if (method === recoveryMethods.phone) {
        await client.removeRecoveryPhone({ auth })
      } else if (method === recoveryMethods.email) {
        await client.removeRecoveryEmail({ auth })
      } else {
        throw new Error('Invalid recovery method')
      }

      dispatch(removeRecoveryMethodActions.success({ recoveryMethod: method }))
      return true
    } catch (e) {
      dispatch(removeRecoveryMethodActions.failure({ message: e.message }))

      if (e instanceof RequestError) {
        if (e.status === 406) {
          await dispatch(fetchSettings())
          // INFO: Can't remove the given method due to it's the only one recovery method
          // Don't need to show toast because there's warning message on the UI
          return false
        }
      }

      dispatch(
        showNotification(
          i18next.t('recoveryMethod.error.remove.default'),
          toastVariants.error
        )
      )

      return false
    }
  }
}
