// @flow
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { actions, selectors } from 'core/toasts'
import { useDispatch, useSelector } from 'react-redux'
import { useToast } from 'common/toasts'
import { toastTypes } from 'utils/constants'
import { reportException } from 'utils/logs'

import LoadingToastComponent from '@edison/webmail-ui/components/LoadingToast'
import BasicToast from '@edison/webmail-ui/components/BasicToast'
import Slide from '@material-ui/core/Slide'

import type { Dispatch } from 'types/redux'

const DEFAULT_TOAST_DURATION = 10 * 1000

type Props = {
  maxToasts?: number,
}

export default ({ maxToasts = 1 }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const notifications = useSelector(selectors.getNotificationToasts())

  const { hideToast } = useToast(toastTypes.notification)
  const hanldeClose = useCallback(
    key => (_, reason) => {
      if (reason === 'clickaway') return
      else hideToast(key)
    },
    []
  )

  // To report the unknown error for better debug
  const unknownErrorKeys = notifications
    .filter(item => item.props.message === t('toast.default.error'))
    .map(item => item.key)

  useEffect(() => {
    if (unknownErrorKeys.length > 0) {
      reportException(t('toast.default.error'), '')
    }
  }, [unknownErrorKeys.join('-')])

  if (notifications.filter(each => each.isOpen).length > maxToasts) {
    const oldest = notifications[0]
    hideToast(oldest.key)
  }

  return (
    <>
      {notifications.map((notification, index) => {
        const { key, isOpen } = notification
        const {
          message,
          variant,
          autoHideDuration = DEFAULT_TOAST_DURATION,
        } = notification.props
        return (
          <BasicToast
            key={key}
            open={isOpen}
            variant={variant}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={hanldeClose(key)}
            onExited={() => dispatch(actions.cleanHidden())}
            style={{
              transition: 'margin 125ms ease',
              margin: `${70 * index}px 0`,
            }}
            message={<span>{message}</span>}
            TransitionComponent={Slide}
          />
        )
      })}
    </>
  )
}

export function LoadingToast() {
  const loadings = useSelector(selectors.getLoadingToast())
  const isOpen = loadings.some(item => item.isOpen)

  return (
    <LoadingToastComponent
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
    />
  )
}
