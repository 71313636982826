// @flow
import React, { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import { betaFeatures } from '@edison/webmail-core/utils/constants'
import { modalTypes, routePaths, RETROFIT_POPUP_ANCHOR } from 'utils/constants'
import { useModal } from 'common/modals'
import { getAuth, isSuperSessionLoading } from 'core/auth/selectors'
import { getSession } from 'core/auth/actions'
import { useBetaFeature } from 'core/beta-features/hooks'
import { useOrderId, useSession } from 'core/auth/hooks'
import { useCurrentBreak } from 'core/inbox-break/hooks'
import { getProfileInSettings } from 'core/settings/selectors'
import { useRetrofitAccounts, useProviderLogos } from 'core/retrofit/hooks'

import AccountMenu, {
  actions,
  MenuSection,
  AddAccountSection,
  SessionAccountItem,
} from '@edison/webmail-ui/components/AccountMenu'
import { SimpleAvatar } from '@edison/webmail-ui/components/Avatar'
import TiledAvatars from '@edison/webmail-ui/components/TiledAvatars'
import Swtich from '@edison/webmail-ui/components/Switch'
import AuthAvatar, { useAuthAvatar } from 'common/AuthAvatar'

import type { RouterHistory, Match } from 'react-router-dom'
import type { Dispatch } from 'types/redux'
import { IOS, Android } from '@edison/webmail-ui/components/Icons'

const authSelector = getAuth()
const profileSelector = getProfileInSettings()

type Props = {
  history: RouterHistory,
  match: Match,
}

export default withRouter(({ history, match }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()

  const userId = useOrderId()
  const session = useSession()
  const accounts = useSessionAccounts()
  const auth = useSelector(authSelector)
  const profile = useSelector(profileSelector)
  const isLoading = useSelector(isSuperSessionLoading)

  const [open, setOpen] = useState(false)
  const authExpiredAt = useRef(moment().unix())

  const hasMultipleAccounts = accounts.length > 1

  const retrofit = useBetaFeature(betaFeatures.retrofit)
  const inboxBreak = useBetaFeature(betaFeatures.inboxBreak)
  const multipleAccounts = useBetaFeature(betaFeatures.multipleAccounts)

  // Enable the button when the feature is available or there're already
  // multiple accounts in super session
  const isMultipleAccountsEnable =
    multipleAccounts.isEnable || hasMultipleAccounts
  const isRetrofitEnable = retrofit.isEnable

  const accountProvidersModal = useModal(modalTypes.accountProviders)
  const manualBreakSetupModal = useModal(modalTypes.manualSetup)
  const endBreakConfirmModal = useModal(modalTypes.endBreakConfirm)
  const mobileDownloadModal = useModal(modalTypes.mobileDownload)

  const currBreak = useCurrentBreak()

  const { accounts: retrofitAccounts } = useRetrofitAccounts()

  const onMenuOpen = useCallback(() => {
    if (isLoading) return

    const now = moment()

    // Refresh the super session after 5 minutes
    // Or re-mount
    if (authExpiredAt.current < now.unix()) {
      dispatch(getSession())
      authExpiredAt.current = now.add(5, 'm').unix()
    }
  }, [isLoading])

  const toggleMenu = useCallback(() => {
    const next = !open
    setOpen(next)

    if (next) {
      onMenuOpen()
    }
  }, [open, onMenuOpen])

  const handleOnAddAccount = useCallback(() => {
    // Close the menu
    toggleMenu()

    if (isRetrofitEnable) {
      accountProvidersModal.showModal()
    } else {
      session.onAddAccount()
    }
  }, [toggleMenu, isRetrofitEnable, accountProvidersModal, session])
  const openMobileDownload = useCallback(() => {
    toggleMenu()
    mobileDownloadModal.showModal()
  }, [toggleMenu, mobileDownloadModal])

  if (auth === null || isEmpty(profile)) return null
  else {
    return (
      <div id={RETROFIT_POPUP_ANCHOR}>
        <AccountMenu
          open={open}
          onToggle={toggleMenu}
          avatar={
            <AuthAvatar
              src={profile.avatar}
              alt={profile.senderName || auth.user}
            />
          }
        >
          <MenuSection style={{ maxHeight: 320 }}>
            {accounts.map(
              ({
                name,
                email,
                onClick,
                isActive,
                avatarUrl,
                isLoading,
                hoverAction,
                validSession,
              }) => (
                <SessionAccountItem
                  key={email}
                  name={name}
                  email={email}
                  hoverAction={hoverAction}
                  isLoading={isLoading}
                  validSession={validSession}
                  hoverable={hasMultipleAccounts}
                  isActive={hasMultipleAccounts && isActive}
                  importAccountsCount={isActive ? retrofitAccounts.length : 0}
                  avatar={
                    <SessionAccountAvatar
                      isActive={isActive}
                      src={isActive ? profile.avatar : avatarUrl}
                      alt={email}
                    />
                  }
                  onClick={
                    hasMultipleAccounts && !isActive ? onClick : undefined
                  }
                />
              )
            )}
          </MenuSection>
          {isMultipleAccountsEnable ? (
            <AddAccountSection
              onClick={handleOnAddAccount}
              enableRetrofitAccounts={isRetrofitEnable}
            />
          ) : null}
          <MenuSection
            options={[
              {
                // HACK: Use the `plural` in i18next to represent the on/off status
                title: t('inboxBreak.menu.status', {
                  count: currBreak.enable ? 2 : 1,
                }),
                action: (
                  <Swtich
                    size="xs"
                    checked={currBreak.enable}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()

                      // Show different modal base on the status
                      if (currBreak.enable) {
                        endBreakConfirmModal.showModal()
                      } else {
                        manualBreakSetupModal.showModal()
                      }
                    }}
                  />
                ),
                condition: inboxBreak.isEnable,
              },
              {
                condition: true,
                title: t('menu.settings'),
                onClick: () =>
                  history.push(generatePath(routePaths.settings, { userId })),
              },
              {
                condition: true,
                title: t('menu.help'),
                onClick: () => window.open(t('supportSite'), '_blank'),
              },
              {
                condition: true,
                title: t('menu.downloadForMobile'),
                action: (
                  <div className={'w-14 flex justify-between items-center'}>
                    <IOS />
                    <Android />
                  </div>
                ),
                onClick: openMobileDownload,
              },
              {
                condition: true,
                title: t('menu.logout', { count: accounts.length }),
                onClick: () => history.push(routePaths.logoutAll),
              },
            ].filter(({ condition }) => condition)}
          />
        </AccountMenu>
      </div>
    )
  }
})

const SessionAccountAvatar = memo(({ isActive, src, alt }) => {
  const { accounts } = useRetrofitAccounts()
  const { getLogo } = useProviderLogos()
  const authAvatar = useAuthAvatar(src, alt)

  if (isActive && accounts.length > 0) {
    return (
      <TiledAvatars maxCount={3}>
        <SimpleAvatar
          mode="cover"
          rounded={false}
          src={authAvatar.src}
          alt={authAvatar.alt}
        />
        {accounts.map((account, index) => (
          <SimpleAvatar
            mode="cover"
            rounded={false}
            key={index}
            src={getLogo(account)}
            alt={account.emailAddress}
          />
        ))}
      </TiledAvatars>
    )
  }

  return <AuthAvatar src={authAvatar.src} alt={authAvatar.alt} />
})

const useSessionAccounts = () => {
  const currentOrderId = useOrderId()
  const isLoading = useSelector(isSuperSessionLoading)
  const { accounts, onSwitch, onLogout, onLogin } = useSession()

  const getActions = account => {
    const { orderId, validSession } = account
    let onClick, hoverAction

    if (validSession) {
      onClick = () => onSwitch(orderId)
      hoverAction = <actions.Logout onClick={() => onLogout(orderId)} />
    } else {
      onClick = () => onLogin(orderId)
      hoverAction = <actions.Login onClick={() => onLogin(orderId)} />
    }

    return { onClick, hoverAction }
  }

  return accounts
    .map(account => {
      const {
        orderId,
        avatarUrl,
        validSession,
        displayName,
        emailAddress,
      } = account
      const { onClick, hoverAction } = getActions(account)

      return {
        orderId,
        onClick,
        avatarUrl,
        hoverAction,
        validSession,
        name: displayName,
        email: emailAddress,
        isActive: currentOrderId === orderId,
        isLoading: !validSession && isLoading,
      }
    })
    .sort(({ isActive }) => (isActive ? -1 : 1))
}
