// @flow
import { createSelector } from 'reselect'
import { selectCalendarState } from './selector-common'
import type { Selector } from 'types/state'
import type { CalendarState, UnixTimestampInSeconds } from './types'
import type { ReduxEvent } from './modal-redux-event'
import type { ReduxEvents } from './modal-redux-events'
import type { UIEventsRange } from './modal-ui-events-range'
import type { ReduxEventJSON } from './modal-redux-event'
import { ICSEventData, ReduxICSEventsData } from './modal-redux-ics-events'

export const getUIEventsRange: Selector<UIEventsRange> = createSelector(
  selectCalendarState,
  (state: CalendarState) => {
    return state.events.uiEventsRange
  }
)

const getCalendarICSEventsDataSelector: Selector<ReduxICSEventsData> = createSelector(
  selectCalendarState,
  (state: CalendarState) => {
    return state.events.icsEventsData
  }
)
const getCalendarEventsSelector: Selector<ReduxEvents> = createSelector(
  selectCalendarState,
  (state: CalendarState) => {
    return state.events.eventsData
  }
)
export const getEventsState: Selector<ReduxEvents> = createSelector(
  getCalendarEventsSelector,
  (state: ReduxEvents) => state
)

export const getSelectedICSEvent = (): Selector<{
  calendarId: string,
  eventId: string,
  instanceId: string,
} | null> => {
  return createSelector(selectCalendarState, (state: CalendarState) => {
    return state.events.selectedICSEvent
  })
}
export const getSelectedUIScopeEvent = (): Selector<ReduxEvent | null> => {
  return createSelector(selectCalendarState, (state: CalendarState) => {
    return state.events.selectedUIScopeEvent
  })
}

export const getUIScopeEvents = (
  {
    start,
    end,
    currentTimeZone,
    calendarIds,
  }: {
    calendarIds: string[],
    currentTimeZone: string,
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
  },
  options?: { showCancelled?: boolean }
): Selector<ReduxEventJSON[]> => {
  return createSelector(
    getCalendarEventsSelector,
    (reduxEvents: ReduxEvents) => {
      return reduxEvents.getUIScopeEvents(
        { start, end, calendarIds, currentTimeZone },
        options
      )
    }
  )
}

export const getEventById = ({
  calendarId,
  eventId,
}: {
  calendarId: string,
  eventId: string,
}): Selector<ReduxEvent | void> => {
  return createSelector(
    getCalendarEventsSelector,
    (reduxEvents: ReduxEvents) => {
      return reduxEvents.getEventById({ calendarId, eventId })
    }
  )
}

export const getICSEventDataByKey = ({
  calendarId,
  eventOrInstanceId,
  startTime,
}: {
  calendarId: string,
  eventOrInstanceId: string,
  startTime: UnixTimestampInSeconds,
}): Selector<ICSEventData | void> => {
  return createSelector(
    getCalendarICSEventsDataSelector,
    (reduxICSEventsData: ReduxICSEventsData) => {
      return reduxICSEventsData.get(
        `${calendarId}:${eventOrInstanceId}:${startTime}`
      )
    }
  )
}

export const getInstanceById = ({
  calendarId,
  eventId,
  instanceId,
}: {
  calendarId: string,
  eventId: string,
  instanceId: string,
}): Selector<ReduxEvent | void> => {
  return createSelector(
    getCalendarEventsSelector,
    (reduxEvents: ReduxEvents) => {
      return reduxEvents.getInstanceById({ calendarId, eventId, instanceId })
    }
  )
}
