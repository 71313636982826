import React, { useMemo } from 'react'
import DetailModal from '@edison/webmail-ui/components/DetailModal'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'core/auth/selectors'
import ContactDetail from './ContactDetail'
import ContactAttachment from './ContactAttachment'
import qs from 'qs'
import { getContactById } from 'core/contacts/selectors'
import { useDetailModalGoBack } from 'hooks/useGoBack'
import { useAppInitialization } from 'core/initialization/hooks'

const ContactDetailModal = ({ contactId }) => {
  const contact = useSelector(
    useMemo(() => getContactById(contactId), [contactId])
  )
  const goBack = useDetailModalGoBack('contact')

  if (!contact) return null
  return (
    <DetailModal isOpen={!!contact} hideModal={goBack}>
      <ContactDetail contactId={contactId} />
    </DetailModal>
  )
}

const ContactAttachmentModal = ({ contactId, attachmentType }) => {
  const contact = useSelector(
    useMemo(() => getContactById(contactId), [contactId])
  )
  const goBack = useDetailModalGoBack('attachmentType')

  if (!contact) return null
  return (
    <DetailModal isOpen={!!contact} hideModal={goBack}>
      <ContactAttachment
        contactId={contactId}
        attachmentType={attachmentType}
      />
    </DetailModal>
  )
}

export default () => {
  const authed = useSelector(useMemo(() => isAuthenticated(), []))
  const location = useLocation()
  const { isInitialized } = useAppInitialization()

  const query = useMemo(() => qs.parse(location.search.slice(1)), [
    location.search,
  ])
  if (!isInitialized || !authed || !query.contact) return null

  return (
    <>
      <ContactDetailModal contactId={query.contact} />
      {!!query.attachmentType && (
        <ContactAttachmentModal
          contactId={query.contact}
          attachmentType={query.attachmentType}
        />
      )}
    </>
  )
}
