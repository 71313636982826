import AttachmentPillItem, {
  AttachmenType,
} from '@edison/webmail-ui/components/Pill/AttachmentPill'
import React, { useEffect, useRef } from 'react'

export function AttachmentPill(props) {
  const ref = useRef()
  const { onSelect, condition, isClearAll } = props
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])
  return (
    <AttachmentPillItem
      ref={ref}
      onSelect={attachmentTypes => {
        const nextCondition = {
          ...condition,
          attachment: attachmentTypes.includes(AttachmenType.HAS),
          attachmentType: attachmentTypes.includes(AttachmenType.ALL)
            ? []
            : attachmentTypes.filter(item => item !== AttachmenType.HAS),
        }
        onSelect(nextCondition)
      }}
    />
  )
}
