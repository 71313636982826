//@flow
// $FlowFixMe
import rrule, { Frequency } from '@ruoxijiang/rrule'

const generateUntilText = (until?: number | string) => {
  let end = ''
  if (until) {
    if (typeof until === 'number') {
      end = `, ${until} times`
    } else {
      end = `, until ${until}`
    }
  }
  return end
}
// const mapToArray = (
//   objOrArray: string | Array<any>
// ): Array<string | number> => {
//   if (typeof objOrArray === 'string') {
//     return [objOrArray]
//   } else if (typeof objOrArray === 'number') {
//     return [objOrArray]
//   } else if (Array.isArray(objOrArray)) {
//     return objOrArray.map(obj => {
//       if (typeof obj === 'string') {
//         return obj
//       } else if (typeof obj === 'number') {
//         return obj
//       } else if ('toString' in obj) {
//         return obj.toString()
//       } else {
//         return ''
//       }
//     })
//   }
// }
export class Recurrence {
  rrule: any
  recurrenceOtherData: string[]
  constructor(rruleString: string[]) {
    this.recurrenceOtherData = []
    this.rrule = {}
    if (!Array.isArray(rruleString)) {
      console.error('Recurrence must initialize with string[]')
      return
    }
    const rruleStr = rruleString.filter((str: string) => {
      if (str.startsWith('RRULE:')) {
        return true
      }
      this.recurrenceOtherData.push(str)
      return false
    })
    if (rruleStr.length) {
      this.rrule = rrule.parseString(rruleStr[0])
    }
  }
  get options() {
    return this.rrule
  }
  get frequency(): 'weekly' | 'monthly' | 'yearly' | 'daily' {
    switch (this.rrule.freq) {
      case Frequency.YEARLY:
        return 'yearly'
      case Frequency.WEEKLY:
        return 'weekly'
      case Frequency.DAILY:
        return 'daily'
      case Frequency.MONTHLY:
        return 'monthly'
      default:
        return 'daily'
    }
  }
  set frequency(val: 'weekly' | 'monthly' | 'yearly' | 'daily') {
    switch (val.toLocaleLowerCase()) {
      case 'weekly':
        this.rrule.freq = Frequency.WEEKLY
        break
      case 'monthly':
        this.rrule.freq = Frequency.MINUTELY
        break
      case 'yearly':
        this.rrule.freq = Frequency.YEARLY
        break
      default:
        this.rrule.freq = Frequency.DAILY
        break
    }
  }
  get interval() {
    return this.rrule.interval
  }
  set interval(val: number) {
    this.rrule.interval = val
  }
  get until(): Date {
    return this.rrule.until
  }
  set until(untilUTCDate: Date) {
    this.rrule.until = untilUTCDate
  }
  rruleOnly(): string {
    if (this.rrule.freq === undefined) {
      return ''
    }
    const ret = new rrule(this.rrule)
    return ret.toString()
  }
  updateRRule(rule: string) {
    this.rrule = rrule.parseString(rule)
  }
  get recurrence(): string[] {
    const rruleStr = this.rruleOnly()
    if (rruleStr.length > 0) {
      return [this.rruleOnly(), ...this.recurrenceOtherData]
    } else {
      return this.recurrenceOtherData.slice()
    }
  }
  toJSON() {
    return this.recurrence
  }
  toString() {
    this.recurrence.join('\n')
  }
  // static extractExRDateString(
  //   rruleString: string
  // ): { withoutExDates: string, exDates: string } {
  //   const withoutExDates = []
  //   const exDates = rruleString.split('\n').filter((rule: string) => {
  //     if (rule.startsWith('EXDATE:')) {
  //       return true
  //     }
  //     withoutExDates.push(rule)
  //     return false
  //   })
  //   return {
  //     withoutExDates: withoutExDates.join('\n'),
  //     exDates: exDates.join('\n'),
  //   }
  // }
  // static extractUntilString(rruleString: string) {
  //   if (rruleString.length > 0) {
  //     // https://regex101.com/r/bl9DiE/1
  //     const untilString = rruleString.match(/UNTIL=\d{8}T\d{6}Z/gm)
  //     if (Array.isArray(untilString) && untilString.length > 0) {
  //       return untilString[0]
  //     }
  //   }
  //   return ''
  // }
  // static newRRuleWithUpdatedUntil(oldRRuleString: string, untilInUTC: Date) {
  //   const rruleStrings = Recurrence.extractExRDateString(oldRRuleString)
  //   const option = rrule.parseString(rruleStrings.withoutExDates)
  //   option.until = untilInUTC
  //   const tmp = new rrule(option)
  //   return rrulestr(`${tmp.toString()}\n${rruleStrings.exDates}`)
  // }
  // static newRRuleWithExRDate(oldRRuleString: string, exDate: Date) {
  //   const ret = rrulestr(oldRRuleString, { forceset: true })
  //   ret.exdate(exDate)
  //   return ret
  // }
}
export const recurrenceType = {
  Daily: {
    Simple: () => 'Daily',
    Custom: (interval: number) => `Every ${interval} days`,
  },
  Weekly: {
    Simple: (day: string) => `Weekly on ${day}`,
    Custom: (interval: number, days: string[], until?: number | string) => {
      const dayStr = days.join(' ')
      const end = generateUntilText(until)
      if (interval === 1) {
        return `${recurrenceType.Weekly.Simple(dayStr)}${end}`
      } else if (interval === 2) {
        return `Biweekly on ${dayStr}${end}`
      } else {
        return `Every ${interval} weeks on ${dayStr}${end}`
      }
    },
  },
  Monthly: {
    Simple: (day: string) => `Monthly on ${day}`,
    Custom: (interval: number, days: string[], until?: number | string) => {
      const dayStr = days.join(' ')
      const end = generateUntilText(until)
      if (interval === 1) {
        return `${recurrenceType.Monthly.Simple(dayStr)}${end}`
      } else {
        return `Every ${interval} months on ${dayStr}${end}`
      }
    },
  },
  Annually: {
    Simple: (day: string) => `Annually on ${day}`,
    Custom: (interval: number, days: string[], until?: number | string) => {
      const dayStr = days.join(' ')
      const end = generateUntilText(until)
      if (interval === 1) {
        return `${recurrenceType.Annually.Simple(dayStr)}${end}`
      } else {
        return `Every ${interval} years on ${dayStr}${end}`
      }
    },
  },
}
