// @flow
import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, generatePath, Redirect } from 'react-router-dom'
import isNil from 'lodash/isNil'

import { useQueryParams } from 'common/url'
import { usePaywall } from 'common/Paywall'
import { useOrderId } from 'core/auth/hooks'
import { useIMAPForm, useRetrofitAccount } from 'core/retrofit/hooks'
import { isAllowImportAccounts } from 'core/premium/selectors'

import { connectionTypes } from '@edison/webmail-core/utils/constants'
import { encryptionOptions, routePaths } from 'utils/constants'

import IMAPForm, {
  ImportButton,
  ReconnectButton,
  INITIAL_VALUES,
} from '@edison/webmail-ui/components/Retrofit/IMAPForm'

const View = () => {
  const userId = useOrderId()
  const { t } = useTranslation()
  const history = useHistory()
  const paywalls = usePaywall()
  const allowImportAccounts = useSelector(isAllowImportAccounts)
  const { onCreate, isCreating, isAdvanced, advancedConfig } = useIMAPForm()

  const { connection } = useQueryParams()
  const { account } = useRetrofitAccount(connection)

  const isReconnect = !isNil(account)

  const { title, ActionButton } = useMemo(() => {
    let title = t('retrofit.imap.title')
    let ActionButton = ImportButton
    if (isReconnect) {
      title = t('retrofit.imap.reconnect.title')
      ActionButton = ReconnectButton
    }
    return { title, ActionButton }
  }, [isReconnect])

  const initialValues = useMemo(() => {
    if (isReconnect) {
      return Object.assign({}, INITIAL_VALUES, {
        emailAddress: account.emailAddress,
      })
    } else return INITIAL_VALUES
  }, [isReconnect, account])

  const disabledFields = useMemo(() => {
    if (isReconnect && !!initialValues.emailAddress) {
      return { emailAddress: true }
    } else return {}
  }, [initialValues, isReconnect])

  useEffect(() => {
    if (!allowImportAccounts) {
      paywalls.retrofit.show()
    }
  }, [allowImportAccounts])

  useEffect(() => {
    // If the account is not IMAP connection type
    // Redirect to connect screen
    if (account && account.connectionType !== connectionTypes.DEFAULT) {
      history.replace({ search: '' })
    }
  }, [account])

  if (!allowImportAccounts) {
    return <Redirect to={generatePath(routePaths.retrofitAdd, { userId })} />
  }

  return (
    <IMAPForm
      advancedConfig={advancedConfig}
      advanced={isAdvanced}
      isLoading={isCreating}
      onSubmit={values => onCreate(values)}
      title={title}
      disabledFields={disabledFields}
      initialValues={initialValues}
      action={({ disabled }) => (
        <ActionButton disabled={disabled} isLoading={isCreating} />
      )}
      encryptionOptions={encryptionOptions}
    />
  )
}

View.displayName = 'IMAPForm'

export default View
