// @flow
import isNil from 'lodash/isNil'
import moment from 'moment-timezone'
import uuid from 'uuid/v4'

import * as client from '@edison/webmail-core/api'
import * as api from '@edison/webmail-core/api/search'
import { getAuth } from 'core/auth/selectors'
import { getSplitInboxLabels } from 'core/split-inboxes/selectors'
import { getActiveLabel } from 'core/threads/selectors'
import type { ActionCreator, ThunkAction } from 'types/redux'
import { labelNames } from 'utils/constants'
import { createAction } from 'utils/redux'
import { getNextConditionLabels, initalCondition } from './helpers'
import {
  getNextPageToken,
  getSearchQuery,
  getSelectContactQuery,
  getSelectedLabels,
  getIsExistQuery,
} from './selectors'
import type {
  ResetSearchState,
  SearchBlur,
  SearchFailure,
  SearchFocus,
  SearchHintsFailure,
  SearchHintsRequest,
  SearchHintsSelect,
  SearchHintsSuccess,
  SearchRecommendationsFailure,
  SearchRecommendationsRequest,
  SearchRecommendationsSuccess,
  SearchRequest,
  SearchSetCondition,
  SearchSetPrefix,
  SearchSetQuery,
  SearchSetSelectContactQuery,
  SearchSetThreadIds,
  SearchSuccess,
  SetSelectLabel,
} from './types'
import { getActiveAccount } from 'core/retrofit/selectors'

export const setQuery: ActionCreator<SearchSetQuery> = createAction(
  'SEARCH_SET_QUERY'
)

export const setSearchThreadIds: ActionCreator<SearchSetThreadIds> = createAction(
  'SEARCH_SET_THREAD_IDS'
)

export const setCondition: ActionCreator<SearchSetCondition> = createAction(
  'SEARCH_SET_CONDITION'
)

export const setSelectContactQuery: ActionCreator<SearchSetSelectContactQuery> = createAction(
  'SEARCH_SET_SELECT_CONTACT_QUERY'
)

export const fetchSearchActions: {
  request: ActionCreator<SearchRequest>,
  success: ActionCreator<SearchSuccess>,
  failure: ActionCreator<SearchFailure>,
} = {
  request: createAction('SEARCH_REQUEST'),
  success: createAction('SEARCH_SUCCESS'),
  failure: createAction('SEARCH_FAILURE'),
}

export const searchThreads = (
  query: string,
  {
    pageToken,
    isInbox = false,
  }: { pageToken?: string, isInbox?: boolean } = {},
  condition = initalCondition
): ThunkAction => {
  return async (dispatch, getState, extras) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(fetchSearchActions.failure({ message: 'User not logged in' }))
      return
    }
    const requestId = uuid()
    const timezone = moment.tz.guess()
    const contactQuery = getSelectContactQuery()(getState())
    const selectedLabels = getSelectedLabels()(getState())
    const activeLabel = getActiveLabel()(getState())
    const splitInboxLabels = getSplitInboxLabels()(getState())
    try {
      dispatch(
        fetchSearchActions.request(
          { pageToken },
          { request: { id: requestId } }
        )
      )
      const res = await api.searchThreads({
        q: {
          query,
          ...condition,
          email: contactQuery.map(item => item.email),
          label: getNextConditionLabels(
            condition.label,
            selectedLabels,
            activeLabel,
            splitInboxLabels
          ),
        },
        pageToken,
        timezone,
        auth,
        prioritizeLabels: ['SPAM', 'TRASH'],
      })
      const isExistQuery = getIsExistQuery()(getState())
      dispatch(
        fetchSearchActions.success(
          { ...res.result, isInbox, isExistQuery },
          {
            request: { id: requestId, pageToken },
          }
        )
      )
      return res.result
    } catch (e) {
      dispatch(
        fetchSearchActions.failure(
          { message: e.message },
          { request: { id: requestId } }
        )
      )
    }
  }
}

export const fetchSearchHintsActions: {
  request: ActionCreator<SearchHintsRequest>,
  success: ActionCreator<SearchHintsSuccess>,
  failure: ActionCreator<SearchHintsFailure>,
} = {
  request: createAction('SEARCH_HINTS_REQUEST'),
  success: createAction('SEARCH_HINTS_SUCCESS'),
  failure: createAction('SEARCH_HINTS_FAILURE'),
}

export function searchHints(query: string): ThunkAction {
  return async (dispatch, getState, extras) => {
    // Do not search when query is empty
    if (query.trim().length === 0) {
      return
    }

    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        fetchSearchHintsActions.failure({ message: 'User not logged in' })
      )
      return
    }

    if (query.length === 0) {
      return
    }

    const requestId = uuid()
    const timezone = moment.tz.guess()

    try {
      dispatch(
        fetchSearchHintsActions.request(undefined, {
          request: {
            id: requestId,
          },
        })
      )
      const res = await api.searchHints({ q: query, timezone, auth })
      dispatch(
        fetchSearchHintsActions.success(res.result, {
          request: { id: requestId },
        })
      )
    } catch (e) {
      dispatch(
        fetchSearchHintsActions.failure(
          { message: e.message },
          { request: { id: requestId } }
        )
      )
    }
  }
}

export const selectSearchHint: ActionCreator<SearchHintsSelect> = createAction(
  'SEARCH_HINTS_SELECT'
)

//search more
export const searchMore = (): ThunkAction => {
  return async (dispatch, getState) => {
    const query = getSearchQuery()(getState())
    const pageToken = getNextPageToken()(getState())

    if (isNil(pageToken)) {
      return
    }

    return dispatch(searchThreads(query, { pageToken }))
  }
}

export const fetchSearchRecommendationsActions: {
  request: ActionCreator<SearchRecommendationsRequest>,
  success: ActionCreator<SearchRecommendationsSuccess>,
  failure: ActionCreator<SearchRecommendationsFailure>,
} = {
  request: createAction('SEARCH_RECOMMENDATIONS_REQUEST'),
  success: createAction('SEARCH_RECOMMENDATIONS_SUCCESS'),
  failure: createAction('SEARCH_RECOMMENDATIONS_FAILURE'),
}

export function fetchSearchRecommendations(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        fetchSearchRecommendationsActions.failure({
          message: 'User not logged in',
        })
      )
    }

    try {
      dispatch(fetchSearchRecommendationsActions.request())
      const recommendations = await api.searchRecommendations({ auth })
      return dispatch(
        fetchSearchRecommendationsActions.success({
          recommendations: recommendations.result,
        })
      )
    } catch (e) {
      return dispatch(fetchSearchRecommendationsActions.failure(e))
    }
  }
}

export function resetSearchStateAction() {
  return (dispatch, getState) => {
    const activeAccount = getActiveAccount(getState())
    dispatch(
      resetSearchState({
        currentActiveAccount: activeAccount
          ? [
              {
                labelUUID: activeAccount.labelUUID,
              },
            ]
          : [],
      })
    )
  }
}
export function searchThreadIds(ids) {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const { result } = await client.threads.batchGet(ids, { auth })
    const threads = result.filter(thread => {
      if (
        thread.labelIds.includes(labelNames.trash) ||
        thread.labelIds.includes(labelNames.spam)
      ) {
        return false
      }
      return true
    })
    const resultIds = threads.map(item => item.id)
    dispatch(
      setSearchThreadIds({
        ids: resultIds,
      })
    )
  }
}

export const setPrefix: ActionCreator<SearchSetPrefix> = createAction(
  'SEARCH_SET_PREFIX'
)

export const blur: ActionCreator<SearchBlur> = createAction('SEARCH_BLUR')

export const focus: ActionCreator<SearchFocus> = createAction('SEARCH_FOCUS')

export const resetSearchState: ActionCreator<ResetSearchState> = createAction(
  'RESET_SEARCH_STATE'
)

export const setSelectedLabels: ActionCreator<SetSelectLabel> = createAction(
  'SET_SELECT_LABEL'
)
