// @flow
import { createSelector } from 'reselect'

import type { State as ModalsState } from './types'
import type { State, Selector } from 'types/state'

export const selectModalsState = (state: State) => state.modals

export function getModals(): Selector<ModalsState> {
  return createSelector(selectModalsState, (modals: ModalsState) => modals)
}

export function getModalStatus(key: string): Selector<boolean> {
  return createSelector(selectModalsState, (modals: ModalsState) => {
    const modal = modals.find(item => item.key === key)
    return !!modal && modal.isOpen
  })
}
