import {
  isEnableThumbnailAttachment,
  getMetaDataFromAttachmentUrl,
  generateLargeAttachmentImagePreviewUrl,
} from 'utils'
import ComposeAttachmentItem from '@edison/webmail-ui/components/ComposeAttachmentItem'
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { imagePreviewSizeOptions } from 'utils/constants'
import { getAuth } from '../../../../core/auth/selectors'
import * as client from '@edison/webmail-core/api'
import { usePreviewModal } from 'core/previews/hooks'

const PreviewAttachmentItem = props => {
  const { messageHeaderId, id, preview, url: attachmentUrl } = props

  const [url, setUrl] = useState('')
  const loadedRef = useRef(false)
  const auth = useSelector(useMemo(() => getAuth(), []))
  const previews = usePreviewModal()

  useEffect(() => {
    if (loadedRef.current) return
    if (!messageHeaderId) return

    const { email, uid } = getMetaDataFromAttachmentUrl(attachmentUrl)

    client.largeAttachments
      .authDownload(
        {
          aid: id,
          mid: messageHeaderId,
          uid,
          email,
          action: 'preview',
          pvformat: preview,
        },
        // $FlowFixMe
        { auth }
      )
      .then(res => {
        loadedRef.current = true
        setUrl(
          generateLargeAttachmentImagePreviewUrl(
            res.result,
            imagePreviewSizeOptions.small
          )
        )
      })
  }, [messageHeaderId, id])

  return (
    <>
      <ComposeAttachmentItem
        {...props}
        src={url}
        onClick={() => previews.showModal({ attachments: [props], index: 0 })}
      />
    </>
  )
}

export default ({ onRemove, messageId, ...attachment }) => {
  const { name, size, contentType } = attachment

  if (!isEnableThumbnailAttachment(attachment)) {
    return (
      <ComposeAttachmentItem
        name={name}
        size={size}
        contentType={contentType}
        onRemove={onRemove}
      />
    )
  } else {
    return (
      <PreviewAttachmentItem
        {...attachment}
        message={messageId}
        onRemove={onRemove}
      />
    )
  }
}
