// @flow
import { generatePath } from 'react-router-dom'
import pullAllBy from 'lodash/pullAllBy'
import unionBy from 'lodash/unionBy'
import last from 'lodash/last'
import has from 'lodash/has'
import uuid from 'uuid'
import * as client from '@edison/webmail-core/api'
import { createAction } from 'utils/redux'
import { batch } from 'react-redux'

import {
  labelNames,
  composeStatus,
  COMPOSE_DEFAULT_HTML,
  snackbarTypes,
  contactTypes,
} from 'utils/constants'
import {
  getMessageOriginSubject,
  formatAttachment,
  formatLargeAttachment,
  scrollToMessage,
  scrollToDraft,
} from 'utils'
import { CONTENT_DISPOSITION_ATTACHMENT } from '@edison/webmail-core/utils/constants'
import { modalTypes, routePaths, autoSelectEmail } from 'utils/constants'
import i18next from 'i18next'
import { show as showModal } from 'core/modals/actions'
import { deleteMessages } from '../metadata/actions'
import { getInnerContactByEmail } from 'core/contacts/selectors'
import { enableShowImage } from 'core/contacts/actions'
import { getActiveAccount } from 'core/retrofit/selectors'
import { hideSnackbar } from 'core/snackbars/actions'
import { showNotification } from '../toasts/actions'
import { labelActions } from '../metadata/actions'
import { toastVariants } from 'common/toasts'
import {
  createDraft,
  updateDraft,
  flushSaveDraft,
  activeDraft,
} from '../compose/actions'
import { addAttachments } from 'core/attachments/actions'
import { getAuth, getOrderId } from '../auth/selectors'
import { getThreadPagination } from '../threads/selectors'
import { getDraft, getDraftAttachments, getDrafts } from '../compose/selectors'
import {
  getMessageThreadId,
  getThreadMessageIds,
  getThreadLabelIds,
  getMessagesState,
  getMessageLabelIds,
  getThreadMessageIdsByLabels,
  getLabelThreads,
} from '../metadata/selectors'

import {
  getMessage,
  getMessageState,
  getReplyRecipientsByMessageId,
  getReplyAllRecipientsByMessageId,
  getThreadContainDraftMessageIds,
  getDefaultSendEmail,
  getReplyDefaultSendEmail,
  getThreadMessages,
} from './selectors'
import { removeLargeAttachmentTemplate } from 'utils/htmlProcess'

import type {
  MessageListRequest,
  MessageListSuccess,
  MessageListFailure,
  SmartReplyRequest,
  SmartReplySuccess,
  SmartReplyFailure,
  BatchGetRequest,
  BatchGetSuccess,
  BatchGetFailure,
  FeedbackSmartReplyRequest,
  FeedbackSmartReplySuccess,
  FeedbackSmartReplyFailure,
  UpdateMessage,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type { FeedbackType } from '@edison/webmail-core/types/messages'
import {
  generateReplyContext,
  generateForwardContext,
} from 'utils/responseTemplate'
import {
  isSignatureEnable,
  getAccountSignatureContent,
} from 'core/settings/selectors'
import { getThreadsById } from 'core/threads/selectors'
import {
  largeAttachmentStatus,
  largeAttachmentScanStatus,
} from '@edison/webmail-ui/utils/constants'
import { getSearchCount, getSearchThreadIds } from 'core/search/selectors'

export const updateMessage: ActionCreator<UpdateMessage> = createAction(
  'UPDATE_MESSAGE'
)

function parseMessages(messages, state, thread) {
  let allAttachments = []
  let allLargeAttachments = []

  const draftMessages = messages.filter(
    item => item.labelIds && !!~item.labelIds.indexOf(labelNames.drafts)
  )
  const drafts = draftMessages
    .map((item, index) => {
      const threadId = thread ? thread.id : getMessageThreadId(item.id)(state)
      const threadMessages = thread
        ? thread.messages
        : getThreadMessages(threadId)(state)

      const replyMessage = threadMessages.find(
        message => message.messageId === item.inReplyTo
      )

      if (!replyMessage) {
        return null
      }
      const draftId = replyMessage.id
      const {
        id: messageId,
        subject,
        html,
        to,
        cc,
        bcc,
        from,
        attachments,
        largeAttachments,
        composeId,
      } = item
      const formattedAttachments = attachments.map(item => ({
        ...formatAttachment(item),
        uuid: uuid(),
      }))
      const formatedLargeAttachments = largeAttachments.map(item => ({
        ...item,
        uuid: uuid(),
        status: largeAttachmentStatus.DONE,
        scanStatus: largeAttachmentScanStatus.CLEAN,
        parts: [{}],
        chunkSize: item.size,
      }))

      allAttachments = allAttachments.concat(formattedAttachments)
      allLargeAttachments = allLargeAttachments.concat(formatedLargeAttachments)
      let removedLargeAttachmentsHtml = html
      if (largeAttachments.length) {
        removedLargeAttachmentsHtml = removeLargeAttachmentTemplate(html)
      }
      return {
        id: draftId,
        composeId,
        responseMessageId: replyMessage.id,
        messageId,
        threadId,
        subject,
        html: removedLargeAttachmentsHtml,
        to,
        cc,
        bcc,
        attachmentUuids: formattedAttachments.map(item => item.uuid),
        largeAttachmentUuids: formatedLargeAttachments.map(item => item.uuid),
        from: from ? from.email : null,
        actived: index === draftMessages.length - 1 ? true : false,
        saved: true,
      }
    })
    .filter(item => item)

  return {
    drafts,
    attachments: allAttachments,
    largeAttachments: allLargeAttachments,
    //the empty labelids is invalid message.
    messages: messages.map(item => ({
      ...item,
      labelIds: item.labelIds || [],
      attachments: item.attachments.map(formatAttachment),
      largeAttachments: item.largeAttachments.map(formatLargeAttachment),
    })),
  }
}
// function standardifyMessageAttachments({ html, attachments }) {
//   attachments
//     .filter(item => !html.includes(`src="cid:${item.id}"`))
//     .forEach(item => (item.contentDisposition = CONTENT_DISPOSITION_ATTACHMENT))
//   return attachments
// }

export const batchGetActions: {
  request: ActionCreator<BatchGetRequest>,
  success: ActionCreator<BatchGetSuccess>,
  failure: ActionCreator<BatchGetFailure>,
} = {
  request: createAction('BATCH_GET_REQUEST'),
  success: createAction('BATCH_GET_SUCCESS'),
  failure: createAction('BATCH_GET_FAILURE'),
}
/**
 * Batch get message endpoints.
 *
 * @public
 * @param {string} threadId - threadId
 * @param {Array<string>} messageIds - message ids
 * @returns {ThunkAction}
 */
export function batchGetMessages(messageIds: Array<string>): ThunkAction {
  return async (dispatch, getState) => {
    if (!messageIds.length) return
    const state = getState()
    const auth = getAuth()(state)

    try {
      dispatch(batchGetActions.request({}))
      const res = await client.messages.batchGet(messageIds, { auth })
      const messages = res.result.messages

      dispatch(batchGetActions.success(parseMessages(messages, state)))
    } catch (e) {
      batch(() => {
        dispatch(batchGetActions.failure({ message: e.message }))
        dispatch(showNotification(e.message, toastVariants.error))
      })
    }
  }
}

export const fetchThreadActions: {
  request: ActionCreator<MessageListRequest>,
  success: ActionCreator<MessageListSuccess>,
  failure: ActionCreator<MessageListFailure>,
} = {
  request: createAction('MESSAGE_LIST_REQUEST'),
  success: createAction('MESSAGE_LIST_SUCCESS'),
  failure: createAction('MESSAGE_LIST_FAILURE'),
}

/**
 * Fetch thread detail the backend API.
 *
 * @public
 * @param {string} threadId - threadId
 * @returns {ThunkAction}
 */
export function fetchThread(threadId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    if (auth === null) {
      dispatch(fetchThreadActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchThreadActions.request({}))
      const res = await client.messages.list(threadId, { auth })

      const thread = res.result

      dispatch(
        fetchThreadActions.success({
          thread,
          ...parseMessages(thread.messages, state, thread),
        })
      )
    } catch (e) {
      batch(() => {
        dispatch(fetchThreadActions.failure({ message: e.message }))
        dispatch(
          showNotification(
            e.status === 404 ? 'Thread not found' : e.message,
            toastVariants.error
          )
        )
      })
    }
  }
}

export function reply(messageId: string, replyContent: ?string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const draftId = messageId
    const replyRecipients = getReplyRecipientsByMessageId(messageId)(state)
    const draft = getDraft(draftId)(state)
    if (
      draft &&
      [
        composeStatus.deleting,
        composeStatus.sending,
        composeStatus.saving,
      ].includes(draft.status)
    )
      return
    const message = getMessage(messageId)(state)

    const subject = `Re: ${getMessageOriginSubject(message.subject)}`

    if (
      draft &&
      ![composeStatus.deleted, composeStatus.sended].includes(draft.status)
    ) {
      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(draft.from)(state)
        : undefined
      const html = replyContent
        ? `<div>${replyContent}</div>${generateReplyContext(
            message,
            signature
          )}`
        : `${COMPOSE_DEFAULT_HTML}${generateReplyContext(message, signature)}`

      const attachments = getDraftAttachments(draftId)(state)

      batch(() => {
        if (!draft.actived) {
          dispatch(activeDraft(messageId))
        }
        dispatch(
          updateDraft({
            ...replyRecipients,
            id: draftId,
            subject,
            attachmentUuids: pullAllBy(
              attachments,
              message.attachments,
              'id'
            ).map(item => item.uuid),
            html:
              draft.html !==
              `${COMPOSE_DEFAULT_HTML}${generateForwardContext(
                message,
                signature
              )}`
                ? draft.html
                : html,
          })
        )
      })

      scrollToDraft(messageId)
    } else {
      const threadId = getMessageThreadId(messageId)(state)

      const messageAttachments = message.attachments
        .filter(
          item => item.contentDisposition !== CONTENT_DISPOSITION_ATTACHMENT
        )
        .map(item => ({
          ...item,
          uuid: uuid(),
        }))
      const from = getDefaultSendEmail(messageId)(state)

      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(from)(state)
        : undefined
      const html = replyContent
        ? `<div>${replyContent}</div>${generateReplyContext(
            message,
            signature
          )}`
        : `${COMPOSE_DEFAULT_HTML}${generateReplyContext(message, signature)}`
      const activeAccount = getActiveAccount(state)
      const auth = getAuth()(state)
      batch(() => {
        if (draft && draft.schedulerId) {
          dispatch(hideSnackbar({ key: snackbarTypes.send }))
        }
        !!messageAttachments.length &&
          dispatch(addAttachments(messageAttachments))
        const autofrom =
          from === autoSelectEmail
            ? activeAccount
              ? activeAccount?.emailAddress
              : auth?.user
            : from
        dispatch(
          createDraft({
            ...replyRecipients,
            id: draftId,
            from: autofrom,
            threadId,
            responseMessageId: messageId,
            subject,
            html,
            attachmentUuids: messageAttachments.map(item => item.uuid),
            focusEnd: !!replyContent,
          })
        )
        //if has reply content immediately save draft
        !!replyContent && dispatch(flushSaveDraft(draftId))
      })

      //some delay time to render then scroll
      setTimeout(() => scrollToDraft(messageId))
    }
  }
}

export function replyAll(
  messageId: string,
  replyContent: ?string
): ThunkAction {
  return async (dispatch, getState) => {
    const draftId = messageId
    const state = getState()
    const replyAllRecipients = getReplyAllRecipientsByMessageId(messageId)(
      state
    )

    const draft = getDraft(draftId)(state)
    if (
      draft &&
      [
        composeStatus.deleting,
        composeStatus.sending,
        composeStatus.saving,
      ].includes(draft.status)
    )
      return

    const message = getMessage(messageId)(state)

    const subject = `Re: ${getMessageOriginSubject(message.subject)}`

    if (
      draft &&
      ![composeStatus.deleted, composeStatus.sended].includes(draft.status)
    ) {
      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(draft.from)(state)
        : ''
      const html = replyContent
        ? `<div>${replyContent}</div>${generateReplyContext(
            message,
            signature
          )}`
        : `${COMPOSE_DEFAULT_HTML}${generateReplyContext(message, signature)}`
      const attachments = getDraftAttachments(draftId)(state)
      batch(() => {
        if (!draft.actived) {
          dispatch(activeDraft(messageId))
        }
        dispatch(
          updateDraft({
            id: draftId,
            subject,
            ...replyAllRecipients,
            attachmentUuids: pullAllBy(
              attachments,
              message.attachments,
              'id'
            ).map(item => item.uuid),
            html:
              draft.html !==
              `${COMPOSE_DEFAULT_HTML}${generateForwardContext(
                message,
                signature
              )}`
                ? draft.html
                : html,
          })
        )
      })

      scrollToDraft(messageId)
    } else {
      const threadId = getMessageThreadId(messageId)(state)
      const messageAttachments = message.attachments
        .filter(
          item => item.contentDisposition !== CONTENT_DISPOSITION_ATTACHMENT
        )
        .map(item => ({
          ...item,
          uuid: uuid(),
        }))
      const from = getReplyDefaultSendEmail(messageId)(state)
      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(from)(state)
        : ''
      const html = replyContent
        ? `<div>${replyContent}</div>${generateReplyContext(
            message,
            signature
          )}`
        : `${COMPOSE_DEFAULT_HTML}${generateReplyContext(message, signature)}`

      batch(() => {
        if (draft && draft.schedulerId) {
          dispatch(hideSnackbar({ key: snackbarTypes.send }))
        }
        !!messageAttachments.length &&
          dispatch(addAttachments(messageAttachments))

        dispatch(
          createDraft({
            from,
            id: draftId,
            threadId: threadId,
            responseMessageId: messageId,
            subject,
            html,
            ...replyAllRecipients,
            attachmentUuids: messageAttachments.map(item => item.uuid),
            focusEnd: !!replyContent,
          })
        )
        //if has reply content immediately save draft
        !!replyContent && dispatch(flushSaveDraft(draftId))
      })

      setTimeout(() => scrollToDraft(messageId))
    }
  }
}

export function forward(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const draftId = messageId
    const state = getState()
    const message = getMessage(messageId)(state)

    const draft = getDraft(draftId)(state)
    if (
      draft &&
      [
        composeStatus.deleting,
        composeStatus.sending,
        composeStatus.saving,
      ].includes(draft.status)
    )
      return

    const messageAttachments = message.attachments.map(item => ({
      ...item,
      uuid: uuid(),
    }))

    messageAttachments.length && dispatch(addAttachments(messageAttachments))
    const subject = `Fwd: ${getMessageOriginSubject(message.subject)}`

    if (
      draft &&
      ![composeStatus.deleted, composeStatus.sended].includes(draft.status)
    ) {
      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(draft.from)(state)
        : ''
      const html = `${COMPOSE_DEFAULT_HTML}${generateForwardContext(
        message,
        signature
      )}`
      const attachments = getDraftAttachments(draftId)(state)
      batch(() => {
        if (!draft.actived) {
          dispatch(activeDraft(messageId))
        }
        dispatch(
          updateDraft({
            id: draftId,
            subject,
            to: [],
            cc: [],
            bcc: [],
            attachmentUuids: unionBy(attachments, messageAttachments, 'id').map(
              item => item.uuid
            ),
            html:
              draft.html !==
              `${COMPOSE_DEFAULT_HTML}${generateReplyContext(
                message,
                signature
              )}`
                ? draft.html
                : html,
          })
        )
      })

      scrollToDraft(messageId)
    } else {
      const threadId = getMessageThreadId(messageId)(state)
      const from = getDefaultSendEmail(messageId)(state)

      const signature = isSignatureEnable()(state)
        ? getAccountSignatureContent(from)(state)
        : ''
      const html = `${COMPOSE_DEFAULT_HTML}${generateForwardContext(
        message,
        signature
      )}`
      batch(() => {
        if (draft && draft.schedulerId) {
          dispatch(hideSnackbar({ key: snackbarTypes.send }))
        }
        dispatch(
          createDraft({
            from,
            id: draftId,
            threadId: threadId,
            responseMessageId: messageId,
            subject,
            html,
            attachmentUuids: messageAttachments.map(item => item.uuid),
          })
        )
      })

      setTimeout(() => scrollToDraft(messageId))
    }
  }
}

export const fetchSmartReplyActions: {
  request: ActionCreator<SmartReplyRequest>,
  success: ActionCreator<SmartReplySuccess>,
  failure: ActionCreator<SmartReplyFailure>,
} = {
  request: createAction('SMART_REPLY_REQUEST'),
  success: createAction('SMART_REPLY_SUCCESS'),
  failure: createAction('SMART_REPLY_FAILURE'),
}
/**
 * Fetch smart reply from thread
 * @param {*} threadId
 */
export function fetchSmartReply(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    try {
      dispatch(fetchSmartReplyActions.request({}))
      const res = await client.messages.fetchSmartReply(messageId, { auth })
      dispatch(
        fetchSmartReplyActions.success({ messageId, smartReply: res.result })
      )
    } catch (e) {
      dispatch(fetchSmartReplyActions.failure({ message: e.message }))
    }
  }
}

//only when no thread in metadata state to use the endpoint
export function checkAndFetchThread(
  threadId: string,
  isFilterPending = true
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const threadsById = getThreadsById(state)
    const messageIds = getThreadMessageIds(threadId, isFilterPending)(state)
    //if metadata no any message record, force get thread
    if (!has(threadsById, threadId) || !messageIds.length) {
      return dispatch(fetchThread(threadId))
    }
    // const messageIds = getThreadMessageIds(threadId)(state)
    dispatch(checkAndBatchGetMessages(messageIds))
  }
}

//meta data loaded to invoke the endpoint
export function checkAndBatchGetMessages(
  messageIds: Array<string>
): ThunkAction {
  return async (dispatch, getState) => {
    if (!messageIds.length) return
    const messageState = getMessageState()(getState())
    const threadId = getMessageThreadId(messageIds[0])(getState())
    const threadsById = getThreadsById(getState())
    if (!has(threadsById, threadId)) return

    const drafts = getDrafts()(getState())

    const needFetchMessageIds = messageIds.filter(
      id =>
        (!messageState[id] || !messageState[id].loaded) &&
        !drafts.some(item => item.messageId === id)
    )
    dispatch(batchGetMessages(needFetchMessageIds))
  }
}

export function checkAndFetchMessageSmartReply(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const { smartReply } = getMessage(messageId)(getState())
    // loaded
    if (smartReply) return
    dispatch(fetchSmartReply(messageId))
  }
}

export function deleteMessage(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const messageMetadataState = getMessagesState()(getState())
    const { threadId } = messageMetadataState[messageId] || {}

    if (threadId) {
      dispatch(deleteMessages(threadId, [messageId]))
    }
  }
}

export function checkAndReadThread(
  threadId: Array<string>,
  labels: any = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const messageIds = getThreadMessageIdsByLabels(threadId, {
      ...labels,
      [labelNames.unread]: true,
    })(state)

    if (messageIds.length > 0) {
      dispatch(labelActions.read.messages(messageIds))
    }
  }
}

export function isPureDraftAction(threadId: string): ThunkAction {
  return (dispatch, getState) => {
    const messageIds = getThreadMessageIds(threadId)(getState())
    const labelIds = getThreadLabelIds(threadId)(getState())
    return (
      messageIds.length === 1 &&
      labelIds.some(item => item === labelNames.drafts)
    )
  }
}

export const feedbackSmartReplyActions: {
  request: ActionCreator<FeedbackSmartReplyRequest>,
  success: ActionCreator<FeedbackSmartReplySuccess>,
  failure: ActionCreator<FeedbackSmartReplyFailure>,
} = {
  request: createAction('FEEDBACK_SMART_REPLY_REQUEST'),
  success: createAction('FEEDBACK_SMART_REPLY_SUCCESS'),
  failure: createAction('FEEDBACK_SMART_REPLY_FAILURE'),
}
export function feedbackSmartReply(
  messageId: string,
  feedbackType: FeedbackType
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    dispatch(feedbackSmartReplyActions.request())

    try {
      const res = await client.messages.feedbackSmartReply(
        messageId,
        feedbackType,
        { auth }
      )
      if (!res.result.ok) {
        throw Error('Feedback failure')
      }
      dispatch(feedbackSmartReplyActions.success({ messageId, feedbackType }))
    } catch (e) {
      dispatch(feedbackSmartReplyActions.failure({ message: e.message }))
      throw e
    }
  }
}

export function printThread(threadId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const orderId = getOrderId(state)
    const messageIds = getThreadMessageIds(threadId)(state)

    if (
      orderId &&
      !messageIds.every(messageId => {
        const labelIds = getMessageLabelIds(messageId)(state)
        return (
          labelIds.includes(labelNames.spam) ||
          labelIds.includes(labelNames.trash)
        )
      })
    ) {
      window.open(
        generatePath(routePaths.threadPrintPreview, {
          userId: orderId,
          thread: threadId,
        }),
        '_blank'
      )
    } else {
      dispatch(
        showModal({
          key: modalTypes.error,
          props: {
            message: i18next.t('thread.error.deletedConversation'),
          },
        })
      )
    }
  }
}

export function printMessage(messageId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const orderId = getOrderId(state)
    const labelIds = getMessageLabelIds(messageId)(state)

    if (
      orderId &&
      !(
        labelIds.includes(labelNames.spam) ||
        labelIds.includes(labelNames.trash)
      )
    ) {
      window.open(
        generatePath(routePaths.messagePrintPreview, {
          userId: orderId,
          message: messageId,
        }),
        '_blank'
      )
    } else {
      dispatch(
        showModal({
          key: modalTypes.error,
          props: {
            message: i18next.t('thread.error.deletedConversation'),
          },
        })
      )
    }
  }
}

export function showOriginalMessage(messageId: string): ThunkActon {
  return (dispatch, getState) => {
    dispatch(
      showModal({
        key: modalTypes.showOriginal,
        props: {
          messageId,
        },
      })
    )
  }
}

export function fetchOriginalMessage(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    return client.messages.fetchOriginal(messageId, { auth })
  }
}

export function autoScroll(threadId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const unreadMessageIds = getThreadMessageIdsByLabels(threadId, {
      [labelNames.unread]: true,
      [labelNames.trash]: false,
    })(state)

    const containDraftMessageIds = getThreadContainDraftMessageIds(threadId, {
      [labelNames.trash]: false,
    })(state)

    if (unreadMessageIds.length) {
      scrollToMessage(unreadMessageIds[0])
    } else if (containDraftMessageIds.length) {
      const draftId = last(containDraftMessageIds)
      scrollToDraft(draftId)
    }
  }
}

export function showImages(
  messageId: string,
  enabled: boolean = true
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const message = getMessage(messageId)(state)
    const contact = getInnerContactByEmail(message.from.email)(state)

    if (contact && contact.status !== contactTypes.PENDING) {
      return dispatch(enableShowImage(contact, enabled))
    }
  }
}

export function getThreadPaginationThreadIds(labelId: string) {
  return (dispatch, getState) => {
    const state = getState()
    // const cleanupModal = getModalStatus(modalTypes.suggestedCleanupCalendar)(state)
    // if (cleanupModal) {
    //   const { type: cleanupType } = getModal(modalTypes.suggestedCleanupCalendar)(state)
    //   const { threadIds } = getCleanup(cleanupType)(state)
    //   return threadIds
    // }
    if (labelId === 'search') {
      return getSearchThreadIds()(state)
    }

    return getLabelThreads(labelId, true)(state)
  }
}

export function getThreadPaginationTotal(labelId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()

    if (labelId === 'search') {
      return getSearchCount()(state)
    }

    const threadPagination = getThreadPagination(state)

    return threadPagination.total
  }
}
