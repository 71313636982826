export let onLine = true

window.addEventListener('online', () => {
  onLine = true
})

window.addEventListener('offline', () => {
  onLine = false
})
export async function waitOnLine() {
  if (!onLine) {
    let event
    const promise = new Promise((resolve, reject) => {
      window.addEventListener('online', (event = resolve))
    })

    await promise

    window.removeEventListener('online', event)
  }
}
