// @flow
import i18next from 'i18next'
import qs from 'qs'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'
import fromPairs from 'lodash/fromPairs'
import uuid from 'uuid'

import * as client from '@edison/webmail-core/api/custom-domains'
import * as authClient from '@edison/webmail-core/api/auth'
import { base64ToFile } from '@edison/webmail-core/utils/file'
import { getAuth, getOrderId } from 'core/auth/selectors'
import {
  getSubaccountsById,
  getCurrentWebDomain,
  shouldRedirect,
  isInOnmail,
  isDomainAlias,
  getOnmailDomain,
} from './selectors'
import { getSuperSessionId } from 'common/storage'
import { getCurrentPlan } from 'core/premium/selectors'
import { createAction } from 'utils/redux'
import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'
import {
  DOMAIN_TICKET_HEADER,
  DOMAIN_HEADER,
  routePaths,
} from 'utils/constants'
import { premium as premiumEvents } from 'core/analytics/actions'
import { requireConfirmation, requirePaymentMethod } from 'utils/stripe'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  UpdateCustomDomainAssetsRequest,
  UpdateCustomDomainAssetsSuccess,
  UpdateCustomDomainAssetsFailure,
  UploadCustomDomainIconOrLogoRequest,
  UploadCustomDomainIconOrLogoSuccess,
  UploadCustomDomainIconOrLogoFailure,
  CustomDomainDetailRequest,
  CustomDomainDetailSuccess,
  CustomDomainDetailFailure,
  CustomDomainPurchaseRequest,
  CustomDomainPurchaseSuccess,
  CustomDomainPurchaseFailure,
  CustomDomainVerifyRequest,
  CustomDomainVerifySuccess,
  CustomDomainVerifyFailure,
  CustomDomainDnsSetupInfoRequest,
  CustomDomainDnsSetupInfoSuccess,
  CustomDomainDnsSetupInfoFailure,
  CustomDomainSearchRequest,
  CustomDomainSearchSuccess,
  CustomDomainSearchFailure,
  CustomDomainConnectRequest,
  CustomDomainConnectSuccess,
  CustomDomainConnectFailure,
  SubaccountListRequest,
  SubaccountListSuccess,
  SubaccountListFailure,
  SubaccountCreateRequest,
  SubaccountCreateSuccess,
  SubaccountCreateFailure,
  SubaccountDeleteRequest,
  SubaccountDeleteSuccess,
  SubaccountDeleteFailure,
  SubaccountResetPasswordLinkRequest,
  SubaccountResetPasswordLinkSuccess,
  SubaccountResetPasswordLinkFailure,
  SubaccountInvitationLinkRequest,
  SubaccountInvitationLinkSuccess,
  SubaccountInvitationLinkFailure,
  CustomDomainDisconnectRequest,
  CustomDomainDisconnectSuccess,
  CustomDomainDisconnectFailure,
  FetchCustomDomainAssetsRequest,
  FetchCustomDomainAssetsSuccess,
  FetchCustomDomainAssetsFailure,
  ClearDomainSearchResults,
  AddOnmailAliasRequest,
  AddOnmailAliasSuccess,
  AddOnmailAliasFailure,
  GetDnsRecordsRequest,
  GetDnsRecordsSuccess,
  GetDnsRecordsFailure,
  UpdateDnsRecordsRequest,
  UpdateDnsRecordsSuccess,
  UpdateDnsRecordsFailure,
} from './types'
import type { Domain, DnsInfo } from '@edison/webmail-core/types/custom-domains'

export const getDomainDetailActions: {
  request: ActionCreator<CustomDomainDetailRequest>,
  success: ActionCreator<CustomDomainDetailSuccess>,
  failure: ActionCreator<CustomDomainDetailFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_DETAIL_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_DETAIL_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_DETAIL_FAILURE'),
}

export function fetchDomainDetail({
  redirect = false,
}: { redirect?: boolean } = {}): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        getDomainDetailActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(getDomainDetailActions.request())
      const res = await client.currentDomain({ auth })
      const { domain, isAdmin, aliases } = res.result
      dispatch(getDomainDetailActions.success({ domain, isAdmin, aliases }))

      if (redirect && shouldRedirect(getState())) {
        return toDomainLogin(dispatch, getState)
      }

      return true
    } catch (e) {
      if (e.status === 404) {
        // Missing custom domain
        if (
          redirect &&
          (!isInOnmail(getState()) || isDomainAlias(getState()))
        ) {
          return backToOnmail(dispatch, getState)
        }
        dispatch(
          getDomainDetailActions.success({
            domain: null,
            isAdmin: true,
            aliases: [],
          })
        )
        return true
      } else if (e.status === 401) {
        dispatch(
          getDomainDetailActions.success({
            domain: null,
            isAdmin: false,
            aliases: [],
          })
        )
        return true
      } else {
        dispatch(getDomainDetailActions.failure({ message: e.message }))
      }
      return false
    }
  }
}

function toDomainLogin(dispatch, getState) {
  const auth = getAuth()(getState())
  if (auth === null) {
    return
  }

  return new Promise(async resolve => {
    try {
      const sessionId = getSuperSessionId()
      const ticketRes = await authClient.getTicket({ auth, sessionId })
      const redirectTicket = ticketRes.getHeader(DOMAIN_TICKET_HEADER)
      let redirectDomain = ticketRes.getHeader(DOMAIN_HEADER)

      if (process.env.NODE_ENV !== 'production') {
        // For development purposes
        redirectDomain = 'localhost:4000'
      }

      const { host, search, pathname } = window.location
      if (!redirectTicket || !redirectDomain || redirectDomain === host) {
        // Do not redirect if domain or ticket is missing, or domain is not
        // the same as current domain
        throw new Error()
      }

      const currentParams = qs.parse(search.substring(1))
      const orderId = getOrderId(getState())
      const params = qs.stringify({
        ...currentParams,
        ticket: redirectTicket,
        email: auth.user,
        sessionId,
        orderId,
        next: encodeURIComponent(pathname + search),
      })
      const url = `https://${redirectDomain}${routePaths.domainLogin}?${params}`

      // Redirect to target domain, and pause the function at current domain
      console.log('Custom domain URL found, redirecting user to', url)
      window.location.replace(url)
    } catch (e) {
      // No redirect, move on
      resolve(true)
    }
  })
}

function backToOnmail(dispatch, getState) {
  return new Promise(resolve => {
    const onmailDomain = getOnmailDomain(getState())
    const { pathname, search } = window.location
    const url = `https://mail.${onmailDomain}${pathname}${search}`
    if (process.env.NODE_ENV === 'production') {
      window.location.replace(url)
    } else {
      console.log('Custom domain invalid, redirecting back to', url)
      resolve(true)
    }
  })
}

export const fetchCustomDomainAssetsActions: {
  request: ActionCreator<FetchCustomDomainAssetsRequest>,
  success: ActionCreator<FetchCustomDomainAssetsSuccess>,
  failure: ActionCreator<FetchCustomDomainAssetsFailure>,
} = {
  request: createAction('FETCH_CUSTOM_DOMAIN_ASSETS_REQUEST'),
  success: createAction('FETCH_CUSTOM_DOMAIN_ASSETS_SUCCESS'),
  failure: createAction('FETCH_CUSTOM_DOMAIN_ASSETS_FAILURE'),
}

export function fetchCustomDomainAssets(): ThunkAction {
  return async (dispatch, getState) => {
    const domain = getCurrentWebDomain(getState())
    try {
      dispatch(fetchCustomDomainAssetsActions.request())
      // Reduce the network request
      // If the domain is invalid
      if (!!domain) {
        const res = await client.getCustomDomainAssets(domain)
        dispatch(fetchCustomDomainAssetsActions.success(res.result))
      } else {
        dispatch(
          fetchCustomDomainAssetsActions.failure({
            message: 'Invalid Domain',
          })
        )
      }
    } catch (e) {
      dispatch(fetchCustomDomainAssetsActions.failure({ message: e.message }))
    }
  }
}

export const updateCustomDomainAssetsActions: {
  request: ActionCreator<UpdateCustomDomainAssetsRequest>,
  success: ActionCreator<UpdateCustomDomainAssetsSuccess>,
  failure: ActionCreator<UpdateCustomDomainAssetsFailure>,
} = {
  request: createAction('UPDATE_CUSTOM_DOMAIN_ASSETS_REQUEST'),
  success: createAction('UPDATE_CUSTOM_DOMAIN_ASSETS_SUCCESS'),
  failure: createAction('UPDATE_CUSTOM_DOMAIN_ASSETS_FAILURE'),
}
const debouncedUpdateCustomDomainAssets = debounce(
  async (dispatch, auth, companyName, color) => {
    try {
      dispatch(updateCustomDomainAssetsActions.request())
      await client.updateCustomDomainAssets(companyName, color, { auth })
      dispatch(updateCustomDomainAssetsActions.success({ companyName, color }))
    } catch (e) {
      dispatch(updateCustomDomainAssetsActions.failure({ message: e.message }))
    }
  },
  500
)

export function updateCustomDomainAssets(
  companyName: string,
  color: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        updateCustomDomainAssetsActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }
    return debouncedUpdateCustomDomainAssets(dispatch, auth, companyName, color)
  }
}

export const uploadCustomDomainIconOrLogoActions: {
  request: ActionCreator<UploadCustomDomainIconOrLogoRequest>,
  success: ActionCreator<UploadCustomDomainIconOrLogoSuccess>,
  failure: ActionCreator<UploadCustomDomainIconOrLogoFailure>,
} = {
  request: createAction('UPLOAD_CUSTOM_DOMAIN_ICON_OR_LOGO_REQUEST'),
  success: createAction('UPLOAD_CUSTOM_DOMAIN_ICON_OR_LOGO_SUCCESS'),
  failure: createAction('UPLOAD_CUSTOM_DOMAIN_ICON_OR_LOGO_FAILURE'),
}

export function updateCustomDomainIconOrLogo(
  domain: string,
  type: string,
  assets: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        uploadCustomDomainIconOrLogoActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }
    try {
      dispatch(uploadCustomDomainIconOrLogoActions.request())
      const file = await base64ToFile(assets, `${auth.user}-${type}.jpg`)
      const refreshUrl = await client.uploadCustomDomainIconOrLogo(
        domain,
        type,
        file,
        {
          auth,
        }
      )
      const name = `${type}Url`
      dispatch(
        uploadCustomDomainIconOrLogoActions.success({ name, refreshUrl })
      )
    } catch (e) {
      dispatch(
        uploadCustomDomainIconOrLogoActions.failure({ message: e.message })
      )
    }
  }
}

export const purchaseDomainActions: {
  request: ActionCreator<CustomDomainPurchaseRequest>,
  success: ActionCreator<CustomDomainPurchaseSuccess>,
  failure: ActionCreator<CustomDomainPurchaseFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_PURCHASE_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_PURCHASE_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_PURCHASE_FAILURE'),
}

export function purchaseDomain(
  domain: string,
  price: string,
  currency: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        purchaseDomainActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }

    try {
      dispatch(purchaseDomainActions.request())
      const res = await client.purchaseDomain(domain, price, currency, { auth })
      const { success, subscription, domain: _domain } = res

      if (
        requirePaymentMethod(subscription) ||
        requireConfirmation(subscription) ||
        !success
      ) {
        throw new Error(
          get(
            subscription,
            'latest_invoice.payment_intent.last_payment_error.message'
          )
        )
      }

      dispatch(premiumEvents.userPurchaseDomain(price, currency))
      dispatch(purchaseDomainActions.success({ domain: _domain }))
      return true
    } catch (e) {
      dispatch(purchaseDomainActions.failure({ message: e.message }))
      return false
    }
  }
}

export const verifyDomainActions: {
  request: ActionCreator<CustomDomainVerifyRequest>,
  success: ActionCreator<CustomDomainVerifySuccess>,
  failure: ActionCreator<CustomDomainVerifyFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_VERIFY_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_VERIFY_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_VERIFY_FAILURE'),
}

export function verifyDomain(
  domain: Domain,
  notify?: boolean = true
): ThunkAction {
  return async (dispatch, getState) => {
    const verificationFailure = () => {
      if (notify) {
        const message = domain.userOwned
          ? i18next.t('customDomain.verification.userOwned.failure')
          : i18next.t('customDomain.verification.hosted.failure')
        dispatch(showNotification(message, toastVariants.error))
      }
    }

    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        verifyDomainActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }

    try {
      dispatch(verifyDomainActions.request())
      const res = await client.verifyDomain(domain.domain, { auth })
      dispatch(verifyDomainActions.success({ records: res.result }))
    } catch (e) {
      verificationFailure()
      dispatch(verifyDomainActions.failure({ message: e.message }))
      return false
    }
  }
}

export const getDnsSetupInfoActions: {
  request: ActionCreator<CustomDomainDnsSetupInfoRequest>,
  success: ActionCreator<CustomDomainDnsSetupInfoSuccess>,
  failure: ActionCreator<CustomDomainDnsSetupInfoFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_DNS_SETUP_INFO_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_DNS_SETUP_INFO_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_DNS_SETUP_INFO_FAILURE'),
}

export function getDnsSetupInfo(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        getDnsSetupInfoActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(getDnsSetupInfoActions.request())
      const res = await client.dnsSetupInfo({ auth })
      dispatch(
        getDnsSetupInfoActions.success({
          info: res.result,
        })
      )
      return true
    } catch (e) {
      dispatch(getDnsSetupInfoActions.failure({ message: e.message }))
      return false
    }
  }
}

export const searchDomainsActions: {
  request: ActionCreator<CustomDomainSearchRequest>,
  success: ActionCreator<CustomDomainSearchSuccess>,
  failure: ActionCreator<CustomDomainSearchFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_SEARCH_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_SEARCH_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_SEARCH_FAILURE'),
}

const debouncedSearch = debounce(
  async (dispatch, query, limit, username, fullName) => {
    const requestId = uuid()
    dispatch(
      searchDomainsActions.request({ limit }, { request: { id: requestId } })
    )
    try {
      const [res, availability] = await Promise.all([
        client.searchDomains(query, { limit, username }),
        Promise.all(
          [username, fullName].filter(Boolean).map(async username => {
            let res: [string, boolean]
            try {
              await authClient.isUsernameAvailable({ username })
              res = [username, true]
            } catch (e) {
              res = [username, false]
            } finally {
              return res
            }
          })
        ),
      ])

      const onmailUsernames = fromPairs(availability)
      const domains = get(res, 'result.domains', [])
      dispatch(
        searchDomainsActions.success(
          { username, domains, onmailUsernames },
          { request: { id: requestId } }
        )
      )
      return true
    } catch (e) {
      dispatch(
        searchDomainsActions.failure({
          message: e.message,
        })
      )
      return false
    }
  },
  500
)

export function searchDomains(
  query: string,
  params?: { limit?: number, username?: string, fullName?: string } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const { limit = 150, username, fullName } = params
    if (query.trim().length === 0) {
      return false
    }

    return debouncedSearch(dispatch, query, limit, username, fullName)
  }
}

export const connectDomainActions: {
  request: ActionCreator<CustomDomainConnectRequest>,
  success: ActionCreator<CustomDomainConnectSuccess>,
  failure: ActionCreator<CustomDomainConnectFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_CONNECT_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_CONNECT_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_CONNECT_FAILURE'),
}

export function connectDomain(domain: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        connectDomainActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(connectDomainActions.request({ domain }))
      const res = await client.connectDomain(domain, { auth })
      dispatch(
        connectDomainActions.success({ domain: res.result, isAdmin: true })
      )
      return true
    } catch (e) {
      switch (e.status) {
        case 409:
          dispatch(
            showNotification(
              i18next.t('customDomain.connect.userDomain.exists'),
              toastVariants.error
            )
          )
          break
        default:
          dispatch(
            showNotification(
              i18next.t('customDomain.connect.userDomain.invalid'),
              toastVariants.error
            )
          )
          break
      }

      dispatch(
        connectDomainActions.failure({
          message: e.status,
        })
      )
      return false
    }
  }
}

export const disconnectDomainActions: {
  request: ActionCreator<CustomDomainDisconnectRequest>,
  success: ActionCreator<CustomDomainDisconnectSuccess>,
  failure: ActionCreator<CustomDomainDisconnectFailure>,
} = {
  request: createAction('CUSTOM_DOMAIN_DISCONNECT_REQUEST'),
  success: createAction('CUSTOM_DOMAIN_DISCONNECT_SUCCESS'),
  failure: createAction('CUSTOM_DOMAIN_DISCONNECT_FAILURE'),
}

export function disconnectDomain(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        disconnectDomainActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(disconnectDomainActions.request())
      await client.disconnectDomain({ auth })
      dispatch(disconnectDomainActions.success())
      return true
    } catch (e) {
      dispatch(
        disconnectDomainActions.failure({
          message: e.message,
        })
      )
      dispatch(
        showNotification(
          i18next.t('settings.domain.disconnect.failure'),
          toastVariants.error
        )
      )
      return false
    }
  }
}

export const createSubaccountAction: {
  request: ActionCreator<SubaccountCreateRequest>,
  success: ActionCreator<SubaccountCreateSuccess>,
  failure: ActionCreator<SubaccountCreateFailure>,
} = {
  request: createAction('SUBACCOUNT_CREATE_REQUEST'),
  success: createAction('SUBACCOUNT_CREATE_SUCCESS'),
  failure: createAction('SUBACCOUNT_CREATE_FAILURE'),
}

export function createSubaccount(
  accountName: string,
  extras?: { thisUser: boolean } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const currentPlan = getCurrentPlan(getState())

    let resp = {
      isSuccess: false,
      message: '',
      res: {},
    }
    if (auth === null) {
      dispatch(
        createSubaccountAction.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return resp
    }

    try {
      dispatch(createSubaccountAction.request())
      const res = await client.createSubaccount(
        { accountName: accountName.trim(), ...extras },
        { auth }
      )
      dispatch(
        premiumEvents.userPurchasePlan(currentPlan ? currentPlan.id : '0')
      )
      dispatch(createSubaccountAction.success(res.result))
      return {
        ...resp,
        isSuccess: true,
        res: res.result,
      }
    } catch (e) {
      dispatch(
        createSubaccountAction.failure({
          message: e.message,
        })
      )

      switch (e.status) {
        case 409:
          return {
            ...resp,
            message: i18next.t('settings.customDomain.error.duplicated'),
          }
        case 400: // TODO: Deprecated
          return {
            ...resp,
            message: e.message,
          }
        case 104: // Pending downgrade
          return {
            ...resp,
            message: i18next.t('settings.customDomain.error.pendingDowngrade'),
          }
        case 105: // Pending cancellation
          return {
            ...resp,
            message: i18next.t(
              'settings.customDomain.error.pendingCancellation'
            ),
          }
        case 106: // Insufficient funds
          return {
            ...resp,
            message: i18next.t('settings.customDomain.error.insufficientFunds'),
          }
        default:
          return {
            ...resp,
            message: i18next.t('settings.customDomain.error.invalidEmail'),
          }
      }
    }
  }
}

export const deleteSubaccountAction: {
  request: ActionCreator<SubaccountDeleteRequest>,
  success: ActionCreator<SubaccountDeleteSuccess>,
  failure: ActionCreator<SubaccountDeleteFailure>,
} = {
  request: createAction('SUBACCOUNT_DELETE_REQUEST'),
  success: createAction('SUBACCOUNT_DELETE_SUCCESS'),
  failure: createAction('SUBACCOUNT_DELETE_FAILURE'),
}

export function deleteSubaccount(accountId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const subaccounts = getSubaccountsById(state)
    if (auth === null) {
      dispatch(
        deleteSubaccountAction.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      const toDeleted = subaccounts[accountId]
      // Return if the ID is not in store
      if (isNil(toDeleted)) return

      dispatch(deleteSubaccountAction.request())
      await client.deleteSubaccount(accountId, { auth })
      dispatch(deleteSubaccountAction.success(toDeleted))
      return true
    } catch (e) {
      dispatch(
        deleteSubaccountAction.failure({
          message: e.message,
        })
      )

      let message
      switch (e.status) {
        case 106:
          message = i18next.t(
            'settings.customDomain.subaccountDetail.deletionConfirm.pastDue'
          )
          break
        default:
          message = i18next.t(
            'settings.customDomain.subaccountDetail.deletionConfirm.failed'
          )
      }

      dispatch(showNotification(message, toastVariants.error))
      return false
    }
  }
}

export const fetchSubaccountsAction: {
  request: ActionCreator<SubaccountListRequest>,
  success: ActionCreator<SubaccountListSuccess>,
  failure: ActionCreator<SubaccountListFailure>,
} = {
  request: createAction('SUBACCOUNT_LIST_REQUEST'),
  success: createAction('SUBACCOUNT_LIST_SUCCESS'),
  failure: createAction('SUBACCOUNT_LIST_FAILURE'),
}

export function fetchSubaccounts(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        fetchSubaccountsAction.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchSubaccountsAction.request())
      const res = await client.getSubaccounts({ auth })
      dispatch(fetchSubaccountsAction.success(res.result))
    } catch (e) {
      dispatch(fetchSubaccountsAction.failure({ message: e.message }))
    }
  }
}

// $FlowFixMe
export const subaccountSignupActions = {
  request: createAction('SUBACCOUNT_SIGNUP_REQUEST'),
  success: createAction('SUBACCOUNT_SIGNUP_SUCCESS'),
  failure: createAction('SUBACCOUNT_SIGNUP_FAILURE'),
}

export const subaccountResetPasswordLinkActions: {
  request: ActionCreator<SubaccountResetPasswordLinkRequest>,
  success: ActionCreator<SubaccountResetPasswordLinkSuccess>,
  failure: ActionCreator<SubaccountResetPasswordLinkFailure>,
} = {
  request: createAction('SUBACCOUNT_RESET_PASSWORD_LINK_REQUEST'),
  success: createAction('SUBACCOUNT_RESET_PASSWORD_LINK_SUCCESS'),
  failure: createAction('SUBACCOUNT_RESET_PASSWORD_LINK_FAILURE'),
}

export function fetchResetPasswordLink(id: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        subaccountResetPasswordLinkActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(subaccountResetPasswordLinkActions.request())
      const res = await client.getResetPasswordLink(id, { auth })
      dispatch(
        subaccountResetPasswordLinkActions.success({
          ...res.result,
          id,
        })
      )
    } catch (e) {
      dispatch(
        subaccountResetPasswordLinkActions.failure({
          message: e.message,
        })
      )
    }
  }
}

export const subaccountInvitationLinkActions: {
  request: ActionCreator<SubaccountInvitationLinkRequest>,
  success: ActionCreator<SubaccountInvitationLinkSuccess>,
  failure: ActionCreator<SubaccountInvitationLinkFailure>,
} = {
  request: createAction('SUBACCOUNT_INVITATION_LINK_REQUEST'),
  success: createAction('SUBACCOUNT_INVITATION_LINK_SUCCESS'),
  failure: createAction('SUBACCOUNT_INVITATION_LINK_FAILURE'),
}

export function fetchSubaccountInvitationLink(id: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        subaccountInvitationLinkActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(subaccountInvitationLinkActions.request())
      const res = await client.getInvitationLink(id, { auth })
      dispatch(
        subaccountInvitationLinkActions.success({
          ...res.result,
          id,
        })
      )
    } catch (e) {
      dispatch(subaccountInvitationLinkActions.failure({ message: e.message }))
    }
  }
}

export const addOnmailAliasActions: {
  request: ActionCreator<AddOnmailAliasRequest>,
  success: ActionCreator<AddOnmailAliasSuccess>,
  failure: ActionCreator<AddOnmailAliasFailure>,
} = {
  request: createAction('ADD_ONMAIL_ALIAS_REQUEST'),
  success: createAction('ADD_ONMAIL_ALIAS_SUCCESS'),
  failure: createAction('ADD_ONMAIL_ALIAS_FAILURE'),
}

export function addOnmailAlias(alias: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        addOnmailAliasActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(addOnmailAliasActions.request())
      // Check for alias availability
      await authClient.isUsernameAvailable({ username: alias })

      const res = await client.addOnmailAlias(alias, { auth })
      dispatch(addOnmailAliasActions.success({ alias: res.result.alias }))

      return { isSuccess: true }
    } catch (e) {
      dispatch(addOnmailAliasActions.failure({ message: e.message }))
      const isSuccess = false
      switch (e.status) {
        case 409:
          return {
            isSuccess,
            message: i18next.t('settings.customDomain.error.duplicated'),
          }
        case 400:
          return {
            isSuccess,
            message: e.message,
          }
        default:
          return {
            isSuccess,
            message: i18next.t('settings.customDomain.error.invalidEmail'),
          }
      }
    }
  }
}

export const clearSearchResults: ActionCreator<ClearDomainSearchResults> = createAction(
  'CLEAR_DOMAIN_SEARCH_RESULTS'
)

export const getDnsRecordsActions: {
  request: ActionCreator<GetDnsRecordsRequest>,
  success: ActionCreator<GetDnsRecordsSuccess>,
  failure: ActionCreator<GetDnsRecordsFailure>,
} = {
  request: createAction('GET_DNS_RECORDS_REQUEST'),
  success: createAction('GET_DNS_RECORDS_SUCCESS'),
  failure: createAction('GET_DNS_RECORDS_FAILURE'),
}

export function getDnsRecords(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (isNil(auth)) {
      dispatch(getDnsRecordsActions.failure({ error: {} }))
      return
    }

    try {
      dispatch(getDnsRecordsActions.request())
      const res = await client.getDnsRecords({ auth })
      const records = res.result

      dispatch(getDnsRecordsActions.success({ records }))
      return true
    } catch (e) {
      dispatch(getDnsRecordsActions.failure({ error: e }))
      return false
    }
  }
}

export const updateDnsRecordsActions: {
  request: ActionCreator<UpdateDnsRecordsRequest>,
  success: ActionCreator<UpdateDnsRecordsSuccess>,
  failure: ActionCreator<UpdateDnsRecordsFailure>,
} = {
  request: createAction('UPDATE_DNS_RECORDS_REQUEST'),
  success: createAction('UPDATE_DNS_RECORDS_SUCCESS'),
  failure: createAction('UPDATE_DNS_RECORDS_FAILURE'),
}

export function updateDnsRecords(
  records: $ReadOnlyArray<DnsInfo>
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (isNil(auth)) {
      dispatch(
        updateDnsRecordsActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(updateDnsRecordsActions.request())
      const res = await client.updateDnsRecords(records, { auth })

      if (res.status === 200) {
        // Success
        dispatch(updateDnsRecordsActions.success({ records: res.result }))
        return true
      } else {
        // GoDaddy PUT API error response
        dispatch(updateDnsRecordsActions.failure({ errors: res.result }))
        return false
      }
    } catch (e) {
      // Our own server error
      dispatch(
        updateDnsRecordsActions.failure({
          message: e.message,
        })
      )

      return false
    }
  }
}

export const clearDnsRecordsErrors = createAction<ClearDnsRecordsErrors>(
  'CLEAR_DNS_RECORDS_ERRORS'
)
