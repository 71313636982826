// @flow
import React, { useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as premium from 'core/premium'
import * as customDomains from 'core/custom-domains'
import * as usage from 'core/usage'
import { useModal } from 'common/modals'
import { useInboxZeroConditon } from 'core/hooks'
import { useOrderId } from 'core/auth/hooks'
import {
  useRetrofitSyncProgress,
  useErrorRetrofitAccount,
} from 'core/retrofit/hooks'
import { useBetaFeature } from 'core/beta-features/hooks'
import { useCurrentBreak } from 'core/inbox-break/hooks'
import { useCurrentPlan } from 'core/premium/hooks'
import { useRecoveryEmail } from 'core/settings/hooks'
import RetrofitAccountError from 'screens/Retrofit/AccountErrorBanner'
import RetrofitSyncSuccess from 'screens/Retrofit/SyncSuccessBanner'
import { modalTypes, routePaths, NAVS } from 'utils/constants'
import { betaFeatures } from '@edison/webmail-core/utils/constants'

import {
  PaymentError,
  Pending,
  StorageWarning,
  StorageError,
  RecoveryEmail,
  DomainSetupWarning,
} from '@edison/webmail-ui/components/Banner'
import { Banner as BreakBanner } from '@edison/webmail-ui/components/InboxBreak'
import { ForceOnMailDarkTheme } from '@edison/webmail-ui/styles/theme'

const View = () => {
  const [hidden, setHidden] = useState<{ [key: string]: boolean }>({})
  const hasPaymentError = useSelector(premium.selectors.getPaymentError)
  const isFetchingPaymentMethod = useSelector(
    premium.selectors.getPaymentMethodLoading
  )
  const isPendingVerification = useSelector(
    customDomains.selectors.isPendingVerification
  )
  const isFetchingDomainDetail = useSelector(
    customDomains.selectors.getCurrentDomainLoading
  )
  const hasDomainSetupError = useSelector(
    customDomains.selectors.hasDomainSetupError
  )
  const isDomainAdmin = useSelector(customDomains.selectors.isDomainAdmin)
  const usageRate = useSelector(usage.selectors.getUsageRate())
  const history = useHistory()
  const recoveryEmail = useRecoveryEmail()
  const userId = useOrderId()
  const { canUpgrade } = useCurrentPlan()
  const { newAccounts, isFullSync } = useRetrofitSyncProgress()
  const errorRetrofitAccount = useErrorRetrofitAccount()
  const { isEnable: isRetrofitEnabel } = useBetaFeature(betaFeatures.retrofit)

  const endBreakConfirmModal = useModal(modalTypes.endBreakConfirm)
  const currBreak = useCurrentBreak()

  const invert = useInboxZeroConditon()

  const goToSettings = (section?: string, subsection?: string) => {
    let next
    if (!!section && !!subsection) {
      next = generatePath(routePaths.settingsSubsection, {
        userId,
        section,
        subsection,
      })
    } else {
      next = generatePath(routePaths.settingsSection, {
        userId,
        section: section || NAVS.account.value,
      })
    }
    history.push(next)
  }

  // Component will return the first item that has a true condition
  const items = [
    {
      key: 'recovery',
      node: (
        <RecoveryEmail
          transparent={invert}
          isSending={false}
          email={recoveryEmail.email}
          onResend={async () => {
            const success = await recoveryEmail.resend()
            success && setHidden({ ...hidden, recovery: true })
          }}
        />
      ),
      condition: recoveryEmail.email && !recoveryEmail.isVerified,
    },
    {
      key: 'payment-error',
      node: (
        <PaymentError
          transparent={invert}
          goToSettings={() => goToSettings()}
        />
      ),
      condition: !isFetchingPaymentMethod && hasPaymentError,
    },
    {
      key: 'retrofit',
      node: (
        <RetrofitAccountError
          transparent={invert}
          account={errorRetrofitAccount}
        />
      ),
      condition: isRetrofitEnabel && errorRetrofitAccount,
    },
    {
      key: 'storage',
      node: (
        <StorageError
          isImporting={!isFullSync}
          transparent={invert}
          onUpgrade={canUpgrade ? () => goToSettings('storage') : undefined}
          onClose={() => setHidden({ ...hidden, storage: true })}
        />
      ),
      condition: usageRate >= 1.0,
    },
    {
      key: 'storage',
      node: (
        <StorageWarning
          transparent={invert}
          onUpgrade={canUpgrade ? () => goToSettings('storage') : undefined}
          onClose={() => setHidden({ ...hidden, storage: true })}
        />
      ),
      condition: usageRate >= 0.75,
    },
    {
      // Banner tells users the account is setting up for hosted domain
      key: 'domain',
      node: <Pending transparent={invert} />,
      condition: !isFetchingDomainDetail && isPendingVerification,
    },
    {
      // Same key as domain, hosted domain takes precedence
      key: 'domain',
      node: (
        <DomainSetupWarning
          transparent={invert}
          isAdmin={isDomainAdmin}
          goToSettings={() => goToSettings('domain', 'settings')}
        />
      ),
      condition: hasDomainSetupError,
    },
    {
      key: 'retrofit-success',
      node: <RetrofitSyncSuccess transparent={invert} />,
      condition: isRetrofitEnabel && newAccounts.length > 0,
    },
  ]

  const toRender = items.find(item => item.condition && !hidden[item.key])

  return (
    <ForceOnMailDarkTheme enable={invert}>
      {toRender && toRender.node}
      <BreakBanner
        invert={invert}
        transparent={invert}
        checked={currBreak.enable}
        endAt={currBreak.breakUntil}
        timezone={currBreak.breakTimezone}
        onClick={endBreakConfirmModal.showModal}
      />
    </ForceOnMailDarkTheme>
  )
}

View.displayName = 'Banner'

export default View
