import React, { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getDraft, isDraftDisabled } from 'core/compose/selectors'

import { updateDraft, debouncedSaveDraft } from 'core/compose/actions'
import InputContainer from './InputContainer'

export default forwardRef(({ draftId }, ref) => {
  const { subject, to, cc, bcc } = useSelector(
    useMemo(() => getDraft(draftId), [draftId])
  )
  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <InputContainer
      label={t('compose.label.subject')}
      labelClassNames="capitalize"
      htmlFor={`${draftId}-subject`}
    >
      <input
        id={`${draftId}-subject`}
        className="w-full h-8 bg-transparent"
        disabled={disabled}
        defaultValue={subject}
        onChange={e => {
          const newSubject = e.target.value
          dispatch(
            updateDraft({
              id: draftId,
              subject: newSubject,
            })
          )
          subject.trim() !== newSubject.trim() &&
            dispatch(debouncedSaveDraft(draftId))
        }}
        autoFocus={!![...to, ...cc, ...bcc].length && !subject.trim().length}
        autoComplete="off"
      />
    </InputContainer>
  )
})
