// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ThreadListItem from '@edison/webmail-ui/components/ThreadListItem'

import AuthAvatar from 'common/AuthAvatar'
import Labels from 'common/Labels'
import { useShowThreadDetail } from 'hooks/useHandleDetailModal'
import { useSenderName, useSenderAvatar } from 'core/threads/hooks'
import { getThread } from 'core/threads/selectors'
import { getThreadMessageIds, getThreadLabelIds } from 'core/metadata/selectors'

import { labelNames } from 'utils/constants'
import { dateFormatter } from 'utils'

import Subject from 'common/ThreadList/Subject'
import { ThreadListItem as ThreadListItemSkeleton } from '@edison/webmail-ui/components/Skeletons'

import type { Thread } from '@edison/webmail-core/types/threads'

type Props = {
  thread: Thread,
  onClick: ?(threadId: string) => void,
  style: Object,
  threadId: string,
  classname?: string,
  isShowLables?: boolean,
}

const View = ({ threadId, style, classname, onClick, isShowLables }: Props) => {
  const showThreadDetail = useShowThreadDetail()
  const { snippet, subject, date, id } = useSelector(
    useMemo(() => getThread(threadId), [threadId])
  )

  const messageIds = useSelector(
    useMemo(() => getThreadMessageIds(threadId), [threadId])
  )

  // Thread status
  const labelIds = useSelector(
    useMemo(() => getThreadLabelIds(threadId), [threadId])
  )
  const status = !labelIds.includes(labelNames.unread)

  // Thread avatar
  const avatar = useSenderAvatar(threadId)

  // Thread senders
  const from = useSenderName(threadId)

  return (
    <ThreadListItem
      style={style}
      labels={
        isShowLables && (
          <Labels
            threadId={id}
            labelIds={labelIds.filter(id => id === labelNames.drafts)}
          />
        )
      }
      className={classname}
      avatar={<AuthAvatar src={avatar} alt={from.name} />}
      fromName={from.name}
      fromAddress={from.email}
      readStatus={status}
      title={<Subject subject={subject} />}
      snippet={snippet}
      receivedAt={dateFormatter(date)}
      actions={null}
      quickActions={null}
      onClick={() => {
        if (onClick) {
          onClick(threadId)
          return
        }
        showThreadDetail(threadId)
      }}
      attachments={null}
      showDot
      messageCount={messageIds.length}
    />
  )
}

export default ({ threadId, ...rest }) => {
  const thread = useSelector(useMemo(() => getThread(threadId), [threadId]))

  if (thread) return <View threadId={threadId} {...rest} />
  return <ThreadListItemSkeleton className="-ml-10" {...rest} />
}
