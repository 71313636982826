// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import {
  getSSFlagByThreadId,
  getRRBFlagByThreadId,
} from 'core/contacts/selectors'

import { SimpleContactDetail } from '@edison/webmail-ui/components/ContactDetail'
import { AllPassMessage } from '@edison/webmail-ui/components/SecurityMessage'
import { Message as ReadReceiptBlockingMessage } from '@edison/webmail-ui/components/SecurityMessage/ReadReceiptBlocking'
import { Message as SuspiciousDetecting } from '@edison/webmail-ui/components/SecurityMessage/SuspiciousDetecting'
import { AuthEmailAvatar } from 'common/AuthAvatar'

type Props = {
  name: string,
  email: string,
  selectedThread?: string,
}
export default ({ name, email, selectedThread }: Props) => {
  const ssFlagByThreadId = useSelector(getSSFlagByThreadId)
  const rrbFlagByThreadId = useSelector(getRRBFlagByThreadId)

  const ssFlag = get(ssFlagByThreadId[email], selectedThread, false)
  const rrbFlag = get(rrbFlagByThreadId[email], selectedThread, false)

  const children = useMemo(() => {
    let nodes = []
    if (rrbFlag) {
      nodes.push(<ReadReceiptBlockingMessage />)
    }
    if (ssFlag) {
      nodes.push(<SuspiciousDetecting />)
    }
    if (nodes.length === 0) {
      nodes.push(<AllPassMessage />)
    }
    return nodes
  }, [ssFlag, rrbFlag])

  return (
    <SimpleContactDetail
      avatar={<AuthEmailAvatar email={email} name={name} />}
      email={email}
      name={name}
    >
      {children}
    </SimpleContactDetail>
  )
}
