// @flow

import type { ActionCreator } from 'types/redux'
import type { ClearCalendarUserData } from './types-common'
import i18next from 'i18next'
import { createAction } from 'utils/redux'
import { showNotification } from '../toasts/actions'
import { toastVariants } from '../../common/toasts'
import {
  RateLimitAddEventText,
  RateLimitGenericText,
} from '@edison/webmail-core/utils/calendar'
export type RequestAPIActionGen<Request, Success, Failure> = {
  request: ActionCreator<Request>,
  success: ActionCreator<Success>,
  failure: ActionCreator<Failure>,
}
export const AuthMissing = i18next.t('notAuthenticated')
export const ServerError = i18next.t('error.application.subtitle[0]')
export const clearCalendarUserDataAction: ActionCreator<ClearCalendarUserData> = createAction(
  'CalendarClearUserData'
)
const showErrors = [ServerError, RateLimitGenericText, RateLimitAddEventText]
export const showErrorNotification = (dispatch, error) => {
  if (
    dispatch &&
    error &&
    error.message &&
    showErrors.includes(error.message)
  ) {
    dispatch(showNotification(error.message, toastVariants.error))
  }
}
