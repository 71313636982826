import React from 'react'
import { useDispatch } from 'react-redux'
import MfaLogin from '@edison/webmail-ui/screens/TwoFactorAuthentication/MfaLogin'

import { sendSMS } from '@edison/webmail-core/api/mfa'
import { login } from 'core/auth/actions'

export default function MfaLoginModal({
  isOpen,
  onClose,
  mfa,
  redirect = true,
  onLogin,
}) {
  const { email, password, token, digits, method } = mfa

  const dispatch = useDispatch()
  const handleVerify = mfaCode => {
    return dispatch(login(email, password, { mfaCode, redirect }))
  }

  return (
    <MfaLogin
      dialog
      isOpen={isOpen}
      onClose={onClose}
      method={method}
      phoneNumber={`******${digits}`}
      onVerify={handleVerify}
      onSendCode={() => sendSMS({}, { token })}
      onValidateRecoveryCode={recoveryCode => {
        return dispatch(login(email, password, { recoveryCode, redirect }))
      }}
    />
  )
}
