// @flow
import React, { useRef, useCallback } from 'react'
import moment from 'moment'
import random from 'lodash/random'
import throttle from 'lodash/throttle'

import { actions, selectors } from 'core/toasts'
import { toastTypes } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'

import Notifications, { LoadingToast } from 'common/notifications'

export { toastVariants } from '@edison/webmail-ui/components/BasicToast'

export const registry = {
  [toastTypes.notification]: Notifications,
  [toastTypes.loading]: LoadingToast,
}

export default () => {
  const toasts = useSelector(selectors.getToasts())
  const types = [...new Set(toasts.map(each => each.props.type))]

  return (
    <React.Fragment>
      {types.map((type, index) => {
        const Component = registry[type]

        return <Component key={index} />
      })}
    </React.Fragment>
  )
}

export function useToast(type: string, props?: { [key: string]: any } = {}) {
  const dispatch = useDispatch()

  const hideToast = useCallback(
    throttle((key: string) => {
      dispatch(actions.hide({ key }))
    }, 300),
    []
  )
  const showToast = useCallback(
    throttle((message: string, variant?: string = '') => {
      const key = (moment().unix() * random(1, 100)).toString(16)
      dispatch(
        actions.show({ key, props: { type, message, variant, ...props } })
      )
      return key
    }, 300),
    []
  )

  return { showToast, hideToast }
}

function initialToastHook(variant) {
  const key = useRef<string>('')
  const { hideToast, showToast } = useToast(variant)

  function handleOnHideToast() {
    if (key.current) hideToast(key.current)
  }

  return {
    showToast: (message: string, variant?: string) => {
      // Reset the loading toast key
      handleOnHideToast()
      key.current = showToast(message, variant)
    },
    hideToast: handleOnHideToast,
  }
}

export function useLoadingToast(): {
  showToast: () => void,
  hideToast: () => void,
} {
  const { showToast, hideToast } = initialToastHook(toastTypes.loading)

  return {
    // Loading toast doesn't need the message
    showToast: () => showToast(''),
    hideToast,
  }
}

export function useNotificationToast(): {
  showToast: (message: string, variant?: string) => void,
  hideToast: () => void,
} {
  return initialToastHook(toastTypes.notification)
}
