// @flow
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

/**
 * Displays the preview modal for either attachment or large attachments.
 *
 * @public
 */
export const usePreviewModal = () => {
  return useModal(modalTypes.preview)
}
