// @flow
export const colorsActionTypes: {
  FetchColorsRequest: 'CALENDAR_COLORS_REQUEST',
  FetchColorsRequestSuccess: 'CALENDAR_COLORS_SUCCESS',
  FetchColorsRequestFailure: 'CALENDAR_COLORS_FAILURE',
} = {
  FetchColorsRequest: 'CALENDAR_COLORS_REQUEST',
  FetchColorsRequestSuccess: 'CALENDAR_COLORS_SUCCESS',
  FetchColorsRequestFailure: 'CALENDAR_COLORS_FAILURE',
}
