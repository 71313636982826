// @flow
import React from 'react'
import { useSelector } from 'react-redux'

import { getSuggestedContacts } from 'core/contacts/selectors'

import { AuthEmailAvatar } from 'common/AuthAvatar'
import Contacts, { Contact } from '@edison/webmail-ui/components/Contacts'

import type { Recipient } from './index'

type Props = {
  showSuggestions: boolean,
  contacts: Array<Recipient>,
  onClick: (index: number) => void,
  onAddRecipient: Recipient => void,
}

const View = ({
  contacts = [],
  onClick,
  onAddRecipient,
  showSuggestions,
}: Props) => {
  const suggestedContacts = useSelector(getSuggestedContacts())

  return (
    <React.Fragment>
      {showSuggestions && suggestedContacts.length > 0 ? (
        <Contacts
          label="Suggested Contacts"
          contacts={suggestedContacts.map(({ name, email, avatar }, index) => (
            <Contact
              name={name}
              email={email}
              avatar={
                <AuthEmailAvatar
                  className="!h-8 !w-8"
                  email={email}
                  name={name}
                />
              }
              onClick={() => onAddRecipient({ name, email, avatar })}
            />
          ))}
        />
      ) : null}
    </React.Fragment>
  )
}

export default View
