// @flow
import Fuse from 'fuse.js'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'
import { getActiveAccount } from 'core/retrofit/selectors'
import {
  MAX_SEARCH_HINTS,
  MAX_SEARCH_HISTORIES,
  searchHintTypes,
} from 'utils/constants'

import type { Suggestion } from '@edison/webmail-core/types/search'
import type { Selector, State } from 'types/state'
import { getIsInitalCondition, initalCondition } from './helpers'
export const getSearchState = (state: State) => state.search

const _getSearchQuery: Selector<string> = createSelector(
  getSearchState,
  state => state.query.trimLeft()
)

const _getSearchCondition = createSelector(
  getSearchState,
  state => state.condition
)

const _getSearchPrefix: Selector<{
  key: string,
  value: string,
  label: string,
}> = createSelector(getSearchState, state => {
  return state.prefix
})

export const _getSelectContactQuery = createSelector(
  getSearchState,
  state => state.selectContactQuery
)

export function getSearchQuery() {
  return _getSearchQuery
}

export function getSearchCondition() {
  return _getSearchCondition
}

export function getSelectContactQuery() {
  return _getSelectContactQuery
}

export const getSearchPriority: Selector<string> = createSelector(
  getSearchState,
  state => state.prioritizedLabel
)

export const _hideSearchRecommend = createSelector(
  getSearchState,
  state => state.hideSearchRecommend
)

export function getHideSearchRecommend() {
  return _hideSearchRecommend
}

export const _getSelectedLabels = createSelector(
  getSearchState,
  state => state.selectedLabels
)

export function getSelectedLabels() {
  return _getSelectedLabels
}

export const getSearchMoreInFlags: Selector<{
  [key: string]: boolean,
}> = createSelector(getSearchState, state => state.moreInFlags)

const _getSearchTokens: Selector<
  $ReadOnlyArray<{
    start: number,
    end: number,
    type: string,
    text: string,
    suggestions: Suggestion[],
    prefix: string,
    suffix: string,
    key?: string,
    value?: string,
  }>
> = createSelector(
  getSearchState,
  getSearchQuery(),
  getSearchPrefix(),
  (state, query: string, searchPrefix: { value: string }) => {
    const prefixLength = searchPrefix.value ? searchPrefix.value.length + 1 : 0

    const [terms, keywords] = [
      // Original query terms
      state.terms,
      // Search hint terms
      state.suggestions
        .filter(item => item.type === searchHintTypes.KEYWORDS)
        .flatMap(items => items.terms),
    ].map(terms =>
      terms
        .map(({ start, end, ...rest }) => ({
          ...rest,
          start: start - prefixLength,
          end: end - prefixLength,
        }))
        .filter(({ start, end }) => start >= 0 && end >= 0)
    )

    // Search history terms
    const histories = state.suggestions
      .filter(item => item.type === searchHintTypes.HISTORY)
      .flatMap(items => items.terms)
      .map(({ end, key, text, ...rest }) => ({
        ...rest,
        end: end - prefixLength,
        key: key.slice(prefixLength),
        text: text.slice(prefixLength),
      }))

    let suggestionStartIndex = 0

    const tokens = terms
      // Perform padding to maintain spacing inside search query
      .reduce((prev, { start, end, type, key, value }, index, src) => {
        let text = query.slice(start, end)

        // Pad text at the end of the query
        //
        // EWM-2051 Should use the length of the filtered terms
        if (index === src.length - 1) {
          text = query.slice(start)
        }

        const next = {
          type,
          start,
          end,
          text,
          key,
          value,
        }

        // Pad characters to the left of the previous term (append)
        if (prev.length === 0 && start !== 0) {
          return [
            {
              type: 'TEXT',
              start: 0,
              end: start,
              text: query.slice(0, start),
            },
            next,
          ]
        }

        if (prev.length > 0) {
          // Pad characters to the right of the previous term (append)
          const last = prev[prev.length - 1]

          if (last.end !== start) {
            const last = prev[prev.length - 1]
            return [
              ...prev,
              {
                type: 'TEXT',
                start: last.end,
                end: start,
                text: query.slice(last.end, start),
              },
              next,
            ]
          }
        }

        // Pad all characters towards the end
        if (index === src.length - 1) {
          return [
            ...prev,
            {
              ...next,
              end: query.length,
              text: query.slice(next.start),
            },
          ]
        }

        return [...prev, next]
      }, [])
      // Populate suggestions
      .map(({ start, end, text, ...rest }) => {
        let suggestions = []
        if (text.trim()) {
          // Suggestions should be intersected with the term
          const related = keywords.filter(
            term => term.start >= start && term.end >= end && end >= term.start
          )

          const fuse = new Fuse(related, {
            keys: ['text'],
            shouldSort: true,
            threshold: 0.3,
          })
          suggestions = fuse.search(text)
        }

        return {
          ...rest,
          start,
          end,
          text,
          suggestions: suggestions.slice(0, MAX_SEARCH_HINTS),
        }
      })
      // Find the first index of token which has suggestions
      .map((token, index) => {
        if (suggestionStartIndex === 0 && token.suggestions.length) {
          suggestionStartIndex = index
        }
        return token
      })
      // Populate histories
      .map(({ start, ...rest }, index) => {
        const _histories: $ReadOnlyArray<{
          start: number,
          end: number,
          type: string,
          text: string,
        }> =
          index === suggestionStartIndex
            ? histories.slice(0, MAX_SEARCH_HISTORIES).map(({ end, text }) => ({
                end,
                start,
                type: 'TEXT',
                text: text.slice(start),
              }))
            : []

        return {
          ...rest,
          start,
          histories: _histories,
        }
      })
      // Get the prefix and suffix for each suggestion
      .map(({ start, suggestions, histories, ...rest }, index) => {
        const prefix = query.slice(start)
        let suffix = '',
          _suggestions = [...suggestions]

        if (index === suggestionStartIndex) {
          suffix = getSearchSuffix(
            prefix,
            get(suggestions, `[0].text`, '').trimEnd()
          )

          if (!suffix && histories.length) {
            suffix = getSearchSuffix(
              prefix,
              get(histories, `[0].text`, '').trimEnd()
            )
            _suggestions = [...histories, ...suggestions]
          } else {
            _suggestions = [
              ...suggestions.slice(0, 1),
              ...histories,
              ...suggestions.slice(1),
            ]
          }
        }

        return {
          ...rest,
          start,
          prefix,
          suffix,
          suggestions: _suggestions,
        }
      })

    return tokens
  }
)
export function getSearchTokens() {
  return _getSearchTokens
}

const _isSearchLoading: Selector<boolean> = createSelector(
  getSearchState,
  getLoadingStatus('SEARCH'),
  (state, isLoading: boolean) => {
    return !isNil(state.requestIds.results)
  }
)
export function isSearchLoading() {
  return _isSearchLoading
}

const _isSearchHintsLoading: Selector<boolean> = createSelector(
  getSearchState,
  getLoadingStatus('SEARCH_HINTS'),
  (state, isLoading: boolean) => {
    return !isNil(state.requestIds.hints) && isLoading
  }
)
export function isSearchHintsLoading() {
  return _isSearchHintsLoading
}

const _getNextPageToken: Selector<?string> = createSelector(
  getSearchState,
  state => state.threadIds.next
)
export function getNextPageToken() {
  return _getNextPageToken
}

const _getHideSuggestions: Selector<boolean> = createSelector(
  getSearchState,
  state => state.hideSuggestions
)
export function getHideSuggestions() {
  return _getHideSuggestions
}

const _getSearchThreadIds: Selector<$ReadOnlyArray<string>> = createSelector(
  getSearchState,
  state => {
    return state.threadIds.ids ? state.threadIds.ids : []
  }
)
export function getSearchThreadIds() {
  return _getSearchThreadIds
}

const _getSearchCount = createSelector(
  getSearchState,
  getSearchThreadIds(),
  (state, ids) => {
    const { total } = state.threadIds
    if (total < 30) {
      return ids.length
    } else return total
  }
)
export function getSearchCount(): Selector<number> {
  return _getSearchCount
}

const _getSearchRecommendations: Selector<{
  contacts: $ReadOnlyArray<{
    id: string,
    name: string,
    email: string,
    avatar: string,
  }>,
  files: $ReadOnlyArray<{
    id: string,
    name: string,
    from: string,
    contentType: string,
    url: string,
    size: number,
    preview: string,
    extendInfo: { [key: string]: any },
  }>,
  images: $ReadOnlyArray<{
    id: string,
    name: string,
    from: string,
    thumbnail: string,
    contentType: string,
    type: string,
    url: string,
    preview: string,
    messageHeaderId: string,
    attachmentsType: string,
  }>,
}> = createSelector(getSearchState, state => {
  const { recommendations } = state
  const contacts = recommendations.contacts.map(
    ({ id, firstName, lastName, emails, avatar }) => ({
      id,
      name: [firstName, lastName]
        .map(name => name.trim())
        .filter(name => name.length > 0)
        .join(' '),
      email: get(emails, '[0].email') || 'Unknown',
      avatar,
    })
  )
  const files = recommendations.files.map(
    ({
      id,
      name,
      url,
      extendInfo,
      contentType,
      type,
      messageHeaderId,
      message,
      preview,
      attachmentsType,
      size,
    }) => ({
      id,
      name,
      from: get(extendInfo, 'from[0].name') || 'Unknown',
      contentType,
      url,
      type,
      messageHeaderId,
      message,
      preview,
      size,
      extendInfo,
      attachmentsType,
    })
  )
  const images = recommendations.images.map(({ extendInfo, ...rest }) => ({
    from: get(extendInfo, 'from[0].name') || 'Unknown',
    extendInfo,
    ...rest,
  }))

  return { contacts, files, images }
})
export function getSearchRecommendations() {
  return _getSearchRecommendations
}

export function getSearchPrefix() {
  return _getSearchPrefix
}

const _isHideRecommendations: Selector<boolean> = createSelector(
  getSearchState,
  state => state.hideRecommendations
)
export function isHideRecommendations() {
  return _isHideRecommendations
}

const _getHideHighlights: Selector<boolean> = createSelector(
  getSearchState,
  state => state.hideHighlights
)
export function getHideHighlights() {
  return _getHideHighlights
}

export function getSearchKeywords(
  field: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(getSearchState, state =>
    get(state.highlightValues, field, [])
  )
}
function getSearchSuffix(prefix: string, input: string) {
  let start = 0
  if (input.length > 0) {
    const compared = input[0].toLowerCase()
    for (let i = 0, length = prefix.length; i < length; i++) {
      if (prefix[i].toLowerCase() === compared) {
        start = i
        break
      }
    }
  }

  const trimedPrefix = prefix.slice(start)

  return input
    .split('')
    .filter(
      (c, i) =>
        isNil(trimedPrefix[i]) ||
        trimedPrefix[i].toLowerCase() !== c.toLowerCase()
    )
    .join('')
}

export function getIsExistQuery() {
  return createSelector(
    getSearchQuery(),
    getSearchCondition(),
    getSelectedLabels(),
    getSelectContactQuery(),
    getActiveAccount,
    (query, condition, selectedLabels, selectContactQuery, activeAccount) => {
      let isInitalCondition = getIsInitalCondition(condition)
      if (activeAccount) {
        isInitalCondition = getIsInitalCondition(condition, {
          ...initalCondition,
          accountLabel: [activeAccount.labelUUID],
        })
      }
      return (
        !isInitalCondition ||
        !!query ||
        !!selectContactQuery.length ||
        !!selectedLabels.length
      )
    }
  )
}
