// @flow
import qs from 'qs'
import url from 'url'
import Fuse from 'fuse.js'
import moment from 'moment'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import uniqBy from 'lodash/uniqBy'
import toNumber from 'lodash/toNumber'
import pullAllBy from 'lodash/pullAllBy'
import i18next from 'i18next'
import numeral from 'numeral'
import PQueue from 'p-queue'
import type { Contact } from '@edison/webmail-core/types/contacts'
import { API_V1 } from '@edison/webmail-core/utils/constants'
import {
  UNKNOWN_SENDER,
  BASE_DOMAIN,
  WM_API_HOST,
  domainStatusMapping,
  imagePreviewSizeOptions,
  premiumPlanIds,
  labelUnreadConfig,
} from './constants'
import {
  recoveryMethods as recoveryMethodsFromAPI,
  CONTENT_DISPOSITION_INLINE,
  CONTENT_DISPOSITION_ATTACHMENT,
} from '@edison/webmail-core/utils/constants'
import {
  recoveryMethods,
  largeAttachmentStatus,
  largeAttachmentScanStatus,
  SECURE_SCAN_STATUS,
  INSECURE_SCAN_STATUS,
} from '@edison/webmail-ui/utils/constants'

import { defaultDateFormat, labelNames } from './constants'
import {
  isEmail,
  isInlineImage,
  getAttachmentContentType,
} from '@edison/webmail-ui/utils'

import type { FuseOptions } from 'fuse.js'
import type { CommonContact } from 'core/contacts/types'
import type { SignUpRecoveryMethod } from '@edison/webmail-core/types/auth'
import type { Thread } from '@edison/webmail-core/types/threads'
import type { Domain } from '@edison/webmail-core/types/custom-domains'
import type { PremiumPlan } from '@edison/webmail-core/types/premium'
import type {
  Attachment,
  ICSAttachmentItem,
} from '@edison/webmail-core/types/attachment'
import type { Message } from '@edison/webmail-core/types/messages'

export function parseQueryParams(): { [string]: string } {
  const { search } = window.location

  try {
    const params = qs.parse(atob(search.slice(1, search.length)))
    return params
  } catch (e) {
    return {}
  }
}

export function createSearch<T>(
  items: T[],
  keys: $ReadOnlyArray<string> = [],
  options: $Shape<FuseOptions> = {}
) {
  const fuse = new Fuse<T>(items, {
    shouldSort: true,
    threshold: 0.8,
    location: 0,
    distance: 5,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys,
    ...options,
  })

  return {
    search: (query: string) => fuse.search(query),
  }
}

export function convertHtmlToText(html: string): string {
  const domParser = new DOMParser()
  const doc = domParser.parseFromString(html, 'text/html')
  return doc.documentElement.innerText.trim()
}

export function dateFormatter(
  date: number,
  formats: {
    [key: 'sameDay' | 'sameYear' | 'default']: string,
  } = defaultDateFormat
): string {
  const dateMoment = moment.unix(date)
  const dateResetConf = { hour: 0, minute: 0, seconds: 0 }
  const yearResetConf = { ...dateResetConf, month: 1, date: 1 }

  const formatKey =
    dateMoment
      .clone()
      .set(yearResetConf)
      .diff(moment().set(yearResetConf), 'years') < 0
      ? 'default'
      : dateMoment
          .clone()
          .set(dateResetConf)
          .diff(moment().set(dateResetConf), 'days') < 0
      ? 'sameYear'
      : 'sameDay'

  return dateMoment.format(get(formats, formatKey, defaultDateFormat.default))
}

export function getSenderFromContact(
  sender: { email: string, name: string },
  contacts: { [email: string]: CommonContact }
) {
  // Priority of the sender name
  // 1. Sender name from email
  // 2. Sender name in contact
  // 3. Name in email address
  if (sender.name) {
    return sender
  }
  if (sender.email in contacts) {
    const { email, name } = contacts[sender.email]
    return { email, name }
  }
  return {
    ...sender,
    name: sender.email.split('@')[0] || UNKNOWN_SENDER,
  }
}

export function waitTime(time: number) {
  return new Promise<void>(resolve => {
    setTimeout(resolve, time)
  })
}

export function getMessageOriginSubject(subject) {
  const matchResult = subject.match(/^\s*(re|fwd)\s*:\s*([\w\W]+)\s*/i)
  if (matchResult) {
    return matchResult[2]
  } else {
    return subject
  }
}

export function getMetaDataFromAttachmentUrl(attachmentUrl) {
  const { pathname } = url.parse(attachmentUrl)
  const matchResult = pathname.match(/^\/download\/([\w\W]+)$/)
  if (matchResult) {
    return qs.parse(decodeURIComponent(atob(matchResult[1])))
  } else {
    throw Error(`Attachment url ${attachmentUrl} not valid.`)
  }
}

export function hasAttachments(thread: Thread): boolean {
  return (
    thread.messages
      .filter(m => (m.attachments || []).length > 0)
      .reduce(
        (prev, curr) =>
          prev ||
          curr.attachments.filter(
            attachment =>
              attachment.contentDisposition !== CONTENT_DISPOSITION_INLINE
          ).length > 0,
        false
      ) ||
    thread.messages.reduce(
      (prev, curr) => [...prev, ...(curr.largeAttachments || [])],
      []
    ).length > 0
  )
}

export function hasSifts(thread: Thread): boolean {
  return (
    thread.messages.flatMap(message => get(message, 'extendInfo.sift', []))
      .length > 0
  )
}

export function hasPriceAlerts(thread: Thread): boolean {
  // INFO: EWM-3482 - remove price alerts
  return false
}

export function hasSpamMessages(thread: Thread): boolean {
  return thread.messages.some(item =>
    (item.labelIds || []).includes(labelNames.spam)
  )
}

export function formatPriceUnit(plan: PremiumPlan) {
  let priceUnitKey = `premium.plans.priceUnit.${plan.interval}`
  if (
    [
      premiumPlanIds.month.pro,
      premiumPlanIds.year.pro,
      premiumPlanIds.month.agency,
      premiumPlanIds.year.agency,
    ].includes(plan.id)
  ) {
    priceUnitKey = priceUnitKey + 'User'
  }

  let price = 'Unkonwn'
  if (plan.unitPrice && plan.currency) {
    price = formatPrice(plan.unitPrice, plan.currency)
  }

  return [price, i18next.t(priceUnitKey)].join(' ')
}

export function formatPrice(price: number | string, currency: string): string {
  if (isNil(price)) {
    return ''
  }

  try {
    return toNumber(price).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
      style: 'currency',
    })
  } catch (e) {
    return price.toLocaleString()
  }
}

export function formatSize(bytes: number) {
  const formatted = numeral(bytes)
    .format('0ib')
    .replace('i', '')

  return formatted
}
export function hasAttachmentComplete({ id, scanStatus }) {
  return id !== undefined || !isNil(scanStatus)
}

export function hasAttachmentSuccessComplete({ id }) {
  return id !== undefined
}
export function hasAttachmentError({ error }) {
  return !!error
}
export function hasLargeAttachmentComplete({ status, scanStatus }) {
  return (
    status === largeAttachmentStatus.DONE &&
    scanStatus !== largeAttachmentScanStatus.SCANNING
  )
}

export function hasLargeAttachmentSuccessComplete({ status, scanStatus }) {
  return (
    status === largeAttachmentStatus.DONE &&
    SECURE_SCAN_STATUS.includes(scanStatus)
  )
}
export function hasLargeAttachmentError({ error, scanStatus }) {
  return !!error || INSECURE_SCAN_STATUS.includes(scanStatus)
}

// Recursive calls to _.get with fallback.
// Used primary in Sift where there are plenty of fallback logic involved.
export function priorityGet(
  obj: any,
  paths: $ReadOnlyArray<string>,
  fallback: any
) {
  if (paths.length === 0) {
    return fallback
  }

  const [head, ...rest] = paths
  return get(obj, head, priorityGet(obj, rest, fallback))
}

export function getAvatarUrl(email: string) {
  const _email = (email || '').trim().toLowerCase()
  if (!_email) {
    return ''
  } else {
    return `${WM_API_HOST}/${API_V1}/profile/${_email}/avatar`
  }
}

export function getClearbitLogo(email: string) {
  const domain = email.replace(/.*@/g, '').toLowerCase()
  if (domain === 'outlook.com') {
    // avoid return the outlook logo from clearbit
    return i18next.t('retrofit.provider.outlook.icon')
  }

  return i18next.t('clearbit.logo', { domain })
}

export function formatRecipients(recipients) {
  return recipients
    .filter(each => each.email && isEmail(each.email))
    .map(each => ({ name: each.name || each.email, email: each.email }))
}
// format content type and content disposition value
export function formatAttachment(attachment) {
  const contentType = Array.isArray(attachment.contentType)
    ? attachment.contentType[0]
    : attachment.contentType
  const { contentDisposition, fileName: name } = attachment
  const fileName = name || 'Mail Attachment'

  return {
    ...attachment,
    fileName: fileName || 'Mail Attachment',
    contentType: getAttachmentContentType(contentType, fileName),
    contentDisposition:
      contentDisposition !== CONTENT_DISPOSITION_INLINE ||
      !isInlineImage(contentType, fileName)
        ? CONTENT_DISPOSITION_ATTACHMENT
        : CONTENT_DISPOSITION_INLINE,
  }
}

export function formatLargeAttachment(attachment) {
  const { name, contentType } = attachment
  return {
    ...attachment,
    name: name || 'Mail Attachment',
    contentType: getAttachmentContentType(contentType, name),
  }
}

export function isStringEqual(
  a: string,
  b: string,
  optionals: {
    caseSensitive: boolean,
  } = {}
): boolean {
  const caseSensitive = get(optionals, 'caseSensitive', false)
  //no need trim because all email address has been handled by backend
  const _a = a || ''
  const _b = b || ''
  let ans = _a === _b
  if (ans) return ans

  if (!caseSensitive) {
    ans = _a.toLowerCase() === _b.toLowerCase()
  }
  return ans
}

export function stringIndexOf(
  source: string,
  keyword: string,
  optionals: {
    position?: number,
    caseSensitive?: boolean,
  } = {}
): number {
  const caseSensitive = get(optionals, 'caseSensitive', false)
  const position = get(optionals, 'position', undefined)
  let ans = -1
  if (!caseSensitive) {
    ans = source.toLowerCase().indexOf(keyword.toLowerCase(), position)
  } else {
    ans = source.indexOf(keyword, position)
  }
  return ans
}

export class QueueMap {
  _map: Map<string, any>
  _queueOpt: { [key: string]: any }

  constructor(queueOptionals: { [key: string]: any } = {}) {
    this._map = new Map()
    this._queueOpt = { ...queueOptionals }
  }

  enqueue(key: string, fn: () => any, optionals: { [key: string]: any } = {}) {
    let q = this._map.get(key)
    if (isNil(q)) {
      q = new PQueue({ ...this._queueOpt, ...optionals })
      q.add(fn)
      q.on('idle', () => {
        this._map.delete(key)
      })
      this._map.set(key, q)
    } else {
      q.add(fn)
    }
    return q
  }

  delete(key: string) {
    this._map.delete(key)
  }
}

export function scrollToMessage(messageId, smooth = true) {
  const node = document.getElementById(messageId)
  if (!node) return

  node.scrollIntoView({
    behavior: smooth ? 'smooth' : 'auto',
    block: 'start',
    inline: 'nearest',
  })
}

export function scrollToDraft(draftId) {
  const node = document.getElementById(`Compose-${draftId}`)
  if (!node) return
  const nodeRect = node.getBoundingClientRect()
  const body = document.getElementById('ThreadDetail-body')
  if (!body) return

  const bodyRect = body.getBoundingClientRect()
  body.scrollTo({
    top:
      body.scrollTop +
      nodeRect.top -
      bodyRect.top -
      bodyRect.height +
      Math.min(350, nodeRect.height),
    behavior: 'smooth',
  })
}

export function formatComposeRecipients({ to = [], cc = [], bcc = [] }) {
  let formatedTo = uniqBy(formatRecipients(to), 'email')
  let formatedCc = uniqBy(formatRecipients(cc), 'email')
  let formatedBcc = uniqBy(formatRecipients(bcc), 'email')

  //to has higher priority, cc, then bcc.
  pullAllBy(formatedCc, formatedTo, 'email')
  pullAllBy(formatedBcc, [...formatedCc, ...formatedTo], 'email')
  return { to: formatedTo, cc: formatedCc, bcc: formatedBcc }
}

export function b64toBlob(dataURI) {
  // eslint-disable-next-line
  const [data, contentType, schema, code] = dataURI.split(/:|;|,/)
  var byteString = atob(code)
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: contentType })
}

export const getDomainStatus = (domain: ?Domain) => {
  if (!domain) return domainStatusMapping.UNSET
  else {
    const isVerified = get(domain, 'verified', false)
    if (isVerified) return domainStatusMapping.VERIFIED
    else return domainStatusMapping.PENDING
  }
}

/*
 * Extend the string methods by handling the cases with emoji and unicode
 */
export const UnicodeString = {
  truncate: (
    input: string,
    options: { length?: number, omission?: string } = {}
  ) => {
    const { length = 30, omission = '...' } = options
    const chars = [...input]

    if (chars.length <= length) {
      return chars.join('')
    } else {
      return chars.slice(0, length).join('') + omission
    }
  },
}

export const generateAttachmentImagePreviewUrl = (
  url,
  size = imagePreviewSizeOptions.small,
  originalHost = false
) => `${originalHost ? '' : WM_API_HOST}${url}?imgsize=${size}`

export const generateLargeAttachmentImagePreviewUrl = (
  url,
  size = imagePreviewSizeOptions.small
) => `${url}&imgsize=${size}`

/**
 * Referred from: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
export function documentVisibility() {
  let hidden, visibilityChange

  if ('hidden' in document) {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if ('msHidden' in document) {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if ('webkitHidden' in document) {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  return {
    // $FlowFixMe
    isVisible: () => !document[hidden],
    addEventListener: (callback: () => mixed) => {
      document.addEventListener(visibilityChange, callback)
    },
    removeEventListener: (callback: () => mixed) => {
      document.removeEventListener(visibilityChange, callback)
    },
  }
}

const _PENDING = Symbol('PROMISE_PENDING')
export function isPromiseFulfilled(p: Promise<any>): Promise<boolean> {
  return Promise.race([p, _PENDING]).then(resolved => resolved !== _PENDING)
}

export function convertAttachmentToAttachmentItem(
  attachment: Attachment,
  messageId: string
): AttachmentItem {
  const {
    fileName: name,
    contentDisposition: disposition,
    ...rest
  } = attachment
  return {
    name,
    message: messageId,
    disposition,
    type: 'normal',
    ...rest,
  }
}

export function convertAttachmentItemToAttachment(
  attachment: AttachmentItem
): Attachment {
  const { name, message, disposition, type, ...rest } = attachment
  return {
    fileName: name,
    contentDisposition: disposition,
    ...rest,
  }
}

export const convertAttachmentToICSItem = (
  attachment: Attachment,
  message: Message
): ICSAttachmentItem => {
  const ics =
    message.extendInfo && Array.isArray(message.extendInfo.ics)
      ? message.extendInfo.ics
      : []
  const ret = {
    ...convertAttachmentToAttachmentItem(attachment, message.id),
    preview: 'ics',
  }
  const eventIds = ics.find(icsInfo => {
    return icsInfo.attachmentId === ret.id
  })
  if (eventIds) {
    ret.eventIds = eventIds.uids
  } else {
    ret.eventIds = []
  }
  return ret
}

export function convertLargeAttachmentToAttachmentItem(
  attachment: LargeAttachment
): AttachmentItem {
  const { aid: id, ...rest } = attachment
  return {
    id,
    type: 'large',
    ...rest,
  }
}

export function convertAttachmentItemToLargeAttachment(attachmentItem) {
  const { id, type, ...rest } = attachmentItem
  return { aid: id, ...rest }
}

const contentTypeBlacklist = ['image/svg+xml']
const contentTypeWhitelist = [
  'image/bmp',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/gif',
]

export function isEnablePreviewAttachment(attachment) {
  let { contentType, preview } = attachment
  if (Array.isArray(contentType) && contentType.length > 0) {
    contentType = contentType[0]
  }
  return (
    contentTypeWhitelist.includes(contentType) ||
    (preview && !contentTypeBlacklist.includes(contentType))
  )
}

export function isEnableThumbnailAttachment(attachment) {
  const { contentType, preview } = attachment
  return preview === 'img' && !contentTypeBlacklist.includes(contentType)
}

export function parseOnMailHost(host: string) {
  const [prefix, ...rest] = host.split('.')
  const suffix = rest.join('.')

  const isPreview = /^preview-(\d+)-(\d+)-mail$/.test(prefix)
  const isLocalhost = /^localhost(:\d+)?$/.test(host)
  const isValidDomain = isLocalhost || isPreview || /^mail$/.test(prefix)
  const isOnMail = isValidDomain && suffix === BASE_DOMAIN

  return {
    onmail: isOnMail,
    preview: isPreview,
    localhost: isLocalhost,
    customDomain: isValidDomain && !isOnMail && !isLocalhost,
  }
}

export function checkCurrentUser(
  aliases: $ReadOnlyArray<string>,
  email: string
) {
  return aliases.some(item => isStringEqual(item, email))
}

export function isPlainDraft(
  labelIds: $ReadOnlyArray<string>,
  messageCount: number
) {
  return messageCount === 1 && labelIds.includes(labelNames.drafts)
}

export function getLabelUnreadConfig(label: string) {
  const { display = true, displayTotal = false } = get(
    labelUnreadConfig,
    label,
    {}
  )

  return {
    display,
    displayTotal,
  }
}

export function stateWrapper<T>(
  name: string,
  state,
  setState
): { value: T, set: T => void } {
  return {
    // $FlowFixMe
    value: state[name],
    // $FlowFixMe
    set: (next: T) => setState(state => ({ ...state, [name]: next })),
  }
}

export const convertToSignUpRecoveryMethod = ({
  type,
  value,
  token,
}: Object): SignUpRecoveryMethod => {
  let output = {
    type,
    value,
    token,
  }

  if (type === recoveryMethods.phone) {
    output.type = recoveryMethodsFromAPI.sms
  }

  return output
}

export function getDomainByEmail(email) {
  if (email) {
    const [, domain] = email.split('@')
    return domain
  }
  return ''
}

export function getTemporaryContact(props: Object): Contact {
  return {
    stared: props.stared || 0,
    notes: props.notes || '',
    company: props.company || '',
    jobTitle: props.jobTitle || '',
    enableShowImage: props.enableShowImage || false,
    phones: props.phones || [],
    websites: props.websites || [],
    addresses: props.addresses || [],
    insertTime: props.insertTime || moment().unix(),
    id: props.id || 0,
    isDomain: props.isDomain || 0,
    status: props.status || 0,
    firstName: props.firstName || '',
    lastName: props.labelNames || '',
    emails: props.emails || [],
    avatar: props.avatar || '',
  }
}
