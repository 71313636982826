// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type {
  PreviewActions,
  FetchAttachmentPreviewLinkSuccess,
  FetchLargeAttachmentPreviewLinkSuccess,
} from './types'

type PreviewLink = {
  id: string,
  url: string,
}

export type State = {|
  attachments: {
    [id: string]: PreviewLink,
  },
  largeAttachments: {
    [id: string]: PreviewLink,
  },
|}

const initialState: State = {
  attachments: {},
  largeAttachments: {},
  attachments_small: {},
  attachments_medium: {},
  attachments_full: {},
}

export default createReducer<State, PreviewActions>(initialState, {
  [actions.fetchAttachmentPreviewLinkActions.success.toString()]: fetchAttachmentPreviewLinkSuccess,
  [actions.fetchLargeAttachmentPreviewLinkActions.success.toString()]: fetchLargeAttachmentPreviewLinkSuccess,
})

function fetchAttachmentPreviewLinkSuccess(
  state: State,
  action: FetchAttachmentPreviewLinkSuccess
) {
  const { id, url, size } = action.payload
  const key = size ? `attachments_${size}` : 'attachments'
  const value = state[key]

  return {
    ...state,
    [key]: {
      ...value,
      [id]: url,
    },
  }
}

function fetchLargeAttachmentPreviewLinkSuccess(
  state: State,
  action: FetchLargeAttachmentPreviewLinkSuccess
) {
  const { id, url } = action.payload

  return {
    ...state,
    largeAttachments: {
      ...state.largeAttachments,
      [id]: url,
    },
  }
}
