// @flow
import qs from 'qs'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import {
  useParams,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router-dom'
import { routePaths } from 'utils/constants'

/**
 * Returns query parameters in the current URL.
 */
export const useQueryParams = (): { [key: string]: string } => {
  const location = useLocation()

  try {
    return qs.parse(location.search.slice(1))
  } catch (e) {
    // Empty object
    return {}
  }
}

/**
 * Returns the handlers to navigate to home page
 */
export const useGoHome = (
  method?: 'push' | 'replace' | ((url: string) => mixed) = 'push'
): ({ go: () => mixed }) => {
  const { userId } = useParams() || {}
  const history = useHistory()
  const homePage = generatePath(routePaths.main, {
    userId: userId || 0,
    label: 'inbox',
  })

  let go

  if (isFunction(method)) {
    go = () => method(homePage)
  } else {
    if (typeof method === 'string' && method in history) {
      go = () => history[method](homePage)
    }
  }

  if (isNil(go)) {
    go = () => console.warn('Invalid home page handler')
  }

  return { go }
}
