// @flow

import type {
  RequestActionGenType,
  UnixTimestampInSeconds,
  RecurringEventModifyTypes,
} from './types-common'
import type { ReduxEvent } from './modal-redux-event'
import type { ReduxEvents } from './modal-redux-events'
import type { UIEventsRange } from './modal-ui-events-range'
import { calendarResolution, eventsActionTypes } from './constants-events'
import type {
  CalendarAttendee,
  CalendarEventInsertResource,
  CalendarEventInstanceListResource,
} from '@edison/webmail-core/types/calendar'
import type { CalendarICSDataResource } from '@edison/webmail-core/api/calendar'
import { ReduxICSEventsData } from './modal-redux-ics-events'

export type FetchEventsActionsToReducerData = {
  calendarId: string,
  events: CalendarEventInstanceListResource,
  start: UnixTimestampInSeconds,
  end: UnixTimestampInSeconds,
  currentTimeZone: string,
}
export type CalendarResolutionTypes = $Values<typeof calendarResolution>

export type EventsState = {
  eventsData: ReduxEvents,
  uiEventsRange: UIEventsRange,
  selectedICSEvent: {
    calendarId: string,
    eventId: string,
    instanceId: string,
  } | null,
  selectedUIScopeEvent: ReduxEvent | null,
  icsEventsData: ReduxICSEventsData,
}
export type SelectICSEvent = RequestActionGenType<
  typeof eventsActionTypes.SelectICSEvent,
  { calendarId: string, eventId: string, instanceId?: string }
>
export type DeselectICSEvent = RequestActionGenType<
  typeof eventsActionTypes.DeselectICSEvent,
  void
>
export type SelectUIScopeEvent = RequestActionGenType<
  typeof eventsActionTypes.SelectUIScopeEvent,
  { calendarId: string, eventId: string, instanceId?: string }
>
export type DeselectUIScopeEvent = RequestActionGenType<
  typeof eventsActionTypes.DeselectUIScopeEvent,
  void
>
export type FetchICSEventDataRequest = RequestActionGenType<
  typeof eventsActionTypes.FetchICSEventDataRequest,
  void
>
export type FetchICSEventDataSuccess = RequestActionGenType<
  typeof eventsActionTypes.FetchICSEventDataSuccess,
  {
    icsData: CalendarICSDataResource,
    calendarId: string,
    eventOrInstanceId: string,
    startTime: UnixTimestampInSeconds,
  }
>
export type FetchICSEventDataFailure = RequestActionGenType<
  typeof eventsActionTypes.FetchICSEventDataFailure,
  { message: string }
>
export type UpdateUIScopeEventRange = RequestActionGenType<
  typeof eventsActionTypes.UpdateUIScopeEventRange,
  {
    resolution: CalendarResolutionTypes,
    year: number,
    month: number,
    date: number,
  }
>
export type CRUDESuccessPayload = {
  undoType: string,
  calendarId: string,
  eventId: string,
  updateEventId?: string,
  newEventId?: string,
  instanceId?: string,
}
export type CRUDEAPISuccess = RequestActionGenType<'', CRUDESuccessPayload>
export type UpdateAndAddEventRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateAndAddEventRequest,
  {
    calendarId: string,
    updateEvent: ReduxEvent,
    newEvent: ReduxEvent,
    breakEventLink: boolean,
  }
>
export type UpdateAndAddEventRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateAndAddEventRequestSuccess,
  {
    calendarId: string,
    updateEventId: string,
    newEventId: string,
    updateEventUpdatedTime: UnixTimestampInSeconds,
    newEventUpdatedTime: UnixTimestampInSeconds,
  }
>
export type UpdateAndAddEventRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateAndAddEventRequestFailure,
  {
    message: string,
    calendarId: string,
    updateEventId: string,
    newEventId: string,
  }
>
export type UpdateEventRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventRequest,
  {
    calendarId: string,
    eventId: string,
    data: ReduxEvent,
    currentTimeZone: string,
    updateType?: RecurringEventModifyTypes,
  }
>
export type UpdateEventRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventRequestSuccess,
  {
    calendarId: string,
    eventId: string,
    updatedTime: UnixTimestampInSeconds,
    currentTimeZone: string,
  }
>
export type UpdateEventRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventRequestFailure,
  { message: string, calendarId: string, eventId: string, data?: ReduxEvent }
>
export type UpdateEventSimpleDataRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventSimpleDataSuccess,
  ReduxEvent
>
export type UpdateEventSimpleDataSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventSimpleDataSuccess,
  { calendarId: string, eventId: string, updatedTime: UnixTimestampInSeconds }
>
export type UpdateEventSimpleDataFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventSimpleDataFailure,
  { message: string, calendarId: string, eventId: string, instanceId: string }
>
export type UpdateEventOrInstanceAttendeeRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventAttendeeDataRequest,
  {
    eventOrInstance: ReduxEvent,
    attendee: CalendarAttendee,
  }
>
export type UpdateEventOrInstanceAttendeeSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventAttendeeDataRequest,
  {
    calendarId: string,
    eventId: string,
    instanceId: string,
    attendee: CalendarAttendee,
    updatedTime: UnixTimestampInSeconds,
    undoType: string,
  }
>
export type UpdateEventOrInstanceAttendeeFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventAttendeeDataRequest,
  {
    message: string,
    calendarId: string,
    eventId: string,
    instanceId: string,
    attendee: CalendarAttendee,
    undoType: string,
  }
>

export type UpdateEventOrInstanceStatusRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventOrInstanceStatusSuccess,
  ReduxEvent
>
export type UpdateEventOrInstanceStatusSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventOrInstanceStatusSuccess,
  CRUDEAPISuccess
>
export type UpdateEventOrInstanceStatusFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateEventOrInstanceStatusFailure,
  { message: string, calendarId: string, eventId: string }
>
export type UpdateSingleEventInstanceRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateSingleEventInstanceRequest,
  {
    calendarId: string,
    eventId: string,
    data: ReduxEvent,
    currentTimeZone: string,
  }
>
export type UpdateSingleEventInstanceRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateSingleEventInstanceRequestSuccess,
  {
    calendarId: string,
    eventId: string,
    instanceId: string,
    updatedTime: UnixTimestampInSeconds,
  }
>
export type UpdateSingleEventInstanceRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateSingleEventInstanceRequestFailure,
  { message: string, calendarId: string, eventId: string, instanceId: string }
>
export type UpdateRecurringEventAndInstanceRequest = RequestActionGenType<
  typeof eventsActionTypes.UpdateRecurringEventAndInstanceRequest,
  { calendarId: string, updateEvent: ReduxEvent, newInstance: ReduxEvent }
>

export type UpdateRecurringEventAndInstanceRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.UpdateRecurringEventAndInstanceSuccess,
  {
    calendarId: string,
    eventId: string,
    instanceId: string,
    undoType: 'updateRecurringEventExDateAndInstance',
  }
>

export type UpdateRecurringEventAndInstanceRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.UpdateRecurringEventAndInstanceFailure,
  {
    calendarId: string,
    eventId: string,
    instanceId: string,
    undoType: 'updateRecurringEventExDateAndInstance',
  }
>

export type AddEventRequest = RequestActionGenType<
  typeof eventsActionTypes.AddEventRequest,
  { calendarId: string, eventData: CalendarEventInsertResource }
>
export type AddEventRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.AddEventRequestSuccess,
  { calendarId: string, eventId: string, data: ReduxEvent }
>
export type AddEventRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.AddEventRequestFailure,
  { message: string }
>
export type DeleteSingleEventInstanceRequest = RequestActionGenType<
  typeof eventsActionTypes.DeleteSingleEventInstanceRequest,
  ReduxEvent
>
export type DeleteSingleEventInstanceRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.DeleteSingleEventInstanceRequestSuccess,
  CRUDESuccessPayload
>
export type DeleteSingleEventInstanceRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.DeleteSingleEventInstanceRequestFailure,
  { calendarId: string, eventId: string, instanceId: string }
>
export type DeleteEventRequest = RequestActionGenType<
  typeof eventsActionTypes.DeleteEventRequest,
  { oldEventOrInstance: ReduxEvent, userAliases: string[] }
>
export type DeleteEventRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.DeleteEventRequestSuccess,
  CRUDESuccessPayload
>
export type DeleteEventRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.DeleteEventRequestFailure,
  { message: string, calendarId: string, eventId: string }
>
export type FetchEventsRequest = RequestActionGenType<
  typeof eventsActionTypes.FetchEventsRequest,
  void
>
export type FetchEventsRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.FetchEventsRequestSuccess,
  FetchEventsActionsToReducerData
>
export type FetchEventsRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.FetchEventsRequestFailure,
  { message: string }
>
export type FetchEventOrInstanceRequest = RequestActionGenType<
  typeof eventsActionTypes.FetchEventOrInstanceRequest,
  { calendarId: string, eventOrInstanceId: string }
>
export type FetchEventOrInstanceRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.FetchEventOrInstanceRequestSuccess,
  { event: ReduxEvent, instance: ReduxEvent }
>
export type FetchEventOrInstanceRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.FetchEventOrInstanceRequestFailure,
  { message: string, calendarId: string, eventOrInstanceId: string }
>

export type AddSimpleEventRequest = RequestActionGenType<
  typeof eventsActionTypes.AddSimpleEventRequest,
  void
>
export type AddSimpleEventRequestSuccess = RequestActionGenType<
  typeof eventsActionTypes.AddSimpleEventRequestSuccess,
  { calendarId: string, eventId: string, data: ReduxEvent }
>
export type AddSimpleEventRequestFailure = RequestActionGenType<
  typeof eventsActionTypes.AddSimpleEventRequestFailure,
  { message: string }
>

export type EventsActions =
  | UpdateSingleEventInstanceRequest
  | UpdateSingleEventInstanceRequestSuccess
  | UpdateSingleEventInstanceRequestFailure
  | UpdateEventRequest
  | UpdateEventRequestSuccess
  | UpdateEventRequestFailure
  | DeleteSingleEventInstanceRequest
  | DeleteSingleEventInstanceRequestSuccess
  | DeleteSingleEventInstanceRequestFailure
  | AddEventRequest
  | AddEventRequestSuccess
  | AddEventRequestFailure
  | FetchEventsRequest
  | FetchEventsRequestSuccess
  | FetchEventsRequestFailure
  | FetchEventOrInstanceRequest
  | FetchEventOrInstanceRequestSuccess
  | FetchEventOrInstanceRequestFailure
  | AddSimpleEventRequest
  | AddSimpleEventRequestSuccess
  | AddSimpleEventRequestFailure
  | DeleteEventRequest
  | DeleteEventRequestSuccess
  | DeleteEventRequestFailure
