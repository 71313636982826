// @flow
import { useEffect } from 'react'

type Options = {
  onPrevious: () => any,
  onNext: () => any,
  deps?: [],
}

export function useKeyboardNavigation(ops: Options, deps = []) {
  const { onPrevious, onNext } = ops
  useEffect(() => {
    const keyDown = e => {
      const { key } = e
      switch (key) {
        case 'ArrowLeft':
          onPrevious && onPrevious()
          break
        case 'ArrowRight':
          onNext && onNext()
          break
        default:
          return
      }
      e.preventDefault()
    }
    window.addEventListener('keydown', keyDown)
    return () => window.removeEventListener('keydown', keyDown)
  }, deps)
}
