import React from 'react'
import { ClearAllPillItem } from '@edison/webmail-ui/components/Pill'
import { getIsInitalCondition } from 'core/search/helpers'
import { useGetInitalCondition } from 'core/search/hooks'

export function ClearAllPill(props) {
  const { onSelect, condition } = props
  const initalCondition = useGetInitalCondition()
  const isInitalCondition = getIsInitalCondition(condition, initalCondition)
  if (isInitalCondition) {
    return null
  }
  return (
    <ClearAllPillItem
      onClick={() => {
        onSelect(initalCondition, true)
      }}
    />
  )
}
