// @flow
import React from 'react'
import get from 'lodash/get'
import { attachmentTypes, imagePreviewSizeOptions } from 'utils/constants'
import { AttachmentImage as NormalAttachmentImage } from 'common/AttachmentItem/NormalAttachmentItem'
import { AttachmentImage as LargeAttachmentImage } from 'common/AttachmentItem/LargeAttachmentItem'
import { isEnableThumbnailAttachment } from 'utils'
import { ImagePlaceholder } from 'common/AuthImg'

import type { Dispatch } from 'types/redux'

const images = {
  [attachmentTypes.LARGE_ATTACHMENT]: (attachment, size) => {
    if (isEnableThumbnailAttachment(attachment)) {
      return <LargeAttachmentImage {...attachment} />
    }
    return <ImagePlaceholder />
  },
  [attachmentTypes.NORMAL_ATTACHMENT]: (attachment, size) => {
    if (isEnableThumbnailAttachment(attachment)) {
      return <NormalAttachmentImage {...attachment} size={size} />
    }
    return <ImagePlaceholder />
  },
}

export const getPreviewProps = (
  dispatch: Dispatch,
  attachment: {
    id: string,
    type: string,
    url: string,
    contentType: string,
    name?: string,
    preview: string,
  },
  size = imagePreviewSizeOptions.small
) => {
  const getImage = get(images, attachment.type, () => <ImagePlaceholder />)

  return {
    name: attachment.name,

    image: getImage(attachment, size),
  }
}
