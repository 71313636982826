import get from 'lodash/get'
import React, { memo, useMemo, useState, useEffect } from 'react'
import ContactAttachments from '@edison/webmail-ui/screens/ContactAttachments'
import { useShowContactAttachments } from 'hooks/useHandleDetailModal'

import { searchAttachmentTabs } from 'utils/constants'
import { useSelector } from 'react-redux'
import { getAuth } from 'core/auth/selectors'
import * as api from '@edison/webmail-core/api/search'
import AttachmentTabListItem from 'common/AttachmentItem/AttachmentTabListItem'

import AttachmentList from '@edison/webmail-ui/components/AttachmentTabList/List'
import NoResultsFound from '@edison/webmail-ui/components/NoResultsFound'
import { getContactById } from 'core/contacts/selectors'

const tabs = Object.values(searchAttachmentTabs).filter(
  item => item.value !== 'links'
)

const PAGE_SIZE = 6

const Attachments = memo(({ label, value: type, email }) => {
  const auth = useSelector(useMemo(() => getAuth(), []))
  const [state, setState] = useState(() => ({
    list: [],
    nextPageToken: '',
    loading: false,
    loaded: false,
  }))

  const { list, nextPageToken, loading, loaded } = state

  const fetchAttachments = async nextPageToken => {
    let pageToken = nextPageToken
    let results = []
    const fetch = async extraSize => {
      const result = await api.searchAttachments({
        auth,
        type,
        pageToken,
        pageSize: PAGE_SIZE,
        otherEmail: email,
      })
      const { attachments, nextPageToken } = result.result
      pageToken = nextPageToken
      results = results.concat(attachments)
      if (!pageToken) return
      if (extraSize - attachments.length <= 0) return
      return fetch(extraSize - attachments.length)
    }
    await fetch(PAGE_SIZE)

    return { attachments: results, nextPageToken: pageToken }
  }

  const handleChangePreviewIndex = currIndex => {
    const { list, nextPageToken, loading } = state
    if (currIndex >= list.length - 2 && nextPageToken && !loading) {
      loadMore()
    }
  }

  const renderItem = ({ columnIndex, key, rowIndex, style }, index) => {
    return (
      <div key={key} style={style} className="p-4">
        {!loaded || nextPageToken || list[index] ? (
          <AttachmentTabListItem
            attachments={list}
            index={index}
            onChangeIndex={handleChangePreviewIndex}
          />
        ) : null}
      </div>
    )
  }

  const loadMore = ({ startIndex, stopIndex }) => {
    if (nextPageToken && !loading) {
      setState(state => ({ ...state, loading: true }))
      fetchAttachments(nextPageToken).then(result => {
        const { attachments, nextPageToken } = result
        setState(state => ({
          ...state,
          list: state.list.concat(attachments),
          nextPageToken,
          loading: false,
        }))
      })
    }
  }
  const isLoaded = ({ index }) => list[index]

  useEffect(() => {
    setState({ list: [], pageToken: '', loading: true, loaded: false })
    fetchAttachments().then(result => {
      const { attachments, nextPageToken } = result
      setState({
        list: attachments,
        nextPageToken,
        loading: false,
        loaded: true,
      })
    })
  }, [])

  if (loaded && !list.length) {
    return <NoResultsFound />
  }

  return (
    <AttachmentList
      renderItem={renderItem}
      total={nextPageToken || !loaded ? list.length + 5 : list.length}
      isLoaded={isLoaded}
      loadMore={loadMore}
    />
  )
})

export default ({ contactId, attachmentType }) => {
  const showContactAttachments = useShowContactAttachments('replace')
  const defaultActiveTab =
    tabs.findIndex(item => item.value === attachmentType) || 0
  const handleChangeActiveTab = index => {
    showContactAttachments(contactId, tabs[index].value)
  }
  const contact = useSelector(
    useMemo(() => getContactById(contactId), [contactId])
  )
  const email = get(contact, 'emails[0].email', '')

  return (
    <ContactAttachments
      tabs={tabs}
      defaultActiveTab={defaultActiveTab}
      onChangeActiveTab={handleChangeActiveTab}
    >
      {activeTab =>
        React.createElement(Attachments, { email, ...tabs[activeTab] })
      }
    </ContactAttachments>
  )
}
