// @flow
import omit from 'lodash/omit'
import { createReducer } from 'utils/redux'
// import { cleanupStatus, cleanupTypes } from '@edison/webmail-core/utils/constants'

import {
  cleanupSearchActions,
  cleanupListActions,
  cleanupUpdateActions,
} from './actions'

import type {
  CleanupActions,
  CleanupSearchSuccess,
  CleanupListSuccess,
  CleanupUpdateSuccess,
} from './types'

export type State = {
  [type: string]: {
    id: string,
    type: string,
    detail: {
      threadIds: [],
      nextPageToken: '',
    },
  },
}

const initalState = {}

export default createReducer<State, CleanupActions>(initalState, {
  [cleanupSearchActions.success.toString()]: cleanupSearchSuccess,
  [cleanupListActions.success.toString()]: cleanupListSuccess,
  [cleanupUpdateActions.success.toString()]: cleanupUpdateSuccess,
})

function cleanupSearchSuccess(state: State, action: CleanupSearchSuccess) {
  const types = action.payload

  return types.reduce(
    (prev, curr) => ({ ...prev, [curr.Type]: { type: curr.Type } }),
    {}
  )
}

function cleanupListSuccess(state: State, action: CleanupListSuccess) {
  const { type, threadUuids: threadIds, nextPageToken } = action.payload
  return {
    ...state,
    [type]: {
      ...state[type],
      threadIds: [...(state[type].threadIds || []), ...threadIds],
      nextPageToken,
    },
  }
}

function cleanupUpdateSuccess(state: State, action: CleanupUpdateSuccess) {
  const { type } = action.payload

  return omit(state, [type])
}
