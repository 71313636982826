import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useShowContactAttachments } from 'hooks/useHandleDetailModal'
import {
  Header as AttachmentsHeader,
  Layout as AttachmentsLayout,
  AttachmentFile,
  AttachmentPhoto,
} from '@edison/webmail-ui/screens/ContactDetail/Attachments'
import { getAttachmentsById } from 'core/contacts/selectors'
import { fetchContactAttachments } from 'core/contacts/actions'
import { usePreviewModal } from 'core/previews/hooks'
import { getPreviewProps } from 'core/large-attachments/helpers'
import { isEnableThumbnailAttachment } from 'utils'
import type { Dispatch } from 'types/redux'

const AttachmentItem = ({ attachments, index }) => {
  const previews = usePreviewModal()
  const attachment = attachments[index]
  const dispatch: Dispatch = useDispatch()

  const onClick = () => {
    previews.showModal({ attachments, index })
  }
  if (isEnableThumbnailAttachment(attachments[index])) {
    const { image: thumbnail } = getPreviewProps(dispatch, attachment)

    return (
      <AttachmentPhoto
        {...attachment}
        thumbnail={thumbnail}
        onClick={onClick}
      />
    )
  } else {
    return <AttachmentFile {...attachment} onClick={onClick} />
  }
}
export default function AttachmentSection({ contactId }) {
  const dispatch: Dispatch = useDispatch()
  const showContactAttachments = useShowContactAttachments()
  const handleViewAll = () => {
    showContactAttachments(contactId, 'all')
  }
  const attachments = useSelector(
    useMemo(() => getAttachmentsById(contactId), [contactId])
  )

  useEffect(() => {
    dispatch(fetchContactAttachments(contactId))
  }, [])

  if (!attachments.length || attachments?.length <= 1) return null
  return (
    <AttachmentsLayout header={<AttachmentsHeader onViewAll={handleViewAll} />}>
      {attachments.map((attachment, index) => (
        <AttachmentItem attachments={attachments} index={index} key={index} />
      ))}
    </AttachmentsLayout>
  )
}
