// @flow
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import * as Yup from 'yup'

import { addForwardEmail } from 'core/settings/actions'
import { getAuth } from 'core/auth/selectors'
import {
  isAddForwardEmailLoading,
  getForwardEmails,
} from 'core/settings/selectors'

import Dialog from '@material-ui/core/Dialog'
import NewForwardEmailContainer, {
  NewForwardEmailForm,
  VerificationSent,
} from '@edison/webmail-ui/screens/Settings/components/NewForwardEmailModal'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
  isSubmitted?: boolean,
  email?: string,
}
const View = ({
  isOpen,
  toggle,
  isSubmitted: _isSubmitted = false,
  email = '',
}: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const auth = useSelector(getAuth())
  const forwardEmails = useSelector(getForwardEmails)
  const isLoading = useSelector(isAddForwardEmailLoading)
  const [isSubmitted, setSubmitted] = useState(_isSubmitted)
  const [emailValue, setEmail] = useState(email)

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSubmitted(false)
        setEmail('')
      }, 200)
    } else {
      if (_isSubmitted) {
        setSubmitted(_isSubmitted)
        setEmail(email)
      }
    }
  }, [isOpen])

  function handleOnSubmit(email) {
    dispatch(addForwardEmail(email)).then(() => {
      setSubmitted(true)
      setEmail(email)
    })
  }

  const selfEmail = get(auth, 'user', '').toLowerCase()
  const forwardEmailSet = new Set(
    forwardEmails.map(({ email }) => email.toLowerCase())
  )

  return (
    <Dialog fullWidth={!isSubmitted} open={isOpen} onClose={toggle}>
      <NewForwardEmailContainer>
        {!isLoading && isSubmitted ? (
          <VerificationSent email={emailValue} onClose={toggle} />
        ) : (
          <NewForwardEmailForm
            onClose={toggle}
            isLoading={isLoading}
            onSubmit={handleOnSubmit}
            validator={async value => {
              const _value = value.trim().toLowerCase()
              if (!_value) return t('required')
              if (forwardEmailSet.has(value))
                return t('settings.general.forwardingAccounts.duplicated', {
                  email: _value,
                })
              if (_value === selfEmail)
                return t('settings.general.forwardingAccounts.self')
              if (
                !(await Yup.string()
                  .email()
                  .isValid(value))
              ) {
                return t(
                  'settings.general.forwardingAccounts.error.emailAddress'
                )
              }
            }}
          />
        )}
      </NewForwardEmailContainer>
    </Dialog>
  )
}

View.displayName = 'NewForwardEmailModal'

export default View
