// @flow
import usePrevious from '@edison/webmail-ui/hooks/previous'
import ThreadList from 'common/ThreadList'
import BatchActionHeader from 'common/ThreadList/BatchActionHeader'
import * as analytics from 'core/analytics/actions'
import * as actions from 'core/search/actions'
import {
  useGetSearchActionFlags,
  useGetThreads,
  useIsExistQuery,
  useIsInbox,
} from 'core/search/hooks'
import * as selectors from 'core/search/selectors'
import * as threadActions from 'core/threads/actions'
import { batchGetThreads } from 'core/threads/actions'
import isNil from 'lodash/isNil'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { Dispatch } from 'types/redux'
import { useDebouncedCallback } from 'use-debounce'
import { inboxViews } from 'utils/constants'
import SearchRecommendations from './Recommendations'

const isSearchLoading = selectors.isSearchLoading()
const getSearchQuery = selectors.getSearchQuery()
const getNextPageToken = selectors.getNextPageToken()
const getSearchThreadIds = selectors.getSearchThreadIds()
const getSearchCondition = selectors.getSearchCondition()

export default () => {
  const dispatch: Dispatch = useDispatch()
  const isExistQuery = useIsExistQuery()
  const isInbox = useIsInbox()
  const pageToken = useSelector(getNextPageToken)
  const query = useSelector(getSearchQuery)
  const [searchAnalytics] = useDebouncedCallback(scrollTop => {
    dispatch(analytics.search.userSearchScroll(scrollTop))
  }, 1000)
  const condition = useSelector(getSearchCondition)
  const resultIds = useSelector(getSearchThreadIds)
  const isLoading = useSelector(isSearchLoading)
  const threads = useGetThreads()
  const searchActions = useGetSearchActionFlags()
  const previousQuery = usePrevious(query)

  const [debouncedFetchThreads] = useDebouncedCallback((query, condition) => {
    dispatch(actions.searchThreads(query, { isExistQuery, isInbox }, condition))
  }, 500)

  const loadMore = () => {
    if (!isNil(pageToken) && !isLoading) {
      dispatch(
        actions.searchThreads(
          query,
          { pageToken, isExistQuery, isInbox },
          condition
        )
      )
    }
  }

  const hasCheckBox = useMemo(() => {
    if (!isExistQuery && isInbox) {
      return false
    }
    return true
  }, [isExistQuery, isInbox])

  const onScroll = ({ scrollTop }) => {
    if (scrollTop > 0) {
      searchAnalytics(scrollTop)
    }
  }

  const onClickItem = () => {
    dispatch(analytics.search.userSearchViewThread(query))
  }

  useEffect(() => {
    dispatch(threadActions.resetSelectThread())
    if (!isExistQuery && isInbox) {
      return
    }
    if (previousQuery !== query) {
      debouncedFetchThreads(query, condition)
    } else {
      dispatch(
        actions.searchThreads(query, { isExistQuery, isInbox }, condition)
      )
    }
  }, [query, JSON.stringify(condition)])

  useEffect(() => {
    dispatch(batchGetThreads(resultIds))
  }, [resultIds])

  return (
    <>
      <BatchActionHeader
        view={inboxViews.LIST}
        threads={!isExistQuery && isInbox ? [] : threads}
      />
      <div className="h-full w-full overflow-y-auto overflow-x-hidden">
        <ThreadList
          id="searchThreadList"
          highlightWord={query}
          total={!isNil(pageToken) ? threads.length + 5 : threads.length}
          actions={searchActions}
          hasCheckBox={hasCheckBox}
          threads={threads}
          loadMore={loadMore}
          isLoading={isLoading}
          onScroll={onScroll}
          onClickItem={onClickItem}
        />
        <SearchRecommendations threads={threads} isLoading={isLoading} />
      </div>
    </>
  )
}
