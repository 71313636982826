// @flow
import React from 'react'
import { withRouter, Link, generatePath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import Navigation, {
  AppLogo,
  NavSection,
} from '@edison/webmail-ui/components/Navigation'
import SystemLabels from './components/SystemLabels'
import CustomLabels from './components/CustomLabels'
import SmartFolders from './components/SmartFolders'
import NavigationFooter from './components/Footer'

import { routePaths } from 'utils/constants'
import { useOrderId } from 'core/auth/hooks'
import { useInboxZeroConditon } from 'core/hooks'
import { useCurrentAssets } from 'core/custom-domains/hooks'
import { getCurrentDomainAssetsLoading } from 'core/custom-domains/selectors'

import { ForceOnMailDarkTheme } from '@edison/webmail-ui/styles/theme'

import type { RouterHistory, Match } from 'react-router-dom'

type Props = {
  history: RouterHistory,
  match: Match,
}

const View = ({ history, match }: Props) => {
  const userId = useOrderId()
  const activeId: string = get(match.params, 'label')

  const assets = useCurrentAssets()
  const isAssetsLoading = useSelector(getCurrentDomainAssetsLoading)

  const invert = useInboxZeroConditon()

  const onClickLabel = (label: string) => {
    history.push(
      generatePath(routePaths.main, {
        userId,
        label,
      })
    )
  }

  return (
    <ForceOnMailDarkTheme enable={invert}>
      <Navigation
        logo={
          <Link to={generatePath(routePaths.main, { userId, label: 'inbox' })}>
            {!isAssetsLoading && <AppLogo src={assets.logoUrl} />}
          </Link>
        }
        footer={<NavigationFooter />}
      >
        <NavSection>
          <SystemLabels
            invert={invert}
            activeId={activeId}
            setLabel={onClickLabel}
          />
        </NavSection>
        <NavSection>
          <SmartFolders
            invert={invert}
            activeId={activeId}
            setLabel={onClickLabel}
          />
        </NavSection>
        <NavSection>
          <CustomLabels
            invert={invert}
            activeId={activeId}
            setLabel={onClickLabel}
          />
        </NavSection>
      </Navigation>
    </ForceOnMailDarkTheme>
  )
}

export default withRouter(View)
