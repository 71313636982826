// @flow
import i18next from 'i18next'
import * as client from '@edison/webmail-core/api/beta-features'
import { createAction } from 'utils/redux'

import { getAuth } from 'core/auth/selectors'

import type {
  BetaFeatureListRequest,
  BetaFeatureListSuccess,
  BetaFeatureListFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchBetaFeaturesActions: {
  request: ActionCreator<BetaFeatureListRequest>,
  success: ActionCreator<BetaFeatureListSuccess>,
  failure: ActionCreator<BetaFeatureListFailure>,
} = {
  request: createAction('BETA_FEATURE_LIST_REQUEST'),
  success: createAction('BETA_FEATURE_LIST_SUCCESS'),
  failure: createAction('BETA_FEATURE_LIST_FAILURE'),
}

export function fetchBetaFeatures(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchBetaFeaturesActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchBetaFeaturesActions.request())
      const { features, newFeatures } = await client.list({ auth })
      dispatch(fetchBetaFeaturesActions.success({ features, newFeatures }))
    } catch (e) {
      dispatch(fetchBetaFeaturesActions.failure({ message: e.message }))
    }
  }
}
