// @flow
import React from 'react'
import { useSelector } from 'react-redux'

import { SingleFileSizeLimitPaywall } from '@edison/webmail-ui/components/Paywall'
import { getFeatureFlags, isPlanUpgradeable } from 'core/premium/selectors'
import { formatSize } from 'utils'

type Props = { isOpen: boolean, toggle: () => mixed, onClose: () => mixed }

const View = (props: Props) => {
  const { singleFileSizeLimit } = useSelector(getFeatureFlags)
  const isUpgradeable = useSelector(isPlanUpgradeable)

  const limit = formatSize(singleFileSizeLimit)
  const noUpgrade = !isUpgradeable

  return (
    <SingleFileSizeLimitPaywall
      limit={limit}
      noUpgrade={noUpgrade}
      {...props}
    />
  )
}

export default View
