// @flow
import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import values from 'lodash/values'
import pick from 'lodash/pick'
import get from 'lodash/get'

import { actions } from 'core/contacts'
import { useShowThreadDetail } from 'hooks/useHandleDetailModal'

import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import ContactDetail from '@edison/webmail-ui/components/ContactDetail'
import ContactHeader from '@edison/webmail-ui/components/ContactDetail/Header'
import AuthAvatar from 'common/AuthAvatar'

import Info from '../components/Info'
import Emails from '../components/Emails'
import Photos from '../components/Photos'
import Files from '../components/Files'

import type { Dispatch } from 'types/redux'
import type { RouterHistory } from 'react-router-dom'
import type { Contact } from '@edison/webmail-core/types/contacts'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onEdit: () => void,
  contact: Contact,
  history: RouterHistory,
}

export default withRouter(({ history, contact, onClose, onEdit }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const showThreadDetail = useShowThreadDetail()
  const name = values(pick(contact, ['firstName', 'lastName'])).join(' ')
  const avatar = get(contact, 'avatar', '')

  const avatarNode = useMemo(
    () => (
      <AuthAvatar
        className="flex-grow-0 flex-shrink-0 w-24 h-24 mx-8 text-4xl"
        src={contact.avatar}
        alt={name}
      />
    ),
    [avatar, name]
  )

  function onDelete() {
    onClose()
    return dispatch(actions.deleteContact([contact.id]))
  }

  return (
    <ContactDetail
      header={
        <ContactHeader
          onClose={onClose}
          avatar={avatarNode}
          actions={[
            { icon: <Edit fontSize="small" />, label: 'Edit', onClick: onEdit },
            {
              icon: <Delete fontSize="small" />,
              label: 'Delete',
              onClick: onDelete,
            },
          ]}
          contact={{
            name,
            jobTitle: contact.jobTitle,
            company: contact.company,
          }}
        />
      }
      tabs={[
        {
          label: 'Info',
          node: <Info id={contact.id} />,
        },
        {
          label: 'Emails',
          node: (
            <Emails
              id={contact.id}
              onSelectEmail={newThreadId => showThreadDetail(newThreadId)}
              selectedEmail=""
            />
          ),
        },
        {
          label: 'Photos',
          node: <Photos id={contact.id} />,
        },
        {
          label: 'Files',
          node: <Files id={contact.id} />,
        },
      ]}
    />
  )
})
