// @flow
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { selectors } from 'core/contacts'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import ContactDetail from './Details'
import ContactForm from './Form'

const getContactsIndexedById = selectors.getContactsIndexedById()

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '1000 !important',
  },
}))

type Props = {
  isOpen: boolean,
  toggle: () => void,
  contactId: string,
}

export default ({ isOpen, toggle, contactId }: Props) => {
  const styles = useStyles()
  const [isEdit, setEdit] = useState(false)
  const contacts = useSelector(getContactsIndexedById)
  const contact = contacts[contactId] || {}

  if (isEmpty(contact) && isOpen) {
    toggle()
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      onClose={toggle}
      classes={{ root: styles.root }}
    >
      <div className="h-60vh">
        {isEdit ? (
          <ContactForm
            isOpen={isOpen}
            onClose={() => setEdit(false)}
            contact={contact}
          />
        ) : (
          <ContactDetail
            isOpen={isOpen}
            onClose={toggle}
            onEdit={() => setEdit(true)}
            contact={contact}
          />
        )}
      </div>
    </Dialog>
  )
}
