// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  connectionStatus,
  syncAccountStatus,
} from '@edison/webmail-core/utils/constants'
import { useProviderNames, useReconnectUrl } from 'core/retrofit/hooks'
import { getSyncStatuses } from 'core/retrofit/selectors'

import { RetrofitAccountError } from '@edison/webmail-ui/components/Banner'

import type { RetrofitAccount } from '@edison/webmail-core/types/retrofit'

type Props = {
  account: ?RetrofitAccount,
}

const AccountErrorBanner = ({ account, ...props }: Props) => {
  const { t } = useTranslation()
  const providerNames = useProviderNames()
  const syncStatuses = useSelector(getSyncStatuses)

  const { getUrl, isLoading } = useReconnectUrl(account ? account.ecUUID : '')

  if (!account) {
    return null
  }

  const message = useMemo(() => {
    const email = account.emailAddress
    const provider = providerNames[account.connectionType]

    if (account.status === connectionStatus.INACTIVE) {
      return t('retrofit.error.banner.default', { email })
    } else {
      const syncStatus = syncStatuses[account.ecUUID]
      if (syncStatus) {
        switch (syncStatus.syncStatus) {
          case syncAccountStatus.INVALID_CREDENTIAL:
            return t('retrofit.error.banner.default', { email })
          case syncAccountStatus.IMAP_ACCESS_NOT_GRANTED:
            return t('retrofit.error.banner.imap', { email, provider })
          default:
        }
      }
    }

    return undefined
  }, [account])

  if (!message) {
    return null
  }

  return (
    <RetrofitAccountError
      {...props}
      message={message}
      isLoading={isLoading}
      onReconnect={() => getUrl(account.connectionType)}
    />
  )
}

export default AccountErrorBanner
