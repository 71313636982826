// @flow
import { useAuthInitialization } from 'core/retrofit/hooks'

/**
 * Initializes OAuth flow
 */
const AuthInit = () => {
  useAuthInitialization()

  // Complete redirect handled inside hooks
  return null
}

export default AuthInit
