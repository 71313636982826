// @flow
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'

import { FlagsContext, UserTutorialContext } from './context'
import {
  newUserKey,
  hideCalendarPopupKey,
  threadDetailContactDrawerOpenKey,
  userTutorialOrder,
} from './constants'
import { makeIsFeatureNew } from 'core/beta-features/selectors'
import {
  getThreadDetailContactDrawerOpen,
  setThreadDetailContactDrawerOpen,
} from 'common/storage'
import { betaFeatures } from '@edison/webmail-core/utils/constants'

import type { FlagsContextType } from './context'
import type { UserTutorialFlag } from './constants'

/**
 * Hook to provider the interface to access the given flag in localstorage
 *
 * @param {string} flag - The name as flag in localstorage
 */
export const useLocalStorageFlag = (flag: string) => {
  const { register } = useContext<FlagsContextType>(FlagsContext)
  return register(flag)
}
export const useThreadContactDrawerOpenFlag = () => {
  const { flags, setFlags } = useContext(FlagsContext)
  const getDrawerOpenFlag = (): ?boolean => {
    if (flags[threadDetailContactDrawerOpenKey] !== undefined) {
      return flags[threadDetailContactDrawerOpenKey]
    }
    return getThreadDetailContactDrawerOpen()
  }
  const setDrawerOpenFlag = (isOpen: boolean) => {
    setThreadDetailContactDrawerOpen(isOpen)
    setFlags({ ...flags, [threadDetailContactDrawerOpenKey]: isOpen })
  }
  return {
    drawerOpen: getDrawerOpenFlag(),
    getDrawerOpenFlag,
    setDrawerOpenFlag,
  }
}

export const useNewUserFlag = () => {
  const history = useHistory()
  const location = useLocation()

  // $FlowFixMe
  const { isNew: isNewFlag = false, ...restQuery } = qs.parse(
    location.search.slice(1)
  )

  const newUserFlag = useLocalStorageFlag(newUserKey)

  return {
    set: newUserFlag.set,
    value: !!isNewFlag || newUserFlag.value,
    clear: () => {
      if (isNewFlag) {
        history.replace({ serach: `?${qs.stringify(restQuery)}` })
      }
      if (newUserFlag.value) {
        newUserFlag.clear()
      }
    },
  }
}

export const useHideCalendarPopupFlag = () => {
  const newFeatureSelecotr = useMemo(
    () => makeIsFeatureNew(betaFeatures.calendar),
    []
  )

  const isNewFeature = useSelector(newFeatureSelecotr)

  const hideCalendarPopupFlag = useLocalStorageFlag(hideCalendarPopupKey)

  return {
    ...hideCalendarPopupFlag,
    value: hideCalendarPopupFlag.value || !isNewFeature,
  }
}

export const useUserTutorial = () => {
  const { flags, setFlags } = useContext(UserTutorialContext)

  const setupFlags = () => {
    const nextFlags = userTutorialOrder.reduce(
      (prev, curr) => ({ ...prev, [curr.toString()]: true }),
      {}
    )

    setFlags(nextFlags)
  }

  /**
   * Based on the flags order, returns whether there's true for the previouse flags
   *
   * @param {UserTutorialFlag} flag - current toturial flag name
   * @return {boolean}
   */
  const isPrevousFlagTrue = (flag: UserTutorialFlag) => {
    let output = false

    for (let i = 0; userTutorialOrder[i] !== flag; i++) {
      const key = userTutorialOrder[i]

      output = output || flags[key] === true
    }

    return output
  }

  /**
   * Returns whether if the current flag should be true
   *
   * @param {UserTutorialFlag} flag - current toturial flag name
   * @return {boolean}
   */
  const getFlag = (flag: UserTutorialFlag) => {
    const previousFlag = isPrevousFlagTrue(flag)

    if (previousFlag) {
      return false
    } else {
      return flags[flag] === true
    }
  }

  /**
   * Set toturial flag value
   *
   * @param {UserTutorialFlag} flag - current toturial flag name
   * @param {boolean} val - the value of the flag
   * @return {undefined}
   */
  const setFlag = (flag: UserTutorialFlag, val: boolean) => {
    const nextFlags = { ...flags, [flag.toString()]: val === true }

    setFlags(nextFlags)
  }

  return {
    isPrevousFlagTrue,
    getFlag,
    setFlag,
    setupFlags,
  }
}
