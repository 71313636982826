//@flow

import type { UnixTimestampInSeconds, ICSEventsKey } from './types-common'
import type { ReduxEvent } from './modal-redux-event'
import { CircularCache, createCircularBuffer } from '../../utils/circularCache'

export type ICSEventData = {
  calendarId: string,
  eventId: string,
  instanceId?: string,
  needToChangeEventStatus: boolean,
  data: ReduxEvent,
  updated: UnixTimestampInSeconds,
}
const cache = createCircularBuffer<ICSEventData>(50)
export class ReduxICSEventsData {
  cache: CircularCache<ICSEventData>
  constructor() {
    this.cache = cache
  }
  get(key: ICSEventsKey): ICSEventData | null {
    return this.cache.get(key)
  }
  getByPartialCacheKey(
    partialKey: string
  ): Array<{ key: string, cache: ICSEventData }> {
    return this.cache.getByPartialCacheKey(partialKey)
  }
  set(key: ICSEventsKey, icsEventData: ICSEventData) {
    this.cache.set(key, { ...icsEventData })
  }
  clearCacheByKey(key: ICSEventsKey) {
    this.cache.clearByKey(key)
  }
  updateCacheByPartialKey(partialKey: string, icsEventData: ICSEventData) {
    if (partialKey) {
      this.cache.updateByPartialCacheKey(partialKey, { ...icsEventData })
    }
  }
  clone() {
    return new ReduxICSEventsData()
  }
}
