// @flow
/**
 * Application initialization logic.
 */
import qs from 'qs'
import head from 'lodash/head'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { generatePath } from 'react-router-dom'
import { createIntervalJob } from 'utils/intervalJob'

import * as labelsActions from 'core/labels/actions'
import * as contactsActions from 'core/contacts/actions'
import * as settingsActions from 'core/settings/actions'
import * as splitInboxActions from 'core/split-inboxes/actions'
import * as customDomainActions from 'core/custom-domains/actions'
import { fetchExternalAssets } from 'core/external/actions'
import * as metadataActions from 'core/metadata/actions'
import * as usageActions from 'core/usage/actions'
import * as authSelectors from 'core/auth/selectors'
import * as cleanupActions from 'core/cleanup/actions'
import * as premium from 'core/premium'
import * as emailNudges from 'core/email-nudges'
import { getSession, refreshToken } from 'core/auth/actions'
import * as betaFeatures from 'core/beta-features'
import * as retrofit from 'core/retrofit'
import * as inboxBreak from 'core/inbox-break'
import { getCurrentWebDomain, isInOnmail } from 'core/custom-domains/selectors'

import { isStringEqual } from 'utils'
import { createAction } from 'utils/redux'
import { authStatus, routePaths, BASE_DOMAIN } from 'utils/constants'
import { initializeTypes } from './constants'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type { SetInitializationStatus } from './types'

export function initialization(): ThunkAction {
  return async (dispatch, getState) => {
    const historyJob = createIntervalJob(
      () => dispatch(metadataActions.fetchHistory()),
      {
        delayOnError: true,
        default: 3 * 1000,
        step: 10 * 1000,
        maxRetries: 12,
      }
    )

    try {
      const promise = Promise.all([
        dispatch(settingsActions.fetchSettings()),
        dispatch(labelsActions.fetchLabels()),
        dispatch(splitInboxActions.fetchSplitInboxes()),
        dispatch(premium.actions.fetchCurrentPlan()),
        dispatch(premium.actions.fetchPaymentMethod()),
        dispatch(usageActions.fetchUsage()),
        dispatch(betaFeatures.actions.fetchBetaFeatures()),
        dispatch(retrofit.actions.fetchAllAccounts()),
        dispatch(inboxBreak.actions.fetchBreakStatus()),
      ])
        .then(() => {
          dispatch(contactsActions.fetchContacts())
          dispatch(emailNudges.actions.fetchEmailNudges())
          dispatch(cleanupActions.searchCleanup())
          dispatch(contactsActions.fetchSuggestedBlocks())
          dispatch(fetchExternalAssets())
        })
        .then(() => {
          historyJob.addEventListner()
          historyJob.start()

          //When the user first launches webmail after a refresh
          authSelectors.isAuthenticated()(getState()) &&
            dispatch(premium.actions.checkAndShowFileStoragePaywall(0.9))
        })

      await promise
      dispatch(
        setInitializationStatus({ name: initializeTypes.APP, status: true })
      )
    } catch (e) {
      console.error('Initialization error', e)
      historyJob.removeEventListener()
    }
  }
}

export function refreshInitialization({
  orderId,
  domainProtected,
}: { orderId: ?string, domainProtected: boolean } = {}): ThunkAction {
  return async (dispatch, getState) => {
    const setInitStatus = () =>
      dispatch(
        setInitializationStatus({
          name: initializeTypes.REFRESH_TOKEN,
          status: true,
        })
      )

    try {
      const sessionSuccess = await dispatch(getSession())
      // No sessions found, let user logout
      if (!sessionSuccess) {
        setInitStatus()
        return
      }

      const isCustomDomain = !isInOnmail(getState())

      const sessionAccounts = authSelectors.getSessionAccounts(getState())
      const orderIds = sessionAccounts.map(({ orderId }) => orderId)
      const validOrderIds = sessionAccounts
        .filter(({ validSession }) => validSession)
        .map(({ orderId }) => orderId)

      const { search } = window.location

      // Check for custom domain URL:
      //
      // Two special cases
      //
      // #1: If the user goes to a custom domain URL with no order ID, they
      // should be redirected automatically to the first custom domain order ID
      //
      // #2: If no such order ID exists, they will be redirected back to
      // OnMail.com with the default order ID
      if (isCustomDomain) {
        const currentDomain = getCurrentWebDomain(getState())

        // Accounts in super session that belongs to the *current* web domain
        // the user is in
        const domainAccounts = sessionAccounts.filter(({ customDomain }) =>
          isStringEqual(currentDomain, customDomain)
        )
        const domainOrderIds = domainAccounts.map(({ orderId }) => orderId)

        // Case #2 - no active custom domain account in super session
        if (isEmpty(domainAccounts)) {
          // Replace the host to OnMail
          const url = new URL(window.location.href)
          url.host = `mail.${BASE_DOMAIN}`

          window.location.replace(url)
          return
        }

        // Case #1 - no order ID specified, and in custom domain
        if (isNil(orderId)) {
          window.location.replace(
            `${generatePath(routePaths.main, {
              label: 'inbox',
              userId: head(domainOrderIds),
            })}${search}`
          )
          return
        }
      } else {
        // Check for invalid order ID
        if (isNil(orderId) || !orderIds.includes(orderId)) {
          // Order ID provided in URL is invalid
          const nextOrderId = head(validOrderIds) || head(orderIds)
          if (nextOrderId) {
            // Redirect to the correct orderId
            window.location.replace(
              `${generatePath(routePaths.main, {
                label: 'inbox',
                userId: nextOrderId,
              })}${search}`
            )
            return
          } else {
            // No other session in place
            setInitStatus()
            return
          }
        }
      }

      console.log('Refresh with', orderId)
      const account = sessionAccounts.find(
        account => account.orderId === orderId
      )

      let code

      if (account) {
        const resp = await dispatch(refreshToken({ orderId: account.orderId }))
        code = resp.code
      } else if (sessionAccounts.length === 0) {
        code = authStatus.NO_AUTH
      }

      switch (code) {
        // Refreshed success
        case authStatus.VALID:
          if (domainProtected) {
            if (!isInOnmail(getState())) {
              window.location.replace('/')
              return
            }
          } else {
            await dispatch(
              customDomainActions.fetchDomainDetail({ redirect: true })
            )
          }
          break
        // Refreshed failed for expired token or missingg user or CSRF
        case authStatus.INVALID:
          const params = qs.stringify({
            orderId,
            error: authStatus.INVALID,
          })
          window.location.replace(`${routePaths.addAccountLogin}?${params}`)
          return
        // No available session, withdraw the super session
        case authStatus.NO_AUTH:
          window.location.replace(routePaths.logoutAll)
          return
        default:
          // Refreshed failed - clear all information related to this particular
          // order ID by logging out
          window.location.replace(
            generatePath(routePaths.logout, {
              userId: orderId,
            })
          )
          return
      }

      setInitStatus()
    } catch (e) {
      console.error('Refresh token initialization error', e)
    }
  }
}

export const setInitializationStatus: ActionCreator<SetInitializationStatus> = createAction(
  'SET_INITIALIZATION_STATUS'
)
