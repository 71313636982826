import LabelPillItem from '@edison/webmail-ui/components/Pill/Label'
import { useGetLabelOptions, useIsInbox } from 'core/search/hooks'
import * as selectors from 'core/search/selectors'
import * as threadSelectors from 'core/threads/selectors'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const getSelectedLabels = selectors.getSelectedLabels()
const getActiveLabel = threadSelectors.getActiveLabel()

export function LabelPill(props) {
  const { onSelect, condition, isClearAll } = props
  const ref = useRef()
  const isInbox = useIsInbox()
  const allLabels = useGetLabelOptions()
  const activeLabel = useSelector(getActiveLabel)
  const selectedLabels = useSelector(getSelectedLabels)
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])

  if (
    (!selectedLabels.length || activeLabel === selectedLabels[0].id) &&
    !isInbox
  ) {
    return null
  }

  return (
    <LabelPillItem
      ref={ref}
      labels={allLabels}
      onSelect={labels => {
        const labelsId = labels.map(item => item.id)
        const nextCondition = {
          ...condition,
          label: labelsId,
        }
        onSelect(nextCondition)
      }}
    />
  )
}
