// @flow
import React, { useMemo } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'
import { dateFormatter } from 'utils'
import { feedViewCaches } from './caches'

import { useShowThreadDetail } from 'hooks/useHandleDetailModal'
import { useThreadActions } from 'core/threads/hooks'
import { getMessage } from 'core/messages/selectors'
import {
  getThread,
  getSelectedThreadIds,
  getThreadUnreadStatus,
} from 'core/threads/selectors'
import {
  dragThread,
  selectThread,
  batchSelectThread,
} from 'core/threads/actions'
import { useDragHandler, dragTypes } from '@edison/webmail-ui/hooks/dnd'

import ThreadFeedItemSkeleton, {
  EmailBodySkeleton,
} from '@edison/webmail-ui/components/Skeletons/ThreadFeedItem'
import ThreadFeedItem from '@edison/webmail-ui/components/ThreadFeedItem'
import EmailBody from 'screens/ThreadDetail/components/EmailBody'
import MoreAction from 'screens/ThreadDetail/components/MoreAction'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import Subject from 'common/ThreadList/Subject'
import QuickActionWrapper from 'common/ThreadList/QuickActionWrapper'

import type { Dispatch } from 'types/redux'

const selectedThreadsSelector = getSelectedThreadIds()

type Props = {
  index: number,
  style: Object,
  isFetched: boolean,
  isLoaded: boolean,
  threadId: string,
  messageId: string,
}
const ThreadFeedItemOrSkeleton = ({
  index,
  style,
  isFetched,
  isLoaded,
  threadId,
  messageId,
}: Props) => {
  const showThreadDetail = useShowThreadDetail()
  const dispatch: Dispatch = useDispatch()
  const getThreadActions = useThreadActions()

  const threadSelector = useMemo(() => getThread(threadId), [threadId])
  const messageSelector = useMemo(() => getMessage(messageId), [messageId])
  const thread = useSelector(threadSelector)
  const message = useSelector(messageSelector)
  const { subject: threadSubject } = thread || {}
  const { from = {}, date, subject: messageSubject } = message || {}

  const selected = useSelector(selectedThreadsSelector)
  const threadActions = getThreadActions({ id: threadId })

  // Thread status
  const threadStatusSelector = useMemo(() => getThreadUnreadStatus(threadId), [
    threadId,
  ])
  const isUnread = useSelector(threadStatusSelector)

  // DnD
  const { ref } = useDragHandler({
    extraInfo: { id: threadId },
    type: dragTypes.message,
    enableCustomPreview: true,
    begin: () => {
      batch(() => {
        dispatch(dragThread({ isDragging: true }))
        dispatch(selectThread({ id: threadId, value: true }))
      })
    },
    end: () => {
      batch(() => {
        dispatch(dragThread({ isDragging: false }))
        // Cancel the selected by passing the ids in redux
        // !!Do not pass the thread id directly here
        dispatch(batchSelectThread({ ids: Array.from(selected), value: false }))
      })
    },
  })

  const actions = useMemo(
    () => (
      <MoreAction
        menus={[
          threadActions.archive,
          isUnread ? threadActions.read : threadActions.unread,
          threadActions.trash,
          threadActions.markAsSpam,
        ]}
      />
    ),
    [isUnread, threadId]
  )

  const avatar = (
    <QuickActionWrapper
      threadId={threadId}
      actions={threadActions}
      actionFlags={{
        archive: true,
        trash: true,
      }}
    >
      <AuthEmailAvatar email={from.email} name={from.name} />
    </QuickActionWrapper>
  )

  function handleOnClick() {
    showThreadDetail(threadId)
  }

  const fromName = (from.name || '').trim()
  const fromEmail = !!fromName ? from.email : ''

  if (isNil(message)) return <ThreadFeedItemSkeleton style={style} />

  return (
    <ThreadFeedItem
      body={
        isLoaded ? (
          <EmailBody
            message={message}
            viewport="mobile"
            caches={feedViewCaches}
          />
        ) : (
          <EmailBodySkeleton />
        )
      }
      dndRef={ref}
      index={index}
      style={style}
      avatar={avatar}
      actions={actions}
      readStatus={!isUnread}
      fromName={fromName || from.email}
      fromAddress={fromEmail}
      onClick={handleOnClick}
      receivedAt={dateFormatter(date)}
      title={<Subject subject={threadSubject || messageSubject} />}
    />
  )
}
export default ThreadFeedItemOrSkeleton
