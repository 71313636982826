// @flow
import moment from 'moment'
import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import fromPairs from 'lodash/fromPairs'
import isEqual from 'lodash/isEqual'
import differenceWith from 'lodash/differenceWith'
import toPairs from 'lodash/toPairs'
import { combineReducers } from 'redux'
import { createReducer } from 'utils/redux'
import { contactTypes } from 'utils/constants'
import {
  fetchContactsActions,
  updateContactActions,
  deleteContactActions,
  batchUpdateSenderActions,
  fetchContactThreadsActions,
  fetchContactPendingThreadsActions,
  fetchContactSuggestionsActions,
  fetchContactAttachmentsActions,
  createTemporaryContact,
  fetchSuggestedBlocksActions,
  feedbackSuggestedBlockActions,
  fetchPendingMessageActions,
  blockDomainActions,
  unblockDomainActions,
  resetContactThreads,
} from './actions'
import { delaySendDraftActions } from '../compose/actions'
import { getAvatarUrl, getTemporaryContact, getDomainByEmail } from 'utils'

import type {
  State,
  ContactActions,
  ContactListSuccess,
  ContactUpdateSuccess,
  ContactDeleteSuccess,
  ContactThreadsSuccess,
  ContactPendingThreadsRequest,
  ContactPendingThreadsSuccess,
  ContactSuggestionsSuccess,
  ContactAttactmentsSuccess,
  ContactStatusBatchUpdateSuccess,
  TemporaryContactCreate,
  SuggestedBlockListSuccess,
  SuggestedBlockFeedbackSuccess,
  ContactPendingMessageSuccess,
  BlockDomainActionsSuccess,
  UnblockDomainActionsSuccess,
  ResetContactThreads,
} from './types'
import { DelaySendDraftSuccess } from '../compose/types'
import { updateEmailsByEntities } from './helpers'

export const contactsState = {
  entities: {},
  emails: {},
}

const contactsReducer = createReducer<
  $PropertyType<State, 'contacts'>,
  ContactActions
>(contactsState, {
  [fetchContactsActions.success.toString()]: contactListSuccess,
  [updateContactActions.success.toString()]: contactUpdateSuccess,
  [deleteContactActions.success.toString()]: contactDeleteSuccess,
  [batchUpdateSenderActions.success.toString()]: contactStatusBatchUpdateSuccess,
  [createTemporaryContact.toString()]: temporaryContactCreate,
  [blockDomainActions.success.toString()]: contactUpdateDomainSuccess,
  [unblockDomainActions.success.toString()]: contactUpdateDomainSuccess,
})

function contactUpdateDomainSuccess(
  state: $PropertyType<State, 'contacts'>,
  action: BlockDomainActionsSuccess | UnblockDomainActionsSuccess
) {
  const { ids, domain, email } = action.payload
  const nextEntities = { ...state.entities }
  if (!!domain) {
    for (let [id, contact] of toPairs(nextEntities)) {
      const hasBlockedDomainByEmail = contact.emails.some(
        item => getDomainByEmail(item.email) === getDomainByEmail(email)
      )
      if (hasBlockedDomainByEmail && contact.status === contactTypes.PENDING) {
        nextEntities[id] = {
          ...nextEntities[id],
          status: contactTypes.BLOCK,
        }
      }
    }
  }
  for (let id of ids) {
    if (id in nextEntities) {
      delete nextEntities[id]
    } else {
      nextEntities[id] = getTemporaryContact({
        id,
        isDomain: 1,
        status: contactTypes.BLOCK,
        firstName: domain,
        lastName: domain,
        emails: [{ email }],
        avatar: getAvatarUrl(email),
      })
    }
  }
  const emails = { ...state.emails }
  return {
    ...state,
    emails: updateEmailsByEntities(nextEntities, emails),
    entities: {
      ...nextEntities,
    },
  }
}

function contactListSuccess(
  state: $PropertyType<State, 'contacts'>,
  action: ContactListSuccess
) {
  const { contacts, status } = action.payload

  let next
  if (values(contactTypes).includes(status)) {
    const existedIds = contacts
      .flatMap(({ emails }) => emails)
      .map(item => state.emails[item.email])
      .filter(Boolean)

    // INFO: omit the existed by emails to remove the temporary contacts
    next = values(omit(state.entities, existedIds)).filter(
      entity => entity.status !== status
    )
    next = [...next, ...contacts]
  } else {
    next = contacts
  }

  return {
    entities: keyBy(next, 'id'),
    emails: fromPairs(
      next.flatMap(each => each.emails.map(email => [email.email, each.id]))
    ),
  }
}

function contactUpdateSuccess(
  state: $PropertyType<State, 'contacts'>,
  action: ContactUpdateSuccess
) {
  const contact = action.payload
  return {
    ...state,
    entities: {
      ...state.entities,
      [contact.id]: contact,
    },
  }
}

function contactDeleteSuccess(
  state: $PropertyType<State, 'contacts'>,
  action: ContactDeleteSuccess
) {
  const { ids } = action.payload
  const toDeletedEmails = ids
    .map(id => state.entities[id])
    .filter(Boolean)
    .flatMap(contact => contact.emails)
    .map(each => each.email)

  return {
    ...state,
    entities: omit(state.entities, ids),
    emails: omit(state.emails, toDeletedEmails),
  }
}

function contactStatusBatchUpdateSuccess(
  state: $PropertyType<State, 'contacts'>,
  action: ContactStatusBatchUpdateSuccess
) {
  const { emails, status } = action.payload

  let nextEntities = { ...state.entities }
  for (let email of emails) {
    const id = state.emails[email]
    if (nextEntities[id]) {
      nextEntities[id].status = status
    }
  }

  return {
    ...state,
    entities: nextEntities,
  }
}

function temporaryContactCreate(
  state: $PropertyType<State, 'contacts'>,
  action: TemporaryContactCreate
) {
  const { email, name, status } = action.payload
  const [emailName] = email.split('@')
  const [firstName, ...lastName] = (name || emailName).split(' ')
  const temporaryContact = getTemporaryContact({
    id: email,
    status,
    firstName,
    lastName: lastName.join(' '),
    emails: [{ email }],
    avatar: getAvatarUrl(email),
  })
  return {
    ...state,
    entities: {
      ...state.entities,
      [temporaryContact.id]: temporaryContact,
    },
    emails: {
      ...state.emails,
      [email]: temporaryContact.id,
    },
  }
}

export const suggestionsState = {
  autoComplete: {},
  block: {},
}

const suggestionsReducer = createReducer<
  $PropertyType<State, 'suggestions'>,
  ContactActions
>(suggestionsState, {
  [fetchContactSuggestionsActions.success.toString()]: contactSuggestionsSuccess,
  [delaySendDraftActions.success.toString()]: composeSendSuccess,
  // Suggested Block
  [fetchSuggestedBlocksActions.success.toString()]: fetchSuggestedBlocksSuccess,
  [feedbackSuggestedBlockActions.success.toString()]: feedbackSuggestedBlockSuccess,
})

function updateAutoComplete(state, next) {
  return {
    ...state,
    autoComplete: values(next)
      .sort((a, b) => (a.date > b.date ? -1 : 1))
      .slice(0, 10)
      .reduce(
        (prev, curr) => ({
          ...prev,
          [curr.email]: curr,
        }),
        {}
      ),
  }
}

function contactSuggestionsSuccess(
  state: $PropertyType<State, 'suggestions'>,
  action: ContactSuggestionsSuccess
) {
  const { contacts } = action.payload
  let next = { ...state.autoComplete }
  for (let contact of contacts) {
    next[contact.email] = contact
  }

  return updateAutoComplete(state, next)
}

function composeSendSuccess(
  state: $PropertyType<State, 'suggestions'>,
  action: DelaySendDraftSuccess
) {
  const { draft } = action.payload
  const { to = [], cc = [], bcc = [] } = draft

  let next = { ...state.autoComplete }

  for (let { email, name } of [...to, ...cc, ...bcc]) {
    if (email in next) {
      continue
    }

    const senderName = !!name.trim() ? name.trim() : email.split('@')[0]
    next[email] = {
      email,
      name: senderName,
      id: '',
      date: moment().unix(),
      avatar: getAvatarUrl(email),
    }
  }

  return updateAutoComplete(state, next)
}

function fetchSuggestedBlocksSuccess(
  state: $PropertyType<State, 'suggestions'>,
  action: SuggestedBlockListSuccess
) {
  const suggestion = action.payload

  return {
    ...state,
    block: suggestion.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {}
    ),
  }
}

function feedbackSuggestedBlockSuccess(
  state: $PropertyType<State, 'suggestions'>,
  action: SuggestedBlockFeedbackSuccess
) {
  const { id } = action.payload

  const { [id]: _, ...nextBlock } = state.block

  return {
    ...state,
    block: nextBlock,
  }
}

export const threadsState = {}

const threadsReducer = createReducer<
  $PropertyType<State, 'threads'>,
  ContactActions
>(threadsState, {
  [fetchContactThreadsActions.success.toString()]: contactThreadsSuccess,
  [resetContactThreads.toString()]: clearContactThreads,
})

function clearContactThreads(
  state: $PropertyType<State, 'threads'>,
  action: ResetContactThreads
) {
  const { id } = action.payload
  return {
    ...state,
    [id]: [],
  }
}

function contactThreadsSuccess(
  state: $PropertyType<State, 'threads'>,
  action: ContactThreadsSuccess
) {
  const { threads, id } = action.payload
  const prevThreads = state[id] ? state[id] : []
  const newThread = differenceWith(threads, prevThreads, isEqual)
  return {
    ...state,
    [id]: [...prevThreads, ...newThread],
  }
}

export const pageTokenState = {
  pageToken: '',
}

const pageTokenReducer = createReducer<
  $PropertyType<State, 'pageToken'>,
  ContactActions
>(pageTokenState, {
  [fetchContactThreadsActions.success.toString()]: pageTokenSuccess,
})

function pageTokenSuccess(
  state: $PropertyType<State, 'pageToken'>,
  action: ContactThreadsSuccess
) {
  const { next_page_token } = action.payload
  return {
    ...state,
    pageToken: next_page_token,
  }
}

const pendingThreadsReducer = createReducer<
  $PropertyType<State, 'pendingThreads'>,
  ContactActions
>(
  {},
  {
    [fetchContactPendingThreadsActions.request.toString()]: contactPendingThreadsRequest,
    [fetchContactPendingThreadsActions.success.toString()]: contactPendingThreadsSuccess,
    [batchUpdateSenderActions.success.toString()]: removePendingThreads,
  }
)

function contactPendingThreadsRequest(
  state: $PropertyType<State, 'pendingThreads'>,
  action: ContactPendingThreadsRequest
) {
  const { id } = action.payload
  if (id in state) return state
  else
    return {
      ...state,
      [id]: [],
    }
}

function contactPendingThreadsSuccess(
  state: $PropertyType<State, 'pendingThreads'>,
  action: ContactPendingThreadsSuccess
) {
  const { id, threads } = action.payload
  return {
    ...state,
    [id]: threads,
  }
}

function removePendingThreads(
  state: $PropertyType<State, 'pendingThreads'>,
  action: ContactStatusBatchUpdateSuccess
) {
  const { emails } = action.payload

  return omit(state, emails)
}

const pendingMessageIdReducer = createReducer<
  $PropertyType<State, 'pendingMessageId'>,
  ContactActions
>(
  {},
  {
    [fetchPendingMessageActions.success.toString()]: fetchPendingMessageSuccess,
  }
)

function fetchPendingMessageSuccess(
  state: $PropertyType<State, 'pendingMessageId'>,
  action: ContactPendingMessageSuccess
) {
  const messageIds = action.payload

  return {
    ...state,
    ...messageIds,
  }
}

export const attachmentsState = {}

const attachmentsReducer = createReducer<
  $PropertyType<State, 'attachments'>,
  ContactActions
>(threadsState, {
  [fetchContactAttachmentsActions.success.toString()]: contactAttactmentsSuccess,
})

function contactAttactmentsSuccess(
  state: $PropertyType<State, 'attachments'>,
  action: ContactAttactmentsSuccess
) {
  const { attachments, id } = action.payload

  const uniqAttachments = {}
  for (let attachment of [...(state[id] || []), ...attachments]) {
    let uniqId = `${attachment.message}-${attachment.id}`
    if (attachment.type === 'large') {
      uniqId = attachment.messageHeaderId + uniqId
    }

    if (uniqId in uniqAttachments) continue
    else {
      uniqAttachments[uniqId] = attachment
    }
  }
  return {
    ...state,
    [id]: values(uniqAttachments).sort((a, b) => {
      if (a.date === b.date) {
        return a.name < b.name ? -1 : 1
      }
      return a.date < b.date ? 1 : -1
    }),
  }
}

export default combineReducers<_, ContactActions>({
  contacts: contactsReducer,
  suggestions: suggestionsReducer,
  threads: threadsReducer,
  pendingThreads: pendingThreadsReducer,
  pendingMessageId: pendingMessageIdReducer,
  attachments: attachmentsReducer,
  pageToken: pageTokenReducer,
})
