// @flow
import get from 'lodash/get'
import xor from 'lodash/xor'
import i18next from 'i18next'
import {
  connectionTypes,
  connectionStatus,
} from '@edison/webmail-core/utils/constants'
import { quickActions } from '@edison/webmail-ui/components/QuickAction'

import type { RetrofitAccount } from '@edison/webmail-core/types/retrofit'
import type { QuickActionType } from '@edison/webmail-ui/components/QuickAction'
import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'

const DEFAULT_CONFIG = {
  // Domain for onmail account
  domain: 'onmail.com',
  // Site domain
  webDomain: 'mail.onmail.com',
  // Domain for requesting for static resouces
  webApiDomain: 'wmapi.onmail.com',
  // Domain for uploading large attachment
  uploadServer: 'upload.onmail.com',
  freeTrialDuration: 94608000,
}

const config = window.onmailConfig || DEFAULT_CONFIG
export const onmailConfig = config
export const defaultFavicon = `${process.env.PUBLIC_URL || ''}/favicon.ico`

export const BASE_DOMAIN = get(config, 'domain', '')
export const WM_API_HOST = `https://${get(config, 'webApiDomain', '')}`

export const messages = {
  // Error
  wrongCredentials: "Username or password doesn't match.",
  notAuthenticated: 'Not authenticated.',
  serverError: 'Server error.',
}

export const AUTH_HEADER = 'x-Auth-Token'
export const CSRF_HEADER = 'x-Csrf-Token'
export const SUPER_SESSION_ID_HEADER = 'x-Super-Session-ID'
export const ORDER_ID_HEADER = 'x-Order-ID'
export const DOMAIN_TICKET_HEADER = 'x-Domain-Ticket'
export const DOMAIN_HEADER = 'x-Domain'
export const ANALYTICS_HEADER = 'x-Analytics-Id'

export const defaultPaginationState = {
  ids: [],
  total: 0,
  next: null,
}

export const siftLabelNames: { [key: string]: string } = {
  // Sift actual label name
  siftPurchase: 'EMS_INTL_SIFT_PURCHASE',
  siftShipment: 'EMS_INTL_SIFT_SHIPMENT',
  siftBill: 'EMS_INTL_SIFT_BILL',
  siftEvent: 'EMS_INTL_SIFT_EVENT',
  siftRestaurant: 'EMS_INTL_SIFT_RESTAURANT',
  siftHotel: 'EMS_INTL_SIFT_HOTEL',
  siftTrain: 'EMS_INTL_SIFT_TRAIN',
  siftRentalcar: 'EMS_INTL_SIFT_RENTALCAR',
  siftFlight: 'EMS_INTL_SIFT_FLIGHT',
  siftBoardingPass: 'EMS_INTL_SIFT_BOARDINGPASS',
  siftCruise: 'EMS_INTL_SIFT_CRUISE',
  siftPriceAlert: 'EMS_INTL_SIFT_PRICEALERT',
}

export const priceAlertLabelNames = {
  // Price alert actual label name
  priceAlert: 'EMS_INTL_SIFT_PRICEALERT',
}

/**
 * Maps label names from URL to actual system labels in backend.
 *
 * @public
 */
export const labelNames = {
  // Sift
  ...siftLabelNames,
  // Price Alert
  ...priceAlertLabelNames,

  inbox: 'INBOX',
  spam: 'SPAM',
  trash: 'TRASH',
  sent: 'SENT',
  drafts: 'DRAFTS',
  unread: 'UNREAD',
  starred: 'STARRED',
  important: 'IMPORTANT',
  archive: 'ARCHIVE',
  primary: 'PRIMARY',
  social: 'SOCIAL',
  promotions: 'PROMOTIONS',
  pending: 'EMS_INTL_PENDING_S',
  travel: 'EMS_INTL_TRAVEL',
  billAndReceipts: 'EMS_INTL_BILL_AND_RECEIPT',
  packages: siftLabelNames.siftShipment,
  events: 'EMS_INTL_EVENTS',
  other: 'EMS_INTL_OTHER',
  priceTracking: priceAlertLabelNames.priceAlert,

  // Retrofit build-in accounts
  allAccounts: 'EMS_INTL_ALL_ACCOUNTS',
  onmail: 'EMS_INTL_ONMAIL',
  // Use to calculate unread count
  rawUnread: '_UNREAD',
  rawRead: '_READ',

  inboxStaged: 'EMS_INTL_INBOX_BREAK',
}

export const labelNameformat = {
  [labelNames.drafts]: 'Draft',
}

export const labelRouteNames = {
  [labelNames.trash]: 'trash',
  [labelNames.spam]: 'spam',
  [labelNames.other]: 'other',
  [labelNames.drafts]: 'drafts',
  [labelNames.travel]: 'travel',
  [labelNames.billAndReceipts]: 'shopping',
  [labelNames.packages]: 'packages',
  [labelNames.events]: 'events',
  [labelNames.priceTracking]: 'price_tracking',
}

export const displayTitles = {
  [labelNames.inbox]: 'Inbox',
  [labelNames.spam]: 'Spam',
  [labelNames.trash]: 'Trash',
  [labelNames.sent]: 'Sent',
  [labelNames.drafts]: 'Drafts',
  [labelNames.unread]: 'Unread',
  [labelNames.starred]: 'Starred',
  [labelNames.important]: 'Important',
  [labelNames.archive]: 'Done',
  [labelNames.primary]: 'Primary',
  [labelNames.social]: 'Social',
  [labelNames.promotions]: 'Promotions',
  [labelNames.other]: 'Other',
  [labelNames.pending]: 'Pending',
  [labelNames.primary]: 'Primary',
  // Sift
  [labelNames.travel]: 'Travel',
  [labelNames.billAndReceipts]: 'Shopping',
  [labelNames.packages]: 'Packages',
  [labelNames.events]: 'Events',

  // Price Alert
  [labelNames.priceAlert]: 'Price Tracking',
}

export const inboxActon = {
  main: [
    actionNodes.archive.name,
    actionNodes.read.name,
    actionNodes.unread.name,
  ],
  menu: [
    actionNodes.trash.name,
    actionNodes.markAsSpam.name,
    actionNodes.moveToSplit.name,
    actionNodes.addLabel.name,
    actionNodes.deleteForever.name,
  ],
  disabled: [],
}

export const archiveAction = {
  main: [
    actionNodes.unarchive.name,
    actionNodes.read.name,
    actionNodes.unread.name,
  ],
  menu: [
    actionNodes.trash.name,
    actionNodes.markAsSpam.name,
    actionNodes.addLabel.name,
    actionNodes.deleteForever.name,
  ],
  disabled: [],
}

export const sentAction = {
  main: [actionNodes.trash.name],
  menu: [actionNodes.addLabel.name, actionNodes.deleteForever.name],
  disabled: [],
}

export const draftsAction = {
  main: [actionNodes.discardDraft.name],
  menu: [],
  disabled: [],
}

export const unreadAction = {
  main: [actionNodes.archive.name, actionNodes.read.name],
  menu: [
    actionNodes.trash.name,
    actionNodes.markAsSpam.name,
    actionNodes.addLabel.name,
    actionNodes.deleteForever.name,
  ],
  disabled: [],
}

export const trashAction = {
  main: [actionNodes.untrash.name, actionNodes.deleteForever.name],
  menu: [actionNodes.markAsSpam.name],
  disabled: [],
}

export const spamAction = {
  main: [actionNodes.notSpam.name, actionNodes.deleteForever.name],
  menu: [],
  disabled: [],
}

export const otherAction = {
  main: [actionNodes.read.name, actionNodes.unread.name],
  menu: [
    actionNodes.removeLabel.name,
    actionNodes.trash.name,
    actionNodes.markAsSpam.name,
    actionNodes.addLabel.name,
    actionNodes.deleteForever.name,
  ],
  disabled: [],
}

export const labelNameMapAction = {
  // TODO now in search don't supprot spilt action, need to support
  [labelNames.inbox]: {
    ...inboxActon,
    menu: xor(inboxActon.menu, [actionNodes.moveToSplit.name]),
  },
  [labelNames.archive]: archiveAction,
  [labelNames.sent]: sentAction,
  [labelNames.drafts]: draftsAction,
  [labelNames.trash]: trashAction,
  [labelNames.spam]: spamAction,
}

export const smartFolders: $ReadOnlyArray<string> = [
  labelNames.travel,
  labelNames.billAndReceipts,
  labelNames.packages,
  labelNames.events,
]

export const siftLabelsGroup: { [key: string]: $ReadOnlyArray<string> } = {
  [labelNames.travel]: [
    labelNames.siftTrain,
    labelNames.siftFlight,
    labelNames.siftCruise,
    labelNames.siftHotel,
    labelNames.siftRentalcar,
  ],
  [labelNames.billAndReceipts]: [labelNames.siftPurchase, labelNames.siftBill],
  [labelNames.packages]: [labelNames.siftShipment],
  [labelNames.events]: [labelNames.siftEvent, labelNames.siftRestaurant],
}

// Labels which aren't using in app
// But not allow user to create
export const reservedLabels = {
  read: 'read',
}

export const labelUnreadConfig = {
  [labelNames.sent]: {
    display: false,
  },
  [labelNames.trash]: {
    display: false,
  },
  [labelNames.archive]: {
    display: false,
  },
  [labelNames.drafts]: {
    displayTotal: true,
  },
}

export const labelTypes = {
  SYSTEM: '1',
  CUSTOM: '2',
  SPLIT_INBOXES: '3',
  RETROFIT: '4',
}

export const UNKNOWN_SENDER = 'Unknown'

export const RESPONSE_TYPE_REPLY = 'reply'
export const RESPONSE_TYPE_REPLY_ALL = 'reply_all'
export const RESPONSE_TYPE_FORWARD = 'forward'
export const RESPONSE_TYPE_NONE = ''

export const COMPOSE_DEBOUNCE_TIME = 2000

export const composeStatus = {
  saving: 'saving',
  saved: 'saved',
  sending: 'sending',
  sended: 'sended',
  deleting: 'deleting',
  deleted: 'deleted',
}

export const defaultDateFormat = {
  sameDay: 'h:mma',
  sameYear: 'MMM D',
  default: 'M/D/YY',
}

export const suggestedBlockStatus = {
  default: 0,
  pending: 1,
  blocked: 2,
  dismissed: 3,
}

export const contactBlockDomainType = {
  UNBLOCK: 0,
  BLOCK: 1,
}

export const contactTypes = {
  NORMAL: 0,
  PENDING: 1,
  BLOCK: 2,
}

export const contactActions = {
  APPROVE: 'approve',
  BLOCK: 'block',
  UNBLOCK: 'unblock',
}

export const contactIdTypes = {
  ID: 'ID',
  EMAIL: 'EMAIL',
}

export const contactDetailTabs = {
  summary: 'SUMMARY',
  reservation: 'RESERVATION',
  emails: 'EMAILS',
  photos: 'PHOTOS',
  files: 'FILES',
}

export const INBOX_ZERO_IMG_PATH = 'urls.regular'
export const INBOX_ZERO_DEFAULT =
  'https://images.unsplash.com/photo-1604462432519-7b81d82d5baf?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8'

export const snackbarTypes = {
  send: 'SEND_SNACKBAR',
}

export const modalTypes = {
  contactModal: 'CONTACT_MODAL',
  preview: 'PREVIEW',
  providers: 'PROVIDERS_MODAL',
  setPassword: 'SET_PASSWORD_MODAL',
  filter: 'FILTER_MODAL',
  splitInbox: 'SPLIT_INBOX',
  moveToSplitInbox: 'MOVE_TO_SPLIT_INBOX',
  labelDetail: 'LABEL_DETAIL',
  labelPrompt: 'LABEL_PROMPT',
  approveSender: 'APPROVE_SENDER',
  siftDetail: 'SIFT_DETAIL',
  blockPrompt: 'BLOCK_PROMPT',
  priceAlertDetail: 'PRICE_ALERT_DETAIL',
  priceAlertList: 'PRICE_ALERT_LIST',
  priceAlertClaimSuccess: 'PRICE_ALERT_CLAIM_SUCCESS',
  onboarding: 'ONBOARDING',
  attachmentPassword: 'ATTACHMENT_PASSWORD',
  downloadAllPassword: 'DOWNLOAD_ALL_PASSWORD',
  exceedMaxSize: 'EXCEED_MAX_SIZE',
  newSubaccount: 'NEW_SUBACCOUNT',
  subaccountDetail: 'SUBACCOUNT_DETAIL',
  convertDomainAdmin: 'CONVERT_DOMAIN_ADMIN',
  addOnmailAlias: 'ADD_ONMAIL_ALIAS',
  error: 'ERROR',
  purchasePayment: 'PURCHASE_PAYMENT',
  updatePayment: 'UPDATE_PAYMENT',
  signupPayment: 'SIGNUP_PAYMENT',
  changePlanPreview: 'CHANGE_PLAN_PREVIEW',
  cancelPendingConfirmation: 'CANCEL_PENDING_CONFIRMATION',
  paywall: 'PAYWALL',
  newForwardEmail: 'NEW_FORWARD_EMAIL',
  storageDetail: 'STORAGE_DETAIL',
  domainDisconnect: 'DOMAIN_DISCONNECT',
  addSessionAccount: 'ADD_SESSION_ACCOUNT',
  customDomainAliasNotReady: 'CUSTOM_DOMAIN_ALIAS_NOT_READY',
  mobileDownload: 'MOBILE_DOWNLOAD',

  confirm: 'CONFIRM',
  upload: 'UPLOAD',
  showOriginal: 'SHOW_ORIGINAL',

  // Critical warnings
  downgradePlanWarning: 'DOWNGRADE_PLAN_WARNING',
  disconnectDomainWarning: 'DISCONNECT_DOMAIN_WARNING',

  sendWithUplodingAttachment: 'SEND_WITH_UPLOADING_ATTACHMENT',
  contactAdmin: 'CONTACT_ADMIN',

  editContact: 'EDIT_CONTACT',
  // DeleteAccount
  deleteAccount: 'DELETE_ACCOUNT',

  // DNS records
  addDnsRecord: 'ADD_DNS_RECORD',
  viewDnsRecord: 'VIEW_DNS_RECORD',

  // DnD
  dndSplitConfirm: 'DND_SPLIT_CONFIRM',
  dndCategoryConfirm: 'DND_CATEGORY_CONFIRM',

  // Retrofit
  accountRemovalConfirmation: 'ACCOUNT_REMOVAL_CONFIRM',
  accountProviders: 'ACCOUNT_PROVIDERS',

  //cleanup
  suggestedCleanupCalendar: 'SUGGESTED_CLEANUP_CALENDAR',

  mfaVerify: 'MFA_VERIFY',
  emailConfirmation: 'EMAIL_CONFIRMATION',

  appSpecificPassword: 'APP_SPECIFIC_PASSOWRD',

  // Inbox break
  manualSetup: 'MANUAL_SETUP',
  endBreakConfirm: 'ENA_BREAK_CONFIRM',
  scheduledBreak: 'SCHEDULED_BREAK',
  breakRemovalConfirm: 'BREAK_REMOVAL_CONFIRM',
  timezoneConfirm: 'TIMEZONE_CONFIRM',

  // Password Auth
  passwordAuth: 'PASSWORD_AUTH',

  premiumSource: 'PREMIUM_SOURCE',

  accountLoading: 'ACCOUNT_LOADING',

  contactsGallery: 'CONTACTS_GALLERY',

  contactFirstAction: 'CONTACT_FIRST_ACTION',
  quickAction: 'QUICK_ACTION',

  recoveryMethod: 'RECOVERY_METHOD',
  addRecoveryMethod: 'ADD_RECOVERY_METHOD',
  deteleContactMethod: 'DETELE_CONTACT_METHOD',

  // domain
  blockDomain: 'BLOCK_DOMAIN_MESSAGE',
  unblockDomain: 'UNBLOCK_DOMAIN',
  unblockSenderWithDomain: 'UNBLCOK_SENDER_WITH_Domain',

  suggestedBlock: 'SUGGESTED_BLOCK_MODAL',
  emailNudgeCard: 'EMAIL_NUDGE_CARD',
}

export const toastTypes = {
  notification: 'NOTIFICATION',
  loading: 'LOADING',
}

export const MAX_SEARCH_HISTORIES = 3
export const MAX_SEARCH_HINTS = 5

export const searchHintTypes = {
  KEYWORDS: 'KEYWORDS',
  HISTORY: 'HISTORY',
}

export const searchPrefixes = {
  emails: { key: 'emails', value: '', label: 'Emails' },
  attachments: {
    key: 'attachments',
    value: 'with attachments',
    label: 'Attachments',
  },
  travel: { key: 'travel', value: 'trip', label: 'Travel' },
  receipt: { key: 'receipt', value: 'receipt', label: 'Receipt' },
  packages: { key: 'packages', value: 'packages', label: 'Packages' },
  events: { key: 'events', value: 'events', label: 'Events' },
  priceTracking: {
    key: 'priceTracking',
    value: 'price tracking',
    label: 'Price Tracking',
  },
}
export const searchAttachmentTabs = {
  all: { value: 'all', label: 'All' },
  photos: { value: 'photos', label: 'Photos' },
  videos: { value: 'videos', label: 'Videos' },
  documents: { value: 'documents', label: 'Documents' },
  presentations: { value: 'presentations', label: 'Presentations' },
  spreadsheets: { value: 'spreadsheets', label: 'Spreadsheets' },
  links: { value: 'links', label: 'Links' },
}

export const uploadAttachmentTabs = {
  photos: { value: 'photos', label: 'Images' },
  documents: { value: 'documents', label: 'Documents' },
  presentations: { value: 'presentations', label: 'Presentations' },
  spreadsheets: { value: 'spreadsheets', label: 'Spreadsheets' },
  videos: { value: 'videos', label: 'Videos' },
}

export const hiddenSearchPrefixes = {
  spam: { value: 'in spam', label: 'Spam' },
  trash: { value: 'in trash', label: 'Trash' },
}

export const draftTypes = {
  DEFAULT: 'DEFAULT',
  CLAIM_EMAIL: 'CLAIM_EMAIL',
}

export const attachmentTypes = {
  NORMAL_ATTACHMENT: 'normal',
  LARGE_ATTACHMENT: 'large',
}

export const siftDomainNames = {
  bill: 'bill',
  hotel: 'hotel',
  train: 'train',
  event: 'event',
  flight: 'flight',
  cruise: 'cruise',
  purchase: 'purchase',
  shipment: 'shipment',
  rentalcar: 'rentalcar',
  restaurant: 'restaurant',
  boardingpass: 'boardingpass',
}

export const domainPriority = [
  siftDomainNames.flight,
  siftDomainNames.boardingpass,
  siftDomainNames.hotel,
  siftDomainNames.rentalcar,
  siftDomainNames.train,
  siftDomainNames.cruise,
  siftDomainNames.restaurant,
  siftDomainNames.shipment,
  siftDomainNames.event,
  siftDomainNames.bill,
  siftDomainNames.purchase,
]

export const contactSiftTabs = {
  [siftDomainNames.bill]: contactDetailTabs.summary,
  [siftDomainNames.train]: contactDetailTabs.reservation,
  [siftDomainNames.hotel]: contactDetailTabs.reservation,
  [siftDomainNames.event]: contactDetailTabs.summary,
  [siftDomainNames.cruise]: contactDetailTabs.reservation,
  [siftDomainNames.flight]: contactDetailTabs.reservation,
  [siftDomainNames.purchase]: contactDetailTabs.summary,
  [siftDomainNames.rentalcar]: contactDetailTabs.reservation,
  [siftDomainNames.shipment]: contactDetailTabs.summary,
  [siftDomainNames.restaurant]: contactDetailTabs.reservation,
}

export const MAX_ATTACHMENT_TOTAL_SIZE = 25 * 1024 * 1024
export const MAX_LARGE_ATTACHMENT_SIZE = 10 * 1024 * 1024 * 1024
export const BLOCKED_UPLOAD_FILE_TYPES =
  '.ADE, .ADP, .APK, .BAT, .CHM, .CMD, .COM, .CPL, .DLL, .DMG, .EXE, .HTA, .INS, .ISP, .JAR, .JS, .JSE, .LIB, .LNK, .MDE, .MSC, .MSI, .MSP, .MST, .NSH, .PIF, .SCR, .SCT, .SHB, .SYS, .VB, .VBE, .VBS, .VXD, .WSC, .WSF, .WSH, .CAB,'

export const smartReplyFeedbackTypes = {
  GOOD_CASE: 'GOOD_CASE',
  BAD_CASE: 'BAD_CASE',
  SELECT_POS: 'SELECT_POS',
  SELECT_NEU: 'SELECT_NEU',
  SELECT_NEG: 'SELECT_NEG',
}

export const smartReplyTypes = {
  POS: 'pos',
  NEU: 'neu',
  NEG: 'neg',
}

export const NAVS = {
  account: {
    key: 'account',
    name: 'Account',
    value: 'account',
  },
  general: {
    key: 'general',
    name: 'General',
    value: 'general',
  },
  storage: {
    key: 'storage',
    name: 'Storage',
    value: 'storage',
  },
  domain: {
    key: 'domain',
    name: 'My Domain',
    value: 'domain',
  },
  split_inboxes: {
    key: 'split_inboxes',
    name: 'Split Inboxes',
    value: 'split_inboxes',
  },
  attachment_links: {
    key: 'attachment_links',
    name: 'Attachment Links',
    value: 'attachment_links',
  },
  filters: {
    key: 'filters',
    name: 'Filters',
    value: 'filters',
  },
  labels: {
    key: 'labels',
    name: 'Labels',
    value: 'labels',
  },
}

// Use one month as the default free trial duration
export const PREMIUM_FREE_TRIAL_DURATION = get(
  config,
  'freeTrialDuration',
  30 * 24 * 60 * 60
)

export const premiumPlanIntervals = {
  month: 'month',
  year: 'year',
}

export const premiumPlanName = {
  free: 'Free',
  personal: 'Personal',
  professional: 'Professional',
}

export const premiumPlanIds = {
  [premiumPlanIntervals.month]: {
    hobby: '0',
    freelance: '1',
    pro: '2',
    agency: '3',
  },
  [premiumPlanIntervals.year]: {
    hobby: '0',
    freelance: '4',
    pro: '5',
    agency: '6',
  },
}

export const premiumPlanRanks = {
  [premiumPlanIds.month.hobby]: 0,
  [premiumPlanIds.year.hobby]: 0,
  [premiumPlanIds.month.freelance]: 1,
  [premiumPlanIds.year.freelance]: 1,
  [premiumPlanIds.month.pro]: 2,
  [premiumPlanIds.year.pro]: 2,
}

export const premiumFreePlanId = premiumPlanIds.month.hobby
export const premiumAgencyPlanId = premiumPlanIds.month.agency

export const premiumCopyId = {
  [premiumPlanIds.month.hobby]: 'hobby',
  [premiumPlanIds.year.hobby]: 'hobby',
  [premiumPlanIds.month.freelance]: 'freelance',
  [premiumPlanIds.year.freelance]: 'freelance',
  [premiumPlanIds.month.pro]: 'pro',
  [premiumPlanIds.year.pro]: 'pro',
  [premiumPlanIds.month.agency]: 'agency',
  [premiumPlanIds.year.agency]: 'agency',
}

export const usernameRegex = /^[A-Z0-9._-]+$/i
export const domainRegex = /^[A-Z0-9.-]+$/i
export const usernameMinLength = 8
export const usernameMaxLength = 64
export const usernameMinLowerBound = 4

export const usernameTypes = {
  extraShort: 'EXTRA_SHORT',
  short: 'SHORT',
  regularPremium: 'REGULAR_PREMIUM',
  regular: 'REGULAR',
  invalid: 'INVALID',
  customDomain: 'CUSTOM_DOMAIN',
}

export const usernameLengths = {
  [usernameTypes.extraShort]: { min: 2, max: 3 },
  [usernameTypes.short]: { min: 4, max: 7 },
  [usernameTypes.regularPremium]: { min: 8, max: 64 },
  [usernameTypes.regular]: { min: 8, max: 64 },
  [usernameTypes.customDomain]: { min: 1, max: 64 },
}

export const usernameAllowedPlans = {
  [usernameTypes.extraShort]: [
    premiumPlanIds.month.pro,
    premiumPlanIds.month.agency,
    premiumPlanIds.year.pro,
    premiumPlanIds.year.agency,
  ],
  [usernameTypes.short]: [
    premiumPlanIds.month.pro,
    premiumPlanIds.month.agency,
    premiumPlanIds.year.pro,
    premiumPlanIds.year.agency,
  ],
  [usernameTypes.regularPremium]: [
    premiumPlanIds.year.agency,
    premiumPlanIds.month.agency,
    premiumPlanIds.year.pro,
    premiumPlanIds.month.pro,
    premiumPlanIds.year.freelance,
    premiumPlanIds.month.freelance,
  ],
  [usernameTypes.regular]: [
    premiumPlanIds.year.agency,
    premiumPlanIds.month.agency,
    premiumPlanIds.year.pro,
    premiumPlanIds.month.pro,
    premiumPlanIds.year.freelance,
    premiumPlanIds.month.freelance,
    premiumPlanIds.year.hobby,
    premiumPlanIds.month.hobby,
  ],
  [usernameTypes.customDomain]: [
    premiumPlanIds.year.agency,
    premiumPlanIds.month.agency,
    premiumPlanIds.year.pro,
    premiumPlanIds.month.pro,
    premiumPlanIds.year.freelance,
    premiumPlanIds.month.freelance,
  ],
  [usernameTypes.invalid]: [],
}

export const usernameRequireInvite = {
  [usernameTypes.extraShort]: true,
  [usernameTypes.short]: false,
  [usernameTypes.regularPremium]: false,
  [usernameTypes.regular]: true,
}

export const upgradePlanPromptTypes = {
  upgradeToFreelance: 'UPGRADE_TO_FREELANCE',
  getYourFreeDomain: 'GET_YOUR_FREE_DOMAIN',
  upgrade: 'UPGRADE',
}

export const firebaseConfig = get(config, 'firebaseConfig', null)

export const domainStatusMapping = {
  UNSET: 'UNSET',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
}

export const retrofitAccountFilter = {
  ALL: 'all',
  ONMAIL: 'onmail',
}

export const mockedRetrofitAccounts: { [key: string]: RetrofitAccount } = {
  [retrofitAccountFilter.ONMAIL]: {
    insertTime: 0,
    lastSyncTime: 0,
    name: 'Unknown',
    emailAddress: 'Unknown',
    ecUUID: retrofitAccountFilter.ONMAIL,
    status: connectionStatus.ACTIVE,
    labelUUID: labelNames.onmail,
    connectionType: connectionTypes.DEFAULT,
  },
}

export const imagePreviewSizeOptions = {
  //200 * 200
  small: 'small',
  //500 * 500
  medium: 'medium',
  //original
  full: 'full',
}

export const authStatus = {
  VALID: 200,
  INVALID: 401,
  NO_AUTH: 404,
  ERROR: 400,
}

export const COMPOSE_DEFAULT_HTML = '<div><br></div>'

export const inboxViews = {
  PREVIEW: 'preview',
  LIST: 'list',
}

export const encryptionOptions = {
  imap: [
    { label: 'Auto', value: 0 },
    { label: 'SSL', value: 1 },
    { label: 'None', value: 2 },
  ],
  smtp: [
    { label: 'Auto', value: 0 },
    { label: 'SSL', value: 1 },
    { label: 'STARTTLS', value: 2 },
    { label: 'None', value: 3 },
  ],
}

export const providerLogos = {
  [connectionTypes.DEFAULT.toString()]: i18next.t(
    'retrofit.provider.other.icon'
  ),
  [connectionTypes.GMAIL_OAUTH.toString()]: i18next.t(
    'retrofit.provider.google.icon'
  ),
  [connectionTypes.OUTLOOK_OAUTH.toString()]: i18next.t(
    'retrofit.provider.outlook.icon'
  ),
  [connectionTypes.GMAIL_IMAP.toString()]: i18next.t(
    'retrofit.provider.google.icon'
  ),
  [connectionTypes.ICLOUD_IMAP.toString()]: i18next.t(
    'retrofit.provider.icloud.icon'
  ),
  [connectionTypes.YAHOO_IMAP.toString()]: i18next.t(
    'retrofit.provider.yahoo.icon'
  ),
  [connectionTypes.AOL_IMAP.toString()]: i18next.t(
    'retrofit.provider.aol.icon'
  ),
}

export const providerMappings = {
  gmail: connectionTypes.GMAIL_IMAP,
  icloud: connectionTypes.ICLOUD_IMAP,
  yahoo: connectionTypes.YAHOO_IMAP,
  aol: connectionTypes.AOL_IMAP,
}

export type PaywallType = string
export const paywallTypes: { [string]: PaywallType } = {
  customFileTransferUrl: 'CUSTOM_FILE_TRANSFER_URL',
  fileStorage: 'FILE_STORAGE',
  passwordProtectedLargeFileLinks: 'PASSWORD_PROTECTED_LARGE_FILE_LINKS',
  customDomain: 'CUSTOM_DOMAIN',
  singleFileSizeLimit: 'SINGLE_FILE_SIZE_LIMIT',
  allowSubaccounts: 'ALLOW_SUBACCOUNTS',
  retrofit: 'RETROFIT',
  scheduledBreak: 'SCHEDULED_BREAK',
}

export const THREAD_LIST_BATCH_NUM = 50
export const THREAD_PREVIEW_BATCH_NUM = 25

export const FREE_DOMAIN_PRICE = '0.00'
export const FREE_FOR_PREMIUM_USERS = 'FREE'

export { default as routePaths } from './routes'

export type SignUpFlow = 'A' | 'B'
export const signupFlows: { [key: string]: SignUpFlow } = {
  A: 'A',
  B: 'B',
}

export const displayImageTipTypes = {
  normal: 'NORMAL',
  hidden: 'HIDDEN',
  enabled: 'ENABLED',
  noSender: 'NO_SENDER',
}
export const SUBACCOUNT_STATUS = {
  PENDING: 'DISABLED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  SOFT_DELETED: 'SOFT_DELETED',
  HARD_DELETED: 'HARD_DELETED',
  BANNED: 'BANNED',
}

export const DANGER_INLINE_ATTACHMENT_SIZE = 10 * 1024

export const RETROFIT_POPUP_ANCHOR = 'EdisonWebmail-retrofit-feature-anchor'

const rsvpDisplayYesText = i18next.t('calendar.event.rsvp.response.yes')
const rsvpDisplayNoText = i18next.t('calendar.event.rsvp.response.no')
const rsvpDisplayMaybeText = i18next.t('calendar.event.rsvp.response.maybe')
export const CALENDAR_EVENT_RSVP_STATUS = {
  accepted: {
    display: rsvpDisplayYesText,
    rfcValue: 'ACCEPTED',
  },
  declined: {
    display: rsvpDisplayNoText,
    rfcValue: 'DECLINED',
  },
  tentative: {
    display: rsvpDisplayMaybeText,
    rfcValue: 'TENTATIVE',
  },
  needsAction: {
    display: '',
    rfcValue: 'NEEDS-ACTION',
  },
}

export const SIGNATURE_IMAGE_MAX_NUMBER = 20

export const quickActionSettingMappings: { [key: string]: QuickActionType } = {
  '0': null,
  '1': quickActions.archive,
  '2': quickActions.trash,
}

export const attachmentsType = {
  file: 'file',
  image: 'image',
}

export const cardTypes = {
  suggestedBlock: 'SUGGESTED_BLOCK',
  senders: 'APPROVE_SENDER',
  nudges: 'EMAIL_NUDGE',
  cleanup: 'CLEANUP',
}

export const autoSelectEmail = 'DEFAULT'
