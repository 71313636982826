// @flow
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from 'common/modals'
import isNil from 'lodash/isNil'
import { modalTypes } from 'utils/constants'

import { useLabelValidator } from 'core/labels/hooks'
import { actions, selectors } from 'core/labels'
import { labelActions } from 'core/metadata/actions'
import useOnMailTheme from 'hooks/useOnMailTheme'
import * as analytics from 'core/analytics/actions'

import Dialog from '@material-ui/core/Dialog'
import LabelDetail from '@edison/webmail-ui/components/Labels/LabelDetail'

import { COLORS } from '@edison/webmail-ui/utils/constants'

import type { Dispatch } from 'types/redux'

const getCustomLabels = selectors.getCustomLabels()

const initialValue = {
  id: '',
  name: '',
  color: COLORS[20],
  isNew: true,
}

type Props = {
  isOpen: boolean,
  toggle: () => void,
  labelId?: string,
  name?: string,
  // Thread ID to apply the new label to
  applyTo?: string | $ReadOnlyArray<string>,
  // Callback when label actions have completed
  onActionComplete?: (data: any) => void,
}

export default ({
  isOpen,
  toggle,
  applyTo,
  labelId,
  name = '',
  onActionComplete,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const theme = useOnMailTheme()
  const labelValidator = useLabelValidator([name])
  const customLabels = useSelector(getCustomLabels)
  const { showModal } = useModal(modalTypes.labelPrompt)
  const { id, name: labelName, color, isNew } = useMemo(() => {
    const label = customLabels.find(item => item.id === labelId)
    if (isNil(label))
      return {
        ...initialValue,
        name,
      }
    else
      return {
        ...label,
        isNew: false,
      }
  })

  function handleSubmit(value) {
    toggle()
    const { name, color } = value

    const action = isNew
      ? actions.createLabel(name, color)
      : actions.updateLabel(id, { name, color })

    return dispatch(action).then(res => {
      // Only apply the new label to thread when create successfully
      if (!isNil(res) && !!applyTo && isNew) {
        const promises = []
        promises.push(
          dispatch(analytics.threadActions.userThreadBatchAction('addLabel'))
        )
        const affectedThreads: $ReadOnlyArray<string> = Array.isArray(applyTo)
          ? applyTo
          : [applyTo]
        promises.push(
          dispatch(labelActions.update([res.id], []).threads(affectedThreads))
        )
        Promise.all(promises).then(data => {
          if (onActionComplete) {
            onActionComplete(data)
          }
        })
      }
    })
  }

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <LabelDetail
        theme={theme}
        label={{ id, name: labelName, color, isNew }}
        validate={values => {
          let errors = {}
          const colorError = labelValidator.color(values.color)
          const nameError = labelValidator.name(values.name)
          if (!!colorError) {
            errors.color = colorError
          }
          if (!!nameError) {
            errors.name = nameError
          }
          return errors
        }}
        onSubmit={handleSubmit}
        onDelete={() => showModal({ labelId: id, name: labelName })}
        onClose={toggle}
      />
    </Dialog>
  )
}
