// @flow
import { useEffect } from 'react'
import { useParams, matchPath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import isNil from 'lodash/isNil'
import get from 'lodash/get'

import { toastVariants } from 'common/toasts'
import { useQueryParams } from 'common/url'
import { routePaths } from 'utils/constants'
import { useDomain } from 'core/custom-domains/hooks'
import { showNotification } from 'core/toasts/actions'
import { logout, logoutAll } from 'core/auth/actions'

import type { Dispatch } from 'types/redux'

const View = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const { next = '' } = useQueryParams()
  const { userId } = useParams()
  const { isInOnmail, onmailDomain } = useDomain()

  useEffect(() => {
    if (isNil(userId)) {
      dispatch(logoutAll()).then(() => {
        dispatch(showNotification(t('auth.logout'), toastVariants.success))
        window.location.replace(routePaths.login)
      })
    } else {
      // Referer used to redirect back after logout success
      let referer, orderId, url
      if (!isNil(next)) {
        try {
          const nextUrl = new URL(decodeURIComponent(next))
          const match = matchPath(nextUrl.pathname, {
            path: routePaths.AUTH_PREFIX,
          })
          orderId = get(match, 'params.userId')
          url = nextUrl.href
        } catch (e) {}

        if (!isInOnmail) {
          const nextUrl = new URL(window.location.href)
          nextUrl.host = `mail.${onmailDomain}`

          url = nextUrl.href
        }
      }

      if (url && orderId) {
        referer = {
          url,
          params: {
            orderId,
          },
        }
      }

      // Logout of single session and switch to the next session
      dispatch(logout(userId, referer)).then(() => {
        dispatch(showNotification(t('auth.logout'), toastVariants.success))
      })
    }
  }, [])

  return null
}

export default View
