// @flow
import React from 'react'
import get from 'lodash/get'
import { Redirect, useParams, generatePath } from 'react-router-dom'
import { connectionTypes } from '@edison/webmail-core/utils/constants'

import { useOrderId } from 'core/auth/hooks'
import { useIMAPForm } from 'core/retrofit/hooks'
import { routePaths, providerMappings } from 'utils/constants'

import {
  GmailASPForm,
  ICloudASPForm,
  YahooASPForm,
  AolASPForm,
} from '@edison/webmail-ui/components/Retrofit/IMAPASPForm'

import type { ConnectionType } from '@edison/webmail-core/utils/constants'

const forms: { [key: ConnectionType]: any } = {
  [connectionTypes.GMAIL_IMAP.toString()]: GmailASPForm,
  [connectionTypes.ICLOUD_IMAP.toString()]: ICloudASPForm,
  [connectionTypes.YAHOO_IMAP.toString()]: YahooASPForm,
  [connectionTypes.AOL_IMAP.toString()]: AolASPForm,
}

const View = () => {
  const userId = useOrderId()
  const { provider } = useParams()
  const { onCreate, isCreating } = useIMAPForm()

  const fallbackUrl = generatePath(routePaths.retrofitAdd, { userId })

  const connectionType = get(providerMappings, provider)
  const ProviderForm = !!connectionType ? forms[connectionType] : null

  function handleOnSubmit(values) {
    return onCreate(values, isNaN(connectionType) ? undefined : connectionType)
  }

  if (!ProviderForm) {
    return <Redirect to={fallbackUrl} />
  }

  return <ProviderForm isLoading={isCreating} onSubmit={handleOnSubmit} />
}

View.displayName = 'IMAPProviderForm'

export default View
