// @flow
export const remoteConfigKeys = {
  smsEnabledCountryCodes: 'sms_enabled_country_codes',
}

export const remoteConfigDefaultValues = {
  [remoteConfigKeys.smsEnabledCountryCodes]: ['US'],
}

export const onboardingEvents = {
  welcome: {
    view: 'onbrdWelcomeScrView',
    loginSelected: 'onbrdWelcomeLoginSelected',
    signUpSelected: 'onbrdWelcomeSignUpSelected',
  },
  chooseAddress: {
    view: 'onbrdChooseAddressScrView',
    inputSelected: 'onbrdChooseAddressScrInputSelected',
    continueSelected: 'onbrdChooseAddressScrContinueSelected',
  },
  recoveryEmail: {
    view: 'onbrdRecoveryEmailScrView',
    input1Selected: 'onbrdRecoveryEmailScrInput1Selected',
    input2Selected: 'onbrdRecoveryEmailScrInput2Selected',
    sendCodeSelected: 'onbrdRecoveryEmailScrSendCodeSelected',
  },
  verifyEmail: {
    view: 'onbrdVerifyEmailScrView',
    inputSelected: 'onbrdVerifyEmailScrInputSelected',
    sendCodeSelected: 'onbrdVerifyEmailScrVerifySelected',
  },
  setPassword: {
    view: 'onbrdCreatePwdScrView',
    input1Selected: 'onbrdCreatePwdScrInput1Selected',
    input2Selected: 'onbrdCreatePwdScrInput2Selected',
    continueSelected: 'onbrdCreatePwdScrContinueSelected',
  },
  setUserName: {
    view: 'onbrdAddNameScrView',
    firstNameSelected: 'onbrdAddNameScrFirstNameSelected',
    lastNameSelected: 'onbrdAddNameScrLastNameSelected',
    acceptTermsSelected: 'onbrdAddNameScrAcceptTermsSelected',
    continueSelected: 'onbrdAddNameScrContinueSelected',
  },
  recoveryPhone: {
    view: 'onbrdRecoveryPhoneScrView',
    inputSelected: 'onbrdRecoveryPhoneScrInputSelected',
    sendCodeSelected: 'onbrdRecoveryPhoneScrSendCodeSelected',
  },
  verifyPhone: {
    view: 'onbrdVerifyPhoneScrView',
    inputSelected: 'onbrdVerifyPhoneScrInputSelected',
    sendCodeSelected: 'onbrdVerifyPhoneScrVerifySelected',
  },
  dataPolicy: {
    view: 'onbrdDataPolicyScrView',
    acceptSelected: 'onbrdDataPolicyScrAcceptSelected',
    declineSelected: 'onbrdDataPolicyScrDeclineSelected',
  },
  subscription: {
    view: 'onbrdSubscriptionScrView',
    freeSelected: 'onbrdSubscriptionScrFreeSelected',
    personalSelected: 'onbrdSubscriptionScrPersonalSelected',
    professionalSelected: 'onbrdSubscriptionScrProfessionalSelected',
  },
  completed: {
    view: 'onbrdCompleted',
  },
}
