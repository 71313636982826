// @flow
import get from 'lodash/get'

import type { Features } from '@edison/webmail-core/types/premium.js'

export function generateFeatureFlags(features: $Shape<Features>) {
  const getFeature = (key, fallback) => get(features, key, fallback)

  return {
    get allowSubaccounts(): boolean {
      return getFeature('allowSubaccounts', false)
    },
    get allowCustomDomains(): boolean {
      return getFeature('customDomain', false)
    },
    get allowLargeAttachmentsPassword(): boolean {
      return getFeature('passwordProtectedLargeFileLinks', false)
    },
    get allowBrandedFileTransfer(): boolean {
      return getFeature('brandedFileTransferPage', false)
    },
    get allowCustomFileTransferUrl(): boolean {
      return getFeature('customFileTransferUrl', false)
    },
    get totalStorageLimit(): number {
      return getFeature('fileStorage', 10000 * 1024 * 1024)
    },
    get singleFileSizeLimit(): number {
      return getFeature('singleFileSizeLimit', 100 * 1024 * 1024)
    },
    get allowRetrofit(): boolean {
      return getFeature('allowImportAccounts', false)
    },
    get allowScheduledBreak(): boolean {
      return getFeature('allowScheduledBreak', false)
    },
    get totalImportAccountsLimit(): number {
      return getFeature('importAccountsLimit', 3)
    },
  }
}
