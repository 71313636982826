// @flow
import React from 'react'
import {
  TextAction,
  InputAction,
} from '@edison/webmail-ui/components/Filter/ActionItem'
import LabelAction from './LabelAction'
import ForwardAction from './ForwardAction'

import type { Action } from '@edison/webmail-ui/components/Filter/ActionItem'

export const registryActions = {
  text: (item: Action) => (
    <TextAction
      name={item.name}
      value={item.value}
      label={item.content || item.label}
    />
  ),
  input: (item: Action) => (
    <InputAction name={item.name} value={item.value} label={item.label} />
  ),
  label: (item: Action) => (
    <LabelAction
      name={item.name}
      value={item.value}
      label={item.label}
      options={item.options}
      placeholder={item.placeholder}
    />
  ),
  forward: (item: Action) => (
    <ForwardAction
      name={item.name}
      value={item.value}
      placeholder={item.placeholder}
    />
  ),
}
