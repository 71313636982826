// @flow
import React from 'react'
import { useDispatch } from 'react-redux'
import { fields } from '@edison/webmail-ui/screens/Settings/fields'

import { useUserTutorial } from 'core/flags/hooks'
import { userTutorialFlags } from 'core/flags/constants'

import { patchSettings } from 'core/settings/actions'

import ContactFirstActionDialog from '@edison/webmail-ui/components/ContactFirstActionDialog'

import type { Dispatch } from 'types/redux'
import type { ActionType } from '@edison/webmail-ui/components/ContactFirstActionDialog'

type Props = {
  isOpen: boolean,
  type: ActionType,
  onClose: () => void,
}

export default ({ isOpen, type, onClose }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const tutorial = useUserTutorial()

  const handleOnSetAutoAccept = () => {
    dispatch(
      patchSettings([
        { key: fields.general.autoAcceptNewContacts, value: true },
      ])
    )
  }

  const handleOnClose = (checked: boolean) => {
    if (checked) {
      handleOnSetAutoAccept()
    }
    onClose()

    tutorial.setFlag(userTutorialFlags.contactAction, false)
  }

  return (
    <ContactFirstActionDialog
      isOpen={isOpen}
      type={type}
      onClose={handleOnClose}
    />
  )
}
