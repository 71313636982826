// @flow

/**
 * Flag that decides to show the onboarding carousel
 */
export const newUserKey = 'edison-webmail-new-user'
/**
 * Flag that decides to hide the retrofit feature popup or not.
 */
export const hideRetrofitPopupKey = 'edison-webmail-hide-retrofit-popup'
/**
 * Flag that decides to hide the calendar feature popup or not.
 */
export const hideCalendarPopupKey = 'edison-webmail-hide-calendar-popup'
/**
 * Flag that stores whether user set thread detail contact drawer to open
 * Possible values: 1,0,undefined
 */
export const threadDetailContactDrawerOpenKey =
  'edison-webmail-thread-detail-contact-drawer-open'

/**
 * Flag that decides to show the pro tips the first time users connect a 3rd-party account
 */
export const newRetrofitUserKey = 'edison-webmail-new-retrofit-user'

/**
 * Flag that decides to show confirmation when users are trying to block sender
 */
export const blockSenderPromptKey = 'block-sender-prompt'

/**
 * Flag that shows how many tutorials are there and which is done
 */
export const userTutorialKey = 'edison-webmail-user-tutorial-key'

export const localStorageFlags = {
  newUserKey,
  hideRetrofitPopupKey,
  hideCalendarPopupKey,
  threadDetailContactDrawerOpenKey,
  newRetrofitUserKey,
  blockSenderPromptKey,
  userTutorialKey,
}

export type UserTutorialFlag =
  | 'contactTooltip'
  | 'contactAction'
  | 'quickAction'

export const userTutorialFlags: { [key: string]: UserTutorialFlag } = {
  /**
   * Tutorial for the tooltip that shows the contact approve/block actions
   */
  contactTooltip: 'contactTooltip',
  /**
   * Tutorial for the success popup of the first approve/block action
   */
  contactAction: 'contactAction',
  /**
   * Tutorial for the tooltip that guide users to setup quick action
   */
  quickAction: 'quickAction',
}

export const userTutorialOrder = [
  userTutorialFlags.contactTooltip,
  userTutorialFlags.contactAction,
  userTutorialFlags.quickAction,
]
