// @flow
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import union from 'lodash/union'

import type { SearchRecommendations } from '@edison/webmail-core/api/search'
import type { Suggestion, Term } from '@edison/webmail-core/types/search'
import type { PaginationState } from 'types/redux'
import {
  defaultPaginationState,
  searchPrefixes,
  attachmentsType,
} from 'utils/constants'
import { createReducer } from 'utils/redux'
import * as metadataActions from '../metadata/actions'
import { setFilterAccountAction } from 'core/retrofit/actions'
import type { SetRetrofitAccount } from 'core/retrofit/types'
import type { MessageBatchDelete, MessageDelete } from '../metadata/types'
import * as actions from './actions'
import { initalCondition } from './helpers'
import type {
  ResetSearchState,
  SearchActions,
  SearchBlur,
  SearchFailure,
  SearchFocus,
  SearchHintsFailure,
  SearchHintsRequest,
  SearchHintsSelect,
  SearchHintsSuccess,
  SearchRecommendationsSuccess,
  SearchRequest,
  SearchSetCondition,
  SearchSetPrefix,
  SearchSetQuery,
  SearchSetSelectContactQuery,
  SearchSetThreadIds,
  SearchSuccess,
  SetSelectLabel,
} from './types'

type Action = SearchActions

export type State = {|
  query: string,
  threadIds: PaginationState,
  terms: $ReadOnlyArray<Term>,
  suggestions: $ReadOnlyArray<Suggestion>,
  requestIds: { results: ?string, hints: ?string },
  // Used to hide suggestions after a user has selected a suggestion
  hideSuggestions: boolean,
  // Used to keep track of when should recommendations be shown to the user
  hideRecommendations: boolean,
  // Used to keep track of when should the highlights be shown to the user
  hideHighlights: boolean,
  recommendations: SearchRecommendations,
  // Prefix used to make querying easier
  prefix: {
    key: string,
    value: string,
    label: string,
  },
  // Should be spam/trash/null
  // Spam - Listing the spam threads
  // Trash - Listing the trash threads
  // Null - Listing the normal threads
  prioritizedLabel: string,
  moreInFlags: {
    [labelName: string]: boolean,
  },
  highlightValues: {
    [key: string]: $ReadOnlyArray<string>,
  },
  hideSearchRecommend: boolean,
  condition: Condition,
  selectContactQuery: $ReadOnlyArray<string>,
  selectedLabels: $ReadOnlyArray<string>,
  recentViewThreadIds: $ReadOnlyArray<string>,
  focusCount: number,
|}

const initialState: State = {
  query: '',
  terms: [],
  suggestions: [],
  threadIds: defaultPaginationState,
  requestIds: {
    results: null,
    hints: null,
  },
  hideSuggestions: false,
  hideRecommendations: false,
  hideHighlights: true,
  hideSearchRecommend: true,
  recommendations: {
    contacts: [],
    files: [],
    images: [],
  },
  prefix: searchPrefixes.emails,
  prioritizedLabel: '',
  moreInFlags: {},
  highlightValues: {},
  condition: initalCondition,
  selectContactQuery: [],
  selectedLabels: [],
  recentViewThreadIds: [],
  focusCount: 0,
}

const reducer = createReducer<State, Action>(initialState, {
  [actions.setQuery.toString()]: setQuery,
  [actions.fetchSearchActions.success.toString()]: searchSuccess,
  [actions.fetchSearchActions.request.toString()]: searchRequest,
  [actions.fetchSearchActions.failure.toString()]: searchFailure,
  [actions.fetchSearchHintsActions.success.toString()]: searchHintsSuccess,
  [actions.fetchSearchHintsActions.request.toString()]: searchHintsRequest,
  [actions.fetchSearchHintsActions.failure.toString()]: searchHintsFailure,
  [actions.selectSearchHint.toString()]: selectSearchHint,
  [actions.fetchSearchRecommendationsActions.success.toString()]: searchRecommendationsSuccess,
  [actions.setPrefix.toString()]: setPrefix,
  [actions.blur.toString()]: hideSuggestions,
  [actions.focus.toString()]: showSearchResult,
  [actions.resetSearchState.toString()]: resetSearchState,
  [metadataActions.deleteMessage.toString()]: messageDelete,
  [metadataActions.batchDeleteThreadsAction.toString()]: messageBatchDelete,
  [actions.setSearchThreadIds.toString()]: recentViewEmails,
  [actions.setCondition.toString()]: setCondition,
  [actions.setSelectContactQuery.toString()]: setSelectContactQuery,
  [actions.setSelectedLabels.toString()]: setSelectedLabels,
  [setFilterAccountAction.toString()]: setFilterAccount,
})

function setFilterAccount(state: State, action: SetRetrofitAccount) {
  const { labelUUID } = action.payload || {}
  return {
    ...state,
    condition: {
      ...state.condition,
      accountLabel: labelUUID ? [labelUUID] : [],
    },
  }
}

function showSearchResult(state: State, action: SearchFocus) {
  const { focusCount } = state
  const isAutoFoucs = focusCount === 0
  return {
    ...state,
    focusCount: focusCount + 1,
    hideSearchRecommend: isAutoFoucs,
  }
}

function setSelectedLabels(state: State, action: SetSelectLabel) {
  const { name, id, isAdd = true } = action.payload
  let nextSelectedLabels = []
  if (isAdd) {
    const ids = state.selectedLabels.map(item => item.id)
    nextSelectedLabels = ids.includes(id)
      ? [...state.selectedLabels]
      : [...state.selectedLabels, { name, id }]
  } else {
    nextSelectedLabels = [...state.selectedLabels].filter(
      item => item.id !== id
    )
  }
  return {
    ...state,
    selectedLabels: nextSelectedLabels,
  }
}

function setSelectContactQuery(
  state: State,
  action: SearchSetSelectContactQuery
) {
  const { contact, isAdd = true } = action.payload
  let nextContactQuery = []
  if (isAdd) {
    const emails = state.selectContactQuery.map(item => item.email)
    nextContactQuery = emails.includes(contact.email)
      ? [...state.selectContactQuery]
      : [...state.selectContactQuery, contact]
  } else {
    nextContactQuery = [...state.selectContactQuery].filter(
      item => item.email !== contact.email
    )
  }
  return {
    ...state,
    selectContactQuery: nextContactQuery,
  }
}

function setCondition(state: State, action: SearchSetCondition) {
  const { condition } = action.payload
  return {
    ...state,
    condition: {
      ...state.condition,
      ...condition,
    },
  }
}

function setQuery(state: State, action: SearchSetQuery) {
  const { query } = action.payload
  const { prefix } = state
  const prefixLength = prefix.value ? prefix.value.length + 1 : 0
  let nextState = {
    ...state,
    query,
    // Get rid of terms and suggestions when backspacing
    // Temporary change, the entire list will be refreshed when API call
    // successfully returns
    //
    // EWM-2044 Need to reset the terms while the query is longer than before
    terms:
      query.length < state.query.length
        ? state.terms.filter(({ start }) => start < query.length + prefixLength)
        : [],
    // Reset request IDs when changed
    requestIds:
      query.trim() !== state.query.trim()
        ? { results: null, hints: null }
        : state.requestIds,
    // Hide suggestions if we are backspacing
    hideSuggestions: query.length < state.query.length,
    hideRecommendations: query.trim().length > 0,
    hideHighlights: true,
  }

  return nextState
}

function searchRequest(state: State, action: SearchRequest) {
  const requestId = get(action.meta, 'request.id', null)
  const { pageToken } = action.payload

  return {
    ...state,
    requestIds: {
      ...state.requestIds,
      results: requestId,
    },
    // Reset the following keys if it's a brand new search query
    threadIds: isNil(pageToken) ? initialState.threadIds : state.threadIds,
    prioritizedLabel: isNil(pageToken) ? '' : state.prioritizedLabel,
    moreInFlags: isNil(pageToken) ? {} : state.moreInFlags,
    highlightValues: {},
  }
}

export function recentViewEmails(state: State, action: SearchSetThreadIds) {
  const { ids } = action.payload
  return {
    ...state,
    threadIds: {
      ...state.threadIds,
      ids: [],
      total: 0,
    },
    recentViewThreadIds: ids,
  }
}

function searchSuccess(state: State, action: SearchSuccess) {
  const {
    ids: threadIds,
    resultSizeEstimate,
    nextPageToken,
    prioritizedLabel,
    moreInFlags,
    highlightValues,
    isInbox,
    isExistQuery,
  } = action.payload
  const requestId = get(action.meta, 'request.id')
  const currPageToken: string = get(action.meta, 'request.pageToken')

  if (isInbox && !isExistQuery) {
    return {
      ...state,
      threadIds: {
        ...state.threadIds,
        ids: state.recentViewThreadIds,
        total: state.recentViewThreadIds.length,
        next: null,
      },
      requestIds: { ...state.requestIds, results: null },
      prioritizedLabel: '',
      moreInFlags: {},
      highlightValues: {},
    }
  }

  // Skip if there is a different request
  if (requestId !== state.requestIds.results) {
    return state
  }

  const ids =
    (currPageToken ? union(state.threadIds.ids, threadIds) : threadIds) || []
  const nextPrioritizedLabel = currPageToken
    ? state.prioritizedLabel
    : prioritizedLabel
  const nextMoreInFlags = currPageToken ? state.moreInFlags : moreInFlags
  return {
    ...state,
    threadIds: {
      ...state.threadIds,
      ids,
      total: resultSizeEstimate,
      next:
        !isNil(nextPageToken) && nextPageToken.length > 0
          ? nextPageToken
          : null,
    },
    hideRecommendations: ids.length > 0,
    requestIds: {
      ...state.requestIds,
      results: null,
    },
    prioritizedLabel: nextPrioritizedLabel,
    moreInFlags: nextMoreInFlags,
    highlightValues,
  }
}

function searchFailure(state: State, action: SearchFailure) {
  const requestId = get(action.meta, 'request.id', null)

  if (requestId !== state.requestIds.results) {
    return state
  }

  return {
    ...state,
    requestIds: {
      ...state.requestIds,
      results: null,
    },
    hideRecommendations: false,
  }
}

function searchHintsRequest(state: State, action: SearchHintsRequest) {
  const requestId = get(action.meta, 'request.id', null)

  return {
    ...state,
    requestIds: {
      ...state.requestIds,
      hints: requestId,
    },
    hideSuggestions: true,
  }
}

function searchHintsSuccess(state: State, action: SearchHintsSuccess) {
  const { suggestions, terms } = action.payload
  const requestId = get(action.meta, 'request.id')

  // Clear if query is empty
  if (state.query.length === 0) {
    return {
      ...state,
      threadIds: defaultPaginationState,
      requestIds: { ...state.requestIds, hints: null },
    }
  }

  // Skip if there is a different request
  if (requestId !== state.requestIds.hints) {
    return state
  }

  return {
    ...state,
    suggestions,
    terms,
    requestIds: { ...state.requestIds, hints: null },
    hideSuggestions: false,
    hideHighlights: false,
  }
}

function searchHintsFailure(state: State, action: SearchHintsFailure) {
  const requestId = get(action.meta, 'request.id', null)

  if (requestId !== state.requestIds.hints) {
    return state
  }

  return {
    ...state,
    // Reset the hints related fields
    terms: [],
    suggestions: [],
    requestIds: {
      ...state.requestIds,
      hints: null,
    },
  }
}

function selectSearchHint(state: State, action: SearchHintsSelect) {
  const { query } = action.payload
  return {
    ...state,
    hideSuggestions: true,
    query,
    // EWM-2044 Need to reset the terms while query is longer than before
    terms: [],
  }
}

function searchRecommendationsSuccess(
  state: State,
  action: SearchRecommendationsSuccess
) {
  const { recommendations } = action.payload

  return {
    ...state,
    recommendations: {
      ...recommendations,
      files: recommendations.files.map(item => ({
        ...item,
        attachmentsType: attachmentsType.file,
      })),
      images: recommendations.images.map(item => ({
        ...item,
        attachmentsType: attachmentsType.image,
      })),
    },
  }
}

function setPrefix(state: State, action: SearchSetPrefix) {
  const { key, value, label } = action.payload
  return {
    ...state,
    hideHighlights: true,
    hideSuggestions: true,
    prefix: { key, value, label },
  }
}

function hideSuggestions(state: State, action: SearchBlur) {
  return {
    ...state,
    hideHighlights: false,
    hideSuggestions: true,
    hideSearchRecommend: true,
  }
}

function resetSearchState(state: State, action: ResetSearchState) {
  const { currentActiveAccount } = action.payload
  if (!currentActiveAccount.length) return initialState
  return {
    ...initialState,
    condition: {
      ...initalCondition,
      accountLabel: currentActiveAccount.map(item => item.labelUUID),
    },
  }
}

function messageDelete(state: State, action: MessageDelete) {
  const { threadId } = action.payload
  const { threadIds } = state

  // No threads to remove
  if (threadIds.ids.length === 0) return state

  const { ids, removed } = threadIds.ids.reduce((prev, curr) => {
    let { ids = [], removed = [] } = prev
    if (curr === threadId) {
      removed.push(curr)
    } else {
      ids.push(curr)
    }

    return { ids, removed }
  }, {})

  const total = Math.max(0, threadIds.total - removed.length)

  return {
    ...state,
    hideRecommendations: total > 0,
    threadIds: {
      ...threadIds,
      ids,
      total,
    },
  }
}

function messageBatchDelete(state: State, action: MessageBatchDelete) {
  const { threads } = action.payload
  const { threadIds } = state

  // No threads to remove
  if (threadIds.ids.length === 0) return state

  const toRemoved = new Set(threads.map(({ threadId }) => threadId))

  const { ids, removed } = threadIds.ids.reduce((prev, curr) => {
    let { ids = [], removed = [] } = prev

    if (toRemoved.has(curr)) {
      removed.push(curr)
    } else {
      ids.push(curr)
    }

    return { ids, removed }
  }, {})

  const total = Math.max(0, threadIds.total - removed.length)

  return {
    ...state,
    hideRecommendations: total > 0,
    threadIds: {
      ...threadIds,
      ids,
      total,
    },
  }
}

export default reducer
