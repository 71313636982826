// @flow
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import cn from 'classnames'

import Button, { sizes } from '@edison/webmail-ui/components/Button'

import { suggestedBlockStatus } from 'utils/constants'
import { showBlockPrompt, feedbackSuggestedBlock } from 'core/contacts/actions'
import {
  getSuggestedBlockById,
  isFeedbackSuggestedBlockLoading,
  isContactUpdateLoading,
} from 'core/contacts/selectors'

import type { Dispatch } from 'types/redux'

const contactUpdateLoadingSelector = isContactUpdateLoading()

export default ({ contactId }: { contactId: string }) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const isUpdating = useSelector(contactUpdateLoadingSelector)
  const isFeedbackLoading = useSelector(isFeedbackSuggestedBlockLoading)

  const suggestedBlockSelector = useMemo(
    () => getSuggestedBlockById(contactId),
    [contactId]
  )
  const contact = useSelector(suggestedBlockSelector)

  const buttonDisabled = isFeedbackLoading || isUpdating

  if (isNil(contact)) return null

  function handleOnIgnore() {
    dispatch(feedbackSuggestedBlock(contact.id, suggestedBlockStatus.dismissed))
  }

  function handleOnBlock() {
    dispatch(showBlockPrompt(contact.email, true))
  }

  return (
    <div className="sticky bottom-0 w-full p-4 bg-app">
      <Button
        className={cn(
          '!text-gray-light',
          'w-56 mr-4',
          { 'opacity-75 cursor-default': buttonDisabled },
          sizes.md
        )}
        color="secondary"
        disabled={buttonDisabled}
        onClick={handleOnIgnore}
      >
        {t('button.ignore')}
      </Button>
      <Button
        className={'w-56'}
        disabled={buttonDisabled}
        onClick={handleOnBlock}
      >
        {t('button.block')}
      </Button>
    </div>
  )
}
