// @flow
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'

import ConvertForm, {
  ConvertConfirm,
} from '@edison/webmail-ui/screens/Settings/components/CustomDomain/ConvertEmail'
import * as auth from 'core/auth'
import * as customDomains from 'core/custom-domains'
import * as premium from 'core/premium'
import { useDomain } from 'core/custom-domains/hooks'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ isOpen, toggle }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const { onmailDomain } = useDomain()
  const { onAddAccount } = auth.hooks.useSession()
  const authState = useSelector(auth.selectors.getAuth())
  const isAddOnmailAliasLoading = useSelector(
    customDomains.selectors.addOnmailAliasLoading
  )
  const isUnsubscribeLoading = useSelector(
    premium.selectors.getUnsubscribeLoading
  )
  const isLoading = isAddOnmailAliasLoading || isUnsubscribeLoading

  const onCreate = async (accountName, formikBag) => {
    const res = await dispatch(
      customDomains.actions.addOnmailAlias(accountName)
    )
    const { isSuccess, message } = res
    if (isSuccess) {
      await dispatch(premium.actions.unsubscribe())
      setEmail(`${accountName}@${onmailDomain}`)
      setSuccess(true)
    } else {
      formikBag.setFieldError('email', message)
    }
  }

  const onConfirm = () => {
    // Redirect to `/login/add-account` instead of
    // logging out of all the accounts
    //
    // https://easilydo.atlassian.net/browse/EWM-2215
    onAddAccount()
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      {!success ? (
        <ConvertForm
          title={t('settings.customDomain.convertToOnMail.form.title')}
          description={t(
            'settings.customDomain.convertToOnMail.form.description'
          )}
          warning={t('settings.customDomain.convertEmail.form.warning')}
          domain={onmailDomain}
          isLoading={isLoading}
          onClose={toggle}
          // $FlowFixMe
          onSubmit={onCreate}
        />
      ) : (
        <ConvertConfirm
          customEmail={email}
          originalEmail={get(authState, 'user')}
          onConfirm={onConfirm}
        />
      )}
    </Dialog>
  )
}

View.displayName = 'AddOnmailAlias'

export default View
