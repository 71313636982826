// @flow
import React from 'react'
import get from 'lodash/get'
import toPairs from 'lodash/toPairs'
import fromPairs from 'lodash/fromPairs'

import AuthAvatar, { DomainAvatar } from 'common/AuthAvatar'

import type { Contact } from '@edison/webmail-core/types/contacts'
import { retrofitAccountFilter } from 'utils/constants'

export function getFormattedContact(contact: Contact) {
  const { emails, firstName, isDomain, lastName, avatar: avatarUrl, ...rest } =
    contact || {}
  const email = get(emails, '[0].email', '')
  const [, domain] = email.split('@')
  const name = [firstName, lastName]
    .map(each => (each || '').trim())
    .filter(Boolean)
    .join(' ')

  const avatar = isDomain ? (
    <DomainAvatar />
  ) : (
    <AuthAvatar src={avatarUrl} alt={name || email} className="!w-10 !h-10" />
  )

  return {
    ...rest,
    isDomain,
    name,
    email,
    avatar,
    domain,
  }
}

export function getFormattedContactById(
  byId: { [key: string]: Contact },
  id: string
) {
  return getFormattedContact(byId[id])
}

export function updateEmailsByEntities(entities, emails) {
  const next = []
  for (const [, contact] of toPairs(entities)) {
    next.push(contact)
  }
  const newEmails = fromPairs(
    next.flatMap(each => each.emails.map(email => [email.email, each.id]))
  )
  return newEmails
}

export function isOnmailAccount(activeAccount) {
  return activeAccount.ecUUID === retrofitAccountFilter.ONMAIL
}
