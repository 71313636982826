// @flow
import React, { useState } from 'react'

import { RetrofitSyncSuccess } from '@edison/webmail-ui/components/Banner'
import { useRetrofitSyncProgress } from 'core/retrofit/hooks'

const SyncSuccessBanner = (props: { [key: string]: any }) => {
  const [isLoading, setLoading] = useState(false)
  const { newAccounts, dismissBanner } = useRetrofitSyncProgress()

  return (
    <RetrofitSyncSuccess
      {...props}
      isLoading={isLoading}
      accounts={newAccounts.map(({ emailAddress }) => emailAddress)}
      onClose={async () => {
        setLoading(true)
        await dismissBanner(newAccounts.map(({ ecUUID }) => ecUUID))
        setLoading(false)
      }}
    />
  )
}

export default SyncSuccessBanner
