// @flow
import toPairs from 'lodash/toPairs'
import unionBy from 'lodash/unionBy'
import concat from 'lodash/concat'
import uniqBy from 'lodash/uniqBy'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getActivedAccounts, getActiveAccount } from 'core/retrofit/selectors'

import {
  getThreadMessageIds,
  getThreadPendingMessageIds,
  getThreadNotDraftMessageIds,
  getMessageLabelIds,
  getMessagesState as getMetadatMessagesState,
} from 'core/metadata/selectors'
import {
  getDefaultSenderEmailSelector,
  isEnableDisplayExternalImagesSelector,
} from 'core/settings/selectors'
import { getDraft } from 'core/compose/selectors'
import { getSearchKeywords } from 'core/search/selectors'
import { getLoadingStatus } from 'core/loading/selectors'

import { getContactByEmail, getContacts } from 'core/contacts/selectors'
import {
  labelNames,
  displayImageTipTypes,
  contactTypes,
  BASE_DOMAIN,
  onmailConfig,
} from 'utils/constants'

import { getAllAliasesSelector } from 'core/custom-domains/selectors'
import { convertHtmlToText, checkCurrentUser, isStringEqual } from 'utils'
import { formatSnippet, isMessageContainKeywords } from 'utils/htmlProcess'
import type { Recipient, Message } from '@edison/webmail-core/types/messages'

import type { State as MessageState } from './types'
import type { State, Selector } from 'types/state'
import { getThreadMessageIdsByLabels } from '../metadata/selectors'
import { getConnectedAccounts } from '../retrofit/selectors'
import { isHTMLContainExternalImages } from '@edison/webmail-ui/utils/DOMSanitize'
import { autoSelectEmail } from '../../utils/constants'
import { getAuth } from 'core/auth/selectors'

export const selectMessages = (state: State) => state.messages

export function getMessageState(): Selector<MessageState> {
  return createSelector(selectMessages, (state: MessageState) => state)
}

export function getMessages(): Selector<MessageState> {
  return createSelector(selectMessages, (state: MessageState) =>
    Object.values(state)
  )
}
export function getMessage(messageId: string): Selector<Message> {
  return createSelector(
    selectMessages,
    (state: MessageState) => state[messageId]
  )
}

export function getThreadMessages(threadId: string): Selector<Array<Message>> {
  return createSelector(
    getMessageState(),
    getThreadMessageIds(threadId),
    (messageState, messageIds) => {
      return messageIds.map(item => messageState[item]).filter(Boolean)
    }
  )
}

export function getThreadPendingMessages(
  threadId: string
): Selector<$ReadOnlyArray<Message>> {
  return createSelector(
    getMessageState(),
    getThreadPendingMessageIds(threadId),
    (messageState, pendingMessageIds) => {
      return pendingMessageIds.map(item => messageState[item]).filter(Boolean)
    }
  )
}

export function getThreadNotDraftMessages(
  threadId: string
): Selector<Array<Message>> {
  return createSelector(
    getMessageState(),
    getThreadNotDraftMessageIds(threadId),
    (messageState, messageIds) => {
      return messageIds.map(item => messageState[item]).filter(Boolean)
    }
  )
}

export function getRecipients(
  threadId: string
): Selector<$ReadOnlyArray<Recipient>> {
  return createSelector(
    state => state,
    getMessageState(),
    getThreadMessageIds(threadId),

    (
      state: State,
      messageState: MessageState,
      messageIds: Array<string> | void
    ) =>
      uniqBy(
        unionBy(
          ...messageIds.reduce((prev, curr) => {
            const message = messageState[curr]
            if (isNil(message)) {
              return prev
            } else {
              return [
                ...prev,
                [message.from, ...message.to, ...message.cc, ...message.bcc],
              ]
            }
          }, []),
          'email'
        ).map(each => {
          const contact = getContactByEmail(each.email)(state)
          if (isNil(contact)) return each
          else return { name: contact.name, email: contact.email }
        }),
        'email'
      )
  )
}

export function isMessagesLoading(): Selector<boolean> {
  return getLoadingStatus('MESSAGE_LIST')
}

export function getLastestLoadedMessageIdByThreadId(
  threadId: string,
  options
): Selector<?Message> {
  return createSelector(
    getThreadMessageIdsByLabels(threadId, options),
    getThreadLoadedMessageIds(threadId),
    (messageIds: Array<string>, loadedMessageIds: Array<string>) => {
      return [...messageIds]
        .reverse()
        .find(item => loadedMessageIds.includes(item))
    }
  )
}

const getReplyRecipients = (aliases, message: Message, accounts) => {
  const { from, to, cc, bcc, labelIds, replyTo } = message
  const { email: replyToEmail, name: replyToName } = replyTo || {}
  const replyToArr = replyToEmail || replyToName ? [replyTo] : []
  if (!from || !from.email) {
    return {
      to,
      cc,
      bcc,
      replyTo: replyToArr,
    }
  }
  //imported message should not be handled(EWM-2459)
  if (checkCurrentUser(aliases, from.email)) {
    const account = accounts.find(
      account => labelIds && labelIds.includes(account.labelUUID)
    )

    if (
      !account ||
      (account && isStringEqual(account.emailAddress, from.email))
    ) {
      return {
        to,
        cc,
        bcc,
        replyTo: replyToArr,
      }
    } else {
      return {
        to: [from],
        cc: to
          .concat(cc)
          .filter(({ email }) => !checkCurrentUser(aliases, email)),
        bcc,
        replyTo: replyToArr,
      }
    }
  } else {
    return {
      to: [from],
      cc: to
        .concat(cc)
        .filter(({ email }) => !checkCurrentUser(aliases, email)),
      bcc,
      replyTo: replyToArr,
    }
  }
}

export function getReplyAllRecipientsByMessageId(
  messageId: string
): Selector<{
  to: $ReadOnlyArray<Recipient>,
  cc: $ReadOnlyArray<Recipient>,
  bcc: $ReadOnlyArray<Recipient>,
}> {
  return createSelector(
    getAllAliasesSelector,
    getMessage(messageId),
    getConnectedAccounts,
    (aliases, message, accounts) => {
      if (isNil(message)) return { to: [], cc: [], bcc: [] }

      const { to, cc, replyTo } = getReplyRecipients(aliases, message, accounts)
      return {
        to,
        cc,
        bcc: [],
        replyTo,
      }
    }
  )
}

export function isShowReplyAll(messageId: string): Selector<boolean> {
  return createSelector(
    getAllAliasesSelector,
    getMessage(messageId),
    (aliases, message) => {
      if (isNil(message)) return false
      const { from, to, cc } = message
      return (
        concat([from], to, cc)
          .map(item => item.email)
          .filter(email => !checkCurrentUser(aliases, email)).length > 1
      )
    }
  )
}
export function isShowSpam(messageId: string): Selector<boolean> {
  return createSelector(
    getMessageLabelIds(messageId),
    labelIds => !labelIds.includes(labelNames.spam)
  )
}

export function isShowTrash(messageId: string): Selector<boolean> {
  return createSelector(
    getMessageLabelIds(messageId),
    labelIds => !labelIds.includes(labelNames.trash)
  )
}

const validLabelIds = [labelNames.inbox, labelNames.archive, labelNames.unread]
const smartReplyBlackList = get(onmailConfig, 'smaryReplyBlacklist', [])
const isInSmartReplyBlackList = email => {
  const [username, domain] = email.split('@')
  return (
    smartReplyBlackList.some(item => isStringEqual(username, item)) &&
    isStringEqual(BASE_DOMAIN, domain)
  )
}
export function isShowSmartReply(messageId: string): Selector<boolean> {
  return createSelector(
    getAllAliasesSelector,
    getMessageLabelIds(messageId),
    getMessage(messageId),
    (aliases, labelIds, message) => {
      if (!labelIds.some(labelId => validLabelIds.includes(labelId))) {
        return false
      }

      if (!message) return false

      if (
        message.to &&
        message.to.every(item => !checkCurrentUser(aliases, item.email))
      )
        return false

      if (!message.from || !message.from.email) return false
      // if message is from user, pass it
      if (
        checkCurrentUser(aliases, message.from.email) ||
        isInSmartReplyBlackList(message.from.email)
      )
        return false

      const { html, subject } = message
      let emptyHTML = !html
      if (!emptyHTML) {
        const parser = new DOMParser()

        const doc = parser.parseFromString(html, 'text/html')
        if (!doc.querySelectorAll('img').length && !doc.body.innerText.trim()) {
          emptyHTML = true
        } else {
          const table = doc.querySelector('table')
          //check the mesage is a conversation email
          if (table && table.className !== 'webmail_upload_view') {
            return false
          }
        }
      }
      if (emptyHTML && !subject.trim()) return false

      return true
    }
  )
}

export function getReplyRecipientsByMessageId(
  messageId: string
): Selector<{
  to: $ReadOnlyArray<Recipient>,
  cc: $ReadOnlyArray<Recipient>,
  bcc: $ReadOnlyArray<Recipient>,
}> {
  return createSelector(
    getAllAliasesSelector,
    getMessage(messageId),
    getConnectedAccounts,
    (aliases, message, accounts) => {
      if (isNil(message)) {
        return { to: [], cc: [], bcc: [], replyTo: [] }
      }

      const { to, replyTo } = getReplyRecipients(aliases, message, accounts)
      return {
        replyTo,
        to,
        cc: [],
        bcc: [],
      }
    }
  )
}

export function getMessageSnippet(messageId: string): Selector<string> {
  return createSelector(getMessageState(), messageState => {
    const {
      inReplyTo,
      text,
      html,
      snippet: messageSnippet,
      largeAttachments,
    } = messageState[messageId]
    const snippet =
      messageSnippet || (text || convertHtmlToText(html)).slice(200)

    const replyMessage = inReplyTo
      ? Object.values(messageState).find(item => item.messageId === inReplyTo)
      : null
    return formatSnippet(snippet, {
      replyMessage,
      largeAttachments,
    })
  })
}

export function getThreadLoadedMessageIds(
  threadId: string
): Selector<Array<string>> {
  return createSelector(
    getThreadMessageIds(threadId),
    getMessageState(),
    (messageIds, messageState) => {
      return messageIds.filter(
        item => messageState[item] && messageState[item].loaded
      )
    }
  )
}

export function getThreadLoadedNotDraftMessageIds(
  threadId: string
): Selector<Array<string>> {
  return createSelector(
    getThreadNotDraftMessageIds(threadId),
    getMessageState(),
    (messageIds, messageState) => {
      return messageIds.filter(
        item => messageState[item] && messageState[item].loaded
      )
    }
  )
}

export function getThreadUnloadMessages(threadId: string) {
  return createSelector(
    getThreadMessageIds(threadId),
    getMessageState(),
    (messageIds, messageState) => {
      return messageIds
        .filter(item => messageState[item] && !messageState[item].loaded)
        .map(item => messageState[item])
    }
  )
}

export function getThreadContainDraftMessageIds(
  threadId: string,
  labels: any = {}
) {
  return createSelector(
    getThreadMessageIdsByLabels(threadId, {
      ...labels,
      [labelNames.drafts]: true,
    }),
    getThreadMessages(threadId),
    getMessageState(),
    (messageIds, messages, messageState) => {
      return messageIds
        .map(item =>
          messages.find(
            message =>
              messageState[item] &&
              message.messageId === messageState[item].inReplyTo
          )
        )
        .filter(Boolean)
        .map(message => message.id)
    }
  )
}

export function getInReplyDraftIds(
  threadId: string
): Selector<{ [messageId: string]: Message }> {
  return createSelector(
    getMessageState(),
    getMetadatMessagesState(),
    getThreadMessageIds(threadId),
    (messages, metadataMessages, ids) => {
      const draftIds = ids.filter(id =>
        get(metadataMessages, `${id}.labelIds`, []).includes(labelNames.drafts)
      )
      const messageHeaderIdMapping = ids.reduce((prev, curr) => {
        const message = messages[curr]
        if (isNil(message)) return prev
        else
          return {
            ...prev,
            [message.messageId]: message.id,
          }
      }, {})
      const draftReplyMapping = draftIds.reduce((prev, curr) => {
        const message = messages[curr]
        if (isNil(message) || !message.inReplyTo) return prev
        else
          return {
            ...prev,
            [message.id]: message.inReplyTo,
          }
      }, {})

      return toPairs(draftReplyMapping)
        .map(([draftId, inReplyTo]) => {
          const messageId = messageHeaderIdMapping[inReplyTo]
          return [messageId, draftId]
        })
        .filter(([messageId]) => Boolean(messageId))
        .reduce(
          (prev, [messageId, draftId]) => ({
            ...prev,
            [messageId]: draftId,
          }),
          {}
        )
    }
  )
}

export function isThreadLoaded(
  threadId: string,
  isFilterPending = true
): Selector<boolean> {
  return createSelector(
    getThreadMessageIds(threadId, isFilterPending),
    getMessageState(),
    (messageIds, messageState) => {
      return messageIds.some(
        item => messageState[item] && messageState[item].loaded
      )
    }
  )
}

export function isMessageLoaded(messageId: string): Selector<boolean> {
  return createSelector(
    getMessage(messageId),
    message => !!message && !!message.loaded
  )
}

export function getUnloadedSentMessageIds(
  threadId: string
): Selector<Array<string>> {
  return createSelector(
    getThreadMessageIds(threadId),
    getMetadatMessagesState(),
    getMessageState(),
    (messageIds, metaDataMessageState, messageState) => {
      return messageIds.filter(
        item =>
          (!messageState[item] || !messageState[item].loaded) &&
          metaDataMessageState[item].labelIds.includes(labelNames.sent)
      )
    }
  )
}

export function isEnableThreadAction(threadId: string): Selector<boolean> {
  return createSelector(
    isThreadLoaded(threadId),
    getLoadingStatus('MESSAGE_UPDATE'),
    getLoadingStatus('THREAD_UPDATE'),
    (loadedThread, loadingMessageUpate, loadingThreadUpdate) => {
      return loadedThread && !loadingMessageUpate && !loadingThreadUpdate
    }
  )
}

export function isEnableMessageAction(messageId: string): Selector<boolean> {
  return createSelector(
    getDraft(messageId),
    getMessage(messageId),
    getMessageState(),
    (draft, message, messages) => {
      if (draft) {
        const { messageId } = draft
        if (messages[messageId]?.sending) return false
      }

      if (message?.sending) return false

      return true
    }
  )
}

export function isAllThreadMessageLoaded(threadId: string): Selector<boolean> {
  return createSelector(
    getThreadMessageIds(threadId),
    getMessageState(),
    (messageIds, messageState) => {
      return messageIds.every(
        item => messageState[item] && messageState[item].loaded
      )
    }
  )
}

export function getNewMessageToastMessages(threadId): Selector<boolean> {
  return createSelector(
    getThreadLoadedMessageIds(threadId),
    getThreadUnloadMessages(threadId),
    getLoadingStatus('BATCH_GET'),
    getAllAliasesSelector,
    (loadedMessageIds, unloadedMessages, loadingStatus, aliases) => {
      if (loadingStatus) return []
      if (!loadedMessageIds.length) return []
      return unloadedMessages.filter(
        item => !checkCurrentUser(aliases, item.from.email)
      )
    }
  )
}

export function getSearchHighlightMessageIds(
  threadId
): Selector<Array<string>> {
  return createSelector(
    getThreadMessages(threadId),
    getSearchKeywords('body'),
    (messages, keywords) => {
      if (!keywords.length) return []

      return messages
        .filter(message => isMessageContainKeywords(message, keywords))
        .map(message => message.id)
    }
  )
}

const EMAIL_WHILTELIST = get(onmailConfig, 'imageTipWhitelist', [])
const isInWhitelist = email => {
  const [username, domain] = email.split('@')
  return (
    EMAIL_WHILTELIST.some(item => isStringEqual(username, item)) &&
    isStringEqual(BASE_DOMAIN, domain)
  )
}

export function isDisplayShowImageTip(messageId): Selector<boolean> {
  return createSelector(
    getContacts(),
    isEnableDisplayExternalImagesSelector,
    getMessage(messageId),
    getMessageLabelIds(messageId),
    getAllAliasesSelector,
    (contacts, enableDisplayExternalImages, message, labelIds, aliases) => {
      const { from, html, largeAttachments } = message
      const fromEmail = from?.email ?? ''
      if (enableDisplayExternalImages) return displayImageTipTypes.hidden

      if (!isHTMLContainExternalImages(html, !!largeAttachments.length)) {
        return displayImageTipTypes.hidden
      }
      if (!fromEmail) {
        return displayImageTipTypes.noSender
      }
      if (checkCurrentUser(aliases, fromEmail))
        return displayImageTipTypes.hidden
      if (isInWhitelist(fromEmail)) return displayImageTipTypes.hidden

      if (labelIds.includes(labelNames.spam)) return displayImageTipTypes.hidden

      const contact = contacts.find(contact =>
        contact.emails.find(item => isStringEqual(item.email, fromEmail))
      )
      if (!contact) {
        return displayImageTipTypes.noSender
      }

      if (contact.status === contactTypes.PENDING) {
        return displayImageTipTypes.noSender
      }

      return contact.enableShowImage
        ? displayImageTipTypes.enabled
        : displayImageTipTypes.normal
    }
  )
}

export function isSpamMessage(messageId): Selector<boolean> {
  return createSelector(getMessageLabelIds(messageId), labelIds => {
    return labelIds.includes(labelNames.spam)
  })
}

export function isDangerMessage(messageId): Selector<boolean> {
  return createSelector(getMessageLabelIds(messageId), labelIds =>
    labelIds.some(labelId =>
      [labelNames.trash, labelNames.spam].includes(labelId)
    )
  )
}

export function getDefaultSendEmail(messageId): Selector<string> {
  return createSelector(
    getMessageLabelIds(messageId),
    getActivedAccounts,
    getDefaultSenderEmailSelector,
    (labelIds, accounts, defaultSenderEmail) => {
      const account = accounts.find(item => labelIds.includes(item.labelUUID))
      return account ? account.emailAddress : defaultSenderEmail
    }
  )
}

export function getReplyDefaultSendEmail(messageId) {
  return createSelector(
    getDefaultSendEmail(messageId),
    getActiveAccount,
    getAuth(),
    (email, activeAccount, auth) => {
      if (email !== autoSelectEmail) {
        return email
      }
      return activeAccount ? activeAccount?.emailAddress : auth.user
    }
  )
}

export function getMessageDefaultRecipient(
  messageId: string
): Selector<?{ email: string, name: string }> {
  return createSelector(
    getAllAliasesSelector,
    getMessage(messageId),
    getMessageLabelIds(messageId),
    (aliases, message, labelIds) => {
      let senders = []
      let res = undefined
      if (message) {
        const { from, to, cc, bcc } = message
        if (
          labelIds.includes(labelNames.drafts) ||
          labelIds.includes(labelNames.sent)
        ) {
          senders = [...to, ...cc, ...bcc]
        } else {
          senders = [from]
        }
      }

      for (let sender of senders) {
        res = sender
        if (res && !checkCurrentUser(aliases, res.email)) break
      }

      return res
    }
  )
}

export function getSendingThreadMessageIds(threadId, isFilterPending = true) {
  return createSelector(
    getThreadMessageIds(threadId, isFilterPending),
    getMessageState(),
    (messageIds, messages) => {
      return messageIds.filter(item => messages[item] && messages[item].sending)
    }
  )
}
