// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import cn from 'classnames'
import { contactIdTypes, contactDetailTabs } from 'utils/constants'

import { getAuth } from 'core/auth/selectors'
import {
  getBlockedContacts,
  isContactStatusUpdating,
  getContactsIndexedByEmail,
} from 'core/contacts/selectors'
import { showBlockPrompt, showUnblockSenderPrompt } from 'core/contacts/actions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ContactDetail from '@edison/webmail-ui/components/ContactDetail'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import Emails from './components/Emails'
import Photos from './components/Photos'
import Files from './components/Files'
import Sift from './components/Sift'

import type { Dispatch } from 'types/redux'

const authSelector = getAuth()
const contactsSelector = getContactsIndexedByEmail()
const contactStatusSelector = isContactStatusUpdating()
const blockedContactSelector = getBlockedContacts()

const registry = {
  [contactDetailTabs.summary]: {
    label: 'SUMMARY',
    Component: Sift,
  },
  [contactDetailTabs.reservation]: {
    label: 'RESERVATION',
    Component: Sift,
  },
  [contactDetailTabs.emails]: {
    label: 'Emails',
    Component: Emails,
  },
  [contactDetailTabs.photos]: {
    label: 'Photos',
    Component: Photos,
  },
  [contactDetailTabs.files]: {
    label: 'Files',
    Component: Files,
  },
}

const defaultTabs = [
  contactDetailTabs.emails,
  contactDetailTabs.photos,
  contactDetailTabs.files,
]

type Props = {
  name: string,
  email: string,
  idType?: string,
  onClose?: () => void,
  enableBlockAction?: boolean,
  tabs?: $ReadOnlyArray<string>,
  onSelectThread: (id: string) => void,
  selectedThread: string,
}

export default ({
  name,
  email,
  enableBlockAction = false,
  onClose,
  onSelectThread,
  selectedThread,
  tabs = defaultTabs,
  idType = contactIdTypes.EMAIL,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const auth = useSelector(authSelector)
  const blockedContacts = useSelector(blockedContactSelector)
  const contacts = useSelector(contactsSelector)
  const isUpdating = useSelector(contactStatusSelector)
  let contactName = get(contacts[email], 'name')
  if (!contactName) {
    if (!!name) {
      contactName = name
    } else {
      contactName = email.split('@')[0]
    }
  }

  const isBlocked = blockedContacts
    .flatMap(contact => contact.emails.map(item => item.email))
    .includes(email)
  function handleBlock() {
    if (isBlocked) {
      dispatch(showUnblockSenderPrompt(email))
    } else {
      dispatch(showBlockPrompt(email))
    }
  }

  const enableToClose = !isNil(onClose)
  const showBlockAction =
    enableBlockAction && get(auth, 'user') !== email && Boolean(contacts[email])

  const tabProps = {
    selectedEmail: selectedThread,
    onSelectEmail: onSelectThread,
  }
  return (
    <ContactDetail
      header={
        <div className="mb-4">
          <div className="px-4 py-2 -mb-4 text-right text-gray-light">
            <IconButton
              className={cn('cursor-pointer', {
                'opacity-0': !enableToClose,
              })}
              disabled={!enableToClose}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="flex items-center pr-4">
            <div>
              <AuthEmailAvatar className="mx-6" email={email} name={name} />
            </div>
            <div className="min-w-0">
              <div className="flex items-center">
                <span className="font-semibold text-gray-dark truncate">
                  {contactName}
                </span>
                {isBlocked && (
                  <span className="px-2 py-1 ml-2 text-xs font-semibold rounded text-gray-medium bg-secondary">
                    Blocked
                  </span>
                )}
              </div>
              <div className="text-sm font-light">{email}</div>
              {showBlockAction && (
                <button
                  className={cn('block text-primary text-xs font-semibold', {
                    'opacity-75 cursor-default': isUpdating,
                  })}
                  disabled={isUpdating}
                  onClick={handleBlock}
                >
                  {isBlocked ? 'Unblock' : 'Block Sender'}
                </button>
              )}
            </div>
          </div>
        </div>
      }
      tabs={tabs
        .map((tab, index) => {
          const currenTab = registry[tab]
          if (!!currenTab) {
            const { label, Component } = currenTab
            return {
              label,
              node: <Component id={email} idType={idType} {...tabProps} />,
            }
          }
          return null
        })
        .filter(Boolean)}
    />
  )
}
