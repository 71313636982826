// @flow
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import get from 'lodash/get'
import qs from 'qs'

import { getOnmailDomain } from 'core/custom-domains/selectors'

import DeleteAccountSuccess from '@edison/webmail-ui/components/DeleteAccountSuccess'

export default () => {
  const history = useHistory()
  const location = useLocation()
  const onmailDomain = useSelector(getOnmailDomain)

  const homepage = `//${onmailDomain}`

  useEffect(() => {
    const params = qs.parse(location.search.slice(1))
    if (get(params, 'deleted') === '1') {
      history.replace({
        ...location,
        search: '',
      })
    } else {
      window.location.replace(homepage)
    }
  }, [])

  return (
    <DeleteAccountSuccess onClick={() => window.location.replace(homepage)} />
  )
}
