// @flow
import React from 'react'

import Usage from './Usage'

const NavigationFooter = () => {
  const items = [
    {
      key: 'usage',
      node: <Usage />,
      condition: true,
    },
  ]
  const toRender = items.find(item => item.condition)

  return toRender ? toRender.node : null
}

export default NavigationFooter
