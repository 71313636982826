// @flow
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { getAuthUser } from 'core/auth/selectors'

import Popover from '@material-ui/core/Popover'
import MessageDetailCard, {
  Email,
  Trigger,
} from '@edison/webmail-ui/components/MessageDetailCard'

import { makeStyles } from '@material-ui/core/styles'
import { useDebouncedCallback } from 'use-debounce'

import type { Message } from '@edison/webmail-core/types/messages'

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 0,
    boxShadow:
      'rgba(0, 0, 0, 0.035) 0px 0px 20px, rgba(0, 0, 0, 0.035) 0px 0px 20px',
  },
}))

const MessageEmail = ({ name, email, onSelectRecipient, ...otherProps }) => {
  const authUser = useSelector(getAuthUser)
  const [onRecipientHoverIn, cancelDebounce] = useDebouncedCallback(
    onSelectRecipient,
    600
  )

  return (
    <Email
      {...otherProps}
      name={name}
      email={email}
      onMouseEnter={
        authUser === email ? null : () => onRecipientHoverIn({ name, email })
      }
      onMouseLeave={cancelDebounce}
    />
  )
}

type Props = {
  message: Message,
  onSelectRecipient: ({ name: string, email: string }) => void,
}

export default ({ message, onSelectRecipient }: Props) => {
  const styles = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { from, to, cc, bcc, replyTo, date, rrbFlag, sendVia } = message
  const { email: replyToEmail, name: replyToName } = replyTo || {}
  return (
    <span>
      <Trigger
        onClick={e => {
          e.stopPropagation()
          setAnchorEl(e.target)
        }}
      />
      <Popover
        classes={styles}
        open={!!anchorEl}
        anchorEl={anchorEl}
        PaperProps={{
          className: 'shadow-hover dark:shadow-lg',
        }}
        onClose={e => {
          e.stopPropagation()
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MessageDetailCard
          from={
            <MessageEmail
              via={sendVia}
              name={from.name}
              email={from.email}
              onSelectRecipient={onSelectRecipient}
            />
          }
          replyTo={
            replyToEmail || replyToName ? (
              <MessageEmail
                name={replyToName}
                email={replyToEmail}
                onSelectRecipient={onSelectRecipient}
              />
            ) : null
          }
          to={to.map(({ name, email }, index) => (
            <MessageEmail
              key={`to-${index}`}
              name={name}
              email={email}
              onSelectRecipient={onSelectRecipient}
            />
          ))}
          cc={cc.map(({ name, email }, index) => (
            <MessageEmail
              key={`cc-${index}`}
              name={name}
              email={email}
              onSelectRecipient={onSelectRecipient}
            />
          ))}
          bcc={bcc.map(({ name, email }, index) => (
            <MessageEmail
              key={`bcc-${index}`}
              name={name}
              email={email}
              onSelectRecipient={onSelectRecipient}
            />
          ))}
          date={date}
          readReceiptBlocking={rrbFlag}
        />
      </Popover>
    </span>
  )
}
