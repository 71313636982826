// @flow
import i18next from 'i18next'
import trim from 'lodash/trim'
import * as client from '@edison/webmail-core/api/labels'

import { showNotification } from 'core/toasts/actions'
import { fetchLabelStatistics } from 'core/metadata/actions'
import { getAuth } from 'core/auth/selectors'

import { COLORS } from '@edison/webmail-ui/utils/constants'
import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'

import type {
  LabelListRequest,
  LabelListSuccess,
  LabelListFailure,
  LabelCreateRequest,
  LabelCreateSuccess,
  LabelCreateFailure,
  LabelUpdateRequest,
  LabelUpdateSuccess,
  LabelUpdateFailure,
  LabelDeleteRequest,
  LabelDeleteSuccess,
  LabelDeleteFailure,
  TemporaryRetrofitLabelCreate,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchLabelsActions: {
  request: ActionCreator<LabelListRequest>,
  success: ActionCreator<LabelListSuccess>,
  failure: ActionCreator<LabelListFailure>,
} = {
  request: createAction('LABEL_LIST_REQUEST'),
  success: createAction('LABEL_LIST_SUCCESS'),
  failure: createAction('LABEL_LIST_FAILURE'),
}

/**
 * Return the list of labels
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchLabels(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchLabelsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchLabelsActions.request())
      const res = await client.list({ auth })
      await dispatch(fetchLabelStatistics())
      dispatch(fetchLabelsActions.success(res.result))
    } catch (e) {
      dispatch(fetchLabelsActions.failure({ message: e.message }))
    }
  }
}

export const createLabelActions: {
  request: ActionCreator<LabelCreateRequest>,
  success: ActionCreator<LabelCreateSuccess>,
  failure: ActionCreator<LabelCreateFailure>,
} = {
  request: createAction('LABEL_CREATE_REQUEST'),
  success: createAction('LABEL_CREATE_SUCCESS'),
  failure: createAction('LABEL_CREATE_FAILURE'),
}

/**
 * Create a label
 *
 * @public
 * @param {string} name - Name for the new label
 * @param {string} color - Color for the new label
 * @returns {ThunkAction}
 */
export function createLabel(
  name: string,
  color: string = COLORS[0]
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(createLabelActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(createLabelActions.request())
      const formattedName = name.replace(/\s+/g, ' ').trim()
      const res = await client.create({ name: formattedName, color }, { auth })
      dispatch(createLabelActions.success({ label: res.result }))
      return res.result
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.label.error.create'),
          toastVariants.error
        )
      )
      dispatch(createLabelActions.failure({ message: e.message }))
    }
  }
}

export const updateLabelActions: {
  request: ActionCreator<LabelUpdateRequest>,
  success: ActionCreator<LabelUpdateSuccess>,
  failure: ActionCreator<LabelUpdateFailure>,
} = {
  request: createAction('LABEL_UPDATE_REQUEST'),
  success: createAction('LABEL_UPDATE_SUCCESS'),
  failure: createAction('LABEL_UPDATE_FAILURE'),
}

/**
 * Update an existed label
 *
 * @public
 * @param {string} labelId - Uniq ID for label
 * @param {string} name - Name for label
 * @param {string} color - Color for label
 * @returns {ThunkAction}
 */
export function updateLabel(
  labelId: string,
  { name, color }: { name: string, color: string }
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(updateLabelActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(
        updateLabelActions.request({
          label: {
            id: labelId,
            name: name,
            color: color,
          },
        })
      )
      const res = await client.update(
        labelId,
        { name: trim(name), color },
        { auth }
      )
      dispatch(updateLabelActions.success({ label: res.result }))
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.label.error.update'),
          toastVariants.error
        )
      )
      dispatch(updateLabelActions.failure({ message: e.message }))
    }
  }
}

export const deleteLabelActions: {
  request: ActionCreator<LabelDeleteRequest>,
  success: ActionCreator<LabelDeleteSuccess>,
  failure: ActionCreator<LabelDeleteFailure>,
} = {
  request: createAction('LABEL_DELETE_REQUEST'),
  success: createAction('LABEL_DELETE_SUCCESS'),
  failure: createAction('LABEL_DELETE_FAILURE'),
}

export function deleteLabel(labelId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(deleteLabelActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(deleteLabelActions.request({ id: labelId }))
      await client.remove(labelId, { auth })
      dispatch(deleteLabelActions.success({ id: labelId }))
    } catch (e) {
      dispatch(deleteLabelActions.failure({ message: e.message }))
    }
  }
}

export const createTemporaryRetrofitLabel: ActionCreator<TemporaryRetrofitLabelCreate> = createAction(
  'TEMPORARY_RETROFIT_LABEL_CREATE'
)
