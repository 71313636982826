// @flow
import reduce from 'lodash/reduce'
import * as actions from './actions'
import { formatAttachment, formatLargeAttachment } from 'utils'
import {
  fetchThreadsActions,
  batchGetThreadsActions,
} from 'core/threads/actions'

import { metadataHistory } from 'core/metadata/actions'
import {
  saveDraftActions,
  delaySendDraftActions,
  undoSendDraftActions,
} from 'core/compose/actions'

import { createReducer } from 'utils/redux'
import { smartReplyFeedbackTypes } from 'utils/constants'
import type {
  State,
  MessageListSuccess,
  SmartReplySuccess,
  MessageActions,
  BatchGetSuccess,
  FeedbackSmartReplySuccess,
  UpdateMessage,
} from './types'
import type {
  ThreadListSuccess,
  ThreadBatchGetSuccess,
} from 'core/threads/types'
import type {
  UndoSendDraftSuccess,
  DelaySendDraftSuccess,
  SaveDraftSuccess,
} from 'core/compose/types'
import type { MetadataHistory } from 'core/metadata/types'

import moment from 'moment'

const initialState: State = {}

export default createReducer<State, MessageActions>(initialState, {
  [actions.fetchThreadActions.success.toString()]: fetchThread,
  [actions.fetchSmartReplyActions.success.toString()]: fetchSmartReply,
  [actions.batchGetActions.success.toString()]: batchGetMessages,
  [actions.feedbackSmartReplyActions.success.toString()]: feedbackSmartReply,
  [actions.updateMessage.toString()]: updateMessage,

  [batchGetThreadsActions.success.toString()]: storeMessagesFromThreads,
  [fetchThreadsActions.success.toString()]: storeMessagesFromThreads,

  [saveDraftActions.success.toString()]: saveDraft,
  [delaySendDraftActions.success.toString()]: delaySendDraft,
  [undoSendDraftActions.success.toString()]: undoSendDraft,
  [metadataHistory.toString()]: fetchHistory,
})

function updateMessage(state: State, action: UpdateMessage) {
  const { id, ...rest } = action.payload
  if (!state[id]) return state
  return {
    ...state,
    [id]: {
      ...state[id],
      ...rest,
    },
  }
}
function undoSendDraft(state: State, action: UndoSendDraftSuccess) {
  const { messageId } = action.payload
  if (!state[messageId]) return state
  return {
    ...state,
    [messageId]: {
      ...state[messageId],
      sending: false,
    },
  }
}

function fetchHistory(state: State, action: MetadataHistory) {
  const { sentMessageIds } = action.payload
  return {
    ...state,
    ...reduce(
      sentMessageIds,
      (result, messageId) => {
        if (state[messageId]) {
          return {
            ...result,
            [messageId]: {
              ...state[messageId],
              sending: false,
            },
          }
        } else return state
      },
      {}
    ),
  }
}

function delaySendDraft(state: State, action: DelaySendDraftSuccess) {
  const { messageId, html } = action.payload
  return {
    ...state,
    [messageId]: {
      ...state[messageId],
      html,
      sending: true,
      //The Date maybe not accurate
      date: moment().unix(),
    },
  }
}

function saveDraft(state: State, action: SaveDraftSuccess) {
  const {
    snippet,

    draft: { subject, html, composeId, to, cc, bcc },
    from,
    messageId,
    date,
    largeAttachments,
    attachments,
    messageHeaderId,
    references,
    inReplyTo,
  } = action.payload

  return {
    ...state,
    [messageId]: {
      id: messageId,
      to,
      cc,
      bcc,
      date,
      from,
      html,
      snippet,
      attachments,
      largeAttachments,
      subject,
      messageId: messageHeaderId,
      references,
      inReplyTo,
      composeId,
      loaded: true,
    },
  }
}
function fetchThread(state: State, action: MessageListSuccess) {
  const { messages } = action.payload
  const entities = messages.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: { ...curr, loaded: true } }),
    {}
  )

  return {
    ...state,
    ...entities,
  }
}

function batchGetMessages(state: State, action: BatchGetSuccess) {
  const { messages } = action.payload
  const entities = messages.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: { ...curr, loaded: true } }),
    {}
  )
  return {
    ...state,
    ...entities,
  }
}

function fetchSmartReply(state: State, action: SmartReplySuccess) {
  const { messageId, smartReply } = action.payload
  const message = state[messageId]
  if (!message) return state
  return {
    ...state,
    [messageId]: {
      ...message,
      smartReply,
    },
  }
}

function storeMessagesFromThreads(
  state: State,
  action: ThreadBatchGetSuccess | ThreadListSuccess
) {
  const { threads } = action.payload
  const messages = threads
    .reduce((prev, curr) => [...prev, ...curr.messages], [])
    .reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: {
          ...state[curr.id],
          ...curr,
          html: state[curr.id] ? state[curr.id].html : '',
          text: state[curr.id] ? state[curr.id].text : '',
          loaded: state[curr.id] ? state[curr.id].loaded : false,
          attachments: curr.attachments.map(formatAttachment),
          largeAttachments: curr.largeAttachments.map(formatLargeAttachment),
        },
      }),
      {}
    )
  return {
    ...state,
    ...messages,
  }
}

function feedbackSmartReply(state: State, action: FeedbackSmartReplySuccess) {
  const { messageId, feedbackType } = action.payload
  if (
    [
      smartReplyFeedbackTypes.GOOD_CASE,
      smartReplyFeedbackTypes.BAD_CASE,
    ].includes(feedbackType) &&
    state[messageId]
  ) {
    return {
      ...state,
      [messageId]: {
        ...state[messageId],
        smartReply: { ...state[messageId].smartReply, feedbacked: true },
      },
    }
  }
  return state
}
