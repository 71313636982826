// @flow
import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import type { State as LoadingState } from './reducers'
import type { State, Selector } from 'types/state'

export const selectLoadingState = (state: State) => state.loading

export function getLoadingStatus(
  id: string,
  defaultStatus?: boolean = false
): Selector<boolean> {
  return createSelector(
    selectLoadingState,
    (loading: LoadingState) => {
      const status = loading[id]

      return !isNil(status) ? status : defaultStatus
    }
  )
}
