// @flow
import React from 'react'
import { useDispatch } from 'react-redux'
import { DeteleContactModal } from '@edison/webmail-ui/components/Contacts/DeteleContactModal'
import { useHiddenContactDetail } from 'hooks/useHandleDetailModal'
import { deleteContact } from 'core/contacts/actions'

export default ({ isOpen, toggle, contactId, ...rest }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const hiddenContactDetail = useHiddenContactDetail('replace')
  function onConfirm() {
    toggle()
    hiddenContactDetail()
    dispatch(deleteContact([contactId]))
  }
  return (
    <DeteleContactModal
      isOpen={isOpen}
      onClose={toggle}
      onConfirm={onConfirm}
      {...rest}
    />
  )
}
