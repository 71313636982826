// @flow
import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useQueryParams } from 'common/url'
import { MfaError } from 'utils/errors'
import { show as showModal } from 'core/modals/actions'
import { login, getSession } from 'core/auth/actions'
import { makeGetSessionAccount } from 'core/auth/selectors'
import { authStatus, modalTypes, routePaths } from 'utils/constants'

import LoginModal, {
  DefaultDescription,
  ExpiredDescription,
  Footer,
  ForgotPassword,
} from '@edison/webmail-ui/components/Login/LoginModal'
import { validation } from '@edison/webmail-ui/components/Login'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'

import type { Dispatch } from 'types/redux'

type Props = {}

const LoginModalView = (props: Props) => {
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const { orderId, error } = useQueryParams()

  const getSessionAccount = useMemo(() => makeGetSessionAccount(orderId), [
    orderId,
  ])
  const account = useSelector(getSessionAccount)

  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(!!orderId)

  useEffect(() => {
    if (isLoading) {
      dispatch(getSession()).then(() => setLoading(false))
    }
  }, [])

  const descriptions = [
    {
      key: 'auth-expired',
      node: <ExpiredDescription />,
      condition: !!orderId && error === authStatus.INVALID.toString(),
    },
    {
      key: 'default',
      node: <DefaultDescription />,
      condition: true,
    },
  ]

  const toRender = descriptions.find(({ condition }) => condition)

  const onLogin = async ({ email, password }) => {
    setSubmitted(true)
    try {
      await dispatch(login(email, password))
    } catch (e) {
      if (e instanceof MfaError) {
        dispatch(
          showModal({
            key: modalTypes.mfaVerify,
            props: {
              mfa: {
                email,
                password,
                token: e.token,
                digits: e.digits,
                method: e.method,
              },
            },
          })
        )
      }
    }

    setSubmitted(false)
  }

  if (isLoading) {
    return <CircularLoader fullWidth fullHeight />
  }

  return (
    <LoginModal
      isOpen
      onLogin={onLogin}
      isLoading={submitted}
      validationSchema={validation}
      description={toRender ? toRender.node : null}
      initialValues={{ email: account ? account.emailAddress : '' }}
      footer={
        <Footer
          forgotPassword={
            <ForgotPassword
              onClick={() => history.push(routePaths.forgotPassword)}
            />
          }
        />
      }
    />
  )
}

export default LoginModalView
