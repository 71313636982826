// @flow
import moment from 'moment'
import keys from 'lodash/keys'
import ceil from 'lodash/ceil'
import mean from 'lodash/mean'
import clamp from 'lodash/clamp'
import keyBy from 'lodash/keyBy'
import floor from 'lodash/floor'
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'

import {
  connectionStatus,
  syncAccountStatus,
  connectionSyncStatus,
} from '@edison/webmail-core/utils/constants'
import { retrofitAccountFilter } from 'utils/constants'
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type {
  RetrofitAccount,
  RetrofitSyncStatus,
} from '@edison/webmail-core/types/retrofit'
import type {
  RetrofitActions,
  StartSyncProgress,
  StopSyncProgress,
} from './types'

export type State = {|
  // Account indexed by ECUUID
  +accounts: { [ecUUID: string]: RetrofitAccount },
  // Account filter for all email related data, defaults to 'all'
  // Set to `onmail` if user wants to filter by onmail accounts
  +filterBy: string,
  +sync: {
    progress: {
      total: number,
      accounts: { [ecUUID: string]: number },
    },
    accounts: { [ecUUID: string]: RetrofitSyncStatus },
  },
  +syncStatus: {
    +pulling: boolean,
    +paused: boolean,
  },
|}

const initialState: State = {
  accounts: {},
  filterBy: retrofitAccountFilter.ALL,
  sync: {
    progress: {
      total: 0,
      accounts: {},
    },
    accounts: {},
  },
  syncStatus: {
    pulling: true,
    paused: false,
  },
}

const reducer = createReducer<State, RetrofitActions>(initialState, {
  [actions.fetchAllAccountsActions.success.toString()]: fetchAllAccountsSuccess,
  [actions.fetchAccountActions.success.toString()]: fetchAccountSuccess,
  [actions.fetchSyncStatusActions.success.toString()]: fetchSyncStatusSuccess,
  [actions.fetchSyncStatusActions.failure.toString()]: fetchSyncStatusFailure,
  [actions.removeAccountActions.success.toString()]: removeAccountSuccess,
  [actions.setFilterAccountAction.toString()]: setFilterAccount,
  [actions.syncProgress.start.toString()]: toggleRetrofitSyncProgress,
  [actions.syncProgress.stop.toString()]: toggleRetrofitSyncProgress,
})

function fetchAllAccountsSuccess(state, action) {
  const { accounts } = action.payload

  return {
    ...state,
    accounts: keyBy(accounts, 'ecUUID'),
  }
}

function fetchAccountSuccess(state, action) {
  const { account } = action.payload

  return {
    ...state,
    accounts: { ...state.accounts, [account.ecUUID]: account },
  }
}

function fetchSyncStatusSuccess(state, action) {
  const {
    accounts,
    sync: { progress },
  } = state
  const syncHistories = keyBy(action.payload.syncHistories, 'ecUUID')

  const calculated = calculateSyncProgress(
    values(accounts).filter(
      account => account.status === connectionStatus.ACTIVE
    ),
    syncHistories
  )

  // Check if there's a account added or removed
  const isAccountChanged =
    keys(progress.accounts).length !== keys(calculated.accounts).length

  return {
    ...state,
    sync: {
      ...state.sync,
      progress: {
        // Keep the max value unless there's a account added or removed
        total: isAccountChanged
          ? calculated.total
          : Math.max(progress.total, calculated.total),
        // Always keep the max value
        accounts: mapValues(calculated.accounts, (value, ecUUID) =>
          Math.max(progress.accounts[ecUUID] || 0, value)
        ),
      },
      accounts: syncHistories,
    },
    syncStatus: {
      ...state.syncStatus,
      paused: false,
    },
  }
}

function fetchSyncStatusFailure(state, action) {
  return {
    ...state,
    syncStatus: {
      ...state.syncStatus,
      paused: true,
    },
  }
}

function removeAccountSuccess(state, action) {
  const { ecUUID } = action.payload
  let next = { ...state.accounts }
  if (ecUUID in state.accounts) {
    // Set the account to soft deleted
    next[ecUUID] = {
      ...state.accounts[ecUUID],
      status: connectionStatus.SOFT_DELETE,
    }
  }

  const hasActive =
    values(next).filter(({ status }) => status === connectionStatus.ACTIVE)
      .length > 0

  let filterBy = state.filterBy
  // Reset to the initial filterBy when
  // 1. The current value is about to delete
  // 2. There's no other active retrofit account
  if (filterBy === ecUUID || !hasActive) {
    filterBy = initialState.filterBy
  }

  return {
    ...state,
    filterBy,
    accounts: next,
  }
}

function setFilterAccount(state, action) {
  const { ecUUID } = action.payload

  return {
    ...state,
    filterBy: ecUUID,
  }
}

function toggleRetrofitSyncProgress(
  state: State,
  action: StartSyncProgress | StopSyncProgress
) {
  let pulling = false

  if (action.type === actions.syncProgress.start.toString()) {
    pulling = true
  }

  return {
    ...state,
    syncStatus: {
      ...state.syncStatus,
      pulling,
    },
  }
}

function calculateSyncProgress(
  accounts: $ReadOnlyArray<RetrofitAccount>,
  status: { [ecUUID: string]: RetrofitSyncStatus }
) {
  function round(input) {
    return clamp(input > 50 ? floor(input) : ceil(input), 0, 99)
  }

  let isFullSync = accounts.every(
    ({ ecUUID }) =>
      status[ecUUID] &&
      status[ecUUID].syncInEndFullSync === connectionSyncStatus.COMPLETE
  )

  const currTimestamp = moment().unix()

  const progress = accounts.reduce((acc, { ecUUID, insertTime }) => {
    let curr = 0

    if (!status[ecUUID]) {
      curr = 0
    } else {
      const { synced, unsynced, syncStatus, syncInEndFullSync } = status[ecUUID]

      if (syncInEndFullSync === connectionSyncStatus.COMPLETE) {
        curr = 100
      } else if (syncStatus === syncAccountStatus.INITIALIZING) {
        // EWM-2356
        //
        // When the account is initializing, the progress bar would be between 0 - 10%
        // within 3 minutes to keep the amount of unsynced stable
        const percentage = 10 * ((currTimestamp - insertTime) / (3 * 60))

        curr = clamp(ceil(percentage), 0, 10)
      } else if (unsynced > 0) {
        curr = round(10 + (synced / unsynced) * 90)
      } else {
        // In syncing, but no messages in
        curr = 10
      }
    }

    return {
      ...acc,
      [ecUUID]: curr,
    }
  }, {})

  return {
    accounts: progress,
    total: isFullSync ? 100 : round(mean(values(progress))),
  }
}

export default reducer
