// @flow
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import { modalTypes } from 'utils/constants'
import { useModal } from 'common/modals'
import PQueue from 'p-queue'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'

import {
  approveSender,
  fetchPendingThreads,
  showBlockPrompt,
} from 'core/contacts/actions'
import {
  getSSFlagByEmail,
  getRRBFlagByEmail,
  getPendingThreads,
  isContactStatusUpdating,
} from 'core/contacts/selectors'
import { useUserTutorial } from 'core/flags/hooks'
import { userTutorialFlags } from 'core/flags/constants'

import WarningIcon from '@material-ui/icons/Error'
import { IconAvatar } from '@edison/webmail-ui/components/Avatar'
import { ProTip } from '@edison/webmail-ui/components/Tooltip'
import Card, {
  CardAction,
  CardSubject,
  INITIAL_CARD_PROPS,
} from '@edison/webmail-ui/components/Cards/Card'
import { RRBButton } from '@edison/webmail-ui/components/ReadReceiptBlockingIcon'
import SenderCardSkeleton from '@edison/webmail-ui/components/Skeletons/SenderCard'
import { AuthEmailAvatar } from 'common/AuthAvatar'

import type { Node } from 'react'
import type { Dispatch } from 'types/redux'
import type { StackedProps } from '@edison/webmail-ui/components/Cards/Card'

const pendingThreadsSelector = getPendingThreads()
const contactStatusSelector = isContactStatusUpdating()

const queue = new PQueue({ concurrency: 4 })

type Props = StackedProps & {
  idx: number,
  id: string,
  email: string,
  name: string,
}
export default ({ idx, id, email, name, ...stackedProps }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const ssFlagByEmail = useSelector(getSSFlagByEmail)
  const rrbFlagByEmail = useSelector(getRRBFlagByEmail)
  const pendingThreads = useSelector(pendingThreadsSelector)
  const buttonDisabled = useSelector(contactStatusSelector)
  const contactsGalleryModal = useModal(modalTypes.contactsGallery)

  const tutorial = useUserTutorial()
  const tooltipFlag = tutorial.getFlag(userTutorialFlags.contactTooltip)
  const [isSubjectLoading, setIsSubjectLoading] = useState(false)
  const [debouncedAction, cancelDebounce] = useDebouncedCallback(
    email => {
      queue
        .add(() => dispatch(fetchPendingThreads(email)))
        .finally(() => setIsSubjectLoading(false))
    },
    500,
    []
  )
  const ssFlag = ssFlagByEmail[email]
  const rrbFlag = rrbFlagByEmail[email]

  useEffect(() => {
    if (!pendingThreads[email]) {
      setIsSubjectLoading(true)
      debouncedAction(email)
      return () => {
        cancelDebounce()
      }
    }
  }, [email])

  const flagWrapper = fn => e => {
    if (tooltipFlag) {
      tutorial.setFlag(userTutorialFlags.contactTooltip, false)
    }

    if (isFunction(fn)) {
      fn(e)
    }
  }

  const handleOnClickCard = flagWrapper(() => {
    contactsGalleryModal.showModal({ contactId: id })
  })

  const avatarNode = useMemo(() => {
    if (ssFlag) {
      return (
        <IconAvatar icon={<WarningIcon style={{ fontSize: '3.5rem' }} />} />
      )
    } else {
      return <AuthEmailAvatar email={email} name={name} />
    }
  }, [ssFlag, email])

  const props = useMemo(() => {
    let props = {
      ...INITIAL_CARD_PROPS,
      placeholder: <SenderCardSkeleton />,
      isLoading: isSubjectLoading,
    }
    if (email in pendingThreads) {
      const isEmpty = pendingThreads[email].length === 0
      const { subject } = get(pendingThreads[email], `[0]`, {})

      props.subject = (
        <CardSubject italic={isEmpty}>
          {isEmpty
            ? t('approveSender.thread.error')
            : subject || t('thread.subject.none')}
        </CardSubject>
      )
      props.info = {
        title: name,
        subtitle: email,
        avatar: avatarNode,
        extra: rrbFlag ? <RRBButton /> : null,
      }
      props.onClick = handleOnClickCard
    }
    return props
  }, [email, pendingThreads, handleOnClickCard])

  const showTooltip = idx === 0 && tooltipFlag

  return (
    <Card
      {...stackedProps}
      {...props}
      actions={
        <>
          <CardAction
            color="secondary"
            disabled={buttonDisabled}
            onClick={flagWrapper(() => dispatch(showBlockPrompt(email, true)))}
          >
            {t('button.block')}
          </CardAction>
          <CardAction
            disabled={buttonDisabled}
            onClick={flagWrapper(() => dispatch(approveSender(email)))}
          >
            <ContactActionWrapper
              onDismiss={flagWrapper()}
              enable={showTooltip}
            >
              <span>{t('button.acceptContact')}</span>
            </ContactActionWrapper>
          </CardAction>
        </>
      }
    />
  )
}

const ContactActionWrapper = ({
  enable,
  children,
  onDismiss,
}: {
  enable: boolean,
  children: Node,
  onDismiss?: () => void,
}) => {
  const { t } = useTranslation()

  return (
    <ProTip
      open={enable}
      onClick={onDismiss}
      title={t('approveSender.firstAction.tooltip')}
      style={{ marginTop: 30, zIndex: 10, maxWidth: '350px' }}
    >
      {children}
    </ProTip>
  )
}
