// @flow
import md5 from 'md5'
import isNil from 'lodash/isNil'

import * as client from '@edison/webmail-core/api'
import { createAction } from 'utils/redux'
import { getModalStatus } from 'core/modals/selectors'
import { getAttachmentPreview } from './selectors'
import { getAuth } from 'core/auth/selectors'
import { download as downloadAttachment } from 'core/attachments/actions'
import { download as downloadLargeAttachment } from 'core/large-attachments/actions'
import { show as showModal, hide as hideModal } from 'core/modals/actions'
import { modalTypes } from 'utils/constants'
import { getMetaDataFromAttachmentUrl } from 'utils'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type { AttachmentItem } from '@edison/webmail-core/types/attachment'
import type {
  FetchAttachmentPreviewLinkRequest,
  FetchAttachmentPreviewLinkSuccess,
  FetchAttachmentPreviewLinkFailure,
  FetchLargeAttachmentPreviewLinkRequest,
  FetchLargeAttachmentPreviewLinkSuccess,
  FetchLargeAttachmentPreviewLinkFailure,
} from './types'
import { isEnablePreviewAttachment } from 'utils'

export const fetchLargeAttachmentPreviewLinkActions: {
  request: ActionCreator<FetchLargeAttachmentPreviewLinkRequest>,
  success: ActionCreator<FetchLargeAttachmentPreviewLinkSuccess>,
  failure: ActionCreator<FetchLargeAttachmentPreviewLinkFailure>,
} = {
  request: createAction('FETCH_LARGE_ATTACHMENT_PREVIEW_LINK_REQUEST'),
  success: createAction('FETCH_LARGE_ATTACHMENT_PREVIEW_LINK_SUCCESS'),
  failure: createAction('FETCH_LARGE_ATTACHMENT_PREVIEW_LINK_FAILURE'),
}

export function fetchLargeAttachmentPreviewLink(
  attachment: AttachmentItem,
  password?: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const { id, preview } = attachment

    if (!isEnablePreviewAttachment(attachment)) return
    try {
      const { messageHeaderId, url } = attachment
      const { email, uid } = getMetaDataFromAttachmentUrl(url)
      dispatch(fetchLargeAttachmentPreviewLinkActions.request({}))
      if (isNil(password)) {
        // $FlowFixMe
        const res = await client.largeAttachments.authDownload(
          {
            aid: id,
            mid: messageHeaderId,
            uid,
            email,
            action: 'preview',
            pvformat: preview,
          },
          // $FlowFixMe
          { auth }
        )
        const previewUrl = res.result

        dispatch(
          fetchLargeAttachmentPreviewLinkActions.success({
            id,
            url: previewUrl,
          })
        )
        return previewUrl
      } else {
        // $FlowFixMe
        const res = await client.largeAttachments.download({
          aid: id,
          mid: messageHeaderId,
          uid,
          email,
          password: password ? md5(password) : '',
          action: 'preview',
          pvformat: preview,
        })
        const previewUrl = res.result

        dispatch(
          fetchLargeAttachmentPreviewLinkActions.success({
            id,
            url: previewUrl,
          })
        )
        return previewUrl
      }
    } catch (e) {
      let message = ''
      if (e.status === 404) {
        message = 'Preview link does not exist'
      }

      dispatch(fetchLargeAttachmentPreviewLinkActions.failure({ message }))

      // No auth
      if (e.status === 403) {
        const isPreviewOpen = getModalStatus(modalTypes.largeAttachmentPreview)(
          getState()
        )
        if (isPreviewOpen) {
          dispatch(hideModal({ key: modalTypes.largeAttachmentPreview }))
          dispatch(
            showModal({
              key: modalTypes.attachmentPassword,
              props: { attachment },
            })
          )
        }
      }
    }
  }
}

export const fetchAttachmentPreviewLinkActions: {
  request: ActionCreator<FetchAttachmentPreviewLinkRequest>,
  success: ActionCreator<FetchAttachmentPreviewLinkSuccess>,
  failure: ActionCreator<FetchAttachmentPreviewLinkFailure>,
} = {
  request: createAction('FETCH_ATTACHMENT_PREVIEW_LINK_REQUEST'),
  success: createAction('FETCH_ATTACHMENT_PREVIEW_LINK_SUCCESS'),
  failure: createAction('FETCH_ATTACHMENT_PREVIEW_LINK_FAILURE'),
}
export function fetchAttachmentPreviewLink(
  attachment: AttachmentItem,
  size: ?string
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const { id, preview, message: messageId } = attachment
    // The normal attachment id is uniq only within messages
    // which is not globally
    const uniqId = !!id ? `${messageId}_${id}` : ''

    const url = getAttachmentPreview(uniqId, size)(state)
    if (url) return url

    if (!isEnablePreviewAttachment(attachment)) return
    try {
      dispatch(fetchAttachmentPreviewLinkActions.request())

      if (preview !== 'pdf') {
        const url = await client.attachments.detail(messageId, id, auth, {
          size,
        })

        dispatch(
          fetchAttachmentPreviewLinkActions.success({
            id: uniqId,
            url,
            size,
          })
        )

        return url
      }

      const res = await client.attachments.download(
        {
          attachmentId: id,
          messageId,
          action: 'preview',
          pvformat: preview,
        },
        { auth }
      )

      const previewUrl = res.result
      dispatch(
        fetchAttachmentPreviewLinkActions.success({
          id: uniqId,
          url: previewUrl,
        })
      )
      return previewUrl
    } catch (e) {
      let message = ''
      if (e.status === 404) {
        message = 'Preview link does not exist'
      }

      dispatch(fetchAttachmentPreviewLinkActions.failure({ message }))

      return ''
    }
  }
}

export function download(attachment) {
  return async (dispatch, getState) => {
    const { type, name, id } = attachment

    if (type === 'normal') {
      dispatch(downloadAttachment({ ...attachment, fileName: name }))
    } else {
      dispatch(downloadLargeAttachment({ ...attachment, aid: id }))
    }
  }
}
