import get from 'lodash/get'
import qs from 'qs'
import React, { useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import type { Dispatch } from 'react-redux'
import { useShowCompose, useShowThreadDetail } from 'hooks/useHandleDetailModal'
import { useParams, useRouteMatch, useLocation } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { searchMore } from 'core/search/actions'
import { fetchNextThreads } from 'core/threads/actions'
import { modalTypes } from 'utils/constants'
import {
  isPureDraftAction,
  getThreadPaginationThreadIds,
  getThreadPaginationTotal,
} from 'core/messages/actions'
import { useActiveLabel } from 'core/threads/hooks'
import { useLoadingToast } from 'common/toasts'

import { getModalStatus } from 'core/modals/selectors'

import Tooltip from '@edison/webmail-ui/components/Tooltip'

const Pagination = ({ threadId }) => {
  const { label } = useParams()
  const loadingToast = useLoadingToast()
  const activeLabel = useActiveLabel()
  const showCompose = useShowCompose('replace')
  const showThreadDetail = useShowThreadDetail('replace')
  //no label mean in search
  const labelId = !!label ? activeLabel : 'search'
  const dispatch: Dispatch = useDispatch()
  const changeRoute = useCallback(
    threadId => {
      dispatch(isPureDraftAction(threadId))
        ? showCompose(threadId)
        : showThreadDetail(threadId)
    },
    [showCompose, showThreadDetail]
  )
  const [loading, setLoading] = useState(false)

  const threadIds = useMemo(
    () => dispatch(getThreadPaginationThreadIds(labelId)),
    [labelId]
  )

  const total = useMemo(() => dispatch(getThreadPaginationTotal(labelId)), [
    labelId,
  ])

  const fetchNext = async () => {
    let res = []

    loadingToast.showToast()
    if (labelId === 'search') {
      res = await dispatch(searchMore())
    } else {
      res = await dispatch(fetchNextThreads())
    }

    loadingToast.hideToast()
    return res
  }

  //store the initial sort

  const { t } = useTranslation()
  if (!threadIds.length) return null
  const index = threadIds.indexOf(threadId)

  if (index === -1) return null

  const enablePrevThread = index >= 1
  const enableNextThread = index !== -1 && index < total - 1

  return (
    <div className="inline-flex items-center flex-shrink-0 ml-4">
      <span className="text-gray-light text-sm mr-2">
        {index + 1}/{total}
      </span>
      <Tooltip title={t('button.newer')}>
        <IconButton
          size="small"
          disableRipple
          onClick={() => changeRoute(threadIds[index - 1])}
          disabled={!enablePrevThread}
        >
          <NavigateBeforeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('button.older')}>
        <IconButton
          size="small"
          disableRipple
          onClick={() => {
            if (threadIds.length === index + 1 && threadIds.length < total) {
              //load more threads
              setLoading(true)
              return fetchNext()
                .then(({ ids }) => {
                  if (ids.length) {
                    threadIds.push(...ids)
                    changeRoute(ids[0])
                  }
                })
                .finally(() => setLoading(false))
            } else {
              changeRoute(threadIds[index + 1])
            }
          }}
          disabled={!enableNextThread || loading}
        >
          <NavigateNextIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

// only label and search router enable pagination
export default ({ threadId }) => {
  const { label } = useParams()
  const { search } = useLocation()
  const match = useRouteMatch({
    path: '/u/:userId/search',
    strict: true,
  })
  const contactDetailModal = !!get(qs.parse(search.slice(1)), 'contact')
  const cleanupModal = useSelector(
    useMemo(() => getModalStatus(modalTypes.suggestedCleanupCalendar), [])
  )
  if (contactDetailModal || cleanupModal) return null
  if (!label && !match) return null
  return <Pagination threadId={threadId} />
}
