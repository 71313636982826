import React, { useMemo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useSelector, useDispatch } from 'react-redux'
import { isDraftDisabled } from 'core/compose/selectors'
import DeleteIcon from '@material-ui/icons/Delete'
import { discardDraft } from 'core/compose/actions'
import { useDebouncedCallback } from 'use-debounce'
import Tooltip from '@edison/webmail-ui/components/Tooltip'
import { useTranslation } from 'react-i18next'

export default function DeleteButton({ draftId }) {
  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )

  const dispatch = useDispatch()
  const [debouncedDiscardDraft] = useDebouncedCallback(
    draftId => dispatch(discardDraft(draftId)),
    300,
    { leading: true, trailing: false }
  )
  const { t } = useTranslation()
  return (
    <Tooltip title={t('button.discardDraft')}>
      <IconButton
        disableRipple
        disableFocusRipple
        disabled={disabled}
        onClick={() => debouncedDiscardDraft(draftId)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
