// @flow
import * as api from '@edison/webmail-core/api/sifts'
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  SiftsGetVendorRequest,
  SiftsGetVendorSuccess,
  SiftsGetVendorFailure,
} from './types'

export const fetchVendorActions: {
  request: ActionCreator<SiftsGetVendorRequest>,
  success: ActionCreator<SiftsGetVendorSuccess>,
  failure: ActionCreator<SiftsGetVendorFailure>,
} = {
  request: createAction('SIFTS_GET_VENDOR_REQUEST'),
  success: createAction('SIFTS_GET_VENDOR_SUCCESS'),
  failure: createAction('SIFTS_GET_VENDOR_FAILURE'),
}

export function fetchVendor(vendorId: number): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchVendorActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchVendorActions.request({ id: vendorId.toString() }))
      const res = await api.fetchVendor(vendorId, { auth })
      dispatch(fetchVendorActions.success(res.result))
    } catch (e) {
      dispatch(fetchVendorActions.failure({ message: e.message }))
    }
  }
}
