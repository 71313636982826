// @flow
import React, { useCallback } from 'react'

import { actions, selectors } from 'core/snackbars'
import { useDispatch, useSelector } from 'react-redux'

import SendSnackbar from 'screens/Compose/components/SendSnackbar'
import { snackbarTypes } from 'utils/constants'
import type { Dispatch } from 'types/redux'

export const registry = {
  [snackbarTypes.send]: SendSnackbar,
}

export default () => {
  const dispatch: Dispatch = useDispatch()
  const snackbars = useSelector(selectors.getSnackbars())

  return (
    <React.Fragment>
      {Object.keys(snackbars).map(key => {
        const SnackbarComponent = registry[key]
        const showSnackbar = () => dispatch(actions.showSnackbar({ key }))
        const hideSnackbar = () => dispatch(actions.hideSnackbar({ key }))

        return (
          <SnackbarComponent
            key={key}
            isOpen={snackbars[key]}
            toggle={snackbars[key] ? hideSnackbar : showSnackbar}
            {...snackbars[key]}
          />
        )
      })}
    </React.Fragment>
  )
}

/**
 * Hook to toggle snackbars
 *
 * @public
 * @param {string} key - The snackbar name to show
 * @return {Object} return the showSnackbar and hideSnackbar handlers
 */
export function useSnackbar(
  key: string,
  props: { [string]: any } = {}
): {
  showSnackbar: (snackbarProps?: { [string]: any }) => void,
  hideSnackbar: () => void,
} {
  const dispatch: Dispatch = useDispatch()

  const hideSnackbar = useCallback(() => {
    dispatch(actions.hideSnackbar({ key }))
  }, [key])
  const showSnackbar = useCallback(
    (snackbarProps: { [string]: any } = {}) => {
      dispatch(
        actions.showSnackbar({ key, props: { ...props, ...snackbarProps } })
      )
    },
    [key]
  )

  return { showSnackbar, hideSnackbar }
}
