//@flow
import type {
  CalendarSettingKeys,
  CalendarSettingsResource,
} from '@edison/webmail-core/types/calendar'
import type { CalendarSettingsListResult } from '@edison/webmail-core/api/calendar'
import moment from 'moment'
import type { UnixTimestampInSeconds } from './types-common'

export class ReduxSettingsResult {
  items: CalendarSettingsResource[]
  updateTime: number

  constructor(result: ?CalendarSettingsListResult) {
    if (result) {
      this.items = result.items
    } else {
      this.items = []
    }
    this.updateTime = 0
  }
  get lastUpdateTime() {
    return this.updateTime
  }
  set lastUpdateTime(val: UnixTimestampInSeconds) {
    this.updateTime = val
  }
  _findValueById(settingId: CalendarSettingKeys) {
    const ret = this.items.find((obj: CalendarSettingsResource) => {
      return obj.id === settingId
    })
    if (ret) {
      return ret.value
    }
  }

  _setValueById(settingId: CalendarSettingKeys, value: any) {
    const ret = this.items.find((obj: CalendarSettingsResource) => {
      return obj.id === settingId
    })
    const now = moment().unix()
    if (ret) {
      ret.value = value
      ret.eTag = now
    } else {
      this.items.push({
        kind: 'calendar#setting',
        eTag: now,
        id: settingId,
        value,
      })
    }
  }

  get dateFieldOrder() {
    return this._findValueById('dateFieldOrder') || 'MDY'
  }

  set dateFieldOrder(val: string) {
    this._setValueById('dateFieldOrder', val)
  }

  get defaultEventLength() {
    return this._findValueById('defaultEventLength') || 60
  }

  set defaultEventLength(val: number) {
    this._setValueById('defaultEventLength', val)
  }

  get format24HourTime() {
    return this._findValueById('format24HourTime') || false
  }

  set format24HourTime(val: boolean) {
    this._setValueById('format24HourTime', val)
  }

  get hideInvitations() {
    return this._findValueById('hideInvitations') || false
  }

  set hideInvitations(val: boolean) {
    this._setValueById('hideInvitations', val)
  }

  get hideWeekends() {
    return this._findValueById('hideWeekends') || false
  }

  set hideWeekends(val: boolean) {
    this._setValueById('hideWeekends', val)
  }

  get locale() {
    return this._findValueById('locale') || 'en'
  }

  set locale(val: string) {
    this._setValueById('locale', val)
  }

  get remindOnRespondedEventsOnly() {
    return this._findValueById('remindOnRespondedEventsOnly') || false
  }

  set remindOnRespondedEventsOnly(val: boolean) {
    this._setValueById('remindOnRespondedEventsOnly', val)
  }

  get showDeclinedEvents() {
    return this._findValueById('showDeclinedEvents') || true
  }

  set showDeclinedEvents(val: boolean) {
    this._setValueById('showDeclinedEvents', val)
  }

  get timezone(): string {
    return (
      this._findValueById('timezone') ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      'Etc/GMT'
    )
  }

  set timezone(val: string) {
    this._setValueById('timezone', val)
  }

  get weekStart() {
    return this._findValueById('weekStart') || 0
  }

  set weekStart(val: number) {
    this._setValueById('weekStart', val)
  }

  get useKeyboardShortcuts() {
    return this._findValueById('useKeyboardShortcuts') || true
  }

  set useKeyboardShortcuts(val: boolean) {
    this._setValueById('useKeyboardShortcuts', val)
  }

  getSettingById(key: CalendarSettingKeys) {
    return this.items.find(
      (setting: CalendarSettingsResource) => setting.id === key
    )
  }

  static updateSettingAndReturnNewSettingsIfNew(
    oldSelf: ReduxSettingsResult,
    newData: CalendarSettingsResource
  ): ?ReduxSettingsResult {
    const items = oldSelf.items
    let dataFound = false
    let dataChanged = false
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === newData.id) {
        dataFound = true
        if (ReduxSettingsResult._isSettingNewer(items[i], newData)) {
          items[i].value = newData.value
          dataChanged = true
        }
        break
      }
    }
    if (!dataFound) {
      items.push(newData)
      dataChanged = true
    }
    if (dataChanged) {
      return new ReduxSettingsResult({ items })
    }
  }

  static _isVersionNewer(oldVersion: number, newVersion: number) {
    return oldVersion < newVersion
  }

  static _isSettingNewer(
    oldSetting: CalendarSettingsResource,
    newSetting: CalendarSettingsResource
  ) {
    return (
      oldSetting.value !== newSetting.value &&
      ReduxSettingsResult._isVersionNewer(oldSetting.eTag, newSetting.eTag)
    )
  }

  _isNewer(newData: CalendarSettingsListResult): boolean {
    if (!Array.isArray(newData.items)) {
      newData.items = []
    }
    if (newData.items.length !== this.items.length) {
      return true
    }
    for (let newSetting of newData.items) {
      const oldSetting = this.getSettingById(newSetting.id)
      if (!oldSetting) {
        return true
      }
      if (ReduxSettingsResult._isSettingNewer(oldSetting, newSetting)) {
        return true
      }
    }
    return false
  }

  static returnNewIfDiff(
    oldSelf: ReduxSettingsResult,
    newData: CalendarSettingsListResult
  ): ?ReduxSettingsResult {
    if (oldSelf._isNewer(newData)) {
      return new ReduxSettingsResult(newData)
    }
  }
}
