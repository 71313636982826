// @flow
import React from 'react'
import cn from 'classnames'

import type { Node } from 'react'

type Props = {
  children: Node,
  label: string,
  labelClassNames?: string,
}

export default ({
  labelClassNames = 'uppercase`',
  label,
  children,
  htmlFor,
}: Props) => (
  //items-baseline not work in safari.
  //https://bugs.webkit.org/show_bug.cgi?id=142968
  <div className="flex flex-1 items-top py-1">
    <label
      className={cn(
        'text-gray-medium pr-2 h-8 inline-flex items-center',
        labelClassNames
      )}
      htmlFor={htmlFor}
    >
      {label}
    </label>
    <div className="flex-1">{children}</div>
  </div>
)
