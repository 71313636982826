import React, {
  useMemo,
  useState,
  Fragment,
  useEffect,
  useCallback,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'
import moment from 'moment'
import cn from 'classnames'
import get from 'lodash/get'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import Sender from 'common/Sender'
import {
  getMessageSnippet,
  getMessage,
  isShowSpam,
} from 'core/messages/selectors'
import { labelActions } from 'core/metadata/actions'
import ReportIcon from '@material-ui/icons/Report'
import WarningIcon from '@material-ui/icons/Error'
import { getMessageLabelIds } from 'core/metadata/selectors'
import { getSearchKeywords } from 'core/search/selectors'

import tailwindConfig from '@edison/webmail-ui/tailwind.config'
import SendVia from '@edison/webmail-ui/components/SendVia'
import ReadReceiptBlockingIcon from '@edison/webmail-ui/components/ReadReceiptBlockingIcon'
import { Prompt as SuspiciousDetectingPrompt } from '@edison/webmail-ui/components/SecurityMessage/SuspiciousDetecting'

import { getComputedStyle } from '@edison/webmail-ui/utils'
import Color from 'color'
import { CONTENT_DISPOSITION_INLINE } from '@edison/webmail-core/utils/constants'
import { composeStatus, labelNames, routePaths } from 'utils/constants'
import { isMessageContainKeywords } from 'utils/htmlProcess'

import { getDraft } from 'core/compose/selectors'
import EmailBody from './EmailBody'
import BlockAllTip from './BlockAllTip'
import MessageAction from './MessageAction'
import MessageDetail from './MessageDetail'
import MessageCompose from './MessageCompose'
import NormalAttachments from './NormalAttachments'
import LargeAttachments from './LargeAttachments'

import { IconAvatar } from '@edison/webmail-ui/components/Avatar'
import { useTranslation } from 'react-i18next'
import { useDetailModalGoBack } from 'hooks/useGoBack'
import { getMessageDefaultRecipient } from 'core/messages/selectors'
import {
  getDomainAliasesSelector,
  isCurrentUser,
} from 'core/custom-domains/selectors'
import {
  fetchCalendars,
  fetchICSEventData,
  updateEvenOrInstanceAttendee,
} from '../../../core/calendar/actions'
import EventInfo from '@edison/webmail-ui/components/Preview/EventInfo'
import {
  getEvent,
  getICSEventDataByKey,
  getPrimaryCalendar,
  getRange,
  getSettings,
} from '../../../core/calendar/selectors'
import { formatEventDate } from '@edison/webmail-ui/screens/Calendar/utils'
import { convertRRuleToRepeatString } from '@edison/webmail-ui/screens/Calendar/rruleUtils'
import { useOrderId } from '../../../core/auth/hooks'
import { getInstanceById } from '../../../core/calendar/selectors-events'
import {
  calendarEventResponseStatusToRSVPStatus,
  generateCalendarUrl,
  updateEventAttendeeRSVPStatus,
} from '../../../utils/calendar'
import { getAuth } from '../../../core/auth/selectors'
import { showNotification } from '../../../core/toasts/actions'
import { toastVariants } from '../../../common/toasts'
import i18next from 'i18next'
import { stringArrayIncludes } from '@edison/functools'
import Subject from 'common/ThreadList/Subject'
import EmailNudgePrompt from './EmailNudgePrompt'

const rsvpChangeErrorText = i18next.t('calendar.event.rsvp.update.error')
const bodyKeywordsSelector = getSearchKeywords('body')

function formatMailDate(date) {
  const mailMomentDate = moment(date * 1000)
  return mailMomentDate.format(
    mailMomentDate.isSame(new Date(), 'day') ? 'h:mm A' : 'MMM D, YYYY, h:mm A'
  )
}

function MessageSender({ name, email, onSelectRecipient, noMe }) {
  const [onRecipientHoverIn, cancelDebounce] = useDebouncedCallback(
    onSelectRecipient,
    600
  )
  const sameUser = useSelector(useMemo(() => isCurrentUser(email), [email]))
  const sender = <Sender name={name} email={email} noMe={noMe} />

  if (sameUser) return sender
  return (
    <span
      className="cursor-pointer truncate"
      onMouseEnter={e => {
        e.stopPropagation()
        onRecipientHoverIn({ name, email })
      }}
      onMouseLeave={cancelDebounce}
    >
      {sender}
    </span>
  )
}

function TrashedMessageTip({ messageId, onUntrash }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className="flex justify-between items-center h-10 px-4  py-2 bg-secondary text-sm">
      <div>{t('message.tip.deleted')}</div>
      <div>
        <button
          className="text-primary font-semibold"
          onClick={() => {
            dispatch(labelActions.untrash.message(messageId))
            onUntrash()
          }}
        >
          {t('button.restoreMessage')}
        </button>
      </div>
    </div>
  )
}

function MarkNoSpamMessageTip({ messageId, onUntrash }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className="p-4 text-sm bg-pane">
      <div>{t('message.spam.tips')}</div>
      <div
        className="cursor-pointer font-bold"
        style={{ color: 'var(--color-red)' }}
        onClick={() => {
          dispatch(labelActions.notSpam.message(messageId))
        }}
      >
        {t('mask.as.not.spam')}
      </div>
    </div>
  )
}

function MessageItem({
  messageId,
  messageIds,
  onSelectRecipient,
  threadId,
  isModalMode = false,
  isDraft = false,
  onViewInCalendar,
  onDismiss,
}) {
  const message = useSelector(useMemo(() => getMessage(messageId), [messageId]))
  const { subject, from } = message
  const params = useParams()
  const labelIds = useSelector(
    useMemo(() => getMessageLabelIds(messageId), [messageId])
  )
  const keywords = useSelector(bodyKeywordsSelector)

  const messageDefaultRecipientSelector = useMemo(
    () => getMessageDefaultRecipient(messageId),
    [messageId]
  )
  const messageDefaultRecipient = useSelector(messageDefaultRecipientSelector)
  const showSpam = useSelector(
    useMemo(() => isShowSpam(messageId), [messageId])
  )
  const draft = useSelector(useMemo(() => getDraft(messageId), [messageId]))
  const isLast = messageIds.indexOf(messageId) === messageIds.length - 1
  const isFirst = messageIds.indexOf(messageId) === 0
  const isTrashed =
    params.label !== 'search' &&
    params.label !== 'trash' &&
    labelIds.includes(labelNames.trash)
  const isUnread = labelIds.includes(labelNames.unread)

  const isContainKeyword = useMemo(
    () => isMessageContainKeywords(message, keywords),
    [keywords]
  )
  // Suspicious Message Flag
  const ssFlag = get(message, 'ssFlag', false)

  const enableTrim =
    (!draft ||
      draft.status === composeStatus.deleted ||
      draft.status === composeStatus.sended) &&
    !isLast &&
    !isTrashed

  const [trimed, setTrimed] = useState(
    enableTrim && !isUnread && !isContainKeyword
  )
  const goBack = useDetailModalGoBack('thread')

  useEffect(() => {
    const isLast = messageIds.indexOf(messageId) === messageIds.length - 1
    if (isLast) {
      setTrimed(false)
    }
  }, [messageIds, messageId])

  useEffect(() => {
    if (!trimed && messageDefaultRecipient) {
      // Select the default recipient when untrim the message
      onSelectRecipient(messageDefaultRecipient)
    }
  }, [trimed])

  const attachmentsNode = <Attachments message={message} trimed={trimed} />

  const trimedRightNode = (
    <TrimedRightContainer
      message={message}
      recipients={<TrimedRecipients message={message} />}
      attachments={attachmentsNode}
    />
  )

  const untrimedRightNode = (
    <UntrimedRightContainer
      message={message}
      mailFrom={
        <MessageSender
          {...message.from}
          noMe
          onSelectRecipient={onSelectRecipient}
        />
      }
      recipients={
        <UntrinedRecipients
          message={message}
          onSelectRecipient={onSelectRecipient}
        />
      }
      actions={
        !!message.messageId ? (
          <MessageAction
            messageId={messageId}
            onAction={action => {
              if (
                ['trash', 'deleteForever'].includes(action) &&
                messageIds.length <= 1
              ) {
                goBack()
              }
            }}
          />
        ) : null
      }
    />
  )
  const untrimedBodyNode = (
    <>
      {isTrashed && (
        <div className="pb-4">
          <TrashedMessageTip
            messageId={messageId}
            onUntrash={() => messageIds.length === 1 && goBack()}
          />
        </div>
      )}
      {!showSpam && (
        <div className="pb-4">
          <MarkNoSpamMessageTip messageId={messageId} />
        </div>
      )}
      <div className="px-4">
        <ICSEvents
          onViewInCalendar={onViewInCalendar}
          messageExtendInfo={message.extendInfo}
        />
        {ssFlag && <SuspiciousDetectingPrompt />}
        {isLast && <BlockAllTip email={message.from.email} />}
        <div className="pb-4">
          <EmailBody message={message} />
        </div>
        {attachmentsNode}
      </div>
    </>
  )

  const composeNode = Boolean(draft) &&
    ![composeStatus.deleted, composeStatus.sended].includes(draft.status) && (
      <MessageCompose
        draftId={messageId}
        onSelectRecipient={onSelectRecipient}
      />
    )

  const avatarNode = useMemo(() => {
    if (labelIds.includes(labelNames.spam)) {
      return (
        <IconAvatar
          icon={<ReportIcon className="text-white" />}
          containerProps={{ className: 'bg-red' }}
        />
      )
    }
    if (ssFlag) {
      return (
        <IconAvatar icon={<WarningIcon style={{ fontSize: '3.5rem' }} />} />
      )
    }
    return (
      <AuthEmailAvatar email={message.from.email} name={message.from.name} />
    )
  }, [labelIds, message])

  if (isModalMode) {
    return (
      <ModalModeMessageLayout
        isFirst={isFirst}
        isDraft={isDraft}
        isLast={isLast}
        message={message}
        from={from}
        onDismiss={onDismiss}
        onSelectRecipient={onSelectRecipient}
        subject={subject}
        messageId={messageId}
        threadId={threadId}
        isTrimed={Boolean(trimed)}
        avatar={avatarNode}
        right={null}
        body={trimed ? null : untrimedBodyNode}
        compose={trimed ? null : composeNode}
        onClick={enableTrim ? () => !isLast && setTrimed(!trimed) : null}
      />
    )
  }

  return (
    <MessageLayout
      messageId={messageId}
      isTrimed={Boolean(trimed)}
      avatar={avatarNode}
      right={trimed ? trimedRightNode : untrimedRightNode}
      body={trimed ? null : untrimedBodyNode}
      compose={trimed ? null : composeNode}
      onClick={enableTrim ? () => !isLast && setTrimed(!trimed) : null}
    />
  )
}
const ICSEvent = React.memo(
  ({
    eventId,
    onAddToCalendar: onAddToCalendarCb,
    onViewInCalendar: onViewInCalendarCb,
  }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const userId = useOrderId()
    const uiRange = useSelector(getRange)
    const userAliases = useSelector(getDomainAliasesSelector)
    const calendarSettings = useSelector(getSettings)
    const primaryCalendar = useSelector(getPrimaryCalendar())
    const startTime = moment()
      .startOf('day')
      .unix()
    const icsData = useSelector(
      getICSEventDataByKey({
        calendarId: primaryCalendar.id,
        eventOrInstanceId: eventId,
        startTime,
      })
    )
    const event = useSelector(
      getEvent({
        calendarId: !!icsData ? icsData.calendarId : '',
        eventId: !!icsData ? icsData.eventId : '',
      })
    )
    const instance = useSelector(
      getInstanceById({
        calendarId: !!icsData ? icsData.calendarId : '',
        eventId: !!icsData ? icsData.eventId : '',
        instanceId: !!icsData ? icsData.instanceId : '',
      })
    )
    const rsvpEvent = useMemo(() => {
      if (event && event.eventId === eventId) {
        return event
      }
      if (instance && instance.instanceId === eventId) {
        return instance
      }
      return null
    }, [eventId, event, instance])
    const auth = useSelector(useMemo(() => getAuth(), []))
    const rsvpAttendee = useMemo(() => {
      if (rsvpEvent) {
        return (
          rsvpEvent.getAttendeeByEmails(userAliases) || { email: auth.user }
        )
      }
      return null
    }, [rsvpEvent, auth])
    const rsvpStatus = useMemo(() => {
      if (rsvpAttendee) {
        return calendarEventResponseStatusToRSVPStatus(
          rsvpAttendee.responseStatus
        )
      }
      return ''
    }, [rsvpAttendee ? rsvpAttendee.responseStatus : ''])
    const eventOrInstance = useMemo(() => {
      if (icsData && icsData.data && icsData.data.isInstance) {
        if (instance) {
          return instance
        }
        return icsData.data
      }
      return event
    }, [event, instance, icsData])
    const onRSVPChange = useCallback(
      status => {
        if (rsvpEvent && rsvpAttendee) {
          updateEventAttendeeRSVPStatus(rsvpAttendee, status)
          return dispatch(
            updateEvenOrInstanceAttendee({
              eventOrInstance: rsvpEvent,
              attendee: rsvpAttendee,
            })
          )
            .then(() => {
              return Promise.resolve()
            })
            .catch(e => {
              dispatch(
                showNotification(rsvpChangeErrorText, toastVariants.error)
              )
              return Promise.reject()
            })
        }
        dispatch(showNotification(rsvpChangeErrorText, toastVariants.error))
        return Promise.resolve()
      },
      [rsvpEvent, rsvpAttendee, dispatch]
    )
    useEffect(() => {
      if (!primaryCalendar.id) {
        dispatch(fetchCalendars()).catch(e => {
          console.error(`Fetching event calendars ${eventId} failed`)
        })
      } else {
        dispatch(
          fetchICSEventData({
            calendarId: primaryCalendar.id,
            eventOrInstanceId: eventId,
            startTimestamp: startTime,
          })
        ).catch(e => {
          console.debug(`Fetching event ${eventId} failed`)
        })
      }
    }, [primaryCalendar.id])
    const calendarUrl = useMemo(() => {
      if (!eventOrInstance || rsvpStatus === 'no') {
        return ''
      }
      return generateCalendarUrl({
        resolution: uiRange.resolution,
        userId,
        timezone: calendarSettings.timezone,
        basePath: routePaths.calendarView,
        eventOrInstance,
      })
    }, [eventOrInstance, rsvpStatus])
    const onViewInCalendar = useMemo(() => {
      if (!calendarUrl) {
        return undefined
      }
      if (
        eventOrInstance &&
        (eventOrInstance.isGhost || eventOrInstance.cancelled)
      ) {
        return undefined
      }
      return () => {
        if (eventOrInstance) {
          if (onViewInCalendarCb) {
            onViewInCalendarCb()
          }
          history.push(calendarUrl)
        }
      }
    }, [calendarUrl, eventOrInstance])
    const hideRSVPStatus = useMemo(() => {
      return eventOrInstance && eventOrInstance.organizer.self
    }, [eventOrInstance])
    const data = useMemo(() => {
      if (!eventOrInstance) {
        return null
      }
      const attendeesMap = {}
      let organizerFound = false
      if (
        eventOrInstance.event.organizer &&
        !eventOrInstance.event.organizer.self &&
        eventOrInstance.event.organizer.email
      ) {
        attendeesMap[eventOrInstance.event.organizer.email] = {
          email: eventOrInstance.event.organizer.email,
          name: eventOrInstance.event.organizer.displayName,
          isOrganizer: true,
        }
        organizerFound = true
      }
      eventOrInstance.attendees.forEach(attendee => {
        if (attendee.email && !attendeesMap[attendee.email]) {
          attendeesMap[attendee.email] = attendee
          if (
            !organizerFound &&
            eventOrInstance &&
            eventOrInstance.self &&
            stringArrayIncludes({
              strArray: userAliases,
              matchStr: attendee.email,
              options: { ignoreCase: true },
            })
          ) {
            attendeesMap[attendee.email].isOrganizer = true
          }
          organizerFound = true
        }
      })
      return {
        hideRSVPStatus,
        rsvpStatus,
        onRSVPChange,
        summary: eventOrInstance.summary,
        description: eventOrInstance.description,
        when: formatEventDate(eventOrInstance.toApiJSON()),
        repeat: convertRRuleToRepeatString(eventOrInstance.rruleString),
        location: eventOrInstance.location,
        attendees: Object.values(attendeesMap),
        isSoftDelete: eventOrInstance.isSoftDelete,
        cancelled: eventOrInstance.cancelled,
        isUpdated: eventOrInstance.isUpdated,
        onViewInCalendar,
      }
    }, [eventOrInstance, onViewInCalendar, onRSVPChange, hideRSVPStatus])
    if (data) {
      return <EventInfo {...data} />
    }
    return null
  },
  (prevProps, nextProps) => {
    return (
      prevProps.eventId === nextProps.eventId &&
      prevProps.onAddToCalendar === nextProps.onAddToCalendar &&
      prevProps.onViewInCalendar === nextProps.onViewInCalendar
    )
  }
)

export const ICSEvents = ({
  messageExtendInfo,
  onAddToCalendar,
  onViewInCalendar,
}) => {
  const eventIds = useMemo(() => {
    const ret = {}
    if (messageExtendInfo && Array.isArray(messageExtendInfo.ics)) {
      messageExtendInfo.ics.forEach(item => {
        if (Array.isArray(item.uids)) {
          item.uids.forEach(eventId => {
            ret[eventId] = true
          })
        }
      })
    }
    return Object.keys(ret)
  }, [messageExtendInfo])
  if (eventIds.length === 0) {
    return null
  }
  const events = eventIds.map(eventId => {
    return (
      <div className="items-stretch p-4" key={eventId}>
        <ICSEvent
          eventId={eventId}
          key={eventId}
          onAddToCalendar={onAddToCalendar}
          onViewInCalendar={onViewInCalendar}
        />
      </div>
    )
  })
  return <>{events}</>
}

export function MessageLayout({
  messageId,
  isTrimed,
  avatar,
  right,
  body,
  compose,
  onClick,
}) {
  return (
    <>
      <div id={messageId}>
        <div
          className={cn(`flex p-4`, { 'cursor-pointer': !!onClick })}
          onClick={onClick}
        >
          <div className="flex-shrink-0">
            <div className="relative">
              {isTrimed && (
                <div
                  className="absolute inset-0 rounded-full"
                  style={{
                    background: Color(
                      getComputedStyle(
                        tailwindConfig.theme.extend.backgroundColor.secondary
                      )
                    )
                      .alpha(0.5)
                      .string(),
                  }}
                />
              )}
              {avatar}
            </div>
          </div>
          <div
            className="flex-grow pl-4"
            style={{ width: `calc(100% - 48px)` }}
          >
            {right}
          </div>
        </div>
        {body}
      </div>
      {compose}
    </>
  )
}

export function TrimedRecipients({ message }) {
  const recipients = [...message.to, ...message.cc, ...message.bcc]

  return (
    <>
      <span className="font-semibold text-dark">
        <Sender {...message.from} trimed noMe />
        &nbsp;to&nbsp;
      </span>
      {recipients.map((each, index) => {
        const join =
          index < recipients.length - 1 ? (
            index === recipients.length - 2 ? (
              <>&nbsp;and&nbsp;</>
            ) : (
              <>,&nbsp;</>
            )
          ) : null
        return (
          <span key={each.email}>
            <Sender {...each} trimed />
            {join}
          </span>
        )
      })}
    </>
  )
}

export function UntrinedRecipients({ message, onSelectRecipient }) {
  const recipients = [...message.to, ...message.cc]

  return (
    <div
      className="flex flex-wrap items-center text-gray-light"
      style={{ whiteSpace: 'break-spaces' }}
    >
      {`to `}
      {recipients.map((each, index) => {
        return (
          <Fragment key={each.email}>
            <MessageSender {...each} onSelectRecipient={onSelectRecipient} />

            {index < recipients.length - 1 ? ', ' : ''}
          </Fragment>
        )
      })}
      {message.bcc.length && recipients.length ? ', ' : ''}
      {message.bcc.length ? 'bcc: ' : ''}
      {message.bcc.map((each, index) => {
        return (
          <Fragment key={each.email}>
            <MessageSender {...each} onSelectRecipient={onSelectRecipient} />
            {index < message.bcc.length - 1 ? ', ' : ''}
          </Fragment>
        )
      })}
      <MessageDetail message={message} onSelectRecipient={onSelectRecipient} />
    </div>
  )
}

export function TrimedRightContainer({ message, recipients, attachments }) {
  const snippet = useSelector(
    useMemo(() => getMessageSnippet(message.id), [message.id])
  )

  return (
    <div>
      <div className="flex items-center text-xs text-gray-light leading-6">
        <div className="flex-grow font-semibold truncate">{recipients}</div>
        <div className="flex-shrink-0 flex">
          <span className="ml-2 text-xs">{formatMailDate(message.date)}</span>
          {message.rrbFlag && (
            <div className="ml-2" onClick={e => e.stopPropagation()}>
              <ReadReceiptBlockingIcon />
            </div>
          )}
        </div>
      </div>
      <div
        className="text-sm text-gray-medium truncate"
        style={{ minHeight: 21 }}
      >
        {snippet}
      </div>
      {attachments}
    </div>
  )
}

export function UntrimedRightContainerModalMode({ actions }) {
  return <div className="flex justify-between">{actions}</div>
}

export function UntrimedRightContainer({
  message,
  recipients,
  actions,
  mailFrom,
  className,
}) {
  const sendVia: string = get(message, 'sendVia', '')
  return (
    <div className={cn('flex justify-between', className)}>
      <div className="leading-relaxed flex-grow truncate">
        <div className="flex items-center">
          <div className="text-sm font-semibold text-gray-dark flex items-center truncate">
            {mailFrom}
            {!!sendVia && <SendVia via={sendVia} />}
          </div>
          <div className="ml-4 text-xs text-gray-light">
            {formatMailDate(message.date)}
          </div>
          {message.rrbFlag && (
            <div className="ml-2 flex" onClick={e => e.stopPropagation()}>
              <ReadReceiptBlockingIcon />
            </div>
          )}
        </div>

        {recipients}
      </div>
      {actions}
    </div>
  )
}

export function Attachments({ message, trimed }) {
  const { attachments, largeAttachments, id, from } = message
  const normalAttachments = useMemo(
    () =>
      attachments.filter(
        ({ contentDisposition }) =>
          contentDisposition !== CONTENT_DISPOSITION_INLINE
      ),
    [attachments]
  )

  const hasNormalAttachments = normalAttachments.length > 0
  const hasLargeAttachments = largeAttachments.length > 0
  if (hasNormalAttachments || hasLargeAttachments) {
    return (
      <div className="pb-4">
        {normalAttachments.length > 0 && (
          <NormalAttachments
            message={message}
            attachments={normalAttachments}
            trimed={trimed}
          />
        )}
        {largeAttachments.length > 0 && (
          <LargeAttachments
            messageId={id}
            largeAttachments={largeAttachments}
            fromEmail={get(from, 'email', '')}
            trimed={trimed}
          />
        )}
      </div>
    )
  }

  return null
}

export function ModalModeMessageLayout({
  messageId,
  isTrimed,
  avatar,
  right,
  body,
  compose,
  subject,
  onClick,
  from,
  onSelectRecipient,
  message,
  threadId,
  isFirst,
  isLast,
  onDismiss,
  isDraft,
}) {
  return (
    <>
      <div
        className={cn('last:pb-32', {
          'pt-8': isFirst,
        })}
        id={messageId}
      >
        {isFirst ? (
          <>
            <div className="flex px-10 justify-between">
              <Subject className="text-2xl font-bold" subject={subject} />
            </div>
            {!isDraft ? (
              <EmailNudgePrompt
                onDismiss={onDismiss}
                className="my-4 px-10"
                threadId={threadId}
              />
            ) : null}
          </>
        ) : null}
        <div
          onClick={onClick}
          className={cn('relative px-10 flex my-8', {
            'cursor-pointer': !!onClick,
          })}
        >
          {avatar}
          <UntrimedRightContainer
            className="ml-4"
            message={message}
            mailFrom={
              <MessageSender
                {...message.from}
                noMe
                onSelectRecipient={onSelectRecipient}
              />
            }
            recipients={
              <UntrinedRecipients
                message={message}
                onSelectRecipient={onSelectRecipient}
              />
            }
          />
        </div>
        <div className="px-6">{body}</div>
      </div>
    </>
  )
}

export default ({ messageId, ...props }) => {
  const message = useSelector(useMemo(() => getMessage(messageId), [messageId]))

  if (!message || !message.loaded) return null
  return <MessageItem {...props} messageId={messageId} />
}
