// @flow
import { createSelector } from 'reselect'
import { cleanupTypes } from '@edison/webmail-core/utils/constants'
import { getLoadingStatus } from 'core/loading/selectors'

import type { State, Selector } from 'types/state'

export const getCleanupState = (state: State) => state.cleanup

const cleanupSort = [
  cleanupTypes.CALENDAR,
  cleanupTypes.ZOOM,
  cleanupTypes.WEBEX,
  cleanupTypes.THIRD_ACCOUNT,
]
export const getCleanupSelector = createSelector(getCleanupState, state =>
  cleanupSort.filter(item => state[item]).map(item => state[item])
)

export function getCleanup(type: string): Selector<boolean> {
  return createSelector(getCleanupState, state => state[type])
}

export const hasCleanupSelector = createSelector(
  getCleanupState,
  state => !!Object.keys(state).length
)
export const isFetchCleanupListLoading: Selector<boolean> = getLoadingStatus(
  'CLEANUP_SEARCH'
)
