// @flow
import React, { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import { show as showModal } from 'core/modals/actions'
import {
  approveSender,
  approveAndMoveTo,
  fetchPendingThreads,
  showBatchBlockPrompt,
} from 'core/contacts/actions'
import { modalTypes } from 'utils/constants'
import { getDomainByEmail } from 'utils'
import {
  getContactById,
  getContactByEmail,
  getPendingThreads,
  isContactStatusUpdating,
  getPendingContacts,
} from 'core/contacts/selectors'
import useOnMailTheme from 'hooks/useOnMailTheme'

import DetailModal from '@edison/webmail-ui/components/DetailModal'
import SimpleContactDetail from 'common/Contacts/SimpleDetails'
import ThreadHelmet from 'screens/Helmets/ThreadHelmet'
import Actions from './Actions'
import Pagination from './Pagination'
import Body, { EmptyBody } from './Body'

import type { RouterHistory } from 'react-router-dom'
import type { Dispatch } from 'types/redux'

const pendingThreadsSelector = getPendingThreads()
const contactStatusSelector = isContactStatusUpdating()
const pendingSelector = getPendingContacts()

type Props = {
  isOpen: boolean,
  id: string,
  email: string,
  toggle: () => void,
  history: RouterHistory,
}
export default withRouter(({ history, isOpen, toggle, email, id }: Props) => {
  const { isDarkTheme } = useOnMailTheme()
  const dispatch: Dispatch = useDispatch()
  const [threadIndex, setThreadIndex] = useState(0)
  const contactDetailSelector = useMemo(() => getContactById(id), [id])
  const contactSelector = useMemo(() => getContactByEmail(email), [email])
  const contactDetail = useSelector(contactDetailSelector)
  const contact = useSelector(contactSelector)
  const pendingThreads = useSelector(pendingThreadsSelector)
  const pendingContactThreads = get(pendingThreads, email, [])
  const thread = pendingContactThreads[threadIndex]
  const pending = useSelector(pendingSelector)
  const contactUpdating = useSelector(contactStatusSelector)

  const {
    mailFrom,
    receivedAt,
  }: {
    mailFrom?: { email: string, name: string },
    receivedAt?: number,
  } = useMemo(() => {
    let mailFrom, receivedAt
    if (contactDetail) {
      receivedAt = contactDetail.insertTime
    }
    if (contact) {
      const { email, name } = contact
      mailFrom = { email, name }
    }

    return { mailFrom, receivedAt }
  }, [contact, contactDetail])

  function onAccept(email) {
    dispatch(approveSender(email))
  }

  function onAcceptAndMoveTo(email, split) {
    dispatch(approveAndMoveTo(email, split))
  }

  function onBlock(isBlockSender: boolean, email: string) {
    if (isBlockSender) {
      dispatch(showBatchBlockPrompt([email]))
    } else {
      dispatch(
        showModal({
          key: modalTypes.blockDomain,
          props: {
            email,
            domain: getDomainByEmail(email),
          },
        })
      )
    }
  }

  useEffect(() => {
    const pendingEmails = pending.flatMap(item => item.emails)
    const isPending = pendingEmails.some(item => item.email === email)
    if (isOpen && !isPending) {
      toggle()
    }
  }, [isOpen, pending])

  useEffect(() => {
    setThreadIndex(0)
    // Refresh pending threads each time open the modal
    if (!isNil(email) && isOpen) {
      dispatch(fetchPendingThreads(email))
    }
  }, [email, isOpen])

  return (
    <>
      <DetailModal isOpen={isOpen} hideModal={toggle}>
        <div className="flex h-screen">
          <div
            className="z-10 flex flex-col flex-1 dark:shadow-deep-r"
            style={{
              boxShadow: isDarkTheme
                ? undefined
                : '0 0px 20px rgba(0,0,0,.035), 0 0px 20px rgba(0,0,0,.035)',
              width: 'calc(100% - 400px)',
            }}
          >
            {isNil(thread) ? (
              <EmptyBody mailFrom={mailFrom} receivedAt={receivedAt} />
            ) : (
              <>
                <ThreadHelmet threadId={thread.id} />
                <Body
                  threadId={thread.id}
                  subject={thread.subject}
                  hideModal={toggle}
                  pagination={
                    <Pagination
                      current={threadIndex}
                      total={pendingContactThreads.length}
                      onChange={setThreadIndex}
                    />
                  }
                />
              </>
            )}
            <Actions
              disabled={contactUpdating}
              onAccept={() => onAccept(email)}
              onBlock={isBlockSender => onBlock(isBlockSender, email)}
              onMoveToSplit={split => onAcceptAndMoveTo(email, split)}
            />
          </div>
          <div className="z-0 overflow-y-auto" style={{ width: 400 }}>
            {!isNil(contact) && (
              <SimpleContactDetail
                name={contact.name}
                email={contact.email}
                selectedThread={thread ? thread.id : undefined}
              />
            )}
          </div>
        </div>
      </DetailModal>
    </>
  )
})

export { default as ContactsGalleryModal } from './GalleryModal'
