// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'
import type {
  State,
  CreateUploadRequest,
  CreateUploadSuccess,
  CreateUploadFailure,
  UploadPartSuccess,
  FetchUploadSuccess,
  UploadPartFailure,
  UploadPartRequest,
  DeleteUploadRequest,
  LargeAttachmentActions,
  RemoveUpload,
  AddUploads,
  UpdateUpload,
  StopUploads,
  UpdateUploads,
} from './types'
import { fetchThreadActions, batchGetActions } from 'core/messages/actions'
import { fetchDraftActions, saveDraftActions } from '../compose/actions'
import type { MessageListSuccess, BatchGetSuccess } from 'core/messages/types'
import type { FetchDraftSuccess } from 'core/compose/types'
import { largeAttachmentStatus } from '@edison/webmail-ui/utils/constants'

export const initialState: State = {}

const reducer = createReducer<State, LargeAttachmentActions>(initialState, {
  [actions.addUploads.toString()]: addUploads,
  [actions.updateUpload.toString()]: updateUpload,
  [actions.createUploadActions.request.toString()]: createUploadRequest,
  [actions.createUploadActions.success.toString()]: createUploadSuccess,
  [actions.createUploadActions.failure.toString()]: createUploadFailure,
  [actions.uploadPartActions.success.toString()]: uploadPart,
  [actions.uploadPartActions.failure.toString()]: uploadPartFailure,
  [actions.uploadPartActions.request.toString()]: uploadPartRequest,
  [actions.deleteUploadActions.request.toString()]: deleteUploadRequest,
  [actions.fetchUploadActions.success.toString()]: fetchUpload,
  [actions.removeUploads.toString()]: removeUploads,

  [actions.removeUpload.toString()]: removeUpload,
  [actions.updateUploads.toString()]: updateUploads,
  [fetchThreadActions.success.toString()]: fetchThread,
  [batchGetActions.success.toString()]: batchGetMessages,
  [fetchDraftActions.success.toString()]: fetchDraft,
  [saveDraftActions.success.toString()]: saveDraft,
})

function updateUploads(state: State, action: UpdateUploads): State {
  const uploads = action.payload
  const obj = {}
  uploads
    .filter(item => state[item.uuid])
    .forEach(item => (obj[item.uuid] = { ...state[item.uuid], ...item }))

  return {
    ...state,
    ...obj,
  }
}
function addUploads(state: State, action: AddUploads): State {
  const uploads = action.payload
  return {
    ...state,
    ...uploads.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function updateUpload(state: State, action: UpdateUpload): State {
  const { uuid, ...otherProps } = action.payload
  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      ...otherProps,
    },
  }
}

function fetchUpload(state: State, action: FetchUploadSuccess): State {
  const { uuid, status, scanStatus } = action.payload

  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      status,
      scanStatus,
    },
  }
}
function createUploadRequest(state: State, action: CreateUploadRequest): State {
  const { uuid } = action.payload
  if (!state[uuid] || state[uuid].status === largeAttachmentStatus.DELETING)
    return state

  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      error: false,
    },
  }
}
function createUploadSuccess(state: State, action: CreateUploadSuccess): State {
  const { uuid, aid, chunkSize } = action.payload
  if (!state[uuid] || state[uuid].status === largeAttachmentStatus.DELETING)
    return state

  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      aid,
      chunkSize,
      parts: [],
      status: 'UPLOADING',
    },
  }
}

function createUploadFailure(state: State, action: CreateUploadFailure): State {
  const { uuid } = action.payload
  if (!state[uuid]) return state
  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      error: true,
    },
  }
}

function uploadPart(state: State, action: UploadPartSuccess): State {
  const { uuid, hash } = action.payload
  if (!state[uuid] || state[uuid].status === largeAttachmentStatus.DELETING)
    return state
  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      parts: state[uuid].parts.concat({
        seq: state[uuid].parts.length + 1,
        hash,
      }),
      chunkLoaded: 0,
    },
  }
}

function deleteUploadRequest(state: State, action: DeleteUploadRequest): State {
  return state
}

function uploadPartFailure(state: State, action: UploadPartFailure): State {
  const { uuid } = action.payload
  const attachment = state[uuid]
  if (!attachment) return state
  return {
    ...state,
    [uuid]: {
      ...attachment,
      error: true,
    },
  }
}

function uploadPartRequest(state: State, action: UploadPartRequest): State {
  const { uuid, ...otherProps } = action.payload
  const attachment = state[uuid]
  if (!attachment) return state

  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      ...otherProps,
      chunkLoaded: 0,
      error: false,
    },
  }
}

function fetchThread(state: State, action: MessageListSuccess) {
  const { largeAttachments } = action.payload

  return {
    ...state,
    ...largeAttachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function batchGetMessages(state: State, action: BatchGetSuccess) {
  const { largeAttachments } = action.payload

  return {
    ...state,
    ...largeAttachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function fetchDraft(state: State, action: FetchDraftSuccess) {
  const { largeAttachments } = action.payload

  return {
    ...state,
    ...largeAttachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function saveDraft(state: State, action) {
  const { addedLargeAttachments, largeAttachments } = action.payload
  const aidToAttachment = {}
  largeAttachments.forEach(item => (aidToAttachment[item.aid] = item))

  const entities = addedLargeAttachments.reduce(
    (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
    {}
  )
  // sync messageheadid and url state make fetch preview success
  for (let uuid in state) {
    if (aidToAttachment[state[uuid].aid]) {
      state[uuid] = { ...state[uuid], ...aidToAttachment[state[uuid].aid] }
    }
  }

  return {
    ...state,
    ...entities,
  }
}

function removeUpload(state: State, action: RemoveUpload) {
  const uuid = action.payload
  return {
    ...state,
    [uuid]: {
      ...state[uuid],
      status: largeAttachmentStatus.DELETING,
    },
  }
}

function removeUploads(state: State, action: StopUploads) {
  const uuids = action.payload
  return {
    ...state,
    ...uuids
      .map(uuid => ({
        ...state[uuid],
        status: largeAttachmentStatus.DELETING,
      }))
      .reduce((prev, curr) => ({ ...prev, [curr.uuid]: curr }), {}),
  }
}

export default reducer
