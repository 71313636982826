import EmailSelect from '@edison/webmail-ui/components/EmailSelect'
import { AccountAvatar } from '@edison/webmail-ui/components/Retrofit'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import { getAuth } from 'core/auth/selectors'
import { debouncedSaveDraft, updateDraft } from 'core/compose/actions'
import { getDraftFromEmail, isDraftDisabled } from 'core/compose/selectors'
import { getAliases, getCurrentDomain } from 'core/custom-domains/selectors'
import { getActiveAccount, getActivedAccounts } from 'core/retrofit/selectors'
import {
  getSenderNameSelector,
  isAutoSelectEmail,
} from 'core/settings/selectors'
import sortBy from 'lodash/sortBy'
import React, { forwardRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { providerLogos } from 'utils/constants'
import InputContainer from './InputContainer'

const autoSelectEmail = {
  name: '',
  email: 'Automatically select best account',
  avatar: '',
}

export const useFromOptions = (needAutoSelectEmail = false) => {
  const senderName = useSelector(getSenderNameSelector)
  const aliases = useSelector(getAliases)
  const domain = useSelector(getCurrentDomain)
  const accounts = useSelector(getActivedAccounts)
  const { t } = useTranslation()
  const auth = useSelector(useMemo(() => getAuth(), []))
  return useMemo(() => {
    const emails = aliases.length
      ? aliases.map(email => {
          const currDomain = email.split('@')[1]

          return {
            name: senderName || email,
            email,
            avatar: (
              <AuthEmailAvatar
                name={senderName || email}
                email={email}
                className="!h-8 !w-8"
              />
            ),
            insertTime:
              domain && domain === currDomain ? domain.insertTime : -1,
          }
        })
      : [
          {
            name: senderName || auth.user,
            email: auth.user,
            avatar: (
              <AuthEmailAvatar
                name={senderName || auth.user}
                email={auth.user}
                className="!h-8 !w-8"
              />
            ),
            insertTime: -1,
          },
        ]

    const results = emails.concat(
      accounts.map(item => ({
        name: senderName || item.emailAddress,
        email: item.emailAddress,
        avatar: (
          <AccountAvatar
            srcs={[
              providerLogos[item.connectionType] || t('onmail-avatar.png'),
            ]}
            className="!h-8 !w-8"
          />
        ),
        insertTime: item.insertTime,
      }))
    )
    return !needAutoSelectEmail
      ? sortBy(results, 'insertTime')
      : [autoSelectEmail, ...sortBy(results, 'insertTime')]
  }, [senderName, aliases, accounts, needAutoSelectEmail, auth.user])
}
export default forwardRef(({ draftId }, ref) => {
  const email = useSelector(
    useMemo(() => getDraftFromEmail(draftId), [draftId])
  )
  const isAutoSelect = useSelector(isAutoSelectEmail)
  const activeAccount = useSelector(getActiveAccount)
  const auth = useSelector(useMemo(() => getAuth(), []))
  const fromOptions = useFromOptions()
  const defaultValue = activeAccount ? activeAccount?.emailAddress : auth.user
  const [emailValue, setEmailValue] = useState(
    isAutoSelect ? defaultValue : email
  )
  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleChange = value => {
    dispatch(
      updateDraft({
        id: draftId,
        from: value,
      })
    )
    dispatch(debouncedSaveDraft(draftId))
    setEmailValue(value)
  }
  return (
    <InputContainer
      label={t('compose.label.from')}
      labelClassNames="capitalize"
      htmlFor={`${draftId}-from`}
    >
      <EmailSelect
        value={emailValue}
        disabled={disabled}
        id={`${draftId}-from`}
        options={fromOptions}
        onChange={handleChange}
      />
    </InputContainer>
  )
})
