// @flow
import { createSelector } from 'reselect'

import type { State } from 'types/state'

export const getPreviewsState = (state: State) => state.previews

export const getAttachmentPreviewsState = (state: State) =>
  state.previews.attachments
export const getLargeAttachmentPreviewsState = (state: State) =>
  state.previews.largeAttachments

export const getAttachmentPreview = (id, size) => {
  return createSelector(getPreviewsState, state =>
    size ? state[`attachments_${size}`][id] : state.attachments[id]
  )
}

export const getLargeAttachmentPreview = id => {
  return createSelector(
    getLargeAttachmentPreviewsState,
    previews => previews[id] || ''
  )
}
