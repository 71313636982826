import React, { useState } from 'react'
import { AttachmentsHeader } from '@edison/webmail-ui/components/AttachmentTabList'
import AttachmentTabList from './AttachmentTabList'
import { SimpleHelmet } from 'screens/Helmets'
import AccountMenu from '../Main/components/AccountMenu'

export default function index() {
  const [query, setQuery] = useState('')
  return (
    <>
      <SimpleHelmet title="Attachments" />
      <AttachmentsHeader
        onKeywordChange={e => setQuery(e.target.value)}
        avatar={<AccountMenu />}
      />
      <div className="flex-grow h-full">
        <AttachmentTabList query={query.trim()} />
      </div>
    </>
  )
}
