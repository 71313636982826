// @flow
import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ViewDNSRecordModal, {
  DNSRecordValue,
  DNSRecordRestricted,
} from '@edison/webmail-ui/components/DomainDNS/ViewDNSRecordModal'
import * as customDomains from 'core/custom-domains'
import { useDnsRecords } from 'core/custom-domains/hooks'
import { useToast, toastVariants } from 'common/toasts'
import { toastTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

type Props = {
  index: number,
  isOpen: boolean,
  toggle: () => mixed,
}

const View = ({ index, isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const { t } = useTranslation()

  const records = useSelector(customDomains.selectors.getDnsRecords)
  const writeRecords = useSelector(customDomains.selectors.getDnsWriteRecords)
  const isLoading = useSelector(customDomains.selectors.updateDnsRecordsLoading)
  const { fields } = useDnsRecords()
  const { showToast } = useToast(toastTypes.notification)
  const { record, allowDelete } = records[index] || {}

  const { values, type } = useMemo(() => {
    let type = '',
      values = []

    if (record) {
      type = record.type
      const recordFields = fields[type]
      values = recordFields.map(field => ({
        label: t(`settings.customDomain.records.labels.${type}.${field}`),
        value: record[field],
      }))
    }
    return { values, type }
  }, [record])

  const onDelete = () => {
    if (!allowDelete) {
      return
    }

    dispatch(
      customDomains.actions.updateDnsRecords(
        writeRecords.filter((_, i) => i !== index)
      )
    ).then(success => {
      if (success) {
        toggle()
        showToast('DNS records updated successfully.', toastVariants.success)
      }
    })
  }

  return (
    <ViewDNSRecordModal
      isOpen={isOpen}
      allowDelete={allowDelete}
      type={type}
      isLoading={isLoading}
      toggle={toggle}
      onDelete={onDelete}
      warning={!allowDelete ? <DNSRecordRestricted /> : undefined}
    >
      {values.map(({ label, value }) => (
        <DNSRecordValue key={value} label={label}>
          {value}
        </DNSRecordValue>
      ))}
    </ViewDNSRecordModal>
  )
}

View.displayName = 'ViewDNSRecordModal'

export default View
