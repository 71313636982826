// @flow
export const settingActionTypes: {
  SettingsListRequest: 'CALENDAR_SETTINGS_LIST_REQUEST',
  SettingsListRequestSuccess: 'CALENDAR_SETTINGS_LIST_SUCCESS',
  SettingsListRequestFailure: 'CALENDAR_SETTINGS_LIST_FAILURE',
  SettingsGetRequest: 'CALENDAR_SETTINGS_GET_REQUEST',
  SettingsGetRequestSuccess: 'CALENDAR_SETTINGS_GET_SUCCESS',
  SettingsGetRequestFailure: 'CALENDAR_SETTINGS_GET_FAILURE',
  SettingsUpdateRequest: 'CALENDAR_SETTINGS_UPDATE_REQUEST',
  SettingsUpdateRequestSuccess: 'CALENDAR_SETTINGS_UPDATE_SUCCESS',
  SettingsUpdateRequestFailure: 'CALENDAR_SETTINGS_UPDATE_FAILURE',
} = {
  SettingsListRequest: 'CALENDAR_SETTINGS_LIST_REQUEST',
  SettingsListRequestSuccess: 'CALENDAR_SETTINGS_LIST_SUCCESS',
  SettingsListRequestFailure: 'CALENDAR_SETTINGS_LIST_FAILURE',
  SettingsGetRequest: 'CALENDAR_SETTINGS_GET_REQUEST',
  SettingsGetRequestSuccess: 'CALENDAR_SETTINGS_GET_SUCCESS',
  SettingsGetRequestFailure: 'CALENDAR_SETTINGS_GET_FAILURE',
  SettingsUpdateRequest: 'CALENDAR_SETTINGS_UPDATE_REQUEST',
  SettingsUpdateRequestSuccess: 'CALENDAR_SETTINGS_UPDATE_SUCCESS',
  SettingsUpdateRequestFailure: 'CALENDAR_SETTINGS_UPDATE_FAILURE',
}
