// @flow
import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import get from 'lodash/get'

import { checkAndFetchThread } from 'core/messages/actions'
import { getThreadPendingMessages } from 'core/messages/selectors'

import Launch from '@material-ui/icons/Launch'
import Warning from '@material-ui/icons/Error'
import MissingEmailBody from '@edison/webmail-ui/components/MissingEmailBody'
import { Prompt as SuspiciousDetectingPrompt } from '@edison/webmail-ui/components/SecurityMessage/SuspiciousDetecting'
import { IconAvatar } from '@edison/webmail-ui/components/Avatar'
import {
  Attachments,
  ICSEvents,
  MessageLayout,
  UntrimedRightContainer,
  UntrinedRecipients,
} from 'screens/ThreadDetail/components/MessageItem'
import EmailBody from 'screens/ThreadDetail/components/EmailBody'
import { AuthEmailAvatar } from 'common/AuthAvatar'

import Subject from 'common/ThreadList/Subject'

import type { Node } from 'react'
import type { Dispatch } from 'types/redux'

type Props = {
  threadId: string,
  subject: string,
  pagination: Node,
  hideModal: () => void,
}

export default ({ threadId, subject, pagination, hideModal }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const pendingMessagesSelector = useMemo(
    () => getThreadPendingMessages(threadId),
    [threadId]
  )

  const pendingMessages = useSelector(pendingMessagesSelector)

  useEffect(() => {
    if (!isNil(threadId)) {
      dispatch(checkAndFetchThread(threadId))
    }
  }, [threadId])

  return (
    <>
      <div className="z-10 flex items-baseline justify-between p-4 border-b text-dark">
        <div
          className="text-xl font-semibold pr-3 inline align-middle"
          style={{ wordBreak: 'break-word' }}
        >
          <Subject subject={subject} />
        </div>
        {pagination}
      </div>
      <div className="flex-1 w-full overflow-auto">
        {pendingMessages.map((message, index) => (
          <MessageItem
            key={message.id}
            enableTrim={index !== pendingMessages.length - 1}
            message={message}
            hideModal={hideModal}
          />
        ))}
      </div>
    </>
  )
}

export function MessageItem({
  message,
  hideModal,
  viewport = undefined,
  ...otherProps
}) {
  const attachments = <Attachments message={message} />
  const ssFlag = get(message, 'ssFlag', false)

  const avatarNode = useMemo(() => {
    if (ssFlag) {
      return <IconAvatar icon={<Warning style={{ fontSize: '3.5rem' }} />} />
    }
    return (
      <AuthEmailAvatar email={message.from.email} name={message.from.name} />
    )
  }, [message])

  return (
    <MessageLayout
      {...otherProps}
      avatar={avatarNode}
      right={
        <UntrimedRightContainer
          message={message}
          mailFrom={<span>{message.from.name || message.from.email}</span>}
          recipients={
            <UntrinedRecipients message={message} onSelectRecipient={noop} />
          }
        />
      }
      body={
        <div className="px-4">
          <ICSEvents
            messageExtendInfo={message.extendInfo}
            onViewInCalendar={hideModal}
            onAddToCalendar={hideModal}
          />
          {ssFlag && <SuspiciousDetectingPrompt />}
          <div className="pb-4">
            <EmailBody message={message} viewport={viewport} />
          </div>

          {attachments}
        </div>
      }
    />
  )
}

export const EmptyBody = ({
  mailFrom,
  receivedAt,
}: {
  receivedAt?: number,
  mailFrom?: { email: string, name: string },
}) => {
  const { t } = useTranslation()
  const mockMessage = useMemo(
    () => ({
      date: receivedAt,
    }),
    [receivedAt]
  )

  const subject = useMemo(
    () => (
      <div className="z-10 flex items-baseline justify-between p-4 border-b text-dark">
        <div
          className="text-xl font-semibold pr-3 inline align-middle"
          style={{ wordBreak: 'break-word' }}
        >
          <Subject subject={t('approveSender.thread.error')} />
        </div>
      </div>
    ),
    []
  )

  const body = useMemo(
    () => (
      <div className="p-4">
        <MissingEmailBody />
      </div>
    ),
    []
  )

  return (
    <>
      {subject}
      <div className="flex-1 w-full overflow-auto">
        {isNil(mailFrom) ? (
          body
        ) : (
          <MessageLayout
            messageId="mock-message"
            avatar={
              <AuthEmailAvatar email={mailFrom.email} name={mailFrom.name} />
            }
            enableTrim={false}
            right={
              <UntrimedRightContainer
                message={mockMessage}
                mailFrom={<span>{mailFrom.name || mailFrom.email}</span>}
                recipients={<div className="text-gray-light">to Me</div>}
              />
            }
            body={body}
          />
        )}
      </div>
    </>
  )
}

type GallerySubjectProps = {
  subject: string,
  threadsCount: number,
  onClick: () => void,
}

export const GallerySubject = ({
  subject,
  threadsCount,
  onClick,
}: GallerySubjectProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center px-4">
      <div title={subject} className="text-2xl font-bold flex-1 truncate">
        {subject || t('thread.subject.none')}
      </div>
      {threadsCount > 1 && (
        <>
          <div className="text-sm text-gray-light rounded-full bg-secondary px-4 py-1 mx-4">
            {t('contactGallery.subject.pendingCount', { count: threadsCount })}
          </div>
          <button className="text-gray-light" onClick={onClick}>
            <Launch />
          </button>
        </>
      )}
    </div>
  )
}
