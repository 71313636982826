// @flow
import { createReducer } from 'utils/redux'
import { clearCalendarUserDataAction } from './actions-common'
import { ReduxSettingsResult } from './modal-redux-settings-result'
import {
  fetchSettingsAction,
  fetchSettingAction,
  updateSettingsAction,
} from './actions-settings'
import type {
  SettingsState,
  SettingsActions,
  SettingsListRequestSuccess,
  SettingsGetRequestSuccess,
} from './types-settings'
import type { ClearCalendarUserData } from './types-common'

export const initSettingsState: SettingsState = new ReduxSettingsResult()

const fetchSettingSuccess = (
  state: SettingsState,
  action: SettingsGetRequestSuccess
) => {
  const newSettings = ReduxSettingsResult.updateSettingAndReturnNewSettingsIfNew(
    state,
    action.payload
  )
  if (newSettings) {
    newSettings.lastUpdateTime = Date.now() / 1000
    return newSettings
  }
  if (state.lastUpdateTime === 0) {
    state.lastUpdateTime = Date.now() / 1000
  }
  return state
}

function fetchSettingsSuccess(
  state: SettingsState,
  action: SettingsListRequestSuccess
) {
  const oldSettings = state
  const newSettings = ReduxSettingsResult.returnNewIfDiff(
    oldSettings,
    action.payload
  )
  if (newSettings) {
    newSettings.lastUpdateTime = Date.now() / 1000
    return newSettings
  }
  if (state.lastUpdateTime === 0) {
    state.lastUpdateTime = Date.now() / 1000
  }
  return state
}
const onClearUserData = (
  state: SettingsState,
  action: ClearCalendarUserData
) => {
  return initSettingsState
}
const reducer = createReducer<SettingsState, SettingsActions>(
  initSettingsState,
  {
    [fetchSettingsAction.success.toString()]: fetchSettingsSuccess,
    [fetchSettingAction.success.toString()]: fetchSettingSuccess,
    [updateSettingsAction.success.toString()]: fetchSettingsSuccess,
    [clearCalendarUserDataAction.toString()]: onClearUserData,
  }
)

export default reducer
