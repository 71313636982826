// @flow
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import { RecipientContainer } from '@edison/webmail-ui/components/EmailInput'
import { getFilteredContacts } from 'core/contacts/selectors'
import InputContainer from '../InputContainer'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import { RESPONSE_TYPE_FORWARD, RESPONSE_TYPE_NONE } from 'utils/constants'
import Tooltip from '@edison/webmail-ui/components/Tooltip'
import './index.css'

const filteredContactsSelector = getFilteredContacts()

export default ({
  values,
  onChange,
  responseType,
  disabled,
  onSelectRecipient,
  recipientStatusNode,
}: Props) => {
  const { t } = useTranslation()
  const [{ focus, expand }, setFocusAndExpand] = useState({
    focus: responseType === RESPONSE_TYPE_FORWARD,
    expand: !!(values.cc.length || values.bcc.length),
  })
  const isFocusRef = useRef(false)
  const ccInputRef = useRef()
  const isExpand = useMemo(() => !!(values.cc.length || values.bcc.length), [
    values,
  ])
  const { to = [], replyTo = [] } = values

  const contacts = useSelector(filteredContactsSelector)

  const getRecipientAvatar = useCallback(
    ({ email, name }) => (
      <AuthEmailAvatar className="!w-8 !h-8" email={email} name={name} />
    ),
    []
  )

  useEffect(() => {
    setFocusAndExpand(({ focus, expand }) => ({
      focus: responseType === RESPONSE_TYPE_FORWARD,
      expand,
    }))
  }, [responseType])

  function handleFocus(e) {
    isFocusRef.current = true
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        setFocusAndExpand(({ expand }) => ({
          focus: isFocusRef.current,
          expand: isFocusRef.current
            ? expand || isExpand
            : responseType === RESPONSE_TYPE_NONE
            ? isExpand
            : false,
        }))
      }
    })
  }
  function handleBlur(e) {
    isFocusRef.current = false
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        setFocusAndExpand(({ expand }) => ({
          focus: isFocusRef.current,
          expand: isFocusRef.current
            ? expand || isExpand
            : responseType === RESPONSE_TYPE_NONE
            ? isExpand
            : false,
        }))
      }
    })
  }

  const getRecipients = () => {
    if (replyTo && replyTo.length) {
      return replyTo
    } else {
      return to ? to : []
    }
  }

  return (
    <div
      className="flex-1 outline-none overflow-auto"
      style={{ maxHeight: '50vh' }}
      tabIndex={focus ? -1 : 0}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {responseType !== RESPONSE_TYPE_NONE && (!focus || disabled) ? (
        recipientStatusNode
      ) : (
        <>
          <InputContainer
            label={t('compose.label.to')}
            labelClassNames="capitalize"
            htmlFor="to"
          >
            <div className="flex items-end">
              <RecipientContainer
                id="to"
                getRecipientAvatar={getRecipientAvatar}
                autoFocus={!to.length || focus}
                recipients={getRecipients()}
                contacts={contacts}
                onChangeRecipients={newRecipients => {
                  onChange({
                    ...values,
                    to: newRecipients,
                  })
                }}
                onSelectRecipient={onSelectRecipient}
              />

              {!expand && (
                <Tooltip title={t('button.addCcBccRecipients')}>
                  <IconButton
                    tabIndex="-1"
                    size="small"
                    onMouseDown={e => {
                      setFocusAndExpand({ focus: true, expand: true })
                      setTimeout(() => {
                        ccInputRef.current && ccInputRef.current.focus()
                      })
                    }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </InputContainer>
          {expand &&
            ['cc', 'bcc'].map((key, index) => {
              const value = get(values, key, [])
              return (
                <InputContainer
                  key={key}
                  label={t(`compose.label.${key}`)}
                  labelClassNames="capitalize"
                  htmlFor={key}
                >
                  <RecipientContainer
                    id={key}
                    ref={node => key === 'cc' && (ccInputRef.current = node)}
                    getRecipientAvatar={getRecipientAvatar}
                    recipients={value}
                    contacts={contacts}
                    onChangeRecipients={newRecipients =>
                      onChange({
                        ...values,
                        [key]: newRecipients,
                      })
                    }
                    onSelectRecipient={onSelectRecipient}
                  />
                </InputContainer>
              )
            })}
        </>
      )}
    </div>
  )
}
