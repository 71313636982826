// @flow
import type {
  EventsState,
  EventsActions,
  FetchEventsRequestSuccess,
  DeleteEventRequest,
  DeleteEventRequestFailure,
  AddSimpleEventRequestSuccess,
  UpdateEventRequest,
  UpdateSingleEventInstanceRequest,
  UpdateSingleEventInstanceRequestFailure,
  AddEventRequestSuccess,
  DeleteSingleEventInstanceRequest,
  DeleteSingleEventInstanceRequestFailure,
  SelectUIScopeEvent,
  DeselectUIScopeEvent,
  CRUDEAPISuccess,
  UpdateUIScopeEventRange,
  UpdateAndAddEventRequest,
  UpdateAndAddEventRequestFailure,
  AddEventRequestFailure,
  DeselectICSEvent,
  SelectICSEvent,
  FetchEventOrInstanceRequestSuccess,
  UpdateEventSimpleDataRequest,
  FetchICSEventDataSuccess,
  UpdateEventOrInstanceAttendeeRequest,
  UpdateEventOrInstanceAttendeeFailure,
  UpdateEventRequestSuccess,
  UpdateEventSimpleDataSuccess,
  UpdateEventRequestFailure,
  UpdateSingleEventInstanceRequestSuccess,
  UpdateEventOrInstanceAttendeeSuccess,
} from './types-events'
import type { ClearCalendarUserData } from './types-common'
import { createReducer } from 'utils/redux'
import { ReduxEvents } from './modal-redux-events'
import {
  addEventRequestAction,
  addSimpleEventAction,
  deleteEventRequestAction,
  deleteSingleEventInstanceRequestAction,
  deselectICSEventAction,
  deselectUIScopeEventAction,
  fetchEventOrInstanceAction,
  fetchICSEventDataAction,
  fetchUIScopeEventsByCalendarIdAction,
  selectICSEventAction,
  selectUIScopeEventAction,
  updateAndAddEventAction,
  updateEventOrInstanceAttendeeAction,
  updateEventRequestAction,
  updateEventSimpleDataAction,
  updateSingleEventInstanceRequestAction,
  updateUIScopeEventRangeAction,
} from './actions-events'
import { clearCalendarUserDataAction } from './actions-common'
import { UIEventsRange } from './modal-ui-events-range'
import { recurringEventModifyType } from './constants-events'
import { ReduxEvent } from './modal-redux-event'
import { ReduxICSEventsData } from './modal-redux-ics-events'
import moment from 'moment'
import { generateNearestInstanceByRecurringEvent } from '../../utils/calendar'
import type { ICSEventData } from './modal-redux-ics-events'
import { UpdateAndAddEventRequestSuccess } from './types-events'

export const initEventsState: EventsState = {
  eventsData: new ReduxEvents(),
  uiEventsRange: new UIEventsRange(),
  selectedUIScopeEvent: null,
  selectedICSEvent: null,
  icsEventsData: new ReduxICSEventsData(),
}
const fetchICSEventDataSuccess = (
  state: EventsState,
  action: FetchICSEventDataSuccess
) => {
  const { event, instances } = action.payload.icsData
  const calendarId = action.payload.calendarId
  const icsEventKey = `${calendarId}:${action.payload.eventOrInstanceId}:${action.payload.startTime}`
  const icsEventsRedux = state.icsEventsData
  const reduxEvents = state.eventsData
  let changed = false
  const oldData = icsEventsRedux.get(icsEventKey)
  if (!event && instances[0]) {
    const instance = new ReduxEvent({ ...instances[0], calendarId })
    if (!oldData || oldData.updated < instance.updated) {
      changed = true
      icsEventsRedux.set(icsEventKey, {
        calendarId,
        eventId: instance.eventId,
        instanceId: instance.instanceId,
        data: instance,
        updated: instance.updated,
      })
    }
    const reduxInstanceData = reduxEvents.getInstanceById({
      calendarId: instance.calendarId,
      eventId: instance.eventId,
      instanceId: instance.instanceId,
    })
    if (!reduxInstanceData || reduxInstanceData.updated < instance.updated) {
      changed = true
      reduxEvents.updateInstance({
        calendarId,
        eventId: instance.eventId,
        instance,
        appendToUndo: false,
        isUndo: false,
      })
    }
  }
  if (event) {
    const eventRedux = new ReduxEvent({ ...event, calendarId })
    const oldEvent = reduxEvents.getEventById({
      calendarId,
      eventId: eventRedux.eventId,
    })
    if (!oldEvent) {
      changed = true
      reduxEvents.addEventOrInstance({
        calendarId,
        eventId: eventRedux.eventId,
        eventOrInstanceData: eventRedux,
        appendToUndo: false,
        isUndo: false,
      })
    } else if (oldEvent.updated < eventRedux.updated) {
      changed = true
      if (eventRedux.isRecurringEvent) {
        reduxEvents.updateRecurringEvent({
          calendarId,
          event: eventRedux,
          appendToUndo: false,
          isUndo: false,
        })
      } else {
        reduxEvents.updateEvent({
          calendarId,
          event: eventRedux,
          appendToUndo: false,
          isUndo: false,
        })
      }
    }
    if (!eventRedux.isRecurringEvent) {
      if (!oldData || oldData.updated < eventRedux.updated) {
        changed = true
        icsEventsRedux.set(icsEventKey, {
          calendarId,
          eventId: eventRedux.eventId,
          data: eventRedux,
          updated: eventRedux.updated,
        })
      }
    } else if (event.cancelled) {
      changed = true
      icsEventsRedux.set(icsEventKey, {
        calendarId,
        eventId: eventRedux.eventId,
        data: eventRedux,
        updated: eventRedux.updated,
      })
    } else {
      const start = moment.unix(action.payload.startTime)
      changed = true
      const ret = generateNearestInstanceByRecurringEvent(
        eventRedux,
        instances.map(item => {
          const redux = new ReduxEvent({
            ...item,
            calendarId,
            recurrence: event.recurrence,
          })
          reduxEvents.updateInstance({
            calendarId,
            eventId: redux.eventId,
            instance: redux,
            appendToUndo: false,
            isUndo: false,
          })
          return redux
        }),
        start
      )
      icsEventsRedux.set(icsEventKey, {
        calendarId,
        eventId: ret.eventId,
        instanceId: ret.instanceId,
        needToChangeEventStatus: true,
        data: ret,
        updated: ret.updated,
      })
    }
  }
  if (changed) {
    return {
      ...state,
      icsEventsData: icsEventsRedux.clone(),
      eventsData: ReduxEvents.clone(reduxEvents),
    }
  }
  return state
}
const onUpdateEventOrInstanceAttendeeRequest = (
  state: EventsState,
  action: UpdateEventOrInstanceAttendeeRequest
) => {
  const { eventOrInstance, attendee } = action.payload
  const { calendarId, eventId } = eventOrInstance
  const reduxEvents = state.eventsData
  const icsEventData = state.icsEventsData
  if (!eventOrInstance.isInstance) {
    const event = reduxEvents.getEventById({ calendarId, eventId })
    if (event) {
      event.updateAttendee(attendee)
      event.isSoftDelete = false
      if (event.isTentative) {
        event.confirm()
      }
      reduxEvents.updateEvent({
        calendarId,
        event,
        appendToUndo: true,
        isUndo: false,
      })
      const cachedICSEventDatas = icsEventData.getByPartialCacheKey(
        `${event.calendarId}:${event.eventId}`
      )
      cachedICSEventDatas.forEach(
        (data: { key: string, cache: ICSEventData }) => {
          const { key, cache: icsEvent } = data
          const eventData = icsEvent.data.clone()
          eventData.isSoftDelete = false
          if (eventData.isTentative) {
            eventData.confirm()
          }
          if (!eventData.differentFromEvent) {
            eventData.updateAttendee(attendee)
          }
          icsEvent.updated = eventData.updated
          icsEvent.data = eventData
          icsEventData.set(key, icsEvent)
        }
      )
      return {
        ...state,
        icsEventsData: icsEventData.clone(),
        eventsData: ReduxEvents.clone(reduxEvents),
      }
    }
  } else {
    if (eventOrInstance) {
      eventOrInstance.updateAttendee(attendee)
      eventOrInstance.isSoftDelete = false
      reduxEvents.updateInstance({
        calendarId,
        eventId,
        instance: eventOrInstance,
        appendToUndo: true,
        isUndo: false,
      })
      const partialKey = `${eventOrInstance.calendarId}:${eventOrInstance.id}`
      const cacheData = {
        calendarId: eventOrInstance.calendarId,
        eventId: eventOrInstance.eventId,
        instanceId: eventOrInstance.instanceId,
        data: eventOrInstance,
        updated: eventOrInstance.updated,
      }
      icsEventData.updateCacheByPartialKey(partialKey, cacheData)
      return {
        ...state,
        icsEventsData: icsEventData.clone(),
        eventsData: ReduxEvents.clone(reduxEvents),
      }
    }
  }
  return state
}
const onUpdateEventOrInstanceAttendeeSuccess = (
  state: EventsState,
  action: UpdateEventOrInstanceAttendeeSuccess
) => {
  const { calendarId, eventId, instanceId, updatedTime } = action.payload
  const reduxEvents = state.eventsData
  reduxEvents.removeUndo({
    type: 'modify',
    calendarId,
    eventId,
    instanceId,
  })
  if (instanceId) {
    const instance = reduxEvents.getInstanceById({
      calendarId,
      eventId,
      instanceId,
    })
    if (instance && updatedTime) {
      instance.updated = updatedTime
      reduxEvents.updateInstance({
        calendarId,
        eventId,
        instance,
        appendToUndo: false,
        isUndo: false,
      })
    }
  } else {
    const event = reduxEvents.getEventById({ calendarId, eventId })
    if (event && updatedTime) {
      event.updated = updatedTime
      reduxEvents.updateEvent({
        calendarId,
        event,
        appendToUndo: false,
        isUndo: false,
      })
    }
  }
  return {
    ...state,
    eventsData: ReduxEvents.clone(reduxEvents),
  }
}
const onUpdateEventOrInstanceAttendeeFailure = (
  state: EventsState,
  action: UpdateEventOrInstanceAttendeeFailure
) => {
  const { calendarId, eventId, instanceId } = action.payload
  const reduxEvents = state.eventsData
  reduxEvents.performUndo({
    type: 'modify',
    calendarId,
    eventId,
    instanceId,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(reduxEvents),
  }
}
const fetchUIScopeEventsSuccess = (
  state: EventsState,
  action: FetchEventsRequestSuccess
) => {
  const events = action.payload.events
  // const instances = action.payload.instances
  const calendarId = action.payload.calendarId
  const oldEventsState = state.eventsData
  const start = action.payload.start
  const end = action.payload.end
  const currentTimeZone = action.payload.currentTimeZone
  if (
    ReduxEvents.areEventsDataNewer(oldEventsState, {
      events,
      calendarId,
      start,
      end,
      currentTimeZone,
    })
  ) {
    oldEventsState.updateEventsFromAPI({
      calendarId,
      events,
      start,
      end,
      currentTimeZone,
    })
    const newEventsState = ReduxEvents.clone(oldEventsState)
    return {
      ...state,
      eventsData: newEventsState,
    }
  }
  console.debug('No new data, skipping update')
  return state
}
const fetchEventOrInstanceSuccess = (
  state: EventsState,
  action: FetchEventOrInstanceRequestSuccess
) => {
  const { event, instance } = action.payload
  const reduxEvents = state.eventsData
  let updated = false
  if (event) {
    const currentEvent = reduxEvents.getEventById({
      calendarId: event.calendarId,
      eventId: event.eventId,
    })
    if (!currentEvent || currentEvent.updated < event.updated) {
      if (!currentEvent) {
        reduxEvents.addEventOrInstance({
          calendarId: event.calendarId,
          eventId: event.eventId,
          eventOrInstanceData: event,
          isUndo: false,
          appendToUndo: false,
        })
      } else {
        reduxEvents.updateEvent({
          calendarId: event.calendarId,
          event,
          appendToUndo: false,
          isUndo: false,
        })
      }
      updated = true
    }
  }
  if (instance) {
    const currentInstance = reduxEvents.getInstanceById({
      calendarId: instance.calendarId,
      eventId: instance.eventId,
      instanceId: instance.instanceId,
    })
    if (!currentInstance || currentInstance.updated < instance.updated) {
      reduxEvents.updateInstance({
        calendarId: instance.calendarId,
        eventId: instance.eventId,
        instance,
        isUndo: false,
        appendToUndo: false,
      })
      updated = true
    }
  }
  if (updated) {
    return {
      ...state,
      eventsData: ReduxEvents.clone(reduxEvents),
    }
  } else {
    return state
  }
}
const deleteEventOrInstanceRequest = (
  state: EventsState,
  action: DeleteEventRequest | DeleteSingleEventInstanceRequest
) => {
  const eventsRedux = state.eventsData
  const { oldEventOrInstance } = action.payload
  eventsRedux.deleteEventOrInstance({
    eventOrInstance: oldEventOrInstance,
    hardDelete: true,
    appendToUndo: true,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const deleteEventOrInstanceRequestFailed = (
  state: EventsState,
  action: DeleteEventRequestFailure | DeleteSingleEventInstanceRequestFailure
) => {
  const eventsRedux = state.eventsData
  // $FlowFixMe
  const { calendarId, eventId, instanceId } = action.payload
  eventsRedux.performUndo({
    calendarId,
    eventId,
    instanceId,
    type: 'delete',
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const addSimpleEventSuccess = (
  state: EventsState,
  action: AddSimpleEventRequestSuccess
) => {
  const eventsRedux = state.eventsData
  const { calendarId, eventId, data } = action.payload
  eventsRedux.addEventOrInstance({
    calendarId,
    eventId,
    eventOrInstanceData: data,
    appendToUndo: false,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const updateEventOrInstanceRequest = (
  state: EventsState,
  action: UpdateEventRequest | UpdateSingleEventInstanceRequest
) => {
  const eventsRedux = state.eventsData
  const { calendarId, eventId, data } = action.payload
  if (data.isInstance) {
    eventsRedux.updateInstance({
      calendarId,
      eventId,
      instance: data,
      appendToUndo: true,
    })
  } else {
    const oldEvent = eventsRedux.getEventById({ calendarId, eventId })
    if (oldEvent && oldEvent.isRecurringEvent) {
      const oldInstances = eventsRedux.getInstancesByCalendarAndEventId({
        calendarId,
        eventId: data.eventId,
      })
      eventsRedux.updateRecurringEvent({
        calendarId,
        event: data,
        appendToUndo: true,
      })
      if (data.isRecurringEvent) {
        if (data.isAllDayEvent === oldEvent.isAllDayEvent) {
          let newFirstInstanceId = `${
            data.originalEventId
          }_${data.start.toUTCMoment().format('YYYYMMDD')}`
          if (!data.isAllDayEvent) {
            newFirstInstanceId = `${newFirstInstanceId}${data.originalStartTime
              .toUTCMoment()
              .format('[T]HHmmss[Z]')}`
          }
          oldInstances.forEach((instance: ReduxEvent) => {
            if (
              !data.endTimeUnspecified &&
              data.rruleUntil <= instance.start.timestamp
            ) {
              eventsRedux.deleteEventOrInstance({
                eventOrInstance: instance,
                appendToUndo: false,
                hardDelete: true,
                isUndo: false,
              })
              return
            }
            if (
              action.payload.updateType === recurringEventModifyType.all &&
              (!instance.cancelled ||
                newFirstInstanceId === instance.instanceId)
            ) {
              eventsRedux.deleteEventOrInstance({
                eventOrInstance: instance,
                appendToUndo: false,
                hardDelete: true,
                isUndo: false,
              })
              return
            }
            if (action.payload.updateType === recurringEventModifyType.all) {
              instance.mergeEventData(data)
            }
            eventsRedux.updateInstance({
              calendarId,
              eventId: data.eventId,
              instance,
              appendToUndo: false,
            })
          })
        }
        eventsRedux.updateEvent({
          calendarId,
          event: data,
          appendToUndo: false,
          isUndo: false,
        })
      }
    } else {
      eventsRedux.updateEvent({ calendarId, event: data, appendToUndo: true })
    }
  }
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateEventRequestSuccess = (
  state: EventsState,
  action: UpdateEventRequestSuccess
) => {
  const eventsRedux = state.eventsData
  // $FlowFixMe
  const { calendarId, eventId, updatedTime } = action.payload
  const event = eventsRedux.getEventById({ calendarId, eventId })
  if (event && updatedTime) {
    event.updated = updatedTime
    eventsRedux.updateEvent({
      calendarId,
      event,
      appendToUndo: false,
      isUndo: false,
    })
  }
  eventsRedux.removeUndo({
    calendarId,
    eventId,
    type: 'modify',
  })

  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateSingleEventInstanceSuccess = (
  state: EventsState,
  action: UpdateSingleEventInstanceRequestSuccess
) => {
  const eventsRedux = state.eventsData
  const { calendarId, eventId, instanceId, updatedTime } = action.payload
  const instance = eventsRedux.getInstanceById({
    calendarId,
    eventId,
    instanceId,
  })
  if (instance && updatedTime) {
    instance.updated = updatedTime
    eventsRedux.updateInstance({
      calendarId,
      eventId,
      instance,
      appendToUndo: false,
      isUndo: false,
    })
  }
  eventsRedux.removeUndo({
    calendarId,
    eventId,
    instanceId,
    type: 'modify',
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateEventOrInstanceRequestFailed = (
  state: EventsState,
  action: UpdateEventRequestFailure | UpdateSingleEventInstanceRequestFailure
) => {
  const eventsRedux = state.eventsData
  // $FlowFixMe
  const { calendarId, eventId, instanceId } = action.payload
  eventsRedux.performUndo({
    calendarId,
    eventId,
    instanceId,
    type: 'modify',
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateAndAddEventRequest = (
  state: EventsState,
  action: UpdateAndAddEventRequest
) => {
  const eventsRedux = state.eventsData
  const { calendarId, updateEvent, newEvent, breakEventLink } = action.payload
  eventsRedux.updateThisAndFollowingEvents({
    calendarId,
    updateEvent,
    newEvent,
    breakEventLink,
    appendToUndo: true,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateAndAddEventRequestSuccess = (
  state: EventsState,
  action: UpdateAndAddEventRequestSuccess
) => {
  const eventsRedux = state.eventsData
  eventsRedux.removeUndo({ ...action.payload, type: 'updateAndAdd' })
  const {
    calendarId,
    updateEventId,
    newEventId,
    updateEventUpdatedTime,
    newEventUpdatedTime,
  } = action.payload
  const updateEvent = eventsRedux.getEventById({
    calendarId,
    eventId: updateEventId,
  })
  if (updateEvent && updateEventUpdatedTime) {
    updateEvent.updated = updateEventUpdatedTime
    eventsRedux.updateEvent({
      calendarId,
      event: updateEvent,
      appendToUndo: false,
      isUndo: false,
    })
  }
  const newEvent = eventsRedux.getEventById({ calendarId, eventId: newEventId })
  if (newEvent && newEventUpdatedTime) {
    newEvent.updated = newEventUpdatedTime
    eventsRedux.updateEvent({
      calendarId,
      event: newEvent,
      appendToUndo: false,
      isUndo: false,
    })
  }
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onUpdateAndAddEventRequestFail = (
  state: EventsState,
  action: UpdateAndAddEventRequestFailure
) => {
  const eventsRedux = state.eventsData
  eventsRedux.performUndo({ ...action.payload, type: 'updateAndAdd' })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
// const onUpdateRecurringEventAndInstanceRequest = (
//   state: EventsState,
//   action: UpdateRecurringEventAndInstanceRequest
// ) => {
//   const eventsRedux = state.eventsData
//   const { calendarId, updateEvent, newInstance } = action.payload
//   eventsRedux.updateRecurringEventAndSingleInstance({
//     calendarId,
//     updateEvent,
//     newInstance,
//     appendToUndo: true,
//   })
//   return {
//     ...state,
//     eventsData: ReduxEvents.clone(eventsRedux),
//   }
// }
// const onUpdateRecurringEventAndInstanceFailure = (
//   state: EventsState,
//   action: UpdateRecurringEventAndInstanceRequestFailure
// ) => {
//   const eventsRedux = state.eventsData
//   // $FlowFixMe
//   const { calendarId, eventId, instanceId, undoType } = action.payload
//   eventsRedux.performUndo({
//     calendarId,
//     eventId,
//     instanceId,
//     type: undoType,
//   })
//   return {
//     ...state,
//     eventsData: ReduxEvents.clone(eventsRedux),
//   }
// }
const onCRUDEAPISuccess = (state: EventsState, action: CRUDEAPISuccess) => {
  const eventsRedux = state.eventsData
  const { undoType, ...rest } = action.payload
  eventsRedux.removeUndo({ ...rest, type: undoType })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onAddEventRequest = (
  state: EventsState,
  action: AddEventRequestSuccess
) => {
  const eventsRedux = state.eventsData
  const { calendarId, eventData } = action.payload
  const event = new ReduxEvent({ ...eventData, calendarId })
  eventsRedux.addEventOrInstance({
    calendarId,
    eventId: event.eventId,
    eventOrInstanceData: event,
    appendToUndo: true,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onAddEventSuccess = (
  state: EventsState,
  action: AddEventRequestSuccess
) => {
  const eventsRedux = state.eventsData
  const { data } = action.payload

  eventsRedux.addEventOrInstance({
    calendarId: data.calendarId,
    eventId: data.eventId,
    eventOrInstanceData: data,
    appendToUndo: false,
  })
  eventsRedux.removeUndo({
    type: 'add',
    calendarId: data.calendarId,
    eventId: data.eventId,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
const onAddEventRequestFailed = (
  state: EventsState,
  action: AddEventRequestFailure
) => {
  const eventsRedux = state.eventsData
  // $FlowFixMe
  const { calendarId, eventId } = action.payload
  eventsRedux.performUndo({
    calendarId,
    eventId,
    type: 'add',
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsRedux),
  }
}
export const onUpdateEventSimpleDataRequest = (
  state: EventsState,
  action: UpdateEventSimpleDataRequest
) => {
  const eventsState = state.eventsData
  if (action.payload) {
    const event = action.payload
    eventsState.updateEventSimpleData({
      calendarId: event.calendarId,
      event,
      appendToUndo: true,
    })
    return {
      ...state,
      eventsData: ReduxEvents.clone(eventsState),
    }
  }
}
export const onUpdateEventSimpleDataSuccess = (
  state: EventsState,
  action: UpdateEventSimpleDataSuccess
) => {
  const eventsState = state.eventsData
  const { calendarId, eventId, updatedTime } = action.payload
  const event = eventsState.getEventById({ calendarId, eventId })
  if (event && updatedTime) {
    event.updated = updatedTime
    eventsState.updateEvent({
      calendarId,
      event,
      appendToUndo: false,
      isUndo: false,
    })
    eventsState
      .getInstancesByCalendarAndEventId({ calendarId, eventId })
      .forEach((instance: ReduxEvent) => {
        instance.updated = updatedTime
        eventsState.updateInstance({
          calendarId,
          eventId,
          instance,
          appendToUndo: false,
          isUndo: false,
        })
      })
  }
  eventsState.removeUndo({
    calendarId,
    eventId,
    type: 'modify',
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsState),
  }
}
export const onUpdateEventSimpleDataFailed = (
  state: EventsState,
  action: UpdateEventSimpleDataRequest
) => {
  const eventsState = state.eventsData
  const { calendarId, eventId, undoType } = action.payload
  eventsState.performUndo({
    calendarId,
    eventId,
    type: undoType,
  })
  return {
    ...state,
    eventsData: ReduxEvents.clone(eventsState),
  }
}
// export const onUpdateEventOrInstanceStatusRequest = (
//   state: EventsState,
//   action: UpdateEventOrInstanceStatusRequest
// ) => {
//   const eventsState = state.eventsData
//   if (action.payload) {
//     const event = action.payload
//     if (event.isInstance) {
//       eventsState.updateInstance({
//         calendarId: event.calendarId,
//         eventId: event.eventId,
//         instance: event,
//         appendToUndo: true,
//         isUndo: false,
//       })
//     } else {
//       eventsState.updateEvent({
//         calendarId: event.calendarId,
//         event,
//         appendToUndo: true,
//         isUndo: false,
//       })
//     }
//     return {
//       ...state,
//       eventsData: ReduxEvents.clone(eventsState),
//     }
//   }
// }
// const onUpdateEventOrInstanceStatusFailed = (
//   state: EventsState,
//   action: UpdateEventOrInstanceStatusFailure
// ) => {
//   const eventsRedux = state.eventsData
//   // $FlowFixMe
//   const { calendarId, eventId, instanceId, undoType } = action.payload
//   eventsRedux.performUndo({
//     calendarId,
//     eventId,
//     instanceId,
//     type: undoType,
//   })
//   return {
//     ...state,
//     eventsData: ReduxEvents.clone(eventsRedux),
//   }
// }
const onSelectICSEvent = (state: EventsState, action: SelectICSEvent) => {
  const { calendarId, eventId, instanceId } = action.payload
  return {
    ...state,
    selectedICSEvent: { calendarId, eventId, instanceId },
  }
}
const onDeselectICSEvent = (state: EventsState, action: DeselectICSEvent) => {
  if (state.selectedICSEvent) {
    return {
      ...state,
      selectedICSEvent: null,
    }
  } else {
    return state
  }
}
const onSelectUIScopeEvent = (
  state: EventsState,
  action: SelectUIScopeEvent
) => {
  const eventsState = state.eventsData
  const { calendarId, eventId, instanceId } = action.payload
  if (instanceId) {
    return {
      ...state,
      selectedUIScopeEvent: eventsState.getInstanceById({
        calendarId,
        eventId,
        instanceId,
      }),
    }
  } else {
    return {
      ...state,
      selectedUIScopeEvent: eventsState.getEventById({ calendarId, eventId }),
    }
  }
}
const onDeselectUIScopeEvent = (
  state: EventsState,
  action: DeselectUIScopeEvent
) => {
  return {
    ...state,
    selectedUIScopeEvent: null,
  }
}
const onUpdateUIScopeEventRange = (
  state: EventsState,
  action: UpdateUIScopeEventRange
) => {
  return {
    ...state,
    uiEventsRange: new UIEventsRange(action.payload),
  }
}
const onClearUserData = (state: EventsState, action: ClearCalendarUserData) => {
  return initEventsState
}
export default createReducer<EventsState, EventsActions>(initEventsState, {
  [updateUIScopeEventRangeAction.toString()]: onUpdateUIScopeEventRange,
  [deselectICSEventAction.toString()]: onDeselectICSEvent,
  [selectICSEventAction.toString()]: onSelectICSEvent,
  [deselectUIScopeEventAction.toString()]: onDeselectUIScopeEvent,
  [selectUIScopeEventAction.toString()]: onSelectUIScopeEvent,
  [fetchICSEventDataAction.success.toString()]: fetchICSEventDataSuccess,
  [updateEventOrInstanceAttendeeAction.request.toString()]: onUpdateEventOrInstanceAttendeeRequest,
  [updateEventOrInstanceAttendeeAction.success.toString()]: onUpdateEventOrInstanceAttendeeSuccess,
  [updateEventOrInstanceAttendeeAction.failure.toString()]: onUpdateEventOrInstanceAttendeeFailure,
  [addEventRequestAction.request.toString()]: onAddEventRequest,
  [addEventRequestAction.success.toString()]: onAddEventSuccess,
  [addEventRequestAction.failure.toString()]: onAddEventRequestFailed,
  [updateAndAddEventAction.request.toString()]: onUpdateAndAddEventRequest,
  [updateAndAddEventAction.failure.toString()]: onUpdateAndAddEventRequestFail,
  [updateAndAddEventAction.success.toString()]: onUpdateAndAddEventRequestSuccess,
  [updateEventSimpleDataAction.request.toString()]: onUpdateEventSimpleDataRequest,
  [updateEventSimpleDataAction.success.toString()]: onUpdateEventSimpleDataSuccess,
  [updateEventSimpleDataAction.failure.toString()]: onUpdateEventSimpleDataFailed,
  [updateEventRequestAction.success.toString()]: onUpdateEventRequestSuccess,
  [updateEventRequestAction.request.toString()]: updateEventOrInstanceRequest,
  [updateEventRequestAction.failure.toString()]: onUpdateEventOrInstanceRequestFailed,
  [updateSingleEventInstanceRequestAction.request.toString()]: updateEventOrInstanceRequest,
  [updateSingleEventInstanceRequestAction.success.toString()]: onUpdateSingleEventInstanceSuccess,
  [updateSingleEventInstanceRequestAction.failure.toString()]: onUpdateEventOrInstanceRequestFailed,
  [addSimpleEventAction.success.toString()]: addSimpleEventSuccess,
  [deleteSingleEventInstanceRequestAction.request.toString()]: deleteEventOrInstanceRequest,
  [deleteSingleEventInstanceRequestAction.success.toString()]: onCRUDEAPISuccess,
  [deleteSingleEventInstanceRequestAction.failure.toString()]: deleteEventOrInstanceRequestFailed,
  [deleteEventRequestAction.request.toString()]: deleteEventOrInstanceRequest,
  [deleteEventRequestAction.success.toString()]: onCRUDEAPISuccess,
  [deleteEventRequestAction.failure.toString()]: deleteEventOrInstanceRequestFailed,
  [fetchUIScopeEventsByCalendarIdAction.success.toString()]: fetchUIScopeEventsSuccess,
  [fetchEventOrInstanceAction.success.toString()]: fetchEventOrInstanceSuccess,
  [clearCalendarUserDataAction.toString()]: onClearUserData,
})
