// @flow
import moment from 'moment-timezone'
import cloneDeep from 'lodash/cloneDeep'
import uuid from 'uuid'
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import {
  AuthMissing,
  ServerError,
  showErrorNotification,
} from './actions-common'
import { ReduxEvent } from './modal-redux-event'
import {
  quickAddEvent,
  getEvent,
  patchEvent,
  addEvent as addEventAPI,
  deleteEvent as deleteEventAPI,
  updateEvent as updateEventAPI,
  listCalendarEvents,
  getICSEventData,
  updateAndAddEvent as updateAndAddEventAPI,
  updateEventOrInstanceAttendeeData as updateEventOrInstanceAttendeeDataAPI,
} from '@edison/webmail-core/api/calendar'
import { getEventById, getUIEventsRange } from './selectors-events'
import {
  convertRFCDate,
  convertRFCDateTime,
  isValidDate,
} from '@edison/datetime'
import * as analytics from 'core/analytics/actions'
import { getCalendarSettingsSelector } from './selectors-settings'
import { showNotification } from '../toasts/actions'
import { toastVariants } from '../../common/toasts'
import { eventsActionTypes } from './constants-events'
import type { UIEventsRange } from './modal-ui-events-range'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  CalendarResolutionTypes,
  UpdateUIScopeEventRange,
  AddSimpleEventRequest,
  AddSimpleEventRequestFailure,
  AddSimpleEventRequestSuccess,
  FetchEventsRequest,
  FetchEventsRequestSuccess,
  FetchEventsRequestFailure,
  DeleteEventRequest,
  DeleteEventRequestSuccess,
  DeleteEventRequestFailure,
  DeleteSingleEventInstanceRequestSuccess,
  DeleteSingleEventInstanceRequestFailure,
  UpdateEventRequest,
  UpdateEventRequestSuccess,
  UpdateEventRequestFailure,
  UpdateSingleEventInstanceRequest,
  UpdateSingleEventInstanceRequestSuccess,
  UpdateSingleEventInstanceRequestFailure,
  DeleteSingleEventInstanceRequest,
  AddEventRequest,
  AddEventRequestSuccess,
  AddEventRequestFailure,
  SelectUIScopeEvent,
  DeselectUIScopeEvent,
  UpdateAndAddEventRequest,
  UpdateAndAddEventRequestSuccess,
  UpdateAndAddEventRequestFailure,
  SelectICSEvent,
  DeselectICSEvent,
  FetchEventOrInstanceRequest,
  FetchEventOrInstanceRequestSuccess,
  FetchEventOrInstanceRequestFailure,
  UpdateEventSimpleDataRequest,
  UpdateEventSimpleDataSuccess,
  UpdateEventSimpleDataFailure,
  FetchICSEventDataRequest,
  FetchICSEventDataSuccess,
  FetchICSEventDataFailure,
  UpdateEventOrInstanceAttendeeSuccess,
  UpdateEventOrInstanceAttendeeFailure,
} from './types-events'
import type {
  RecurringEventModifyTypes,
  UnixTimestampInSeconds,
} from './types-common'
import type { RequestAPIActionGen } from './actions-common'
import type {
  CalendarEventInsertResource,
  CalendarEventNotificationUpdateScope,
  CalendarAttendee,
} from '@edison/webmail-core/types/calendar'
import { getSettings } from './selectors'
import { updateRepeatDay } from '../../utils/rrule'
import { setCalendarViewResolution } from '../../common/storage'
import { UpdateEventOrInstanceAttendeeRequest } from './types-events'
import { getAllAliasesSelector } from '../custom-domains/selectors'

export const fetchUIScopeEventsByCalendarIdAction: RequestAPIActionGen<
  FetchEventsRequest,
  FetchEventsRequestSuccess,
  FetchEventsRequestFailure
> = {
  request: createAction(eventsActionTypes.FetchEventsRequest),
  success: createAction(eventsActionTypes.FetchEventsRequestSuccess),
  failure: createAction(eventsActionTypes.FetchEventsRequestFailure),
}

export const fetchUIScopeEventsByCalendarId = (
  calendarId: string,
  range?: UIEventsRange
): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        fetchUIScopeEventsByCalendarIdAction.failure({ message: AuthMissing })
      )
      return
    }
    if (typeof calendarId !== 'string' || calendarId.length === 0) {
      dispatch(
        fetchUIScopeEventsByCalendarIdAction.failure({ message: ServerError })
      )
      return
    }
    try {
      if (!range) {
        range = getUIEventsRange(getState())
        // dispatch(fetchUIScopeEventsByCalendarId(calendarId, range.nextRange))
        // dispatch(
        //   fetchUIScopeEventsByCalendarId(calendarId, range.previousRange)
        // )
      }
      const timeMax = moment.unix(range.end).format()
      const timeMin = moment.unix(range.start).format()
      const query = { timeMax, timeMin }
      let ret = null
      const listEventsWithPagination = () => {
        const helper = (resolve, reject) => {
          listCalendarEvents({ auth, calendarId, query })
            .then(res => {
              if (res.result) {
                if (!ret) {
                  ret = res.result
                } else {
                  if (!Array.isArray(ret.items)) {
                    ret.items = []
                  }
                  ret.items = ret.items.concat(res.result.items)
                  if (ret.updated < res.result.updated) {
                    ret.updated = res.result.updated
                  }
                }
                if (res.result.nextPageToken) {
                  query.pageToken = res.result.nextPageToken
                  setTimeout(() => helper(resolve, reject))
                  return
                } else {
                  resolve(ret)
                  return
                }
              }
              reject(new Error(ServerError))
            })
            .catch(reject)
        }
        return new Promise((resolve, reject) => {
          helper(resolve, reject)
        })
      }
      const eventsList = await listEventsWithPagination()
      const settings = getSettings(getState())
      dispatch(
        fetchUIScopeEventsByCalendarIdAction.success({
          events: eventsList,
          calendarId,
          start: range.start,
          end: range.end,
          currentTimeZone: settings.timezone,
        })
      )
    } catch (e) {
      console.error(e)
      dispatch(
        fetchUIScopeEventsByCalendarIdAction.failure({ message: e.message })
      )
    }
  }
}

export const fetchICSEventDataAction: RequestAPIActionGen<
  FetchICSEventDataRequest,
  FetchICSEventDataSuccess,
  FetchICSEventDataFailure
> = {
  request: createAction(eventsActionTypes.FetchICSEventDataRequest),
  success: createAction(eventsActionTypes.FetchICSEventDataSuccess),
  failure: createAction(eventsActionTypes.FetchICSEventDataFailure),
}

export const fetchICSEventData = ({
  calendarId,
  eventOrInstanceId,
  startTimestamp,
}: {
  calendarId: string,
  eventOrInstanceId: string,
  startTimestamp: UnixTimestampInSeconds,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(fetchICSEventDataAction.failure({ message: AuthMissing }))
      return Promise.reject(new Error(AuthMissing))
    }
    if (!eventOrInstanceId) {
      dispatch(fetchICSEventDataAction.failure({ message: 'No event' }))
      return Promise.reject(new Error('No event'))
    }
    const timeMin = moment.unix(startTimestamp).format()
    try {
      const ret = await getICSEventData({
        auth,
        calendarId,
        eventId: eventOrInstanceId,
        query: { timeMin },
      })
      if (ret.result) {
        dispatch(
          fetchICSEventDataAction.success({
            icsData: ret.result,
            calendarId,
            eventOrInstanceId,
            startTime: startTimestamp,
          })
        )
        return Promise.resolve(ret)
      } else {
        throw new Error(ServerError)
      }
    } catch (e) {
      dispatch(fetchICSEventDataAction.failure({ message: e.message }))
      console.error(e)
      return Promise.reject(e)
    }
  }
}

export const fetchEventOrInstanceAction: RequestAPIActionGen<
  FetchEventOrInstanceRequest,
  FetchEventOrInstanceRequestSuccess,
  FetchEventOrInstanceRequestFailure
> = {
  request: createAction(eventsActionTypes.FetchEventOrInstanceRequest),
  success: createAction(eventsActionTypes.FetchEventOrInstanceRequestSuccess),
  failure: createAction(eventsActionTypes.FetchEventOrInstanceRequestFailure),
}

export const updateEventSimpleDataAction: RequestAPIActionGen<
  UpdateEventSimpleDataRequest,
  UpdateEventSimpleDataSuccess,
  UpdateEventSimpleDataFailure
> = {
  request: createAction(eventsActionTypes.UpdateEventSimpleDataRequest),
  success: createAction(eventsActionTypes.UpdateEventSimpleDataSuccess),
  failure: createAction(eventsActionTypes.UpdateEventSimpleDataFailure),
}

export const updateEventSimpleData = (event: ReduxEvent): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(
        updateEventSimpleDataAction.failure({
          message: AuthMissing,
          calendarId: event.calendarId,
          eventId: event.eventId,
          undoType: 'modify',
        })
      )
      return Promise.reject(new Error(AuthMissing))
    }
    dispatch(updateEventSimpleDataAction.request(event))
    try {
      const data = {
        summary: event.summary,
        description: event.description,
        attendees: event.attendees,
      }
      const apiRet = await patchEvent({
        auth,
        query: { sendUpdate: 'all' },
        calendarId: event.calendarId,
        eventId: event.id,
        event: data,
      })
      if (apiRet && apiRet.result) {
        const ret = {
          type: 'modify',
          calendarId: event.calendarId,
          eventId: event.eventId,
          updatedTime: apiRet.result.updated,
        }
        if (event.instanceId) {
          ret.instanceId = event.instanceId
        }
        dispatch(updateEventSimpleDataAction.success(ret))

        dispatch(analytics.calendar.useUpdateEventAction())
        return Promise.resolve()
      } else {
        throw new Error(ServerError)
      }
    } catch (e) {
      dispatch(
        updateEventSimpleDataAction.failure({
          message: e.message,
          calendarId: event.calendarId,
          eventId: event.eventId,
          undoType: 'modify',
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(e)
    }
  }
}
export const updateEventOrInstanceAttendeeAction: RequestAPIActionGen<
  UpdateEventOrInstanceAttendeeRequest,
  UpdateEventOrInstanceAttendeeSuccess,
  UpdateEventOrInstanceAttendeeFailure
> = {
  request: createAction(eventsActionTypes.UpdateEventAttendeeDataRequest),
  success: createAction(eventsActionTypes.UpdateEventAttendeeDataSuccess),
  failure: createAction(eventsActionTypes.UpdateEventAttendeeDataFailure),
}

export const updateEvenOrInstanceAttendee = ({
  eventOrInstance,
  attendee,
}: {
  eventOrInstance: ReduxEvent,
  attendee: CalendarAttendee,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const { calendarId, eventId, instanceId } = eventOrInstance
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(
        updateEventOrInstanceAttendeeAction.failure({
          message: AuthMissing,
          calendarId: calendarId,
          eventId: eventId,
          instanceId: instanceId,
          attendee,
          undoType: 'modify',
        })
      )
      return Promise.reject(new Error(AuthMissing))
    }
    dispatch(
      updateEventOrInstanceAttendeeAction.request({
        eventOrInstance,
        attendee,
      })
    )
    const data = {
      auth,
      calendarId,
      eventOrInstanceId: eventId,
      data: { attendee },
      query: { sendUpdate: 'all' },
    }
    if (instanceId) {
      data.eventOrInstanceId = instanceId
      data.data.recurringEventId = eventId
    }
    try {
      const ret = await updateEventOrInstanceAttendeeDataAPI(data)
      if (ret && ret.result) {
        dispatch(
          updateEventOrInstanceAttendeeAction.success({
            calendarId,
            eventId,
            instanceId,
            attendee,
            updatedTime: ret.result.updated,
            undoType: 'modify',
          })
        )
        dispatch(analytics.calendar.useRSVPEventAction())
        return Promise.resolve()
      } else {
        throw new Error(ServerError)
      }
    } catch (e) {
      dispatch(
        updateEventOrInstanceAttendeeAction.failure({
          message: e.message,
          calendarId: calendarId,
          eventId: eventId,
          instanceId: instanceId,
          attendee,
          undoType: 'modify',
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(e)
    }
  }
}

// export const updateEvenOrInstanceStatusAction: RequestAPIActionGen<
//   UpdateEventOrInstanceStatusRequest,
//   UpdateEventOrInstanceStatusSuccess,
//   UpdateEventOrInstanceStatusFailure
// > = {
//   request: createAction(eventsActionTypes.UpdateEventOrInstanceStatusRequest),
//   success: createAction(eventsActionTypes.UpdateEventOrInstanceStatusSuccess),
//   failure: createAction(eventsActionTypes.UpdateEventOrInstanceStatusFailure),
// }
// export const updateEventOrInstanceStatus = (event: ReduxEvent): ThunkAction => {
//   return async (dispatch, getState) => {
//     const auth = getAuth()(getState())
//     if (!auth) {
//       dispatch(
//         updateEvenOrInstanceStatusAction.failure({
//           message: AuthMissing,
//           calendarId: event.calendarId,
//           eventId: event.eventId,
//           instanceId: event.instanceId,
//           undoType: 'modify',
//         })
//       )
//       return Promise.reject(new Error(AuthMissing))
//     }
//     const data = { status: event.status }
//     if (event.isInstance) {
//       data.recurringEventId = event.eventId
//     }
//     dispatch(updateEvenOrInstanceStatusAction.request(event))
//     try {
//       const ret = await updateEventOrInstanceStatusAPI({
//         auth,
//         query: { sendUpdate: 'all' },
//         calendarId: event.calendarId,
//         eventId: event.id,
//         event: data,
//       })
//       if (ret.result) {
//         const ret = {
//           type: 'modify',
//           calendarId: event.calendarId,
//           eventId: event.eventId,
//         }
//         if (event.instanceId) {
//           ret.instanceId = event.instanceId
//         }
//         dispatch(updateEvenOrInstanceStatusAction.success(ret))
//
//         dispatch(analytics.calendar.useUpdateEventAction())
//         return Promise.resolve()
//       } else {
//         throw new Error(ServerError)
//       }
//     } catch (e) {
//       dispatch(
//         updateEvenOrInstanceStatusAction.failure({
//           message: e.message,
//           calendarId: event.calendarId,
//           eventId: event.eventId,
//           instanceId: event.instanceId,
//           undoType: 'modify',
//         })
//       )
//       showErrorNotification(dispatch, e)
//       return Promise.reject(e)
//     }
//   }
// }

export const fetchEventOrInstance = ({
  calendarId,
  eventOrInstanceId,
}: {
  calendarId: string,
  eventOrInstanceId: string,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(
        fetchEventOrInstanceAction.failure({
          message: AuthMissing,
          calendarId,
          eventOrInstanceId,
        })
      )
      return Promise.reject(new Error(AuthMissing))
    }
    try {
      const result = await getEvent({
        auth,
        eventId: eventOrInstanceId,
        calendarId,
        query: {},
      })
      if (result.result) {
        const eventOrInstance = new ReduxEvent({ ...result.result, calendarId })
        const ret = { event: null, instance: null }
        if (eventOrInstance.isInstance) {
          ret.instance = eventOrInstance
          const eventResponse = await getEvent({
            auth,
            eventId: eventOrInstance.eventId,
            calendarId,
            query: {},
          })
          if (eventResponse.result) {
            ret.event = new ReduxEvent({ ...eventResponse.result, calendarId })
          }
        } else {
          ret.event = eventOrInstance
        }
        dispatch(fetchEventOrInstanceAction.success(ret))
        return Promise.resolve(ret)
      } else {
        throw new Error(ServerError)
      }
    } catch (e) {
      dispatch(
        fetchEventOrInstanceAction.failure({
          message: AuthMissing,
          calendarId,
          eventOrInstanceId,
        })
      )
      return Promise.reject(e)
    }
  }
}

export const addSimpleEventAction: RequestAPIActionGen<
  AddSimpleEventRequest,
  AddSimpleEventRequestSuccess,
  AddSimpleEventRequestFailure
> = {
  request: createAction(eventsActionTypes.AddSimpleEventRequest),
  success: createAction(eventsActionTypes.AddSimpleEventRequestSuccess),
  failure: createAction(eventsActionTypes.AddSimpleEventRequestFailure),
}

export const addSimpleEvent = (
  calendarId: string,
  query: {
    text: string,
    sendUpdate: CalendarEventNotificationUpdateScope,
  }
): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(addSimpleEventAction.failure({ message: AuthMissing }))
      return
    }
    try {
      const ret = await quickAddEvent({ auth, calendarId, query })
      if (ret.result) {
        const event = new ReduxEvent({ ...ret.result, calendarId })
        dispatch(
          addSimpleEventAction.success({
            calendarId,
            eventId: event.eventId,
            data: event,
            undoType: 'add',
          })
        )

        dispatch(analytics.calendar.useCreateEventAction())
      }
    } catch (e) {
      dispatch(addSimpleEventAction.failure({ message: AuthMissing }))
    }
  }
}

export const deleteEventRequestAction: RequestAPIActionGen<
  DeleteEventRequest,
  DeleteEventRequestSuccess,
  DeleteEventRequestFailure
> = {
  request: createAction(eventsActionTypes.DeleteEventRequest),
  success: createAction(eventsActionTypes.DeleteEventRequestSuccess),
  failure: createAction(eventsActionTypes.DeleteEventRequestFailure),
}

export const deleteEvent = (event: ReduxEvent): ThunkAction => {
  return async (dispatch, getState) => {
    const calendarId = event.calendarId
    const eventId = event.eventId
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        deleteEventRequestAction.failure({
          message: AuthMissing,
          calendarId,
          eventId,
        })
      )
      dispatch(showNotification(AuthMissing, toastVariants.error))
      return Promise.reject(new Error(AuthMissing))
    }
    const aliases = getAllAliasesSelector(getState())
    try {
      dispatch(
        deleteEventRequestAction.request({
          oldEventOrInstance: event,
          userAliases: aliases,
        })
      )
      await deleteEventAPI({
        auth,
        calendarId,
        eventIdOrInstanceId: eventId,
        query: {},
      })
      dispatch(
        deleteEventRequestAction.success({
          calendarId,
          eventId,
          undoType: 'delete',
        })
      )

      dispatch(analytics.calendar.useDeleteEventAction())
    } catch (e) {
      dispatch(
        deleteEventRequestAction.failure({
          message: e.message,
          calendarId,
          eventId,
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(new Error(ServerError))
    }
  }
}

export const deleteSingleEventInstanceRequestAction: RequestAPIActionGen<
  DeleteSingleEventInstanceRequest,
  DeleteSingleEventInstanceRequestSuccess,
  DeleteSingleEventInstanceRequestFailure
> = {
  request: createAction(eventsActionTypes.DeleteSingleEventInstanceRequest),
  success: createAction(
    eventsActionTypes.DeleteSingleEventInstanceRequestSuccess
  ),
  failure: createAction(
    eventsActionTypes.DeleteSingleEventInstanceRequestFailure
  ),
}

const appendRepeatDay = (eventData: CalendarEventInsertResource) => {
  let m
  if (eventData.start && eventData.start.dateTime) {
    m = convertRFCDateTime(eventData.start.dateTime)
  } else if (eventData.start && eventData.start.date) {
    m = convertRFCDate(eventData.start.date).utc(true)
  }
  if (m) {
    if (Array.isArray(eventData.recurrence) && eventData.recurrence[0]) {
      eventData.recurrence[0] = updateRepeatDay(eventData.recurrence[0], m)
    }
  }
}
const appendTimezone = (settings, data) => {
  if (
    typeof data.start.dateTime === 'string' &&
    data.start.dateTime.length > 0
  ) {
    if (
      typeof data.start.timeZone !== 'string' ||
      data.start.timeZone.length === 0
    ) {
      data.start.timeZone = settings.timezone
    }
  }
  if (typeof data.end.dateTime === 'string' && data.end.dateTime.length > 0) {
    if (
      typeof data.end.timeZone !== 'string' ||
      data.end.timeZone.length === 0
    ) {
      data.end.timeZone = settings.timezone
    }
  }
}
export const addEventRequestAction: RequestAPIActionGen<
  AddEventRequest,
  AddEventRequestSuccess,
  AddEventRequestFailure
> = {
  request: createAction(eventsActionTypes.AddEventRequest),
  success: createAction(eventsActionTypes.AddEventRequestSuccess),
  failure: createAction(eventsActionTypes.AddEventRequestFailure),
}

export const addEvent = ({
  calendarId,
  eventData,
  sendUpdates,
}: {
  calendarId: string,
  eventData: CalendarEventInsertResource,
  sendUpdates: CalendarEventNotificationUpdateScope,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(addEventRequestAction.failure({ message: AuthMissing }))
      dispatch(showNotification(AuthMissing, toastVariants.error))
      return Promise.reject(new Error(AuthMissing))
    }
    try {
      const settings = getCalendarSettingsSelector(getState())
      appendTimezone(settings, eventData)
      if (!eventData.id) {
        eventData.id = uuid().replaceAll('-', '')
      }
      appendRepeatDay(eventData)
      dispatch(
        addEventRequestAction.request({
          calendarId,
          eventData,
        })
      )
      const ret = await addEventAPI({
        auth,
        calendarId,
        event: eventData,
        query: { sendUpdates },
      })
      if (ret.result) {
        const newEvent = new ReduxEvent({ ...ret.result, calendarId })
        dispatch(
          addEventRequestAction.success({
            calendarId,
            eventId: newEvent.eventId,
            data: newEvent,
            undoType: 'add',
          })
        )
        if (newEvent.isRecurringEvent) {
          dispatch(fetchUIScopeEventsByCalendarId(newEvent.calendarId))
        }

        dispatch(analytics.calendar.useCreateEventAction())
      } else {
        dispatch(
          addEventRequestAction.failure({
            message: ServerError,
            calendarId,
            eventId: eventData.id,
          })
        )
        dispatch(showNotification(ServerError, toastVariants.error))
        return Promise.reject(new Error(ServerError))
      }
    } catch (e) {
      dispatch(
        addEventRequestAction.failure({
          message: e.message,
          calendarId,
          eventId: eventData.id,
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(e)
    }
  }
}

export const updateEventRequestAction: RequestAPIActionGen<
  UpdateEventRequest,
  UpdateEventRequestSuccess,
  UpdateEventRequestFailure
> = {
  request: createAction(eventsActionTypes.UpdateEventRequest),
  success: createAction(eventsActionTypes.UpdateEventRequestSuccess),
  failure: createAction(eventsActionTypes.UpdateEventRequestFailure),
}

export const updateEvent = ({
  eventData,
  sendUpdates,
  updateType,
}: {
  eventData: ReduxEvent,
  sendUpdates: CalendarEventNotificationUpdateScope,
  updateType?: RecurringEventModifyTypes,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const calendarId = eventData.calendarId
    const eventId = eventData.eventId
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        updateEventRequestAction.failure({
          message: AuthMissing,
          calendarId,
          eventId,
          data: eventData,
        })
      )
      dispatch(showNotification(AuthMissing, toastVariants.error))
      return Promise.reject(new Error(AuthMissing))
    }
    const settings = getCalendarSettingsSelector(getState())
    const event = getEventById({ calendarId, eventId })(getState())
    if (!event) {
      dispatch(
        updateEventRequestAction.failure({
          message: AuthMissing,
          calendarId,
          eventId,
          data: eventData,
        })
      )
      dispatch(showNotification(ServerError, toastVariants.error))
      return Promise.reject(new Error(ServerError))
    }
    eventData.incrementSequence()
    try {
      dispatch(
        updateEventRequestAction.request({
          calendarId,
          data: eventData,
          eventId,
          currentTimeZone: settings.timezone,
          updateType,
        })
      )
      const eventAPIJSON = eventData.toAPIUpdateJSON()
      appendTimezone(settings, eventAPIJSON)
      const ret = await updateEventAPI({
        auth,
        calendarId,
        eventId,
        event: eventAPIJSON,
        query: { sendUpdates },
      })
      if (ret.result) {
        dispatch(
          updateEventRequestAction.success({
            calendarId,
            eventId,
            updatedTime: ret.result.updated,
          })
        )
        dispatch(analytics.calendar.useUpdateEventAction())

        if (eventData.isRecurringEvent) {
          return dispatch(fetchUIScopeEventsByCalendarId(eventData.calendarId))
        }
      } else {
        eventData.decreaseSequence()
        dispatch(
          updateEventRequestAction.failure({
            message: ServerError,
            calendarId,
            eventId,
            data: eventData,
          })
        )
        throw new Error(ServerError)
      }
    } catch (e) {
      eventData.decreaseSequence()
      dispatch(
        updateEventRequestAction.failure({
          message: e.message,
          calendarId,
          eventId,
          data: eventData,
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(new Error(e))
    }
  }
}

export const updateSingleEventInstanceRequestAction: RequestAPIActionGen<
  UpdateSingleEventInstanceRequest,
  UpdateSingleEventInstanceRequestSuccess,
  UpdateSingleEventInstanceRequestFailure
> = {
  request: createAction(eventsActionTypes.UpdateSingleEventInstanceRequest),
  success: createAction(
    eventsActionTypes.UpdateSingleEventInstanceRequestSuccess
  ),
  failure: createAction(
    eventsActionTypes.UpdateSingleEventInstanceRequestFailure
  ),
}

export const updateInstance = ({
  instanceData,
  oldInstanceData,
  sendUpdates,
}: {
  instanceData: ReduxEvent,
  oldInstanceData: ReduxEvent,
  sendUpdates: CalendarEventNotificationUpdateScope,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const { calendarId, eventId, instanceId } = instanceData
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        updateSingleEventInstanceRequestAction.failure({
          message: AuthMissing,
          calendarId,
          eventId,
          instanceId,
        })
      )
      dispatch(showNotification(AuthMissing, toastVariants.error))
      return Promise.reject(new Error(AuthMissing))
    }
    const settings = getCalendarSettingsSelector(getState())
    if (!oldInstanceData) {
      dispatch(
        updateSingleEventInstanceRequestAction.failure({
          message: AuthMissing,
          calendarId,
          eventId,
          instanceId,
        })
      )
      dispatch(showNotification(ServerError, toastVariants.error))
      return Promise.reject(new Error(ServerError))
    }
    try {
      const data = instanceData.clone()
      data.differentFromEvent = true
      dispatch(
        updateSingleEventInstanceRequestAction.request({
          calendarId,
          eventId,
          instanceId,
          currentTimeZone: settings.timezone,
          data,
        })
      )
      const dataJSON = instanceData.toAPIUpdateJSON()
      appendTimezone(settings, dataJSON)
      const ret = await updateEventAPI({
        auth,
        calendarId,
        eventId: instanceId,
        event: dataJSON,
        query: { sendUpdates },
      })
      if (ret.result) {
        dispatch(
          updateSingleEventInstanceRequestAction.success({
            calendarId,
            eventId,
            instanceId,
            updatedTime: ret.result.updated,
            undoType: 'modify',
          })
        )

        dispatch(analytics.calendar.useUpdateEventAction())
      } else {
        dispatch(
          updateSingleEventInstanceRequestAction.failure({
            message: 'No data returned',
            calendarId,
            eventId,
            instanceId,
          })
        )
        dispatch(showNotification(ServerError, toastVariants.error))
        return Promise.reject(new Error(ServerError))
      }
    } catch (e) {
      dispatch(
        updateSingleEventInstanceRequestAction.failure({
          message: e.message,
          calendarId,
          eventId,
          instanceId,
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(new Error(ServerError))
    }
  }
}

// export const updateRecurringEventAndInstanceAction: RequestAPIActionGen<
//   UpdateRecurringEventAndInstanceRequest,
//   UpdateRecurringEventAndInstanceRequestSuccess,
//   UpdateRecurringEventAndInstanceRequestFailure
// > = {
//   request: createAction(
//     eventsActionTypes.UpdateRecurringEventAndInstanceRequest
//   ),
//   success: createAction(
//     eventsActionTypes.UpdateRecurringEventAndInstanceSuccess
//   ),
//   failure: createAction(
//     eventsActionTypes.UpdateRecurringEventAndInstanceFailure
//   ),
// }

// export const updateRecurringEventExDateAndInstance = ({
//   calendarId,
//   updateEvent,
//   newInstance,
//   sendUpdates,
// }: {
//   calendarId: string,
//   updateEvent: ReduxEvent,
//   newInstance: ReduxEvent,
//   sendUpdates: CalendarEventNotificationUpdateScope,
// }): ThunkAction => {
//   return async (dispatch, getState) => {
//     const auth = getAuth()(getState())
//     if (auth === null) {
//       dispatch(
//         updateRecurringEventAndInstanceAction.failure({
//           message: AuthMissing,
//           calendarId,
//           eventId: updateEvent.eventId,
//           instanceId: newInstance.instanceId,
//           undoType: 'updateRecurringEventExDateAndInstance',
//         })
//       )
//       dispatch(showNotification(AuthMissing, toastVariants.error))
//       return Promise.reject(new Error(AuthMissing))
//     }
//     const settings = getCalendarSettingsSelector(getState())
//     const updateJSON = updateEvent.toAPIUpdateJSON()
//     const newEventJSON = newInstance.toAPIUpdateJSON()
//     newInstance.differentFromEvent = true
//     appendTimezone(settings, updateJSON)
//     appendTimezone(settings, newEventJSON)
//     dispatch(
//       updateRecurringEventAndInstanceAction.request({
//         calendarId,
//         updateEvent,
//         newInstance,
//       })
//     )
//     try {
//       await updateAndAddEventAPI({
//         auth,
//         query: { sendUpdates },
//         calendarId,
//         updateEvent: updateJSON,
//         otherInstancesEffected: false,
//         newEvent: newEventJSON,
//       })
//       dispatch(
//         updateRecurringEventAndInstanceAction.success({
//           calendarId,
//           eventId: updateEvent.eventId,
//           instanceId: newInstance.instanceId,
//           undoType: 'updateRecurringEventExDateAndInstance',
//         })
//       )
//
//       dispatch(analytics.calendar.useUpdateEventAction())
//       return Promise.resolve()
//     } catch (e) {
//       dispatch(
//         updateRecurringEventAndInstanceAction.failure({
//           message: e.message,
//           calendarId,
//           eventId: updateEvent.eventId,
//           instanceId: newInstance.instanceId,
//           undoType: 'updateRecurringEventExDateAndInstance',
//         })
//       )
//       return Promise.reject(e)
//     }
//   }
// }

export const updateAndAddEventAction: RequestAPIActionGen<
  UpdateAndAddEventRequest,
  UpdateAndAddEventRequestSuccess,
  UpdateAndAddEventRequestFailure
> = {
  request: createAction(eventsActionTypes.UpdateAndAddEventRequest),
  success: createAction(eventsActionTypes.UpdateAndAddEventRequestSuccess),
  failure: createAction(eventsActionTypes.UpdateAndAddEventRequestFailure),
}

export const updateAndAddEvent = ({
  calendarId,
  updateEvent,
  newEvent,
  breakEventLink,
  sendUpdates,
}: {
  calendarId: string,
  updateEvent: ReduxEvent,
  breakEventLink: boolean,
  newEvent: CalendarEventInsertResource,
  sendUpdates: CalendarEventNotificationUpdateScope,
}): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        updateAndAddEventAction.failure({
          message: AuthMissing,
          calendarId,
          updateEventId: updateEvent.eventId,
          newEventId: newEvent.id,
        })
      )
      dispatch(showNotification(AuthMissing, toastVariants.error))
      return Promise.reject(new Error(AuthMissing))
    }
    const settings = getCalendarSettingsSelector(getState())
    const updateJSON = updateEvent.toAPIUpdateJSON()
    const newEventJSON = cloneDeep(newEvent)
    appendTimezone(settings, updateJSON)
    appendTimezone(settings, newEventJSON)
    dispatch(
      updateAndAddEventAction.request({
        calendarId,
        updateEvent,
        newEvent: new ReduxEvent({ ...newEvent, calendarId }),
        breakEventLink,
      })
    )
    try {
      const ret = await updateAndAddEventAPI({
        auth,
        query: { sendUpdates },
        calendarId,
        updateEvent: updateJSON,
        otherInstancesEffected: true,
        newEvent: newEventJSON,
      })
      if (ret && ret.result) {
        dispatch(
          updateAndAddEventAction.success({
            calendarId,
            updateEventId: updateEvent.eventId,
            newEventId: newEvent.id,
            updateEventUpdatedTime: ret.result.updateEvent.updated,
            newEventUpdatedTime: ret.result.newEvent.updated,
            undoType: 'updateAndAdd',
          })
        )
        dispatch(analytics.calendar.useUpdateEventAction())
        return Promise.resolve()
      } else {
        throw new Error(ServerError)
      }
    } catch (e) {
      dispatch(
        updateAndAddEventAction.failure({
          message: e.message,
          calendarId,
          updateEventId: updateEvent.eventId,
          newEventId: newEvent.id,
        })
      )
      showErrorNotification(dispatch, e)
      return Promise.reject(e)
    }
  }
}

export const selectICSEventAction: ActionCreator<SelectICSEvent> = createAction(
  eventsActionTypes.SelectICSEvent
)

export const selectICSEvent = ({
  calendarId,
  eventId,
  instanceId,
}: {
  calendarId: string,
  eventId: string,
  instanceId?: string,
}): ThunkAction => {
  return async (dispatch, getState) => {
    return dispatch(selectICSEventAction({ calendarId, eventId, instanceId }))
  }
}

export const deselectICSEventAction: ActionCreator<DeselectICSEvent> = createAction(
  eventsActionTypes.DeselectICSEvent
)
export const deselectICSEvent = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(deselectICSEventAction())
  }
}

export const selectUIScopeEventAction: ActionCreator<SelectUIScopeEvent> = createAction(
  eventsActionTypes.SelectUIScopeEvent
)

export const selectUIScopeEvent = ({
  calendarId,
  eventId,
  instanceId,
}: {
  calendarId: string,
  eventId: string,
  instanceId?: string,
}): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(selectUIScopeEventAction({ calendarId, eventId, instanceId }))
  }
}

export const deselectUIScopeEventAction: ActionCreator<DeselectUIScopeEvent> = createAction(
  eventsActionTypes.DeselectUIScopeEvent
)
export const deselectUIScopeEvent = (): ThunkAction => {
  return async (dispatch, getState) => {
    dispatch(deselectUIScopeEventAction())
  }
}

export const updateUIScopeEventRangeAction: ActionCreator<UpdateUIScopeEventRange> = createAction(
  eventsActionTypes.UpdateUIScopeEventRange
)
export const updateUIScopeEventRange = (data: {
  resolution: CalendarResolutionTypes,
  year: number,
  month: number,
  day: number,
}): ThunkAction => {
  return async (dispatch, getState) => {
    if (!isValidDate(data)) {
      return
    }
    if (!('resolution' in data)) {
      return
    }
    const uiRange: UIEventsRange = getUIEventsRange(getState())
    if (uiRange.isDiff(data)) {
      if (uiRange.resolution !== data.resolution) {
        setCalendarViewResolution(data.resolution)
      }
      dispatch(updateUIScopeEventRangeAction(data))
    }
  }
}
