// @flow
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'
import { routePaths, NAVS } from 'utils/constants'

import StripeForm from '@edison/webmail-ui/components/Stripe'
import * as premium from 'core/premium'
import stripeInit from './stripe'
import { useOrderId } from 'core/auth/hooks'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const history = useHistory()
  const userId = useOrderId()
  const isLoading = useSelector(premium.selectors.getUpdatePaymentMethodLoading)

  const onSubmit = ({ stripe, cardElement, name, postalCode }) => {
    const onSuccess = () => {
      toggle()

      // Go back to settings account section
      history.push(
        generatePath(routePaths.settingsSection, {
          userId,
          section: NAVS.account.value,
        })
      )
    }

    dispatch(
      premium.actions.updatePaymentMethod(name, postalCode, stripe, cardElement)
    ).then(res => {
      if (res) {
        onSuccess()
      }
    })
  }

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => !isLoading && isOpen && toggle()}
    >
      <StripeForm
        isNew={false}
        stripe={stripeInit}
        onSubmit={onSubmit}
        isLoading={!stripeInit || isLoading}
        onCancel={() => !isLoading && isOpen && toggle()}
      />
    </Dialog>
  )
}

View.displayName = 'PaymentUpdate'

export default View
