// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { selectors } from 'core/contacts'

import Dialog from '@material-ui/core/Dialog'
import ContactForm from 'common/Contacts/Modal/Form'

const getContactsIndexedById = selectors.getContactsIndexedById()

type Props = {
  isOpen: boolean,
  toggle: () => void,
  contactId: string,
}

const EditContactModal = ({ isOpen, toggle, contactId }: Props) => {
  const contacts = useSelector(getContactsIndexedById)
  const contact = contacts[contactId] || {}

  if (isEmpty(contact) && isOpen) {
    toggle()
  }

  return (
    <Dialog open={isOpen} fullWidth={true} onClose={toggle}>
      <ContactForm isOpen={isOpen} onClose={toggle} contact={contact} />
    </Dialog>
  )
}
export default EditContactModal
