import React, { useState } from 'react'

import ReplyIcon from '@material-ui/icons/Reply'
import ReplyAllIcon from '@material-ui/icons/ReplyAll'
import ForwardIcon from '@material-ui/icons/Forward'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import {
  RESPONSE_TYPE_REPLY,
  RESPONSE_TYPE_FORWARD,
  RESPONSE_TYPE_REPLY_ALL,
} from 'utils/constants'

const StyledMenu = props => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
)

const menus = [
  {
    iconComponent: ReplyIcon,
    label: 'Reply',
    type: RESPONSE_TYPE_REPLY,
  },
  {
    iconComponent: ReplyAllIcon,
    label: 'Reply all',
    type: RESPONSE_TYPE_REPLY_ALL,
  },
  {
    iconComponent: ForwardIcon,
    label: 'Forward',
    type: RESPONSE_TYPE_FORWARD,
  },
]

export default ({ showReplyAll, value, disabled, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const iconNode = React.createElement(
    menus.find(each => each.type === value).iconComponent,
    { fontSize: 'small' }
  )
  const handleClick = e => {
    e.preventDefault()
    !disabled && setAnchorEl(e.currentTarget)
  }
  return (
    <>
      <IconButton
        disableRipple
        size="small"
        className="w-8 h-8"
        onMouseDown={handleClick}
      >
        {iconNode}
      </IconButton>

      <StyledMenu
        id="reply-menu"
        autoFocus={false}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {menus
          .filter(item =>
            showReplyAll ? true : item.type !== RESPONSE_TYPE_REPLY_ALL
          )
          .map(({ iconComponent, label, type }, index) => (
            <MenuItem
              onClick={e => {
                e.preventDefault()
                value !== type && onChange(type)
                setAnchorEl(null)
              }}
              dense
              key={index}
            >
              <ListItemIcon>
                {React.createElement(iconComponent, { fontSize: 'small' })}
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  )
}
