// @flow
import uuid from 'uuid'
import i18next from 'i18next'
import { batch } from 'react-redux'
import * as client from '@edison/webmail-core/api'

import { getAuth } from 'core/auth/selectors'
import { getDraft } from 'core/compose/selectors'
import { getAttachment } from './selectors'
import { showSnackbar } from 'core/snackbars/actions'
import tailwindConfig from '@edison/webmail-ui/tailwind.config'

import { debouncedSaveDraft, updateDraft } from 'core/compose/actions'
import { fetchAttachmentPreviewLink } from 'core/previews/actions'
import { createAction } from 'utils/redux'
import { snackbarTypes } from 'utils/constants'
import type {
  AddAttachment,
  AddAttachments,
  UpdateAttachment,
  RemoveAttachment,
  RemoveAttachments,
  UpdateAttachments,
  DownloadAllRequest,
  DownloadAllSuccess,
  DownloadAllFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type { Attachment } from '@edison/webmail-core/types/attachment'
import { download as downloadFile } from '@edison/webmail-core/utils/file'
import { CONTENT_DISPOSITION_INLINE } from '@edison/webmail-core/utils/constants'
import PQueue from 'p-queue'
import { checkRemainingSizeAndShowFileStoragePaywall } from 'core/premium/actions'

const CONCURRENT_UPLOAD_COUNT = 3
const queue = new PQueue({ concurrency: CONCURRENT_UPLOAD_COUNT })

export const addAttachment: ActionCreator<AddAttachment> = createAction(
  'ADD_ATTACHMENT'
)
export const addAttachments: ActionCreator<AddAttachments> = createAction(
  'ADD_ATTACHMENTS'
)
export const removeAttachment: ActionCreator<RemoveAttachment> = createAction(
  'REMOVE_ATTACHMENT'
)
export const updateAttachment: ActionCreator<UpdateAttachment> = createAction(
  'UPDATE_ATTACHMENT'
)
export const updateAttachments: ActionCreator<UpdateAttachments> = createAction(
  'UPDATE_ATTACHMENTS'
)
export const removeAttachments: ActionCreator<RemoveAttachments> = createAction(
  'REMOVE_ATTACHMENTS'
)

export function uploadAttachment(
  draftId: string,
  attachmentUuid: string
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()

    const { file } = getAttachment(attachmentUuid)(state)
    const auth = getAuth()(state)

    const progressEvents = {
      'upload.onprogress': event => {
        dispatch(
          updateAttachment({
            uuid: attachmentUuid,
            progress: (event.loaded / event.total) * 100,
          })
        )
      },
    }

    const uploadPromise = queue.add(() =>
      client.attachments
        .upload({ file, auth }, progressEvents)
        .then(result => {
          batch(() => {
            dispatch(
              updateAttachment({
                uuid: attachmentUuid,
                id: result.result.id,
                scanStatus: result.result.status,
              })
            )
            !!result.result.id && dispatch(debouncedSaveDraft(draftId))
          })
        })
        .catch(e => {
          dispatch(
            updateAttachment({
              uuid: attachmentUuid,
              error: e.message,
            })
          )
        })
    )
    const promise = uploadPromise

    dispatch(
      updateAttachment({
        uuid: attachmentUuid,
        promise,
        xhr: uploadPromise.xhr,
        progress: 0,
        error: null,
      })
    )
  }
}

export function uploadAttachments(
  draftId: string,
  attachments: Array<Attachment>
): ThunkAction {
  return async (dispatch, getState) => {
    batch(() => {
      dispatch(addAttachments(attachments))

      attachments.forEach(({ uuid }) => {
        dispatch(uploadAttachment(draftId, uuid))
      })
    })
  }
}

export const retry = uploadAttachment

export function insertInlineImage(draftId: string, file: File): ThunkAction {
  return async (dispatch, getState) => {
    if (dispatch(checkRemainingSizeAndShowFileStoragePaywall(file.size))) {
      return null
    }
    const auth = getAuth()(getState())
    const { attachmentUuids } = getDraft(draftId)(getState())

    const promise = client.attachments.upload({ file, auth })

    const newAttachment = {
      file,
      fileName: file.name,
      contentType: file.type,
      size: file.size,
      progress: 0,
      uuid: file.uuid || uuid(),
      contentDisposition: CONTENT_DISPOSITION_INLINE,
      promise,
    }
    batch(() => {
      dispatch(
        updateDraft({
          id: draftId,
          attachmentUuids: attachmentUuids.concat(newAttachment.uuid),
        })
      )
      dispatch(addAttachments([newAttachment]))
    })

    try {
      const res = await promise
      dispatch(
        updateAttachment({ uuid: newAttachment.uuid, id: res.result.id })
      )

      return res.result.id
    } catch (e) {
      dispatch(
        showSnackbar({
          key: snackbarTypes.send,
          props: {
            draftId,
            message: i18next.t('compose.toast.attachFailure'),
            autoHideDuration: 10000,
            backgroundColor: tailwindConfig.theme.colors.red,
          },
        })
      )
      return null
    }
  }
}

export function insertInlineImages(
  draftId: string,
  files: Array<File>
): ThunkAction {
  return async (dispatch, getState) => {
    if (
      dispatch(
        checkRemainingSizeAndShowFileStoragePaywall(
          [...files].reduce((prev, curr) => prev + curr.size, 0)
        )
      )
    ) {
      return null
    }
    const auth = getAuth()(getState())
    const { attachmentUuids } = getDraft(draftId)(getState())

    const newAttachments = files.map(file => {
      const promise = client.attachments.upload({ file, auth })

      return {
        file,
        fileName: file.name,
        contentType: file.type,
        size: file.size,
        progress: 0,
        uuid: file.uuid || uuid(),
        contentDisposition: CONTENT_DISPOSITION_INLINE,
        promise,
      }
    })
    batch(() => {
      dispatch(
        updateDraft({
          id: draftId,
          attachmentUuids: attachmentUuids.concat(
            newAttachments.map(item => item.uuid)
          ),
        })
      )
      dispatch(addAttachments(newAttachments))
    })

    const res = await Promise.all(newAttachments.map(item => item.promise))

    dispatch(
      updateAttachments(
        res.map((item, index) => ({
          uuid: newAttachments[index].uuid,
          id: item.result.id,
        }))
      )
    )
    return res.map(item => item.result.id)
  }
}

export function loadInlineImage(draftId: string, cid: string): ThunkAction {
  return async (dispatch, getState) => {
    const { messageId, responseMessageId } = getDraft(draftId)(getState())

    return dispatch(
      fetchAttachmentPreviewLink({
        message: messageId || responseMessageId,
        id: cid,
        preview: 'image',
      })
    )
  }
}

export function download(attachment: Attachment): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const { url, fileName, contentType } = attachment

    if (auth === null) {
      return
    }

    downloadFile(url, fileName, contentType, { auth })
  }
}

export const downloadAllActions: {
  request: ActionCreator<DownloadAllRequest>,
  success: ActionCreator<DownloadAllSuccess>,
  failure: ActionCreator<DownloadAllFailure>,
} = {
  request: createAction('DOWNLOAD_ALL_REQUEST'),
  success: createAction('DOWNLOAD_ALL_SUCCESS'),
  failure: createAction('DOWNLOAD_ALL_FAILURE'),
}

export function downloadAll(messageId): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    dispatch(downloadAllActions.request({}))
    try {
      await downloadFile(
        client.attachments.endpoints.downloadAll.url(messageId),
        null,
        null,
        { auth }
      )
      dispatch(downloadAllActions.success({}))
    } catch (error) {
      dispatch(downloadAllActions.failure({ message: error.message }))
    }
  }
}

export function importAttachment(
  attachment,
  targetAttachmentType
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const { message, type, id, url, contentType } = attachment
    const res = await client.attachments.importAttachment(
      {
        sourceMessageId: message,
        sourceAttachmentType: type,
        sourceAttachmentId: id,
        sourceAttachmentUrl: url,
        contentType,
        targetAttachmentType,
      },
      auth
    )
    return res.result
  }
}
