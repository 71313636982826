// @flow
import { userTutorialOrder } from './constants'

import type { UserTutorialFlag } from './constants'

export function makeBitMasksDecoder<T>(
  mappings: $ReadOnlyArray<T>
): string => { [T]: boolean } {
  return input => {
    const mask = isNaN(input) ? '' : parseInt(input, 10).toString(2)
    const bitMasks = mask.split('').reverse()

    let output = {}
    for (let i = 0; i < mappings.length; i++) {
      const key = mappings[i]
      const val = bitMasks[i] === '1'

      output[key] = val
    }

    return output
  }
}

export function makeBitMasksEncoder<T>(
  mappings: $ReadOnlyArray<T>
): ({ [T]: boolean }) => string {
  return input => {
    let bitMasks = []

    for (let item of mappings) {
      const val = input[item] ? 1 : 0

      bitMasks.push(val)
    }

    const output = parseInt(bitMasks.reverse().join(''), 2)

    return output ? output.toString() : ''
  }
}

export const tutorialsDecoder = makeBitMasksDecoder<UserTutorialFlag>(
  userTutorialOrder
)

export const tutorialsEncoder = makeBitMasksEncoder<UserTutorialFlag>(
  userTutorialOrder
)
