// @flow
import get from 'lodash/get'

import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import * as api from '@edison/webmail-core/api/external'
import { INBOX_ZERO_IMG_PATH, INBOX_ZERO_DEFAULT } from 'utils/constants'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  ExternalAssetsRequest,
  ExternalAssetsSuccess,
  ExternalAssetsFailure,
} from './types'

export const fetchExternalAssetsActions: {
  request: ActionCreator<ExternalAssetsRequest>,
  success: ActionCreator<ExternalAssetsSuccess>,
  failure: ActionCreator<ExternalAssetsFailure>,
} = {
  request: createAction('EXTERNAL_ASSETS_REQUEST'),
  success: createAction('EXTERNAL_ASSETS_SUCCESS'),
  failure: createAction('EXTERNAL_ASSETS_FAILURE'),
}

export function fetchExternalAssets(): ThunkAction {
  return async (dispatch, getState, extras) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        fetchExternalAssetsActions.failure({ message: 'Unauthorized' })
      )
    }

    try {
      const [image, quote] = await Promise.all([
        api.getInboxZeroBackground({ auth }),
        api.getQuoteOfTheDay({ auth }),
      ])

      // Prefetch image
      const prefetch = new Image()
      prefetch.src = get(image, INBOX_ZERO_IMG_PATH, INBOX_ZERO_DEFAULT)

      dispatch(
        fetchExternalAssetsActions.success({ inboxZero: { image, quote } })
      )
    } catch (e) {
      dispatch(fetchExternalAssetsActions.failure({ message: e.message }))
    }
  }
}
