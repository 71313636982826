// @flow

const CACHE_PREFIX = 'WEBMAIL-FEEDVIEW-CACHE'
const CACHE_ELEMENT = 'script'

export const generateFeedViewCacheID = (key: string) => {
  return `${CACHE_PREFIX}-${key}`
}

export const setFeedViewCache = (key: string, content: string = '') => {
  const script = document.createElement(CACHE_ELEMENT)

  // $FlowFixMe
  script.noModule = true
  script.type = 'text/plain'
  script.id = generateFeedViewCacheID(key)
  script.text = content.replace(/\n|\r|\t/g, '')

  document.body.append(script)
}

export const getFeedViewCache = (key: string) => {
  const ref = document.getElementById(generateFeedViewCacheID(key))

  return ref ? ref.innerText : undefined
}

export const clearFeedViewCache = () => {
  // For the usage of `|=` selector, it means to match the attr value which is "value" or with "value-" prefix
  // Refer to: https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors#syntax
  const refs = document.querySelectorAll(
    `${CACHE_ELEMENT}[id|=${CACHE_PREFIX}]`
  )

  refs.forEach(node => document.body?.removeChild(node))
}

export const feedViewCaches = {
  get: getFeedViewCache,
  set: setFeedViewCache,
  clear: clearFeedViewCache,
}

window.feedViewCaches = feedViewCaches
