// @flow
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import * as selectors from './selectors'
import * as threadsSelectors from 'core/threads/selectors'
import isNil from 'lodash/isNil'
import { displayTitles, labelNames } from '../../utils/constants'

const selectedThreadsSelector = threadsSelectors.getSelectedThreadIds()

export const usePreviewableSplits = () => {
  const isDraging = useSelector(threadsSelectors.isThreadDragging)
  const selected = useSelector(selectedThreadsSelector)

  const nextPreviewableSplits = useSelector(selectors.getPreviewableSplits)

  const [previewables, setPreviewables] = useState<$ReadOnlyArray<string>>([])

  useEffect(() => {
    if (isDraging) return
    if (selected.size > 0) {
      setPreviewables([])
    } else {
      if (nextPreviewableSplits.length !== previewables.length) {
        setPreviewables(nextPreviewableSplits)
      }
    }
  }, [nextPreviewableSplits, isDraging, selected.size])

  return previewables
}

const getSplitInboxes = selectors.getSplitInboxes()
export const useSortedSplitInboxes = () => {
  const customSplitInboxes = useSelector(getSplitInboxes)
  const splitInboxesMetaData = useSelector(selectors.getSplitMetaState)
  return useMemo(() => {
    const getSplitOrder = function(meta, defaultVal) {
      return isNil(meta) ? defaultVal : meta.idx
    }
    return [
      {
        labelId: labelNames.primary,
        name: displayTitles[labelNames.primary],
        idx: getSplitOrder(splitInboxesMetaData[labelNames.primary], 0),
      },
      {
        labelId: labelNames.other,
        name: displayTitles[labelNames.other],
        idx: getSplitOrder(splitInboxesMetaData[labelNames.other], 1),
      },
      ...customSplitInboxes.map(({ id, labelId, name, idx }) => ({
        labelId,
        name,
        idx: getSplitOrder(splitInboxesMetaData[id], idx),
      })),
    ].sort((a, b) => (a.idx < b.idx ? -1 : 1))
  }, [customSplitInboxes, splitInboxesMetaData])
}
