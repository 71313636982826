// @flow
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isNil from 'lodash/isNil'
import head from 'lodash/head'
import get from 'lodash/get'
import qs from 'qs'

import { getSiftsByThreadId, fields } from 'core/sifts/selectors'
import { getThreadsById } from 'core/threads/selectors'
import {
  siftDomainNames,
  domainPriority,
  contactSiftTabs,
} from 'utils/constants'

import CircularProgress from '@material-ui/core/CircularProgress'
import Bill from '@edison/webmail-ui/components/Sift/panes/Bill'
import Train from '@edison/webmail-ui/components/Sift/panes/Train'
import Hotel from '@edison/webmail-ui/components/Sift/panes/Hotel'
import Event from '@edison/webmail-ui/components/Sift/panes/Event'
import Cruise from '@edison/webmail-ui/components/Sift/panes/Cruise'
import Flight from '@edison/webmail-ui/components/Sift/panes/Flight'
import Purchase from '@edison/webmail-ui/components/Sift/panes/Purchase'
import RentalCar from '@edison/webmail-ui/components/Sift/panes/RentalCar'
import Shipment from '@edison/webmail-ui/components/Sift/panes/Shipment'
import Restaurant from '@edison/webmail-ui/components/Sift/panes/Restaurant'

import type { Location } from 'react-router-dom'

const registry = {
  [siftDomainNames.bill]: Bill,
  [siftDomainNames.train]: Train,
  [siftDomainNames.hotel]: Hotel,
  [siftDomainNames.event]: Event,
  [siftDomainNames.cruise]: Cruise,
  [siftDomainNames.flight]: Flight,
  [siftDomainNames.purchase]: Purchase,
  [siftDomainNames.rentalcar]: RentalCar,
  [siftDomainNames.shipment]: Shipment,
  [siftDomainNames.restaurant]: Restaurant,
}

type Props = {
  location: Location,
}

const View = ({ location }: Props) => {
  const [displayDomain, setDomain] = useState(null)
  const [props, setProps] = useState({})
  const [isLoading, setLoading] = useState(true)
  const { thread: threadId } = qs.parse(location.search.slice(1))
  const siftsByThreadId = useSelector(getSiftsByThreadId)

  const threadsById = useSelector(getThreadsById)
  const thread = threadsById[threadId]

  const { domain, payload } =
    getLastestSiftInThread(siftsByThreadId[threadId] || []) || {}

  useEffect(() => {
    async function load() {
      setLoading(true)
      const parser = get(fields, domain)
      if (!!parser) {
        const res = await parser(payload, { thread })
        setProps(res)
        setDomain(domain)
        setLoading(false)
      }
    }
    load()
  }, [domain, payload])

  if (isNil(thread) || isNil(displayDomain)) return null

  const Details = registry[displayDomain]

  return isLoading ? (
    <div className="text-center my-6">
      <CircularProgress />
    </div>
  ) : (
    <Details {...props} />
  )
}

export default withRouter(View)

export function isRegistriedDomain(domain: string) {
  return Object.keys(registry).includes(domain)
}

export function getDomainTab(domain: string) {
  return get(contactSiftTabs, domain)
}

type Sift = {
  domain: string,
  payload: Object,
}

export function getLastestSiftInThread(sifts: $ReadOnlyArray<Sift>): ?Sift {
  let siftMap: { [domain: string]: Array<Sift> } = {}

  for (let sift of sifts) {
    if (!(sift.domain in siftMap)) {
      siftMap[sift.domain] = []
    }
    siftMap[sift.domain].push(sift)
  }

  function siftSortHandler(a, b) {
    const aEmailTime = get(a, 'payload.emailTime', 0)
    const bEmailTime = get(b, 'payload.emailtime', 0)
    return aEmailTime < bEmailTime ? 1 : -1
  }

  function getLatestSift(domain) {
    if (Array.isArray(siftMap[domain])) {
      return head(siftMap[domain].sort(siftSortHandler))
    }
    return null
  }

  for (let domain of domainPriority) {
    const sift = getLatestSift(domain)
    if (!isNil(sift)) return sift
  }
  return null
}
