import React from 'react'
import ContactSection from './ContactSection'
import MessageListSection from './MessageListSection'
import AttachmentSection from './AttachmentSection'
import SuggestedActions from './SuggestedActions'

export default function ContactDetail({ contactId }) {
  return (
    <div className="pt-6 px-6 flex flex-col h-screen">
      <ContactSection contactId={contactId} />
      <AttachmentSection contactId={contactId} />

      <MessageListSection contactId={contactId} />

      <SuggestedActions contactId={contactId} />
    </div>
  )
}
