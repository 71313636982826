// @flow
import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGoHome } from 'common/url'

import * as selectors from './selectors'

export function useBetaFeature(
  feature: string,
  redirect: boolean = false
): { isEnable: boolean } {
  const home = useGoHome('replace')

  const isFeatureLoading = useSelector(selectors.isBetaFeaturesLoading)
  const featureSelector = useMemo(
    () => selectors.makeIsFeatureAvailable(feature),
    [feature]
  )
  const isEnable = useSelector(featureSelector)

  useEffect(() => {
    // After loading beta features, redirect to home page
    // if the feature is not enable
    if (!isEnable && redirect && !isFeatureLoading) {
      home.go()
    }
  }, [isEnable, redirect, isFeatureLoading])

  return { isEnable }
}
