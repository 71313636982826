// @flow
export const calendarsActionTypes: {
  fetchSecondaryCalendarsRequest: 'CALENDAR_FETCH_USER_CALENDAR_LIST_REQUEST',
  fetchSecondaryCalendarsRequestSuccess: 'CALENDAR_FETCH_USER_CALENDAR_LIST_SUCCESS',
  fetchSecondaryCalendarsRequestFailure: 'CALENDAR_FETCH_USER_CALENDAR_LIST_FAILURE',
  fetchPrimaryCalendarRequest: 'CALENDAR_FETCH_PRIMARY_CALENDAR_REQUEST',
  fetchPrimaryCalendarRequestSuccess: 'CALENDAR_FETCH_PRIMARY_CALENDAR_SUCCESS',
  fetchPrimaryCalendarRequestFailure: 'CALENDAR_FETCH_PRIMARY_CALENDAR_FAILURE',
  clearPrimaryCalendarRequest: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_REQUEST',
  clearPrimaryCalendarRequestSuccess: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_SUCCESS',
  clearPrimaryCalendarRequestFailure: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_FAILURE',
  updateCalendarRequest: 'CALENDAR_UPDATE_CALENDAR_REQUEST',
  updateCalendarRequestSuccess: 'CALENDAR_UPDATE_CALENDAR_SUCCESS',
  updateCalendarRequestFailure: 'CALENDAR_UPDATE_CALENDAR_FAILURE',
} = {
  fetchSecondaryCalendarsRequest: 'CALENDAR_FETCH_USER_CALENDAR_LIST_REQUEST',
  fetchSecondaryCalendarsRequestSuccess:
    'CALENDAR_FETCH_USER_CALENDAR_LIST_SUCCESS',
  fetchSecondaryCalendarsRequestFailure:
    'CALENDAR_FETCH_USER_CALENDAR_LIST_FAILURE',
  fetchPrimaryCalendarRequest: 'CALENDAR_FETCH_PRIMARY_CALENDAR_REQUEST',
  fetchPrimaryCalendarRequestSuccess: 'CALENDAR_FETCH_PRIMARY_CALENDAR_SUCCESS',
  fetchPrimaryCalendarRequestFailure: 'CALENDAR_FETCH_PRIMARY_CALENDAR_FAILURE',
  clearPrimaryCalendarRequest: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_REQUEST',
  clearPrimaryCalendarRequestSuccess: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_SUCCESS',
  clearPrimaryCalendarRequestFailure: 'CALENDAR_CLEAR_PRIMARY_CALENDAR_FAILURE',
  updateCalendarRequest: 'CALENDAR_UPDATE_CALENDAR_REQUEST',
  updateCalendarRequestSuccess: 'CALENDAR_UPDATE_CALENDAR_SUCCESS',
  updateCalendarRequestFailure: 'CALENDAR_UPDATE_CALENDAR_FAILURE',
}
