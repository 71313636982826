// @flow
import moment from 'moment'
import i18next from 'i18next'
import * as client from '@edison/webmail-core/api'
import { commonErrorCode } from '@edison/webmail-core/utils/constants'

import * as selectors from './selectors'
import { getAuth } from 'core/auth/selectors'
import { showNotification } from 'core/toasts/actions'

import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'

import type {
  ScheduledBreak,
  CreatedScheduledBreak,
} from '@edison/webmail-core/types/inbox-break'
import type { ActionCreator, ThunkAction } from 'types/redux'
import type {
  FetchBreakStatusRequest,
  FetchBreakStatusSuccess,
  FetchBreakStatusFailure,
  FetchBreakSettingsRequest,
  FetchBreakSettingsSuccess,
  FetchBreakSettingsFailure,
  UpdateBreakSettingRequest,
  UpdateBreakSettingSuccess,
  UpdateBreakSettingFailure,
  CreateScheduledBreakRequest,
  CreateScheduledBreakSuccess,
  CreateScheduledBreakFailure,
  UpdateScheduledBreakRequest,
  UpdateScheduledBreakSuccess,
  UpdateScheduledBreakFailure,
  RemoveScheduledBreakRequest,
  RemoveScheduledBreakSuccess,
  RemoveScheduledBreakFailure,
  FetchRecentMessagesRequest,
  FetchRecentMessagesSuccess,
  FetchRecentMessagesFailure,
  RemoveRecentMessageRequest,
  RemoveRecentMessageSuccess,
  RemoveRecentMessageFailure,
  ExecuteBreakRequest,
  ExecuteBreakSuccess,
  ExecuteBreakFailure,
  ToggleFullscreenAlertFlag,
} from './types.js'

export const fetchBreakStatusActions: {
  request: ActionCreator<FetchBreakStatusRequest>,
  success: ActionCreator<FetchBreakStatusSuccess>,
  failure: ActionCreator<FetchBreakStatusFailure>,
} = {
  request: createAction('FETCH_BREAK_STATUS_REQUEST'),
  success: createAction('FETCH_BREAK_STATUS_SUCCESS'),
  failure: createAction('FETCH_BREAK_STATUS_FAILURE'),
}

export function fetchBreakStatus(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    let scheduledTimer = selectors.getStatusTimer(getState())

    if (auth === null) {
      dispatch(
        fetchBreakStatusActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchBreakStatusActions.request())
      const res = await client.inboxBreak.fetchStatus({ auth })
      const { status, breakUntil, breakTimezone, nextUpdateTime } = res.result

      let statusTimer
      const nextTick = (breakUntil || nextUpdateTime) - moment().unix()

      if (nextTick > 0) {
        if (scheduledTimer) {
          clearTimeout(scheduledTimer)
        }

        statusTimer = setTimeout(() => {
          dispatch(fetchBreakStatus())
        }, nextTick * 1000 /* Convert second to millisecond */)
      }

      dispatch(
        fetchBreakStatusActions.success({
          breakUntil,
          statusTimer,
          breakTimezone,
          enable: status === 1,
        })
      )
    } catch (e) {
      dispatch(fetchBreakStatusActions.failure({ message: e.message }))
    }
  }
}

export const fetchBreakSettingsActions: {
  request: ActionCreator<FetchBreakSettingsRequest>,
  success: ActionCreator<FetchBreakSettingsSuccess>,
  failure: ActionCreator<FetchBreakSettingsFailure>,
} = {
  request: createAction('FETCH_BREAK_SETTINGS_REQUEST'),
  success: createAction('FETCH_BREAK_SETTINGS_SUCCESS'),
  failure: createAction('FETCH_BREAK_SETTINGS_FAILURE'),
}

export function fetchBreakSettings(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchBreakSettingsActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchBreakSettingsActions.request())
      const res = await client.inboxBreak.fetchBreakSettings({ auth })
      const { enableAutoReply, replyMessage, schedules } = res.result

      const enable = selectors.isBreakEnable(getState())

      dispatch(
        fetchBreakSettingsActions.success({
          schedules,
          enableAutoReply,
          replyMessage:
            !enable && replyMessage.length === 0
              ? i18next.t('inboxBreak.manual.setup.defaultMessage')
              : replyMessage,
        })
      )
    } catch (e) {
      dispatch(fetchBreakSettingsActions.failure({ message: e.message }))
    }
  }
}

export const updateBreakSettingActions: {
  request: ActionCreator<UpdateBreakSettingRequest>,
  success: ActionCreator<UpdateBreakSettingSuccess>,
  failure: ActionCreator<UpdateBreakSettingFailure>,
} = {
  request: createAction('UPDATE_BREAK_SETTING_REQUEST'),
  success: createAction('UPDATE_BREAK_SETTING_SUCCESS'),
  failure: createAction('UPDATE_BREAK_SETTING_FAILURE'),
}

export function updateBreakSetting({
  enableAutoReply,
  replyMessage,
}: {
  enableAutoReply: boolean,
  replyMessage: string,
}): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        updateBreakSettingActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(updateBreakSettingActions.request())
      const res = await client.inboxBreak.updateBreakSetting(
        { enableAutoReply, replyMessage },
        { auth }
      )

      dispatch(
        updateBreakSettingActions.success({
          schedules: res.result.schedules,
          replyMessage: res.result.replyMessage,
          enableAutoReply: res.result.enableAutoReply,
        })
      )
    } catch (e) {
      dispatch(updateBreakSettingActions.failure({ message: e.message }))
    }
  }
}

export const createScheduledBreakActions: {
  request: ActionCreator<CreateScheduledBreakRequest>,
  success: ActionCreator<CreateScheduledBreakSuccess>,
  failure: ActionCreator<CreateScheduledBreakFailure>,
} = {
  request: createAction('CREATE_SCHEDULED_BREAK_REQUEST'),
  success: createAction('CREATE_SCHEDULED_BREAK_SUCCESS'),
  failure: createAction('CREATE_SCHEDULED_BREAK_FAILURE'),
}

export function createScheduledBreak(schedule: ScheduledBreak): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        createScheduledBreakActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(createScheduledBreakActions.request())
      const res = await client.inboxBreak.createSchedule(schedule, { auth })

      dispatch(
        createScheduledBreakActions.success({
          ...schedule,
          id: res.result,
        })
      )
      return true
    } catch (e) {
      if (e.status === commonErrorCode.SCHEDULED_BREAK_DUPLICATE) {
        dispatch(
          showNotification(
            i18next.t('inboxBreak.scheduledBreak.duplicate'),
            toastVariants.error
          )
        )
      } else {
        dispatch(
          showNotification(
            i18next.t('inboxBreak.scheduledBreak.error.create'),
            toastVariants.error
          )
        )
      }
      dispatch(createScheduledBreakActions.failure({ message: e.message }))
      return false
    }
  }
}

export const updateScheduledBreakActions: {
  request: ActionCreator<UpdateScheduledBreakRequest>,
  success: ActionCreator<UpdateScheduledBreakSuccess>,
  failure: ActionCreator<UpdateScheduledBreakFailure>,
} = {
  request: createAction('UPDATE_SCHEDULED_BREAK_REQUEST'),
  success: createAction('UPDATE_SCHEDULED_BREAK_SUCCESS'),
  failure: createAction('UPDATE_SCHEDULED_BREAK_FAILURE'),
}

export function updatescheduledBreak(
  schedule: CreatedScheduledBreak
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        updateScheduledBreakActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(updateScheduledBreakActions.request())
      await client.inboxBreak.updateSchedule(schedule, { auth })

      dispatch(updateScheduledBreakActions.success({ ...schedule }))
      return true
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('inboxBreak.scheduledBreak.error.update'),
          toastVariants.error
        )
      )
      dispatch(updateScheduledBreakActions.failure({ message: e.message }))
      return false
    }
  }
}

export const removeScheduledBreakActions: {
  request: ActionCreator<RemoveScheduledBreakRequest>,
  success: ActionCreator<RemoveScheduledBreakSuccess>,
  failure: ActionCreator<RemoveScheduledBreakFailure>,
} = {
  request: createAction('REMOVE_SCHEDULED_BREAK_REQUEST'),
  success: createAction('REMOVE_SCHEDULED_BREAK_SUCCESS'),
  failure: createAction('REMOVE_SCHEDULED_BREAK_FAILURE'),
}

export function removeScheduledBreak(id: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    const schedule = selectors.getScheduledBreakById(id)(getState())

    if (auth === null) {
      dispatch(
        removeScheduledBreakActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(removeScheduledBreakActions.request({ id }))
      await client.inboxBreak.removeSchedule(id, { auth })
      dispatch(removeScheduledBreakActions.success())

      return true
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('inboxBreak.scheduledBreak.error.remove'),
          toastVariants.error
        )
      )
      dispatch(
        removeScheduledBreakActions.failure({
          message: e.message,
          backup: schedule,
        })
      )
      return false
    }
  }
}

export const fetchRecentMessagesActions: {
  request: ActionCreator<FetchRecentMessagesRequest>,
  success: ActionCreator<FetchRecentMessagesSuccess>,
  failure: ActionCreator<FetchRecentMessagesFailure>,
} = {
  request: createAction('FETCH_RECENT_MESSAGES_REQUEST'),
  success: createAction('FETCH_RECENT_MESSAGES_SUCCESS'),
  failure: createAction('FETCH_RECENT_MESSAGES_FAILURE'),
}

export function fetchRecentMessages(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchRecentMessagesActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchRecentMessagesActions.request())
      const res = await client.inboxBreak.fetchRecentMessages({ auth })

      dispatch(fetchRecentMessagesActions.success(res.result))
    } catch (e) {
      dispatch(fetchRecentMessagesActions.failure({ message: e.message }))
    }
  }
}

export const removeRecentMessageActions: {
  request: ActionCreator<RemoveRecentMessageRequest>,
  success: ActionCreator<RemoveRecentMessageSuccess>,
  failure: ActionCreator<RemoveRecentMessageFailure>,
} = {
  request: createAction('REMOVE_RECENT_MESSAGE_REQUEST'),
  success: createAction('REMOVE_RECENT_MESSAGE_SUCCESS'),
  failure: createAction('REMOVE_RECENT_MESSAGE_FAILURE'),
}

export function removeRecentMessage(id: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        removeRecentMessageActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(removeRecentMessageActions.request({ id }))
      await client.inboxBreak.removeRecentMessage(id, { auth })
      dispatch(removeRecentMessageActions.success())
    } catch (e) {
      dispatch(
        removeRecentMessageActions.failure({
          message: e.message,
        })
      )
    }
  }
}

export const executeBreakActions: {
  request: ActionCreator<ExecuteBreakRequest>,
  success: ActionCreator<ExecuteBreakSuccess>,
  failure: ActionCreator<ExecuteBreakFailure>,
} = {
  request: createAction('EXECUTE_BREAK_REQUEST'),
  success: createAction('EXECUTE_BREAK_SUCCESS'),
  failure: createAction('EXECUTE_BREAK_FAILURE'),
}

export function executeBreak({
  enable,
  enableAutoReply,
  replyMessage,
}: {
  enable: boolean,
  enableAutoReply: boolean,
  replyMessage: string,
}): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        executeBreakActions.failure({ message: i18next.t('notAuthenticated') })
      )
      return false
    }

    try {
      dispatch(executeBreakActions.request())
      await client.inboxBreak.executeBreak(
        { enable, enableAutoReply, replyMessage },
        { auth }
      )
      dispatch(
        executeBreakActions.success({
          enable,
          replyMessage,
          enableAutoReply,
        })
      )
      return true
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('inboxBreak.execute.error'),
          toastVariants.error
        )
      )
      dispatch(executeBreakActions.failure({ message: e.message }))
      return false
    }
  }
}

export const toggleFullscreenAlertFlag: ActionCreator<ToggleFullscreenAlertFlag> = createAction(
  'TOGGLE_FULLSCREEN_ALERT_FLAG'
)
