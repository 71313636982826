import DatePillItem from '@edison/webmail-ui/components/Pill/DatePill'
import React, { useEffect, useRef } from 'react'

export function DatePill(props) {
  const { onSelect, condition, isClearAll } = props
  const ref = useRef()
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])
  return (
    <DatePillItem
      ref={ref}
      onSelect={(start, end) => {
        const nextCondition = {
          ...condition,
          date: { start, end },
        }
        onSelect(nextCondition)
      }}
    />
  )
}
