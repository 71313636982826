// @flow
import React, { useEffect, useMemo, useRef } from 'react'
import cn from 'classnames'
import noop from 'lodash/noop'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import type { Dispatch } from 'types/redux'

import ComposeBody from './components/ComposeBody'
import From from './components/From'
import Recipients from './components/Recipients'

import AttachmentBox from './components/AttachmentBox'
import Subject from './components/Subject'
import {
  RESPONSE_TYPE_NONE,
  RESPONSE_TYPE_REPLY,
  composeStatus,
} from 'utils/constants'

import ResponseTypeSelect from './components/ResponseTypeSelect'
import {
  getDraft,
  getResponseType,
  getFromListSelector,
} from 'core/compose/selectors'
import { activeDraft } from 'core/compose/actions'

export default ({ draftId, onSelectRecipient = noop, ...rest }) => {
  const { t } = useTranslation()
  const { status, saved, updated, actived } = useSelector(
    useMemo(() => getDraft(draftId), [draftId])
  )
  const fromList = useSelector(getFromListSelector)
  const responseType = useSelector(
    useMemo(() => getResponseType(draftId), [draftId])
  )
  const needPromptBeforeUnload = updated ? !saved : false
  const dispatch: Dispatch = useDispatch()
  const headerRef = useRef()
  useEffect(() => {
    if (needPromptBeforeUnload) {
      let uploadEvent
      window.addEventListener(
        'beforeunload',
        (uploadEvent = e => {
          e.preventDefault()
          e.returnValue = t('compose.beforeUnload')
          return t('compose.beforeUnload')
        })
      )
      return () => {
        window.removeEventListener('beforeunload', uploadEvent)
      }
    }
  }, [needPromptBeforeUnload])

  const statusNode = useMemo(
    () =>
      status ? (
        <span
          className="inline-block text-gray-light whitespace-no-wrap text-right"
          style={{ minWidth: 100 }}
        >
          {status === composeStatus.saving
            ? t('compose.tip.saving')
            : status === composeStatus.saved
            ? t('compose.tip.saved')
            : null}
        </span>
      ) : null,
    [status]
  )

  const body = useMemo(
    () => (
      <ComposeBody draftId={draftId} key={responseType} headerRef={headerRef} />
    ),
    [draftId, responseType]
  )
  return (
    <>
      <div
        {...rest}
        className={cn('w-full h-full flex flex-col', {
          'overflow-auto': responseType === RESPONSE_TYPE_NONE,
        })}
        onClick={() => !actived && dispatch(activeDraft(draftId))}
      >
        <div className="flex-grow-0 sticky top-0 z-10 bg-app" ref={headerRef}>
          <div
            className={`flex border-b p-4 ${
              responseType !== RESPONSE_TYPE_NONE ? 'py-2' : ''
            }`}
          >
            {responseType !== RESPONSE_TYPE_NONE && (
              <div className="flex-shrink-0 pt-1">
                <ResponseTypeSelect draftId={draftId} />
              </div>
            )}
            <div className="z-10 text-sm flex-1">
              {responseType === RESPONSE_TYPE_REPLY ? (
                <Recipients
                  draftId={draftId}
                  onSelectRecipient={onSelectRecipient}
                  statusNode={statusNode}
                />
              ) : (
                <Recipients
                  draftId={draftId}
                  onSelectRecipient={onSelectRecipient}
                />
              )}
              {fromList.length > 1 && <From draftId={draftId} />}
              {responseType !== RESPONSE_TYPE_REPLY && (
                <div className="flex items-center justify-between">
                  <Subject draftId={draftId} />
                  {statusNode}
                </div>
              )}
            </div>
          </div>
        </div>

        {body}
      </div>
      <AttachmentBox draftId={draftId} />
    </>
  )
}
