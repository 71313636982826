// @flow
import React from 'react'
import { displayTitles } from 'utils/constants'

import Dialog from '@material-ui/core/Dialog'
import DndConfirmLayout from '@edison/webmail-ui/components/DndConfirm'
import { Title } from '@edison/webmail-ui/components/DndConfirm/Category'

type Props = {
  targetLabelId: string,
  threadIds: $ReadOnlyArray<string>,
  isOpen: boolean,
  toggle: () => void,
}
export default ({ targetLabelId, threadIds, isOpen, toggle }: Props) => {
  const name = displayTitles[targetLabelId]
  const isPlural = threadIds.length > 1

  return (
    <Dialog fullWidth open={isOpen} toggle={toggle}>
      <DndConfirmLayout
        onConfirm={toggle}
        title={<Title name={name} isPlural={isPlural} />}
      />
    </Dialog>
  )
}
