// @flow
import React from 'react'
import { useSelector } from 'react-redux'

import { useCardListCondition } from 'core/hooks'
import { useInboxQuote } from 'core/external/hooks'
import { getExternalAssetsLoading } from 'core/external/selectors'
import { getInboxZeroBackgroundSettings } from 'core/settings/selectors'

import ThreadListZero from '@edison/webmail-ui/components/InboxZero/ThreadListZero'
import ForwardView from 'common/ThreadList/ForwardView'

const ThreadZero = ({ showForward, onPress }) => {
  const quote = useInboxQuote()
  const { hasCard: hasSmartCard } = useCardListCondition()

  const { enable: enableInboxZeroBackground } = useSelector(
    getInboxZeroBackgroundSettings
  )

  const isExternalAssetsLoading = useSelector(getExternalAssetsLoading)

  if (isExternalAssetsLoading) return null

  if (showForward) {
    return <ForwardView zeroImage={!hasSmartCard} hasWraper onPress={onPress} />
  }
  if (hasSmartCard) {
    return <ThreadListZero />
  }

  if (quote) {
    return React.cloneElement(quote, { invert: enableInboxZeroBackground })
  }

  return <React.Fragment />
}

export default React.memo(ThreadZero)
