// @flow
import * as client from '@edison/webmail-core/api'
import { getAuth } from 'core/auth/selectors'
import { createAction } from 'utils/redux'

import type {
  UsageFetchRequest,
  UsageFetchSuccess,
  UsageFetchFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchUsageActions: {
  request: ActionCreator<UsageFetchRequest>,
  success: ActionCreator<UsageFetchSuccess>,
  failure: ActionCreator<UsageFetchFailure>,
} = {
  request: createAction('USAGE_FETCH_REQUEST'),
  success: createAction('USAGE_FETCH_SUCCESS'),
  failure: createAction('USAGE_FETCH_FAILURE'),
}

/**
 * Return user usage
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchUsage(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchUsageActions.failure({ message: 'User not logged in' }))
      return false
    }

    try {
      dispatch(fetchUsageActions.request())
      const res = await client.usage.fetch({ auth })
      dispatch(fetchUsageActions.success(res.result.storage))
      return true
    } catch (e) {
      dispatch(fetchUsageActions.failure({ message: e.message }))
      // dispatch(showNotification(e.message, toastVariants.error))
    }
    return false
  }
}
