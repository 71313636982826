// @flow
import { createSelector } from 'reselect'

import { initializeTypes } from './constants'

import type { Selector, State } from 'types/state'

const getInitializationState = (state: State) => state.initialization

const makeGetInitializeStatus = (name: string): Selector<boolean> => {
  return createSelector(
    getInitializationState,
    state => state.isInitialized[name] || false
  )
}

export const getAppInitializationStatus = makeGetInitializeStatus(
  initializeTypes.APP
)
export const getRefreshTokenInitializationStatus = makeGetInitializeStatus(
  initializeTypes.REFRESH_TOKEN
)
