import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import { labelNames } from 'utils/constants'

export const initalCondition = {
  accountLabel: [],
  from: [],
  to: [],
  email: [],
  attachment: false,
  attachmentType: [],
  date: { start: 0, end: 0 },
  label: [],
  split: [],
  isUnread: false,
  excludeIcs: false,
}

export function getIsInitalCondition(condition, inital = initalCondition) {
  return isEqual(inital, condition)
}

export function getTokenQuery(query) {
  let macthWord = ''
  for (let i = query.length - 1; i >= 0; i--) {
    if (query[i] === ' ' && query[i - 1] !== ' ') {
      return macthWord.trim()
    }
    macthWord = query[i] + macthWord
  }
  return query
}

export function isMatchQuery(value, query) {
  const str = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  return value.search(new RegExp(str, 'ig')) !== -1
}

export function getNextConditionLabels(
  pillLabels,
  selectedLabels,
  activeLabel,
  splitInboxLabels
) {
  const isInbox =
    activeLabel === labelNames.inbox ||
    activeLabel === labelNames.primary ||
    activeLabel === labelNames.other ||
    splitInboxLabels.includes(activeLabel)
  const labelIds = uniq([...pillLabels, ...selectedLabels.map(item => item.id)])
  if (isInbox) {
    return labelIds.length ? labelIds : []
  }
  return labelIds.length ? labelIds : [activeLabel]
}
