// @flow
import { createAction } from 'utils/redux'

import type { ShowSnackbar, HideSnackbar } from './types'
import type { ActionCreator } from 'types/redux'

export const showSnackbar: ActionCreator<ShowSnackbar> = createAction(
  'SNACKBAR_SHOW'
)
export const hideSnackbar: ActionCreator<HideSnackbar> = createAction(
  'SNACKBAR_HIDE'
)
