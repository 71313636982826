// @flow
import { useMemo } from 'react'
import { useRouteMatch, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import toLower from 'lodash/toLower'
import toPairs from 'lodash/toPairs'
import invert from 'lodash/invert'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import get from 'lodash/get'

import { useModal } from 'common/modals'
import { useOrderId } from 'core/auth/hooks'
import { labelActions } from 'core/metadata/actions'
import * as labelsSelector from './selectors'
import {
  modalTypes,
  labelNames,
  routePaths,
  displayTitles,
  reservedLabels,
  labelRouteNames,
} from 'utils/constants'

import type { Dispatch } from 'types/redux'

const allLabelsListSelector = labelsSelector.getAllLabels()
const allLabelsSelector = labelsSelector.getAllLabelsState()
const customLabelsSelector = labelsSelector.getCustomLabels()
const customLabelsMapSelector = labelsSelector.getCustomLabelsMap()

export function useLabels() {
  const all = useSelector(allLabelsListSelector)
  const custom = useSelector(customLabelsSelector)
  const created: $ReadOnlyArray<string> = all.flatMap(item => [
    item.name,
    item.id,
  ])

  return { custom, all, created }
}

export function useLabelValidator(ignore: string[] = []) {
  const { t } = useTranslation()
  const builtInLabels = toPairs({ ...displayTitles, ...reservedLabels })
    .flatMap(item => item)
    .map(toLower)

  const { created } = useLabels()
  const createdWithLowerCase = created
    .filter(labelName => !ignore.includes(labelName))
    .map(toLower)

  return {
    color: (color: ?string) => {
      if (!color) {
        return t('required')
      } else return undefined
    },
    name: (name: ?string) => {
      let error = undefined
      const nameWithLowerCase = String(name)
        .trim()
        .toLowerCase()

      if (!name) {
        error = t('required')
      } else if (!nameWithLowerCase) {
        error = t('settings.label.error.empty')
      } else if (builtInLabels.includes(nameWithLowerCase)) {
        error = t('settings.label.error.reserved', {
          value: nameWithLowerCase,
        })
      } else if (createdWithLowerCase.includes(nameWithLowerCase)) {
        error = t('settings.label.error.duplicated')
      }

      const matches = /([(){}:\-!#|&,.+"~*])|((\s|^)(([Aa][Nn][Dd])|([Nn][Oo][Tt])|([Oo][Rr]))(\s|$))/.exec(
        String(name)
      )
      if (!!matches) {
        error = t('settings.label.error.invalid', {
          value: matches[0].toUpperCase(),
        })
      }

      return error
    },
  }
}

/**
 * Parses the ID for label and split-inbox in route
 *
 * @public
 * @returns {string} - Current label ID in route
 */
export function useRouteLabel(): ?string {
  const match = useRouteMatch(routePaths.main)
  const subMatch = useRouteMatch(routePaths.splitInbox)

  const routeNamesInLabel = useMemo(() => invert(labelRouteNames), [])

  const label = toLower(get(match, 'params.label', ''))
  const splitInboxId = toLower(get(subMatch, 'params.splitInboxId', ''))

  return useMemo(() => {
    if (
      !match ||
      ['contacts', 'attachments', 'search', 'calendar', 'settings'].some(
        each => each === label
      )
    ) {
      // No match label
      return
    }

    if (label in routeNamesInLabel || splitInboxId in routeNamesInLabel) {
      return routeNamesInLabel[label] || routeNamesInLabel[splitInboxId]
    } else if (!isEmpty(splitInboxId)) {
      // Split inbox
      return splitInboxId
    } else if (keys(labelNames).includes(label)) {
      // System label
      return label === 'inbox' ? labelNames.primary : labelNames[label]
    } else {
      // User custom label
      return label
    }
  }, [label, splitInboxId])
}

/**
 * Maps the label ID to route URL
 */
export function useLabelInRoute(
  activeLabel: string,
  isSplitInbox: boolean
): string {
  const userId = useOrderId()
  const namesInLabel = useMemo(() => invert(labelNames), [])

  return useMemo(() => {
    let label = 'inbox'
    let splitInboxId

    if (activeLabel === labelNames.primary) {
      // Do nothing
    } else if (activeLabel === labelNames.other) {
      splitInboxId = labelRouteNames[labelNames.other]
    } else if (isSplitInbox) {
      splitInboxId = activeLabel
    } else if (activeLabel in labelRouteNames) {
      label = labelRouteNames[activeLabel]
    } else if (activeLabel in namesInLabel) {
      label = namesInLabel[activeLabel]
    } else {
      label = activeLabel
    }

    if (!!splitInboxId) {
      return generatePath(routePaths.splitInbox, {
        userId,
        label,
        splitInboxId,
      })
    } else {
      return generatePath(routePaths.main, { userId, label })
    }
  }, [activeLabel, isSplitInbox])
}

export function useLabelMeta(
  routeLabel: string
): { label: string, displayName: string } {
  const labels = useSelector(allLabelsSelector)
  const routeNamesInLabel: { [key: string]: string } = useMemo(
    () => invert(labelRouteNames),
    []
  )

  return useMemo(() => {
    let [label, displayName] = [routeLabel, routeLabel]

    if (routeLabel in routeNamesInLabel) {
      label = routeNamesInLabel[label]
      displayName = displayTitles[label]
    } else {
      const { id, name } = get(labels, labelNames[label] || label, {})
      label = id
      displayName = name
    }
    return {
      label,
      displayName,
    }
  }, [routeLabel])
}

export function useLabelPickerActions(
  affectedThreads: string | $ReadOnlyArray<string>
) {
  const dispatch: Dispatch = useDispatch()
  const labelDetail = useModal(modalTypes.labelDetail)
  const customLabels = useSelector(customLabelsMapSelector)

  const applyTo: $ReadOnlyArray<string> = Array.isArray(affectedThreads)
    ? affectedThreads
    : [affectedThreads]

  function add(name: string) {
    if (!!name) {
      labelDetail.showModal({ name, applyTo })
    }
  }

  function change(
    prevLabels: $ReadOnlyArray<string>,
    currLabels: $ReadOnlyArray<string>
  ) {
    // Handle the checked labels and return the new label for creation
    const checked = currLabels.filter(id => !prevLabels.includes(id))

    // Handle the unchecked custom labels
    const unchecked = prevLabels
      .filter(id => id in customLabels)
      .filter(id => !currLabels.includes(id))

    dispatch(labelActions.update(checked, unchecked).threads(applyTo))
  }

  return {
    add,
    change,
  }
}
