import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ResponseTypeSelect from './ResponseTypeSelect'
import {
  getResponseType,
  isDraftDisabled,
  getDraft,
} from 'core/compose/selectors'
import { reply, replyAll, forward } from 'core/messages/actions'
import {
  RESPONSE_TYPE_REPLY,
  RESPONSE_TYPE_FORWARD,
  RESPONSE_TYPE_REPLY_ALL,
} from 'utils/constants'
import { isShowReplyAll } from 'core/messages/selectors'

export default ({ draftId, ...props }) => {
  const responseType = useSelector(
    useMemo(() => getResponseType(draftId), [draftId])
  )

  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )
  const { responseMessageId } = useSelector(
    useMemo(() => getDraft(draftId), [draftId])
  )
  const showReplyAll = useSelector(
    useMemo(() => isShowReplyAll(responseMessageId), [responseMessageId])
  )
  const dispatch: Dispatch = useDispatch()
  return (
    <ResponseTypeSelect
      disabled={disabled}
      showReplyAll={showReplyAll}
      value={responseType}
      onChange={newResponseType => {
        if (newResponseType === RESPONSE_TYPE_REPLY) {
          dispatch(reply(responseMessageId))
        } else if (newResponseType === RESPONSE_TYPE_REPLY_ALL) {
          dispatch(replyAll(responseMessageId))
        } else if (newResponseType === RESPONSE_TYPE_FORWARD) {
          dispatch(forward(responseMessageId))
        }
      }}
    />
  )
}
