// @flow
import React, { useMemo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { connect } from 'formik'
import { onbrdEventReport } from 'core/analytics/actions'
import ChooseEmailAddress, {
  EmailAddress,
} from '@edison/webmail-ui/components/SignUp/ChooseEmailAddress'
import * as selectors from 'core/auth/selectors'
import { checkUsername, checkUsernameActions } from 'core/auth/actions'
import { usernameRegex, usernameMaxLength, routePaths } from 'utils/constants'
import { useOnboardingEvent } from 'hooks/useOnboardingEvent'
import type { RouterHistory } from 'react-router-dom'
import type { FormikProps } from 'formik'
import type { Dispatch } from 'types/redux'

type Props = {
  next: () => void,
  onSubmit: (value: string) => void,
  history: RouterHistory,
  initialValues: { email: string },
}

const debounceTime = 500
const getUsernameAvailability = debounce(
  (dispatch, username) => dispatch(checkUsername(username)),
  debounceTime
)

const View = ({ onSubmit, next, history, initialValues, ...rest }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const isUsernameTaken = useSelector(
    useMemo(() => selectors.isUsernameTaken())
  )
  const isVerifying = useSelector(
    useMemo(() => selectors.isVerifyingUsername())
  )
  const { report } = useOnboardingEvent(
    onbrdEventReport.onbrdChooseAddressScrInputSelected
  )
  const usernameMinLength = useSelector(selectors.getUsernameMinLength)
  const usernamePremiumBounds = useSelector(selectors.getUsernamePremiumBounds)
  useEffect(() => {
    dispatch(onbrdEventReport.onbrdChooseAddressScrView())
  }, [])
  const onLogin = () => {
    history.push(routePaths.login)
  }

  const _onSubmit = value => {
    dispatch(onbrdEventReport.onbrdWelcomeScrContinueSelected())
    onSubmit(value)
    next()
  }

  const validate = username => {
    report()
    if (username.length > 0) {
      if (!isVerifying) {
        dispatch(checkUsernameActions.request())
      }
      getUsernameAvailability(dispatch, username)
    }
  }

  useEffect(() => {
    validate(initialValues.email)
  }, [initialValues.email])

  return (
    <ChooseEmailAddress
      {...rest}
      initialValues={initialValues}
      onSubmit={_onSubmit}
      onLogin={onLogin}
      isLoading={isVerifying}
      isUsernameTaken={isUsernameTaken}
      validate={validate}
      emailRegex={usernameRegex}
      minLength={usernameMinLength}
      maxLength={usernameMaxLength}
      premiumBounds={usernamePremiumBounds}
    />
  )
}

export const InlineEmailAddress = connect(
  ({ formik, minLength }: { formik: FormikProps, minLength?: number }) => {
    const usernameRef = useRef('')
    const dispatch: Dispatch = useDispatch()
    const isUsernameTaken = useSelector(
      useMemo(() => selectors.isUsernameTaken())
    )
    const isVerifying = useSelector(
      useMemo(() => selectors.isVerifyingUsername())
    )
    const usernameMinLength = useSelector(selectors.getUsernameMinLength)
    const usernamePremiumBounds = useSelector(
      selectors.getUsernamePremiumBounds
    )

    const validate = username => {
      if (username.length > 0) {
        if (username !== usernameRef.current) {
          if (!isVerifying) {
            dispatch(checkUsernameActions.request())
          }
          getUsernameAvailability(dispatch, username)
          usernameRef.current = username
        }
      }
    }

    useEffect(() => {
      validate(formik.initialValues.email)
    }, [])

    return (
      <EmailAddress
        isLoading={isVerifying}
        isUsernameTaken={isUsernameTaken}
        validate={validate}
        emailRegex={usernameRegex}
        minLength={minLength || usernameMinLength}
        maxLength={usernameMaxLength}
        premiumBounds={usernamePremiumBounds}
      />
    )
  }
)

export default withRouter(View)
