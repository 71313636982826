// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import * as selectors from 'core/contacts/selectors'
import { getFormattedContact } from 'core/contacts/helpers'
import type { GalleryView } from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import { InfiniteLoader } from 'react-virtualized'
import Checkbox from '@edison/webmail-ui/components/Checkbox'
import Contacts, {
  GalleryPendingContact,
} from '@edison/webmail-ui/screens/Contacts'
import BaseBody from '@edison/webmail-ui/components/ContactsGallery/GalleryBody'
import Actions from './Actions'

import type { SplitInbox } from '@edison/webmail-core/types/split-inboxes'

const getContactStatusUpdating = selectors.isContactStatusUpdating()

type Props = {
  ids: $ReadOnlyArray<string>,
  selectAll: boolean,
  selected: Set<string>,
  onSelect: string => void,
  onAccept: (
    email?: string,
    options?: {
      isNeedUpdateSelected?: boolean,
      isClearSelected?: boolean,
    }
  ) => void,
  onBlock: (
    email?: string,
    options?: {
      isBlockSender?: boolean,
      isNeedUpdateSelected?: boolean,
      isClearSelected?: boolean,
    }
  ) => void,
  onMoveToSplit: (split: SplitInbox) => void,
  onNextNavigate: (
    view: GalleryView,
    currentData: any,
    isResetSelected?: boolean
  ) => void,
}

export default ({
  selected,
  selectAll,
  ids,
  onSelect,
  onAccept,
  onBlock,
  onMoveToSplit,
  onNextNavigate,
  ...rest
}: Props) => {
  const count = ids.length
  const contactsById = useSelector(selectors.getContactsById)
  const isContactStatusUpdating = useSelector(getContactStatusUpdating)
  const renderItem = ({ index, key, style }) => {
    const id = ids[index]
    const { email, name, avatar } = getFormattedContact(contactsById[id])

    return (
      <GalleryPendingContact
        onClick={() =>
          onNextNavigate(
            'gallery',
            {
              currentContact: {
                id,
              },
            },
            true
          )
        }
        key={email}
        name={name}
        style={style}
        email={email}
        avatar={avatar}
        onAccept={() =>
          onAccept(id, {
            isNeedUpdateSelected: true,
            isClearSelected: false,
          })
        }
        onBlock={() =>
          onBlock(id, {
            isNeedUpdateSelected: true,
            isClearSelected: false,
            isBlockSender: true,
          })
        }
        checkbox={
          <Checkbox
            name={email}
            checked={selected.has(id) || selectAll}
            onChange={() => onSelect(id)}
          />
        }
      />
    )
  }

  return (
    <BaseBody
      {...rest}
      action={
        (selected.size > 0 || selectAll) && (
          <Actions
            onlyBlockSender
            disabled={
              isContactStatusUpdating || (!selectAll && selected.size === 0)
            }
            onAccept={() =>
              onAccept('', {
                isNeedUpdateSelected: false,
                isClearSelected: true,
              })
            }
            onBlock={isBlockSender =>
              onBlock('', {
                isNeedUpdateSelected: false,
                isClearSelected: true,
                isBlockSender,
              })
            }
            onMoveToSplit={split => onMoveToSplit(split)}
          />
        )
      }
    >
      <InfiniteLoader threhold={2} rowCount={count} isRowLoaded={() => true}>
        {({ onRowsRendered, registerChild }) => (
          <Contacts
            count={count}
            ref={registerChild}
            rowRenderer={renderItem}
            onRowsRendered={onRowsRendered}
          />
        )}
      </InfiniteLoader>
    </BaseBody>
  )
}
