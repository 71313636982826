import { SinglePillItem } from '@edison/webmail-ui/components/Pill'
import React, { useEffect, useState } from 'react'

const DEFALUT_TEXT = 'Exclude calendar updates'

export function ExcludeUpdatePill(props) {
  const { onClick, condition, isClearAll } = props
  const [exclude, setExclude] = useState(false)
  useEffect(() => {
    if (isClearAll) {
      setExclude(false)
    }
  }, [isClearAll])
  return (
    <SinglePillItem
      active={exclude}
      onClick={() => {
        setExclude(!exclude)
        const nextCondition = {
          ...condition,
          excludeIcs: !exclude,
        }
        onClick(nextCondition)
      }}
    >
      {DEFALUT_TEXT}
    </SinglePillItem>
  )
}
