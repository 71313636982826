// @flow
import { createReducer } from 'utils/redux'
import omit from 'lodash/omit'
import * as actions from './actions'
import type {
  AddImages,
  DeleteImages,
  State,
  SignatureImageActions,
} from './types'

export const initialState: State = {}

const reducer = createReducer<State, SignatureImageActions>(initialState, {
  [actions.addImages.toString()]: addSignatureImages,
  [actions.deleteImages.toString()]: deleteImages,
})

function addSignatureImages(state: State, action: AddImages) {
  const images = action.payload

  return {
    ...state,
    ...images.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr,
      }),
      {}
    ),
  }
}

function deleteImages(state: State, action: DeleteImages) {
  const imageIds = action.payload
  return omit(state, imageIds)
}

export default reducer
