// @flow
import { createSelector } from 'reselect'

import { toastTypes } from 'utils/constants'

import type { State as ToastsState } from './types'
import type { State, Selector } from 'types/state'

export const selectToastsState = (state: State) => state.toasts

export function getToasts(): Selector<ToastsState> {
  return createSelector(
    selectToastsState,
    (toasts: ToastsState) => toasts
  )
}

export function getNotificationToasts(): Selector<ToastsState> {
  return createSelector(
    selectToastsState,
    (toasts: ToastsState) =>
      toasts.filter(each => each.props.type === toastTypes.notification)
  )
}

export function getLoadingToast(): Selector<ToastsState> {
  return createSelector(
    selectToastsState,
    (toasts: ToastsState) =>
      toasts.filter(each => each.props.type === toastTypes.loading)
  )
}
