import LRU from 'lru-cache'
import { loadJSON, recentSearchQueryKey, recentViewEmailsKey } from '../storage'

const DEFAULT_MAX_CACHE_COUNT = 3

export class LRUCache {
  constructor(cacheValue, options = {}) {
    this.cache = new LRU({
      ...options,
      max: options.max || DEFAULT_MAX_CACHE_COUNT,
    })
    for (const item of cacheValue) {
      this.cache.set(item.key, item.value)
    }
  }
  set(key, value) {
    this.cache.set(key, value)
  }
  get(key) {
    return this.cache.get(key)
  }
  getAllValues() {
    return this.cache.values()
  }
  delete(key) {
    this.cache.del(key)
  }
}

let cacheSearchQueryLRU = null
export function cacheSearchQuery(orderId = 0) {
  const searchCache = loadJSON(recentSearchQueryKey, { [orderId]: [] })
  const result = []
  const cacheSearch = searchCache[orderId] || []
  for (const query of cacheSearch) {
    result.unshift({
      key: query,
      value: query,
    })
  }
  if (!cacheSearchQueryLRU) {
    cacheSearchQueryLRU = new LRUCache(result)
  }
  return cacheSearchQueryLRU
}

let cacheRecentViewEmailsLRU = null
export function cacheRecentViewEmails(orderId = 0) {
  const emailsIdCache = loadJSON(recentViewEmailsKey, { [orderId]: [] })
  const result = []
  const cacheEmails = emailsIdCache[orderId] || []
  for (const id of cacheEmails) {
    result.unshift({
      key: id,
      value: id,
    })
  }
  if (!cacheRecentViewEmailsLRU) {
    cacheRecentViewEmailsLRU = new LRUCache(result)
  }
  return cacheRecentViewEmailsLRU
}
