import moment from 'moment'
import { inlineCSSHtml } from '@edison/webmail-ui/utils/inlineCSS'
import escape from 'escape-html'

export const QUOTATION_DATE_FORMAT = 'MMM D, YYYY'
export const QUOTATION_TIME_FORMAT = 'HH:mm'

export const quotationDate = date => {
  const momentObj = moment(date * 1000)
  return `${momentObj.format(QUOTATION_DATE_FORMAT)} at ${momentObj.format(
    QUOTATION_TIME_FORMAT
  )}`
}
export function generateSignatureSection(signature) {
  return `<br><div class="webmail_signature">${signature}</div>`
}

function generateEmailLink(email, name) {
  return `<a href="mailto:${email}" target="_blank" rel="noopener noreferrer">${name}</a>`
}

export function generateReplyContext(message, signature) {
  return `${signature !== undefined ? generateSignatureSection(signature) : ''}
  <br><div class="webmail_quote">
    <blockquote style="margin: 1ex 0 0 0; height: min-content; border-left: 1px #ccc solid; padding-left: 0.5ex;"  type="cite">
      <div>
        <div class="webmail_attr">
          <a
            style="color: currentcolor; text-decoration-color: rgba(128, 128, 128, 0.38); "
            dir="ltr"
            >On ${quotationDate(message.date)}</a>, &lt;${generateEmailLink(
    escape(message.from.email),
    escape(message.from.name)
  )}&gt; wrote:<br/><br/>
        </div>
        <div>
          ${
            message.html
              ? formatMessageHTML(message.html)
              : formatMessageText(message.text)
          }
        </div>
      </div>
    </blockquote>
  </div>`
}

export function generateForwardContext(message, signature) {
  return `${signature !== undefined ? generateSignatureSection(signature) : ''}
  <br><div class="webmail_quote">
  <br /><br />Begin forwarded message:<br /><br />
  <blockquote style="margin: 1ex 0 0 0; height: min-content; border-left: 1px #ccc solid; padding-left: 0.5ex;" type="cite">
    <div>
      <b>From: </b> ${escape(message.from.name)} &lt;<a
        href="mailto:${escape(message.from.email)}"
        target="_blank"
        rel="noopener noreferrer"
        >${escape(message.from.name)}</a>&gt;<br/><b>Date: </b>${quotationDate(
    message.date
  )}<br/><b>To: </b>
      ${message.to
        .map(item =>
          item.name
            ? `${escape(item.name)}
      &lt;${generateEmailLink(escape(item.email), escape(item.email))}&gt;`
            : generateEmailLink(escape(item.email), escape(item.email))
        )
        .join('; ')}${
    message.cc.length
      ? `<br/>Cc: ${message.cc
          .map(item =>
            item.name
              ? `${escape(item.name)} &lt;${generateEmailLink(
                  escape(item.email),
                  escape(item.email)
                )}&gt;`
              : generateEmailLink(escape(item.email), escape(item.email))
          )
          .join('; ')}`
      : ''
  }<br/><b>Subject: ${escape(message.subject)}</b><br/><br/>
    </div>
    <div>
      ${
        message.html
          ? formatMessageHTML(message.html)
          : formatMessageText(message.text)
      }
    </div>
  </blockquote>
</div>`
}

export function formatMessageText(text) {
  return `<div>${text
    .split('\n')
    .map(item => (item ? escape(item) : ''))
    .join('<br>')}</div>`
}
const formatMessageHTML = html => {
  return inlineCSSHtml(html)
}
