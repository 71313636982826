// @flow
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  UpgradePrompt,
  UpgradeToFreelancePrompt,
  GetYourFreeDomainPrompt,
  UpgradeTip,
} from '@edison/webmail-ui/components/Navigation/components/Usage'
import { Usage } from '@edison/webmail-ui/components/Navigation'
import {
  getUsageSelector,
  getUpgradePlanNameSelector,
  getUpgradePromptTypeSelector,
} from 'core/usage/selectors'
import { getFeatureFlags } from 'core/premium/selectors'
import { checkAndFetchPlans } from 'core/premium/actions'
import * as analytics from 'core/analytics'
import { upgradePlanPromptTypes } from 'utils/constants'
import { toggleUpgradeTipFlag, getUpgradeTipFlag } from 'common/storage'
import { useInboxZeroConditon } from 'core/hooks'

import type { Dispatch } from 'types/redux'

const View = () => {
  const history = useHistory()
  const { userId } = useParams()
  const usage = useSelector(getUsageSelector)
  const featureFlags = useSelector(getFeatureFlags)

  const upgradePlanName = useSelector(getUpgradePlanNameSelector)
  const upgradePromptType = useSelector(getUpgradePromptTypeSelector)
  const dispatch: Dispatch = useDispatch()

  const [hardRefresh, setHardRefresh] = useState({})

  const isInboxZero = useInboxZeroConditon()

  const hideUpgradeTip = useMemo(() => getUpgradeTipFlag(), [hardRefresh])

  const onUpgrade = useCallback(() => {
    dispatch(analytics.actions.premium.userUpgradePrompt())
    history.push(`/u/${userId || 0}/pricing`)
  }, [])

  const onGetCustomDomain = useCallback(() => {
    history.push(`/u/${userId || 0}/add-domain/hosted`)
  }, [])

  const tip = useMemo(() => {
    // Don't show the tip when user closed it or there's no plan to upgrade
    if (!upgradePlanName || hideUpgradeTip) return null
    return (
      <UpgradeTip
        insert={isInboxZero}
        onUpgrade={onUpgrade}
        upgradePlanName={upgradePlanName}
        onClose={() => toggleUpgradeTipFlag()}
        onTransitionEnd={() => setHardRefresh({})}
      />
    )
  }, [upgradePlanName, hideUpgradeTip, isInboxZero])
  useEffect(() => {
    dispatch(checkAndFetchPlans())
  }, [])

  const promptNode = useMemo(() => {
    switch (upgradePromptType) {
      case upgradePlanPromptTypes.upgradeToFreelance:
        return <UpgradeToFreelancePrompt onUpgrade={onUpgrade} />
      case upgradePlanPromptTypes.getYourFreeDomain:
        return <GetYourFreeDomainPrompt onUpgrade={onGetCustomDomain} />
      case upgradePlanPromptTypes.upgrade:
        return (
          <UpgradePrompt
            upgradePlanName={upgradePlanName}
            onUpgrade={onUpgrade}
          />
        )
      default:
        return null
    }
  }, [upgradePromptType])

  return (
    <div className="relative">
      <Usage
        usage={usage || 0}
        total={featureFlags.totalStorageLimit}
        promptNode={promptNode}
        onUpgrade={onUpgrade}
        upgradePlanName={upgradePlanName}
      />
      {tip}
    </div>
  )
}

View.displayName = 'UsageView'

export default View
