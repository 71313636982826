// @flow
import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'

import { getLabelTotalCount, getLabelUnreadCount } from 'core/labels/selectors'
import { dropThreads } from 'core/threads/actions'
import { useActiveLabel } from 'core/threads/hooks'
import { useBetaFeature } from 'core/beta-features/hooks'
import { useRetrofitAccounts } from 'core/retrofit/hooks'

import Label from '@edison/webmail-ui/components/Navigation/components/Label'
import Labels from '@edison/webmail-ui/components/Navigation/components/Labels'
import AccountSwitcher from 'screens/Retrofit/AccountSwitcher'
import DroppableLabel from './DroppableLabel'

import { getLabelUnreadConfig } from 'utils'
import { labelNames, displayTitles } from 'utils/constants'
import { betaFeatures } from '@edison/webmail-core/utils/constants'

import type { Dispatch } from 'types/redux'

const totalSelector = getLabelTotalCount()
const labelsUnreadSelector = getLabelUnreadCount()

type Props = {
  invert: boolean,
  activeId: string,
  setLabel: (labelId: string) => void,
}
export default ({ invert, activeId, setLabel }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const activeLabel = useActiveLabel()
  const total = useSelector(totalSelector)
  const unreadLabels = useSelector(labelsUnreadSelector)

  const labels = useMemo(
    () =>
      [
        {
          id: 'inbox',
          droppable: true,
        },
        {
          id: 'archive',
          droppable: true,
        },
        {
          id: 'sent',
          droppable: false,
        },
        {
          id: 'drafts',
          droppable: false,
        },
        {
          id: 'unread',
          droppable:
            activeLabel !== labelNames.trash && activeLabel !== labelNames.spam,
        },
        {
          id: 'trash',
          droppable: true,
        },
        {
          id: 'spam',
          droppable: true,
        },
      ].map(({ id, droppable }) => ({
        id,
        title: displayTitles[labelNames[id]],
        Component: droppable ? DroppableLabel : Label,
      })),
    [activeLabel]
  )

  const { isEnable: isRetrofitEnable } = useBetaFeature(betaFeatures.retrofit)
  const { accounts } = useRetrofitAccounts()

  const header = useMemo(() => {
    if (isRetrofitEnable && accounts.length > 0) {
      return <AccountSwitcher />
    } else return null
  }, [isRetrofitEnable, accounts])

  return (
    <Labels enableCollapse={false} header={header}>
      {labels.map(({ id, title, Component }, index) => {
        let unreadNumber = 0
        const labelId = labelNames[id] || id
        const { display, displayTotal } = getLabelUnreadConfig(labelId)
        if (display) {
          unreadNumber = displayTotal
            ? total[labelNames.drafts]
            : get(unreadLabels, labelId, 0)
        }

        return (
          <Component
            id={id}
            key={id}
            name={title}
            invert={invert}
            unread={unreadNumber}
            isActive={id === activeId}
            onClick={() => setLabel(id)}
            onDrop={() =>
              dispatch(
                dropThreads({
                  from: labelNames[activeId] || activeId,
                  to: labelNames[id] || id,
                })
              )
            }
          />
        )
      })}
    </Labels>
  )
}
