// @flow
import omit from 'lodash/omit'
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type { State, ShowSnackbar, HideSnackbar } from './types'

const initialState: State = {}

export default createReducer<State, ShowSnackbar | HideSnackbar>(initialState, {
  [actions.showSnackbar.toString()]: showSnackbar,
  [actions.hideSnackbar.toString()]: hideSnackbar,
})

function showSnackbar(state: State, action: ShowSnackbar) {
  const { key, props = {} } = action.payload
  return {
    ...state,
    [key]: props,
  }
}

function hideSnackbar(state: State, action: HideSnackbar) {
  const { key } = action.payload
  return omit(state, [key])
}
