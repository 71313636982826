// @flow
import values from 'lodash/values'
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'

import type { State } from './reducers'
import type { Selector, State as GlobalState } from 'types/state'

import type {
  CreatedScheduledBreak,
  RecentReplyMessage,
} from '@edison/webmail-core/types/inbox-break'

const getInboxBreakState = (state: GlobalState) => state.inboxBreak

export const getScheduledBreaks: Selector<
  $ReadOnlyArray<CreatedScheduledBreak>
> = createSelector(getInboxBreakState, (state: State) => {
  return values(state.schedules)
})

export function getScheduledBreakById(
  id: string
): Selector<?CreatedScheduledBreak> {
  return createSelector(getInboxBreakState, (state: State) => {
    return state.schedules[id]
  })
}

export const getRecentMessages: Selector<
  $ReadOnlyArray<RecentReplyMessage>
> = createSelector(getInboxBreakState, (state: State) => {
  return values(state.recentMessages)
})

export function getRecentMessageById(
  id: string
): Selector<?RecentReplyMessage> {
  return createSelector(getInboxBreakState, (state: State) => {
    return state.recentMessages[id]
  })
}

export const isBreakEnable: Selector<boolean> = createSelector(
  getInboxBreakState,
  (state: State) => state.enable
)

export const getCurrentBreakInfo: Selector<{
  enable: boolean,
  breakUntil: number,
  replyMessage: string,
  breakTimezone: string,
  enableAutoReply: boolean,
}> = createSelector(getInboxBreakState, (state: State) => {
  const {
    enable,
    breakUntil,
    breakTimezone,
    replyMessage,
    enableAutoReply,
  } = state

  return { enable, breakUntil, breakTimezone, enableAutoReply, replyMessage }
})

export const isBreakStatusLoading = getLoadingStatus('FETCH_BREAK_STATUS')
export const isBreakSettingsLoading = getLoadingStatus('FETCH_BREAK_SETTINGS')
export const isScheduledBreakCreating = getLoadingStatus(
  'CREATE_SCHEDULED_BREAK'
)
export const isScheduledBreakUpdating = getLoadingStatus(
  'UPDATE_SCHEDULED_BREAK'
)
export const isScheduledBreakRemoving = getLoadingStatus(
  'REMOVE_SCHEDULED_BREAK'
)
export const isExecuteBreakLoading = getLoadingStatus('EXECUTE_BREAK')

export const getStatusTimer: Selector<?TimeoutID> = createSelector(
  getInboxBreakState,
  (state: State) => state.statusTimer
)

export const isFullscreenAlertShowed: Selector<boolean> = createSelector(
  getInboxBreakState,
  (state: State) => state.flags.fullscreenAlert
)
