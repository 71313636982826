// @flow
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchContactAttachments } from 'core/contacts/actions'
import { getFileAttachmentsById } from 'core/contacts/selectors'
import { usePreviewModal } from 'core/previews/hooks'
import { dateFormatter } from 'utils'

import FilesLayout, {
  File as FileItem,
} from '@edison/webmail-ui/components/ContactDetail/FilesView'
import FileIcon from '@edison/webmail-ui/components/FileIcon'

import type { Dispatch } from 'types/redux'

type Props = {
  id: string,
  idType?: 'ID' | 'EMAIL',
}

export default ({ id, idType = 'ID' }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const previews = usePreviewModal()
  const attachments = useSelector(getFileAttachmentsById(id))

  useEffect(() => {
    if (!isNil(id)) {
      dispatch(fetchContactAttachments(id, idType))
    }
  }, [id])

  return (
    <FilesLayout>
      {attachments.map((attachment, index) => {
        const { name, contentType, date } = attachment
        const onClick = () => {
          previews.showModal({ attachments, index })
        }
        const type = contentType.split('/')[0]
        return (
          <FileItem
            key={`${name}-${index}`}
            icon={
              <FileIcon contentType={contentType} fileName={name} icon={type} />
            }
            name={name}
            type={type}
            date={dateFormatter(date)}
            onClick={onClick}
          />
        )
      })}
    </FilesLayout>
  )
}
