import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { OnMailThemeProvider } from '@edison/webmail-ui/styles/theme'
import MomentUtils from '@date-io/moment'

import '@edison/webmail-ui/styles/index.css'
import '@edison/webmail-ui/i18n'
import 'firebase/analytics'
import 'firebase/remote-config'

import { ApplicationError } from '@edison/webmail-ui/components/ErrorBoundaries'
import { useThemePrefer } from '@edison/webmail-ui/hooks/themePrefer'
import { themeMode } from '@edison/webmail-ui/utils/constants'
import { openFeedbackDialog } from 'utils/logs'
import { getThemePrefer } from 'common/storage'
import { initialize } from 'utils/logs'

import ErrorBoundary from 'common/ErrorBoundary'
import store from './store'
import Routes from './routes'

import { FlagsContextProvider } from 'core/flags/context'

// Turns on Webpack hot-reloading if it exists - no more refresh!
if (module.hot) {
  module.hot.accept()
}

let needRefresh = false

if (process.env.NODE_ENV === 'production') {
  const OfflinePluginRuntime = require('offline-plugin/runtime')
  OfflinePluginRuntime.install({
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating')
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady')
      // Tells to new SW to take control immediately
      OfflinePluginRuntime.applyUpdate()
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated')
      needRefresh = true
    },
    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed')
    },
  })
}

const App = () => {
  const prefer = useThemePrefer()

  const mode = useMemo(() => {
    const setting = getThemePrefer()

    if (setting === themeMode.LIGHT || setting === themeMode.DARK) {
      return setting
    } else {
      return prefer
    }
  }, [prefer])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <ErrorBoundary
          needRefresh={needRefresh}
          fallback={({ eventId }) => (
            <ApplicationError
              onRetry={() => window.location.reload(false)}
              onContact={() => openFeedbackDialog(eventId)}
            />
          )}
        >
          <OnMailThemeProvider mode={mode}>
            <FlagsContextProvider>
              <Routes />
            </FlagsContextProvider>
          </OnMailThemeProvider>
        </ErrorBoundary>
      </Provider>
    </MuiPickersUtilsProvider>
  )
}

initialize()

ReactDOM.render(<App />, document.getElementById('root'))
