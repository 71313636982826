// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type { State, UsageActions, UsageFetchSuccess } from './types'

export const initialState: State = {
  messageUsed: 0,
  largeAttachmentUsed: 0,
  messageNum: 0,
  largeAttachmentNum: 0,
  limits: { largeAttachmentNumPerLink: 100 },
}

const reducer = createReducer<State, UsageActions>(initialState, {
  [actions.fetchUsageActions.success.toString()]: fetchUsage,
})

function fetchUsage(state: State, action: UsageFetchSuccess): State {
  const usage = action.payload
  return usage
}

export default reducer
