// @flow
import { createReducer } from 'utils/redux'
import { fetchColorsAction } from './actions-colors'
import { clearCalendarUserDataAction } from './actions-common'
import { ReduxColors } from './modal-redux-colors'
import type {
  ColorsActions,
  ColorsState,
  FetchColorsRequestSuccess,
} from './types-colors'
import type { ClearCalendarUserData } from './types-common'

export const initColorsState: ColorsState = new ReduxColors()
function fetchColorsSuccess(
  state: ColorsState,
  action: FetchColorsRequestSuccess
) {
  const newColors = ReduxColors.ifNewerReturnNewColors(state, action.payload)
  if (newColors) {
    return newColors
  }
  return state
}
const onClearUserData = (state: ColorsState, action: ClearCalendarUserData) => {
  return new ReduxColors()
}
export default createReducer<ColorsState, ColorsActions>(initColorsState, {
  [fetchColorsAction.success.toString()]: fetchColorsSuccess,
  [clearCalendarUserDataAction.toString()]: onClearUserData,
})
