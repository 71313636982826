// @flow

/**
 * Exports for all core shared data and logic.
 */
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import * as auth from './auth'
import * as labels from './labels'
import * as messages from './messages'
import * as profile from './profile'
import * as threads from './threads'
import * as loading from './loading'
import * as toasts from './toasts'
import * as calendar from './calendar'
import * as credentials from './credentials'
import * as contacts from './contacts'
import * as settings from './settings'
import * as signatureImages from './signature-images'
import * as modals from './modals'
import * as compose from './compose'
import * as filters from './filters'
import * as splitInboxes from './split-inboxes'
import * as search from './search'
import * as external from './external'
import * as metadata from './metadata'
import * as largeAttachments from './large-attachments'
import * as attachmentLinks from './attachment-links'
import * as attachments from './attachments'
import * as snackbars from './snackbars'
import * as sifts from './sifts'
import * as customDomains from './custom-domains'
import * as usage from './usage'
import * as premium from './premium'
import * as previews from './previews'
import * as emailNudges from './email-nudges'
import * as analytics from './analytics'
import * as initialization from './initialization'
import * as kochava from './kochava'
import * as retrofit from './retrofit'
import * as betaFeatures from './beta-features'
import * as cleanup from './cleanup'
import * as inboxBreak from './inbox-break'

import * as commonActions from './actions'
import * as commonSelectors from './selectors'

import type { Action } from 'types/actions'

export const createRootReducer = (history: any) =>
  combineReducers<_, Action>({
    initialization: initialization.reducer,
    kochava: initialization.reducer,
    router: connectRouter(history),
    auth: auth.reducer,
    labels: labels.reducer,
    messages: messages.reducer,
    profile: profile.reducer,
    threads: threads.reducer,
    loading: loading.reducer,
    toasts: toasts.reducer,
    calendar: calendar.reducer,
    credentials: credentials.reducer,
    contacts: contacts.reducer,
    settings: settings.reducer,
    signatureImages: signatureImages.reducer,
    modals: modals.reducer,
    compose: compose.reducer,
    filters: filters.reducer,
    splitInboxes: splitInboxes.reducers,
    search: search.reducer,
    external: external.reducer,
    metadata: metadata.reducer,
    largeAttachments: largeAttachments.reducer,
    attachmentLinks: attachmentLinks.reducer,
    attachments: attachments.reducer,
    snackbars: snackbars.reducer,
    sifts: sifts.reducer,
    customDomains: customDomains.reducer,
    usage: usage.reducer,
    premium: premium.reducer,
    previews: previews.reducer,
    emailNudges: emailNudges.reducer,
    retrofit: retrofit.reducer,
    betaFeatures: betaFeatures.reducer,
    cleanup: cleanup.reducer,
    inboxBreak: inboxBreak.reducer,
  })

export const actions = {
  initialization: initialization.actions,
  kochava: kochava.actions,
  auth: auth.actions,
  labels: labels.actions,
  messages: messages.actions,
  profile: profile.actions,
  threads: threads.actions,
  shared: commonActions,
  loading: loading.actions,
  toasts: toasts.actions,
  credentials: credentials.actions,
  contacts: contacts.actions,
  settings: settings.actions,
  signatureImages: signatureImages.actions,
  modals: modals.actions,
  compose: compose.actions,
  filters: filters.actions,
  splitInboxes: splitInboxes.actions,
  search: search.actions,
  metadata: metadata.actions,
  largeAttachments: largeAttachments.actions,
  attachmentLinks: attachmentLinks.actions,
  attachments: attachments.actions,
  snackbars: snackbars.actions,
  sifts: sifts.actions,
  customDomains: customDomains.actions,
  usage: usage.actions,
  premium: premium.actions,
  previews: previews.actions,
  emailNudges: emailNudges.actions,
  analytics: analytics.actions,
  retrofit: retrofit.actions,
  betaFeatures: betaFeatures.actions,
  cleanup: cleanup.actions,
  inboxBreak: inboxBreak.actions,
}

export const selectors = {
  initialization: initialization.selectors,
  auth: auth.selectors,
  labels: labels.selectors,
  messages: messages.selectors,
  profile: profile.selectors,
  threads: threads.selectors,
  shared: commonSelectors,
  loading: loading.selectors,
  toasts: toasts.selectors,
  credentials: credentials.selectors,
  contacts: contacts.selectors,
  settings: settings.selectors,
  signatureImages: signatureImages.selectors,
  modals: modals.selectors,
  compose: compose.selectors,
  filters: filters.selectors,
  splitInboxes: splitInboxes.selectors,
  metadata: metadata.selectors,
  largeAttachments: largeAttachments.selectors,
  attachmentLinks: attachmentLinks.selectors,
  attachments: attachments.selectors,
  snackbars: snackbars.selectors,
  sifts: sifts.selectors,
  customDomains: customDomains.selectors,
  usage: usage.selectors,
  premium: premium.selectors,
  previews: previews.selectors,
  emailNudges: emailNudges.selectors,
  analytics: analytics.selectors,
  retrofit: retrofit.selectors,
  betaFeatures: betaFeatures.selectors,
  cleanup: cleanup.selectors,
  inboxBreak: inboxBreak.selectors,
}
