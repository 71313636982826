//@flow
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEvent,
  getICSEventDataByKey,
  getPrimaryCalendar,
  getRange,
  getSettings,
} from '../../core/calendar/selectors'
import {
  fetchCalendars,
  fetchICSEventData,
  updateEvenOrInstanceAttendee,
} from '../../core/calendar/actions'
import NotAvailable from '@edison/webmail-ui/components/Preview/NotAvailable'
import ICS from '@edison/webmail-ui/components/Preview/ICS'
import Loading from '@edison/webmail-ui/components/Preview/Loading'
import { convertRRuleToRepeatString } from '@edison/webmail-ui/screens/Calendar/rruleUtils'
import { formatEventDate } from '@edison/webmail-ui/screens/Calendar/utils'
import { useHistory } from 'react-router-dom'
import { routePaths } from '../../utils/constants'
import { useOrderId } from '../../core/auth/hooks'
import moment from 'moment'
import { getInstanceById } from '../../core/calendar/selectors-events'
import {
  calendarEventResponseStatusToRSVPStatus,
  generateCalendarUrl,
  updateEventAttendeeRSVPStatus,
} from '../../utils/calendar'
import { getAuth } from '../../core/auth/selectors'
import { showNotification } from '../../core/toasts/actions'
import { toastVariants } from '../toasts'
import i18next from 'i18next'
import { getDomainAliasesSelector } from '../../core/custom-domains/selectors'
import { stringArrayIncludes } from '@edison/functools'

const rsvpChangeErrorText = i18next.t('calendar.event.rsvp.update.error')
const ICSAttachmentPreview = ({
  attachment,
  onClose,
  onDownload,
  disableActions = false,
}) => {
  const componentMounted = useRef(true)
  useEffect(() => {
    return () => {
      componentMounted.current = false
    }
  }, [])
  const dispatch = useDispatch()
  const history = useHistory()
  const userId = useOrderId()
  const userAliases = useSelector(getDomainAliasesSelector)
  const uiRange = useSelector(getRange)
  const calendarSettings = useSelector(getSettings)
  const eventId = useMemo(() => {
    let eventIds = attachment.eventIds
    if (!Array.isArray(eventIds)) {
      if (
        attachment.extendInfo &&
        attachment.extendInfo.ics &&
        Array.isArray(attachment.extendInfo.ics.uids)
      ) {
        eventIds = attachment.extendInfo.ics.uids
      } else {
        eventIds = []
      }
    }
    return eventIds.length > 0 ? eventIds[0] : ''
  }, [attachment])
  const [missing, setMissing] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const primaryCalendar = useSelector(getPrimaryCalendar())
  const startTime = moment()
    .startOf('day')
    .unix()
  const icsData = useSelector(
    getICSEventDataByKey({
      calendarId: primaryCalendar.id,
      eventOrInstanceId: eventId,
      startTime,
    })
  )
  const event = useSelector(
    getEvent({
      calendarId: !!icsData ? icsData.calendarId : '',
      eventId: !!icsData ? icsData.eventId : '',
    })
  )
  const instance = useSelector(
    getInstanceById({
      calendarId: !!icsData ? icsData.calendarId : '',
      eventId: !!icsData ? icsData.eventId : '',
      instanceId: !!icsData ? icsData.instanceId : '',
    })
  )
  const rsvpEvent = useMemo(() => {
    if (event && event.eventId === eventId) {
      return event
    }
    if (instance && instance.instanceId === eventId) {
      return instance
    }
    return null
  }, [eventId, event, instance])
  const auth = useSelector(useMemo(() => getAuth(), []))
  const rsvpAttendee = useMemo(() => {
    if (rsvpEvent) {
      return rsvpEvent.getAttendeeByEmails(userAliases) || { email: auth.user }
    }
    return null
  }, [rsvpEvent, auth])
  const rsvpStatus = useMemo(() => {
    if (rsvpAttendee) {
      return calendarEventResponseStatusToRSVPStatus(
        rsvpAttendee.responseStatus
      )
    }
    return ''
  }, [rsvpAttendee ? rsvpAttendee.responseStatus : ''])
  const eventOrInstance = useMemo(() => {
    if (icsData && icsData.data && icsData.data.isInstance) {
      if (instance) {
        return instance
      }
      return icsData.data
    }
    return event
  }, [event, instance, icsData])
  const onRSVPChange = useMemo(() => {
    if (disableActions) {
      return null
    }
    return status => {
      if (rsvpEvent && rsvpAttendee) {
        updateEventAttendeeRSVPStatus(rsvpAttendee, status)
        return dispatch(
          updateEvenOrInstanceAttendee({
            eventOrInstance: rsvpEvent,
            attendee: rsvpAttendee,
          })
        )
          .then(() => {
            return Promise.resolve()
          })
          .catch(e => {
            dispatch(showNotification(rsvpChangeErrorText, toastVariants.error))
            return Promise.reject()
          })
      }
      dispatch(showNotification(rsvpChangeErrorText, toastVariants.error))
      return Promise.reject()
    }
  }, [rsvpEvent, rsvpAttendee, disableActions, dispatch])
  useEffect(() => {
    if (!primaryCalendar.id) {
      dispatch(fetchCalendars()).catch(() => {
        console.error(`Fetching event calendars ${eventId} failed`)
        if (componentMounted.current) {
          setMissing(true)
          setDataFetched(true)
        }
      })
    } else {
      dispatch(
        fetchICSEventData({
          calendarId: primaryCalendar.id,
          eventOrInstanceId: eventId,
          startTimestamp: startTime,
        })
      ).then(
        () => {
          if (componentMounted.current) {
            setDataFetched(true)
          }
        },
        () => {
          if (componentMounted.current) {
            setMissing(true)
            setDataFetched(true)
          }
        }
      )
    }
  }, [primaryCalendar.id])
  const calendarUrl = useMemo(() => {
    if (!eventOrInstance || disableActions || rsvpStatus === 'no') {
      return ''
    }
    return generateCalendarUrl({
      resolution: uiRange.resolution,
      userId,
      timezone: calendarSettings.timezone,
      basePath: routePaths.calendarView,
      eventOrInstance,
    })
  }, [eventOrInstance, disableActions, rsvpStatus])
  const onViewInCalendar = useMemo(() => {
    if (!calendarUrl) {
      return undefined
    }
    if (
      eventOrInstance &&
      (eventOrInstance.isGhost || eventOrInstance.cancelled)
    ) {
      return undefined
    }
    return () => {
      if (eventOrInstance) {
        if (onClose) {
          onClose()
        }
        history.push(calendarUrl)
      }
    }
  }, [calendarUrl, eventOrInstance])
  const hideRSVPStatus = useMemo(() => {
    return eventOrInstance && eventOrInstance.organizer.self
  }, [eventOrInstance])
  const data = useMemo(() => {
    if (!eventOrInstance) {
      return null
    }
    const attendeesMap = {}
    let organizerFound = false
    if (
      eventOrInstance.event.organizer &&
      !eventOrInstance.event.organizer.self &&
      eventOrInstance.event.organizer.email
    ) {
      attendeesMap[eventOrInstance.event.organizer.email] = {
        email: eventOrInstance.event.organizer.email,
        name: eventOrInstance.event.organizer.displayName,
        isOrganizer: true,
      }
      organizerFound = true
    }
    eventOrInstance.attendees.forEach(attendee => {
      if (attendee.email && !attendeesMap[attendee.email]) {
        attendeesMap[attendee.email] = attendee
        if (
          !organizerFound &&
          eventOrInstance &&
          eventOrInstance.self &&
          stringArrayIncludes({
            strArray: userAliases,
            matchStr: attendee.email,
            options: { ignoreCase: true },
          })
        ) {
          attendeesMap[attendee.email].isOrganizer = true
        }
        organizerFound = true
      }
    })
    return {
      userAliases,
      hideRSVPStatus,
      rsvpStatus,
      onRSVPChange,
      summary: eventOrInstance.summary,
      description: eventOrInstance.description,
      when: formatEventDate(eventOrInstance.toApiJSON()),
      repeat: convertRRuleToRepeatString(eventOrInstance.rruleString),
      location: eventOrInstance.location,
      attendees: Object.values(attendeesMap),
      isSoftDelete: eventOrInstance.isSoftDelete,
      cancelled: eventOrInstance.cancelled,
      isUpdated: eventOrInstance.isUpdated,
      onViewInCalendar,
    }
  }, [
    eventOrInstance,
    onViewInCalendar,
    rsvpStatus,
    onRSVPChange,
    hideRSVPStatus,
    disableActions,
  ])
  if (missing) {
    return <NotAvailable onClose={onClose} onDownload={onDownload} />
  }
  if (data) {
    return <ICS {...data} onClose={onClose} />
  }
  if (dataFetched && !data) {
    return <NotAvailable onClose={onClose} onDownload={onDownload} />
  }
  return <Loading onClose={onClose} />
}

export default ICSAttachmentPreview
