// @flow
import { batch } from 'react-redux'
import moment from 'moment'
import get from 'lodash/get'
import keys from 'lodash/keys'
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import values from 'lodash/values'
import debounce from 'lodash/debounce'
import mapValues from 'lodash/mapValues'
import intersection from 'lodash/intersection'

import * as client from '@edison/webmail-core/api'
import { historyTypes } from '@edison/webmail-core/utils/constants'
import { createAction } from 'utils/redux'
import {
  labelNames,
  routePaths,
  THREAD_PREVIEW_BATCH_NUM,
} from 'utils/constants'
import { labelOperations } from './constants'

import { batchGetThreads } from '../threads/actions'
import {
  getMetadataState,
  getMessagesState,
  getLastHistoryId,
  getChangedMessages,
  getViewThreadsState,
  getThreadMessagesState,
  getBatchUpdateThreadParameters,
  isThreadBatchUpdateLoading,
  isMessageBatchUpdateLoading,
  isThreadsMarkAllLoading,
  isThreadsMarkAllDeletedLoading,
} from './selectors'
import { getActiveLabel } from '../threads/selectors'
import {
  getLabelState,
  getTotalCountByLabel,
  getLabelsWithRetrofitFilter,
} from '../labels/selectors'
import {
  getAccountsByLabel,
  getActiveAccountLabel,
} from '../retrofit/selectors'
import { getInReplyDraftIds } from '../messages/selectors'
import { getAuth } from '../auth/selectors'
import { isBreakEnable } from '../inbox-break/selectors'
import {
  getThreadLabels,
  getAssignedLabels,
  isValidatedHistory,
  isFromCurrentAccount,
} from '../metadata/helpers'
import { existedDraftMessageIds } from '../compose/helpers'

import type {
  ThreadBatchUpdateRequest,
  ThreadBatchUpdateSuccess,
  ThreadBatchUpdateFailure,
  MessageBatchUpdateRequest,
  MessageBatchUpdateSuccess,
  MessageBatchUpdateFailure,
  MessageLabelsBatchUpdate,
  MessageDelete,
  MetadataHistory,
  ThreadBatchDelete,
  CleanupLabelChanged,
  ThreadMarkAllRequest,
  ThreadMarkAllSuccess,
  ThreadMarkAllFailure,
  ThreadMarkAllDeletedRequest,
  ThreadMarkAllDeletedSuccess,
  ThreadMarkAllDeletedFailure,
  LabelStatisticsRequest,
  LabelStatisticsSuccess,
  LabelStatisticsFailure,
} from './types'
import type { ThunkAction, ActionCreator, Dispatch } from 'types/redux'
import type { ThreadBatchUpdateRequest as ThreadBatchUpdateParams } from '@edison/webmail-core/api/threads'
import { getOnmailDomain } from '../custom-domains/selectors'

export const deleteMessage: ActionCreator<MessageDelete> = createAction(
  'MESSAGE_DELETE'
)

export const batchDeleteThreadsAction: ActionCreator<ThreadBatchDelete> = createAction(
  'THREAD_BATCH_DELETE_IN_METADATA'
)

export const batchUpdateMessageLabels: ActionCreator<MessageLabelsBatchUpdate> = createAction(
  'MESSAGE_LABELS_BATCH_UPDATE'
)

export const cleanupLabelChanged: ActionCreator<CleanupLabelChanged> = createAction(
  'CLEANUP_LABEL_CHANGED'
)

export const markAllThreadsActions: {
  request: ActionCreator<ThreadMarkAllRequest>,
  success: ActionCreator<ThreadMarkAllSuccess>,
  failure: ActionCreator<ThreadMarkAllFailure>,
} = {
  request: createAction('THREAD_MARK_ALL_REQUEST'),
  success: createAction('THREAD_MARK_ALL_SUCCESS'),
  failure: createAction('THREAD_MARK_ALL_FAILURE'),
}

export const markAllThreads = (
  targetLabel: string,
  add: $ReadOnlyArray<string>,
  remove: $ReadOnlyArray<string>
): ThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const accountLabel = getActiveAccountLabel(targetLabel)(state)

    if (auth === null) {
      dispatch(
        markAllThreadsActions.failure({
          message: 'User not logged in',
        })
      )
      return false
    }

    try {
      dispatch(markAllThreadsActions.request())
      if (add.length || remove.length) {
        await client.threads.markAll({
          auth,
          accountLabel,
          label: targetLabel,
          addLabel: add,
          delLabel: remove,
        })
      }

      dispatch(markAllThreadsActions.success())
      return true
    } catch (e) {
      dispatch(
        markAllThreadsActions.failure({
          message: e.message,
        })
      )
      return false
    }
  }
}

export const batchUpdateMessagesActions: {
  request: ActionCreator<MessageBatchUpdateRequest>,
  success: ActionCreator<MessageBatchUpdateSuccess>,
  failure: ActionCreator<MessageBatchUpdateFailure>,
} = {
  request: createAction('MESSAGE_BATCH_UPDATE_REQUEST'),
  success: createAction('MESSAGE_BATCH_UPDATE_SUCCESS'),
  failure: createAction('MESSAGE_BATCH_UPDATE_FAILURE'),
}

export const batchUpdateMessages = (
  messageIds: $ReadOnlyArray<string>,
  add: $ReadOnlyArray<string>,
  remove: $ReadOnlyArray<string>
): ThunkAction => {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    if (auth === null) {
      dispatch(
        batchUpdateMessagesActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      const dispatchTimestamp = moment().unix()
      dispatch(batchUpdateMessagesActions.request())

      if (messageIds.length && (add.length || remove.length)) {
        await client.messages.batchUpdate(
          {
            messageIds,
            addLabel: add,
            delLabel: remove,
          },
          { auth }
        )
      }

      dispatch(
        batchUpdateMessagesActions.success({
          messageIds,
          timestamp: dispatchTimestamp,
        })
      )
    } catch (e) {
      dispatch(batchUpdateMessagesActions.failure({ message: e.message }))
    }
  }
}

export const batchUpdateThreadsActions: {
  request: ActionCreator<ThreadBatchUpdateRequest>,
  success: ActionCreator<ThreadBatchUpdateSuccess>,
  failure: ActionCreator<ThreadBatchUpdateFailure>,
} = {
  request: createAction('THREAD_BATCH_UPDATE_REQUEST'),
  success: createAction('THREAD_BATCH_UPDATE_SUCCESS'),
  failure: createAction('THREAD_BATCH_UPDATE_FAILURE'),
}

export const batchUpdateThreadsSync = async (
  params: ThreadBatchUpdateParams,
  {
    dispatch,
    getState,
  }: {
    dispatch: Dispatch,
    getState: () => any,
  }
) => {
  const auth = getAuth()(getState())

  if (auth === null) {
    dispatch(
      batchUpdateThreadsActions.failure({ message: 'User not logged in' })
    )
    return
  }

  try {
    const dispatchTimestamp = moment().unix()
    dispatch(batchUpdateThreadsActions.request())

    if (
      params.remove.threadIds.length ||
      params.addLabel.length ||
      params.delLabel.length
    ) {
      await client.threads.batchUpdate(params, { auth })
    }

    dispatch(
      batchUpdateThreadsActions.success({ timestamp: dispatchTimestamp })
    )
  } catch (e) {
    dispatch(batchUpdateThreadsActions.failure({ message: e.message }))
  }
}

export const batchUpdateThreads = (): ThunkAction => {
  return async (dispatch, getState) => {
    const params = getBatchUpdateThreadParameters(getState())

    await batchUpdateThreadsSync(params, { dispatch, getState })
  }
}

const batchUpdateDebounce = 500
export const debouncedBatchUpdateThreads = debounce(
  batchUpdateThreads(),
  batchUpdateDebounce
)

type ActionOptionals = {
  action?: string,
  undoable: boolean,
  execute?: boolean,
}

type LabelActions = {
  message: (id: string) => ThunkAction,
  messages: (ids: $ReadOnlyArray<string>) => ThunkAction,
  thread: (id: string) => ThunkAction,
  threads: (ids: $ReadOnlyArray<string>) => ThunkAction,
}

function createLabelUpdateAction(
  add: $ReadOnlyArray<string>,
  remove: $ReadOnlyArray<string>,
  { action, undoable: defaultUndoable }: ActionOptionals
): LabelActions {
  function getThreadPrevAndCurrLabels({ id, messageIds }, { getState }) {
    const state = getState()
    const activeLabel = getActiveLabel()(state)
    const labelsMeta = getLabelsWithRetrofitFilter(state)
    const messagesMeta = getMessagesState()(state)
    const threadMessagesMeta = getThreadMessagesState()(state)
    const threadMessages = get(threadMessagesMeta, `${id}.messageIds`, [])

    const prevLabels = getThreadLabels(
      threadMessages.map(id => get(messagesMeta, `${id}.labelIds`, []))
    )

    const currLabels = getThreadLabels([
      // Labels in unchanged messages
      ...threadMessages
        .filter(id => !messageIds.includes(id))
        .map(id => get(messagesMeta, `${id}.labelIds`, [])),

      // Labels in changed messages
      ...messageIds
        .map(id => get(messagesMeta, `${id}.labelIds`, []))
        .map(labelIds =>
          [...labelIds, ...add].filter(id => !remove.includes(id))
        ),
    ])

    const prevViewLabels = getAssignedLabels(labelsMeta, prevLabels)
    const currViewLabels = getAssignedLabels(
      labelsMeta,
      isFromCurrentAccount(currLabels, labelsMeta) ? currLabels : []
    )

    return {
      visible: currViewLabels.has(activeLabel),
      prevViewLabels: Array.from(prevViewLabels),
      currViewLabels: Array.from(currViewLabels),
    }
  }

  function dispatchMessagesLabelUpdate(
    messageIds: $ReadOnlyArray<string>,
    { dispatch, getState, ...optionals }
  ) {
    const activeLabel = getActiveLabel()(getState())
    const messagesMeta = getMessagesState()(getState())

    const messages = messageIds.map(id => messagesMeta[id]).filter(Boolean)

    // Abort when there's no valid message
    if (messages.length === 0) return

    const threads = values(
      messages.reduce(
        (prev, { id, threadId }) => ({
          ...prev,
          [threadId]: {
            threadId,
            messageIds: union(prev[threadId] ? prev[threadId].messageIds : [], [
              id,
            ]),
          },
        }),
        {}
      )
    ).map(({ threadId, messageIds }) => ({
      threadId,
      messageIds,
      ...getThreadPrevAndCurrLabels({ id: threadId, messageIds }, { getState }),
    }))

    const { execute = true, undoable = defaultUndoable } = optionals
    const meta = { type: 'message', action, undoable }

    return batch(async () => {
      if (execute) {
        await dispatch(batchUpdateMessages(messageIds, add, remove))
      }

      dispatch(
        batchUpdateMessageLabels(
          {
            add,
            remove,
            threads,
            updateAsync: false,
            labelId: activeLabel,
          },
          meta
        )
      )
    })
  }

  function dispatchThreadsLabelUpdate(
    threadIds: $ReadOnlyArray<string>,
    { dispatch, getState, ...optionals }
  ) {
    const activeLabel = getActiveLabel()(getState())
    const threadsMeta = getThreadMessagesState()(getState())
    const threads = threadIds.map(id => threadsMeta[id]).filter(Boolean)

    // Abort when there's no valid thread
    if (threads.length === 0) return

    const { execute = true, undoable = defaultUndoable } = optionals
    const meta = { type: 'thread', action, undoable }

    const updateAsync = threadIds.length < THREAD_PREVIEW_BATCH_NUM

    return batch(async () => {
      if (execute) {
        if (updateAsync) {
          // Debounced the request
          await dispatch(debouncedBatchUpdateThreads)
        } else {
          const params = {
            remove: { threadIds: [] },
            addLabel: add.map(label => ({ label, threadIds })),
            delLabel: remove.map(label => ({ label, threadIds })),
          }

          await batchUpdateThreadsSync(params, { dispatch, getState })
        }
      }

      dispatch(
        batchUpdateMessageLabels(
          {
            add,
            remove,
            updateAsync,
            labelId: activeLabel,
            threads: threads.map(({ id, messageIds }) => ({
              messageIds,
              threadId: id,
              ...getThreadPrevAndCurrLabels({ id, messageIds }, { getState }),
            })),
          },
          meta
        )
      )
    })
  }

  function updateMessage(
    messageId: string,
    optionals: ActionOptionals = {}
  ): ThunkAction {
    return async (dispatch, getState) => {
      return dispatchMessagesLabelUpdate([messageId], {
        dispatch,
        getState,
        ...optionals,
      })
    }
  }

  function updateMessages(
    messageIds: $ReadOnlyArray<string>,
    optionals: ActionOptionals = {}
  ): ThunkAction {
    return async (dispatch, getState) => {
      return dispatchMessagesLabelUpdate(messageIds, {
        dispatch,
        getState,
        ...optionals,
      })
    }
  }

  function updateThread(
    threadId: string,
    optionals: ActionOptionals = {}
  ): ThunkAction {
    return async (dispatch, getState) => {
      return dispatchThreadsLabelUpdate([threadId], {
        dispatch,
        getState,
        ...optionals,
      })
    }
  }

  function updateThreads(
    threadIds: $ReadOnlyArray<string>,
    optionals: ActionOptionals = {}
  ): ThunkAction {
    return async (dispatch, getState) => {
      return dispatchThreadsLabelUpdate(threadIds, {
        dispatch,
        getState,
        ...optionals,
      })
    }
  }

  function markAll(targetLabel?: string, optionals: ActionOptionals = {}) {
    return async (dispatch, getState) => {
      const state = getState()
      const viewThreadsState = getViewThreadsState()(state)

      if (!targetLabel) {
        // Use current active label as default
        targetLabel = getActiveLabel()(state)
      }

      const labelTotal = getTotalCountByLabel(targetLabel)(state)

      const threadIds = viewThreadsState[targetLabel] || []

      if (threadIds.length === 0) {
        // Skip network request
        return true
      } else if (
        labelTotal === threadIds.length &&
        threadIds.length < THREAD_PREVIEW_BATCH_NUM
      ) {
        // Use label batch update action
        // When the thread IDs are less then the batch size
        return dispatchThreadsLabelUpdate(threadIds, {
          dispatch,
          getState,
          ...optionals,
        })
      }

      const res = await dispatch(markAllThreads(targetLabel, add, remove))
      await dispatchThreadsLabelUpdate(threadIds, {
        ...optionals,
        dispatch,
        getState,
        execute: false,
      })

      return res
    }
  }

  return {
    markAll,
    message: updateMessage,
    messages: updateMessages,
    thread: updateThread,
    threads: updateThreads,
  }
}

export const labelActions = {
  ...mapValues(
    {
      read: { action: 'read' },
      unread: { action: 'unread' },
      archive: { action: 'archive' },
      unarchive: { action: 'unarchive' },
      trash: { action: 'trash' },
      untrash: { action: 'untrash' },
      markAsSpam: { action: 'markAsSpam' },
      notSpam: { action: 'notSpam' },
      inbox: { action: 'moveToInbox' },
    },
    ({ action }) => {
      const { add = [], remove = [] } = labelOperations[action] || {}
      const optionals = { action, undoable: true }
      return createLabelUpdateAction(add, remove, optionals)
    }
  ),
  update: (add, remove) => {
    const optionals = { action: 'labelUpdate', undoable: false }
    return createLabelUpdateAction(add, remove, optionals)
  },
}

function processDeletedMessages(
  toDelete: $ReadOnlyArray<{
    threadId: string,
    messageIds: $ReadOnlyArray<string>,
  }>,
  { getState }
): $ReadOnlyArray<{
  threadId: string,
  prevViewLabels: $ReadOnlyArray<string>,
  currViewLabels: $ReadOnlyArray<string>,
}> {
  const state = getState()
  const labelsMeta = getLabelState(state)
  const messagesMeta = getMessagesState()(state)
  const threadMessagesMeta = getThreadMessagesState()(state)

  return toDelete.map(({ threadId, messageIds }) => {
    const prevMessageIds =
      get(threadMessagesMeta, `${threadId}.messageIds`) || []

    const prevLabels = prevMessageIds.flatMap(
      id => get(messagesMeta, `${id}.labelIds`) || []
    )
    const currLabels = prevMessageIds
      .filter(id => !messageIds.includes(id))
      .flatMap(id => get(messagesMeta, `${id}.labelIds`) || [])

    const [prevViewLabels, currViewLabels] = [prevLabels, currLabels]
      .map(labelIds => getAssignedLabels(labelsMeta, labelIds))
      .map(assigned => Array.from(assigned))

    return {
      threadId,
      prevViewLabels,
      currViewLabels,
    }
  })
}

export const markAllDeletedActions: {
  request: ActionCreator<ThreadMarkAllDeletedRequest>,
  success: ActionCreator<ThreadMarkAllDeletedSuccess>,
  failure: ActionCreator<ThreadMarkAllDeletedFailure>,
} = {
  request: createAction('THREAD_MARK_ALL_DELETED_REQUEST'),
  success: createAction('THREAD_MARK_ALL_DELETED_SUCCESS'),
  failure: createAction('THREAD_MARK_ALL_DELETED_FAILURE'),
}

export function markAllDeleted(label: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const accountLabel = getActiveAccountLabel(label)(state)
    const messageMeta = getMessagesState()(state)
    const viewThreads = getViewThreadsState()(state)
    const threadMessagesState = getThreadMessagesState()(state)

    const threads = (viewThreads[label] || [])
      .map(id => {
        const thread = threadMessagesState[id]
        if (thread) {
          const { messageIds } = thread
          const filteredMessageIds = messageIds.filter(id => {
            // For the threads in Drafts and Trash, only delete the related messages
            if (label === labelNames.drafts || label === labelNames.trash) {
              const message = messageMeta[id]
              return message ? message.labelIds.includes(label) : false
            } else return true
          })

          if (filteredMessageIds.length) {
            return {
              threadId: id,
              messageIds: filteredMessageIds,
            }
          } else return null
        }
        return null
      })
      .filter(Boolean)

    if (auth === null) {
      dispatch(
        markAllDeletedActions.failure({
          message: 'User not logged in',
        })
      )
      return false
    }

    // Skip the network request
    if (threads.length === 0) return

    dispatch(markAllDeletedActions.request())
    try {
      await client.threads.markAllDeleted({
        auth,
        label,
        accountLabel,
      })
      dispatch(markAllDeletedActions.success())
    } catch (e) {
      dispatch(markAllDeletedActions.failure({ message: e.message }))
    }

    // Delete threads in a label might takes too much time
    // That the request might be error by timeout
    // That pretend all the threads are deleted
    const deletedMessages = threads.flatMap(({ messageIds }) => messageIds)

    const nextThreads = processDeletedMessages(threads, { getState })

    dispatch(
      batchDeleteThreadsAction({
        threads: nextThreads,
        messageIds: deletedMessages,
      })
    )
    return true
  }
}

export function batchDeleteMessages(
  threads: $ReadOnlyArray<{
    threadId: string,
    messageIds: $ReadOnlyArray<string>,
  }>
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    if (auth === null) return

    try {
      const nextThreads = processDeletedMessages(threads, { getState })

      let deletedMessages = []
      threads.forEach(
        item => (deletedMessages = deletedMessages.concat(item.messageIds))
      )

      if (deletedMessages.length > 0) {
        dispatch(
          batchDeleteThreadsAction({
            threads: nextThreads,
            messageIds: deletedMessages,
          })
        )
        await client.messages.batchDelete(
          { messageIds: [...deletedMessages] },
          { auth }
        )
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export function deleteMessages(
  threadId: string,
  messageIds: $ReadOnlyArray<string>
) {
  return batchDeleteMessages([{ threadId, messageIds }])
}

export function batchDeleteTrashMessages(
  threadIds: $ReadOnlyArray<string>
): ThunkAction {
  return async (dispatch, getState) => {
    const threadMessagesState = getThreadMessagesState()(getState())
    const messageState = getMessagesState()(getState())

    dispatch(
      batchDeleteMessages(
        threadIds.map(id => {
          const messageIds = get(threadMessagesState, `${id}.messageIds`) || []
          const replyDraftIds = getInReplyDraftIds(id)(getState())

          return {
            threadId: id,
            messageIds: messageIds
              .filter(messageId => {
                const labelIds =
                  get(messageState, `${messageId}.labelIds`) || []
                return labelIds.includes(labelNames.trash)
              })
              // Check if any draft reply to the message
              .flatMap(id => {
                const draftId = replyDraftIds[id]
                if (isNil(draftId)) return [id]
                else return [id, draftId]
              }),
          }
        })
      )
    )
  }
}

export function batchDeleteThreads(
  threadIds: $ReadOnlyArray<string>
): ThunkAction {
  return async (dispatch, getState) => {
    const threadMessagesState = getThreadMessagesState()(getState())
    dispatch(
      batchDeleteMessages(
        threadIds.map(id => {
          const messageIds = get(threadMessagesState, `${id}.messageIds`) || []
          return {
            messageIds,
            threadId: id,
          }
        })
      )
    )
  }
}

export const metadataHistory: ActionCreator<MetadataHistory> = createAction(
  'METADATA_HISTORY'
)

export function fetchHistory(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const onmailDomain = getOnmailDomain(state)
    const {
      historyId: lastHistoryId,
      newMessageHistoryId: lastMessageHistoryId,
    } = getLastHistoryId()(state)
    const isInBreak = isBreakEnable(state)
    const activeLabel = getActiveLabel()(state)
    const labels = getLabelsWithRetrofitFilter(state)
    const retrofitAccounts = getAccountsByLabel(state)

    const threadBatchUpdateting = isThreadBatchUpdateLoading(state)
    const messageBatchUpdating = isMessageBatchUpdateLoading(state)
    const threadsMarkAllLoading = isThreadsMarkAllLoading(state)
    const threadsMarkAllDeleting = isThreadsMarkAllDeletedLoading(state)

    // Should skip when the time-wasted task is operating
    const isUpdating =
      threadBatchUpdateting ||
      messageBatchUpdating ||
      threadsMarkAllLoading ||
      threadsMarkAllDeleting

    try {
      if (auth === null || !lastHistoryId || isUpdating || isInBreak) return

      const res = await client.threads.fetchHistory({
        auth,
        lastHistoryId,
        lastMessageHistoryId,
      })
      const { history, historyId, newMsgHistoryId } = res.result

      const changedMessages = getChangedMessages(getState())
      const markedMessages = {}

      if (
        historyId !== lastHistoryId ||
        newMsgHistoryId !== lastMessageHistoryId
      ) {
        const state = getState()
        const metadata = getMetadataState(state)

        let [
          // New indexes in messages state
          messages,
          // New indexed in thread messages state
          threadMessages,
          // Indexes to remove in messages state
          deletedMessages,
          // New messages object for batch get
          newMessages,
          // Thread IDs with label changes in view threads state
          changedThreadSet,
          // Sent messages
          sentMessageIds,
        ] = [{}, {}, [], [], new Set(), new Set()]
        for (let item of history) {
          switch (true) {
            case historyTypes.MESSAGES_ADDED in item:
              item[historyTypes.MESSAGES_ADDED]
                .filter(history => isValidatedHistory(history))
                //Filter current user drafts actions
                .filter(item => !existedDraftMessageIds.has(item.messages.id))
                .forEach(item => {
                  //Maybe missing labelIds
                  const { date, id, threadId, labelIds } = item.messages

                  // Record the new messages for batch get
                  newMessages.push(item.messages)

                  changedThreadSet.add(threadId)
                  messages[id] = {
                    id,
                    threadId,
                    labelIds,
                    // Tempoarily place the message at bottom
                    // Will update it after the threads batch get action
                    date: date || -1,
                  }

                  const prevMessageIds =
                    get(threadMessages, `${threadId}.messageIds`) ||
                    get(metadata, `threadMessages.${threadId}.messageIds`) ||
                    []
                  threadMessages[threadId] = {
                    id: threadId,
                    messageIds: [...prevMessageIds, id],
                  }
                })

              break
            case historyTypes.MESSAGES_DELETED in item:
              item[historyTypes.MESSAGES_DELETED].forEach(
                ({ messages: { id, threadId } }) => {
                  const nextMessageIds =
                    get(threadMessages, `${threadId}.messageIds`) ||
                    get(metadata, `threadMessages.${threadId}.messageIds`) ||
                    []
                  changedThreadSet.add(threadId)
                  deletedMessages.push(id)
                  threadMessages[threadId] = {
                    id: threadId,
                    // If the message ids only have one and it's in scheduler IDs
                    // Replace it with it's orignal message id
                    messageIds: nextMessageIds.filter(item => item !== id),
                  }
                }
              )

              break
            case historyTypes.LABELS_ADDED in item:
            case historyTypes.LABELS_REMOVED in item:
              const histories =
                item[historyTypes.LABELS_ADDED] ||
                item[historyTypes.LABELS_REMOVED] ||
                []

              histories
                .filter(history => isValidatedHistory(history))
                .forEach(item => {
                  if (
                    item.labelIds.includes(labelNames.drafts) &&
                    item.messages.labelIds.includes(labelNames.sent)
                  ) {
                    sentMessageIds.add(item.messages.id)
                  }

                  const { id, threadId, labelIds } = item.messages
                  if (changedMessages[id] > 0) {
                    // Skip the mesages which are changed on local
                    markedMessages[id] = (markedMessages[id] || 0) + 1
                  } else {
                    const prevMessage =
                      get(messages, id) || get(metadata, `messages.${id}`)
                    // Only apply the label changed to the messages which exist in frontend
                    if (prevMessage) {
                      changedThreadSet.add(threadId)
                      messages[id] = {
                        ...prevMessage,
                        id,
                        threadId,
                        labelIds,
                      }
                    }
                  }
                })
              break
            default:
          }
        }
        // Check whether the new messages containing pendings
        if (newMessages.length > 0) {
          const retrofitLabels = keys(retrofitAccounts)
          // Batch get new messages
          const newThreadSet = new Set()
          for (let message of newMessages) {
            if (!isNil(message)) {
              const { date, threadId, labelIds } = message

              if (
                intersection(retrofitLabels, labelIds).every(label =>
                  label in retrofitAccounts
                    ? date > retrofitAccounts[label].insertTime
                    : true
                )
              ) {
                newThreadSet.add(threadId)
              }
            }
          }
          if (newThreadSet.size > 0) {
            await dispatch(batchGetThreads(Array.from(newThreadSet), true))
          }
        }

        const newestMetadata = getMetadataState(getState())
        // According the new messages and thread messages state
        // To generate the modified threads for re-indexing in view threads state
        const threads = Array.from(changedThreadSet).map(threadId => {
          const prevLabelIds = get(
            newestMetadata,
            `threadMessages.${threadId}.messageIds`,
            []
          ).flatMap(messageId =>
            get(newestMetadata, `messages.${messageId}.labelIds`, [])
          )

          const currLabelIds = (
            get(threadMessages, `${threadId}.messageIds`) ||
            get(newestMetadata, `threadMessages.${threadId}.messageIds`) ||
            []
          ).flatMap(
            messageId =>
              get(messages, `${messageId}.labelIds`) ||
              get(newestMetadata, `messages.${messageId}.labelIds`) ||
              []
          )

          const [prevViewLabels, currViewLabels] = [prevLabelIds, currLabelIds]
            .map(labelIds =>
              // Filter out the threads which don't belong to current account
              isFromCurrentAccount(labelIds, labels) ? labelIds : []
            )
            .map(labelIds => getAssignedLabels(labels, labelIds))

          return {
            threadId,
            visible: currViewLabels.has(activeLabel),
            prevViewLabels: Array.from(prevViewLabels),
            currViewLabels: Array.from(currViewLabels),
          }
        })

        dispatch(
          metadataHistory({
            threads,
            messages,
            threadMessages,
            deletedMessages,
            markedMessages,
            labelId: activeLabel,
            lastHistoryId: historyId,
            lastMessageHistoryId: newMsgHistoryId,
            sentMessageIds: [...sentMessageIds],
          })
        )
      }
    } catch (e) {
      if (e.status === 401) {
        const onAddAccount = () => {
          const host =
            process.env.NODE_ENV === 'production'
              ? `mail.${onmailDomain}`
              : 'localhost:3000'
          window.location.href = `//${host}${routePaths.addAccountLogin}`
        }
        onAddAccount()
      }
      console.error('Fetch history error: ', e)
    }
  }
}

export const labelStatisticsActions: {
  request: ActionCreator<LabelStatisticsRequest>,
  success: ActionCreator<LabelStatisticsSuccess>,
  failure: ActionCreator<LabelStatisticsFailure>,
} = {
  request: createAction('LABEL_STATISTIC_REQUEST'),
  success: createAction('LABEL_STATISTIC_SUCCESS'),
  failure: createAction('LABEL_STATISTIC_FAILURE'),
}

export function fetchLabelStatistics(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const accountLabel = getActiveAccountLabel('')(state)

    if (auth === null) {
      dispatch(
        labelStatisticsActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(labelStatisticsActions.request())
      const res = await client.labels.statistic({
        auth,
        accountLabel,
      })

      dispatch(labelStatisticsActions.success(res.result))
    } catch (e) {
      dispatch(labelStatisticsActions.failure({ message: e.message }))
    }
  }
}
