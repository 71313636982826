import { getCleanupSelector } from 'core/cleanup/selectors'
import { getEmailNudges } from 'core/email-nudges/selectors'
import xorBy from 'lodash/xorBy'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cardTypes } from 'utils/constants'
import { fetchContactSuggestions } from '../contacts/actions'
import {
  getPendingContacts,
  getSuggestedBlockContacts,
  getSuggestedContacts,
} from '../contacts/selectors'

export function useContactsByFrequency(contacts) {
  const dispatch = useDispatch()
  const suggestedContacts = useSelector(getSuggestedContacts())
  useEffect(() => {
    dispatch(fetchContactSuggestions())
  }, [])
  return useMemo(() => {
    const otherContact = xorBy(suggestedContacts, contacts, 'email')
    return [
      ...suggestedContacts.sort((a, b) => (b.date - a.date > 0 ? 1 : -1)),
      ...otherContact.sort(),
    ]
  }, [contacts, suggestedContacts])
}

const pendingContactsSelector = getPendingContacts()

const CardTypeMapViewType = {
  [cardTypes.cleanup]: 'cleanup',
  [cardTypes.nudges]: 'emailNudge',
  [cardTypes.senders]: 'gallery',
  [cardTypes.suggestedBlock]: 'suggestedBlock',
}

export function usePreviousCardContact(cardType: string) {
  const nudges = useSelector(getEmailNudges)
  const senders = useSelector(pendingContactsSelector)
  const cleanup = useSelector(getCleanupSelector)
  const lastSenders = senders[senders.length - 1]
  const lastNudges = nudges[nudges.length - 1]
  const lastCleanup = cleanup[cleanup.length - 1]
  if (!lastSenders && !lastNudges && !lastCleanup) {
    return {
      hasPreviousCard: false,
      previousCardData: null,
      galleryView: null,
    }
  }
  if (cardType === cardTypes.suggestedBlock) {
    const type = lastCleanup
      ? cardTypes.cleanup
      : lastNudges
      ? cardTypes.nudges
      : lastSenders
      ? cardTypes.senders
      : null
    const data = lastCleanup || lastNudges || lastSenders || {}
    return {
      hasPreviousCard: !!type,
      galleryView: CardTypeMapViewType[type],
      previousCardData: data,
    }
  }
  if (cardType === cardTypes.cleanup) {
    const type = lastNudges
      ? cardTypes.nudges
      : lastSenders
      ? cardTypes.senders
      : null
    const data = lastNudges || lastSenders || {}
    return {
      hasPreviousCard: !!type,
      galleryView: CardTypeMapViewType[type],
      previousCardData: data,
    }
  }
  if (cardType === cardTypes.nudges) {
    const type = lastSenders ? cardTypes.senders : null
    const data = lastSenders || {}
    return {
      hasPreviousCard: !!type,
      galleryView: CardTypeMapViewType[type],
      previousCardData: data,
    }
  }
  return {
    hasPreviousCard: false,
    previousCardData: null,
    galleryView: null,
  }
}

export function useNextCardContact(cardType: string) {
  const nudges = useSelector(getEmailNudges)
  const suggestedBlock = useSelector(getSuggestedBlockContacts)
  const cleanup = useSelector(getCleanupSelector)
  const firstNudges = nudges[0]
  const firstSuggestedBlock = suggestedBlock[0]
  const firstCleanup = cleanup[0]
  if (!firstNudges && !firstCleanup && !firstSuggestedBlock) {
    return {
      hasNextCard: false,
      nextCardData: null,
      nextGalleryView: null,
    }
  }
  if (cardType === cardTypes.senders) {
    const type = firstNudges
      ? cardTypes.nudges
      : firstCleanup
      ? cardTypes.cleanup
      : firstSuggestedBlock
      ? cardTypes.suggestedBlock
      : null
    const data = firstNudges || firstCleanup || firstSuggestedBlock || {}
    return {
      nextGalleryView: CardTypeMapViewType[type],
      hasNextCard: !!type,
      nextCardData: data,
    }
  }
  if (cardType === cardTypes.nudges) {
    const type = firstCleanup
      ? cardTypes.cleanup
      : firstSuggestedBlock
      ? cardTypes.suggestedBlock
      : null
    const data = firstCleanup || firstSuggestedBlock || {}
    return {
      nextGalleryView: CardTypeMapViewType[type],
      hasNextCard: !!type,
      nextCardData: data,
    }
  }
  if (cardType === cardTypes.cleanup) {
    const type = firstSuggestedBlock ? cardTypes.suggestedBlock : null
    const data = firstSuggestedBlock || {}
    return {
      nextGalleryView: CardTypeMapViewType[type],
      hasNextCard: !!type,
      nextCardData: data,
    }
  }
  return {
    hasNextCard: false,
    nextCardData: null,
    nextGalleryView: null,
  }
}

export function useMacthIndex(values: [], key: string, findValue: string) {
  return useMemo(() => {
    const total = values.length - 1
    const currIdx = Math.max(
      values.findIndex(item => item[key] === findValue),
      0
    )
    const isLast = currIdx === total
    const isFirst = currIdx === 0
    return { currIdx, isLast, isFirst }
  }, [values, findValue])
}

const galleryViewMap = {
  gallery: 'currentContact',
  emailNudge: 'currentEmailNudge',
  suggestedBlock: 'currentSuggestBlockContact',
  cleanup: 'currentCleanup',
}

export function useCardTypeMatchIndex(cardType: string, macthValue: string) {
  const nudges = useSelector(getEmailNudges)
  const suggestedBlocks = useSelector(getSuggestedBlockContacts)
  const cleanups = useSelector(getCleanupSelector)
  const cardTypeMapData = {
    [cardTypes.nudges]: {
      values: nudges,
      key: 'threadId',
    },
    [cardTypes.cleanup]: {
      values: cleanups,
      key: 'type',
    },
    [cardTypes.suggestedBlock]: {
      values: suggestedBlocks,
      key: 'id',
    },
  }
  const { values, key } = cardTypeMapData[cardType]
  const matchIndex = useMacthIndex(values, key, macthValue)
  return {
    ...matchIndex,
    values: cardTypeMapData[cardType].values,
  }
}

export function useCard(
  cardType: string,
  macthValue: string,
  onNextNavigate: (view: GalleryView, data: any) => void,
  setNext: () => void
) {
  const {
    hasPreviousCard,
    galleryView,
    previousCardData,
  } = usePreviousCardContact(cardType)
  const { hasNextCard, nextCardData, nextGalleryView } = useNextCardContact(
    cardType
  )
  const { currIdx, isLast, isFirst, values } = useCardTypeMatchIndex(
    cardType,
    macthValue
  )

  const onNextCard = () => {
    if (!isLast) {
      const next = values[currIdx + 1]
      setNext(next)
    } else {
      if (hasNextCard) {
        onNextNavigate(nextGalleryView, {
          [galleryViewMap[nextGalleryView]]: nextCardData,
        })
      }
    }
  }

  const onPreviousCard = () => {
    if (!isFirst) {
      const previous = values[currIdx - 1]
      setNext(previous)
    } else {
      if (hasPreviousCard) {
        onNextNavigate(galleryView, {
          [galleryViewMap[galleryView]]: previousCardData,
        })
      }
    }
  }

  return {
    onNextCard,
    onPreviousCard,
    currIdx,
    hasNext: !isLast || hasNextCard,
    hasPrevious: !isFirst || hasPreviousCard,
  }
}

export function useAllCard() {
  const nudges = useSelector(getEmailNudges)
  const senders = useSelector(pendingContactsSelector)
  const suggestedBlock = useSelector(getSuggestedBlockContacts)
  const cleanup = useSelector(getCleanupSelector)
  return [...senders, ...nudges, ...suggestedBlock, ...cleanup]
}
