// @flow
import React from 'react'

import { useDropZone, dragTypes } from '@edison/webmail-ui/hooks/dnd'
import Label from '@edison/webmail-ui/components/Navigation/components/Label'

type Props = {
  id: string,
  invert: boolean,
  isActive: boolean,
  onDrop: () => any,
  onClick: () => any,
}
export default ({ onDrop = console.log, ...props }: Props) => {
  const { ref, isOver } = useDropZone({
    acceptType: dragTypes.message,
    onDrop: onDrop,
  })
  return (
    <Label {...props} ref={props.isActive ? null : ref} isDragOver={isOver} />
  )
}
