import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { useDebouncedCallback } from 'use-debounce'
import NormalAttachmentItem from 'common/AttachmentItem/NormalAttachmentItem'
import LargeAttachmentItem from 'common/AttachmentItem/LargeAttachmentItem'

import { getAuth } from 'core/auth/selectors'
import * as api from '@edison/webmail-core/api/search'
import {
  convertAttachmentItemToLargeAttachment,
  convertAttachmentItemToAttachment,
} from 'utils'
import { uploadAttachmentTabs } from 'utils/constants'
import { AttachmentCard } from 'common/AttachmentCard'
import UploadDialog, {
  InboxAttachmentList,
  formatId,
  NoAttachmentsTip,
} from '@edison/webmail-ui/components/UploadDialog'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'
import { isLargeAttachmentStateAction } from 'core/compose/actions'
import { getDraft } from 'core/compose/selectors'
import { importAttachment } from 'core/attachments/actions'

import { insertLargeAttachments, insertAttachments } from 'core/compose/actions'
import { CONTENT_DISPOSITION_ATTACHMENT } from '@edison/webmail-core/utils/constants'
const PAGE_SIZE = 28

const fetchAttachments = async (auth, type, messageId) => {
  let pageToken = ''
  let results = []
  const fetch = async extraSize => {
    const result = await api.searchAttachments({
      auth,
      type,
      pageToken,
      pageSize: PAGE_SIZE,
      timezone: moment.tz.guess(),
      incDraft: true,
    })
    const { attachments, nextPageToken } = result.result
    pageToken = nextPageToken
    results = results.concat(
      attachments
        .filter(item => item.message !== messageId)
        .map(item => ({
          ...item,
          uuid: `${item.message}-${item.id}`,
        }))
    )
    if (!pageToken) return
    if (extraSize - attachments.length <= 0) return
    return fetch(extraSize - attachments.length)
  }
  await fetch(PAGE_SIZE)

  return results.map((attachment, index) => ({
    ...attachment,
    uuid: `${attachment.uuid}-${index}`,
  }))
}

export const InboxAttachmentComponent = ({ type, label, ...props }) => {
  const auth = useSelector(useMemo(() => getAuth(), []))
  const [state, setState] = useState(() => ({
    list: [],
    nextPageToken: '',
    loading: false,
    loaded: false,
  }))

  const fetchAttachments = async nextPageToken => {
    let pageToken = nextPageToken
    let results = []
    const fetch = async extraSize => {
      const result = await api.searchAttachments({
        auth,
        type,
        pageToken,
        pageSize: PAGE_SIZE,
        timezone: moment.tz.guess(),
        incDraft: true,
      })
      const { attachments, nextPageToken } = result.result
      pageToken = nextPageToken
      results = results.concat(attachments)
      if (!pageToken) return
      if (extraSize - attachments.length <= 0) return
      return fetch(extraSize - attachments.length)
    }
    await fetch(PAGE_SIZE)

    return { attachments: results, nextPageToken: pageToken }
  }

  const { list, nextPageToken, loading, loaded } = state

  const loadMore = ({ startIndex, stopIndex }) => {
    if (nextPageToken && !loading) {
      setState(state => ({ ...state, loading: true }))
      fetchAttachments(nextPageToken).then(result => {
        const { attachments, nextPageToken } = result
        setState(state => ({
          ...state,
          list: [...list, ...attachments],
          nextPageToken,
          loading: false,
        }))
      })
    }
  }

  const isLoaded = ({ index }) => list[index]

  const renderItem = (
    { columnIndex, rowIndex, key, style, ...props },
    index
  ) => {
    return (
      <div key={formatId(list[index])} style={style} className="py-2">
        {!loaded || nextPageToken || list[index] ? (
          <AttachmentCard attachments={list} index={index} {...props} />
        ) : null}
      </div>
    )
  }

  const [debouncedFetchList, , callPending] = useDebouncedCallback(() => {
    setState({ list: [], pageToken: '', loading: true, loaded: false })
    fetchAttachments().then(result => {
      const { attachments, nextPageToken } = result
      setState({
        list: attachments,
        nextPageToken,
        loading: false,
        loaded: true,
      })
    })
  })

  useEffect(() => {
    debouncedFetchList()
  }, [type])

  useEffect(() => {
    callPending()
  }, [])

  return (
    <InboxAttachmentList
      category={label}
      loading={loading}
      attachments={list}
      attachmentComponent={AttachmentCard}
      loadMore={loadMore}
      isLoaded={isLoaded}
      total={nextPageToken || !loaded ? list.length + 5 : list.length}
      renderItem={renderItem}
      {...props}
    />
  )
}

const AttachmentList = ({
  draftId,
  category,
  selectedAttachments,
  onSelect,
  onInsert,
}) => {
  const [loading, setLoading] = useState(false)
  const [attachments, setAttachments] = useState([])
  const auth = useSelector(useMemo(() => getAuth(), []))
  const draft = useSelector(useMemo(() => getDraft(draftId) || {}, [draftId]))
  const { messageId } = draft || {}
  const wider = useMemo(() => {
    return (
      attachments.length <= 4 &&
      attachments.every(item => item.preview !== 'img')
    )
  }, [attachments])

  useEffect(() => {
    setLoading(true)
    fetchAttachments(auth, category, messageId)
      .then(attachments => {
        setAttachments(attachments.slice(0, PAGE_SIZE))
      })
      .finally(() => setLoading(false))
  }, [category, auth])

  if (!category && loading) {
    return null
  }

  if (!category && !loading && !attachments.length) {
    return null
  }

  return (
    <>
      <div className="font-semibold py-4 pl-2">
        Recent {category ? uploadAttachmentTabs[category].label : ''}
      </div>
      {loading ? (
        <CircularLoader className="flex-1" />
      ) : attachments.length ? (
        <div className="flex flex-wrap">
          {attachments.map((item, index) =>
            item.type === 'large' ? (
              <LargeAttachmentItem
                key={item.uuid}
                attachments={attachments}
                index={index}
                selected={!!selectedAttachments[item.uuid]}
                onSelect={() => onSelect(item)}
                onDoubleClick={() => onInsert(item)}
                wider={wider}
              />
            ) : (
              <NormalAttachmentItem
                key={item.uuid}
                attachments={attachments}
                index={index}
                selected={!!selectedAttachments[item.uuid]}
                onSelect={() => onSelect(item)}
                onDoubleClick={() => onInsert(item)}
                wider={wider}
              />
            )
          )}
        </div>
      ) : category ? (
        <NoAttachmentsTip
          categoryLabel={uploadAttachmentTabs[category].label}
        />
      ) : null}
    </>
  )
}

export default ({ draftId, onSure, ...props }) => {
  const attachmentCategoryTabs = Object.values(uploadAttachmentTabs)
  const dispatch = useDispatch()
  const handleSure = attachments => {
    const largeAttachmentFlag = dispatch(
      isLargeAttachmentStateAction(draftId, attachments)
    )
    if (largeAttachmentFlag) {
      return Promise.all(
        attachments.map(item => dispatch(importAttachment(item, 'large')))
      ).then(results => {
        const targets = attachments
          .map((item, index) => ({
            ...item,
            id: results[index].targetAttachmentId,
            preview: null,
          }))
          .map(convertAttachmentItemToLargeAttachment)
        dispatch(insertLargeAttachments(draftId, targets))
      })
    } else {
      return Promise.all(
        attachments.map(item => dispatch(importAttachment(item, 'normal')))
      ).then(results => {
        const targets = attachments
          .map((item, index) => ({
            ...item,
            id: results[index].targetAttachmentId,
            preview: null,
            contentDisposition: CONTENT_DISPOSITION_ATTACHMENT,
          }))
          .map(convertAttachmentItemToAttachment)
        dispatch(insertAttachments(draftId, targets))
      })
    }
  }
  return (
    <UploadDialog
      onSure={handleSure}
      attachmentCategoryTabs={attachmentCategoryTabs}
      {...props}
    >
      {(category, selectedAttachments, onSelect, onInsert) => (
        <AttachmentList
          draftId={draftId}
          category={category}
          selectedAttachments={selectedAttachments}
          onSelect={onSelect}
          onInsert={onInsert}
        />
      )}
    </UploadDialog>
  )
}
