import React, { useEffect, useRef } from 'react'
import { getSearchKeywords } from 'core/search/selectors'
import { getThreadsById } from 'core/threads/selectors'
import { useSelector } from 'react-redux'
import ThreadSubject from 'common/ThreadList/Subject'
import { highlightHTML } from 'utils/htmlProcess'

const subjectKeywordsSelector = getSearchKeywords('subject')

const MAX_SUBJECT_BYTE_LENGTH = 998

const byteSlice = (str, start, end) => {
  const encoder = new TextEncoder()
  const decoder = new TextDecoder()
  return decoder.decode(encoder.encode(str).slice(start, end))
}
export default function Subject({ threadId }) {
  const ref = useRef(null)
  const keywords = useSelector(subjectKeywordsSelector)
  const threadsById = useSelector(getThreadsById)
  const thread = threadsById[threadId]

  useEffect(() => {
    if (ref.current && keywords.length) {
      highlightHTML(ref.current, keywords)
    }
  }, [keywords, thread && thread.subject])

  if (!thread) return null
  return (
    <div
      className="inline pr-3 text-xl font-semibold align-middle leading-tight"
      style={{ wordBreak: 'break-word' }}
    >
      <ThreadSubject
        ref={ref}
        subject={byteSlice(thread.subject, 0, MAX_SUBJECT_BYTE_LENGTH)}
      />
    </div>
  )
}
