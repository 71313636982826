// @flow
import { createReducer } from 'utils/redux'
import omit from 'lodash/omit'
import { filterTypes } from '@edison/webmail-core/utils/constants'

import * as actions from './actions'

import type {
  State,
  FilterActions,
  FilterListSuccess,
  FilterCreateSuccess,
  FilterDeleteSuccess,
  FilterUpdateSuccess,
} from './types'

export const initialState: State = {}

const reducer = createReducer<State, FilterActions>(initialState, {
  [actions.fetchFiltersActions.success.toString()]: fetchFiltersSuccess,
  [actions.createFilterActions.success.toString()]: createFilterSuccess,
  [actions.deleteFilterActions.success.toString()]: deleteFilterSuccess,
  [actions.deleteFilterActions.request.toString()]: deleteFilterSuccess,
  [actions.updateFilterActions.success.toString()]: updateFilterSuccess,
})

function fetchFiltersSuccess(state: State, action: FilterListSuccess): State {
  const filters = action.payload
  return filters.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: {
        ...curr,
        executed: true,
      },
    }),
    {}
  )
}

function createFilterSuccess(state: State, action: FilterCreateSuccess): State {
  const filter = action.payload
  return {
    ...state,
    [filter.id]: { ...filter, filterType: filterTypes.CUSTOM },
  }
}

function deleteFilterSuccess(state: State, action: FilterDeleteSuccess): State {
  const id = action.payload
  return omit(state, [id])
}

function updateFilterSuccess(state: State, action: FilterUpdateSuccess): State {
  const filter = action.payload

  return {
    ...state,
    [filter.id]: { ...filter, filterType: filterTypes.CUSTOM },
  }
}

export default reducer
