// @flow
import isFunction from 'lodash/isFunction'
import React, { useCallback } from 'react'

import { actions, selectors } from 'core/modals'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmDialog from '@edison/webmail-ui/components/ConfirmDialog'
import CustomDomainAliasNotReadyDialog from '@edison/webmail-ui/components/CustomDomainAliasNotReadyDialog'
import ErrorDialog from '@edison/webmail-ui/components/ErrorDialog'
import MobileDownload from '@edison/webmail-ui/components/MobileDownloadModal'
import Onboarding from '@edison/webmail-ui/components/Onboarding'
import SendDialog from '@edison/webmail-ui/components/SendDialog'
import ContactModal from 'common/Contacts/Modal'
import Preview from 'common/Preview'
import BlockPrompt from 'screens/ApproveSender/BlockPrompt'
import ApproveSenderModal, {
  ContactsGalleryModal,
} from 'screens/ApproveSender/Modal'
import UploadDialog from 'screens/Compose/components/UploadDialog'
import EditContactModal from 'screens/ContactDetailModal/EditContactModal'
import ConvertDomainAdmin from 'screens/ConvertDomainAdmin'
import FilterModal from 'screens/FilterModal'
import { LabelDetail, LabelPrompt } from 'screens/LabelModal'
import LoginModal from 'screens/Login/LoginModal'
import MfaVerifyModal from 'screens/Login/MfaLoginModal'
import NewSubaccount from 'screens/NewSubaccount'
import { AccountLoadingModal } from 'screens/Retrofit/AccountLoading'
import SetPasswordModal from 'screens/Settings/components/SetPasswordModal'
import SplitInboxModal from 'screens/Settings/components/SplitInboxModal'
import SiftDetailModal from 'screens/SiftDetailModal'
import SubaccountDetail from 'screens/SubaccountDetail'
import AttachmentPasswordModal from 'screens/ThreadDetail/components/AttachmentPasswordModal'
import DownloadAllPasswordModal from 'screens/ThreadDetail/components/DownloadAllPasswordModal'
import ShowOriginalDialog from 'screens/ThreadDetail/components/ShowOriginalDialog'
import {
  DisconnectDomainWarning,
  DowngradePlanWarning,
} from '@edison/webmail-ui/components/CriticalWarning'
import { TimezoneConfirm } from '@edison/webmail-ui/components/InboxBreak'
import { ContactAdmin } from '@edison/webmail-ui/components/Paywall'
import { CancelPendingConfirmation } from '@edison/webmail-ui/components/PremiumPlans/ChangePlanConfirmation'
import PremiumSourceModal from '@edison/webmail-ui/components/PremiumPlans/PremiumSourceModal'
import StorageDetailModal from '@edison/webmail-ui/components/StorageDetailModal'
import DeteleContactModal from 'common/Contacts/Modal/DeleteModal'
import BlockDomainModal, {
  UnblockSenderWithDomainModal,
  UnblockWithDomailModal,
} from 'common/Contacts/Modal/DomainModal'
import {
  ChangePlanPreview,
  PurchasePayment,
  SignUpPayment,
  UpdatePayment,
} from 'common/Payment'
import Paywall from 'common/Paywall'
import AccountProvidersModal from 'screens/AccountProvidersModal'
import AddOnmailAlias from 'screens/AddOnmailAlias'
import ContactFirstActionDialog from 'screens/ApproveSender/ContactFirstActionDialog'
import ASPModal from 'screens/AppSpecificPassword'
import ChangeEmailConfirmation from 'screens/ChangeEmailConfirmation'
import DeleteAccountConfirm from 'screens/DeleteAccountConfirm'
import {
  DndCategoryConfirmModal,
  DndSplitConfirmModal,
} from 'screens/DndConfirmModal'
import DomainDisconnectModal from 'screens/DomainDisconnectModal'
import {
  BreakRemovalConfirmModal,
  EndBreakConfirmModal,
  ManualBreakSetupModal,
  ScheduledBreakModal,
} from 'screens/InboxBreak'
import NewForwardEmailModal from 'screens/NewForwardEmailModal'
import PasswordAuthModal from 'screens/PasswordAuthModal'
import QuickActionModal from 'screens/QuickActionModal'
import AccountRemovalConfirmation from 'screens/Retrofit/AccountRemovalConfirmation'
import AddDNSRecordModal from 'screens/Settings/sections/Domain/AddDNSRecordModal'
import ViewDNSRecordModal from 'screens/Settings/sections/Domain/ViewDNSRecordModal'
import MoveToSplitInboxModal from '../screens/MoveToSplitInboxModal/MoveToSplitInboxModal'

import RecoveryMethodModal, {
  AddRecoveryMethodModal,
} from 'screens/RecoveryMethodModal'

import { modalTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

export const registry = {
  [modalTypes.contactModal]: ContactModal,
  [modalTypes.setPassword]: SetPasswordModal,
  [modalTypes.filter]: FilterModal,
  [modalTypes.splitInbox]: SplitInboxModal,
  [modalTypes.moveToSplitInbox]: MoveToSplitInboxModal,
  [modalTypes.labelDetail]: LabelDetail,
  [modalTypes.labelPrompt]: LabelPrompt,
  [modalTypes.approveSender]: ApproveSenderModal,
  [modalTypes.contactsGallery]: ContactsGalleryModal,
  [modalTypes.siftDetail]: SiftDetailModal,
  [modalTypes.blockPrompt]: BlockPrompt,
  [modalTypes.onboarding]: Onboarding,
  [modalTypes.attachmentPassword]: AttachmentPasswordModal,
  [modalTypes.downloadAllPassword]: DownloadAllPasswordModal,
  [modalTypes.newSubaccount]: NewSubaccount,
  [modalTypes.subaccountDetail]: SubaccountDetail,
  [modalTypes.convertDomainAdmin]: ConvertDomainAdmin,
  [modalTypes.error]: ErrorDialog,
  [modalTypes.purchasePayment]: PurchasePayment,
  [modalTypes.updatePayment]: UpdatePayment,
  [modalTypes.signupPayment]: SignUpPayment,
  [modalTypes.paywall]: Paywall,
  [modalTypes.newForwardEmail]: NewForwardEmailModal,
  [modalTypes.storageDetail]: StorageDetailModal,
  [modalTypes.domainDisconnect]: DomainDisconnectModal,
  [modalTypes.preview]: Preview,
  [modalTypes.confirm]: ConfirmDialog,
  [modalTypes.downgradePlanWarning]: DowngradePlanWarning,
  [modalTypes.disconnectDomainWarning]: DisconnectDomainWarning,
  [modalTypes.sendWithUplodingAttachment]: SendDialog,
  [modalTypes.changePlanPreview]: ChangePlanPreview,
  [modalTypes.upload]: UploadDialog,
  [modalTypes.showOriginal]: ShowOriginalDialog,
  [modalTypes.contactAdmin]: ContactAdmin,
  [modalTypes.deleteAccount]: DeleteAccountConfirm,
  [modalTypes.addOnmailAlias]: AddOnmailAlias,
  [modalTypes.addDnsRecord]: AddDNSRecordModal,
  [modalTypes.viewDnsRecord]: ViewDNSRecordModal,
  [modalTypes.dndSplitConfirm]: DndSplitConfirmModal,
  [modalTypes.dndCategoryConfirm]: DndCategoryConfirmModal,
  [modalTypes.editContact]: EditContactModal,
  [modalTypes.addSessionAccount]: LoginModal,
  [modalTypes.accountRemovalConfirmation]: AccountRemovalConfirmation,
  [modalTypes.cancelPendingConfirmation]: CancelPendingConfirmation,
  [modalTypes.customDomainAliasNotReady]: CustomDomainAliasNotReadyDialog,
  [modalTypes.accountProviders]: AccountProvidersModal,
  [modalTypes.mfaVerify]: MfaVerifyModal,
  [modalTypes.emailConfirmation]: ChangeEmailConfirmation,
  [modalTypes.appSpecificPassword]: ASPModal,
  [modalTypes.manualSetup]: ManualBreakSetupModal,
  [modalTypes.scheduledBreak]: ScheduledBreakModal,
  [modalTypes.endBreakConfirm]: EndBreakConfirmModal,
  [modalTypes.breakRemovalConfirm]: BreakRemovalConfirmModal,
  [modalTypes.timezoneConfirm]: TimezoneConfirm,
  [modalTypes.passwordAuth]: PasswordAuthModal,
  [modalTypes.premiumSource]: PremiumSourceModal,
  [modalTypes.accountLoading]: AccountLoadingModal,
  [modalTypes.mobileDownload]: MobileDownload,
  [modalTypes.contactFirstAction]: ContactFirstActionDialog,
  [modalTypes.quickAction]: QuickActionModal,
  [modalTypes.recoveryMethod]: RecoveryMethodModal,
  [modalTypes.addRecoveryMethod]: AddRecoveryMethodModal,
  [modalTypes.deteleContactMethod]: DeteleContactModal,
  [modalTypes.blockDomain]: BlockDomainModal,
  [modalTypes.unblockDomain]: UnblockWithDomailModal,
  [modalTypes.unblockSenderWithDomain]: UnblockSenderWithDomainModal,
}

export default () => {
  const dispatch: Dispatch = useDispatch()
  const modals = useSelector(selectors.getModals())

  return (
    <React.Fragment>
      {modals.map(({ key, isOpen, props }) => {
        const ModalComponent = registry[key]
        const showModal = () => dispatch(actions.show({ key, props }))
        const hideModal = () => {
          dispatch(actions.hide({ key, props }))
          if (isFunction(props.onClose)) props.onClose()
        }

        return (
          <ModalComponent
            {...props}
            key={key}
            isOpen={isOpen}
            onClose={hideModal}
            toggle={isOpen ? hideModal : showModal}
          />
        )
      })}
    </React.Fragment>
  )
}

/**
 * Hook to toggle modals
 *
 * @public
 * @param {string} key - The modal name to show
 * @return {Object} return the showModal and hideModal handlers
 */
export function useModal(
  key: string,
  props: { [string]: any } = {}
): {
  showModal: (modalProps?: { [string]: any }) => void,
  hideModal: () => void,
} {
  const dispatch: Dispatch = useDispatch()

  const hideModal = useCallback(() => {
    dispatch(actions.hide({ key }))
  }, [key])
  const showModal = useCallback(
    (modalProps: { [string]: any } = {}) => {
      dispatch(actions.show({ key, props: { ...props, ...modalProps } }))
    },
    [key]
  )

  return { showModal, hideModal }
}
