// @flow
import omit from 'lodash/omit'
import { createReducer } from 'utils/redux'
import * as actions from './actions'
import { labelNames } from 'utils/constants'
import type {
  State,
  AddAttachment,
  UpdateAttachment,
  RemoveAttachment,
  AddAttachments,
  RemoveAttachments,
  AttachmentActions,
  UpdateAttachments,
} from './types'
import type { MessageListSuccess, BatchGetSuccess } from 'core/messages/types'
import type { FetchDraftSuccess } from 'core/compose/types'
import { fetchThreadActions, batchGetActions } from 'core/messages/actions'
import { fetchDraftActions } from '../compose/actions'
import { batchGetThreadsActions } from 'core/threads/actions'

export const initialState: State = {}

const reducer = createReducer<State, AttachmentActions>(initialState, {
  [actions.addAttachment.toString()]: addAttachment,
  [actions.removeAttachment.toString()]: removeAttachment,
  [actions.addAttachments.toString()]: addAttachments,
  [actions.updateAttachment.toString()]: updateAttachment,
  [actions.removeAttachments.toString()]: removeAttachments,
  [actions.updateAttachments.toString()]: updateAttachments,

  [fetchThreadActions.success.toString()]: fetchThread,
  [batchGetActions.success.toString()]: batchGetMessages,
  [fetchDraftActions.success.toString()]: fetchDraft,

  //sync attachment preview type
  [batchGetThreadsActions.success.toString()]: syncAttachment,
})

function syncAttachment(state: State, action): State {
  const { threads } = action.payload

  const idToAttachment = {}
  for (let key in state) {
    state[key].id && (idToAttachment[state[key].id] = state[key])
  }
  threads
    .reduce((prev, curr) => [...prev, ...curr.messages], [])
    .filter(item => item.labelIds.includes(labelNames.drafts))
    .forEach(item => {
      item.attachments.forEach(attachment => {
        if (idToAttachment[attachment.id]) {
          state[idToAttachment[attachment.id].uuid] = {
            ...idToAttachment[attachment.id],
            preview: attachment.preview,
          }
        }
      })
    })

  return { ...state }
}

function addAttachment(state: State, action: AddAttachment): State {
  return state
}

function removeAttachment(state: State, action: RemoveAttachment): State {
  const uuid = action.payload
  const attachment = state[uuid]
  if (!attachment) return state
  attachment.xhr && attachment.xhr.abort()

  return omit(state, [uuid])
}

function addAttachments(state: State, action: AddAttachments): State {
  const attachments = action.payload

  return {
    ...state,
    ...attachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function updateAttachment(state: State, action: UpdateAttachment): State {
  const { uuid, ...props } = action.payload
  const attachment = state[uuid]
  if (!attachment) return state
  return {
    ...state,
    [uuid]: {
      ...attachment,
      ...props,
    },
  }
}

function updateAttachments(state: State, action: UpdateAttachments): State {
  if (!action.payload.length) return state
  return {
    ...state,
    ...action.payload.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: {
          ...state[curr.uuid],
          ...curr,
        },
      }),
      {}
    ),
  }
}

function removeAttachments(state: State, action: RemoveAttachments): State {
  const uuids = action.payload
  return omit(state, uuids)
}

function fetchDraft(state: State, action: FetchDraftSuccess): State {
  const { attachments } = action.payload
  return {
    ...state,
    ...attachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function fetchThread(state: State, action: MessageListSuccess): State {
  const { attachments } = action.payload
  return {
    ...state,
    ...attachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}

function batchGetMessages(state: State, action: BatchGetSuccess): State {
  const { attachments } = action.payload
  return {
    ...state,
    ...attachments.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.uuid]: curr,
      }),
      {}
    ),
  }
}
export default reducer
