// @flow
import React, { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  getSelectedThreadIds,
  isSelectedUniverse,
  getThreadPagination,
} from 'core/threads/selectors'
import { routePaths } from 'utils/constants'

import ThreadDragPreview from '@edison/webmail-ui/components/ThreadDragPreview'

const selectedThreadIdsSelector = getSelectedThreadIds()

export default () => {
  const matched = useRouteMatch(routePaths.main)
  const pagination = useSelector(getThreadPagination)
  const universeChecked = useSelector(isSelectedUniverse)
  const selectedThreads = useSelector(selectedThreadIdsSelector)

  const labelId = matched && matched.params.label

  const count = useMemo(
    () =>
      universeChecked && labelId !== 'search'
        ? pagination.total
        : selectedThreads.size,

    [labelId, universeChecked, pagination.total, selectedThreads.size]
  )

  return <ThreadDragPreview count={count} />
}
