import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import {
  useThreadActions,
  useThreadDetailActionFlags,
} from 'core/threads/hooks'
import { useParams } from 'react-router-dom'

import isNil from 'lodash/isNil'
import { getThreadsById } from 'core/threads/selectors'
import { getThreadLabelIds } from 'core/metadata/selectors'
import { isEnableThreadAction } from 'core/messages/selectors'

const order = [
  actionNodes.untrash.name,
  actionNodes.notSpam.name,
  actionNodes.unarchive.name,
  actionNodes.archive.name,
  actionNodes.read.name,
  actionNodes.unread.name,
  actionNodes.trash.name,
  actionNodes.deleteForever.name,
  actionNodes.markAsSpam.name,
  actionNodes.discardDraft.name,
]
const secondaries = new Set([
  actionNodes.trash.name,
  actionNodes.untrash.name,
  actionNodes.deleteForever.name,
  actionNodes.markAsSpam.name,
])

export default function useActions(threadId, onGoBack) {
  const threads = useSelector(getThreadsById)
  const thread = threads[threadId]
  const { label } = useParams()

  const getThreadActions = useThreadActions({ debounced: false })
  const getActionFlags = useThreadDetailActionFlags(!label)
  const labelIds = useSelector(
    useMemo(() => getThreadLabelIds(threadId), [threadId])
  )
  const enabled = useSelector(
    useMemo(() => isEnableThreadAction(threadId), [threadId])
  )
  if (!enabled) return []

  const flags = !isNil(thread) ? getActionFlags({ ...thread, labelIds }) : {}

  const threadActions = !isNil(thread) ? getThreadActions(thread) : {}
  const actions = order
    .filter(item => flags[item])
    .filter(item => !secondaries.has(item))
    .filter(item => threadActions[item])

  return actions.map(action => ({
    ...threadActions[action],
    onClick: e => {
      onGoBack(e, 'threadActionClick')

      threadActions[action].onClick()
    },
  }))
}
