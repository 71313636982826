// @flow
import i18next from 'i18next'
import mapValues from 'lodash/mapValues'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import values from 'lodash/values'
import isNil from 'lodash/isNil'
import keys from 'lodash/keys'
import trim from 'lodash/trim'
import get from 'lodash/get'

import { labelNames } from './constants'

import type { FilterInApp } from 'core/filters/types'
import type { Filter as BackendFilter } from '@edison/webmail-core/types/filters'

const systemLabelList = values(labelNames)

export const criteriaConfig = {
  from: {
    name: 'from',
    label: i18next.t('settings.filter.criteria.from'),
  },
  to: {
    name: 'to',
    label: i18next.t('settings.filter.criteria.to'),
  },
  subject: {
    name: 'subject',
    label: i18next.t('settings.filter.criteria.subject'),
  },
  query: {
    name: 'query',
    label: i18next.t('settings.filter.criteria.query'),
  },
}

export const actionConfig = {
  read: {
    label: i18next.t('settings.filter.action.read.label'),
    action: {
      name: 'unread',
      value: false,
      type: 'text',
      label: i18next.t('settings.filter.action.status'),
      content: i18next.t('settings.filter.action.read.label'),
    },
  },
  archive: {
    label: i18next.t('settings.filter.action.archive.label'),
    action: {
      name: 'archive',
      value: true,
      type: 'text',
      label: i18next.t('settings.filter.action.status'),
      content: i18next.t('settings.filter.action.archive.label'),
    },
  },
  // Not support yet
  // {
  //   label: 'Star Message',
  //   value: {
  //     name: 'star',
  //     value: true,
  //     type: 'text',
  //     label: 'Status',
  //     content: 'Star Message',
  //   },
  // },
  label: {
    label: i18next.t('settings.filter.action.label.label'),
    action: {
      name: 'label',
      value: null,
      type: 'label',
      label: i18next.t('settings.filter.action.label.label'),
      placeholder: i18next.t('settings.filter.action.label.placeholder'),
      options: [],
    },
  },
  forward: {
    label: i18next.t('settings.filter.action.forward.label'),
    action: {
      name: 'forward',
      value: null,
      type: 'forward',
      placeholder: i18next.t('settings.filter.action.forward.placeholder'),
      label: i18next.t('settings.filter.action.forward.label'),
    },
  },
  // categorize: {
  //   label: 'Categorize',
  //   action: {
  //     name: 'categorize',
  //     value: '',
  //     type: 'input',
  //     label: 'Categorize as',
  //   },
  // },
  trash: {
    label: i18next.t('settings.filter.action.trash.label'),
    action: {
      name: 'trash',
      value: true,
      type: 'text',
      label: i18next.t('settings.filter.action.status'),
      content: i18next.t('settings.filter.action.trash.label'),
    },
  },
  spam: {
    label: i18next.t('settings.filter.action.spam.label'),
    action: {
      name: 'spam',
      value: false,
      type: 'text',
      label: i18next.t('settings.filter.action.status'),
      content: i18next.t('settings.filter.action.spam.label'),
    },
  },
}

export const allowDuplicatedActions = [actionConfig.label.action.name]

/**
 * Transform a filter object to fit the strcture in backend
 *
 * @public
 * @param {Filter} filter
 * @returns {BackendFilter}
 */
export function transformFilterToApi(filter: FilterInApp): BackendFilter {
  const { criteria, action = [] } = filter

  const formattedCriteria = {
    ...criteria,
    [criteriaConfig.query.name]: !!trim(
      get(criteria, criteriaConfig.query.name)
    )
      ? `body:(${criteria.query})`
      : '',
  }

  let addLabelSet = new Set()
  let removeLabelSet = new Set()

  action.map(each => {
    const { name, value } = each
    switch (name) {
      case 'spam':
      case 'trash':
      case 'unread':
        if (value) {
          addLabelSet.add(labelNames[name])
        } else {
          removeLabelSet.add(labelNames[name])
        }
        break
      case 'archive':
        addLabelSet.add(labelNames.archive)
        removeLabelSet.add(labelNames.inbox)
        removeLabelSet.add(labelNames.trash)
        break

      case 'label':
      case 'categorize':
        addLabelSet.add(value)
        break

      default:
    }
    return each
  })

  return {
    criteria: fromPairs(
      toPairs(formattedCriteria)
        .map(([key, value]) => {
          if (key === 'query') return [key, value]
          let content = Array.isArray(value) ? value.join(' ') : value
          const match = /^\((.*)\)$/.exec(content)
          if (isNil(match)) {
            content = !!content ? `(${content})` : ''
          } else {
            content = !!match[1] ? content : ''
          }
          return [key, content]
        })
        .filter(([, value]) => !!value)
    ),
    action: Object.assign(
      {},
      {
        addLabelIds: Array.from(addLabelSet),
        removeLabelIds: Array.from(removeLabelSet),
      }
    ),
  }
}

/**
 * Transform a filter object from backend
 *
 * @public
 * @param {BackendFilter} filter
 * @returns {Filter}
 */
export function transformFilterFromApi(filter: BackendFilter): FilterInApp {
  const { criteria, action } = filter
  const { addLabelIds = [], removeLabelIds = [], forward } = action

  let filterAction = []

  addLabelIds.map(each => {
    let actionName = ''
    switch (each) {
      case ((actionName = 'trash'), labelNames.trash):
      case ((actionName = 'archive'), labelNames.archive):
        filterAction.push(actionConfig[actionName].action)
        break
      default:
        if (!systemLabelList.includes(each)) {
          filterAction.push({
            ...actionConfig['label'].action,
            value: each,
          })
        }
    }
    return each
  })

  removeLabelIds.map(each => {
    let actionName = ''
    switch (each) {
      case ((actionName = 'spam'), labelNames.spam):
      case ((actionName = 'read'), labelNames.unread):
        filterAction.push(actionConfig[actionName].action)
        break
      default:
    }
    return each
  })

  if (!isNil(forward)) {
    filterAction.push({
      ...actionConfig['forward'].action,
      value: forward,
    })
  }

  const { query = '', ...otherCriteria } = criteria
  const match = /body:\((.+)\)$/.exec(query)
  const queryMatch = isNil(match) ? '' : match[1]

  const normalCriteriaRegex = /^\((.*)\)$/
  return {
    criteria: {
      ...mapValues(otherCriteria, item => {
        const match = normalCriteriaRegex.exec(item)
        return isNil(match) ? item : match[1]
      }),
      ...(!!queryMatch ? { query: queryMatch } : {}),
    },
    action: filterAction,
  }
}

export function isQueryComplex(query: $PropertyType<FilterInApp, 'criteria'>) {
  if (keys(query).length > 1) {
    return true
  }
  if (
    values(query)
      .filter(value => Array.isArray(value) || typeof value === 'string')
      .flatMap(each => each)
      .find(each => / AND | NOT |,/i.test(each))
  ) {
    return true
  }
  return false
}
