// @flow
import React, { useEffect, useMemo } from 'react'
import isNil from 'lodash/isNil'
import difference from 'lodash/difference'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch, batch } from 'react-redux'

import SelectAllThreads from '@edison/webmail-ui/components/ThreadList/SelectAllThreads'
import * as threadSelectors from 'core/threads/selectors'
import * as splitInboxSelectors from 'core/split-inboxes/selectors'
import * as labelSelectors from 'core/labels/selectors'
import {
  batchSelectThread,
  selectUniverseThread,
  resetSelectThread,
} from 'core/threads/actions'
import { displayTitles } from 'utils/constants'

import type { Match } from 'react-router-dom'
import type { Dispatch } from 'types/redux'

type Props = {
  match: Match,
  threads: $ReadOnlyArray<{ id: string }>,
}

const getSelectedThreadIds = threadSelectors.getSelectedThreadIds()
const getActiveLabel = threadSelectors.getActiveLabel()
const getSplitInboxes = splitInboxSelectors.getSplitInboxNameByLabelId()
const getLabelNamesById = labelSelectors.getLabelNamesById()

const View = ({ match, threads }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const activeId: ?string = match.params.label
  const activeLabel = useSelector(getActiveLabel)
  const splitInboxes = useSelector(getSplitInboxes)
  const selected = useSelector(getSelectedThreadIds)
  const labelNamesById = useSelector(getLabelNamesById)
  const totalSelector = useMemo(
    () => labelSelectors.getTotalCountByLabel(activeLabel),
    [activeLabel]
  )
  const universe = useSelector(totalSelector)

  const universeChecked = useSelector(threadSelectors.isSelectedUniverse)

  let labelName
  if (isNil(activeId)) {
    labelName = 'search'
  } else if (activeLabel in splitInboxes) {
    labelName = splitInboxes[activeLabel]
  } else if (activeLabel in displayTitles) {
    labelName = displayTitles[activeLabel]
  } else {
    labelName = labelNamesById[activeLabel]
  }

  const allowSelectUniverse = !isNil(activeId)
  const numThreads = threads.length
  const numSelected = selected.size
  const numUniverse = allowSelectUniverse ? universe : Infinity
  const checked =
    (numSelected > 0 && numSelected === numThreads) || universeChecked

  const threadIds = threads.map(({ id }) => id)
  const select = ids => dispatch(batchSelectThread({ ids, value: true }))
  const deselect = ids => dispatch(batchSelectThread({ ids, value: false }))
  const selectUniverse = () => {
    batch(() => {
      select(threadIds)
      dispatch(selectUniverseThread({ value: true }))
    })
  }

  const reset = () => {
    dispatch(resetSelectThread())
  }

  useEffect(() => {
    return () => {
      const diff =
        numSelected > 0 ? difference(Array.from(selected), threadIds) : []

      if (diff.length > 0) {
        deselect(diff)
      }
    }
  }, [numUniverse, threads, selected.size])

  useEffect(() => {
    if (universeChecked) {
      if (selected.size !== numUniverse) {
        dispatch(selectUniverseThread({ value: false }))
      }
    } else {
      if (selected.size > 0 && selected.size === numUniverse) {
        dispatch(selectUniverseThread({ value: true }))
      }
    }
  }, [numUniverse, selected.size])

  return (
    <SelectAllThreads
      isSelected={selected.size > 0}
      checked={checked}
      label={labelName}
      universeChecked={universeChecked}
      numSelected={numSelected}
      numUniverse={numUniverse}
      onSelect={() => {
        if (!checked) {
          // Dispatch action to select all rendered threads
          select(threadIds)
        } else {
          // Dispatch action to deselect all
          reset()
        }
      }}
      onSelectUniverse={
        allowSelectUniverse
          ? () => {
              selectUniverse()
            }
          : undefined
      }
      onCancel={() => reset()}
    />
  )
}

View.displayName = 'SelectAllThreads'

export default withRouter(View)
