// @flow
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

import { getVerifiedForwardEmails } from 'core/settings/selectors'
import { SelectAction } from '@edison/webmail-ui/components/Filter/ActionItem'
import Tooltip from '@edison/webmail-ui/components/Tooltip'
import Email, {
  EmptyListPlaceholder,
} from '@edison/webmail-ui/components/Filter/components/Email'
import { useOrderId } from 'core/auth/hooks'

type Props = {
  name: string,
  value: string,
  placeholder?: string,
}
export default ({ name, value, placeholder }: Props) => {
  const history = useHistory()
  const userId = useOrderId()
  const forwardEmails = useSelector(getVerifiedForwardEmails)
  const { hideModal: hideFilterModal } = useModal(modalTypes.filter)

  function goToGeneralSetting() {
    hideFilterModal()
    history.push(`/u/${userId}/settings/general`)
  }

  return forwardEmails.length > 0 ? (
    <SelectAction
      name={name}
      value={value}
      label={value}
      placeholder={placeholder}
      renderOptions={({ onClick }) =>
        forwardEmails
          .filter(email => email.verified)
          .map(({ email, verified }) => (
            <Email disabled={!verified} onClick={() => onClick(email)}>
              <Tooltip title={email}>
                <div className="truncate">{email}</div>
              </Tooltip>
            </Email>
          ))
      }
    />
  ) : (
    <EmptyListPlaceholder onClick={goToGeneralSetting} />
  )
}
