import intersection from 'lodash/intersection'
import uniq from 'lodash/uniq'
import xor from 'lodash/xor'
import {
  archiveAction,
  draftsAction,
  inboxActon,
  labelNames,
  sentAction,
  spamAction,
  trashAction,
} from 'utils/constants'
import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'

export function getSearchActionNodes(labelIds, resultIds, labelAction) {
  if (!resultIds.length) {
    return {
      main: [],
      menu: [],
      disabled: [],
    }
  }
  const actionConfig = getActionConfig(labelIds, labelAction)
  return {
    main: actionConfig.main.length
      ? actionConfig.main
      : actionConfig.menu.slice(0, 1),
    menu: actionConfig.main.length
      ? actionConfig.menu
      : actionConfig.menu.slice(1),
    disabled: [],
  }
}

export const searchActionConfig = {
  main: [
    actionNodes.archive.name,
    actionNodes.unarchive.name,
    actionNodes.untrash.name,
    actionNodes.read.name,
    actionNodes.unread.name,
  ],
  menu: [
    actionNodes.trash.name,
    actionNodes.addLabel.name,
    actionNodes.markAsSpam.name,
    actionNodes.deleteForever.name,
    actionNodes.discardDraft.name,
    actionNodes.notSpam.name,
  ],
}

export function getActionConfig(threadLabelIds, queryLabelAction) {
  const threadsActionByLabelIds = threadLabelIds.flatMap(threadLabelId => {
    const labelIds = threadLabelId || []
    const singleThreadActionBylabeIds = labelIds.reduce(
      (acc, label) => {
        switch (label) {
          case labelNames.inbox:
            return {
              main: [...acc.main, ...inboxActon.main],
              menu: [
                ...acc.menu,
                // TODO now in search don't supprot spilt action, need to support
                ...xor(inboxActon.menu, [actionNodes.moveToSplit.name]),
              ],
            }
          case labelNames.archive:
            return {
              main: [...acc.main, ...archiveAction.main],
              menu: [...acc.menu, ...archiveAction.menu],
            }
          case labelNames.sent:
            return {
              main: [...acc.main, ...sentAction.main],
              menu: [...acc.menu, ...sentAction.menu],
            }
          case labelNames.read:
            return {
              main: [...acc.main, ...actionNodes.unread.name],
              menu: acc.menu,
            }
          case labelNames.unread:
            return {
              main: [...acc.main, ...actionNodes.read.name],
              menu: acc.menu,
            }
          case labelNames.trash:
            return {
              main: [...acc.main, ...trashAction.main],
              menu: [...acc.menu, ...trashAction.menu],
            }
          case labelNames.spam:
            return {
              main: [...acc.main, ...spamAction.main],
              menu: [...acc.menu, ...spamAction.menu],
            }
          case labelNames.drafts:
            return {
              main: [...acc.main, ...draftsAction.main],
              menu: [...acc.menu, ...draftsAction.menu],
            }
          default:
            return acc
        }
      },
      {
        main: [],
        menu: [],
      }
    )
    return singleThreadActionBylabeIds
  })
  // firstly get single thread what need these action then get they intersection with queryLabelAction
  // and get intersection single thread with single thread
  const threadsAction = threadsActionByLabelIds
    .map(item => getSingleThreadAction(item, queryLabelAction))
    .reduce((acc, cur, curIndex) => {
      return curIndex === 0 ? cur : intersection(acc, cur)
    }, [])
  return {
    main: uniq(intersection(searchActionConfig.main, threadsAction)),
    menu: uniq(intersection(searchActionConfig.menu, threadsAction)),
    disabled: [],
  }
}

function getSingleThreadAction(acionConfig, queryLabelAction) {
  if (queryLabelAction.main.length || queryLabelAction.menu.length) {
    return intersection(
      [...acionConfig.main, ...acionConfig.menu],
      [...queryLabelAction.main, ...queryLabelAction.menu]
    )
  }
  return intersection(
    [...acionConfig.main, ...acionConfig.menu],
    [...searchActionConfig.main, ...searchActionConfig.menu]
  )
}
