import React, { useMemo, useState, Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  hasTrashMessage,
  getThreadTrashMessageIds,
  getThreadMessageIdsByLabels,
  getThreadUnreadMessageIds,
} from 'core/metadata/selectors'
import {
  isThreadLoaded,
  isAllThreadMessageLoaded,
  getThreadContainDraftMessageIds,
  getSearchHighlightMessageIds,
  getSendingThreadMessageIds,
} from 'core/messages/selectors'
import { autoScroll, checkAndReadThread } from 'core/messages/actions'
import Labels from './components/Labels'
import MessageItem from './components/MessageItem'
import ResponseBar from './components/ResponseBar'
import Pagination from './components/Pagination'
import ThreadAction from './components/ThreadAction'
import Subject from './components/Subject'
import EmailNudgePrompt from './components/EmailNudgePrompt'
import NewMessageToast from './components/NewMessageToast'
import TrashedMessageTip from './components/TrashedMessageTip'
import FoldLine from './components/FoldLine'
import { scrollToMessage } from 'utils'
import { labelNames } from 'utils/constants'
import { useCache } from 'core/search/hooks'

const FOLD_MESSAGE_NUMBER = 4

export const LoadedMessageList = ({ threadId, ...otherProps }) => {
  const loaded = useSelector(isThreadLoaded(threadId, false))
  if (!loaded) return null
  return <MessageList {...otherProps} threadId={threadId} />
}

const MessageList = ({ threadId, ...otherProps }) => {
  const hasTrash = useSelector(
    useMemo(() => hasTrashMessage(threadId), [threadId])
  )
  const params = useParams()

  const onlyTrash = params.label === 'trash' && hasTrash

  if (onlyTrash) {
    return <TrashMessageList {...otherProps} threadId={threadId} />
  } else {
    return <InboxMessageList {...otherProps} threadId={threadId} />
  }
}

const TrashMessageList = ({
  threadId,
  onSelectRecipient,
  isModalMode = false,
}) => {
  const trashMessageIds = useSelector(
    useMemo(() => getThreadTrashMessageIds(threadId), [threadId])
  )

  const [folded, setFold] = useState(
    trashMessageIds.length > FOLD_MESSAGE_NUMBER
  )
  const dispatch: Dispatch = useDispatch()

  useEffect(() => {
    //use timeout it wait the messsage rendered.
    dispatch(checkAndReadThread(threadId))
  }, [threadId])

  return (
    <>
      {(folded
        ? [trashMessageIds[0], ...trashMessageIds.slice(-2)]
        : trashMessageIds
      ).map((messageId, index) => (
        <Fragment key={messageId}>
          <MessageItem
            isModalMode={isModalMode}
            messageId={messageId}
            messageIds={trashMessageIds}
            onSelectRecipient={onSelectRecipient}
          />
          {folded && index === 0 && (
            <FoldLine
              className={isModalMode ? 'mx-10' : ''}
              messageId={messageId}
              number={trashMessageIds.length - FOLD_MESSAGE_NUMBER + 1}
              onClick={() => setFold(false)}
            />
          )}
        </Fragment>
      ))}
    </>
  )
}

const InboxMessageList = ({
  threadId,
  onSelectRecipient,
  onViewInCalendar,
  isModalMode,
  onDismiss,
  isFilterPendingMessage = true,
}) => {
  const dispatch: Dispatch = useDispatch()
  const defaultTrashMessageIds = useSelector(
    useMemo(() => getThreadTrashMessageIds(threadId), [])
  )
  const containDraftMessageIds = useSelector(
    useMemo(() => getThreadContainDraftMessageIds(threadId), [])
  )
  const unreadMessageIds = useSelector(
    useMemo(() => getThreadUnreadMessageIds(threadId), [])
  )
  const containKeywordMessageIds = useSelector(
    useMemo(() => getSearchHighlightMessageIds(threadId), [])
  )
  const notDraftTrashMessageIds = useSelector(
    useMemo(() => {
      const labels = { [labelNames.drafts]: false, [labelNames.trash]: false }

      return getThreadMessageIdsByLabels(
        threadId,
        labels,
        isFilterPendingMessage
      )
    }, [threadId])
  )
  const [isShowDeletedMessages, showDeletedMessage] = useState(
    defaultTrashMessageIds.length ? !notDraftTrashMessageIds.length : true
  )
  const notDraftMessageIds = useSelector(
    useMemo(() => {
      const labels = { [labelNames.drafts]: false }
      if (!isShowDeletedMessages) {
        labels[labelNames.trash] = false
      }

      return getThreadMessageIdsByLabels(
        threadId,
        labels,
        isFilterPendingMessage
      )
    }, [threadId, isShowDeletedMessages])
  )

  const draftMessageIds = useSelector(
    useMemo(() => {
      const labels = { [labelNames.drafts]: true }
      return getThreadMessageIdsByLabels(threadId, labels)
    }, [threadId])
  )

  const [folded, setFold] = useState(() => {
    const interval = []
    let fold = false
    let tempKey = []

    notDraftMessageIds.slice(0, -1).forEach(messageId => {
      if (
        containDraftMessageIds.includes(messageId) ||
        unreadMessageIds.includes(messageId) ||
        containKeywordMessageIds.includes(messageId)
      ) {
        fold = false
        interval.push(tempKey)
        tempKey = []
        return
      } else {
        if (fold) {
          tempKey.push(messageId)
        } else {
          tempKey = [messageId]
          fold = true
        }
      }
    })

    interval.push(tempKey)
    return interval
      .filter(item => item.length >= FOLD_MESSAGE_NUMBER)
      .reduce((prev, curr) => ({ ...prev, [curr.join('-')]: true }), {})
  })

  const isAllLoaded = useSelector(
    useMemo(() => isAllThreadMessageLoaded(threadId), [threadId])
  )
  const sendingMessageIds = useSelector(
    useMemo(
      () => getSendingThreadMessageIds(threadId, isFilterPendingMessage),
      [threadId]
    )
  )

  useEffect(() => {
    if (isAllLoaded) {
      dispatch(checkAndReadThread(threadId))
      //use timeout it wait the messsage rendered.
      setTimeout(() => {
        dispatch(autoScroll(threadId))
      })
    }
  }, [isAllLoaded, threadId])
  return (
    <>
      {notDraftMessageIds.map((messageId, index) => {
        const foldedMessageIds = Object.keys(folded)
        const foldedKey = foldedMessageIds.find(item =>
          item.includes(messageId)
        )
        const messageItem = (
          <MessageItem
            onDismiss={onDismiss}
            onViewInCalendar={onViewInCalendar}
            isModalMode={isModalMode}
            messageId={messageId}
            threadId={threadId}
            messageIds={notDraftTrashMessageIds}
            onSelectRecipient={onSelectRecipient}
          />
        )
        if (!foldedKey || !folded[foldedKey])
          return <Fragment key={messageId}>{messageItem}</Fragment>

        const foldedKeyMessageIds = foldedKey.split('-')
        const foldedIndex = foldedKeyMessageIds.indexOf(messageId)

        if (foldedIndex === 0) {
          return (
            <Fragment key={messageId}>
              {messageItem}
              {/* pass message id make sure the message is loaded to display the line */}
              <FoldLine
                msgClx="bg-app dark:bg-card"
                className={isModalMode ? 'mx-10' : ''}
                messageId={messageId}
                number={foldedKeyMessageIds.length - 2}
                onClick={() =>
                  setFold({
                    ...folded,
                    [foldedKey]: false,
                  })
                }
              />
            </Fragment>
          )
        } else if (foldedIndex === foldedKeyMessageIds.length - 1) {
          return <Fragment key={messageId}>{messageItem}</Fragment>
        } else {
          return null
        }
      })}
      {sendingMessageIds.map(item => (
        <MessageItem
          onViewInCalendar={onViewInCalendar}
          isModalMode={isModalMode}
          threadId={threadId}
          key={item}
          messageId={item}
          messageIds={[item]}
          onSelectRecipient={onSelectRecipient}
        />
      ))}
      {isModalMode &&
        draftMessageIds.map(item => (
          <MessageItem
            isDraft={true}
            onViewInCalendar={onViewInCalendar}
            isModalMode={isModalMode}
            threadId={threadId}
            key={item}
            messageId={item}
            messageIds={[item]}
            onSelectRecipient={onSelectRecipient}
          />
        ))}
      {!isShowDeletedMessages && (
        <TrashedMessageTip
          threadId={threadId}
          onView={() => {
            showDeletedMessage(true)
            setFold({})
            //smooth scroll behavior not work after message body render
            setTimeout(() => {
              scrollToMessage(defaultTrashMessageIds[0], false)
            })
          }}
        />
      )}
    </>
  )
}

const ThreadDetail = ({ threadId, labelIds, onSelectRecipient }) => {
  const { cacheViewEmail } = useCache()
  useEffect(() => {
    if (
      !labelIds ||
      !labelIds.length ||
      labelIds.includes(labelNames.spam) ||
      labelIds.includes(labelNames.trash)
    ) {
      return
    }
    cacheViewEmail(threadId)
  }, [threadId, labelIds])
  return (
    <div className="z-0 flex flex-col w-full h-full">
      <div className="sticky top-0" style={{ zIndex: 11 }}>
        <div className="flex justify-between p-4 border-b items-top text-dark">
          <div>
            <Subject threadId={threadId} />
            <Labels threadId={threadId} />
          </div>
          <div className="flex items-center" style={{ height: 30 }}>
            <Pagination threadId={threadId} />
            <ThreadAction threadId={threadId} />
          </div>
        </div>
      </div>

      <div
        className="w-full flex-grow flex flex-col overflow-auto"
        id="ThreadDetail-body"
      >
        <div className="flex-shrink-0 flex-grow">
          <EmailNudgePrompt threadId={threadId} />
          <LoadedMessageList
            threadId={threadId}
            onSelectRecipient={onSelectRecipient}
            key={threadId}
          />
        </div>
        <div className="flex-shrink-1">
          <ResponseBar threadId={threadId} />
        </div>
      </div>
      <NewMessageToast threadId={threadId} />
    </div>
  )
}

export default ThreadDetail
