// @flow
import React from 'react'
import cn from 'classnames'
import isNil from 'lodash/isNil'
import capitalize from 'lodash/capitalize'
import { useSelector, useDispatch } from 'react-redux'

import Label, { DraftLabel } from '@edison/webmail-ui/components/Labels'
import LabelStrip from '@edison/webmail-ui/components/Labels/LabelStrip'
import { getDisplayLabelsById } from 'core/labels/selectors'
import { labelActions } from 'core/metadata/actions'
import { useLabelRemoveActions } from 'core/threads/hooks'
import { COLORS } from '@edison/webmail-ui/utils/constants'
import { labelTypes, labelNames, labelNameformat } from 'utils/constants'
import useOnMailTheme from 'hooks/useOnMailTheme'
import type { Dispatch } from 'types/redux'

type Props = {
  hoverable?: boolean,
  // Passed in to ensure unique keys for labels
  threadId?: string,
  strip?: boolean,
  labelIds: $ReadOnlyArray<string>,
  className?: string,
}

const Labels = ({
  strip = true,
  hoverable,
  labelIds,
  threadId,
  className,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const getLabelRemoveActions = useLabelRemoveActions()
  const theme = useOnMailTheme()
  const labelsById = useSelector(getDisplayLabelsById())

  const labels = labelIds
    .map(id => labelsById[id])
    .filter(label => !isNil(label))
  const draft = labels.filter(label => label.name === labelNames.drafts)
  const system = labels
    .filter(label => label.type === labelTypes.SYSTEM)
    .filter(label => label.name !== labelNames.drafts)
  const custom = labels
    .filter(label => label.type === labelTypes.CUSTOM)
    .sort((a, b) => (a.name < b.name ? -1 : 1))

  function handleOnRemove(label) {
    if (threadId) {
      const labelRemoveActions = getLabelRemoveActions({ id: threadId })
      if (label.type === labelTypes.CUSTOM) {
        dispatch(labelActions.update([], [label.id]).thread(threadId))
      } else {
        const action = labelRemoveActions[label.id]
        if (action) action.onClick()
      }
    }
  }

  const nodes = [...draft, ...system, ...custom].map(label => {
    const LabelNode = label.name === labelNames.drafts ? DraftLabel : Label
    return (
      <LabelNode
        theme={theme}
        key={isNil(threadId) ? label.id : `${threadId}_${label.id}`}
        hoverable={hoverable}
        className={cn(`mr-1`, className)}
        color={label.color || COLORS[COLORS.length - 1]}
        onRemove={() => handleOnRemove(label)}
      >
        {capitalizeName(label.type, label.name)}
      </LabelNode>
    )
  })

  if (strip) {
    return <LabelStrip>{nodes}</LabelStrip>
  } else return <>{nodes}</>
}

function capitalizeName(type: string, name: string) {
  if (name === labelNames.drafts) {
    return labelNameformat[name]
  }
  return type === labelTypes.SYSTEM ? capitalize(name) : name
}

export default Labels
