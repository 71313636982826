import { useEffect, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import qs from 'qs'

let historyStep = 0

export function useHistoryStep() {
  const history = useHistory()

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'PUSH') {
        historyStep++
      } else if (action === 'POP') {
        historyStep--
      }
    })
  }, [])
}

export default function useGoBack(fn, isGoBack = false) {
  const history = useHistory()
  const excuteFn = () => {
    typeof fn === 'function' ? fn() : history.replace(fn)
  }
  if (isGoBack) {
    return () => excuteFn()
  }
  return () => (historyStep ? history.goBack() : excuteFn())
}

export function useDetailModalGoBack(keyword, isGoBack = false) {
  const { search } = useLocation()
  const history = useHistory()
  const query = useMemo(() => qs.parse(search.slice(1)), [search])

  return useGoBack(() => {
    const newQuery = { ...query }
    delete newQuery[keyword]
    history.replace({ search: `?${qs.stringify(newQuery)}` })
  }, isGoBack)
}

export function useGoBackOrGoHome() {
  const { userId } = useParams()
  return useGoBack(userId ? `/u/${userId}/inbox` : '/')
}
