// @flow
import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, withRouter, generatePath } from 'react-router-dom'
import { useModal } from 'common/modals'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import {
  labelNames,
  labelRouteNames,
  modalTypes,
  routePaths,
} from 'utils/constants'

import { useOrderId } from 'core/auth/hooks'
import { useActiveLabel } from 'core/threads/hooks'
import * as labelSelectors from 'core/labels/selectors'
import { dropThreads } from 'core/threads/actions'

import { useDropZone, dragTypes } from '@edison/webmail-ui/hooks/dnd'
import Add from '@material-ui/icons/AddCircleOutline'
import { HeaderWrapper, HeaderItem } from '@edison/webmail-ui/components/Header'

import type { Match, RouterHistory } from 'react-router-dom'
import type { Dispatch } from 'types/redux'
import { useSortedSplitInboxes } from '../../../core/split-inboxes/hooks'
import { resetSelectThread } from '../../../core/threads/actions'

const getLabelUnread = labelSelectors.getLabelUnreadCount()

type Props = {
  match: Match,
  history: RouterHistory,
}

const DroppableHeaderItem = ({ onDrop, ...props }) => {
  const { ref, isOver } = useDropZone({
    acceptType: dragTypes.message,
    onDrop,
  })
  return (
    <HeaderItem
      ref={props.isActive ? null : ref}
      isDragOver={isOver}
      {...props}
    />
  )
}

const View = ({ match, history }: Props) => {
  const label: ?string = match.params.label
  const userId = useOrderId()

  const inboxUrl = generatePath(routePaths.main, {
    userId,
    label: label || 'inbox',
  })

  const activeLabel = useActiveLabel()
  const dispatch: Dispatch = useDispatch()
  const labelUnread = useSelector(getLabelUnread)
  const { showModal } = useModal(modalTypes.splitInbox)

  const handleOnDrop = useCallback(
    (target: string) => {
      dispatch(dropThreads({ from: activeLabel, to: target })).finally(() =>
        dispatch(resetSelectThread())
      )
    },
    [activeLabel]
  )

  const handleOnClick = useCallback(
    (nextSplit: string) => {
      let next = inboxUrl

      if (nextSplit !== labelNames.primary) {
        next = generatePath(routePaths.splitInbox, {
          userId,
          label,
          splitInboxId:
            nextSplit === labelNames.other
              ? labelRouteNames[labelNames.other]
              : nextSplit,
        })
      }

      history.push(next)
    },
    [userId, label, inboxUrl]
  )
  const sortedInboxes = useSortedSplitInboxes()

  if (isNil(label)) {
    return <Redirect to={inboxUrl} />
  }

  if (isNil(label)) {
    return <Redirect to={inboxUrl} />
  }

  return (
    <HeaderWrapper
      suffix={
        <HeaderItem
          name={
            <div className="flex ml-6">
              <Add fontSize="small" />
            </div>
          }
          unread={0}
          onClick={() => showModal()}
          isActive={false}
        />
      }
    >
      {sortedInboxes.map(({ labelId, name }) => (
        <DroppableHeaderItem
          key={labelId}
          name={name}
          isActive={activeLabel === labelId}
          unread={get(labelUnread, labelId, 0)}
          onDrop={() => handleOnDrop(labelId)}
          onClick={() => handleOnClick(labelId)}
        />
      ))}
    </HeaderWrapper>
  )
}

export default withRouter(View)
