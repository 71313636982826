// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { labelNames } from 'utils/constants'

import { useActiveLabel } from 'core/threads/hooks'
import { getThreadsById } from 'core/threads/selectors'
import { isLabelStateLoaded } from 'core/labels/selectors'
import { getThreadLabelIds } from 'core/metadata/selectors'
import Labels from 'common/Labels'
import { ThreadLabelPicker } from 'common/Labels/Picker'

type Props = {
  threadId: string,
}

const labelStateLoaded = isLabelStateLoaded()

//todo: handle thread labels in metadata
const LabelsView = ({ threadId }: Props) => {
  const activeLabel = useActiveLabel()

  const labelsSelector = useMemo(() => getThreadLabelIds(threadId), [threadId])
  const values = useSelector(labelsSelector)

  return (
    <div className="inline">
      <Labels
        hoverable
        strip={false}
        threadId={threadId}
        labelIds={values.filter(id =>
          activeLabel === labelNames.trash ? true : id !== labelNames.trash
        )}
      />
      <ThreadLabelPicker inline threadId={threadId} />
    </div>
  )
}

export default ({ threadId }: Props) => {
  const isLabelLoaded = useSelector(labelStateLoaded)
  const threadsById = useSelector(getThreadsById)
  const isThreadLoaded = !!threadsById[threadId]

  const isLoaded = isLabelLoaded && isThreadLoaded
  return isLoaded ? <LabelsView threadId={threadId} /> : null
}
