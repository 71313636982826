import React, { useMemo, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import equal from 'deep-equal'
import { formatRecipients } from 'utils'
import Recipients from './Recipients'
import {
  getResponseType,
  getDraft,
  isDraftDisabled,
} from 'core/compose/selectors'
import { isEmail } from '@edison/webmail-ui/utils'

import { updateDraft, debouncedSaveDraft } from 'core/compose/actions'

function equalRecipients(newRecipients, oldRecipients) {
  return ['to', 'cc', 'bcc'].every(each =>
    equal(
      formatRecipients(newRecipients[each]),
      formatRecipients(oldRecipients[each])
    )
  )
}

function RecipientStatus({ statusNode, values, actived }) {
  const { t } = useTranslation()
  const allRecipients = useMemo(() => {
    const recipients = [...values.to, ...values.cc]
    const totalLength = [...recipients, ...values.bcc].length
    return totalLength ? (
      <>
        {recipients.map((each, index) => (
          <Fragment key={index}>
            <span className={!isEmail(each.email) ? 'text-red' : ''}>
              {each.name || each.email}
            </span>
            {index !== recipients.length - 1 && ', '}
          </Fragment>
        ))}
        {totalLength !== recipients.length && ', '}
        {values.bcc.length > 0 && (
          <>
            <span>Bcc: </span>
            {values.bcc.map((each, index) => (
              <Fragment key={index}>
                <span className={!isEmail(each.email) ? 'text-red' : ''}>
                  {each.name || each.email}
                </span>
                {index !== values.bcc.length - 1 && ', '}
              </Fragment>
            ))}
          </>
        )}
      </>
    ) : (
      t('compose.label.emptyRecipient')
    )
  }, [values])
  return (
    <div
      className="flex items-center justify-between "
      style={{ minHeight: 40 }}
    >
      <div className="text-gray-medium line-clamp">
        {!actived ? <span className="text-primary">Draft</span> : 'Me'}
        &nbsp;to&nbsp;
        {allRecipients}
      </div>
      {statusNode}
    </div>
  )
}

export default ({ draftId, onSelectRecipient, statusNode }) => {
  const disabled = useSelector(
    useMemo(() => isDraftDisabled(draftId), [draftId])
  )
  const responseType = useSelector(
    useMemo(() => getResponseType(draftId), [draftId])
  )
  const { to, cc, bcc, replyTo, actived } = useSelector(
    useMemo(() => getDraft(draftId), [draftId])
  )

  const recipients = useMemo(() => {
    if (replyTo && replyTo.length) {
      return { replyTo, cc, to: [], bcc }
    } else {
      return { to, cc, bcc }
    }
  }, [to, cc, bcc, replyTo])
  const dispatch = useDispatch()
  const recipientStatusNode = useMemo(
    () => (
      <RecipientStatus
        values={recipients}
        actived={actived}
        statusNode={statusNode}
      />
    ),
    [recipients, actived, statusNode]
  )
  return (
    <Recipients
      recipientStatusNode={recipientStatusNode}
      disabled={disabled}
      onSelectRecipient={onSelectRecipient}
      responseType={responseType}
      values={recipients}
      onChange={newRecipients => {
        if (equal(recipients, newRecipients)) return
        dispatch(
          updateDraft({
            id: draftId,
            ...newRecipients,
          })
        )
        !equalRecipients(newRecipients, { to, cc, bcc }) &&
          dispatch(debouncedSaveDraft(draftId))
      }}
    />
  )
}
