import React, { useState } from 'react'
import isNil from 'lodash/isNil'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@edison/webmail-ui/components/Tooltip'

const MoreAction = ({ menus = [], disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [advanced, setAdvanced] = useState(false)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  if (!menus.length) return null

  const advancedMenus = menus.filter(item => item.advanced)
  let advancedNode = null
  if (!!advancedMenus.length) {
    advancedNode = !advanced ? (
      <MenuItem
        onMouseEnter={() => {
          setAdvanced(true)
        }}
      >
        <span className="text-gray-light">{t('message.action.advanced')}</span>
      </MenuItem>
    ) : (
      advancedMenus.map(({ icon, title, onClick }, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            onClick()
            setAnchorEl(null)
          }}
        >
          {title}
        </MenuItem>
      ))
    )
  }

  return (
    <>
      <Tooltip title={t('button.more')}>
        <IconButton
          disableRipple
          disableFocusRipple
          size="small"
          aria-controls="more-menu"
          disabled={!menus.length || disabled}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        open={open}
        id="more-menu"
        elevation={2}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          className: 'shadow-hover dark:shadow-lg-t bg-app !dark:bg-secondary',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menus
          .filter(item => !item.advanced)
          .map(({ icon, title, onClick, Wrapper }, index) => {
            if (!isNil(Wrapper)) {
              return (
                <Wrapper key={index}>
                  <MenuItem>{title}</MenuItem>
                </Wrapper>
              )
            }
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  onClick()
                  setAnchorEl(null)
                }}
              >
                {title}
              </MenuItem>
            )
          })}
        {advancedNode}
      </Menu>
    </>
  )
}
export default MoreAction
