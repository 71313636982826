// @flow
import React from 'react'
import { useSelector } from 'react-redux'

import { FileStoragePaywall } from '@edison/webmail-ui/components/Paywall'
import { getRemainingSizeSelector } from 'core/usage/selectors'
import { getFeatureFlags, isPlanUpgradeable } from 'core/premium/selectors'
import { formatSize } from 'utils'

type Props = { isOpen: boolean, toggle: () => mixed, onClose: () => mixed }

const View = (props: Props) => {
  const { totalStorageLimit } = useSelector(getFeatureFlags)
  const remainingSize = useSelector(getRemainingSizeSelector)
  const isUpgradeable = useSelector(isPlanUpgradeable)

  const noUpgrade = !isUpgradeable
  const available = formatSize(remainingSize)
  const limit = formatSize(totalStorageLimit)
  const isFull = remainingSize <= 0

  return (
    <FileStoragePaywall
      available={available}
      limit={limit}
      noUpgrade={noUpgrade}
      isFull={isFull}
      {...props}
    />
  )
}

export default View
