// @flow
import AccountPillItem, {
  DEFALUT_TEXT,
} from '@edison/webmail-ui/components/Pill/AccountPill'
import { useRetrofitAccounts } from 'core/retrofit/hooks'
import React, { useEffect, useRef } from 'react'
import { useAccountList } from 'screens/Retrofit/AccountSwitcher'
import { useCurrentActiveAccount } from 'core/retrofit/hooks'
import { retrofitAccountFilter } from 'utils/constants'

function useDefalutText() {
  const { active } = useRetrofitAccounts()
  return active ? active.emailAddress : DEFALUT_TEXT
}

function isOnlyOneOnmailAccount(accountList) {
  const ecUUID = accountList.map(item => item.ecUUID)
  if (
    accountList.length === 2 &&
    ecUUID.includes(retrofitAccountFilter.ONMAIL)
  ) {
    return true
  }
  return false
}

export function AccountPill(props) {
  const { onSelect, condition, isClearAll } = props
  const accountList = useAccountList()
  const ref = useRef()
  const currentActiveAccount = useCurrentActiveAccount()
  const defalutText = useDefalutText()
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])

  if (isOnlyOneOnmailAccount(accountList)) {
    return null
  }

  return (
    <AccountPillItem
      ref={ref}
      onSelect={account => {
        const nextCondition = {
          ...condition,
          accountLabel: account.map(item => item.labelUUID) || [],
        }
        onSelect(nextCondition)
      }}
      currentActiveAccount={currentActiveAccount}
      defalutText={defalutText}
      accountList={accountList}
      retrofitAccountFilter={retrofitAccountFilter}
    />
  )
}
