// @flow
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom'

import Label from '@edison/webmail-ui/components/Navigation/components/Label'
import Labels from '@edison/webmail-ui/components/Navigation/components/Labels'
import DroppableLabel from './DroppableLabel'

import { betaFeatures } from '@edison/webmail-core/utils/constants'
import {
  labelNames,
  labelRouteNames,
  displayTitles,
  routePaths,
  smartFolders as smartFolderIds,
} from 'utils/constants'
import { useOrderId } from 'core/auth/hooks'
import { dropThreadsToSmartFolder } from 'core/threads/actions'
import { useBetaFeature } from 'core/beta-features/hooks'

import type { Dispatch } from 'types/redux'

type Props = {
  invert: boolean,
  activeId: ?string,
  setLabel: (labelId: string) => void,
}
export default ({ invert, activeId, setLabel }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const { isEnable: calendarEnabled } = useBetaFeature(betaFeatures.calendar)

  const history = useHistory()
  const routeMatch = useRouteMatch()
  const { url } = routeMatch
  const userId = useOrderId()

  const smartFolders = useMemo(() => {
    const droppable = [
      // INFO: Smart folders won't contain the threads from spam and trash
      labelRouteNames[labelNames.spam],
      labelRouteNames[labelNames.trash],
      // INFO: Threads from drafts shouldn't be dragged into smart folder either
      labelRouteNames[labelNames.drafts],
    ].every(route => activeId !== route)

    return smartFolderIds.map(id => ({
      id,
      route: labelRouteNames[id],
      title: displayTitles[id],
      Component:
        droppable && id !== labelNames.priceTracking ? DroppableLabel : Label,
    }))
  }, [activeId])

  const calendarFolder = useMemo(() => {
    if (!calendarEnabled) return null
    const path = generatePath(routePaths.main, { userId, label: 'calendar' })
    return (
      <Label
        id={path}
        invert={invert}
        key="calendar"
        name="Calendar"
        isActive={url.startsWith(path)}
        onClick={() => history.push(path)}
      />
    )
  }, [url, userId, invert])

  const contactsFolder = useMemo(() => {
    const path = generatePath(routePaths.main, { userId, label: 'contacts' })
    return (
      <Label
        id={path}
        invert={invert}
        key="contacts"
        name="Contacts"
        isActive={url.startsWith(path)}
        onClick={() => history.push(path)}
      />
    )
  }, [url, userId, invert])

  const attachmentFolder = useMemo(() => {
    const path = generatePath(routePaths.main, { userId, label: 'attachments' })
    return (
      <Label
        id={path}
        invert={invert}
        key="attachments"
        name="Attachments"
        isActive={url.startsWith(path)}
        onClick={() => history.push(path)}
      />
    )
  }, [url, userId, invert])

  return (
    <Labels>
      {calendarFolder}
      {contactsFolder}
      {attachmentFolder}
      {smartFolders.map(({ id, route, title, Component }) => (
        <Component
          id={id}
          key={id}
          name={title}
          color=""
          unread={0}
          invert={invert}
          isActive={activeId === route}
          onClick={() => setLabel(route)}
          onDrop={() => dispatch(dropThreadsToSmartFolder(id))}
        />
      ))}
    </Labels>
  )
}
