// @flow
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux'

import * as customDomains from 'core/custom-domains'
import * as auth from 'core/auth'

import ConvertForm, {
  ConvertConfirm,
} from '@edison/webmail-ui/screens/Settings/components/CustomDomain/ConvertEmail'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ isOpen, toggle }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const authState = useSelector(auth.selectors.getAuth())
  const current = useSelector(customDomains.selectors.getCurrentDomain)
  const isLoading = useSelector(
    customDomains.selectors.getCreateSubaccountLoading
  )
  const { onAddAccount } = auth.hooks.useSession()
  const domain = !!current ? current.domain : ''

  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  const onCreate = (accountName, formikBag) => {
    dispatch(
      customDomains.actions.createSubaccount(accountName, { thisUser: true })
    ).then(({ isSuccess, message }) => {
      if (isSuccess) {
        setEmail(`${accountName}@${domain}`)
        setSuccess(true)
      } else {
        formikBag.setFieldError('email', message)
      }
    })
  }

  const onConfirm = () => {
    // Redirect to `/login/add-account` instead of
    // logging out of all the accounts
    //
    // https://easilydo.atlassian.net/browse/EWM-2215
    onAddAccount()
  }

  if (authState === null) {
    return null
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      {!success ? (
        <ConvertForm
          title={t('settings.customDomain.convertEmail.form.title')}
          description={t(
            'settings.customDomain.convertEmail.form.description',
            { email: authState.user }
          )}
          warning={t('settings.customDomain.convertEmail.form.warning')}
          domain={domain}
          isLoading={isLoading}
          onClose={toggle}
          onSubmit={onCreate}
        />
      ) : (
        <ConvertConfirm
          customEmail={email}
          originalEmail={authState.user}
          onConfirm={onConfirm}
        />
      )}
    </Dialog>
  )
}

View.displayName = 'ConvertDomainAdmin'

export default View
