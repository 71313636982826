// @flow
import { createReducer } from 'utils/redux'

import * as emailNudgesActions from './actions'

import type { EmailNudge } from '@edison/webmail-core/types/email-nudges'
import type { FetchEmailNudgeSuccess, MessageNudgeUpdateSuccess } from './types'

export type State = {
  [threadId: string]: EmailNudge,
}

const reducer = createReducer<State, FetchEmailNudgeSuccess>(
  {},
  {
    [emailNudgesActions.fetchEmailNudgesActions.success.toString()]: fetchEmailNudgesSuccess,
    [emailNudgesActions.messageNudgeUpdateActions.success.toString()]: nudgeUpdateSuccess,
  }
)

function fetchEmailNudgesSuccess(state: State, action: FetchEmailNudgeSuccess) {
  const emailNudges = action.payload
  const nudgeMap = emailNudges.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.threadId]: curr,
    }),
    {}
  )

  return {
    ...state,
    ...nudgeMap,
  }
}

function nudgeUpdateSuccess(state: State, action: MessageNudgeUpdateSuccess) {
  const { threadId } = action.payload
  const { [threadId]: _, ...nextState } = state

  return nextState
}

export default reducer
