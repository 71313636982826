// @flow
import React from 'react'
import { useDispatch } from 'react-redux'
import { localStorageFlags } from 'core/flags/constants'
import { batchBlockSenders } from 'core/contacts/actions'
import { useLocalStorageFlag } from 'core/flags/hooks'

import BlockPrompt from '@edison/webmail-ui/components/ApproveSender/Prompt'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  toggle: () => void,
  emails: $ReadOnlyArray<string>,
  applyAll: boolean,
  callBack?: () => void,
}
export default ({
  isOpen,
  toggle,
  emails,
  applyAll = true,
  callBack,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const promptFlag = useLocalStorageFlag(localStorageFlags.blockSenderPromptKey)
  function handleOnConfirm(doNotShowAgain) {
    if (doNotShowAgain) {
      promptFlag.set()
    }
    toggle()
    dispatch(batchBlockSenders(emails, applyAll)).then(
      () => callBack && callBack()
    )
  }
  return (
    <BlockPrompt
      open={isOpen}
      emails={emails}
      onClose={toggle}
      onConfirm={handleOnConfirm}
    />
  )
}
