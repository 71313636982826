// @flow
import { useDispatch, useSelector } from 'react-redux'
import { modalTypes } from 'utils/constants'
import { useModal } from 'common/modals'

import * as actions from './actions'
import * as selectors from './selectors'

import type {
  ScheduledBreak,
  CreatedScheduledBreak,
} from '@edison/webmail-core/types/inbox-break'
import type { Dispatch } from 'types/redux'

export const useCurrentBreak = () => {
  const dispatch: Dispatch = useDispatch()
  const manualSetupModal = useModal(modalTypes.manualSetup)
  const isLoading = useSelector(selectors.isBreakStatusLoading)
  const isExecuting = useSelector(selectors.isExecuteBreakLoading)
  const isFullscreenAlertShowed = useSelector(selectors.isFullscreenAlertShowed)

  const currBreak = useSelector(selectors.getCurrentBreakInfo)

  function onFetchStatus() {
    dispatch(actions.fetchBreakStatus())
  }

  function onToggle({
    enable,
    replyMessage = currBreak.replyMessage,
    enableAutoReply = currBreak.enableAutoReply,
  }: {
    enable: boolean,
    replyMessage: string,
    enableAutoReply: boolean,
  }) {
    return dispatch(
      actions.executeBreak({ enable, enableAutoReply, replyMessage })
    )
  }

  function onUpdate({
    enableAutoReply,
    replyMessage,
  }: {
    enableAutoReply: boolean,
    replyMessage: string,
  }) {
    return dispatch(
      actions.updateBreakSetting({ enableAutoReply, replyMessage })
    )
  }

  function onShowFullscreenAlert() {
    manualSetupModal.showModal({ success: true })
  }

  return {
    ...currBreak,
    onToggle,
    onUpdate,
    isLoading,
    onFetchStatus,
    isExecuting,
    onShowFullscreenAlert,
    shouldShowFullscreenAlert: currBreak.enable && !isFullscreenAlertShowed,
  }
}

export const useScheduledBreaks = () => {
  const dispatch: Dispatch = useDispatch()
  const isLoading = useSelector(selectors.isBreakSettingsLoading)
  const isCreating = useSelector(selectors.isScheduledBreakCreating)

  const schedules = useSelector(selectors.getScheduledBreaks)

  function onCreate(values: ScheduledBreak) {
    return dispatch(actions.createScheduledBreak(values))
  }

  function onFetch() {
    dispatch(actions.fetchBreakSettings())
  }

  return {
    onFetch,
    onCreate,
    schedules,
    isLoading,
    isCreating,
  }
}

export const useRecentMessages = () => {
  const dispatch: Dispatch = useDispatch()

  const messages = useSelector(selectors.getRecentMessages)

  function onFetch() {
    dispatch(actions.fetchRecentMessages())
  }

  function onRemove(id: string) {
    return dispatch(actions.removeRecentMessage(id))
  }

  return {
    messages,
    onRemove,
    onFetch,
  }
}

export const useScheduledBreak = (id?: string = '') => {
  const dispatch: Dispatch = useDispatch()
  const isUpdating = useSelector(selectors.isScheduledBreakUpdating)
  const isRemoving = useSelector(selectors.isScheduledBreakRemoving)

  const schedule = useSelector(selectors.getScheduledBreakById(id))

  function onUpdate(values: CreatedScheduledBreak) {
    return dispatch(actions.updatescheduledBreak(values))
  }

  function onRemove() {
    return dispatch(actions.removeScheduledBreak(id))
  }

  return {
    schedule,
    onUpdate,
    onRemove,
    isUpdating,
    isRemoving,
  }
}
