// @flow
import i18next from 'i18next'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import max from 'lodash/max'
import mapValues from 'lodash/mapValues'
import values from 'lodash/values'
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'
import { isDomainAdmin, getCurrentDomain } from 'core/custom-domains/selectors'
import { generateFeatureFlags } from './flags'
import { formatSize } from 'utils'
import {
  usernameAllowedPlans,
  usernameTypes,
  premiumPlanRanks,
} from 'utils/constants'
import { premiumSources } from '@edison/webmail-core/utils/constants'

import type {
  CurrentPremiumPlan,
  PremiumPlan,
  Features,
  PaymentMethod,
  BillingHistoryItem,
  PremiumPreview,
} from '@edison/webmail-core/types/premium'
import type { PremiumSource } from '@edison/webmail-core/utils/constants'
import type { State, Selector } from 'types/state'

export const getPremiumState = (state: State) => state.premium

export const getCurrentPlan: Selector<?CurrentPremiumPlan> = createSelector(
  getPremiumState,
  state => state.currentPlan
)

export const getPremiumPlans: Selector<
  $ReadOnlyArray<PremiumPlan>
> = createSelector(getPremiumState, state => {
  const { currentPlan, order, plans } = state
  return (
    (order || [])
      .map(id => plans[id])
      // Only the visible and current plans should be listed
      .filter(
        plan => plan.visible || (currentPlan && currentPlan.id === plan.id)
      )
  )
})

export const getPremiumPlansById: Selector<{
  [planId: string]: PremiumPlan,
}> = createSelector(getPremiumState, state => state.plans)

type FeatureFlags = $Call<typeof generateFeatureFlags, Features>
export const getFeatureFlags: Selector<FeatureFlags> = createSelector(
  getCurrentPlan,
  plan => {
    if (isNil(plan)) {
      return generateFeatureFlags({})
    }

    return generateFeatureFlags(plan.features)
  }
)

export const getBasicPlan: Selector<?PremiumPlan> = createSelector(
  getPremiumPlans,
  plans => {
    // Returns the cheapest plan after the free plan
    if (plans.length < 1) {
      return null
    }

    return plans[1]
  }
)

export const getPremiumUsernamePlan: Selector<?PremiumPlan> = createSelector(
  getPremiumState,
  state => {
    return state.plans[usernameAllowedPlans[usernameTypes.short][0]]
  }
)

export const getLatestSubscriptionPending: Selector<?PremiumPlan> = createSelector(
  getPremiumState,
  state => {
    if (isNil(state.latestSubscription)) {
      return null
    }

    return state.latestSubscription.pendingPlan
  }
)

export const getFeaturesByPlanId: Selector<{
  [id: string]: $ReadOnlyArray<string>,
}> = createSelector(getPremiumState, state => {
  const featureOrder = [
    'fileStorage',
    'customDomain',
    'passwordProtectedLargeFileLinks',
    'singleFileSizeLimit',
    'brandedFileTransferPage',
    'customFileTransferUrl',
    'adminDashboard',
    'premiumSupport',
    'importAccountsLimit',
  ]

  return mapValues(state.plans, plan => {
    const { features, metaData } = plan
    return featureOrder
      .filter(
        feature =>
          (features && features[feature]) || (metaData && metaData[feature])
      )
      .map(feature => {
        let params
        switch (feature) {
          case 'fileStorage':
            params = { size: formatSize(features[feature]) }
            break
          case 'singleFileSizeLimit':
            params = { size: formatSize(features[feature]) }
            break
          case 'importAccountsLimit':
            if (features[feature] === -1) {
              return i18next.t(`premium.features.${feature}.unlimted`)
            } else {
              params = { count: features[feature] }
            }
            break
          default:
            params = {}
        }
        return i18next.t(`premium.features.${feature}`, params)
      })
  })
})

export const getPremiumOwner: Selector<?string> = createSelector(
  getCurrentPlan,
  plan => (plan ? plan.owner : null)
)

export const getPaymentMethod: Selector<?PaymentMethod> = createSelector(
  getPremiumState,
  state => state.paymentMethod
)

export const getPaymentError: Selector<boolean> = createSelector(
  getPremiumState,
  state => {
    if (isNil(state.paymentMethod)) {
      return false
    }

    return !state.paymentMethod.isValid
  }
)

export const isAccountLocked: Selector<boolean> = createSelector(
  getPremiumState,
  state => {
    // User should not be allowed to use their account if their Stripe status
    // is either incomplete or incomplete expired
    //
    // Redirect to Settings/account
    const status = get(state.latestSubscription, 'subscription.status')

    return status === 'incomplete' || status === 'incomplete_expired'
  }
)

export const getLatestSubscriptionPlan: Selector<?CurrentPremiumPlan> = createSelector(
  getPremiumState,
  state => {
    if (isNil(state.latestSubscription)) {
      return null
    }

    return state.latestSubscription.plan
  }
)

export const getLatestSubscriptionFeatures: Selector<FeatureFlags> = createSelector(
  getLatestSubscriptionPlan,
  plan => {
    if (isNil(plan)) {
      return generateFeatureFlags({})
    }

    return generateFeatureFlags(plan.features)
  }
)

export const getPremiumPreviewById: Selector<{
  [planId: string]: PremiumPreview,
}> = createSelector(getPremiumState, state => state.preview)

export const canChangePlan: Selector<boolean> = createSelector(
  getCurrentDomain,
  isDomainAdmin,
  // Has custom domain, and is an admin of the domain or no domain
  (current, isAdmin) => (!isNil(current) ? isAdmin : true)
)

// Determine if user's premium plan is upgradeable
export const isPlanUpgradeable: Selector<boolean> = createSelector(
  getCurrentPlan,
  current => {
    if (isNil(current)) {
      return true
    }

    const planRank = premiumPlanRanks[current.id]
    return planRank < max(values(premiumPlanRanks))
  }
)

/**
 * Loading status.
 */
export const getPremiumPlansLoading: Selector<boolean> = getLoadingStatus(
  'FETCH_PLANS',
  true
)

export const getPaymentMethodLoading: Selector<boolean> = getLoadingStatus(
  'FETCH_PAYMENT_METHOD',
  true
)

export const getUpdatePaymentMethodLoading: Selector<boolean> = getLoadingStatus(
  'UPDATE_PAYMENT_METHOD'
)

export const getCurrentPlanLoading: Selector<boolean> = getLoadingStatus(
  'FETCH_CURRENT_PLAN',
  true
)

export const getPurchaseLoading: Selector<boolean> = getLoadingStatus(
  'PURCHASE_PLAN'
)

export const getUpdatePlanLoading: Selector<boolean> = getLoadingStatus(
  'CHANGE_PLAN'
)

export const getBillingHistoryLoading: Selector<boolean> = getLoadingStatus(
  'FETCH_BILLING_HISTORY'
)

export const getSignupLoading: Selector<boolean> = getLoadingStatus(
  'PREMIUM_SIGNUP'
)

export const getUnsubscribeLoading: Selector<boolean> = getLoadingStatus(
  'UNSUBSCRIBE_PLAN'
)

export const getPreviewLoading: Selector<boolean> = getLoadingStatus(
  'PREMIUM_PREVIEW'
)

export const getPremiumSourece: Selector<PremiumSource> = createSelector(
  getCurrentPlan,
  current => (current ? current.source : premiumSources.NONE)
)

export const isSubscribedOnOtherPlatform: Selector<boolean> = createSelector(
  getPremiumSourece,
  source => source !== premiumSources.NONE && source !== premiumSources.STRIPE
)

export const getBillingHistory: Selector<
  $ReadOnlyArray<BillingHistoryItem>
> = createSelector(
  getPremiumState,
  isSubscribedOnOtherPlatform,
  (state, otherPlatformSubscription) => {
    if (otherPlatformSubscription) {
      return []
    } else {
      return state.billingHistory
    }
  }
)

export const isAllowUnlimitedImportAccounts: Selector<boolean> = createSelector(
  getFeatureFlags,
  flags => flags.totalImportAccountsLimit === -1
)

export const isAllowImportAccounts: Selector<boolean> = createSelector(
  getFeatureFlags,
  isAllowUnlimitedImportAccounts,
  (flags, unlimitedImportAccounts) =>
    flags.totalImportAccountsLimit > 0 || unlimitedImportAccounts
)
