// @flow
import React, { useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'
import { modalTypes, routePaths } from 'utils/constants'

import { useModal } from 'common/modals'
import * as analytics from 'core/analytics'
import { useSession, useOrderId } from 'core/auth/hooks'
import {
  useProviderNames,
  useProviderLogos,
  useAuthorizationUrl,
  useRetrofitAccounts,
  useSupportedProviders,
} from 'core/retrofit/hooks'
import { useCurrentPlan } from 'core/premium/hooks'
import { getFeatureFlags, isAllowImportAccounts } from 'core/premium/selectors'
import { usePaywall } from 'common/Paywall'

import Avatar from '@edison/webmail-ui/components/Avatar'
import AccountProvidersModal, {
  OnMailAccount,
  RetrofitAccounts,
  UpgradeButton,
} from '@edison/webmail-ui/components/AccountProviders'
import {
  AccountHorizontalList,
  AccountChip,
} from '@edison/webmail-ui/components/Retrofit/AccountList'
import { AccountQuotaMessage } from '@edison/webmail-ui/components/Retrofit'
import AccountItem from '@edison/webmail-ui/components/Retrofit/OAuthListItem'

import type { Node } from 'react'
import type { Dispatch } from 'types/redux'
import { premiumFreePlanId } from '../../utils/constants'

type Props = {
  message?: Node,
  isOpen: boolean,
  toggle: () => void,
}
const View = ({ isOpen, toggle, message }: Props) => {
  const { t } = useTranslation()
  const userId = useOrderId()
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const [loading, setLoading] = useState(null)
  const removalConfirm = useModal(modalTypes.accountRemovalConfirmation)

  const names = useProviderNames()
  const { logos, getLogo } = useProviderLogos()
  const { onAddAccount } = useSession()
  const { getUrl } = useAuthorizationUrl()
  const flags = useSelector(getFeatureFlags)
  const allowImportAccounts = useSelector(isAllowImportAccounts)
  const { canImport, accounts } = useRetrofitAccounts()
  const paywalls = usePaywall()

  const currentPlan = useCurrentPlan()
  const isPremium = currentPlan.id !== premiumFreePlanId

  const supportedProviders = useSupportedProviders()

  const onUpgradeHandler = useCallback(() => {
    toggle()
    dispatch(analytics.actions.premium.userUpgradePrompt())
    history.push(generatePath(routePaths.pricing, { userId }))
  }, [dispatch, history, userId])

  const reachedQuotaLimit = useMemo(() => {
    return !canImport && accounts.length > 0
  }, [canImport, accounts])

  const quotaMessage = useMemo(() => {
    if (reachedQuotaLimit) {
      return (
        <>
          <AccountQuotaMessage
            isPremium={isPremium}
            count={accounts.length}
            maxCount={flags.totalImportAccountsLimit}
          />
          <AccountHorizontalList
            columnWidth={340}
            accounts={accounts}
            cellRenderer={({ data: account, style, className }) => (
              <div key={account.ecUUID} style={style} className={className}>
                <AccountChip
                  avatar={
                    <Avatar src={getLogo(account)} alt={account.emailAddress} />
                  }
                  emailAddress={account.emailAddress}
                  onClick={() => {
                    removalConfirm.showModal({ ecUUID: account.ecUUID })
                  }}
                />
              </div>
            )}
          />
        </>
      )
    } else return null
  }, [reachedQuotaLimit, accounts, removalConfirm, isPremium])

  async function handleOnClickRetrofitAccount(provider) {
    if (allowImportAccounts) {
      setLoading(provider)
      await getUrl(provider)
      setLoading(null)
    } else {
      paywalls.retrofit.show()
    }
    toggle()
  }

  return (
    <AccountProvidersModal open={isOpen} onClose={toggle}>
      <OnMailAccount>
        <AccountItem
          disabled={!!loading}
          onClick={onAddAccount}
          name={t('welcome.brand')}
          image={t('onmail-avatar.png')}
        />
      </OnMailAccount>
      <RetrofitAccounts message={quotaMessage}>
        {reachedQuotaLimit && !isPremium ? (
          <UpgradeButton onClick={onUpgradeHandler} />
        ) : (
          supportedProviders.map(provider => (
            <AccountItem
              key={provider}
              name={names[provider]}
              image={logos[provider]}
              isLoading={provider === loading}
              disabled={!canImport || (!!loading && provider !== loading)}
              onClick={() => handleOnClickRetrofitAccount(provider)}
            />
          ))
        )}
      </RetrofitAccounts>
      {message}
    </AccountProvidersModal>
  )
}

View.displayName = 'AccountProvidersModal'

export default View
