// @flow
import i18next from 'i18next'
import { batch } from 'react-redux'
import trim from 'lodash/trim'
import isNil from 'lodash/isNil'
import * as client from '@edison/webmail-core/api'
import { showNotification } from 'core/toasts/actions'
import { createLabelActions, deleteLabelActions } from 'core/labels/actions'
import { createFilterActions, deleteFilterActions } from 'core/filters/actions'
import { getAuth, getOrderId } from 'core/auth/selectors'
import { getSplitInboxById, getSplitInboxByLabelId } from './selectors'

import { toastVariants } from 'common/toasts'
import { createAction } from 'utils/redux'
import { labelNames } from 'utils/constants'

import type {
  SplitInboxListRequest,
  SplitInboxListSuccess,
  SplitInboxListFailure,
  SplitInboxCreateRequest,
  SplitInboxCreateSuccess,
  SplitInboxCreateFailure,
  SplitInboxUpdateRequest,
  SplitInboxUpdateSuccess,
  SplitInboxUpdateFailure,
  SplitInboxUpdateOrderRequest,
  SplitInboxUpdateOrderSuccess,
  SplitInboxUpdateOrderFailure,
  SplitInboxDeleteRequest,
  SplitInboxDeleteSuccess,
  SplitInboxDeleteFailure,
  InitialListViews,
  ToggleSplitView,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type { SplitViewType } from '@edison/webmail-core/utils/constants'
import type { SplitInboxCreateBody } from '@edison/webmail-core/types/split-inboxes'

export const fetchSplitInboxesActions: {
  request: ActionCreator<SplitInboxListRequest>,
  success: ActionCreator<SplitInboxListSuccess>,
  failure: ActionCreator<SplitInboxListFailure>,
} = {
  request: createAction('SPLIT_INBOX_LIST_REQUEST'),
  success: createAction('SPLIT_INBOX_LIST_SUCCESS'),
  failure: createAction('SPLIT_INBOX_LIST_FAILURE'),
}

/**
 * Fetch data for a list of config for split inboxes
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchSplitInboxes(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const orderId = getOrderId(state)

    if (auth === null) {
      dispatch(
        fetchSplitInboxesActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(fetchSplitInboxesActions.request())
      const res = await client.splitInboxes.list({ auth })
      const splitInboxes = res.result.settings
      dispatch(fetchSplitInboxesActions.success(splitInboxes))
      dispatch(initialListViews({ splitInboxes, orderId }))
    } catch (e) {
      dispatch(fetchSplitInboxesActions.failure({ message: e.message }))
    }
  }
}

export const createSplitInboxActions: {
  request: ActionCreator<SplitInboxCreateRequest>,
  success: ActionCreator<SplitInboxCreateSuccess>,
  failure: ActionCreator<SplitInboxCreateFailure>,
} = {
  request: createAction('SPLIT_INBOX_CREATE_REQUEST'),
  success: createAction('SPLIT_INBOX_CREATE_SUCCESS'),
  failure: createAction('SPLIT_INBOX_CREATE_FAILURE'),
}

/**
 * Create a split inbox with specified config
 *
 * @public
 * @param {number} idx - Order for the split inbox
 * @param {string} name - Name for it
 * @param {SplitInboxQuery} query - Search criteria
 * @returns {ThunkAction}
 */
export function createSplitInbox({
  idx,
  name,
  query,
}: SplitInboxCreateBody): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        createSplitInboxActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(createSplitInboxActions.request())
      const res = await client.splitInboxes.create(
        { idx, query, name: trim(name) },
        { auth }
      )
      const returns = {
        id: res.result.id,
        idx,
        query,
        name: res.result.name,
        label: res.result.label,
        filter: res.result.filter,
      }
      batch(() => {
        dispatch(createSplitInboxActions.success(returns))
        dispatch(createLabelActions.success({ label: returns.label }))
        dispatch(createFilterActions.success(returns.filter))
      })
      return returns
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.splitInbox.error.create'),
          toastVariants.error
        )
      )
      dispatch(createSplitInboxActions.failure({ message: e.message }))
    }
  }
}

export const updateSplitInboxActions: {
  request: ActionCreator<SplitInboxUpdateRequest>,
  success: ActionCreator<SplitInboxUpdateSuccess>,
  failure: ActionCreator<SplitInboxUpdateFailure>,
} = {
  request: createAction('SPLIT_INBOX_UPDATE_REQUEST'),
  success: createAction('SPLIT_INBOX_UPDATE_SUCCESS'),
  failure: createAction('SPLIT_INBOX_UPDATE_FAILURE'),
}

/**
 * Update the split inbox with specified config
 *
 * @public
 * @param {string} id - Unique ID for the split inbox
 * @param {number} idx - Order number for it
 * @param {string} name - Name for it
 * @param {SplitInboxQuery} query - Search criteria
 * @returns {ThunkAction}
 */
export function updateSplitInbox(
  id: string,
  { idx, name, query }: SplitInboxCreateBody
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        updateSplitInboxActions.failure({ message: 'User not logged in' })
      )
      return
    }

    const prevSplitInbox = getSplitInboxById(id)(getState())
    try {
      dispatch(updateSplitInboxActions.request())
      const res = await client.splitInboxes.update(
        id,
        { idx, name: trim(name), query },
        { auth }
      )
      const returns = {
        id,
        idx,
        query,
        name: res.result.name,
        label: res.result.label,
        filter: res.result.filter,
      }

      batch(() => {
        dispatch(updateSplitInboxActions.success(returns))
        // Remove the previous binding label
        if (returns.label.id.toString() !== prevSplitInbox.labelId.toString()) {
          dispatch(deleteLabelActions.success({ id: prevSplitInbox.labelId }))
          dispatch(createLabelActions.success({ label: returns.label }))
        }
        // Remove the prevois binding filter
        if (
          returns.filter.id.toString() !== prevSplitInbox.filterId.toString()
        ) {
          dispatch(deleteFilterActions.success(prevSplitInbox.filterId))
          dispatch(createFilterActions.success(returns.filter))
        }
      })
      return returns
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.splitInbox.error.update'),
          toastVariants.error
        )
      )
      dispatch(updateSplitInboxActions.failure({ message: e.message }))
    }
  }
}

export const reorderSplitInboxActions: {
  request: ActionCreator<SplitInboxUpdateOrderRequest>,
  success: ActionCreator<SplitInboxUpdateOrderSuccess>,
  failure: ActionCreator<SplitInboxUpdateOrderFailure>,
} = {
  request: createAction('SPLIT_INBOX_UPDATE_ORDER_REQUEST'),
  success: createAction('SPLIT_INBOX_UPDATE_ORDER_SUCCESS'),
  failure: createAction('SPLIT_INBOX_UPDATE_ORDER_FAILURE'),
}

/**
 * Update the order for each split inbox
 *
 * @public
 * @param {Array<{ id: string, idx: number}>} newOrders - New order number for each split inbox
 * @returns {ThunkAction}
 */
export function reorderSplitInbox(
  newOrders: Array<{ id: string, idx: number }>
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        reorderSplitInboxActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(reorderSplitInboxActions.request())
      await client.splitInboxes.updateOrder(newOrders, { auth })
      dispatch(reorderSplitInboxActions.success(newOrders))
    } catch (e) {
      dispatch(reorderSplitInboxActions.failure({ message: e.message }))
    }
  }
}

export const deleteSplitInboxActions: {
  request: ActionCreator<SplitInboxDeleteRequest>,
  success: ActionCreator<SplitInboxDeleteSuccess>,
  failure: ActionCreator<SplitInboxDeleteFailure>,
} = {
  request: createAction('SPLIT_INBOX_DELETE_REQUEST'),
  success: createAction('SPLIT_INBOX_DELETE_SUCCESS'),
  failure: createAction('SPLIT_INBOX_DELETE_FAILURE'),
}

/**
 * Delete a split inbox by its ID
 *
 * @public
 * @param {string} id - Unique ID for split inbox
 * @returns {ThunkAction}
 */
export function deleteSplitInbox(id: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        deleteSplitInboxActions.failure({ message: 'User not logged in' })
      )
      return
    }

    const splitInbox = getSplitInboxById(id)(getState())
    if (!isNil(splitInbox)) {
      try {
        dispatch(
          deleteSplitInboxActions.request({
            id,
            labelId: splitInbox.labelId,
            filterId: splitInbox.filterId,
          })
        )
        const res = await client.splitInboxes.remove(id, { auth })
        batch(() => {
          dispatch(
            deleteSplitInboxActions.success({
              id: res.result.id,
              labelId: splitInbox.labelId,
              filterId: splitInbox.filterId,
            })
          )
          // Remove the binding label and filter also
          dispatch(deleteFilterActions.success(splitInbox.filterId))
          dispatch(deleteLabelActions.success({ id: splitInbox.labelId }))
        })
      } catch (e) {
        dispatch(deleteSplitInboxActions.failure({ message: e.message }))
      }
    }
  }
}

export const toggleSplitViewAction: ActionCreator<ToggleSplitView> = createAction(
  'TOGGLE_SPLIT_VIEW'
)

export function toggleSplitView(
  labelId: string,
  view: SplitViewType
): ThunkAction {
  return async (dispatch, getState) => {
    const splitsByLabel = getSplitInboxByLabelId(getState())

    let id = ''
    const split = splitsByLabel[labelId]

    // Only other and splits can be toggled view
    if (split) {
      id = split.id
    } else if (labelId === labelNames.other) {
      id = labelNames.other
    }

    if (id !== '') {
      dispatch(toggleSplitViewAction({ id, view }))

      const auth = getAuth()(getState())

      if (auth !== null) {
        client.splitInboxes.updateView({ view, id }, { auth }).catch(e => {
          console.warn(
            `Failed to update split inbox view ID: [${id}] view: [${view}] error: [${e}]`
          )
        })
      }
    }
  }
}

export const initialListViews: ActionCreator<InitialListViews> = createAction(
  'INITIAL_LIST_VIEWS'
)
