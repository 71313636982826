// @flow
import get from 'lodash/get'
import head from 'lodash/head'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { nudgeTypes } from '@edison/webmail-core/utils/constants'

import { getLoadingStatus } from 'core/loading/selectors'

import type { State, Selector } from 'types/state'
import type { EmailNudge } from '@edison/webmail-core/types/email-nudges'

export const getEmailNudgesState = (state: State) => state.emailNudges

export const getEmailNudgesById: Selector<{
  [threadId: string]: EmailNudge,
}> = createSelector(getEmailNudgesState, state => state)

export const getEmailNudges: Selector<
  $ReadOnlyArray<EmailNudge>
> = createSelector(getEmailNudgesState, state => {
  return values(state)
    .filter(each => each.suggestionType !== nudgeTypes.NONE)
    .map(each => {
      let sender = { name: '', email: each.from.email }

      if (each.suggestionType === nudgeTypes.FOLLOWUP) {
        if (each.to.length === 1) {
          sender = {
            name: '',
            email: get(each, 'to.[0].email'),
          }
        } else {
          const names = each.to
            .filter(item => !!item.email)
            .map(({ name, email }) => ({
              name: head(name.trim().split(' ')) || head(email.split('@')),
              email,
            }))
          sender = {
            name:
              names.length > 2
                ? `${names
                    .slice(0, 2)
                    .map(item => item.name)
                    .join(', ')} & ${names.length - 2} others`
                : names.map(item => item.name).join(', '),
            email: names.reduce((prev, curr) => curr.email, ''),
          }
        }
      }
      return {
        ...each,
        sender,
      }
    })
    .sort((a, b) => b.date - a.date)
})

export const hasEmailNudge: Selector<boolean> = createSelector(
  getEmailNudges,
  nudges => {
    return !isEmpty(nudges)
  }
)

export const isNudgeListLoading: Selector<boolean> = getLoadingStatus(
  'FETCH_EMAIL_NUDGE'
)

export const getUpdateNudgeLoading: Selector<boolean> = getLoadingStatus(
  'MESSAGE_NUDGE_UPDATE'
)
