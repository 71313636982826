// @flow
import isNil from 'lodash/isNil'
import * as client from '@edison/webmail-core/api/email-nudges'
import { createAction } from 'utils/redux'

import { getAuth } from 'core/auth/selectors'
import { getEmailNudgesById } from './selectors'

import type {
  FetchEmailNudgeRequest,
  FetchEmailNudgeSuccess,
  FetchEmailNudgeFailure,
  MessageNudgeUpdateRequest,
  MessageNudgeUpdateSuccess,
  MessageNudgeUpdateFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchEmailNudgesActions: {
  request: ActionCreator<FetchEmailNudgeRequest>,
  success: ActionCreator<FetchEmailNudgeSuccess>,
  failure: ActionCreator<FetchEmailNudgeFailure>,
} = {
  request: createAction('FETCH_EMAIL_NUDGE_REQUEST'),
  success: createAction('FETCH_EMAIL_NUDGE_SUCCESS'),
  failure: createAction('FETCH_EMAIL_NUDGE_FAILURE'),
}

export function fetchEmailNudges(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchEmailNudgesActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(fetchEmailNudgesActions.request())
      const res = await client.list({ auth })
      dispatch(fetchEmailNudgesActions.success(res.result))
    } catch (e) {
      dispatch(fetchEmailNudgesActions.failure({ message: e.message }))
    }
  }
}

export const messageNudgeUpdateActions: {
  request: ActionCreator<MessageNudgeUpdateRequest>,
  success: ActionCreator<MessageNudgeUpdateSuccess>,
  failure: ActionCreator<MessageNudgeUpdateFailure>,
} = {
  request: createAction('MESSAGE_NUDGE_UPDATE_REQUEST'),
  success: createAction('MESSAGE_NUDGE_UPDATE_SUCCESS'),
  failure: createAction('MESSAGE_NUDGE_UPDATE_FAILURE'),
}

export function updateMessageNudge(
  status: number,
  {
    threadId,
  }: {
    threadId: string,
  }
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)

    if (auth === null) {
      dispatch(
        messageNudgeUpdateActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      const emailNudges = getEmailNudgesById(state)
      const nudge = emailNudges[threadId]
      if (isNil(nudge)) return

      dispatch(messageNudgeUpdateActions.request())
      await client.batchUpdate({ messageIds: [nudge.id], status }, { auth })
      dispatch(
        messageNudgeUpdateActions.success({
          threadId,
          status,
        })
      )
    } catch (e) {
      dispatch(messageNudgeUpdateActions.failure({ message: e.message }))
    }
  }
}
