// @flow
import React from 'react'

import { AccountRemovalConfirmation } from '@edison/webmail-ui/components/Retrofit'
import { useRetrofitAccount } from 'core/retrofit/hooks'

const AccountRemovalConfirmationModal = ({
  isOpen,
  toggle,
  ecUUID,
}: {
  isOpen: boolean,
  toggle: () => mixed,
  ecUUID: string,
}) => {
  const { account, remove, isRemoving } = useRetrofitAccount(ecUUID)

  if (!account) {
    return null
  }

  return (
    <AccountRemovalConfirmation
      isOpen={isOpen}
      toggle={toggle}
      emailAddress={account.emailAddress}
      isLoading={isRemoving}
      onRemove={() => remove().then(() => toggle())}
      onCancel={toggle}
    />
  )
}

export default AccountRemovalConfirmationModal
