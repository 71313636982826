// @flow
import get from 'lodash/get'
import last from 'lodash/last'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import toPairs from 'lodash/toPairs'
import findLast from 'lodash/findLast'
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'
import { labelNames } from 'utils/constants'

import type {
  MessagesState,
  MessageMetadata,
  ThreadMessagesState,
  ViewThreadsState,
  ExtraInfoState,
} from './types'
import type { State as MetadataState } from './reducers'
import type { State, Selector } from 'types/state'
import type { ThreadBatchUpdateRequest } from '@edison/webmail-core/api/threads'

export const getMetadataState = (state: State) => state.metadata

// Sub-reducer selectors
const _getMessagesState = createSelector(
  getMetadataState,
  (state: MetadataState) => state.messages
)
export function getMessagesState(): Selector<MessagesState> {
  return _getMessagesState
}

const _getThreadMessagesState = createSelector(
  getMetadataState,
  (state: MetadataState) => state.threadMessages
)

export function getThreadMessagesState(): Selector<ThreadMessagesState> {
  return _getThreadMessagesState
}

export function getViewThreadsState(): Selector<ViewThreadsState> {
  return createSelector(
    getMetadataState,
    (state: MetadataState) => state.viewThreads
  )
}

export function getExtraInfoState(): Selector<ExtraInfoState> {
  return createSelector(
    getMetadataState,
    (state: MetadataState) => state.extraInfo
  )
}

export function getLabelThreads(
  labelId: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getViewThreadsState(),
    getThreadMessagesState(),
    getMessagesState(),
    (
      viewThreadsState: ViewThreadsState,
      threadMessagesState: ThreadMessagesState,
      messagesState: MessagesState
    ) => {
      return sortThreads(
        get(viewThreadsState, labelId, []),
        threadMessagesState,
        messagesState
      )
    }
  )
}

export function getLabelFetchedThreads(
  labelId: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getViewThreadsState(),
    viewThreads => viewThreads[labelId] || []
  )
}

export function getLabelThreadsCount(labelId: string): Selector<number> {
  return createSelector(
    getLabelFetchedThreads(labelId),
    threadIds => threadIds.length
  )
}

// Thread Messages
const _getThreadMessagesMap: Selector<{
  [threadId: string]: $ReadOnlyArray<{
    id: string,
    date: number,
    threadId: string,
    labelIds: $ReadOnlyArray<string>,
  }>,
}> = createSelector(
  getMessagesState(),
  getThreadMessagesState(),
  (messageState: MessagesState, threadMessagesState: ThreadMessagesState) => {
    return mapValues(threadMessagesState, thread =>
      uniq(thread.messageIds)
        .map(messageId => messageState[messageId])
        .filter(Boolean)
    )
  }
)
export function getThreadMessagesMap() {
  return _getThreadMessagesMap
}
//get thread messageIds
export function getThreadMessageIds(
  threadId: string,
  isFilterPending = true
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getThreadMessagesState(),
    getMessagesState(),
    (threadMessagesState: ThreadMessagesState, messageState: MessagesState) =>
      threadMessagesState[threadId]
        ? sortBy(
            uniq(threadMessagesState[threadId].messageIds)
              .map(messageId => messageState[messageId])
              .filter(Boolean)
              .filter(item =>
                isFilterPending
                  ? !(item.labelIds || []).includes(labelNames.pending)
                  : true
              ),
            'date'
          ).map(item => item.id)
        : []
  )
}

export function getThreadsMessageIds(
  threadIds: $ReadOnlyArray<string>
): Selector<$ReadOnlyArray<$ReadOnlyArray<string>>> {
  return createSelector(
    getThreadMessagesState(),
    getMessagesState(),
    (threadMessagesState: ThreadMessagesState, messageState: MessagesState) => {
      return threadIds.map(threadId =>
        threadMessagesState[threadId]
          ? sortBy(
              uniq(threadMessagesState[threadId].messageIds)
                .map(messageId => messageState[messageId])
                .filter(Boolean)
                .filter(
                  item => !(item.labelIds || []).includes(labelNames.pending)
                ),
              'date'
            ).map(item => item.id)
          : []
      )
    }
  )
}

export function getThreadPendingMessageIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getThreadMessagesState(),
    getMessagesState(),
    (threadMessagesState, messageState) => {
      const thread = threadMessagesState[threadId]
      if (!thread) {
        return []
      } else {
        return sortBy(
          uniq(threadMessagesState[threadId].messageIds)
            .map(messageId => messageState[messageId])
            .filter(Boolean)
            .filter(item => (item.labelIds || []).includes(labelNames.pending)),
          'date'
        ).map(item => item.id)
      }
    }
  )
}

export function getThreadIdsLabelIdsMap(threadIds) {
  return createSelector(
    getThreadMessagesState(),
    getMessagesState(),
    (threadMessage: ThreadMessagesState, messageState: MessagesState) => {
      const results = {}
      threadIds
        .map(item => threadMessage[item])
        .filter(Boolean)
        .forEach(({ messageIds, id }) => {
          const labelSet = new Set(
            messageIds.flatMap(id => get(messageState, `${id}.labelIds`, []))
          )
          results[id] = Array.from(labelSet)
        })
      return results
    }
  )
}

export function getLatestThreadMessageId(
  threadIds: $ReadOnlyArray<string>
): Selector<$ReadOnlyArray<string>> {
  return createSelector(_getThreadMessagesMap, byId =>
    threadIds
      .map(id => {
        const sorted = sortBy(byId[id], 'date')

        let lastMsg =
          findLast(
            sorted,
            ({ labelIds }) => !labelIds.includes(labelNames.trash)
          ) || last(sorted)

        return get(lastMsg, 'id')
      })
      .filter(Boolean)
  )
}

export function getThreadNotDraftMessageIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return getThreadMessageIdsByLabels(threadId, { [labelNames.drafts]: false })
}

export function getThreadUnreadMessageIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return getThreadMessageIdsByLabels(threadId, { [labelNames.unread]: true })
}
/**
 * get thread message by labels options
 * @param {*} threadId
 * @param {*} labels { [labelId]: true/false } true mean include, false mean exclude.
 */
export function getThreadMessageIdsByLabels(
  threadId,
  labels = {},
  isFilterPending = true
): Selector<$ReadOnlyArray<string>> {
  const labelKeys = Object.keys(labels)
  return createSelector(
    getThreadMessageIds(threadId, isFilterPending),
    getMessagesState(),
    (messageIds: $ReadOnlyArray<string>, messageState: MessagesState) =>
      messageIds.filter(
        item =>
          messageState[item] &&
          labelKeys.every(
            labelId =>
              get(messageState[item], 'labelIds', []).includes(labelId) ===
              labels[labelId]
          )
      )
  )
}
export function getThreadDraftMessageIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return getThreadMessageIdsByLabels(threadId, { [labelNames.drafts]: true })
}

export function getThreadTrashMessageIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return getThreadMessageIdsByLabels(threadId, { [labelNames.trash]: true })
}

export function hasTrashMessage(threadId: string): Selector<boolean> {
  return createSelector(
    getThreadTrashMessageIds(threadId),
    trashMessages => trashMessages.length > 0
  )
}

const _getThreadLabelIdsMap = createSelector(
  getThreadMessagesState(),
  getMessagesState(),
  (threadMessage: ThreadMessagesState, messageState: MessagesState) => {
    return mapValues(threadMessage, ({ messageIds }) => {
      const labelSet = new Set(
        messageIds.flatMap(id => get(messageState, `${id}.labelIds`, []))
      )
      return Array.from(labelSet)
    })
  }
)

export function getThreadLabelIdsMap(): Selector<{
  [threadId: string]: $ReadOnlyArray<string>,
}> {
  return _getThreadLabelIdsMap
}

export function getThreadLabelIds(
  threadId: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getThreadMessageIds(threadId),
    getMessagesState(),
    (messageIds: $ReadOnlyArray<string>, messagesState: MessagesState) => {
      return uniq(
        messageIds.flatMap(item =>
          messagesState[item] ? messagesState[item].labelIds : []
        )
      )
    }
  )
}

export function getThreadsLabelIds(
  threadIds: $ReadOnlyArray<string>
): Selector<$ReadOnlyArray<string>> {
  return createSelector(
    getThreadsMessageIds(threadIds),
    getMessagesState(),
    (
      threadMessageIds: $ReadOnlyArray<$ReadOnlyArray<string>>,
      messagesState: MessagesState
    ) => {
      return threadMessageIds.map(messageIds =>
        uniq(
          messageIds.flatMap(messageId =>
            messagesState[messageId] ? messagesState[messageId].labelIds : []
          )
        )
      )
    }
  )
}

export function getMessageLabelIds(
  messageId: string
): Selector<$ReadOnlyArray<string>> {
  return createSelector(getMessagesState(), messagesState =>
    messagesState[messageId] ? messagesState[messageId].labelIds : []
  )
}

export function getMessageThreadId(messageId: string): Selector<?string> {
  return createSelector(getMessagesState(), messagesState =>
    messagesState[messageId] ? messagesState[messageId].threadId : null
  )
}

export function getThreadMessages(
  threadId: string
): Selector<$ReadOnlyArray<MessageMetadata>> {
  return createSelector(
    getThreadMessagesMap(),
    (threadMessagesMap: {
      [threadId: string]: $ReadOnlyArray<MessageMetadata>,
    }) => {
      return threadMessagesMap[threadId] || []
    }
  )
}

export function getLastHistoryId(): Selector<{
  historyId: ?number,
  newMessageHistoryId: ?number,
}> {
  return createSelector(
    getExtraInfoState(),
    (extraInfoState: ExtraInfoState) => ({
      historyId: extraInfoState.historyId,
      newMessageHistoryId: extraInfoState.newMessageHistoryId,
    })
  )
}

export function sortThreads(
  threads: Array<string>,
  threadMessagesState: ThreadMessagesState,
  messagesState: MessagesState
): Array<string> {
  const threadsDate = {}
  const threadSet = new Set(threads)

  threadSet.forEach(thread => {
    const threadMessages = threadMessagesState[thread]
    if (isNil(threadMessages)) return
    else {
      threadsDate[thread] = Math.max(
        ...threadMessages.messageIds
          .map(id => messagesState[id])
          .filter(Boolean)
          .map(message => message.date)
      )
    }
  })

  if (isEmpty(threadsDate)) return threads
  else {
    return Array.from(threadSet).sort((a, b) => {
      const { [a]: aDate, [b]: bDate } = threadsDate
      const [isAInvalid, isBInvalid] = [isNil(aDate), isNil(bDate)]

      if (!isAInvalid && !isBInvalid && aDate !== bDate) {
        // Do the comparsion only when there're both valid date
        return bDate - aDate
      } else if (isAInvalid && !isBInvalid) {
        // Move B ahead when A is invalid
        return 1
      } else if (!isAInvalid && isBInvalid) {
        // Move A ahead when B is invalid
        return -1
      } else return 0
    })
  }
}

export const getBatchUpdateThreadParameters: Selector<ThreadBatchUpdateRequest> = createSelector(
  getExtraInfoState(),
  (state: ExtraInfoState) => {
    const { changed } = state
    let [removeThreads, addLabel, delLabel] = [[], {}, {}]

    const changedThreads = changed
      .filter(({ type, updateAsync }) => type === 'thread' && updateAsync)
      .flatMap(({ ids, add, remove }) =>
        ids.map(id => ({ threadId: id, add, remove }))
      )

    for (let { threadId, add, remove } of changedThreads) {
      for (let label of add) {
        if (label in delLabel && delLabel[label].has(threadId)) {
          delLabel[label].delete(threadId)
          continue
        }

        if (!(label in addLabel)) {
          addLabel[label] = new Set()
        }
        addLabel[label].add(threadId)
      }

      for (let label of remove) {
        if (label in addLabel && addLabel[label].has(threadId)) {
          addLabel[label].delete(threadId)
          continue
        }

        if (!(label in delLabel)) {
          delLabel[label] = new Set()
        }
        delLabel[label].add(threadId)
      }
    }
    return {
      remove: { threadIds: removeThreads },
      addLabel: toPairs(addLabel)
        .filter(([, threadIds]) => threadIds.size > 0)
        .map(([label, threadIds]) => ({
          label,
          threadIds: Array.from(threadIds),
        })),
      delLabel: toPairs(delLabel)
        .filter(([, threadIds]) => threadIds.size > 0)
        .map(([label, threadIds]) => ({
          label,
          threadIds: Array.from(threadIds),
        })),
    }
  }
)

export const getChangedMessages: Selector<{
  [messageId: string]: number,
}> = createSelector(
  getExtraInfoState(),
  (state: ExtraInfoState) => state.changedMessages
)

export const isThreadBatchUpdateLoading = getLoadingStatus(
  'THREAD_BATCH_UPDATE'
)
export const isMessageBatchUpdateLoading = getLoadingStatus(
  'MSSAGE_BATCH_UPDATE'
)
export const isThreadsMarkAllLoading = getLoadingStatus('THREAD_MARK_ALL')
export const isThreadsMarkAllDeletedLoading = getLoadingStatus(
  'THREAD_MARK_ALL_DELETED'
)
