// @flow
import * as _parsers from 'edison-sift-parsers'
import { fetchVendor } from '@edison/webmail-core/api/sifts'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import toNumber from 'lodash/toNumber'

import { priorityGet } from 'utils'

import type { Auth } from '@edison/webmail-core/types'

type SiftParser<T> = ({ domain: string, payload: Object }) => T

export const bill: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.billParser(payload)
export const rentalcar: SiftParser<{}> = ({ domain, payload }) => {
  const res = _parsers.rentalParser(payload)
  res.provider = get(payload, 'payload.provider.name')

  return res
}
export const cruise: SiftParser<{}> = ({ domain, payload }) => {
  let res = _parsers.cruiseParser(payload)

  // Missing from the parser results
  res.vendorName = get(payload, 'payload.broker.name')
  res.destination = get(payload, 'payload.arrivalLocation.name')

  return res
}
export const entertainment: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.restaurantParser(payload)
export const restaurant: SiftParser<{}> = ({ domain, payload }) => {
  let res = _parsers.restaurantParser(payload)
  res.reservationCode = get(payload, 'payload.reservationId')
  res.phoneNumber = get(payload, 'payload.reservationFor.telephone')

  return res
}
export const event: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.eventParser(payload)
export const flight: SiftParser<{
  departureCity: string,
  departureCode: string,
  arrivalCity: string,
  arrivalCode: string,
}> = ({ domain, payload }) => {
  let res = _parsers.flightParser(payload)

  res.departureCity = priorityGet(payload, [
    'payload.reservationFor[0].departureAirport[x-cityName]',
    'payload.reservationFor[0].departureAirport[x-rawName]',
  ])

  res.departureCode = get(
    payload,
    'payload.reservationFor[0].departureAirport.iataCode'
  )

  res.arrivalCity = priorityGet(payload, [
    'payload.reservationFor[0].arrivalAirport[x-cityName]',
    'payload.reservationFor[0].arrivalAirport[x-rawName]',
  ])

  res.arrivalCode = get(
    payload,
    'payload.reservationFor[0].arrivalAirport.iataCode'
  )
  res.flightNumber = get(payload, 'payload.reservationFor[0].flightNumber')

  return res
}
export const hotel: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.hotelParser(payload)
export const packageTracking: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.packageParser(payload)
export const receipt: SiftParser<{}> = ({ domain, payload }) => {
  const currency = get(payload, 'payload.x-priceCurrency', '$')
  const hasCurrency = price => {
    const first = price.charAt(0)
    const last = price.charAt(price.length - 1)

    return isNaN(toNumber(first)) || isNaN(toNumber(last))
  }
  const formatPrice = price => {
    const _price = !!price ? String(price) : ''
    if (!_price) {
      // Empty price here
      return _price
    }

    return hasCurrency(_price) ? _price : `${currency}${_price}`
  }

  let res = _parsers.purchaseParser(payload)
  res.items = get(payload, 'payload.acceptedOffer', [])
    .map(offer => ({
      name: get(offer, 'itemOffered.name', 'Unknown item'),
      price: formatPrice(get(offer, 'price')),
      image: get(offer, 'itemOffered.image'),
    }))
    .filter(({ name, price }) => !!name)
    .map(({ price, ...rest }) => ({ price: formatPrice(price), ...rest }))

  res.price = formatPrice(res.price)
  res.total = formatPrice(res.total)
  res.tax = formatPrice(res.tax)

  return res
}
export const train: SiftParser<{}> = ({ domain, payload }) => {
  let res = _parsers.trainParser(payload)
  res.departureCity = get(
    payload,
    'payload.reservationFor[0].departureStation.name'
  )
  res.startTime = get(payload, 'payload.reservationFor[0].departureTime')
  res.arrivalCity = get(
    payload,
    'payload.reservationFor[0].arrivalStation.name'
  )
  res.endTime = get(payload, 'payload.reservationFor[0].arrivalTime')

  return res
}
export const shipment: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.packageParser(payload)
export const purchase: SiftParser<{}> = receipt
export const boardingpass: SiftParser<{}> = ({ domain, payload }) =>
  _parsers.boardingPassParser(payload)

const parsers = {
  bill,
  rentalcar,
  cruise,
  entertainment,
  restaurant,
  event,
  flight,
  hotel,
  packageTracking,
  receipt,
  train,
  shipment,
  purchase,
  // Skipped domains
  //  boardingpass,
}

/**
 * Return the sift parsed message
 *
 * @public
 * @param {Array<Object>} sift - Sift object to parse
 * @returns {Array<Object>}
 */
export function siftsParser(sifts: Array<Object>): Array<Object> {
  if (!Array.isArray(sifts)) {
    return []
  }

  const parsed = sifts
    .map(({ domain, payload }) => {
      try {
        const parser = parsers[domain]
        return {
          domain,
          payload: parser({ domain, payload }),
        }
      } catch (e) {
        console.groupCollapsed(domain, 'parsing error')
        console.log('Payload', payload)
        console.warn(e)
        console.groupEnd()
        return null
      }
    })
    // Skip the parsing error case
    .filter(Boolean)

  return uniqBy(parsed, sift => get(sift, 'payload.uniqueId'))
}

/**
 * Return vendor detail by specified vendor ID
 *
 * @public
 * @param {number} vendorId - Vendor ID
 * @param {Auth} auth - User valided authentication
 * @returns {Object}
 */
export async function getVendorImage(
  vendorId: number,
  auth: Auth
): Promise<{ vendorName: string, logoUrl: string }> {
  const res = await fetchVendor(vendorId, { auth })

  return get(res, 'result.logoUrl', '')
}
