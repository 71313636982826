// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'

import { INBOX_ZERO_IMG_PATH, INBOX_ZERO_DEFAULT } from 'utils/constants'
import { getLoadingStatus } from 'core/loading/selectors'

import type { State, Selector } from 'types/state'
import type { State as ExternalState } from './reducers'

export const getExternalState = (state: State) => state.external

export function getInboxZeroImage(): Selector<string> {
  return createSelector(getExternalState, (state: ExternalState) =>
    get(state.inboxZero.image, INBOX_ZERO_IMG_PATH, INBOX_ZERO_DEFAULT)
  )
}

// Returns the images url by viewports
export const getUnsplashImages: Selector<{
  viewports: $ReadOnlyArray<{ width: number, src: string }>,
  src: string,
}> = createSelector(getExternalState, (state: ExternalState) => {
  const full = get(state.inboxZero.image, 'urls.full', '')
  const regular = get(
    state.inboxZero.image,
    INBOX_ZERO_IMG_PATH,
    INBOX_ZERO_DEFAULT
  )

  if (!full) return { viewports: [], src: regular }

  const url = new URL(full)
  return {
    viewports: [
      {
        width: 2400,
        src: (url.searchParams.delete('w'), url.toString()),
      },
      {
        width: 1080,
        src: (url.searchParams.set('w', '2400'), url.toString()),
      },
    ],
    src: regular,
  }
})

export function getInboxZeroImageAuthor(): Selector<{|
  image: string,
  name: string,
  location: string,
  link: string,
|}> {
  return createSelector(getExternalState, (state: ExternalState) => {
    return {
      image: get(
        state.inboxZero.image,
        'user.profile_image.small',
        'https://images.unsplash.com/profile-1559260378432-b28d0978997e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32'
      ),
      name: get(state.inboxZero.image, 'user.name', 'Nadine Shaabana'),
      location: get(
        state.inboxZero.image,
        'location.name',
        'Gray Whale Cove State Beach, Half Moon Bay, United States'
      ),
      link: get(state.inboxZero.image, 'user.links.html', ''),
    }
  })
}

export function getInboxZeroQuote(): Selector<string> {
  return createSelector(getExternalState, (state: ExternalState) =>
    get(
      state.inboxZero.quote,
      'contents.quotes[0].quote',
      'Action may not always bring happiness; but there is no happiness without action'
    )
  )
}

export function getInboxZeroQuoteAuthor(): Selector<string> {
  return createSelector(getExternalState, (state: ExternalState) =>
    get(state.inboxZero.quote, 'contents.quotes[0].author', 'Benjamin Disraeli')
  )
}

export const getExternalAssetsLoading: Selector<boolean> = getLoadingStatus(
  'EXTERNAL_ASSETS',
  true
)
