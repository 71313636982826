// @flow
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useCurrentBreak, useRecentMessages } from 'core/inbox-break/hooks'
import { toggleFullscreenAlertFlag } from 'core/inbox-break/actions'

import {
  FullscreenLayout,
  ManualBreakSetup,
  ManualBreakSuccess,
} from '@edison/webmail-ui/components/InboxBreak'

import type { Dispatch } from 'types/redux'

type Props = {
  isOpen: boolean,
  success: boolean,
  toggle: () => void,
}

const View = ({ isOpen, success, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const [isSuccess, setSuccess] = useState(!!success)

  const currBreak = useCurrentBreak()
  const recentMessages = useRecentMessages()

  useEffect(() => {
    // Refresh the recent messages on open
    if (isOpen) {
      recentMessages.onFetch()
    }
  }, [])

  function handleOnSubmit({ enableAutoReply, replyMessage }) {
    currBreak
      .onToggle({
        enable: true,
        replyMessage,
        enableAutoReply,
      })
      .then(res => setSuccess(res))
  }

  function handleOnEndBreak() {
    currBreak
      .onToggle({
        enable: false,
        replyMessage: currBreak.replyMessage,
        enableAutoReply: currBreak.enableAutoReply,
      })
      .then(() => toggle())
  }

  function handleOnClose() {
    if (isSuccess) {
      dispatch(toggleFullscreenAlertFlag())
    }
    toggle()
  }

  return (
    <FullscreenLayout open={isOpen} onClose={handleOnClose}>
      {isSuccess ? (
        <ManualBreakSuccess
          onClose={handleOnClose}
          endAt={currBreak.breakUntil}
          onEndBreak={handleOnEndBreak}
          isLoading={currBreak.isExecuting}
          timezone={currBreak.breakTimezone}
        />
      ) : (
        <ManualBreakSetup
          onClose={handleOnClose}
          onSubmit={handleOnSubmit}
          isLoading={currBreak.isExecuting}
          recentMessages={recentMessages.messages}
          onRemoveRecentMessage={recentMessages.onRemove}
        />
      )}
    </FullscreenLayout>
  )
}

View.displayName = 'ManualBreakSetupModal'

export default View
