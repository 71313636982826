// @flow
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'
import { labelNames, labelRouteNames } from 'utils/constants'
import { splitViewTypes } from '@edison/webmail-core/utils/constants'

import { selectLoadingState, getLoadingStatus } from 'core/loading/selectors'

import type { State as SplitInboxState, EntityState } from './reducers'
import type { State as LoadingState } from 'core/loading/reducers'
import type { State, Selector } from 'types/state'
import type {
  SplitInbox,
  SplitMetas,
} from '@edison/webmail-core/types/split-inboxes'

const selectState = (state: State) => state.splitInboxes

export const getSplitInboxState: Selector<EntityState> = createSelector(
  selectState,
  (state: SplitInboxState) => state.entities
)

export function getSplitInboxes(): Selector<$ReadOnlyArray<SplitInbox>> {
  return createSelector(getSplitInboxState, (state: EntityState) =>
    values(state).sort((a, b) => (a.idx < b.idx ? -1 : 1))
  )
}

export function getSplitInboxLabels(): Selector<$ReadOnlyArray<string>> {
  return createSelector(getSplitInboxState, (state: EntityState) =>
    values(state).map(inbox => inbox.labelId.toString())
  )
}

export function getSplitInboxFilters(): Selector<$ReadOnlyArray<string>> {
  return createSelector(getSplitInboxState, (state: EntityState) =>
    values(state).map(inbox => inbox.filterId.toString())
  )
}

export function getSplitInboxNames(): Selector<$ReadOnlyArray<string>> {
  return createSelector(getSplitInboxState, (state: EntityState) =>
    values(state).map(inbox => inbox.name)
  )
}

export function getSplitInboxById(id: string): Selector<SplitInbox> {
  return createSelector(getSplitInboxState, (state: EntityState) => state[id])
}

export function isSplitInboxesLoaded(): Selector<boolean> {
  const id = 'SPLIT_INBOX_LIST'
  return createSelector(
    selectLoadingState,
    getLoadingStatus(id),
    (loadingState: LoadingState, isLoading: boolean) =>
      id in loadingState && !isLoading
  )
}

export const getSplitInboxByLabelId: Selector<{
  [labelId: string]: SplitInbox & { view: string },
}> = createSelector(getSplitInboxState, (state: EntityState) =>
  values(state).reduce<Object>(
    (prev, curr) => ({
      ...prev,
      [curr.labelId]: curr,
    }),
    {}
  )
)

const _getSplitInboxNameByLabelId: Selector<{
  [labelId: string]: string,
}> = createSelector(getSplitInboxByLabelId, byLabelId =>
  mapValues(byLabelId, item => item.name)
)

export function getSplitInboxNameByLabelId() {
  return _getSplitInboxNameByLabelId
}

export const getSplitMetaState: Selector<SplitMetas> = createSelector(
  selectState,
  (state: SplitInboxState) => state.meta
)

export const getPreviewableSplits: Selector<
  $ReadOnlyArray<string>
> = createSelector(
  getSplitMetaState,
  getSplitInboxes(),
  (splitMetas: SplitMetas, splits) => {
    let isPreviewable = id => {
      return id in splitMetas && splitMetas[id].view === splitViewTypes.PREVIEW
    }

    let previewableSplits = splits
      .filter(({ id }) => isPreviewable(id))
      .map(({ labelId }) => labelId)

    if (isPreviewable(labelNames.other)) {
      const labelOther = labelRouteNames[labelNames.other]
      previewableSplits.push(labelOther)
    }

    return previewableSplits
  }
)
