// @flow
import React from 'react'

import { LabelPopover } from '@edison/webmail-ui/components/Labels/LabelPicker'
import { useLabels, useLabelValidator } from 'core/labels/hooks'

import type { ElementConfig } from 'react'

type Props = {
  isOpen: boolean,
  anchorEl: any,
  // Current assigned labels
  labelIds: $ReadOnlyArray<string>,
  onChange: $PropertyType<ElementConfig<typeof LabelPopover>, 'onChange'>,
  onNewLabel: $PropertyType<ElementConfig<typeof LabelPopover>, 'onNewLabel'>,
  onClose: () => void,
}

const View = ({ labelIds = [], onNewLabel, ...rest }: Props) => {
  const { custom } = useLabels()
  const labelValidator = useLabelValidator()

  return (
    <LabelPopover
      validate={values => {
        let errors = {}
        const nameError = labelValidator.name(values.name)
        if (!!nameError) {
          errors.name = nameError
        }
        return errors
      }}
      labels={custom.map(each => ({
        ...each,
        checked: labelIds.includes(each.id),
      }))}
      onNewLabel={onNewLabel}
      {...rest}
    />
  )
}

View.displayName = 'LabelPicker'

export default View
