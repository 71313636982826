import React from 'react'
import Compose from 'screens/Compose'

export default ({ draftId, onSelectRecipient }) => {
  return (
    <div className="border-t border-b" id={`Compose-${draftId}`}>
      <Compose
        draftId={draftId}
        onSelectRecipient={onSelectRecipient}
        style={{ minHeight: 280 }}
      />
    </div>
  )
}
