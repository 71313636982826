// @flow
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { SUBACCOUNT_STATUS } from '@edison/webmail-ui/utils/constants'
import { getAuth, getAuthUser } from 'core/auth/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { getActivedAccounts } from 'core/retrofit/selectors'
import { checkCurrentUser, isStringEqual } from 'utils'
import { BASE_DOMAIN } from 'utils/constants'

import type {
  Domain,
  DnsInfo,
  ReadOnlyDnsInfo,
  Subaccount,
} from '@edison/webmail-core/types/custom-domains'
import type { State, Selector } from 'types/state'

export const getCustomDomainsState = (state: State) => state.customDomains

export const getCurrentDomain: Selector<?Domain> = createSelector(
  getCustomDomainsState,
  state => get(state, 'current', null)
)

export const isDomainAdmin: Selector<boolean> = createSelector(
  getCustomDomainsState,
  state => get(state, 'isAdmin', false)
)

export const getDnsSetupInfo: Selector<
  $ReadOnlyArray<DnsInfo>
> = createSelector(getCustomDomainsState, state =>
  get(state, 'setup.dnsInfo', [])
)

export const getCurrentDomainLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_DETAIL',
  true
)

export const getDnsSetupInfoLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_DNS_SETUP_INFO',
  true
)

export const getVerificationLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_VERIFY'
)

export const getPurchaseLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_PURCHASE'
)

export const getConnectLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_CONNECT'
)

export const getDomainSearchLoading: Selector<boolean> = createSelector(
  getCustomDomainsState,
  state => get(state, 'search.requestId') !== null
)

export const getSubaccountsLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_LIST',
  true
)

export const fetchSubaccountLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_DETAIL',
  true
)

export const getCreateSubaccountLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_CREATE'
)

export const getSubaccountInvitationLinkLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_INVITATION_LINK'
)

export const addOnmailAliasLoading: Selector<boolean> = getLoadingStatus(
  'ADD_ONMAIL_ALIAS'
)

export const getDomainSearchQuery: Selector<string> = createSelector(
  getCustomDomainsState,
  state => get(state, 'search.username', '').trim()
)

export const getDomainSearchResults: Selector<{
  domains: $ReadOnlyArray<{
    domain: string,
    available: boolean,
    price: string,
    currency: string,
  }>,
  onmailUsernames: { [key: string]: boolean },
}> = createSelector(getCustomDomainsState, state => {
  return {
    domains: get(state, 'search.domains', []),
    onmailUsernames: get(state, 'search.onmailUsernameAvailability', {}),
  }
})

export const getSubaccountsById: Selector<{
  [accountId: string]: Subaccount,
}> = createSelector(getCustomDomainsState, state => {
  return get(state, 'subaccounts', {})
})

export const getSubaccounts: Selector<
  $ReadOnlyArray<Subaccount>
> = createSelector(getCustomDomainsState, state => {
  const subaccounts = get(state, 'subaccounts', {})
  let [admin, pendings, actives, deleteds] = [[], [], [], []]
  for (let key in subaccounts) {
    const item = subaccounts[key]
    if (item.isAdmin) admin.push(item)
    else {
      switch (item.status) {
        case SUBACCOUNT_STATUS.PENDING:
          pendings.push(item)
          break
        case SUBACCOUNT_STATUS.ACTIVE:
          actives.push(item)
          break
        default:
          deleteds.push(item)
      }
    }
  }

  return [admin, pendings, actives, deleteds]
    .map(each => each.sort((a, b) => (a.email < b.email ? -1 : 1)))
    .flatMap(item => item)
})

export const isAdminConverted: Selector<boolean> = createSelector(
  getCustomDomainsState,
  state => {
    const subaccounts = get(state, 'subaccounts', {})

    for (let key in subaccounts) {
      if (get(subaccounts, `${key}.isAdmin`, false)) {
        return true
      }
    }
    return false
  }
)

export const getResetPasswordLinks: Selector<{
  [accountId: string]: string,
}> = createSelector(getCustomDomainsState, state => {
  return get(state, 'resetPasswordLinks', {})
})

export const getResetPasswordLinkLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_RESET_PASSWORD_LINK'
)

export const getDisconnectDomainLoading: Selector<boolean> = getLoadingStatus(
  'CUSTOM_DOMAIN_DISCONNECT'
)

export const getCurrentDomainAssets: Selector<
  $PropertyType<Domain, 'assets'>
> = createSelector(getCurrentDomain, current => {
  const { companyName, color, iconUrl, logoUrl } = get(current, 'assets', {})

  return {
    companyName,
    color,
    iconUrl,
    logoUrl,
  }
})

export const getOnmailDomain: Selector<string> = createSelector(
  () => BASE_DOMAIN
)

export const getCurrentWebDomain: Selector<string> = createSelector(
  () => window.location,
  getOnmailDomain,
  (location, onmailDomain) => {
    const domain = location.host
      .split('.')
      .slice(1)
      .join('.')

    // If current location.host is `localhost`
    // We treat it as onmail
    return !!domain ? domain : onmailDomain
  }
)

export const isInOnmail: Selector<boolean> = createSelector(
  getCurrentWebDomain,
  getOnmailDomain,
  (domain, onmailDomain) => {
    return domain === onmailDomain
  }
)

export const isDomainAlias: Selector<boolean> = createSelector(
  getAuthUser,
  getOnmailDomain,
  (username, onmailDomain) => {
    const [, domain] = username.split('@')

    return !isStringEqual(domain, onmailDomain)
  }
)

export const getCurrentDomainAssetsLoading: Selector<boolean> = createSelector(
  getLoadingStatus('FETCH_CUSTOM_DOMAIN_ASSETS', true),
  isInOnmail,
  (isLoading, inOnmail) => {
    if (inOnmail) return false
    else return isLoading
  }
)

export const isUserOwned: Selector<boolean> = createSelector(
  getCurrentDomain,
  domain => !!domain && domain.userOwned
)

export const shouldRedirect: Selector<boolean> = createSelector(
  getCurrentDomain,
  isInOnmail,
  (domain, isInOnmail) =>
    !!domain && domain.verified && domain.isReady && isInOnmail
)

// For hosted domain, we need some time to verify the domain. This controls
// when we will display the error banner for users.
export const isPendingVerification: Selector<boolean> = createSelector(
  getCustomDomainsState,
  getAuth(),
  getOnmailDomain,
  (state, auth, onmailDomain) => {
    if (isNil(state.current)) {
      return false
    }

    // Do not display if user is logged into an OnMail account
    if (auth && auth.user.endsWith(onmailDomain)) {
      return false
    }

    return !state.current.userOwned && !state.current.verified
  }
)

export const hasDomainSetupError: Selector<boolean> = createSelector(
  getCustomDomainsState,
  state => (state.current ? !state.current.verified : false)
)

export const getAliases: Selector<
  Array<string>
> = createSelector(getCustomDomainsState, state => get(state, 'aliases', []))

// Get current account and current account custom domains
export const getDomainAliasesSelector = createSelector(
  getAuthUser,
  getAliases,
  (authUser, customDomains) => {
    if (!authUser) return []
    return [...new Set([authUser, ...customDomains])]
  }
)

export const getAllAliasesSelector = createSelector(
  getAuthUser,
  getAliases,
  getActivedAccounts,
  (authUser, aliases, accounts) => {
    if (!authUser) return []
    return [
      ...new Set([
        authUser,
        ...aliases,
        ...accounts.map(({ emailAddress }) => emailAddress),
      ]),
    ]
  }
)
export function isCurrentUser(email: string): Selector<boolean> {
  return createSelector(getAllAliasesSelector, aliases => {
    return checkCurrentUser(aliases, email)
  })
}

export const hasOnmailAlias: Selector<boolean> = createSelector(
  getAuth(),
  getAliases,
  getOnmailDomain,
  (auth, aliases, onmail) =>
    [get(auth, 'user', ''), ...aliases].some(alias =>
      alias.toLowerCase().endsWith(onmail)
    )
)

export const isInvalidDomain: Selector<boolean> = createSelector(
  getCustomDomainsState,
  state => state.search.isInvalidDomain
)

export const getDnsRecords: Selector<
  $ReadOnlyArray<ReadOnlyDnsInfo>
> = createSelector(getCustomDomainsState, state => state.records.values)

// DNS records for updating
export const getDnsWriteRecords: Selector<
  $ReadOnlyArray<DnsInfo>
> = createSelector(getDnsRecords, records =>
  records
    .map(({ record }) => record)
    // Filter out default record set by GoDaddy
    .filter(record => !(record.type === 'A' && record.data === 'Parked'))
)

export const updateDnsRecordsErrors: Selector<?any> = createSelector(
  getCustomDomainsState,
  state => state.records.errors
)

export const getDnsRecordsLoading: Selector<boolean> = getLoadingStatus(
  'GET_DNS_RECORDS'
)

export const updateDnsRecordsLoading: Selector<boolean> = getLoadingStatus(
  'UPDATE_DNS_RECORDS'
)

export const deleteSubaccountLoading: Selector<boolean> = getLoadingStatus(
  'SUBACCOUNT_DELETE'
)
