// @flow
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import { useMemo } from 'react'
import { useSelector as _useSelector } from 'react-redux'

import type { EntityState, PaginationState, ActionMeta } from 'types/redux'

export function createAction<T: string, Payload>(
  type: T
): (
  Payload,
  ActionMeta | void
) => {| type: T, payload: Payload, meta: ActionMeta |} {
  function action(payload, meta = {}) {
    return { type, payload, meta }
  }

  // Inspiration from:
  // https://github.com/reduxjs/redux-starter-kit/blob/master/src/createAction.ts
  //
  // $FlowFixMe - Flow does not allow this, but this is valid Javascript.
  action.toString = () => type

  return action
}

export function createReducer<S, A>(
  initialState: S,
  handlers: { [string]: (S, any) => S }
): (state?: S, A) => S {
  return (state?: S = initialState, action: any) => {
    const reducer = handlers[action.type]
    if (isNil(reducer)) {
      return state
    }

    return reducer(state, action)
  }
}

export function createEntityState<S>(): EntityState<S> {
  return {
    entities: {},
    ids: [],
  }
}

export function updateEntities<T: { id: string }>(
  state: { [string]: T },
  entities: $ReadOnlyArray<T>
) {
  return {
    ...state,
    ...entities.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
  }
}

export function updateIds<T: { id: string }>(
  state: $ReadOnlyArray<string>,
  entities: $ReadOnlyArray<T>
) {
  return union<string>(state, entities.map(item => item.id))
}

export function updatePagination(
  pagination: PaginationState,
  ids: string[],
  { total, next }: { total: number, next: string }
): PaginationState {
  return {
    ids,
    next: !!next ? next : null,
    // To avoid the inaccurate estimate total
    total: !!next ? total : ids.length,
  }
}

/**
 * React hook that uses a memoized selector.
 *
 * @public
 * @param {Function} selectorFn
 * @param {Array<any>} keys
 * @returns {typeof useSelector}
 */
export function useSelector<T: Function>(
  selectorFn: T,
  keys?: Array<any> = []
): $Call<typeof _useSelector, T> {
  return _useSelector(useMemo(() => selectorFn, keys))
}
