// @flow
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from 'core/contacts/actions'
import { getContactsById } from 'core/contacts/selectors'
import { getFormattedContactById } from 'core/contacts/helpers'
import { useShowContactDetail } from 'hooks/useHandleDetailModal'
import Contacts, { BlockedContact } from '@edison/webmail-ui/screens/Contacts'
import EmptyList from '@edison/webmail-ui/screens/Settings/components/EmptyList'

import type { Dispatch } from 'types/redux'

type Props = {
  ids: $ReadOnlyArray<string>,
}

export default ({ ids }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const showContactDetail = useShowContactDetail()
  const contactsById = useSelector(getContactsById)
  function onUnblockDomain(email, id) {
    dispatch(actions.showUnblockDomainPrompt(email, id))
  }

  function onUnblockSender(email) {
    dispatch(actions.showUnblockSenderPrompt(email))
  }

  const renderItem = useCallback(
    ({ index, style, key }) => {
      const { id, email, name, avatar, isDomain } = getFormattedContactById(
        contactsById,
        ids[index]
      )
      return isDomain ? (
        <BlockedContact
          key={id}
          name={name}
          style={style}
          email={`all mail from ${email}`}
          avatar={avatar}
          onUnblock={() => onUnblockDomain(email, id)}
          onClick={() => showContactDetail(id)}
        />
      ) : (
        <BlockedContact
          key={id}
          name={name}
          style={style}
          email={email}
          avatar={avatar}
          onUnblock={() => onUnblockSender(email)}
          onClick={() => showContactDetail(id)}
        />
      )
    },
    [contactsById, ids, showContactDetail]
  )

  const count = ids.length

  if (count === 0) {
    return (
      <EmptyList
        image={t('empty-settings-contacts-blocked.png')}
        title={t('contacts.blocked.empty.title')}
        subtitle={t('contacts.blocked.empty.subtitle')}
      />
    )
  }

  return <Contacts count={count} rowRenderer={renderItem} />
}
