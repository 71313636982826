// @flow
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'
import max from 'lodash/max'
import { createSelector } from 'reselect'
import { getFeatureFlags } from 'core/premium/selectors'
import { isDomainAdmin, getCurrentDomain } from 'core/custom-domains/selectors'
import { getCurrentPlan, getPremiumPlans } from 'core/premium/selectors'
import {
  premiumPlanRanks,
  premiumPlanIds,
  upgradePlanPromptTypes,
} from 'utils/constants'
import { storageStatus } from '@edison/webmail-ui/utils/constants'
import type { Usage } from '@edison/webmail-core/types/usage'
import type { State, Selector } from 'types/state'
import type { State as UsageState } from './types'

export const selectUsageState: Selector<UsageState> = (state: State) =>
  state.usage

export const usageSelector: Selector<Usage> = createSelector(
  selectUsageState,
  state => state
)

export function hasUsageLoaded(): Selector<boolean> {
  return createSelector(selectUsageState, (usage: Usage) => !isEmpty(usage))
}

export function getUsageRate(): Selector<number> {
  return createSelector(
    getUsageSelector,
    getFeatureFlags,
    (usage: number, planFeature) => {
      const total = planFeature.totalStorageLimit
      if (!total) return 1
      return Math.min(usage / total, 1)
    }
  )
}

export const getUsageSelector: Selector<number> = createSelector(
  selectUsageState,
  (usage: Usage) => {
    const { messageUsed, largeAttachmentUsed, ecMessageUsed } = usage
    return messageUsed + largeAttachmentUsed + ecMessageUsed
  }
)

export const getRemainingSizeSelector: Selector<number> = createSelector(
  getUsageSelector,
  getFeatureFlags,
  (usage, planFeature) => {
    const total = planFeature.totalStorageLimit
    if (!total) return 0
    return total - usage
  }
)
export const getUpgradePlanNameSelector: Selector<?string> = createSelector(
  isDomainAdmin,
  getCurrentPlan,
  getPremiumPlans,
  (admin, currPlan, plans) => {
    if (!plans || !plans.length || !currPlan) return null
    if (admin) {
      const currPlanIds = premiumPlanIds[currPlan.interval]
      if (!currPlanIds) return null

      const allPlanIds = sortBy(
        Object.values(currPlanIds),
        (obj: string) => premiumPlanRanks[obj]
      )
      if (!allPlanIds.includes(currPlan.id)) return null
      const index = allPlanIds.indexOf(currPlan.id)
      if (index === allPlanIds.length - 1) return null
      const upgradePlanId = allPlanIds[index + 1]
      const upgradePlan = plans.find(item => item.id === upgradePlanId)
      if (!upgradePlan) return null

      return upgradePlan.name
    }
    return null
  }
)

export const getUpgradePromptTypeSelector: Selector<?string> = createSelector(
  isDomainAdmin,
  getCurrentPlan,
  getPremiumPlans,
  getFeatureFlags,
  getCurrentDomain,
  getUsageRate(),
  (admin, currentPlan, plans, featureFlags, currentDomain, usageRate) => {
    if (!plans || !plans.length || !currentPlan) return null

    if (currentPlan && currentPlan.unitPrice === '0') {
      return upgradePlanPromptTypes.upgradeToFreelance
    }

    if (
      featureFlags.allowCustomDomains &&
      !featureFlags.allowSubaccounts &&
      !currentDomain
    ) {
      return upgradePlanPromptTypes.getYourFreeDomain
    }

    if (admin && currentPlan.id < max(values(premiumPlanRanks))) {
      if (usageRate * 100 >= storageStatus.warning)
        return upgradePlanPromptTypes.upgrade
    }

    return null
  }
)
