// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import isNil from 'lodash/isNil'

import { getSplitInboxByLabelId } from 'core/split-inboxes/selectors'

import Dialog from '@material-ui/core/Dialog'
import DndConfirmLayout from '@edison/webmail-ui/components/DndConfirm'
import { Title } from '@edison/webmail-ui/components/DndConfirm/SplitInbox'

type Props = {
  targetLabelId: string,
  isOpen: boolean,
  toggle: () => void,
  prefill: {
    from: $ReadOnlyArray<string>,
  },
}
export default ({ isOpen, toggle, prefill, targetLabelId }: Props) => {
  const splitsByLabelId = useSelector(getSplitInboxByLabelId)

  const targetSplit = splitsByLabelId[targetLabelId]

  if (isNil(targetSplit)) return null

  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      <DndConfirmLayout
        onConfirm={toggle}
        title={<Title name={targetSplit.name} emails={prefill.from} />}
      />
    </Dialog>
  )
}
