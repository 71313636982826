// @flow
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import { AuthMissing } from './actions-common'
import { getColors } from '@edison/webmail-core/api/calendar'
import { colorsActionTypes } from './constants-colors'
import type { ThunkAction } from 'types/redux'
import type { RequestAPIActionGen } from './actions-common'
import type {
  FetchColorsRequestFailure,
  FetchColorsRequest,
  FetchColorsRequestSuccess,
} from './types-colors'

export const fetchColorsAction: RequestAPIActionGen<
  FetchColorsRequest,
  FetchColorsRequestSuccess,
  FetchColorsRequestFailure
> = {
  request: createAction(colorsActionTypes.FetchColorsRequest),
  success: createAction(colorsActionTypes.FetchColorsRequestSuccess),
  failure: createAction(colorsActionTypes.FetchColorsRequestFailure),
}

export function fetchColors(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(fetchColorsAction.failure({ message: AuthMissing }))
      return
    }
    try {
      dispatch(fetchColorsAction.request())
      const res = await getColors({ auth })
      dispatch(fetchColorsAction.success(res.result))
      // dispatch(
      //   fetchColorsAction.success({
      //     calendar: {
      //       abc: { background: 'fff', foreground: '000' },
      //     },
      //     event: {
      //       abc: { background: 'red', foreground: 'black' },
      //     },
      //   })
      // )
    } catch (e) {
      dispatch(fetchColorsAction.failure({ message: e.message }))
    }
  }
}
