import FromEmailPillItem, {
  ToEmailPill as ToEmailPillItem,
} from '@edison/webmail-ui/components/Pill/EmailPill'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import { getFilteredContacts } from 'core/contacts/selectors'
import { useContactsByFrequency } from 'core/contacts/hooks'
import React, { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

export function useGetRecipientAvatar() {
  const getRecipientAvatar = useCallback(
    ({ email, name }, className) => (
      <AuthEmailAvatar
        isSizeCls={true}
        className={className || '!w-8 !h-8'}
        email={email}
        name={name}
      />
    ),
    []
  )
  return getRecipientAvatar
}

export function FromEmailPill(props) {
  const { onSelect, condition, isClearAll } = props
  const ref = useRef()
  const contacts = useSelector(getFilteredContacts())
  const sortContacts = useContactsByFrequency(contacts)
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])
  const getRecipientAvatar = useGetRecipientAvatar()
  return (
    <FromEmailPillItem
      ref={ref}
      contacts={sortContacts}
      getRecipientAvatar={getRecipientAvatar}
      onSelect={contacts => {
        const nextCondition = {
          ...condition,
          from: contacts.map(item => item.email),
        }
        onSelect(nextCondition)
      }}
    />
  )
}

export function ToEmailPill(props) {
  const { onSelect, condition, isClearAll } = props
  const contacts = useSelector(getFilteredContacts())
  const getRecipientAvatar = useGetRecipientAvatar()
  const sortContacts = useContactsByFrequency(contacts)
  const ref = useRef()
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])
  return (
    <ToEmailPillItem
      ref={ref}
      contacts={sortContacts}
      getRecipientAvatar={getRecipientAvatar}
      onSelect={contacts => {
        const nextCondition = {
          ...condition,
          to: contacts.map(item => item.email),
        }
        onSelect(nextCondition)
      }}
    />
  )
}
