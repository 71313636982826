// @flow
import * as client from '@edison/webmail-core/api/calendar'
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import { AuthMissing } from './actions-common'
import { settingActionTypes } from './constants-settings'
import type { ThunkAction } from 'types/redux'
import type {
  SettingsUpdateRequest,
  SettingsUpdateRequestSuccess,
  SettingsUpdateRequestFailure,
  SettingsGetRequestSuccess,
  SettingsGetRequest,
  SettingsGetRequestFailure,
  SettingsListRequest,
  SettingsListRequestFailure,
  SettingsListRequestSuccess,
} from './types-settings'
import type {
  CalendarSettingsResource,
  CalendarSettingKeys,
} from '@edison/webmail-core/types/calendar'
import type { RequestAPIActionGen } from './actions-common'

export const fetchSettingsAction: RequestAPIActionGen<
  SettingsListRequest,
  SettingsListRequestSuccess,
  SettingsListRequestFailure
> = {
  request: createAction(settingActionTypes.SettingsListRequest),
  success: createAction(settingActionTypes.SettingsListRequestSuccess),
  failure: createAction(settingActionTypes.SettingsListRequestFailure),
}

export function fetchSettingsList(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(fetchSettingsAction.failure({ message: AuthMissing }))
      return
    }
    try {
      dispatch(fetchSettingsAction.request())
      const res = await client.listSettings({ auth, pagination: {} })
      dispatch(fetchSettingsAction.success(res.result))
    } catch (e) {
      dispatch(fetchSettingsAction.failure({ message: e.message }))
    }
  }
}

export const fetchSettingAction: RequestAPIActionGen<
  SettingsGetRequest,
  SettingsGetRequestSuccess,
  SettingsGetRequestFailure
> = {
  request: createAction(settingActionTypes.SettingsGetRequest),
  success: createAction(settingActionTypes.SettingsGetRequestSuccess),
  failure: createAction(settingActionTypes.SettingsGetRequestFailure),
}

export function fetchSetting(settingId: CalendarSettingKeys): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(fetchSettingAction.failure({ message: AuthMissing }))
      return
    }
    try {
      dispatch(fetchSettingAction.request())
      const res = await client.getSetting({ auth, settingId })
      dispatch(fetchSettingAction.success(res.result))
    } catch (e) {
      dispatch(fetchSettingAction.failure({ message: e.message }))
    }
  }
}

export const updateSettingsAction: RequestAPIActionGen<
  SettingsUpdateRequest,
  SettingsUpdateRequestSuccess,
  SettingsUpdateRequestFailure
> = {
  request: createAction(settingActionTypes.SettingsUpdateRequest),
  success: createAction(settingActionTypes.SettingsUpdateRequestSuccess),
  failure: createAction(settingActionTypes.SettingsUpdateRequestFailure),
}

export function updateSettings(
  settings: CalendarSettingsResource[]
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(updateSettingsAction.failure({ message: AuthMissing }))
      return
    }
    try {
      dispatch(updateSettingsAction.request())
      const res = await client.updateSettings({ auth, settings })
      dispatch(updateSettingsAction.success(res.result))
    } catch (e) {
      dispatch(updateSettingsAction.failure({ message: e.message }))
    }
  }
}
