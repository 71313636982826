// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  useHistory,
  Redirect,
  useRouteMatch,
  generatePath,
} from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import {
  SyncProgress,
  SyncProgressItem,
} from '@edison/webmail-ui/components/Retrofit'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'

import {
  useRetrofitSyncProgress,
  useProviderLogos,
  useRetrofitAccounts,
} from 'core/retrofit/hooks'
import { fetchAllAccounts } from 'core/retrofit/actions'
import { routePaths } from 'utils/constants'

import type { Dispatch } from 'types/redux'

const View = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { userId } = match.params
  const redirectUrl = generatePath(routePaths.main, { userId, label: 'inbox' })
  const {
    activeAccounts,
    isSyncing,
    isSyncError,
    progress,
    isPaused,
    syncErrorMessages,
  } = useRetrofitSyncProgress()

  const { logos } = useProviderLogos()

  const onClose = () => {
    // Might come to this page immediately, should go back to main inbox
    history.replace(redirectUrl)
  }

  if (isEmpty(activeAccounts)) {
    return <Redirect to={redirectUrl} />
  }

  const sortedAccounts = orderBy(
    activeAccounts,
    [({ ecUUID }) => isSyncing[ecUUID], 'lastSyncTime'],
    ['desc', 'desc']
  )

  return (
    <SyncProgress
      onBack={onClose}
      onContinue={onClose}
      emails={sortedAccounts.map(({ emailAddress }) => emailAddress)}
    >
      {sortedAccounts.map(({ ecUUID, connectionType, name, emailAddress }) => {
        return (
          <SyncProgressItem
            key={ecUUID}
            name={name}
            isPaused={isPaused}
            email={emailAddress}
            progress={progress[ecUUID]}
            logo={logos[connectionType]}
            isError={isSyncError[ecUUID]}
            errorMessage={syncErrorMessages[ecUUID]}
          />
        )
      })}
    </SyncProgress>
  )
}

View.displayName = 'SyncProgressView'

export default () => {
  const dispatch: Dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const { accounts } = useRetrofitAccounts()

  useEffect(() => {
    new Promise(resolve => {
      if (accounts.length === 0) {
        dispatch(fetchAllAccounts()).then(resolve)
      } else {
        resolve()
      }
    }).then(() => {
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return <CircularLoader fullWidth fullHeight />
  } else {
    return <View />
  }
}
