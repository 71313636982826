// @flow
import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import get from 'lodash/get'
import moment from 'moment'
import qs from 'qs'

import { useToast, toastVariants } from 'common/toasts'
import * as client from '@edison/webmail-core/api/auth'
import getCaptchaToken from '@edison/webmail-core/captcha'
import { recoveryMethods as recoveryMethodsFromAPI } from '@edison/webmail-core/utils/constants'
import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import { getSessionAccounts } from './selectors'
import * as selectors from './selectors'
import * as actions from './actions'
import { useDomain } from 'core/custom-domains/hooks'
import { useQueryParams } from 'common/url'
import {
  toastTypes,
  routePaths,
  authStatus,
  usernameTypes,
  usernameLengths,
  premiumPlanIds,
  premiumFreePlanId,
  premiumPlanIntervals,
} from 'utils/constants'
import { stateWrapper } from 'utils'

import type { Dispatch } from 'types/redux'
import type { ExtraAuth } from '@edison/webmail-core/types'
import type { RecoveryMethod } from '@edison/webmail-ui/utils/constants'

/**
 * Common fields and logic for sign up pages.
 *
 * @public
 */
export const useSignup = ({
  isInvite = false,
  requiredQueryParams = [],
}: {
  isInvite?: boolean,
  requiredQueryParams?: $ReadOnlyArray<string>,
} = {}) => {
  const params = useQueryParams()
  const dispatch: Dispatch = useDispatch()
  const [invitationCode, setInvitationCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [profile, setProfile] = useState<{
    firstName: string,
    lastName: string,
    birthday: {
      day: number,
      month: number,
      year: number,
    },
    tnc: boolean,
    optIn: boolean,
  }>({
    firstName: '',
    lastName: '',
    birthday: {
      // To set placeholder for birthday
      day: 0,
      month: 0,
      year: 0,
    },
    tnc: false,
    optIn: false,
  })
  const [recoveryMethod, setRecoveryMethod] = useState<?Object>(null)
  const [paymentMethod, setPaymentMethod] = useState<?Object>(null)
  const [planId, setPlanId] = useState<string>('0')
  // For custom domains
  const [price, setPrice] = useState<string | void>(undefined)
  const [currency, setCurrency] = useState<string | void>(undefined)

  const [isFreeTrial, setFreeTrail] = useState<boolean>(false)
  const [isPlanPreset, setPresetPlan] = useState<boolean>(false)

  const { onmailDomain } = useDomain()

  const redirectToMarketingSite = () => {
    window.location.href = 'https://www.onmail.com/onmailpricing'
  }

  // Pre-fill fields
  useEffect(() => {
    const code: string = get(params, 'invitationCode', '')
    const planId: string = get(params, 'planId')
    const emailAddress: string = get(params, 'emailAddress')

    setInvitationCode(code)

    // Redirect back to marketing site signup if any of required fields are not present
    const hasMissingParams =
      requiredQueryParams && requiredQueryParams.some(item => !params[item])
    if (hasMissingParams) {
      redirectToMarketingSite()
      return
    }

    if (requiredQueryParams.includes('planId') || !!planId) {
      // Invalid plan ID, e.g. 8 or 9
      //
      // EWM-2455
      // See the business related plans as invalid
      const isInvalidPlanId = !includes(
        [
          ...values(premiumPlanIds.month),
          ...values(premiumPlanIds.year),
        ].filter(
          id =>
            id !== premiumPlanIds.month.agency &&
            id !== premiumPlanIds.year.agency
        ),
        planId
      )

      // Non-invite users cannot sign up for free account
      const isFreePlanId = planId === premiumFreePlanId

      if (!isInvite && (isInvalidPlanId || isFreePlanId)) {
        redirectToMarketingSite()
        return
      }
    }

    if (isInvite || !!planId) {
      if (isInvite) {
        // One year free trail of personal plan for invited users
        setFreeTrail(true)
        setPlanId(premiumPlanIds[premiumPlanIntervals.year].pro)
      } else {
        setPlanId(planId)
      }
      setPresetPlan(true)
    }

    // Check for domain - username shouldn't consist of the domain if
    // user is signing up for custom domain
    if (emailAddress) {
      const [username, domain] = emailAddress.split('@')
      if (domain === onmailDomain) {
        setEmail(username)
      } else {
        setEmail(emailAddress)
      }
    }
  }, [])

  return {
    email: { value: email, set: setEmail },
    password: { value: password, set: setPassword },
    profile: { value: profile, set: setProfile },
    invitationCode: { value: invitationCode, set: setInvitationCode },
    recoveryMethod: { value: recoveryMethod, set: setRecoveryMethod },
    paymentMethod: { value: paymentMethod, set: setPaymentMethod },
    planId: { value: planId, set: setPlanId },
    price: { value: price, set: setPrice },
    currency: { value: currency, set: setCurrency },
    getUsernameType: useUsernameType,
    isFreeTrial,
    isPlanPreset,
    formatBirthday: (birthday: $PropertyType<typeof profile, 'birthday'>) => {
      const res = moment()
      res.date(profile.birthday.day)
      res.month(profile.birthday.month)
      res.year(profile.birthday.year)

      return res.format('YYYY-MM-DD')
    },
    verifyInvitation: (code: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (!!invitationCode) {
          dispatch(actions.verifyInvitation(code)).then(success => {
            if (success) {
              setInvitationCode(code)
              resolve()
            } else {
              reject()
            }
          })
        } else {
          reject()
        }
      })
    },
  }
}

export const useSignupCallback = () => {
  const [orderId, setOrderId] = useState<string | void>(undefined)

  const goToInbox = (params: { [key: string]: any } = {}) => {
    const url = new URL(window.location.origin)
    if (orderId) {
      url.pathname = generatePath(routePaths.main, {
        userId: orderId,
        label: 'inbox',
      })
    }

    for (let key in params) {
      url.searchParams.set(key, params[key])
    }

    window.location.replace(url)
  }

  return {
    orderId,
    goToInbox,
    setOrderId,
  }
}

export const useUsernameType = (username: string, hasInviteCode: boolean) => {
  const checkLimits = (username, type) => {
    const len = username.length
    const { min, max } = usernameLengths[type]

    return len >= min && len <= max
  }

  // Regular
  if (checkLimits(username, usernameTypes.regular)) {
    return usernameTypes.regular
  }

  // Short
  if (checkLimits(username, usernameTypes.short)) {
    return usernameTypes.short
  }

  // Extra Short
  if (checkLimits(username, usernameTypes.extraShort)) {
    if (hasInviteCode) {
      return usernameTypes.extraShort
    } else {
      return usernameTypes.invalid
    }
  }

  return usernameTypes.invalid
}

/**
 * Super session order ID for multiple account support.
 */
export const useOrderId = ({
  defaultOrderId = '0',
}: { defaultOrderId?: ?string } = {}): ?string => {
  const orderId = useSelector(selectors.getOrderId)

  return !isNil(orderId) ? orderId : defaultOrderId
}

export const useSession = () => {
  const { onmailDomain } = useDomain()
  const currentOrderId = useOrderId()
  const accounts = useSelector(getSessionAccounts)
  const host = useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return `mail.${onmailDomain}`
    } else {
      return 'localhost:3000'
    }
  }, [])

  const onSwitch = (orderId: string) => {
    const account = find(accounts, account => account.orderId === orderId)
    if (isNil(account)) {
      console.error('Invalid order ID', orderId)
      return
    }

    const next = generatePath(routePaths.main, {
      userId: orderId,
      label: 'inbox',
    })
    const host = account.customDomain ? account.customDomain : onmailDomain
    window.location.href = `//mail.${host}${next}`
  }

  const onAddAccount = () => {
    window.location.href = `//${host}${routePaths.addAccountLogin}`
  }

  const onLogin = (orderId: string) => {
    const account = accounts.find(account => account.orderId === orderId)

    if (account) {
      const params = qs.stringify({
        orderId: account.orderId,
        error: !account.validSession ? authStatus.INVALID : undefined,
      })

      window.location.href = `//${host}${routePaths.addAccountLogin}?${params}`
    } else {
      onAddAccount()
    }
  }

  const onLogout = (orderId: string) => {
    const account = accounts.find(account => account.orderId === orderId)
    if (account) {
      let targetHost = host
      // If the account owns a domain, logout of it under custom domain
      if (account.customDomain) {
        targetHost = `mail.${account.customDomain}`
      }

      const path = generatePath(routePaths.logout, {
        userId: orderId,
      })

      let params = {}
      if (orderId !== currentOrderId) {
        params['next'] = encodeURIComponent(window.location.href)
      }

      const query = isEmpty(params) ? '' : '?' + qs.stringify(params)

      window.location.replace(`//${targetHost}${path}${query}`)
    }
  }

  return { accounts, onSwitch, onAddAccount, onLogin, onLogout }
}

export const useCurrentAuth = () => {
  const auth = useSelector(selectors.getAuth())

  return auth
}

type ASPState = {
  step: 'PASSWORD' | 'NAME' | 'DETAIL',
  password: {
    name: string,
    value: string,
  },
  auth: ?ExtraAuth,
}
export const useAppPassword = () => {
  const stepNames = {
    PASSWORD: 'PASSWORD',
    NAME: 'NAME',
    DETAIL: 'DETAIL',
  }

  const dispatch: Dispatch = useDispatch()
  const [state, setState] = useState<ASPState>({
    auth: null,
    password: {
      name: '',
      value: '',
    },
    step: stepNames.PASSWORD,
  })

  function handleOnGrantPassword(auth: ExtraAuth) {
    setState(state => ({ ...state, auth, step: stepNames.NAME }))
  }

  function handleListAppPasswords() {
    dispatch(actions.listAppPasswords())
  }

  function handleOnCreate(name: string) {
    dispatch(actions.createAppPassword(name, { auth: state.auth })).then(
      password => {
        if (!!password) {
          setState(state => ({ ...state, password, step: stepNames.DETAIL }))
        }
      }
    )
  }

  function handleOnRemove(id: string) {
    return dispatch(actions.removeAppPassword(id))
  }

  return {
    stepNames,
    step: {
      value: state.step,
      set: (step: $PropertyType<ASPState, 'step'>) =>
        setState(state => ({ ...state, step })),
    },
    password: state.password,
    authed: !!state.auth,
    onCreate: handleOnCreate,
    onRemove: handleOnRemove,
    onGrantPassword: handleOnGrantPassword,
    onListAppPasswords: handleListAppPasswords,
  }
}

export const useResetPasswordForm = () => {
  const { t } = useTranslation()
  const toast = useToast(toastTypes.notification)

  const [isLoading, setLoading] = useState(false)
  const [state, setState] = useState({
    methods: [],
    selected: {},
    account: '',
    resetPreToken: '',
    resetToken: '',
  })

  const getRecoveryMethods = async account => {
    setLoading(true)
    try {
      const res = await client.getRecoveryMethods(account)

      const { phoneNumber, recoveryEmail } = res.result

      let methods = []
      if (phoneNumber) {
        methods.push({
          type: recoveryMethods.phone,
          value: phoneNumber,
        })
      }
      if (recoveryEmail) {
        methods.push({
          type: recoveryMethods.email,
          value: recoveryEmail,
        })
      }

      setState(state => ({ ...state, account, methods }))
      return true
    } catch (e) {
      console.error(e)

      toast.showToast(
        t('recoveryMethod.error.getMethods.default'),
        toastVariants.error
      )

      return false
    } finally {
      setLoading(false)
    }
  }

  const sendCode = async (method: RecoveryMethod) => {
    setLoading(true)

    try {
      let methodToAPI
      if (method.type === recoveryMethods.email) {
        methodToAPI = recoveryMethodsFromAPI.email
      } else if (method.type === recoveryMethods.phone) {
        methodToAPI = recoveryMethodsFromAPI.sms
      }

      if (!methodToAPI) {
        throw new Error('Invalid recovery method')
      }

      const captcha = await getCaptchaToken(`${method.type}_reset_send`)

      const res = await client.sendRecoveryCode(
        { account: state.account, method: methodToAPI },
        { captcha: captcha.token, resetPreToken: state.resetPreToken }
      )

      const { resetPreToken } = res.result

      setState(state => ({ ...state, resetPreToken, selected: method }))
      return true
    } catch (e) {
      console.error(e)

      toast.showToast(
        t('recoveryMethod.error.sendCode.default'),
        toastVariants.error
      )
      return false
    } finally {
      setLoading(false)
    }
  }

  const verifyCode = async code => {
    setLoading(true)

    const { account, selected, resetPreToken } = state

    try {
      if (!selected) {
        throw new Error('Invalid recovery method')
      }

      const res = await client.verifyRecoveryCode(
        { account, code },
        { resetPreToken }
      )

      const { resetToken } = res.result

      setState(state => ({ ...state, resetToken }))
      return true
    } catch (e) {
      console.error(e)

      toast.showToast(
        t('recoveryMethod.error.verifyCode.default'),
        toastVariants.error
      )
      return false
    } finally {
      setLoading(false)
    }
  }

  const confirmReset = async password => {
    setLoading(true)

    const { account, resetToken } = state

    try {
      if (!password) {
        throw new Error('Invalid password')
      }

      await client.confirmRecovery({ account, password }, { resetToken })

      toast.showToast(t('resetPassword.success'), toastVariants.success)
      return true
    } catch (e) {
      console.error(e)

      toast.showToast(
        t('recoveryMethod.error.confirm.default'),
        toastVariants.error
      )
      return false
    } finally {
      setLoading(false)
    }
  }

  return {
    isLoading,
    selected: stateWrapper<RecoveryMethod>('selected', state, setState),
    methods: stateWrapper<$ReadOnlyArray<RecoveryMethod>>(
      'methods',
      state,
      setState
    ),
    getRecoveryMethods,
    sendCode,
    verifyCode,
    confirmReset,
  }
}
