// @flow
import i18next from 'i18next'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import union from 'lodash/union'
import values from 'lodash/values'
import * as client from '@edison/webmail-core/api/contacts'
import { base64ToFile } from '@edison/webmail-core/utils/file'
import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'
import { createFlagStorage } from 'common/storage'
import {
  modalTypes,
  contactTypes,
  contactActions,
  suggestedBlockStatus,
} from 'utils/constants'
import { getDomainByEmail } from 'utils'
import { actionTypes as contactActionTypes } from '@edison/webmail-ui/components/ContactFirstActionDialog'

import { getTutorialFlag } from 'core/flags/selectors'
import { localStorageFlags, userTutorialFlags } from 'core/flags/constants'
import { show as showModal } from 'core/modals/actions'
import { labelActions } from 'core/metadata/actions'
import { batchGetThreads } from '../threads/actions'
import { batchGetMessages } from '../messages/actions'
import { showNotification } from 'core/toasts/actions'
import { updateSplitInbox } from 'core/split-inboxes/actions'
import { getAuth } from 'core/auth/selectors'
import {
  getContactById,
  hasBlockedDomainByEmail,
  getBlockDomainContact,
  getRelatedContactsByEmail,
} from './selectors'
import {
  isContactStatusUpdating,
  getPendingThreads,
  getSuggestedBlockByEmail,
  getPendingMessageId,
} from './selectors'
import * as analytics from 'core/analytics'
import type {
  ContactListRequest,
  ContactListSuccess,
  ContactListFailure,
  ContactUpdateRequest,
  ContactUpdateSuccess,
  ContactUpdateFailure,
  ContactDeleteRequest,
  ContactDeleteSuccess,
  ContactDeleteFailure,
  ContactThreadsRequest,
  ContactThreadsSuccess,
  ContactThreadsFailure,
  ContactPendingThreadsRequest,
  ContactPendingThreadsSuccess,
  ContactPendingThreadsFailure,
  ContactSuggestionsRequest,
  ContactSuggestionsSuccess,
  ContactSuggestionsFailure,
  ContactAttactmentsRequest,
  ContactAttactmentsSuccess,
  ContactAttactmentsFailure,
  ContactUploadAvatarRequest,
  ContactUploadAvatarSuccess,
  ContactUploadAvatarFailure,
  ContactStatusBatchUpdateRequest,
  ContactStatusBatchUpdateSuccess,
  ContactStatusBatchUpdateFailure,
  ContactPendingMessageRequest,
  ContactPendingMessageSuccess,
  ContactPendingMessageFailure,
  TemporaryContactCreate,
  SuggestedBlockListRequest,
  SuggestedBlockListSuccess,
  SuggestedBlockListFailure,
  SuggestedBlockFeedbackRequest,
  SuggestedBlockFeedbackSuccess,
  SuggestedBlockFeedbackFailure,
  BlockDomainActionsRequest,
  BlockDomainActionsSuccess,
  BlockDomainActionsFailure,
  UnblockDomainActionsRequest,
  UnblockDomainActionsSuccess,
  UnblockDomainActionsFailure,
  ResetContactThreads,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type { Contact } from '@edison/webmail-core/types/contacts'
import type { SplitInbox } from '@edison/webmail-core/types/split-inboxes'

export const fetchContactsActions: {
  request: ActionCreator<ContactListRequest>,
  success: ActionCreator<ContactListSuccess>,
  failure: ActionCreator<ContactListFailure>,
} = {
  request: createAction('CONTACT_LIST_REQUEST'),
  success: createAction('CONTACT_LIST_SUCCESS'),
  failure: createAction('CONTACT_LIST_FAILURE'),
}

export function fetchContacts(
  status?: number,
  includeDomains?: 0 | 1
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchContactsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchContactsActions.request())
      const res = await client.list({ auth, status, includeDomains })
      dispatch(fetchContactsActions.success({ ...res.result, status }))
    } catch (e) {
      dispatch(fetchContactsActions.failure({ message: e.message }))
    }
  }
}

export const updateContactActions: {
  request: ActionCreator<ContactUpdateRequest>,
  success: ActionCreator<ContactUpdateSuccess>,
  failure: ActionCreator<ContactUpdateFailure>,
} = {
  request: createAction('CONTACT_UPDATE_REQUEST'),
  success: createAction('CONTACT_UPDATE_SUCCESS'),
  failure: createAction('CONTACT_UPDATE_FAILURE'),
}

export function updateContact(contact: Contact): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchContactsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(updateContactActions.request())
      await client.update({ auth, contact })
      dispatch(updateContactActions.success(contact))
    } catch (e) {
      dispatch(updateContactActions.failure({ message: e.message }))
    }
  }
}

export const deleteContactActions: {
  request: ActionCreator<ContactDeleteRequest>,
  success: ActionCreator<ContactDeleteSuccess>,
  failure: ActionCreator<ContactDeleteFailure>,
} = {
  request: createAction('CONTACT_DELETE_REQUEST'),
  success: createAction('CONTACT_DELETE_SUCCESS'),
  failure: createAction('CONTACT_DELETE_FAILURE'),
}

export function deleteContact(ids: Array<string>): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(deleteContactActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(deleteContactActions.request())
      await client.remove({ auth, ids })
      dispatch(deleteContactActions.success({ ids }))
    } catch (e) {
      dispatch(deleteContactActions.failure({ message: e.message }))
    }
  }
}

export const fetchContactThreadsActions: {
  request: ActionCreator<ContactThreadsRequest>,
  success: ActionCreator<ContactThreadsSuccess>,
  failure: ActionCreator<ContactThreadsFailure>,
} = {
  request: createAction('CONTACT_THREADS_REQUEST'),
  success: createAction('CONTACT_THREADS_SUCCESS'),
  failure: createAction('CONTACT_THREADS_FAILURE'),
}

export function fetchContactThreads(
  id: string,
  idType?: 'ID' | 'EMAIL' = 'ID',
  pageToken = ''
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchContactsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchContactThreadsActions.request())

      let res = {}
      if (idType === 'EMAIL') {
        res = await client.threadListByEmail(id, { auth })
      } else {
        res = await client.threadList(id, { auth }, pageToken)
      }
      //filter the thread contained spam message
      const { threads, next_page_token } = res.result

      dispatch(
        fetchContactThreadsActions.success({
          id,
          threads,
          next_page_token,
        })
      )
    } catch (e) {
      dispatch(fetchContactThreadsActions.failure({ message: e.message }))
    }
  }
}

export const fetchContactPendingThreadsActions: {
  request: ActionCreator<ContactPendingThreadsRequest>,
  success: ActionCreator<ContactPendingThreadsSuccess>,
  failure: ActionCreator<ContactPendingThreadsFailure>,
} = {
  request: createAction('CONTACT_PENDING_THREADS_REQUEST'),
  success: createAction('CONTACT_PENDING_THREADS_SUCCESS'),
  failure: createAction('CONTACT_PENDING_THREADS_FAILURE'),
}

export function fetchPendingThreads(email: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchContactPendingThreadsActions.failure({
          message: 'User not logged in',
        })
      )
      return
    }

    try {
      dispatch(fetchContactPendingThreadsActions.request({ id: email }))
      const res = await client.pendingThreads(email, { auth })
      dispatch(
        fetchContactPendingThreadsActions.success({
          id: email,
          threads: res,
        })
      )
    } catch (e) {
      dispatch(
        fetchContactPendingThreadsActions.failure({ message: e.message })
      )
    }
  }
}

export const fetchContactAttachmentsActions: {
  request: ActionCreator<ContactAttactmentsRequest>,
  success: ActionCreator<ContactAttactmentsSuccess>,
  failure: ActionCreator<ContactAttactmentsFailure>,
} = {
  request: createAction('CONTACT_ATTACHMENT_REQUEST'),
  success: createAction('CONTACT_ATTACHMENT_SUCCESS'),
  failure: createAction('CONTACT_ATTACHMENT_FAILURE'),
}

export function fetchContactAttachments(
  id: string,
  idType: 'ID' | 'EMAIL' = 'ID',
  attachmentType: 'file' | 'image' = 'file'
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchContactsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchContactAttachmentsActions.request())
      let res = {}
      if (idType === 'EMAIL') {
        res = await client.attachmentsByEmail(id, {
          auth,
          type: attachmentType,
        })
      } else {
        const email = get(getContactById(id)(getState()), 'emails[0].email', '')

        res = await client.attachmentsByEmail(email, {
          auth,
          type: attachmentType,
        })
      }
      dispatch(fetchContactAttachmentsActions.success({ id, ...res.result }))
    } catch (e) {
      dispatch(fetchContactAttachmentsActions.failure({ message: e.message }))
    }
  }
}

export const fetchContactSuggestionsActions: {
  request: ActionCreator<ContactSuggestionsRequest>,
  success: ActionCreator<ContactSuggestionsSuccess>,
  failure: ActionCreator<ContactSuggestionsFailure>,
} = {
  request: createAction('CONTACT_SUGGESTIONS_REQUEST'),
  success: createAction('CONTACT_SUGGESTIONS_SUCCESS'),
  failure: createAction('CONTACT_SUGGESTIONS_FAILURE'),
}

export function fetchContactSuggestions(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchContactsActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchContactSuggestionsActions.request())
      const res = await client.suggestions({ auth })
      dispatch(fetchContactSuggestionsActions.success(res.result))
    } catch (e) {
      dispatch(fetchContactSuggestionsActions.failure({ message: e.message }))
    }
  }
}

export const uploadAvatarActions: {
  request: ActionCreator<ContactUploadAvatarRequest>,
  success: ActionCreator<ContactUploadAvatarSuccess>,
  failure: ActionCreator<ContactUploadAvatarFailure>,
} = {
  request: createAction('CONTACT_UPLOAD_AVATAR_REQUEST'),
  success: createAction('CONTACT_UPLOAD_AVATAR_SUCCESS'),
  failure: createAction('CONTACT_UPLOAD_AVATAR_FAILURE'),
}

export function uploadAvatar(
  contactId: string,
  attachment: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(uploadAvatarActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(uploadAvatarActions.request())
      const file = await base64ToFile(attachment, `${auth.user}-avatar.jpg`)
      const res = await client.uploadAvatar(contactId, file, { auth })
      dispatch(uploadAvatarActions.success())

      return res
    } catch (e) {
      dispatch(uploadAvatarActions.failure({ message: e.message }))
    }
  }
}

export const batchUpdateSenderActions: {
  request: ActionCreator<ContactStatusBatchUpdateRequest>,
  success: ActionCreator<ContactStatusBatchUpdateSuccess>,
  failure: ActionCreator<ContactStatusBatchUpdateFailure>,
} = {
  request: createAction('CONTACT_STATUS_BATCH_UPDATE_REQUEST'),
  success: createAction('CONTACT_STATUS_BATCH_UPDATE_SUCCESS'),
  failure: createAction('CONTACT_STATUS_BATCH_UPDATE_FAILURE'),
}

export function batchApproveSenders(
  emails: $ReadOnlyArray<string>
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const isLoading = isContactStatusUpdating()(state)

    const firstActionFlag = getTutorialFlag(userTutorialFlags.contactAction)(
      state
    )

    if (auth === null) {
      dispatch(
        batchUpdateSenderActions.failure({ message: 'User not logged in' })
      )
      return false
    }

    if (!isLoading) {
      const pendingThreads = getPendingThreads()(state)
      const threadIds = emails
        .flatMap(email => get(pendingThreads, email, []))
        .map(each => each.id)

      try {
        dispatch(batchUpdateSenderActions.request())
        await client.batchAction(emails, contactActions.APPROVE, {
          auth,
          applyAll: true,
        })

        // Fetch the latest state of the pending threads
        await dispatch(batchGetThreads(threadIds, true))

        dispatch(analytics.actions.approveSenders.userApproveSender())
        dispatch(
          batchUpdateSenderActions.success({
            emails,
            status: contactTypes.NORMAL,
          })
        )

        if (firstActionFlag) {
          dispatch(
            showModal({
              key: modalTypes.contactFirstAction,
              props: {
                type: contactActionTypes.accepted,
              },
            })
          )
        }

        return true
      } catch (e) {
        dispatch(batchUpdateSenderActions.failure({ message: e.message }))
        dispatch(
          showNotification(
            i18next.t('approveSender.approve.error'),
            toastVariants.error
          )
        )
        return false
      }
    } else return true
  }
}

export function batchBlockSenders(
  emails: $ReadOnlyArray<string>,
  applyAll?: boolean = false
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const isLoading = isContactStatusUpdating()(state)
    const suggestedBlock = emails.map(email =>
      getSuggestedBlockByEmail(email)(state)
    )

    const firstActionFlag = getTutorialFlag(userTutorialFlags.contactAction)(
      state
    )

    if (auth === null) {
      dispatch(
        batchUpdateSenderActions.failure({ message: 'User not logged in' })
      )
      return
    }

    if (!isLoading) {
      try {
        dispatch(batchUpdateSenderActions.request())
        await client.batchAction(emails, contactActions.BLOCK, {
          auth,
          applyAll,
        })
        dispatch(analytics.actions.approveSenders.userBlockSender())
        dispatch(
          batchUpdateSenderActions.success({
            emails,
            status: contactTypes.BLOCK,
          })
        )

        if (firstActionFlag) {
          dispatch(
            showModal({
              key: modalTypes.contactFirstAction,
              props: {
                type: contactActionTypes.blocked,
              },
            })
          )
        }

        for (let suggested of suggestedBlock) {
          if (!isNil(suggested)) {
            dispatch(
              feedbackSuggestedBlock(suggested.id, suggestedBlockStatus.blocked)
            )
          }
        }
        return true
      } catch (e) {
        dispatch(batchUpdateSenderActions.failure({ message: e.message }))
        dispatch(
          showNotification(
            i18next.t('approveSender.block.error'),
            toastVariants.error
          )
        )
        return false
      }
    } else return true
  }
}

export function batchUnblockSenders(
  emails: $ReadOnlyArray<string>
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const isLoading = isContactStatusUpdating()(getState())

    if (auth === null) {
      dispatch(
        batchUpdateSenderActions.failure({ message: 'User not logged in' })
      )
      return false
    }

    if (!isLoading) {
      try {
        dispatch(batchUpdateSenderActions.request())
        await client.batchAction(emails, contactActions.UNBLOCK, {
          auth,
          applyAll: false,
        })

        dispatch(analytics.actions.approveSenders.userUnblockSender())
        dispatch(
          batchUpdateSenderActions.success({
            emails,
            status: contactTypes.NORMAL,
          })
        )
        return true
      } catch (e) {
        dispatch(batchUpdateSenderActions.failure({ message: e.message }))
        return false
      }
    } else return true
  }
}

export function approveSender(email: string): ThunkAction {
  return batchApproveSenders([email])
}

export function blockSender(
  email: string,
  applyAll?: boolean = false
): ThunkAction {
  return batchBlockSenders([email], applyAll)
}

export function unblockSender(email: string): ThunkAction {
  return batchUnblockSenders([email])
}

export function batchApproveAndMoveTo(
  emails: $ReadOnlyArray<string>,
  split: SplitInbox
): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const pendingThreads = getPendingThreads()(state)
    const threadIds = emails
      .flatMap(email => get(pendingThreads, email, []))
      .map(each => each.id)

    let success = await dispatch(batchApproveSenders(emails))
    if (!success) {
      return false
    }

    dispatch(
      labelActions
        .update([split.labelId], [])
        .threads(threadIds, { execute: false })
    )

    const { from, ...restQuery } = split.query

    const toUpdate = {
      ...split,
      query: {
        ...restQuery,
        from: union(from, emails),
      },
    }

    success = await dispatch(updateSplitInbox(split.id, toUpdate))

    if (!!success) {
      dispatch(
        showModal({
          key: modalTypes.dndSplitConfirm,
          threadIds,
          prefill: { from: emails },
          targetLabelId: split.labelId,
        })
      )
    }

    return !!success
  }
}

export function approveAndMoveTo(
  email: string,
  split: SplitInbox
): ThunkAction {
  return batchApproveAndMoveTo([email], split)
}

export const fetchPendingMessageActions: {
  request: ActionCreator<ContactPendingMessageRequest>,
  success: ActionCreator<ContactPendingMessageSuccess>,
  failure: ActionCreator<ContactPendingMessageFailure>,
} = {
  request: createAction('CONTACT_PENDING_MESSAGE_REQUEST'),
  success: createAction('CONTACT_PENDING_MESSAGE_SUCCESS'),
  failure: createAction('CONTACT_PENDING_MESSAGE_FAILURE'),
}

export function fetchPendingMessage(
  emails: $ReadOnlyArray<string>
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const byEmail = getPendingMessageId(getState())

    if (auth === null) {
      dispatch(
        fetchPendingMessageActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      const toFetch = emails.filter(email => !(email in byEmail))
      if (toFetch.length > 0) {
        dispatch(fetchPendingMessageActions.request())
        const res = await client.pendingMessage(toFetch, { auth })
        const { messageIds } = res.result
        dispatch(fetchPendingMessageActions.success(messageIds))
        dispatch(batchGetMessages(values(messageIds)))
      }
    } catch (e) {
      dispatch(fetchPendingMessageActions.failure({ message: e.message }))
    }
  }
}

export function showBlockPrompt(
  email: string,
  applyAll: boolean = false,
  callback?: () => void
) {
  return showBatchBlockPrompt([email], applyAll, callback)
}

export function showBatchBlockPrompt(
  emails: $ReadOnlyArray<string>,
  applyAll: boolean = true,
  callback?: () => void
) {
  return (dispatch, getState) => {
    const blockSenderPrompt = createFlagStorage(
      localStorageFlags.blockSenderPromptKey
    ).getFlag()
    const firstActionFlag = getTutorialFlag(userTutorialFlags.contactAction)(
      getState()
    )

    // INFO: Only show the prompt when
    // False for the block sender prompt and
    // False for the contact first action flag
    if (blockSenderPrompt || firstActionFlag) {
      dispatch(batchBlockSenders(emails, applyAll)).then(
        () => callback && callback()
      )
    } else {
      dispatch(
        showModal({
          key: modalTypes.blockPrompt,
          props: {
            emails,
            applyAll,
            callback,
          },
        })
      )
    }
  }
}

export const createTemporaryContact: ActionCreator<TemporaryContactCreate> = createAction(
  'TEMPORARY_CONTACT_CREATE'
)

export const fetchSuggestedBlocksActions: {
  request: ActionCreator<SuggestedBlockListRequest>,
  success: ActionCreator<SuggestedBlockListSuccess>,
  failure: ActionCreator<SuggestedBlockListFailure>,
} = {
  request: createAction('SUGGESTED_BLOCK_LIST_REQUEST'),
  success: createAction('SUGGESTED_BLOCK_LIST_SUCCESS'),
  failure: createAction('SUGGESTED_BLOCK_LIST_FAILURE'),
}

export function fetchSuggestedBlocks(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchSuggestedBlocksActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(fetchSuggestedBlocksActions.request())
      const res = await client.fetchSuggestedBlocks({ auth })
      const { suggestion } = res.result
      dispatch(fetchSuggestedBlocksActions.success(suggestion))
    } catch (e) {
      dispatch(fetchSuggestedBlocksActions.failure({ message: e.message }))
    }
  }
}

export const feedbackSuggestedBlockActions: {
  request: ActionCreator<SuggestedBlockFeedbackRequest>,
  success: ActionCreator<SuggestedBlockFeedbackSuccess>,
  failure: ActionCreator<SuggestedBlockFeedbackFailure>,
} = {
  request: createAction('SUGGESTED_BLOCK_FEEDBACK_REQUEST'),
  success: createAction('SUGGESTED_BLOCK_FEEDBACK_SUCCESS'),
  failure: createAction('SUGGESTED_BLOCK_FEEDBACK_FAILURE'),
}

export function feedbackSuggestedBlock(
  id: string,
  status: 0 | 1 | 2 | 3
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        feedbackSuggestedBlockActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(feedbackSuggestedBlockActions.request())
      await client.updateSuggestedBlock({ id, status }, { auth })
      dispatch(feedbackSuggestedBlockActions.success({ id }))
    } catch (e) {
      dispatch(feedbackSuggestedBlockActions.failure({ message: e.message }))
    }
  }
}

export function enableShowImage(
  contact: Contact,
  enabled: boolean = true
): ThunkAction {
  return async (dispatch, getState) => {
    if (contact) {
      contact.enableShowImage = enabled
      return dispatch(updateContact(contact))
    }
  }
}

export const blockDomainActions: {
  request: ActionCreator<BlockDomainActionsRequest>,
  success: ActionCreator<BlockDomainActionsSuccess>,
  failure: ActionCreator<BlockDomainActionsFailure>,
} = {
  request: createAction('BLOCK_DOMAIN_ACTIONS_REQUEST'),
  success: createAction('BLOCK_DOMAIN_ACTIONS_SUCCESS'),
  failure: createAction('BLOCK_DOMAIN_ACTIONS_FAILURE'),
}

export function blockDomain(email) {
  const [, domain] = email.split('@')
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(blockDomainActions.failure({ message: 'User not logged in' }))
      return []
    }
    try {
      dispatch(blockDomainActions.request())
      const { result } = await client.blockDomain(auth, {
        domains: ['@' + domain],
      })
      dispatch(
        blockDomainActions.success({
          ids: result.ids,
          domain,
          email,
        })
      )
      return result.ids
    } catch (error) {
      dispatch(blockDomainActions.failure({ message: error.message }))
      return []
    }
  }
}

export const unblockDomainActions: {
  request: ActionCreator<UnblockDomainActionsRequest>,
  success: ActionCreator<UnblockDomainActionsSuccess>,
  failure: ActionCreator<UnblockDomainActionsFailure>,
} = {
  request: createAction('UN_BLOCK_DOMAIN_ACTIONS_REQUEST'),
  success: createAction('UN_BLOCK_DOMAIN_ACTIONS_SUCCESS'),
  failure: createAction('UN_BLOCK_DOMAIN_ACTIONS_FAILURE'),
}

export function unblockDomain(ids) {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(unblockDomainActions.failure({ message: 'User not logged in' }))
      return
    }
    try {
      dispatch(unblockDomainActions.request())
      const { result } = await client.unblockDomain(auth, {
        ids,
      })
      dispatch(
        unblockDomainActions.success({
          ids: result.ids,
        })
      )
    } catch (error) {
      dispatch(unblockDomainActions.failure({ message: error.message }))
    }
  }
}

export function showUnblockDomainPrompt(email, id) {
  return (dispatch, getState) => {
    const blockDomainContract = getBlockDomainContact(email)(getState())
    const ids = blockDomainContract.map(item => item.id)
    const relatedContacts = getRelatedContactsByEmail(email)(getState())
    dispatch(
      showModal({
        key: modalTypes.unblockDomain,
        props: {
          email,
          domain: getDomainByEmail(email),
          relatedContacts: relatedContacts.length,
          ids: id ? [id] : ids,
        },
      })
    )
  }
}

export function showUnblockSenderPrompt(email) {
  return (dispatch, getState) => {
    const blockDomainContract = getBlockDomainContact(email)(getState())
    const ids = blockDomainContract.map(item => item.id)
    const hasBlockedDomain = hasBlockedDomainByEmail(email)(getState())
    dispatch(unblockSender(email))
    hasBlockedDomain && dispatch(unblockDomain(ids))
  }
}

export const resetContactThreads: ActionCreator<ResetContactThreads> = createAction(
  'RESET_CONTACT_THREADS'
)
