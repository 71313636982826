import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getMessage } from 'core/messages/selectors'
import cn from 'classnames'

export default function FoldLine({
  messageId,
  number,
  className,
  msgClx,
  ...props
}) {
  const { t } = useTranslation()
  const message = useSelector(useMemo(() => getMessage(messageId), [messageId]))
  if (!message || !message.loaded) return null

  return (
    <div
      className={cn(
        'relative flex items-center mx-4 py-4 text-gray-light cursor-pointer',
        className
      )}
      {...props}
    >
      <hr className="absolute w-full" style={{ zIndex: -1 }} />
      <span className="inline-flex items-center justify-center text-lg border rounded-full left-0 w-12 h-12 font-semibold bg-card">
        {number}
      </span>
      <div className="text-xs flex-grow text-center font-semibold">
        <span className={cn('px-4 bg-app', msgClx)}>
          {t('thread.tip.foldMessage')}
        </span>
      </div>
    </div>
  )
}
