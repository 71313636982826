// @flow
import { labelNames } from 'utils/constants'

/**
 * Keeps track of all label operations while performing thread actions.
 *
 * @public
 */
type Operation = {
  add: $ReadOnlyArray<string>,
  remove: $ReadOnlyArray<string>,
}

export const labelOperations: { [actionName: string]: Operation } = {
  archive: {
    add: [labelNames.archive],
    remove: [
      labelNames.inbox,
      labelNames.trash,
      labelNames.spam,
      labelNames.unread,
    ],
  },
  unarchive: {
    add: [labelNames.inbox],
    remove: [labelNames.archive],
  },
  read: {
    add: [],
    remove: [labelNames.unread],
  },
  unread: {
    add: [labelNames.unread],
    remove: [],
  },
  trash: {
    add: [labelNames.trash],
    remove: [labelNames.inbox, labelNames.archive, labelNames.spam],
  },
  untrash: {
    add: [labelNames.inbox],
    remove: [labelNames.trash, labelNames.spam, labelNames.archive],
  },
  deleteForever: {
    add: [],
    remove: [],
  },
  notSpam: {
    add: [labelNames.inbox],
    remove: [labelNames.spam, labelNames.trash, labelNames.archive],
  },
  markAsSpam: {
    add: [labelNames.spam],
    remove: [labelNames.inbox, labelNames.archive, labelNames.trash],
  },
  moveToInbox: {
    add: [labelNames.inbox],
    remove: [labelNames.archive, labelNames.spam, labelNames.trash],
  },
  discardDraft: {
    add: [],
    remove: [],
  },
  primary: {
    add: [labelNames.primary],
    remove: [labelNames.social, labelNames.promotions],
  },
  other: {
    add: [labelNames.promotions],
    remove: [labelNames.primary, labelNames.social],
  },
}
