import md5 from 'md5'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import PasswordForm from '@edison/webmail-ui/components/AttachmentPasswordForm'
import { useDispatch } from 'react-redux'
import * as client from '@edison/webmail-core/api'
import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'

export default function AttachmentPasswordModal({
  isOpen,
  toggle,

  mid,
  uid,
  email,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      <DialogTitle>
        {t('settings.attachmentLinks.passwordProtection')}
      </DialogTitle>
      <DialogContent>
        <div className="text-gray-medium text-sm">
          {t('settings.attachmentLinks.passwordProtection.message')}
        </div>
      </DialogContent>

      <PasswordForm
        defaultPassword={''}
        onChangePassword={async password => {
          try {
            const res = await client.largeAttachments.downloadAll({
              email,
              uid,
              mid,
              password: password ? md5(password) : '',
              action: 'preview',
            })
            window.open(res.result, '_blank')
            toggle()
          } catch (e) {
            dispatch(showNotification(e.message, toastVariants.error))
          }
        }}
        onCancel={toggle}
      />
    </Dialog>
  )
}
