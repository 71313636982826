// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getSplitInboxByLabelId } from 'core/split-inboxes/selectors'
import { getAllLabelsState, getLabelUnreadCount } from 'core/labels/selectors'
import { useActiveLabel } from 'core/threads/hooks'
import { getLabelUnreadConfig } from 'utils'
import { displayTitles, labelNames, labelTypes } from 'utils/constants'

import { AuthHelmet } from './index'
import { useTranslation } from 'react-i18next'

const labelSelector = getAllLabelsState()
const threadUnreadSelector = getLabelUnreadCount()

const View = () => {
  const { t } = useTranslation()
  const label = useActiveLabel()
  const labels = useSelector(labelSelector)
  const splitsByLabel = useSelector(getSplitInboxByLabelId)
  const labelThreadUnread = useSelector(threadUnreadSelector)

  const { labelId, labelName, labelType, count } = useMemo(() => {
    let [labelId, labelName, labelType, count] = [
      label || '',
      '',
      labelTypes.SYSTEM,
      0,
    ]
    if (label) {
      if (label in displayTitles) {
        // Hanlde the mocked labels
        labelName = displayTitles[label]
      } else if (label in labelNames) {
        // Handle the system labels
        labelId = labelNames[label]
        if (labelId === labelNames.inbox) {
          labelId = labelNames.primary
        }
        labelName = displayTitles[labelId]
      } else if (label in labels) {
        // Handle the user-created labels
        const { id, name, type } = labels[label]
        labelId = id
        labelName = name
        labelType = type
      }

      if (labelType === labelTypes.SPLIT_INBOXES) {
        // Handle then split-inbox name
        const { name } = splitsByLabel[labelId] || {}
        labelName = name
      }

      if (!!labelId) {
        let { display, displayTotal } = getLabelUnreadConfig(labelId)
        if (display) {
          count = displayTotal ? 0 : labelThreadUnread[labelId]
        }
      }
    }

    return {
      labelId,
      labelName,
      labelType,
      count,
    }
  }, [label, labels, splitsByLabel, labelThreadUnread])

  const title = useMemo(() => {
    const withQuote =
      labelType === labelTypes.CUSTOM || labelType === labelTypes.SPLIT_INBOXES

    return [
      count > 0 ? `${count} ${t('text.unread')} -` : undefined,
      withQuote ? `"${labelName}"` : labelName,
    ]
      .filter(Boolean)
      .join(' ')
  }, [labelName, labelType, count])

  if (!labelId) {
    return null
  }

  return (
    <AuthHelmet>
      <title>{title}</title>
    </AuthHelmet>
  )
}

View.displayName = 'InboxHelmet'

export default View
