// @flow
import type Moment from 'moment'
import type { CalendarResolutionTypes } from './types-events'
import type { UnixTimestampInSeconds } from './types-common'
import moment from 'moment'
import { calendarResolution } from './constants-events'
import { getCalendarViewResolution } from '../../common/storage'

export class UIEventsRange {
  _start: UnixTimestampInSeconds
  _end: UnixTimestampInSeconds
  _resolution: CalendarResolutionTypes
  _year: number
  _month: number
  _day: number
  _range: number
  _m: Moment

  constructor(data?: {
    resolution: CalendarResolutionTypes,
    year: number,
    month: number,
    date: number,
  }) {
    let m: Moment = moment()
    if (data) {
      m = moment({ year: data.year, month: data.month - 1, date: data.date })
      this._resolution = Object.values(calendarResolution).includes(
        data.resolution
      )
        ? data.resolution
        : getCalendarViewResolution()
    } else {
      this._resolution = getCalendarViewResolution()
    }
    if (!m.isValid()) {
      m = moment()
    }
    this._m = m.clone()
    this._year = m.year()
    this._month = m.month() + 1
    this._day = m.date()
    const resolutionStart = m.startOf(this._resolution).clone()
    const resolutionEnd = m.endOf(this._resolution).clone()
    if (this._resolution === calendarResolution.Month) {
      if (resolutionStart.weekday() !== 0) {
        resolutionStart.startOf('week')
      }
      if (resolutionEnd.weekday() !== 7) {
        resolutionEnd.endOf('week')
      }
    }
    this._start = resolutionStart.unix()
    this._end = resolutionEnd.unix()
    this._range = this.end - this.start
  }

  isDiff(data: {
    resolution: CalendarResolutionTypes,
    year: number | string,
    month: number | string,
    date: number | string,
  }) {
    if (typeof data.year === 'string') {
      data.year = parseInt(data.year, 10)
    }
    if (typeof data.month === 'string') {
      data.month = parseInt(data.month, 10)
    }
    if (typeof data.date === 'string') {
      data.date = parseInt(data.date, 10)
    }
    const ret = this.toJSON()
    return (
      ret.resolution !== data.resolution ||
      ret.month !== data.month ||
      ret.year !== data.year ||
      ret.date !== data.date
    )
  }

  get start(): UnixTimestampInSeconds {
    return this._start
  }

  get end(): UnixTimestampInSeconds {
    return this._end
  }

  get range() {
    return this._range
  }

  get resolution() {
    return this._resolution
  }

  toJSON(): {
    resolution: CalendarResolutionTypes,
    year: number,
    month: number,
    date: number,
  } {
    return {
      resolution: this._resolution,
      month: this._m.month() + 1,
      year: this._m.year(),
      date: this._m.date(),
    }
  }

  get nextRange(): UIEventsRange {
    const m = this._m.clone()
    if (this._resolution === calendarResolution.Day) {
      m.date(this._day + 1)
    } else if (this._resolution === calendarResolution.Week) {
      m.week(m.week() + 1)
    } else if (this._resolution === calendarResolution.Month) {
      m.month(m.month() + 1)
    } else {
      m.year(m.year() + 1)
    }
    return new UIEventsRange({
      resolution: this._resolution,
      month: m.month() + 1,
      year: m.year(),
      date: m.date(),
    })
  }

  get previousRange(): UIEventsRange {
    const m = this._m.clone()
    if (this._resolution === calendarResolution.Day) {
      m.date(this._day - 1)
    } else if (this._resolution === calendarResolution.Week) {
      m.week(m.week() - 1)
    } else if (this._resolution === calendarResolution.Month) {
      m.month(m.month() - 1)
    } else {
      m.year(m.year() - 1)
    }
    return new UIEventsRange({
      resolution: this._resolution,
      month: m.month() + 1,
      year: m.year(),
      date: m.date(),
    })
  }

  get yearRange() {
    return new UIEventsRange({
      ...this.toJSON(),
      resolution: calendarResolution.Year,
    })
  }

  get monthRange() {
    return new UIEventsRange({
      ...this.toJSON(),
      resolution: calendarResolution.Month,
    })
  }

  get weekRange() {
    return new UIEventsRange({
      ...this.toJSON(),
      resolution: calendarResolution.Week,
    })
  }

  get dayRange() {
    return new UIEventsRange({
      ...this.toJSON(),
      resolution: calendarResolution.Day,
    })
  }

  get todayRange() {
    const m = moment()
    return new UIEventsRange({
      resolution: this._resolution,
      year: m.year(),
      month: m.month() + 1,
      date: m.date(),
    })
  }
}
