// @flow
import { combineReducers } from 'redux'

import { createReducer } from 'utils/redux'
import { fetchExternalAssetsActions } from './actions'

import type { ExternalAssetsSuccess } from './types'

export type InboxZeroState = {|
  // Both objects depends on request from the API
  quote: Object,
  image: Object,
|}

export type State = {
  inboxZero: InboxZeroState,
}

const initialInboxZeroState: InboxZeroState = {
  quote: {},
  image: {},
}

const inboxZero = createReducer(initialInboxZeroState, {
  [fetchExternalAssetsActions.success.toString()]: (
    state: InboxZeroState,
    action: ExternalAssetsSuccess
  ) => {
    const { image, quote } = action.payload.inboxZero
    return { image, quote }
  },
})

export default combineReducers<_, State>({ inboxZero })
