import get from 'lodash/get'
import React, { useMemo } from 'react'
import { AttachmentTabListItem as LargeAttachmentTabListItem } from 'common/AttachmentItem/LargeAttachmentItem'
import { AttachmentTabListItem as NormalAttachmentTabListItem } from 'common/AttachmentItem/NormalAttachmentItem'
import AttachmentTabListItem from '@edison/webmail-ui/components/AttachmentTabList/Item'
import { useSelector } from 'react-redux'
import { isCurrentUser } from 'core/custom-domains/selectors'

export default ({ attachments, index }) => {
  const attachment = attachments[index]
  const fromEmail = get(attachment, 'extendInfo.from[0].email', '')

  const fromMe = useSelector(
    useMemo(() => isCurrentUser(fromEmail), [fromEmail])
  )

  if (!attachment) return <AttachmentTabListItem />
  if (attachment.type === 'large') {
    return (
      <LargeAttachmentTabListItem
        attachments={attachments}
        index={index}
        fromMe={fromMe}
      />
    )
  }
  if (attachment.type === 'normal')
    return (
      <NormalAttachmentTabListItem
        attachments={attachments}
        index={index}
        fromMe={fromMe}
      />
    )
  return (
    <AttachmentTabListItem
      attachments={attachments}
      index={index}
      fromMe={fromMe}
    />
  )
}
