// @flow
import uuid from 'uuid/v4'
import i18next from 'i18next'
import head from 'lodash/head'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import qs from 'qs'
import { generatePath } from 'react-router-dom'
import { MfaError, InvalidMfaCodeError } from 'utils/errors'
import * as client from '@edison/webmail-core/api/auth'
import { validateRecovery } from '@edison/webmail-core/api/mfa'
import * as customDomains from '@edison/webmail-core/api/custom-domains'
import Castle from '@edison/webmail-core/castle'
import { getCaptchaTokenV2 } from '@edison/webmail-core/captcha'
import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'
import { show as showModal, hide as hideModal } from 'core/modals/actions'
import {
  kochavaEventsLoginSuccess,
  kochavaEventsSignUpSuccess,
} from 'core/kochava/actions'
import { showNotification, cleanUp as cleanUpToasts } from 'core/toasts/actions'
import { subaccountSignupActions } from 'core/custom-domains/actions'
import { getOnmailDomain } from 'core/custom-domains/selectors'
import { getAuth, getInvite, getSessionAccounts } from './selectors'
import {
  getCsrfToken,
  persistCsrfToken,
  clearCsrfToken,
  persistUser,
  persistUsers,
  markLogout,
  getUser,
  clearUserData,
  clearAllUsersData,
  clearUser,
  persistSuperSessionId,
  getSuperSessionId,
} from 'common/storage'
import * as analytics from 'core/analytics'

import { convertToSignUpRecoveryMethod } from 'utils'
import {
  routePaths,
  authStatus,
  AUTH_HEADER,
  CSRF_HEADER,
  DOMAIN_TICKET_HEADER,
  DOMAIN_HEADER,
  ANALYTICS_HEADER,
  SUPER_SESSION_ID_HEADER,
  ORDER_ID_HEADER,
  modalTypes,
} from 'utils/constants'
import { reportException } from 'utils/logs'

import type { ExtraAuth } from '@edison/webmail-core/types'
import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  AuthLoginRequest,
  AuthLoginSuccess,
  AuthLoginFailure,
  AuthSignupRequest,
  AuthSignupSuccess,
  AuthSignupFailure,
  AuthUpdateRequest,
  AuthUpdateSuccess,
  AuthUpdateFailure,
  AuthCheckUsernameRequest,
  AuthCheckUsernameSuccess,
  AuthCheckUsernameFailure,
  AuthVerifyRequest,
  AuthVerifySuccess,
  AuthVerifyFailure,
  AuthVerifyInvitationRequest,
  AuthVerifyInvitationSuccess,
  AuthVerifyInvitationFailure,
  AuthLogout,
  AuthLoaded,
  AuthRefreshRequest,
  AuthRefreshSuccess,
  AuthRefreshFailure,
  DeleteAccountRequest,
  DeleteAccountSuccess,
  DeleteAccountFailure,
  GetSessionRequest,
  GetSessionSuccess,
  GetSessionFailure,
  CreateAppPasswordRequest,
  CreateAppPasswordSuccess,
  CreateAppPasswordFailure,
  ListAppPasswordsRequest,
  ListAppPasswordsSuccess,
  ListAppPasswordsFailure,
  RemoveAppPasswordRequest,
  RemoveAppPasswordSuccess,
  RemoveAppPasswordFailure,
  PasswordAuthRequest,
  PasswordAuthSuccess,
  PasswordAuthFailure,
  AuthResetPasswordRequest,
  AuthResetPasswordSuccess,
  AuthResetPasswordFailure,
} from './types'

export const loginActions: {
  request: ActionCreator<AuthLoginRequest>,
  success: ActionCreator<AuthLoginSuccess>,
  failure: ActionCreator<AuthLoginFailure>,
} = {
  request: createAction('AUTH_LOGIN_REQUEST'),
  success: createAction('AUTH_LOGIN_SUCCESS'),
  failure: createAction('AUTH_LOGIN_FAILURE'),
}

export const logoutAction: ActionCreator<AuthLogout> = createAction(
  'AUTH_LOGOUT'
)
export const authLoaded: ActionCreator<AuthLoaded> = createAction('AUTH_LOADED')

export function login(
  email: string,
  password: string,
  {
    redirect = true,
    mfaCode,
    recoveryCode,
  }: { redirect: boolean, mfaCode: string, recoveryCode: string } = {}
): ThunkAction {
  return async (dispatch, getState, extras) => {
    dispatch(loginActions.request({}))
    try {
      email = email.trim().toLowerCase()

      const superSessionId = getSuperSessionId()

      let res
      if (recoveryCode) {
        res = await validateRecovery(email, password, recoveryCode)
      } else {
        res = await client.login({
          email,
          password,
          superSessionId,
          mfaCode,
        })
      }

      const auth = {
        user: email,
        token: res.getHeader(AUTH_HEADER),
      }

      const orderId = res.getHeader(ORDER_ID_HEADER)
      const sessionId = res.getHeader(SUPER_SESSION_ID_HEADER)
      persistCsrfToken(orderId, res.getHeader(CSRF_HEADER))
      persistSuperSessionId(sessionId)
      persistUser(orderId, email)

      let domain = res.getHeader(DOMAIN_HEADER)
      const domainTicket = res.getHeader(DOMAIN_TICKET_HEADER)

      if (redirect && !isNil(domain) && !isNil(domainTicket)) {
        if (process.env.NODE_ENV !== 'production') {
          // For development purposes
          domain = 'localhost:4000'
        }

        const params = qs.stringify({
          email,
          orderId,
          sessionId,
          ticket: domainTicket,
        })

        // Redirect to custom URL - if custom domain URL is active
        const url = `https://${domain}${routePaths.domainLogin}?${params}`

        console.log('Redirecting user to custom URL with ticket', url)
        window.location.replace(url)
        return
      }

      const analyticsId = res.getHeader(ANALYTICS_HEADER)
      dispatch(analytics.actions.onboarding.setUserId(analyticsId))

      dispatch(cleanUpToasts())
      dispatch(loginActions.success(auth))
      dispatch(kochavaEventsLoginSuccess())
      if (redirect) {
        // Redirect user into the new inbox
        window.location.replace(
          generatePath(routePaths.main, {
            label: 'inbox',
            userId: orderId,
          })
        )
      }

      return true
    } catch (e) {
      if (e.status === 401) {
        if (mfaCode !== undefined || recoveryCode !== undefined) {
          throw new InvalidMfaCodeError(e.message)
        }
        dispatch(
          loginActions.failure({ message: i18next.t('wrongCredentials') })
        )
        dispatch(
          showNotification(i18next.t('wrongCredentials'), toastVariants.error)
        )
      } else if (e.status === 403) {
        dispatch(loginActions.failure({ message: i18next.t('lockout') }))
        dispatch(showNotification(i18next.t('lockout'), toastVariants.error))
      } else if (e.status === 309) {
        throw new MfaError(
          e.message,
          e.headers.get('x-mfa-token'),
          e.headers.get('x-mfa-digits'),
          e.headers.get('x-mfa-method')
        )
      } else {
        dispatch(loginActions.failure({ message: i18next.t('serverError') }))
        dispatch(
          showNotification(i18next.t('serverError'), toastVariants.error)
        )
        reportException(
          e,
          {
            message: 'User login failed',
          },
          true
        )
      }

      return false
    }
  }
}

export function ticketLogin(
  ticket: string,
  email: string,
  { sessionId }: { sessionId?: string } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    let resp = {
      success: false,
      orderId: '',
    }

    try {
      email = email.trim().toLowerCase()
      dispatch(loginActions.request({}))

      const res = await customDomains.ticketLogin(ticket, { sessionId })
      const auth = {
        user: email,
        token: res.getHeader(AUTH_HEADER),
      }
      const orderId = res.getHeader(ORDER_ID_HEADER)
      persistSuperSessionId(res.getHeader(SUPER_SESSION_ID_HEADER))
      persistCsrfToken(orderId, res.getHeader(CSRF_HEADER))
      persistUser(orderId, email)

      const analyticsId = res.getHeader(ANALYTICS_HEADER)
      dispatch(analytics.actions.onboarding.setUserId(analyticsId))

      dispatch(loginActions.success(auth))
      dispatch(kochavaEventsLoginSuccess(email))
      return {
        ...resp,
        orderId,
        success: true,
      }
    } catch (e) {
      if (e.status === 401) {
        dispatch(
          loginActions.failure({ message: i18next.t('wrongCredentials') })
        )
        dispatch(
          showNotification(i18next.t('wrongCredentials'), toastVariants.error)
        )
      } else {
        dispatch(loginActions.failure({ message: i18next.t('serverError') }))
        dispatch(
          showNotification(i18next.t('serverError'), toastVariants.error)
        )
        reportException(
          e,
          {
            message: 'User ticket login failed',
          },
          true
        )
      }
      return resp
    }
  }
}

export const signupActions: {
  request: ActionCreator<AuthSignupRequest>,
  success: ActionCreator<AuthSignupSuccess>,
  failure: ActionCreator<AuthSignupFailure>,
} = {
  request: createAction('AUTH_SIGNUP_REQUEST'),
  success: createAction('AUTH_SIGNUP_SUCCESS'),
  failure: createAction('AUTH_SIGNUP_FAILURE'),
}

export function signup(
  email: string,
  password: string,
  recoveryMethod: Object,
  firstName: string,
  lastName: string,
  birthday: string,
  tnc: boolean,
  optIn: boolean
): ThunkAction {
  return async (dispatch, getState, extras) => {
    const onmailDomain = getOnmailDomain(getState())

    try {
      email = email.trim().toLowerCase()
      dispatch(signupActions.request({ email }))

      let captcha = {}
      try {
        captcha = await getCaptchaTokenV2()
        dispatch(analytics.actions.onboarding.userCaptcha(email, true))
      } catch (e) {
        dispatch(analytics.actions.onboarding.userCaptcha(email, false))
        throw e
      }

      let castle: string = ''
      try {
        castle = await Castle.getRequestToken()
      } catch (e) {}

      const params = {
        email,
        password,
        castle,
        captcha: captcha.token,
        recoveryMethod: convertToSignUpRecoveryMethod(recoveryMethod),
        firstName,
        lastName,
        birthday,
        tnc,
        optIn,
      }

      // Invalidate invite code
      const invite = getInvite(getState())
      if (invite) {
        const invalidated = await client.invalidateInvitation(invite)
        if (!invalidated) {
          throw new Error('Invalid invitation code.')
        }
      }

      const res = await client.signup(params)
      const authToken = res.getHeader(AUTH_HEADER)
      const orderId = res.getHeader(ORDER_ID_HEADER)
      const fullEmail = `${email}@${onmailDomain}`.toLowerCase()
      persistSuperSessionId(res.getHeader(SUPER_SESSION_ID_HEADER))
      persistCsrfToken(orderId, res.getHeader(CSRF_HEADER))
      persistUser(orderId, fullEmail)

      dispatch(
        signupActions.success({
          user: fullEmail,
          token: authToken,
        })
      )

      dispatch(kochavaEventsSignUpSuccess())

      const analyticsId = res.getHeader(ANALYTICS_HEADER)
      dispatch(analytics.actions.onboarding.setUserId(analyticsId))

      return {
        orderId,
      }
    } catch (e) {
      dispatch(signupActions.failure({ message: e.message }))

      let message
      switch (e.status) {
        case 403:
          message = i18next.t('auth.signup.recaptcha.failure')
          break
        default:
          message = i18next.t('auth.signup.failure')
      }

      dispatch(showNotification(message, toastVariants.error))

      throw e
    }
  }
}

export function subaccountSignup(
  token: string,
  password: string,
  recoveryMethod: Object,
  firstName: string,
  lastName: string,
  birthday: string,
  tnc: boolean,
  optIn: boolean
): ThunkAction {
  return async (dispatch, getState) => {
    let resp = {
      success: false,
      orderId: undefined,
    }
    try {
      dispatch(subaccountSignupActions.request())

      let captcha = {}
      try {
        captcha = await getCaptchaTokenV2()
        dispatch(analytics.actions.onboarding.userCaptcha('subaccount', true))
      } catch (e) {
        dispatch(analytics.actions.onboarding.userCaptcha('subaccount', false))
        throw e
      }

      const params = {
        token,
        password,
        captcha: captcha.token,
        recoveryMethod: convertToSignUpRecoveryMethod(recoveryMethod),
        firstName,
        lastName,
        birthday,
        tnc,
        optIn,
      }

      const res = await customDomains.subaccountSignup(params)
      const authEmail = res.result.email.trim().toLowerCase()
      const authToken = res.getHeader(AUTH_HEADER)
      const orderId = res.getHeader(ORDER_ID_HEADER)
      persistSuperSessionId(res.getHeader(SUPER_SESSION_ID_HEADER))
      persistCsrfToken(orderId, res.getHeader(CSRF_HEADER))
      persistUser(orderId, authEmail)

      dispatch(
        subaccountSignupActions.success({
          email: authEmail,
          token: authToken,
        })
      )

      const analyticsId = res.getHeader(ANALYTICS_HEADER)
      dispatch(analytics.actions.onboarding.setUserId(analyticsId))

      return {
        ...resp,
        orderId,
        success: true,
      }
    } catch (e) {
      dispatch(subaccountSignupActions.failure({ message: e.message }))

      let message
      switch (e.status) {
        case 403:
          message = i18next.t('auth.signup.recaptcha.failure')
          break
        default:
          message = i18next.t('customDomain.signup.invalidInvite')
      }
      dispatch(showNotification(message, toastVariants.error))

      return {
        ...resp,
        success: false,
      }
    }
  }
}

type Referer = {|
  url: string,
  params: {|
    orderId: string,
  |},
|}

export function logout(orderId: string, referer?: Referer): ThunkAction {
  return async (dispatch, getState, extras) => {
    try {
      dispatch(logoutAction())

      await dispatch(refreshToken({ orderId }))
      const auth = getAuth()(getState())

      const superSessionId = getSuperSessionId()
      if (auth !== null && !!superSessionId) {
        await client.logout(orderId, { auth, superSessionId })
      }

      // Remove CSRF token for the order ID we logged out of
      clearUserData(orderId)

      const redirected = await dispatch(goToNextActiveSession(orderId, referer))
      if (!redirected) {
        // No more auth session left
        clearAllUsersData()
        window.location.replace(routePaths.login)
      }
    } catch (e) {
      if (e.status !== 401) {
        console.error('User session not cleared in backend')
      }
    } finally {
      markLogout(orderId)
    }
  }
}

export function logoutAll(): ThunkAction {
  return async (dispatch, getState, extras) => {
    const auth = getAuth()(getState())
    try {
      dispatch(logoutAction())
      const superSessionId = getSuperSessionId()

      clearAllUsersData()
      if (auth !== null && superSessionId) {
        await client.sessionLogout(superSessionId, { auth })
      }
    } catch (e) {
      if (e.status !== 401) {
        console.error('User session not cleared in backend')
      }
    } finally {
      markLogout()
      window.location.replace(routePaths.login)
    }
  }
}

// Redirects user into the next active session given the current order ID
export function goToNextActiveSession(
  orderId: string,
  referer?: Referer
): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(getSession())
    const sessionAccounts = getSessionAccounts(getState())
    const remainingAccounts = sessionAccounts.filter(
      account => account.orderId !== orderId
    )
    const hasActiveSessions = remainingAccounts.length > 0

    // Need to make sure the token is clear in frontend
    if (hasActiveSessions) {
      let account, nextUrl

      // Redirect back to previous URL if it exists
      if (referer) {
        account = remainingAccounts.find(
          account => account.orderId === referer.params.orderId
        )

        if (account) {
          nextUrl = referer.url
        }
      }

      // Switch to the next available auth session
      if (!account) {
        account = head(remainingAccounts)
        nextUrl = generatePath(routePaths.main, {
          label: 'inbox',
          userId: account.orderId,
        })
      }

      window.location.replace(nextUrl)

      return true
    } else {
      return false
    }
  }
}

export const updateActions: {
  request: ActionCreator<AuthUpdateRequest>,
  success: ActionCreator<AuthUpdateSuccess>,
  failure: ActionCreator<AuthUpdateFailure>,
} = {
  request: createAction('AUTH_UPDATE_REQUEST'),
  success: createAction('AUTH_UPDATE_SUCCESS'),
  failure: createAction('AUTH_UPDATE_FAILURE'),
}

export function update(oldPassword: string, newPassword: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(updateActions.failure({ message: 'User not logged in' }))
      dispatch(
        showNotification(i18next.t('notAuthenticated'), toastVariants.error)
      )
      return
    }

    try {
      const superSessionId = getSuperSessionId()
      dispatch(updateActions.request())
      // Only return the new CSRF token and refresh token
      const res = await client.updatePassword({
        auth: auth,
        oldPassword,
        newPassword,
        superSessionId,
      })
      const orderId = res.getHeader(ORDER_ID_HEADER)
      persistCsrfToken(orderId, res.getHeader(CSRF_HEADER))
      // Refresh the current auth
      await dispatch(refreshToken({ orderId }))

      dispatch(updateActions.success())
      dispatch(hideModal({ key: modalTypes.setPassword }))
      dispatch(
        showNotification(
          i18next.t('settings.recovery.password.success'),
          toastVariants.success
        )
      )
    } catch (e) {
      if (e.status === 401) {
        dispatch(
          showNotification(i18next.t('incorrectPassword'), toastVariants.error)
        )
      } else {
        dispatch(
          showNotification(
            i18next.t(`settings.recovery.password.failure`),
            toastVariants.error
          )
        )
      }
      dispatch(updateActions.failure({ message: e.message }))
    }
  }
}

export const checkUsernameActions: {
  request: ActionCreator<AuthCheckUsernameRequest>,
  success: ActionCreator<AuthCheckUsernameSuccess>,
  failure: ActionCreator<AuthCheckUsernameFailure>,
} = {
  request: createAction('AUTH_CHECK_USERNAME_REQUEST'),
  success: createAction('AUTH_CHECK_USERNAME_SUCCESS'),
  failure: createAction('AUTH_CHECK_USERNAME_FAILURE'),
}

export function checkUsername(username: string): ThunkAction {
  return async (dispatch, getState) => {
    const actionMeta = {
      request: {
        id: uuid(),
      },
    }
    dispatch(checkUsernameActions.request(undefined, actionMeta))

    try {
      await client.isUsernameAvailable({ username })
      dispatch(checkUsernameActions.success(undefined, actionMeta))
    } catch (e) {
      dispatch(checkUsernameActions.failure(undefined, actionMeta))
    }
  }
}

export const verifyActions: {
  request: ActionCreator<AuthVerifyRequest>,
  success: ActionCreator<AuthVerifySuccess>,
  failure: ActionCreator<AuthVerifyFailure>,
} = {
  request: createAction('AUTH_VERIFY_REQUEST'),
  success: createAction('AUTH_VERIFY_SUCCESS'),
  failure: createAction('AUTH_VERIFY_FAILURE'),
}

export function verify(token: string): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(verifyActions.request())
    try {
      await client.verify({ token })
      dispatch(verifyActions.success())
      dispatch(
        showNotification(
          i18next.t('auth.verifyEmailSuccess'),
          toastVariants.success
        )
      )
    } catch (e) {
      dispatch(
        verifyActions.failure({
          message: i18next.t('auth.verifyEmailFailure'),
        })
      )
      dispatch(
        showNotification(
          i18next.t('auth.verifyEmailFailure'),
          toastVariants.error
        )
      )
    }
  }
}

export const verifyInvitationActions: {
  request: ActionCreator<AuthVerifyInvitationRequest>,
  success: ActionCreator<AuthVerifyInvitationSuccess>,
  failure: ActionCreator<AuthVerifyInvitationFailure>,
} = {
  request: createAction('AUTH_VERIFY_INVITATION_REQUEST'),
  success: createAction('AUTH_VERIFY_INVITATION_SUCCESS'),
  failure: createAction('AUTH_VERIFY_INVITATION_FAILURE'),
}

export function verifyInvitation(code: string): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(verifyInvitationActions.request())
    try {
      const res = await client.verifyInvitation({ code })

      // Invalid code here
      if (res.codeStatus !== 'valid') {
        throw new Error()
      }

      dispatch(
        verifyInvitationActions.success({
          invitation: res,
        })
      )

      return true
    } catch (e) {
      const message = i18next.t('auth.verifyInvitationFailure')
      dispatch(verifyInvitationActions.failure({ message }))
      dispatch(showNotification(message, toastVariants.error))

      return false
    }
  }
}

export const refreshTokenActions: {
  request: ActionCreator<AuthRefreshRequest>,
  success: ActionCreator<AuthRefreshSuccess>,
  failure: ActionCreator<AuthRefreshFailure>,
} = {
  request: createAction('AUTH_REFRESH_REQUEST'),
  success: createAction('AUTH_REFRESH_SUCCESS'),
  failure: createAction('AUTH_REFRESH_FAILURE'),
}

export function refreshToken({
  orderId,
}: { orderId: string } = {}): ThunkAction {
  return async (dispatch, getState) => {
    let resp = {
      success: false,
      code: authStatus.ERROR,
    }

    dispatch(refreshTokenActions.request())

    const user = getUser(orderId)
    const csrfToken = getCsrfToken(orderId)
    if (isNil(csrfToken) || isNil(user)) {
      // Skip if CSRF token or user do not exist
      dispatch(
        refreshTokenActions.failure({ message: 'CSRF or user do not exist' })
      )
      return {
        ...resp,
        code: authStatus.INVALID,
      }
    }

    try {
      const res = await client.refresh(csrfToken, orderId)
      const { token, analyticsId } = res

      dispatch(analytics.actions.onboarding.setUserId(analyticsId))
      dispatch(refreshTokenActions.success({ user, token }))
      return {
        ...resp,
        success: true,
        code: authStatus.VALID,
      }
    } catch (e) {
      dispatch(refreshTokenActions.failure({ message: e.message }))
      if (e.status === 401) {
        return {
          ...resp,
          code: authStatus.INVALID,
        }
      }
      return resp
    }
  }
}

export const deleteAccountActions: {
  request: ActionCreator<DeleteAccountRequest>,
  success: ActionCreator<DeleteAccountSuccess>,
  failure: ActionCreator<DeleteAccountFailure>,
} = {
  request: createAction('DELETE_ACCOUNT_REQUEST'),
  success: createAction('DELETE_ACCOUNT_SUCCESS'),
  failure: createAction('DELETE_ACCOUNT_FAILURE'),
}

export function deleteAccount(
  email: string,
  orderId: string,
  { auth }: { auth: ExtraAuth }
): ThunkAction {
  return async (dispatch, getState) => {
    if (isNil(auth)) {
      dispatch(
        deleteAccountActions.failure({ message: i18next.t('notAuthenticated') })
      )
      return false
    }

    try {
      dispatch(deleteAccountActions.request())
      await client.deleteAccount(email, { auth })

      clearUser(orderId)
      clearCsrfToken(orderId)

      dispatch(deleteAccountActions.success())
      return true
    } catch (e) {
      dispatch(deleteAccountActions.failure({ message: e.message }))
      dispatch(
        showNotification(
          i18next.t('deleteAccount.failure.message'),
          toastVariants.error
        )
      )
      return false
    }
  }
}

export const getSessionActions: {
  request: ActionCreator<GetSessionRequest>,
  success: ActionCreator<GetSessionSuccess>,
  failure: ActionCreator<GetSessionFailure>,
} = {
  request: createAction('GET_SESSION_REQUEST'),
  success: createAction('GET_SESSION_SUCCESS'),
  failure: createAction('GET_SESSION_FAILURE'),
}

export function getSession(): ThunkAction {
  return async (dispatch, getState) => {
    try {
      const sessionId = getSuperSessionId()
      dispatch(getSessionActions.request())
      const res = await client.getSession(sessionId)

      const session = res.result

      // Replace the saved users with the latest
      const users = values(session).reduce(
        (prev, curr) => ({
          ...prev,
          [curr.orderId]: curr.emailAddress,
        }),
        {}
      )
      persistUsers(users)

      dispatch(getSessionActions.success({ session }))
      return true
    } catch (e) {
      dispatch(getSessionActions.failure({ message: e.message }))
      return false
    }
  }
}

export const listAppPasswordsActions: {
  request: ActionCreator<ListAppPasswordsRequest>,
  success: ActionCreator<ListAppPasswordsSuccess>,
  failure: ActionCreator<ListAppPasswordsFailure>,
} = {
  request: createAction('LIST_APP_PASSWORDS_REQUEST'),
  success: createAction('LIST_APP_PASSWORDS_SUCCESS'),
  failure: createAction('LIST_APP_PASSWORDS_FAILURE'),
}

export function listAppPasswords(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        listAppPasswordsActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(listAppPasswordsActions.request())
      const res = await client.listASP({ auth })
      const { passwords } = res.result

      dispatch(listAppPasswordsActions.success(passwords))
    } catch (e) {
      dispatch(listAppPasswordsActions.failure({ message: e.message }))
    }
  }
}

export const createAppPasswordActions: {
  request: ActionCreator<CreateAppPasswordRequest>,
  success: ActionCreator<CreateAppPasswordSuccess>,
  failure: ActionCreator<CreateAppPasswordFailure>,
} = {
  request: createAction('CREATE_APP_PASSWORD_REQUEST'),
  success: createAction('CREATE_APP_PASSWORD_SUCCESS'),
  failure: createAction('CREATE_APP_PASSWORD_FAILURE'),
}

export function createAppPassword(
  name: string,
  { auth }: { auth: ?ExtraAuth }
): ThunkAction {
  return async dispatch => {
    if (isNil(auth)) {
      dispatch(
        createAppPasswordActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(createAppPasswordActions.request())
      const res = await client.createASP(name, { auth })
      const { password } = res.result

      dispatch(listAppPasswords())
      dispatch(createAppPasswordActions.success())

      return { name, value: password }
    } catch (e) {
      showNotification(i18next.t('asp.error.create'), toastVariants.error)
      dispatch(createAppPasswordActions.failure({ message: e.message }))
      return
    }
  }
}

export const removeAppPasswordActions: {
  request: ActionCreator<RemoveAppPasswordRequest>,
  success: ActionCreator<RemoveAppPasswordSuccess>,
  failure: ActionCreator<RemoveAppPasswordFailure>,
} = {
  request: createAction('REMOVE_APP_PASSWORD_REQUEST'),
  success: createAction('REMOVE_APP_PASSWORD_SUCCESS'),
  failure: createAction('REMOVE_APP_PASSWORD_FAILURE'),
}

export function removeAppPassword(id: string): ThunkAction {
  return async dispatch => {
    const auth = await dispatch(getPasswordAuth())

    if (isNil(auth)) {
      dispatch(
        createAppPasswordActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(removeAppPasswordActions.request())
      await client.removeASP(id, { auth })

      dispatch(removeAppPasswordActions.success({ id }))
    } catch (e) {
      dispatch(
        showNotification(i18next.t('asp.error.delete'), toastVariants.error)
      )
      dispatch(removeAppPasswordActions.failure({ message: e.message }))
    }
  }
}

export const passwordAuthActions: {
  request: ActionCreator<PasswordAuthRequest>,
  success: ActionCreator<PasswordAuthSuccess>,
  failure: ActionCreator<PasswordAuthFailure>,
} = {
  request: createAction('PASSWORD_AUTH_REQUEST'),
  success: createAction('PASSWORD_AUTH_SUCCESS'),
  failure: createAction('PASSWORD_AUTH_FAILURE'),
}

export function grantPasswordAuth(password: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        passwordAuthActions.failure({ message: i18next.t('notAuthenticated') })
      )
      return
    }

    try {
      dispatch(passwordAuthActions.request())
      const res = await client.passwordAuth(password, { auth })
      const extraAuth: ExtraAuth = { token: res.token }

      dispatch(passwordAuthActions.success())
      return extraAuth
    } catch (e) {
      if (e.status === 401) {
        dispatch(
          showNotification(i18next.t('wrongCredentials'), toastVariants.error)
        )
      } else {
        dispatch(loginActions.failure({ message: i18next.t('serverError') }))
      }
      dispatch(passwordAuthActions.failure({ message: e.message }))
      return
    }
  }
}

export function getPasswordAuth(): ThunkAction {
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(
        showModal({
          key: modalTypes.passwordAuth,
          props: {
            onConfirm: resolve,
            onClose: resolve,
          },
        })
      )
    })
  }
}

export const resetPasswordActions: {
  request: ActionCreator<AuthResetPasswordRequest>,
  success: ActionCreator<AuthResetPasswordSuccess>,
  failure: ActionCreator<AuthResetPasswordFailure>,
} = {
  request: createAction('AUTH_RESET_PASSWORD_REQUEST'),
  success: createAction('AUTH_RESET_PASSWORD_SUCCESS'),
  failure: createAction('AUTH_RESET_PASSWORD_FAILURE'),
}

export function resetPassword(token: string, password: string): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(resetPasswordActions.request())
    try {
      await client.resetPassword({ password, token })
      dispatch(resetPasswordActions.success())
      dispatch(
        showNotification(
          i18next.t('resetPassword.success'),
          toastVariants.success
        )
      )
      return true
    } catch (e) {
      dispatch(resetPasswordActions.failure({ message: e.message }))
      dispatch(
        showNotification(
          i18next.t('resetPassword.failure'),
          toastVariants.error
        )
      )
      return false
    }
  }
}
