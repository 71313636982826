// @flow
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import flatten from 'lodash/flatten'
import toPairs from 'lodash/toPairs'
import mapValues from 'lodash/mapValues'
import difference from 'lodash/difference'
import { useMemo, useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLoadingToast } from 'common/toasts'

import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import {
  labelNames,
  modalTypes,
  routePaths,
  siftLabelsGroup,
  inboxActon,
  archiveAction,
  sentAction,
  draftsAction,
  unreadAction,
  trashAction,
  spamAction,
  otherAction,
  labelNameMapAction,
} from 'utils/constants'
import * as actions from 'core/threads/actions'
import * as selectors from 'core/threads/selectors'
import * as searchSelectors from 'core/search/selectors'
import {
  useIsInbox,
  useIsOnlyQueryDraftLabel,
  useHasQuerySpamLabel,
} from 'core/search/hooks'
import * as metadataSelectors from 'core/metadata/selectors'
import { getThreadsLabelIds } from 'core/metadata/selectors'
import * as analytics from 'core/analytics/actions'
import { labelActions } from 'core/metadata/actions'
import { labelOperations } from 'core/metadata/constants'
import {
  markAllDeleted,
  batchDeleteThreads,
  batchDeleteTrashMessages,
} from 'core/metadata/actions'
import { batchDeleteDrafts } from 'core/compose/actions'
import { useRouteLabel } from 'core/labels/hooks'
import { actions as modalActions } from 'core/modals'
import type { Node } from 'react'
import type { Dispatch } from 'types/redux'
import type { Thread } from '@edison/webmail-core/types/threads'
import type { Recipient } from '@edison/webmail-core/types/messages'
import type { ThreadAction } from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import { getSearchActionNodes } from './helpers'
import { getSelectedThreadIds } from './selectors'
import { useCache } from 'core/search/hooks'
import { useSortedSplitInboxes } from 'core/split-inboxes/hooks'

export type ThreadActionObject = {
  icon: Node,
  title: string,
  name: string,
  onClick: () => void,
}
/**
 * Returns a hook that encapsulates logic for performing actions on a single
 * thread.
 *
 * @public
 * @returns {function} Takes in a thread item, and returns the associated
 *  action handlers
 */
export function useThreadActions({
  debounced = true,
}: { debounced: boolean } = {}): ({ id: string }) => {
  [key: ThreadAction]: ThreadActionObject,
} {
  const dispatch: Dispatch = useDispatch()
  const selectedThreadIds = useSelector(getSelectedThreadIds())
  const activeLabel = useActiveLabel()
  const { clearViewEmails } = useCache()
  const wrap = f => () => {
    f()
  }

  const handlers = ({ id, ...rest }: { id: string }) => ({
    archive: wrap(() => {
      dispatch(labelActions.archive.thread(id))
    }),
    unarchive: wrap(() => {
      dispatch(labelActions.unarchive.thread(id))
    }),
    read: wrap(() => {
      dispatch(labelActions.read.thread(id))
    }),
    unread: wrap(() => {
      dispatch(labelActions.unread.thread(id))
    }),
    trash: wrap(() => {
      dispatch(labelActions.trash.thread(id))
    }),
    untrash: wrap(() => {
      dispatch(labelActions.untrash.thread(id))
    }),
    deleteForever: wrap(() => {
      const deleteAction = getDeleteAction(activeLabel)
      clearViewEmails([id])
      dispatch(deleteAction([id]))
    }),
    notSpam: wrap(() => {
      dispatch(labelActions.notSpam.thread(id))
    }),
    markAsSpam: wrap(() => {
      dispatch(labelActions.markAsSpam.thread(id))
    }),
    discardDraft: wrap(() => {
      dispatch(batchDeleteDrafts([id]))
    }),
  })

  return thread =>
    mapValues(handlers(thread), (onClick, key) => ({
      ...actionNodes[key],
      onClick: () => {
        const { id } = thread || {}
        onClick()
        const isOnlyOneSelectedThread = selectedThreadIds.size === 1
        // If only one selected thread, directly resetSelectThread
        if (isOnlyOneSelectedThread && id && selectedThreadIds.has(id)) {
          dispatch(actions.resetSelectThread())
        }
        dispatch(analytics.threadActions.userThreadAction(key))
      },
    }))
}

/**
 * Returns a mapping which the key is the removing label
 * and the value is the corresponding action
 */
export function useLabelRemoveActions({
  debounced = true,
}: {
  debounced: boolean,
} = {}): ({ id: string }) => {
  [key: string]: {
    icon: Node,
    title: string,
    name: string,
    onClick: () => void,
  },
} {
  const getThreadActions = useThreadActions({ debounced })

  return thread => {
    const threadActions = getThreadActions(thread)

    return {
      [labelNames.inbox]: threadActions.archive,
      [labelNames.archive]: threadActions.unarchive,
      [labelNames.trash]: threadActions.untrash,
      [labelNames.spam]: threadActions.notSpam,
      [labelNames.drafts]: threadActions.discardDraft,
    }
  }
}

/**
 * Returns a hook that encapsulates logic for performing actions on a single
 * thread.
 *
 * @public
 * @returns {function} Takes in a thread item, and returns the associated
 *  action handlers
 * @param data
 */
export function useThreadBatchActions(applyToAll?: boolean) {
  const dispatch: Dispatch = useDispatch()
  const loadingToast = useLoadingToast()
  const threadLabelsById = useSelector(metadataSelectors.getThreadLabelIdsMap())
  const selected = useSelector(selectors.getSelectedThreadIds())
  const universeChecked = useSelector(selectors.isSelectedUniverse)
  const uniqueLabels = useSelector(selectors.getSelectedUniqueLabels())
  const activeLabel = useSelector(selectors.getActiveLabel())
  const affectedThreads = useMemo(() => {
    return Array.from(selected)
  }, [selected])
  const { clearViewEmails } = useCache()
  const dispatchRemoveSmartFolder = () => {
    if (!(activeLabel in siftLabelsGroup)) return
    let smartLabels = siftLabelsGroup[activeLabel]

    const removedMappings = {}

    // One smart folder would contain multiple sift labels
    // Need to find each sift label for the selected threads
    for (let threadId of Array.from(selected)) {
      const matched = (threadLabelsById[threadId] || []).find(label =>
        smartLabels.includes(label)
      )

      if (matched) {
        removedMappings[matched] = [
          ...(removedMappings[matched] || []),
          threadId,
        ]
      }
    }

    return Promise.all(
      toPairs(removedMappings).map(([removedLabel, threadIds]) =>
        dispatch(labelActions.update([], [removedLabel]).threads(threadIds))
      )
    )
  }

  const dispatchLabelUpdate = (add, remove) => {
    if (universeChecked || applyToAll) {
      // Apply the action to all the threads in the active label
      return dispatch(
        labelActions.update(add, remove).markAll(activeLabel)
      ).then(success => {
        if (success) return dispatch(actions.fetchThreads(activeLabel))
      })
    } else {
      return dispatch(labelActions.update(add, remove).threads(affectedThreads))
    }
  }

  const dispatchThreadsDeletion = name => {
    if (universeChecked || applyToAll) {
      return dispatch(markAllDeleted(activeLabel)).then(success => {
        if (success) return dispatch(actions.fetchThreads(activeLabel))
      })
    } else if (name === actionNodes.deleteForever.name) {
      const deleteAction = getDeleteAction(activeLabel)
      clearViewEmails(affectedThreads)
      return dispatch(deleteAction(affectedThreads))
    } else if (name === actionNodes.discardDraft.name) {
      return dispatch(batchDeleteDrafts(affectedThreads))
    }

    return Promise.resolve()
  }

  const action = name =>
    wrap(async () => {
      loadingToast.showToast()
      if (
        name === actionNodes.deleteForever.name ||
        name === actionNodes.discardDraft.name
      ) {
        await dispatchThreadsDeletion(name)
      } else {
        // Get the labels to add and remove from a centralized constants file
        const { add, remove } = labelOperations[name]

        await dispatchLabelUpdate(add, remove)
      }
      loadingToast.hideToast()

      // [EWM-1858] De-select all of the threads
      // https://easilydo.atlassian.net/browse/EWM-1858
      dispatch(
        actions.batchSelectThread({ ids: affectedThreads, value: false })
      )

      // Log analytics event
      dispatch(analytics.threadActions.userThreadBatchAction(name))
    })

  const removeLabel = wrap(async () => {
    loadingToast.showToast()
    if (
      [
        labelNames.travel,
        labelNames.billAndReceipts,
        labelNames.packages,
        labelNames.events,
      ].includes(activeLabel)
    ) {
      await dispatchRemoveSmartFolder()
    } else {
      await dispatchLabelUpdate([], [activeLabel])
      dispatch(
        actions.batchSelectThread({ ids: affectedThreads, value: false })
      )
    }
    loadingToast.hideToast()
  })

  const addLabel: (labelIds: $ReadOnlyArray<string>) => mixed = wrap(
    async labelIds => {
      const add = difference(labelIds, uniqueLabels)
      const remove = difference(uniqueLabels, labelIds)

      if (add.length > 0 || remove.length > 0) {
        loadingToast.showToast()
        await dispatchLabelUpdate(add, remove)
        loadingToast.hideToast()

        // [EWM-1858] De-select all of the threads
        // https://easilydo.atlassian.net/browse/EWM-1858
        dispatch(
          actions.batchSelectThread({ ids: affectedThreads, value: false })
        )

        // Log analytics event
        dispatch(analytics.threadActions.userThreadBatchAction('addLabel'))
      }
    }
  )

  const moveToSplit = () => {
    dispatch(
      modalActions.show({
        key: modalTypes.moveToSplitInbox,
        props: {
          selectedThreadIds: affectedThreads,
          isBatchAction: true,
        },
      })
    )
  }

  const basic: { [actionName: ThreadAction]: Function } = [
    actionNodes.archive.name,
    actionNodes.unarchive.name,
    actionNodes.read.name,
    actionNodes.unread.name,
    actionNodes.trash.name,
    actionNodes.untrash.name,
    actionNodes.deleteForever.name,
    actionNodes.notSpam.name,
    actionNodes.markAsSpam.name,
    actionNodes.discardDraft.name,
  ].reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: action(curr),
    }),
    {}
  )

  return {
    ...basic,
    // Actions specific to the thread action
    [actionNodes.moveToSplit.name]: moveToSplit,
    [actionNodes.removeLabel.name]: removeLabel,
    [actionNodes.addLabel.name]: addLabel,
  }
}

export function useThreadListItemActionFlags(splitInboxId: ?string) {
  const activeLabel = useActiveLabel()

  return ({
    id,
    labelIds,
  }: {
    id: string,
    labelIds: $ReadOnlyArray<string>,
  }) => {
    const _labelIds = new Set<string>(labelIds)

    if (activeLabel === labelNames.sent) {
      return { trash: !_labelIds.has(labelNames.trash) }
    }

    if (activeLabel === labelNames.drafts) {
      return { discardDraft: _labelIds.has(labelNames.drafts) }
    }

    return {
      get archive() {
        const isArchived = !_labelIds.has(labelNames.inbox)
        switch (activeLabel) {
          case labelNames.primary:
          case labelNames.other:
            return true
          case labelNames.archive:
            return false
          case labelNames.drafts:
          case labelNames.unread:
            return !isArchived
          case labelNames.sent:
          case labelNames.spam:
          case labelNames.trash:
            return false
          default:
            return !isNil(splitInboxId)
        }
      },
      unarchive: activeLabel === labelNames.archive,
      read: _labelIds.has(labelNames.unread),
      unread: !_labelIds.has(labelNames.unread),
      trash:
        activeLabel !== labelNames.trash && activeLabel !== labelNames.spam,
      untrash: activeLabel === labelNames.trash,
      deleteForever:
        activeLabel === labelNames.trash || activeLabel === labelNames.spam,
      notSpam: activeLabel === labelNames.spam,
    }
  }
}

export function useIsSearchDraftFolder() {
  const activeLabel = useActiveLabel()
  const threadActiveLabel = useSelector(selectors.getActiveLabel())
  return activeLabel === '' && threadActiveLabel === labelNames.drafts
}

export function useSearchActionFlags() {
  const activeLabel = useSelector(selectors.getActiveLabel())
  const isOnlyQueryDraftLabel = useIsOnlyQueryDraftLabel()
  const noAction = useNoActionFlags()
  const isSearchDraftFolder = useIsSearchDraftFolder()
  const hasQuerySpamLabel = useHasQuerySpamLabel()
  return ({
    id,
    labelIds,
  }: {
    id: string,
    labelIds: $ReadOnlyArray<string>,
  }) => {
    const _labelIds = new Set<string>(labelIds)
    // in search draft folder, don't need action
    if (isSearchDraftFolder || isOnlyQueryDraftLabel) {
      return noAction
    }
    return {
      get archive() {
        if (_labelIds.has(labelNames.spam)) {
          return false
        }

        return _labelIds.has(labelNames.inbox)
      },
      get unarchive() {
        if (_labelIds.has(labelNames.inbox)) {
          return false
        }

        if (_labelIds.has(labelNames.spam)) {
          return false
        }

        if (_labelIds.has(labelNames.trash)) {
          return false
        }

        return _labelIds.has(labelNames.archive)
      },
      get read() {
        if (
          _labelIds.has(labelNames.sent) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        if (
          _labelIds.has(labelNames.drafts) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        return _labelIds.has(labelNames.unread)
      },
      get unread() {
        if (
          _labelIds.has(labelNames.sent) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        if (
          _labelIds.has(labelNames.drafts) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        return !_labelIds.has(labelNames.unread)
      },
      get trash() {
        if (
          _labelIds.has(labelNames.drafts) &&
          !_labelIds.has(labelNames.inbox)
        ) {
          return false
        }
        if (
          activeLabel === labelNames.trash ||
          _labelIds.has(labelNames.spam)
        ) {
          return false
        }

        if (
          _labelIds.has(labelNames.trash) &&
          _labelIds.has(labelNames.inbox)
        ) {
          return true
        }

        return !_labelIds.has(labelNames.trash)
      },
      get untrash() {
        if (
          activeLabel !== labelNames.trash ||
          _labelIds.has(labelNames.spam)
        ) {
          return false
        }

        if (_labelIds.has(labelNames.inbox)) {
          return false
        }

        return _labelIds.has(labelNames.trash)
      },
      get deleteForever() {
        if (
          activeLabel !== labelNames.spam &&
          activeLabel !== labelNames.trash &&
          !hasQuerySpamLabel
        ) {
          return false
        }
        if (_labelIds.has(labelNames.spam) || _labelIds.has(labelNames.trash)) {
          return true
        }

        return false
      },
      get markAsSpam() {
        if (
          _labelIds.has(labelNames.sent) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        if (
          _labelIds.has(labelNames.drafts) &&
          !_labelIds.has(labelNames.inbox) &&
          !_labelIds.has(labelNames.archive)
        ) {
          return false
        }

        return !_labelIds.has(labelNames.spam)
      },
      notSpam:
        (activeLabel === labelNames.spam || hasQuerySpamLabel) &&
        _labelIds.has(labelNames.spam),
      // discard draft icon same with trash, no need to add to action
      // discardDraft: _labelIds.has(labelNames.drafts),
    }
  }
}

export function useNoActionFlags() {
  return ({
    id,
    labelIds,
  }: {
    id: string,
    labelIds: $ReadOnlyArray<string>,
  }) => {
    return {
      get archive() {
        return false
      },
      get unarchive() {
        return false
      },
      get read() {
        return false
      },
      get unread() {
        return false
      },
      get trash() {
        return false
      },
      get untrash() {
        return false
      },
      get deleteForever() {
        return false
      },
      get markAsSpam() {
        return false
      },
      notSpam: false,
    }
  }
}

export function useThreadDetailActionFlags(search: boolean = false) {
  return useSearchActionFlags()
}

const selectedThreadsSelector = getSelectedThreadIds()

export function useSearActiveLabel() {
  const activeLabel = useActiveLabel()
  const condition = useSelector(searchSelectors.getSearchCondition())
  const selectedLabels = useSelector(searchSelectors.getSelectedLabels())
  const threadActiveLabel = useSelector(selectors.getActiveLabel())
  const isInbox = useIsInbox()
  // search
  if (!selectedLabels.length && activeLabel === '' && !condition.label.length) {
    return isInbox ? '' : threadActiveLabel
  }
  return activeLabel
}

export function useIsInSearch() {
  const activeLabel = useSearActiveLabel()
  return activeLabel === ''
}

export function useSearchLabelAction() {
  const condition = useSelector(searchSelectors.getSearchCondition())
  const selectedLabels = useSelector(searchSelectors.getSelectedLabels())
  if (!selectedLabels.length && !condition.label.length) {
    return {
      main: [],
      menu: [],
    }
  }
  return [...selectedLabels.map(item => item.id), ...condition.label].reduce(
    (acc, labelName) => {
      if (labelName in labelNameMapAction) {
        const { main, menu } = labelNameMapAction[labelName]
        return {
          main: uniq([...acc.main, ...main]),
          menu: uniq([...acc.menu, ...menu]),
        }
      } else {
        return {
          main: uniq([...acc.main, ...otherAction.main]),
          menu: uniq([...acc.menu, ...otherAction.menu]),
        }
      }
    },
    {
      main: [],
      menu: [],
    }
  )
}

export function useThreadBatchActionFlags(
  splitInboxId: ?string
): {
  main: $ReadOnlyArray<ThreadAction>,
  menu: $ReadOnlyArray<ThreadAction>,
  disabled: $ReadOnlyArray<ThreadAction>,
} {
  const activeLabel = useSearActiveLabel()
  const labelAction = useSearchLabelAction()
  const selected = useSelector(selectedThreadsSelector)
  const resultIds = Array.from(selected)
  const labelIds = useSelector(
    useMemo(() => metadataSelectors.getThreadsLabelIds(resultIds), [resultIds])
  )
  let currentLabel = activeLabel
  if (
    activeLabel === splitInboxId ||
    activeLabel === labelNames.primary ||
    activeLabel === labelNames.other
  ) {
    currentLabel = labelNames.inbox
  }

  switch (currentLabel) {
    case labelNames.inbox:
      return inboxActon
    case labelNames.archive:
      return archiveAction
    case labelNames.sent:
      return sentAction
    case labelNames.drafts:
      return draftsAction
    case labelNames.unread:
      return unreadAction
    case labelNames.trash:
      return trashAction
    case labelNames.spam:
      return spamAction
    case '':
      const actionNodesLabel = getSearchActionNodes(
        labelIds,
        resultIds,
        labelAction
      )
      // Search
      return actionNodesLabel
    default:
      // Smart Folders and custom labels
      return otherAction
  }
}

export function useSenderName(threadId: string): Recipient {
  const sendersById = useSelector(selectors.getSendersById)

  return sendersById[threadId]
}

export function useSenderAvatar(threadId: string): string {
  const sendersById = useSelector(selectors.getSenderAvatarById)

  return sendersById[threadId]
}

export function useActiveLabel(): string {
  const prioritizedLabel = useSelector(searchSelectors.getSearchPriority)
  const threadActiveLabel = useSelector(selectors.getActiveLabel())
  const routeMatch = useRouteMatch(routePaths.main)

  return get(routeMatch, 'params.label') === 'search'
    ? prioritizedLabel
    : threadActiveLabel
}

export function useIsCurrentViewInInbox(): boolean {
  const inboxMatched = useRouteMatch(routePaths.main)
  return (
    inboxMatched &&
    inboxMatched.params &&
    inboxMatched.params.label.toLocaleLowerCase() === 'inbox'
  )
}

// Add common pre and post steps inside here
const wrap = <F: Function>(f: F) => (...args: Array<mixed>) => {
  return f(...args)
}

function getDeleteAction(activeLabel: string) {
  return activeLabel === labelNames.trash
    ? batchDeleteTrashMessages
    : batchDeleteThreads
}

/**
 * Returns a memoried list of fetched thread IDs
 */
export function useActiveLabelThreads() {
  const activeLabel = useRouteLabel()

  const threadIdsSelector = useMemo(
    () => metadataSelectors.getLabelThreads(activeLabel || ''),
    [activeLabel]
  )

  const ids = useSelector(threadIdsSelector)
  const threads = useSelector(selectors.getThreadsById)

  const idsRef = useRef(ids)
  const memoIds = useMemo(() => {
    if (
      idsRef.current.length !== ids.length ||
      difference(idsRef.current, ids).length
    ) {
      return (idsRef.current = ids)
    } else return idsRef.current
  }, [ids])
  const entities = useMemo<$ReadOnlyArray<Thread>>(() => {
    return ids.map(id => threads[id]).filter(Boolean)
  }, [ids, threads])

  return { ids: memoIds, entities }
}

export function useThreadsSplitLabel(threadIds) {
  const splitInboxes = useSortedSplitInboxes()
  const threadsLabelIds = useSelector(getThreadsLabelIds(threadIds))
  const threadSplitLabelIds = flatten(threadsLabelIds).filter(
    labelId =>
      splitInboxes.map(item => item.labelId).includes(labelId) ||
      labelId === labelNames.promotions
  )
  const currentSplitInbox = threadSplitLabelIds.filter(label =>
    threadSplitLabelIds.length > 1
      ? label !== labelNames.promotions && label !== labelNames.primary
      : true
  )
  return currentSplitInbox[0] === labelNames.promotions
    ? labelNames.other
    : currentSplitInbox[0]
}
