import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { imagePreviewSizeOptions } from 'utils/constants'
import ComposeAttachmentItem from '@edison/webmail-ui/components/ComposeAttachmentItem'
import { getAuth } from '../../../../core/auth/selectors'
import { isEnableThumbnailAttachment } from 'utils'
import * as client from '@edison/webmail-core/api'
import { usePreviewModal } from 'core/previews/hooks'

const PreviewAttachmentItem = props => {
  const { message, id } = props

  const [url, setUrl] = useState('')
  const loadedRef = useRef(false)
  const auth = useSelector(useMemo(() => getAuth(), []))
  const previews = usePreviewModal()

  useEffect(() => {
    if (loadedRef.current) return
    if (!message) return
    client.attachments
      .detail(message, id, auth, {
        size: imagePreviewSizeOptions.small,
      })
      .then(url => {
        setUrl(url)
        loadedRef.current = true
      })
  }, [message, id])

  return (
    <>
      <ComposeAttachmentItem
        {...props}
        src={url}
        onClick={() => previews.showModal({ attachments: [props], index: 0 })}
      />
    </>
  )
}

export default props => {
  //new attachment need wait sync message to get type
  const { onRemove, onRetry, ...attachment } = props

  if (!isEnableThumbnailAttachment(attachment)) {
    return <ComposeAttachmentItem {...props} />
  } else {
    return <PreviewAttachmentItem {...props} />
  }
}
