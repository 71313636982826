// @flow
import get from 'lodash/get'
import omit from 'lodash/omit'
import { combineReducers } from 'redux'
import { createReducer } from 'utils/redux'
import { inboxViews } from 'utils/constants'

import {
  fetchSplitInboxesActions,
  createSplitInboxActions,
  updateSplitInboxActions,
  reorderSplitInboxActions,
  deleteSplitInboxActions,
  toggleSplitViewAction,
} from './actions'
import { fetchSettingsActions } from 'core/settings/actions'
import { dragBetweenInboxActions } from 'core/threads/actions'

import type {
  SplitInboxActions,
  SplitInboxListSuccess,
  SplitInboxCreateSuccess,
  SplitInboxUpdateSuccess,
  SplitInboxUpdateOrderSuccess,
  SplitInboxDeleteRequest,
  SplitInboxDeleteSuccess,
  ToggleSplitView,
} from './types'
import type { SettingsSuccess } from 'core/settings/types'
import type { DragBetweenInboxSuccess } from 'core/threads/types'
import type {
  SplitInbox,
  SplitMetas,
} from '@edison/webmail-core/types/split-inboxes'

export type EntityState = {
  [key: string]: SplitInbox,
}

const initialEntityState = {}

const entities = createReducer<EntityState, SplitInboxActions>(
  initialEntityState,
  {
    [fetchSplitInboxesActions.success.toString()]: splitInboxListSuccess,
    [createSplitInboxActions.success.toString()]: splitInboxCreateSuccess,
    [updateSplitInboxActions.success.toString()]: splitInboxUpdateSuccess,
    [deleteSplitInboxActions.request.toString()]: splitInboxDeleteRequest,
    [dragBetweenInboxActions.success.toString()]: dragBetweenInboxSuccess,
  }
)

function splitInboxListSuccess(
  state: EntityState,
  action: SplitInboxListSuccess
) {
  const inboxes = action.payload

  return inboxes.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    state
  )
}

function splitInboxCreateSuccess(
  state: EntityState,
  action: SplitInboxCreateSuccess
) {
  const { label, filter, ...splitInbox } = action.payload
  return {
    ...state,
    [splitInbox.id]: {
      ...splitInbox,
      labelId: label.id,
      filterId: filter.id,
    },
  }
}

function splitInboxUpdateSuccess(
  state: EntityState,
  action: SplitInboxUpdateSuccess
) {
  const { label, filter, ...splitInbox } = action.payload

  return {
    ...state,
    [splitInbox.id]: {
      ...state[splitInbox.id],
      ...splitInbox,
      labelId: label.id,
      filterId: filter.id,
    },
  }
}

function splitInboxDeleteRequest(
  state: EntityState,
  action: SplitInboxDeleteRequest
) {
  const { id } = action.payload

  return omit(state, id)
}

function dragBetweenInboxSuccess(
  state: EntityState,
  action: DragBetweenInboxSuccess
) {
  const { sourceSplitId, targetSplitId, senders } = action.payload
  const sourceSplit = get(state, sourceSplitId)
  const targetSplit = get(state, targetSplitId)
  let nextEntityState = {}

  if (sourceSplit) {
    const { from = [], ...restQuery } = sourceSplit.query
    const nextFrom = from.filter(val => !senders.includes(val))
    const query =
      nextFrom.length > 0 ? { ...restQuery, from: nextFrom } : restQuery

    nextEntityState[sourceSplit.id] = {
      ...sourceSplit,
      query,
    }
  }

  if (targetSplit) {
    const { from = [], ...restQuery } = targetSplit.query
    const nextFrom = new Set([...from, ...senders])

    nextEntityState[targetSplit.id] = {
      ...targetSplit,
      query: {
        ...restQuery,
        from: Array.from(nextFrom),
      },
    }
  }

  return {
    ...state,
    ...nextEntityState,
  }
}

export type MetaState = SplitMetas
const initialMetaState = {}

const meta = createReducer<MetaState, SplitInboxActions>(initialMetaState, {
  [fetchSettingsActions.success.toString()]: init,
  [toggleSplitViewAction.toString()]: toggle,
  [reorderSplitInboxActions.success.toString()]: reorder,
  [createSplitInboxActions.success.toString()]: createMeta,
  [deleteSplitInboxActions.success.toString()]: deleteMeta,
})

function init(state: MetaState, action: SettingsSuccess) {
  const { splitMetas } = action.payload
  return splitMetas
}

function toggle(state: MetaState, action: ToggleSplitView) {
  const { id, view } = action.payload

  if (id in state) {
    return {
      ...state,
      [id]: {
        ...state[id],
        view,
      },
    }
  }

  return state
}

function reorder(state: MetaState, action: SplitInboxUpdateOrderSuccess) {
  const newOrders = action.payload

  let next = { ...state }

  for (let split of newOrders) {
    if (split.id in state) {
      next[split.id] = {
        ...next[split.id],
        idx: split.idx,
      }
    }
  }

  return next
}

function createMeta(state: MetaState, action: SplitInboxCreateSuccess) {
  const { id, idx, name } = action.payload

  return {
    ...state,
    [id]: {
      idx,
      name,
      view: inboxViews.LIST,
    },
  }
}

function deleteMeta(state: MetaState, action: SplitInboxDeleteSuccess) {
  const { id } = action.payload

  return omit(state, id)
}

export type State = {
  entities: EntityState,
  meta: MetaState,
}

export default combineReducers<_, State>({ entities, meta })
