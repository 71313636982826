// @flow
import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import type { State as SnackbarsState } from './types'
import type { State, Selector } from 'types/state'

export const selectSnackbarsState = (state: State) => state.snackbars

export function getSnackbars(): Selector<ModalsState> {
  return createSelector(
    selectSnackbarsState,
    (snackbars: SnackbarsState) => snackbars
  )
}

export function getSnackbarStatus(key: string): Selector<boolean> {
  return createSelector(
    selectSnackbarsState,
    (snackbars: SnackbarsState) => {
      const snackbar = snackbars[key]
      if (isNil(snackbar)) return false
      else return snackbar.isOpen
    }
  )
}
