//@flow
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import Tooltip from '@edison/webmail-ui/components/Tooltip'
import Fab from '@material-ui/core/Fab'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useThreadsSplitLabel } from 'core/threads/hooks'
import { dropThreads, resetSelectThread } from '../../core/threads/actions'
import * as analytics from '../../core/analytics/actions'
import { useSortedSplitInboxes } from '../../core/split-inboxes/hooks'
import { useActiveLabel, useIsInSearch } from '../../core/threads/hooks'
type Props = {
  isOpen: boolean,
  selectedThreadIds: string[],
  isBatchAction: boolean,
  toggle: () => void,
}

const MoveToSplitInboxModal = ({
  isOpen,
  selectedThreadIds,
  isBatchAction,
  toggle,
}: Props) => {
  const dispatch = useDispatch()
  const currentLabelId = useActiveLabel()
  const sortedSplitInboxes = useSortedSplitInboxes()
  const threadsSplitLabel = useThreadsSplitLabel(selectedThreadIds)
  const isInSearch = useIsInSearch()
  const splitInboxes = useMemo(() => {
    return sortedSplitInboxes.filter(split => {
      return isInSearch
        ? split.labelId !== threadsSplitLabel
        : split.labelId !== currentLabelId
    })
  }, [sortedSplitInboxes, currentLabelId, threadsSplitLabel, isInSearch])
  const handleClose = useCallback(() => {
    toggle()
  }, [toggle])
  const onSplitSelected = useCallback(
    (selectedSplitInboxId: string) => {
      dispatch(
        dropThreads({
          from: currentLabelId || threadsSplitLabel,
          to: selectedSplitInboxId,
          selectedThreadIds,
        })
      ).finally(() => {
        // Log analytics event
        if (isBatchAction) {
          dispatch(analytics.threadActions.userThreadBatchAction('moveToSplit'))
        } else {
          dispatch(analytics.threadActions.userThreadAction('moveToSplit'))
        }
        dispatch(resetSelectThread())
        handleClose()
      })
    },
    [handleClose, currentLabelId, selectedThreadIds, isBatchAction]
  )
  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <div className="flex h-full w-full pt-6">
        <div className="flex-shrink-0 flex flex-col items-center w-56">
          <Tooltip title="Back">
            <Fab
              component="div"
              disableRipple
              className="shadow"
              onClick={e => {
                e.stopPropagation()
                handleClose()
              }}
              disableFocusRipple
            >
              <ArrowBackIcon />
            </Fab>
          </Tooltip>
        </div>
        <div className="flex flex-col items-start justify-center text-6xl select-none">
          {splitInboxes.map(split => {
            return (
              <div
                key={split.labelId}
                className="hover:text-primary cursor-pointer font-bold"
                onClick={e => {
                  e.stopPropagation()
                  if (onSplitSelected) {
                    onSplitSelected(split.labelId)
                  }
                }}
              >
                {split.name}
              </div>
            )
          })}
        </div>
      </div>
    </Dialog>
  )
}

export default MoveToSplitInboxModal
