// @flow
import i18next from 'i18next'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

/**
 * Stripe API library helper methods.
 */

type Stripe = any
type Subscription = Object
type PaymentMethod = Object

export async function createPaymentMethod(
  stripe: Stripe,
  element: any,
  name: string,
  postalCode: string
) {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: 'card',
    card: element,
    billing_details: {
      name,
      address: {
        postal_code: postalCode,
      },
    },
  })

  if (!!error) {
    throw new Error(i18next.t('premium.payment.error'))
  }

  return paymentMethod
}

export async function confirmPaymentMethod(
  stripe: Stripe,
  subscription: Subscription,
  paymentMethod: PaymentMethod
) {
  const paymentIntent = get(subscription, 'latest_invoice.payment_intent')
  if (isNil(paymentIntent) || isNil(paymentMethod)) {
    // Unknown error here
    throw new Error(i18next.t('premium.payment.error'))
  }

  const res = await stripe.confirmCardPayment(paymentIntent.client_secret, {
    payment_method: paymentMethod.id,
  })

  // If confirmation fails again - we stop the user from proceeding.
  if (!!res.error) {
    throw new Error(get(res, 'error.message'))
  }
}

export function requireConfirmation(subscription: Subscription) {
  const isSubscriptionActive = get(subscription, 'status') === 'active'
  const requiresAction =
    get(subscription, 'latest_invoice.payment_intent.status') ===
    'requires_action'

  return !isSubscriptionActive && requiresAction
}

export function requirePaymentMethod(subscription: Subscription) {
  const isSubscriptionActive = get(subscription, 'status') === 'active'
  const requiresAction =
    get(subscription, 'latest_invoice.payment_intent.status') ===
    'requires_payment_method'

  return !isSubscriptionActive && requiresAction
}
