// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  disconnectDomain,
  fetchDomainDetail,
} from 'core/custom-domains/actions'
import {
  getCurrentDomainLoading,
  getDisconnectDomainLoading,
} from 'core/custom-domains/selectors'

import Dialog from '@material-ui/core/Dialog'
import DomainDisconnectForm from '@edison/webmail-ui/components/DomainDisconnectConfirm'

import type { Dispatch } from 'types/redux'

type Props = {
  domain: string,
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ domain, isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const isDomainLoading = useSelector(getCurrentDomainLoading)
  const isDomainDeleting = useSelector(getDisconnectDomainLoading)

  const isLoading = isDomainLoading || isDomainDeleting

  function handleOnConfirm() {
    dispatch(disconnectDomain())
      .then(success => {
        if (success) {
          return dispatch(fetchDomainDetail({ redirect: true }))
        } else {
          return false
        }
      })
      .then(success => {
        if (success) toggle()
      })
  }

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <DomainDisconnectForm
        domain={domain}
        onClose={toggle}
        isLoading={isLoading}
        onConfirm={handleOnConfirm}
      />
    </Dialog>
  )
}

View.displayName = 'DomainDisconnectModal'

export default View
