// @flow
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useRouteMatch,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router-dom'
import { useQueryParams } from 'common/url'
import { routePaths } from 'utils/constants'
import { commonErrorCode } from '@edison/webmail-core/utils/constants'

import { OAuthFailure } from '@edison/webmail-ui/components/Retrofit'

const AuthError = () => {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { userId } = match.params
  const { error } = useQueryParams()
  const redirectUrl = generatePath(routePaths.retrofitAdd, { userId })

  const message = useMemo(() => {
    if (parseInt(error) === commonErrorCode.OAUTH_ACCESS_DENIED) {
      return t('retrofit.oauth.error.imap.denied')
    }
    return ''
  }, [error])

  return (
    <OAuthFailure
      errorMessage={message}
      onBack={() => history.push(redirectUrl, { from: location.pathname })}
      onRetry={() => history.push(redirectUrl, { from: location.pathname })}
    />
  )
}

export default AuthError
