// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import isNil from 'lodash/isNil'

import { getContactById } from 'core/contacts/selectors'

import InfoView from '@edison/webmail-ui/components/ContactDetail/InfoView'

type Props = {
  id: string,
}

export default ({ id }: Props) => {
  const contact = useSelector(getContactById(id))
  return (
    <InfoView
      contact={{
        ...contact,
        emails: isNil(contact.emails) ? [] : contact.emails,
        phones: isNil(contact.phones) ? [] : contact.phones,
        websites: isNil(contact.websites) ? [] : contact.websites,
      }}
    />
  )
}
