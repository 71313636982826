import React, { useRef, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'

import ComposeAttachmentItem from '@edison/webmail-ui/components/ComposeAttachmentItem'

import { usePreviewModal } from 'core/previews/hooks'

import { fetchLargeAttachmentPreviewLink } from 'core/previews/actions'
import { getLargeAttachmentPreview } from 'core/previews/selectors'
import { useLazyLoadImage } from '@edison/webmail-ui/hooks/lazyImage'
import AttachmentTabItem from '@edison/webmail-ui/components/AttachmentTabList/Item'
import { imagePreviewSizeOptions } from 'utils/constants'
import { useModal } from 'common/modals'

import {
  generateLargeAttachmentImagePreviewUrl,
  isEnableThumbnailAttachment,
} from 'utils'
import { modalTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

export const useLazyloadSrc = (rootRef, attachment, size) => {
  const { id } = attachment
  const dispatch: Dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const previewLink = useSelector(
    useMemo(() => getLargeAttachmentPreview(id), [id])
  )

  const src = previewLink
    ? generateLargeAttachmentImagePreviewUrl(previewLink, size)
    : ''

  useLazyLoadImage(rootRef, () => {
    if (isEnableThumbnailAttachment(attachment) && !src) {
      setLoading(true)
      dispatch(fetchLargeAttachmentPreviewLink(attachment))
    }
  })

  //todo: use placeholder image replace to
  return { loading: loading, src }
}

export const AttachmentImage = props => {
  const rootRef = useRef()
  const { src } = useLazyloadSrc(rootRef, props, imagePreviewSizeOptions.small)

  const { t } = useTranslation()
  return (
    <img
      ref={rootRef}
      src={
        isEnableThumbnailAttachment(props) && src
          ? src
          : t('placeholder-photo.png')
      }
      alt={props.name}
    />
  )
}

export default ({ attachments, index, ...rest }) => {
  const previewModal = useModal(modalTypes.preview)
  const attachment = attachments[index]
  const rootRef = useRef()
  const { src } = useLazyloadSrc(
    rootRef,
    attachment,
    imagePreviewSizeOptions.small
  )

  const { name, contentType, size } = attachment

  return (
    <ComposeAttachmentItem
      {...rest}
      ref={rootRef}
      name={name}
      contentType={contentType}
      size={size}
      onClick={() => previewModal.showModal({ attachments, index })}
      src={isEnableThumbnailAttachment(attachment) && src ? src : ''}
    />
  )
}

export const AttachmentTabListItem = ({ attachments, index, ...props }) => {
  const attachment = attachments[index]
  const rootRef = useRef()
  const { loading, src } = useLazyloadSrc(
    rootRef,
    attachment,
    imagePreviewSizeOptions.medium
  )
  const previews = usePreviewModal()

  return (
    <AttachmentTabItem
      {...props}
      attachment={attachment}
      src={isEnableThumbnailAttachment(attachment) && src ? src : ''}
      loading={loading}
      ref={rootRef}
      onClick={() => {
        previews.showModal({ attachments, index })
      }}
    />
  )
}
