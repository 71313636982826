// @flow
import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import {
  BillCalculator,
  BillCalculatorItem,
  BillCalculatorTotal,
} from '@edison/webmail-ui/components/Stripe'
import * as premium from 'core/premium'
import { formatPrice } from 'utils'

const View = ({ planId }: { planId: string }) => {
  const previewsById = useSelector(premium.selectors.getPremiumPreviewById)
  const currentPlan = useSelector(premium.selectors.getCurrentPlan)

  const preview = previewsById[planId]

  return (
    <BillCalculator
      name={get(preview, 'plan.name')}
      interval={get(preview, 'plan.interval')}
    >
      {get(preview, 'invoiceItems', [])
        .sort((a, b) => {
          return a.subtotal.startsWith('-') ? 1 : -1
        })
        .map((item, i) => {
          const deduct = item.unitPrice.startsWith('-')
          const unitPrice = deduct
            ? `${formatPrice(item.unitPrice.slice(1), item.currency)}`
            : formatPrice(item.unitPrice, item.currency)
          const subtotal = deduct
            ? `${formatPrice(item.subtotal.slice(1), item.currency)}`
            : formatPrice(item.subtotal, item.currency)
          const interval = deduct
            ? currentPlan
              ? currentPlan.interval
              : ''
            : preview.plan.interval

          return (
            <BillCalculatorItem
              key={i}
              deduct={deduct}
              unitPrice={unitPrice}
              interval={interval}
              quantity={item.qty}
              subtotal={subtotal}
            />
          )
        })}
      <BillCalculatorTotal
        dueDate={moment.unix(get(preview, 'dueDate')).format('MM/DD/YYYY')}
        amount={formatPrice(
          get(preview, 'total', ''),
          get(preview, 'plan.currency', '')
        )}
      />
    </BillCalculator>
  )
}

View.displayName = 'BillCalculator'

export default View
