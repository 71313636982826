// @flow
import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { labelNames, smartFolders } from 'utils/constants'
import { splitViewTypes } from '@edison/webmail-core/utils/constants'

import { batchSelectThread, selectUniverseThread } from 'core/threads/actions'
import {
  getSelectedThreadIds,
  isThreadDragging,
  isSelectedUniverse,
} from 'core/threads/selectors'
import { useThreadBatchActions, useActiveLabel } from 'core/threads/hooks'
import { useRetrofitSyncProgress } from 'core/retrofit/hooks'
import { toggleSplitView } from 'core/split-inboxes/actions'

import DoneAll from '@material-ui/icons/DoneAll'
import ActionBarLayout, {
  BatchActionDropdown,
  ViewToggleButton,
} from '@edison/webmail-ui/components/ThreadListActionBar'
import { ImportingNotices } from '@edison/webmail-ui/components/Retrofit'
import SelectAllThreads from 'common/ThreadList/SelectAllThreads'

import type { Dispatch } from 'types/redux'
import type { SplitViewType } from '@edison/webmail-core/utils/constants'

const selectedThreadsSelector = getSelectedThreadIds()

type Props = {
  view: SplitViewType,
  threads: $ReadOnlyArray<{ id: string }>,
}
export default ({ view, threads }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const routeParams = useParams()
  const selected = useSelector(selectedThreadsSelector)
  const selectedUniverse = useSelector(isSelectedUniverse)
  const isDragging = useSelector(isThreadDragging)
  const batchActions = useThreadBatchActions(true)
  const activeLabel = useActiveLabel()

  const { isFullSync } = useRetrofitSyncProgress()

  const threadIds = threads.map(({ id }) => id)

  const handleOnToggle = useCallback(() => {
    const nextView =
      view === splitViewTypes.PREVIEW
        ? splitViewTypes.LIST
        : splitViewTypes.PREVIEW

    dispatch(toggleSplitView(activeLabel, nextView))

    if (selected.size) {
      dispatch(batchSelectThread({ ids: threadIds, value: false }))
    } else if (selectedUniverse) {
      dispatch(selectUniverseThread({ value: false }))
    }
  }, [view, activeLabel, selected.size, selectedUniverse, threadIds])

  const onlyShowSelectAll =
    routeParams.label !== 'inbox' || activeLabel === labelNames.primary

  const left = useMemo(() => {
    const showSelectAll =
      // Always show it when under preview mode
      view === splitViewTypes.PREVIEW ||
      // Shows when threads is selected or select universe
      // Hide it when dragging
      (threadIds && threadIds.length && !isDragging)

    return [
      {
        node: <SelectAllThreads threads={threads} />,
        condition: showSelectAll,
      },
    ]
      .filter(({ condition }) => condition)
      .map(({ node }) => node)
  }, [threads, selected.size, selectedUniverse, isDragging, view])

  const right = useMemo(
    () =>
      [
        {
          node: (
            <BatchActionDropdown
              main={{
                title: t('thread.actions.allArchive'),
                icon: <DoneAll />,
                onClick: batchActions.archive,
              }}
              options={[
                {
                  title: t('thread.actions.allTrash'),
                  icon: null,
                  onClick: batchActions.trash,
                },
              ]}
            />
          ),
          condition: view === splitViewTypes.PREVIEW,
        },
        {
          node: <ViewToggleButton value={view} onClick={handleOnToggle} />,
          condition: !onlyShowSelectAll,
        },
      ]
        .filter(({ condition }) => condition)
        .map(({ node }) => node),
    [view, handleOnToggle, onlyShowSelectAll, batchActions]
  )

  const notices = useMemo(() => {
    if (!isFullSync && smartFolders.includes(activeLabel)) {
      return <ImportingNotices className="ml-1 pl-10 py-4" />
    } else return null
  }, [activeLabel, isFullSync])

  return (
    <>
      {notices}
      <ActionBarLayout left={left} right={right} />
    </>
  )
}
