// @flow
import React, { useState, useMemo } from 'react'
import values from 'lodash/values'
import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import { modalTypes } from 'utils/constants'
import { useModal } from 'common/modals'
import {
  useRecoveryMethods,
  useRecoveryMethodForm,
  useRecoveryEmailValidator,
} from 'core/settings/hooks'

import Dialog from '@material-ui/core/Dialog'
import Container, {
  UpdateEmailForm,
  UpdatePhoneNumberForm,
  RecoveryMethodDetail,
  RemoveRecoveryMethod,
  CodeVerifyForm,
  UpdatedSuccess,
  RemovedSuccess,
} from '@edison/webmail-ui/components/RecoveryMethod'
import { Warnings } from '@edison/webmail-ui/components/RecoveryMethod/components'
import { Form as PasswordForm } from 'screens/PasswordAuthModal'
import { useSelector } from 'react-redux'
import { getSMSEnabledCountries } from 'core/analytics/selectors'

import type { RecoveryMethod } from '@edison/webmail-ui/utils/constants'

type Props = {
  method: RecoveryMethod,
  isOpen: boolean,
  onClose: () => void,
}

const RecoveryMethodModal = ({ method, isOpen, onClose }: Props) => {
  const [actionType, setActionType] = useState<?'update' | 'remove'>()
  const [showUnsetMethodWarning, setShowUnsetMethodWarning] = useState(false)
  const {
    auth,
    form,
    isLoading,
    sendCode,
    updateMethod,
    removeMethod,
  } = useRecoveryMethodForm()
  const recoveryEmailValidator = useRecoveryEmailValidator()
  const verifiedMethods = useRecoveryMethods()
  const enabledCountries = useSelector(getSMSEnabledCountries)
  const addRecoveryMethodModal = useModal(modalTypes.addRecoveryMethod)

  const unsetMethod = useMemo<?$PropertyType<RecoveryMethod, 'type'>>(() => {
    return values(recoveryMethods).find(key => {
      const val = verifiedMethods.getMethod(key)

      return val.length === 0
    })
  }, [verifiedMethods])

  const warnings = useMemo(() => {
    if (unsetMethod && showUnsetMethodWarning) {
      const handleOnClickWarnings = () => {
        if (unsetMethod) {
          addRecoveryMethodModal.showModal({
            auth: auth.value,
            recoveryMethod: unsetMethod,
          })

          setTimeout(onClose)
        }
      }

      return (
        <Warnings
          recoveryMethod={unsetMethod}
          onClick={handleOnClickWarnings}
        />
      )
    }
  }, [unsetMethod, showUnsetMethodWarning])

  const handleOnSubmitEmail = ({ email }, { push }) => {
    const nextMethod = {
      type: recoveryMethods.email,
      value: email,
    }

    sendCode(nextMethod).then(success => {
      if (success) {
        form.set(nextMethod)
        push('verify')
      }
    })
  }

  const handleOnSubmitPhoneNumber = ({ phoneNumber }, { push }) => {
    const nextMethod = {
      type: recoveryMethods.phone,
      value: phoneNumber,
    }

    sendCode(nextMethod).then(success => {
      if (success) {
        form.set(nextMethod)
        push('verify')
      }
    })
  }

  const handleOnEnterRemoveView = ({ push }) => {
    if (unsetMethod) {
      setShowUnsetMethodWarning(true)
    } else {
      push('remove')
    }
  }

  const handleOnRemove = ({ push }) => {
    removeMethod(method.type, { auth: auth.value }).then(success => {
      if (success) {
        setActionType('remove')

        push('success')
      } else {
        setShowUnsetMethodWarning(true)
      }
    })
  }

  const handleOnVerify = ({ code }, { push }) => {
    updateMethod(code).then(success => {
      if (success) {
        setActionType('update')
        push('success')
      }
    })
  }

  const handleOnResend = () => {
    sendCode(form.value)
  }

  const steps = [
    {
      id: 'password-auth',
      render: ({ push }) => (
        <PasswordForm
          onClose={onClose}
          onConfirm={extraAuth => {
            if (extraAuth) {
              auth.set(extraAuth)
              push('detail')
            }
          }}
        />
      ),
    },
    {
      id: 'detail',
      render: renderProps => (
        <RecoveryMethodDetail
          isLoading={isLoading}
          method={method}
          onClose={onClose}
          onRemove={() => handleOnEnterRemoveView(renderProps)}
          onUpdate={() => renderProps.push('update')}
          warnings={warnings}
        />
      ),
    },
    {
      id: 'update',
      render: renderProps => {
        if (method.type === recoveryMethods.email) {
          return (
            <UpdateEmailForm
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={values => handleOnSubmitEmail(values, renderProps)}
              validator={recoveryEmailValidator}
            />
          )
        } else {
          return (
            <UpdatePhoneNumberForm
              enabledCountries={enabledCountries}
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={values =>
                handleOnSubmitPhoneNumber(values, renderProps)
              }
            />
          )
        }
      },
    },
    {
      id: 'remove',
      render: renderProps => (
        <RemoveRecoveryMethod
          isLoading={isLoading}
          method={method}
          onClose={onClose}
          onRemove={() => handleOnRemove(renderProps)}
          warnings={warnings}
        />
      ),
    },
    {
      id: 'verify',
      render: renderProps => {
        return (
          <CodeVerifyForm
            isLoading={isLoading}
            method={form.value}
            onSubmit={values => handleOnVerify(values, renderProps)}
            onClose={onClose}
            onResend={handleOnResend}
          />
        )
      },
    },
    {
      id: 'success',
      render: () => {
        const props = {
          recoveryMethod: method.type,
          onClick: onClose,
        }

        if (actionType === 'update') {
          return <UpdatedSuccess {...props} />
        } else if (actionType === 'remove') {
          return <RemovedSuccess {...props} />
        }

        return <></>
      },
    },
  ]

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <Container steps={steps} />
    </Dialog>
  )
}

export default RecoveryMethodModal

export { default as AddRecoveryMethodModal } from './AddModal'
