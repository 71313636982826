// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type { SetInitializationStatus, InitializationActions } from './types'

type State = {
  isInitialized: {},
}

export const initialState: State = {
  isInitialized: {},
}

const reducer = createReducer<State, InitializationActions>(initialState, {
  [actions.setInitializationStatus.toString()]: setInitializationStatus,
})

function setInitializationStatus(
  state: State,
  action: SetInitializationStatus
) {
  const { name, status } = action.payload

  return {
    ...state,
    isInitialized: {
      ...state.isInitialized,
      [name]: status,
    },
  }
}

export default reducer
