// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'
import * as threadActions from 'core/threads/actions'
import keys from 'lodash/keys'
import get from 'lodash/get'

import { labelNames, labelTypes } from 'utils/constants'

import type {
  State,
  LabelActions,
  LabelListSuccess,
  LabelUpdateRequest,
  LabelUpdateSuccess,
  LabelCreateSuccess,
  LabelDeleteSuccess,
  TemporaryRetrofitLabelCreate,
} from './types'
import type { ThreadHistorySuccess } from 'core/threads/types'
import type { Label } from '@edison/webmail-core/types/labels'

export const initialState: State = {}

const reducer = createReducer<State, LabelActions>(initialState, {
  // CURD for labels
  [actions.createLabelActions.success.toString()]: createLabelSuccess,
  [actions.updateLabelActions.request.toString()]: updateLabelHandler,
  [actions.updateLabelActions.success.toString()]: updateLabelHandler,
  [actions.fetchLabelsActions.success.toString()]: fetchLabelsSuccess,
  [actions.deleteLabelActions.success.toString()]: deleteLabelSuccess,
  [actions.deleteLabelActions.request.toString()]: deleteLabelSuccess,
  // History update for unread number
  [threadActions.fetchThreadHistoryActions.success.toString()]: threadHistorySuccess,

  [actions.createTemporaryRetrofitLabel.toString()]: createTemporaryRetrofitLabel,
})

function createTemporaryRetrofitLabel(
  state: State,
  action: TemporaryRetrofitLabelCreate
): State {
  const { id } = action.payload

  return {
    ...state,
    [id]: {
      id,
      name: '',
      color: '',
      threadsUnread: 0,
      type: labelTypes.RETROFIT,
    },
  }
}

function fetchLabelsSuccess(state: State, action: LabelListSuccess): State {
  const { labels } = action.payload
  return labels.reduce<State>(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr,
    }),
    {}
  )
}

function updateLabelHandler(
  state: State,
  action: LabelUpdateRequest | LabelUpdateSuccess
): State {
  const {
    label: { id, name, color },
  } = action.payload
  return {
    ...state,
    [id]: {
      ...state[id],
      name,
      color,
    },
  }
}

function createLabelSuccess(state: State, action: LabelCreateSuccess): State {
  const { label } = action.payload
  return {
    ...state,
    [label.id]: label,
  }
}

function deleteLabelSuccess(state: State, action: LabelDeleteSuccess): State {
  const { id } = action.payload

  return keys(state)
    .filter(each => each !== id)
    .reduce<State>(
      (prev, curr) => ({
        ...prev,
        [curr]: state[curr],
      }),
      {}
    )
}

function threadHistorySuccess(
  state: State,
  action: ThreadHistorySuccess
): State {
  const threads = action.payload.history.map(item => item.thread)
  return threads.reduce((prev, curr) => {
    if (curr.labelIds.includes(labelNames.unread)) {
      const excludeLabels = getExcludedLabels(curr.labelIds)
      return (excludeLabels.length > 0 ? excludeLabels : curr.labelIds).reduce<{
        [key: string]: Label,
      }>(
        (memo, label) => ({
          ...memo,
          [label]: {
            ...memo[label],
            threadsUnread: get(memo, `${label}.threadsUnread`, 0) + 1,
          },
        }),
        prev
      )
    }
    return prev
  }, state)
}

function getExcludedLabels(
  labels: $ReadOnlyArray<string>
): $ReadOnlyArray<string> {
  return [labelNames.trash, labelNames.spam].filter(label =>
    labels.includes(label)
  )
}

export default reducer
