// @flow
import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { compose } from '@edison/functools'
import mapValues from 'lodash/mapValues'
import toPairs from 'lodash/toPairs'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import _values from 'lodash/values'
import isNil from 'lodash/isNil'
import maxBy from 'lodash/maxBy'
import head from 'lodash/head'
import keys from 'lodash/keys'
import get from 'lodash/get'

import { useLabels } from 'core/labels/hooks'
import { actions, selectors } from 'core/split-inboxes'
import { getFilteredContactsEmail } from 'core/contacts/selectors'

import SplitInbox, {
  steps,
  Main,
  NormalForm,
  AdvancedForm,
  Name,
  Value,
  Footer,
} from '@edison/webmail-ui/components/SplitInbox'
import Dialog from '@edison/webmail-ui/components/HeroDialog'
import Criteria from '@edison/webmail-ui/components/Filter/Criteria'
import OnBackButton from '@edison/webmail-ui/components/Filter/OnBackButton'

import { isQueryComplex } from 'utils/filter'
import { displayTitles, labelTypes, toastTypes } from 'utils/constants'
import { useToast, toastVariants } from 'common/toasts'
import { getSplitInboxTipFlag, hideSplitInboxTip } from 'common/storage'

import type { Dispatch } from 'types/redux'

const INITIAL_VALUES = {
  name: '',
  query: {
    from: [''],
  },
}

const validationSchema = {
  query: Yup.object().shape({
    to: Yup.array().of(Yup.string()),
    from: Yup.array().of(Yup.string()),
    subject: Yup.array().of(Yup.string()),
    query: Yup.array().of(Yup.string()),
  }),
}

const criteriaConfig = {
  from: {
    name: 'from',
    label: 'From',
  },
  to: {
    name: 'to',
    label: 'To',
  },
  subject: {
    name: 'subject',
    label: 'Subject Includes',
  },
  query: {
    name: 'query',
    label: 'Have the Words',
  },
}

const valuePlaceholders = {
  from: 'name@example.com',
  to: 'name@example.com',
}

const getSplitInboxNames = selectors.getSplitInboxNames()

type Props = {
  isOpen: boolean,
  toggle: () => void,
  initialStep: string,
  splitInboxId: string,
  prefill: { [key: string]: $ReadOnlyArray<string> },
}

const View = ({
  isOpen,
  toggle,
  splitInboxId,
  initialStep,
  prefill = {},
}: Props) => {
  const isNewSplit = isNil(splitInboxId)
  const dispatch: Dispatch = useDispatch()
  const { t } = useTranslation()
  const { showToast } = useToast(toastTypes.notification)
  const splitInboxes = useSelector(selectors.getSplitInboxState)
  const splitMetas = useSelector(selectors.getSplitMetaState)
  const initialValues = get(splitInboxes, splitInboxId, INITIAL_VALUES)
  const [isAdvanced, setAdvance] = useState(
    !isEmpty(prefill) || isQueryComplex(initialValues.query)
  )
  const contacts = useSelector(getFilteredContactsEmail)
  const splitInboxNames = useSelector(getSplitInboxNames)
  const [showNameTip, setShowNameTip] = useState(!getSplitInboxTipFlag())
  const onNameTipClosed = () => {
    if (showNameTip) {
      hideSplitInboxTip()
      setShowNameTip(false)
    }
  }
  const { all } = useLabels()
  const createdNames = useMemo(
    () =>
      [
        // Ignore the created split-inbox name
        ...splitInboxNames.filter(item => item !== initialValues.name),
        // Ignore the system and internal labels
        ...all
          .filter(item => item.type !== labelTypes.CUSTOM)
          .flatMap(item => [item.name, item.id]),
        // Ignore the display name of internal labels
        ...Object.entries(displayTitles).flatMap(item => item),
      ].map(each => String(each).toLowerCase()),
    // Only update this when the modal is re-open
    [isOpen]
  )
  const [showNotification] = useDebouncedCallback(
    (message, variant) => {
      showToast(message, variant)
    },
    5000,
    { leading: true }
  )

  const threads = []

  function handleSubmit(values) {
    const toSave = {
      ...values,
      // Filter the empty values
      query: toPairs(values.query).reduce((prev, [key, value]) => {
        if (!!value.join('')) {
          return { ...prev, [key]: value.filter(item => !!item) }
        } else return prev
      }, {}),
    }
    const action = isNewSplit
      ? actions.createSplitInbox({
          ...toSave,
          idx:
            get(
              maxBy(_values(splitMetas), 'idx'),
              'idx',
              _values(splitMetas).length - 1
            ) + 1,
        })
      : actions.updateSplitInbox(splitInboxId, toSave)
    dispatch(action)
    return toggle()
  }

  function getActiveCriteria(formikProps) {
    return compose(head, keys)(get(formikProps.values, 'query', {}))
  }

  function isEnableToSubmit(formikProps) {
    const { dirty, values, errors } = formikProps
    const isDirty = isEmpty(prefill) ? dirty : !isEqual(values, initialValues)

    return isDirty && isEmpty(errors)
  }

  function isQueryEmpty(formikProps) {
    const { values } = formikProps
    return (
      _values(get(values, 'query', {})).filter(value => {
        return value.length > 0 && value.every(item => !!item.trim())
      }).length === 0
    )
  }

  return (
    <SplitInbox
      initialStep={initialStep}
      values={{
        ...initialValues,
        query: mapValues({ ...initialValues.query, ...prefill }, (_, key) => [
          ...(initialValues.query[key] || []),
          ...(prefill[key] || []),
        ]),
      }}
      validationSchema={{
        ...validationSchema,
        name: Yup.string()
          .trim()
          .lowercase()
          .notOneOf(createdNames, t('settings.label.error.duplicated'))
          .required('Required'),
      }}
      onSubmit={handleSubmit}
      name={({ push, formikProps }) => (
        <Name
          enableToNext={
            !!formikProps.values.name && !get(formikProps, 'errors.name')
          }
          onNext={() => push(steps.main)}
          onCancel={toggle}
        />
      )}
      main={({ push, formikProps, arrayHelpers }) => {
        const activeCriteria = getActiveCriteria(formikProps)
        const options =
          activeCriteria === criteriaConfig.from.name ||
          activeCriteria === criteriaConfig.to.name
            ? contacts
            : []

        return (
          <Main
            form={
              isAdvanced ? (
                <AdvancedForm
                  fields={[
                    { name: 'from', label: 'From' },
                    { name: 'to', label: 'To' },
                    { name: 'subject', label: 'Subject' },
                    { name: 'query', label: 'Have the Words' },
                  ]}
                />
              ) : (
                <NormalForm
                  values={get(
                    formikProps.values,
                    `query.${activeCriteria}`,
                    []
                  ).map((value, index) => (
                    <Value
                      key={index}
                      type="input"
                      value={value}
                      options={options}
                      name={`query.${activeCriteria}.${index}`}
                      placeholder={get(valuePlaceholders, activeCriteria)}
                      onRemove={() => arrayHelpers.remove(index)}
                      onError={() =>
                        showNotification(
                          t('settings.splitInbox.error.duplicate'),
                          toastVariants.warning
                        )
                      }
                      validator={nextValue => {
                        const queries = new Set(
                          get(formikProps.values, `query.${activeCriteria}`, [])
                        )
                        if (queries.has(nextValue)) {
                          throw Error()
                        }
                      }}
                    />
                  ))}
                  showTip={showNameTip}
                  onTipClosed={onNameTipClosed}
                  name={formikProps.values.name}
                  activeCriteria={activeCriteria}
                  criterias={criteriaConfig}
                  onAddValue={() => arrayHelpers.push('')}
                  onChangeName={() => push(steps.name)}
                  onChangeCriteria={() => push(steps.criteria)}
                />
              )
            }
            footer={
              <Footer
                isNewSplit={isNewSplit}
                enableToSubmit={isEnableToSubmit(formikProps)}
                isQueryEmpty={isQueryEmpty(formikProps)}
                matched={threads.length}
                isAdvanced={isAdvanced}
                setAdvance={() => setAdvance(true)}
                onSubmit={formikProps.handleSubmit}
                onCancel={toggle}
                onPreview={() => console.log('TODO: preview matched')}
              />
            }
          />
        )
      }}
      criteria={({ push, formikProps }) => (
        <Criteria
          config={_values(criteriaConfig)}
          activeCriteria={getActiveCriteria(formikProps)}
          onClick={criteria => {
            formikProps.setFieldValue('query', { [criteria]: [''] })
            push(steps.main)
          }}
          left={<OnBackButton onClick={() => push(steps.main)} />}
        />
      )}
    />
  )
}

const SplitInboxModal = ({ isOpen, toggle, ...props }: Props) => {
  return (
    <Dialog open={isOpen} onClose={toggle}>
      <View {...props} isOpen={isOpen} toggle={toggle} />
    </Dialog>
  )
}
SplitInboxModal.displayName = 'SplitInboxModal'
export default SplitInboxModal
