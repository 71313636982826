// @flow
import { batch } from 'react-redux'
import moment from 'moment'
import random from 'lodash/random'
import i18next from 'i18next'
import { createAction } from 'utils/redux'
import { toastTypes } from 'utils/constants'

import { getLoadingToast } from './selectors'

import type {
  ShowToast,
  HideToast,
  CleanHiddenToasts,
  CleanUpToasts,
} from './types'
import { toastVariants } from '@edison/webmail-ui/components/BasicToast'

import type { ThunkAction, ActionCreator } from 'types/redux'

export const show: ActionCreator<ShowToast> = createAction('TOAST_SHOW')
export const hide: ActionCreator<HideToast> = createAction('TOAST_HIDE')
export const cleanHidden: ActionCreator<CleanHiddenToasts> = createAction(
  'TOAST_CLEAN_HIDDEN'
)
export const cleanUp: ActionCreator<CleanUpToasts> = createAction(
  'TOAST_CLEAN_UP'
)

export function showNotification(
  message: string,
  variant?: string = ''
): ThunkAction {
  return dispatch => {
    //empty message is meaningless
    if (!message && variant !== toastVariants.error) {
      return
    }
    if (!message && variant === toastVariants.error) {
      message = i18next.t('toast.default.error')
    }
    dispatch(
      show({
        key: getRandomKey(),
        props: {
          message: message,
          type: toastTypes.notification,
          variant: variant,
        },
      })
    )
  }
}

export function hideLoading(): ThunkAction {
  return (dispatch, getState) => {
    const existLoading = getLoadingToast()(getState())
    batch(() => {
      for (let { key } of existLoading.filter(toast => toast.isOpen)) {
        dispatch(hide({ key }))
      }
    })
  }
}

export function showLoading(): ThunkAction {
  return (dispatch, getState) => {
    dispatch(hideLoading())

    dispatch(
      show({
        key: getRandomKey(),
        props: {
          type: toastTypes.loading,
        },
      })
    )
  }
}

function getRandomKey() {
  return (moment().unix() * random(1, 100)).toString(16)
}
