// @flow
import React from 'react'
import isNil from 'lodash/isNil'
import { useHistory, generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useModal } from 'common/modals'
import { modalTypes, paywallTypes, routePaths } from 'utils/constants'
import * as analytics from 'core/analytics'
import { useCurrentPlan } from 'core/premium/hooks'
import {
  CustomFileTransferUrlPaywall,
  PasswordProtectedLargeFileLinksPaywall,
  CustomDomainPaywall,
  AllowSubaccountsPaywall,
  RetrofitPaywall,
  ScheduledBreakPaywall,
} from '@edison/webmail-ui/components/Paywall'
import FileStoragePaywall from './FileStoragePaywall'
import SingleFileSizeLimitPaywall from './SingleFileSizeLimitPaywall'
import { useOrderId } from 'core/auth/hooks'

import type { Dispatch } from 'types/redux'

const registry = {
  [paywallTypes.customFileTransferUrl]: CustomFileTransferUrlPaywall,
  [paywallTypes.fileStorage]: FileStoragePaywall,
  [paywallTypes.passwordProtectedLargeFileLinks]: PasswordProtectedLargeFileLinksPaywall,
  [paywallTypes.customDomain]: CustomDomainPaywall,
  [paywallTypes.singleFileSizeLimit]: SingleFileSizeLimitPaywall,
  [paywallTypes.allowSubaccounts]: AllowSubaccountsPaywall,
  [paywallTypes.retrofit]: RetrofitPaywall,
  [paywallTypes.scheduledBreak]: ScheduledBreakPaywall,
}

const Paywall = ({
  type,
  toggle,
  isOpen,
  onClose,
  ...rest
}: {
  type: $Keys<typeof registry>,
  isOpen: boolean,
  toggle: () => mixed,
  onClose: () => mixed,
}) => {
  const PaywallComponent = registry[type]
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const userId = useOrderId()
  const { canUpgrade } = useCurrentPlan()
  const onUpgrade = () => {
    dispatch(analytics.actions.premium.userPaywallCta(type))
    history.push(generatePath(routePaths.pricing, { userId }), rest)
    toggle()
  }

  return (
    <PaywallComponent
      isOpen={isOpen}
      onUpgrade={canUpgrade ? onUpgrade : undefined}
      onClose={toggle}
      {...rest}
    />
  )
}

export const usePaywall = () => {
  const { showModal, hideModal } = useModal(modalTypes.paywall)
  const dispatch: Dispatch = useDispatch()

  // Closes the modal
  const onClose = () => hideModal()

  const showPaywall = type => (props: Object) => {
    dispatch(analytics.actions.premium.userPaywallTrigger(type))
    showModal({ type, onClose, ...props })
  }

  return {
    customFileTransferUrl: {
      show: showPaywall(paywallTypes.customFileTransferUrl),
      hide: onClose,
    },
    fileStorage: {
      show: showPaywall(paywallTypes.fileStorage),
      hide: onClose,
    },
    passwordProtectedLargeFileLinks: {
      show: showPaywall(paywallTypes.passwordProtectedLargeFileLinks),
      hide: onClose,
    },
    customDomain: {
      show: (domain?: string, domainPrice?: string, domainCurrency?: string) =>
        !isNil(domain)
          ? showPaywall(paywallTypes.customDomain)({
              domain,
              domainPrice,
              domainCurrency,
            })
          : showPaywall(paywallTypes.customDomain)(),
      hide: onClose,
    },
    singleFileSizeLimit: {
      show: showPaywall(paywallTypes.singleFileSizeLimit),
      hide: onClose,
    },
    allowSubaccounts: {
      show: showPaywall(paywallTypes.allowSubaccounts),
      hide: onClose,
    },
    retrofit: {
      show: showPaywall(paywallTypes.retrofit),
      hide: onClose,
    },
    scheduledBreak: {
      show: showPaywall(paywallTypes.scheduledBreak),
      hide: onClose,
    },
  }
}

export default Paywall
