// @flow
import get from 'lodash/get'
import omit from 'lodash/omit'
import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import toString from 'lodash/toString'
import { createReducer } from 'utils/redux'
import {
  loginActions,
  signupActions,
  checkUsernameActions,
  logoutAction,
  refreshTokenActions,
  verifyInvitationActions,
  deleteAccountActions,
  getSessionActions,
  listAppPasswordsActions,
  removeAppPasswordActions,
} from './actions'
import * as premium from 'core/premium'

import type {
  AuthActions,
  AuthLoginSuccess,
  AuthLogout,
  AuthLoginRequest,
  AuthSignupSuccess,
  AuthCheckUsernameRequest,
  AuthCheckUsernameSuccess,
  AuthCheckUsernameFailure,
  AuthRefreshSuccess,
  AuthRefreshFailure,
  AuthVerifyInvitationSuccess,
  DeleteAccountSuccess,
  GetSessionSuccess,
  ListAppPasswordsSuccess,
  RemoveAppPasswordSuccess,
} from './types'
import type { PremiumSignUpSuccess } from 'core/premium/types'
import type { State } from './types'

const initialState: State = {
  info: null,
  signup: {
    usernameTaken: null,
    temp: null,
  },
  invitation: null,
  refreshed: false,
  requestIds: { check: null },
  session: {
    accounts: {},
    orderId: null,
  },
  appPasswords: {},
}

export default createReducer<State, AuthActions>(initialState, {
  [loginActions.request.toString()]: loginRequest,
  [loginActions.success.toString()]: loginSuccess,
  [signupActions.success.toString()]: signupSuccess,
  [checkUsernameActions.request.toString()]: checkUsernameRequest,
  [checkUsernameActions.success.toString()]: checkUsernameSuccess,
  [checkUsernameActions.failure.toString()]: checkUsernameFailure,
  [logoutAction.toString()]: logout,
  [refreshTokenActions.success.toString()]: refreshSuccess,
  [refreshTokenActions.failure.toString()]: refreshFailure,
  [verifyInvitationActions.success.toString()]: verifyInvitationSuccess,
  [premium.actions.premiumSignupActions.success.toString()]: signupSuccess,
  [deleteAccountActions.success.toString()]: resetAuth,
  [getSessionActions.success.toString()]: getSessionSuccess,
  [listAppPasswordsActions.success.toString()]: listAppPasswordSuccess,
  [removeAppPasswordActions.success.toString()]: removeAppPasswordSuccess,
})

function loginRequest(state: State, action: AuthLoginRequest) {
  return state
}

function loginSuccess(state: State, action: AuthLoginSuccess) {
  return {
    ...state,
    info: action.payload,
  }
}

function signupSuccess(
  state: State,
  action: AuthSignupSuccess | PremiumSignUpSuccess
) {
  const { user, token } = action.payload
  return {
    ...state,
    signup: {
      ...state.signup,
      temp: {
        user,
        info: '',
        token,
      },
    },
  }
}

function logout(state: State, action: AuthLogout) {
  return {
    ...state,
    info: null,
  }
}

function checkUsernameRequest(state: State, action: AuthCheckUsernameRequest) {
  const requestId = get(action.meta, 'request.id', null)

  return {
    ...state,
    signup: {
      usernameTaken: null,
    },
    requestIds: {
      ...state.requestIds,
      check: requestId,
    },
  }
}

function checkUsernameSuccess(state: State, action: AuthCheckUsernameSuccess) {
  const requestId = get(action.meta, 'request.id', null)

  if (requestId !== state.requestIds.check) {
    return state
  }

  return {
    ...state,
    signup: {
      usernameTaken: false,
    },
    requestIds: {
      ...state.requestIds,
      check: null,
    },
  }
}

function checkUsernameFailure(state: State, action: AuthCheckUsernameFailure) {
  const requestId = get(action.meta, 'request.id', null)

  if (requestId !== state.requestIds.check) {
    return state
  }

  return {
    ...state,
    signup: {
      usernameTaken: true,
    },
    requestIds: {
      ...state.requestIds,
      check: null,
    },
  }
}

function refreshFailure(state: State, action: AuthRefreshFailure) {
  return {
    ...state,
    refreshed: true,
  }
}

function refreshSuccess(state: State, action: AuthRefreshSuccess) {
  const { user, token } = action.payload
  return {
    ...state,
    info: { user, token },
    refreshed: true,
  }
}

function verifyInvitationSuccess(
  state: State,
  action: AuthVerifyInvitationSuccess
) {
  const { invitation } = action.payload

  return {
    ...state,
    invitation,
  }
}

function resetAuth(state: State, action: DeleteAccountSuccess) {
  return initialState
}

function getSessionSuccess(state: State, action: GetSessionSuccess) {
  const { session } = action.payload

  return {
    ...state,
    session: {
      ...state.session,
      accounts: keyBy(
        values(session).map(({ orderId, ...rest }) => ({
          ...rest,
          orderId: toString(orderId),
        })),
        'orderId'
      ),
    },
  }
}

function listAppPasswordSuccess(state: State, action: ListAppPasswordsSuccess) {
  const appPasswords = action.payload

  return {
    ...state,
    appPasswords: appPasswords.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: curr }),
      {}
    ),
  }
}

function removeAppPasswordSuccess(
  state: State,
  action: RemoveAppPasswordSuccess
) {
  const { id } = action.payload

  return {
    ...state,
    appPasswords: omit(state.appPasswords, id),
  }
}
