// @flow
import React from 'react'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { isAppPasswordCreating, getAppPasswords } from 'core/auth/selectors'

import {
  PasswordDetail,
  AppPasswordLayout,
  AppNameForm,
} from '@edison/webmail-ui/components/AppSpecificPassword'
import Button from '@edison/webmail-ui/components/Button'
import { Form as PasswordForm } from 'screens/PasswordAuthModal'

import type { ExtraAuth } from '@edison/webmail-core/types'

type PasswordAuthProps = {
  onClose: () => void,
  onGrantPassword: (auth: ExtraAuth) => void,
}
export const PasswordAuth = ({
  onClose,
  onGrantPassword,
}: PasswordAuthProps) => {
  return (
    <PasswordForm
      onClose={onClose}
      onConfirm={auth => {
        onGrantPassword(auth)
      }}
    />
  )
}

type NameSetupProps = {
  onClose: () => void,
  onSubmit: (name: string) => void,
}
export const NameSetup = ({ onClose, onSubmit }: NameSetupProps) => {
  const { t } = useTranslation()

  const passwords = useSelector(getAppPasswords)
  const isLoading = useSelector(isAppPasswordCreating)

  return (
    <AppPasswordLayout
      onClose={onClose}
      title={t('settings.asp.title')}
      subtitle={t('settings.asp.generate.subtitle')}
    >
      <AppNameForm
        onBack={onClose}
        isLoading={isLoading}
        validationSchema={Yup.string()
          .lowercase()
          .notOneOf(
            passwords.map(({ name }) => name.toLowerCase()),
            t('settings.asp.form.appName.duplicate')
          )}
        onSubmit={({ name }) => {
          onSubmit(name)
        }}
      />
    </AppPasswordLayout>
  )
}

type DetailProps = {
  password: { name: string, value: string },
  onClose: () => void,
}
export const Detail = ({ onClose, password }: DetailProps) => {
  const { t } = useTranslation()
  return (
    <AppPasswordLayout
      onClose={onClose}
      title={t('settings.asp.detail.title')}
      subtitle={t('settings.asp.detail.subtitle')}
      action={<Button onClick={onClose}>{t('button.done')}</Button>}
    >
      <PasswordDetail name={password.name} value={password.value} />
    </AppPasswordLayout>
  )
}
