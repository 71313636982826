// @flow
import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose } from '@edison/functools'
import curryRight from 'lodash/curryRight'
import toPairs from 'lodash/toPairs'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import head from 'lodash/head'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import get from 'lodash/get'
import set from 'lodash/set'
import * as Yup from 'yup'

import { actions, selectors } from 'core/filters'
import { selectors as labelSelectors } from 'core/labels'

import Filter, {
  steps,
  Main,
  Footer,
  Action,
  Criteria,
  ActionItem,
  OnBackButton,
  NormalFilter,
  AdvancedFilter,
  ThreadListPreview,
} from '@edison/webmail-ui/components/Filter'
import Dialog from '@edison/webmail-ui/components/HeroDialog'
import ThreadListItem from '@edison/webmail-ui/components/ThreadListItem'
import Avatar from 'common/AuthAvatar'
import { registryActions } from './actions'

import {
  isQueryComplex,
  criteriaConfig,
  actionConfig,
  transformFilterToApi,
  transformFilterFromApi,
  allowDuplicatedActions,
} from 'utils/filter'

import type { Dispatch } from 'types/redux'

const initialValues = {
  criteria: {
    from: '',
  },
  action: {},
}

const validationSchema = {
  criteria: Yup.object({
    to: Yup.string(),
    from: Yup.string(),
    subject: Yup.string(),
    query: Yup.string(),
  }),
  action: Yup.array()
    .min(1)
    .of(
      Yup.object({
        value: Yup.mixed().required(),
      })
    ),
}

const getFilters = selectors.getFilterState()
const getCustomLabels = labelSelectors.getCustomLabels()

type Props = {
  isOpen: boolean,
  toggle: () => void,
  filterId: string,
}

const View = ({ toggle, filterId }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const filters = useSelector(getFilters)
  const customLabels = useSelector(getCustomLabels).map(({ name, id }) => ({
    id,
    name,
  }))
  const filter = useMemo(
    () =>
      compose(
        transformFilterFromApi,
        curryRight(get)(filterId, initialValues)
      )(filters),
    [filterId]
  )
  const [isAdvanced, setAdvance] = useState(isQueryComplex(filter.criteria))

  // TODO: Should do the API request to get the thread list
  const threads = []

  function handleSubmit(values) {
    const { execute, ...filterToSubmit } = values
    const filterToApi = transformFilterToApi(filterToSubmit)
    const action = isNil(filterId)
      ? actions.createFilter(filterToApi)
      : actions.updateFilter(filterId, filterToApi)

    dispatch(action).then(filter => {
      if (!isNil(filter) && execute) {
        dispatch(actions.executeFilter(filter))
      }
    })
    return toggle()
  }

  function getActiveCriteria(formikProps) {
    return compose(head, keys)(get(formikProps.values, 'criteria', {}))
  }

  function getActions(formikProps) {
    return get(formikProps.values, 'action', [])
  }

  function isActionExisted(formikProps, actionName) {
    const actions = get(formikProps.values, 'action', [])
    return actions.find(action => action.name === actionName)
  }

  function getActionConfig(formikProps) {
    return compose(
      values,
      curryRight(set)('label.action.options', customLabels),
      // Action `forward` was deprecated
      input => omit(input, 'forward'),
      // Only one of the Actions in `archive` and `trash` would be able to
      // be chosen, because email can't be in both label
      input => {
        if (isActionExisted(formikProps, actionConfig.archive.action.name)) {
          return omit(input, actionConfig.trash.action.name)
        }

        if (isActionExisted(formikProps, actionConfig.trash.action.name)) {
          return omit(input, actionConfig.archive.action.name)
        }

        return input
      }
    )(actionConfig)
  }

  function isEnableToSubmit(formikProps) {
    const { values, errors, dirty } = formikProps
    const { criteria, action } = values
    const isCriteriaValidated =
      toPairs(criteria).filter(([, value]) => !!(value || '').trim()).length > 0
    const isActionValidated = !!action.length
    return dirty && isEmpty(errors) && isCriteriaValidated && isActionValidated
  }

  function renderItem({ index, style }) {
    const { id, ...item } = threads[index]
    return (
      <ThreadListItem
        key={id}
        style={style}
        avatar={<Avatar src="" alt={item.fromName.charAt(0)} />}
        {...item}
      />
    )
  }

  return (
    <Filter
      values={{
        ...filter,
        execute: get(filter, 'execute', false),
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      main={({ push, formikProps, arrayHelpers }) => (
        <Main
          onSubmit={formikProps.handleSubmit}
          onAddAction={() => push(steps.action)}
          filter={
            isAdvanced ? (
              <AdvancedFilter />
            ) : (
              <NormalFilter
                criterias={criteriaConfig}
                activeCriteria={getActiveCriteria(formikProps)}
                onChangeCriteria={() => push(steps.criteria)}
              />
            )
          }
          actions={getActions(formikProps).map((action, index) => {
            return (
              <div key={`${action.name}-${index}`} className="m-2">
                <ActionItem
                  label={action.label}
                  onRemove={() => arrayHelpers.remove(index)}
                  renderAction={() =>
                    get(
                      registryActions,
                      action.type,
                      noop
                    )({
                      ...action,
                      name: `action.${index}.value`,
                    })
                  }
                />
              </div>
            )
          })}
          footer={
            <Footer
              note={
                <>
                  {isActionExisted(
                    formikProps,
                    actionConfig.forward.action.name
                  ) && <p>{t('settings.filter.action.forward.note')}</p>}
                  {isActionExisted(
                    formikProps,
                    actionConfig.spam.action.name
                  ) && <p>{t('settings.filter.action.spam.note')}</p>}
                </>
              }
              enableToSubmit={isEnableToSubmit(formikProps)}
              matched={threads.length}
              isAdvanced={isAdvanced}
              setAdvance={() => setAdvance(true)}
              onPreview={() => push(steps.preview)}
              onSubmit={formikProps.handleSubmit}
              onCancel={toggle}
            />
          }
        />
      )}
      criteria={({ push, formikProps }) => (
        <Criteria
          config={values(criteriaConfig)}
          activeCriteria={getActiveCriteria(formikProps)}
          onClick={criteria => {
            formikProps.setFieldValue('criteria', { [criteria]: '' })
            return push(steps.main)
          }}
          left={<OnBackButton onClick={() => push(steps.main)} />}
        />
      )}
      action={({ push, formikProps, arrayHelpers }) => (
        <Action
          config={getActionConfig(formikProps)}
          onClick={action => {
            let toRemoveIndex = []
            // To remove the actions which are not allowed to be duplicated
            if (!allowDuplicatedActions.includes(action.name)) {
              formikProps.values.action.forEach((item, index) => {
                if (item.name === action.name) toRemoveIndex.push(index)
              })
            }
            toRemoveIndex.forEach(item => arrayHelpers.remove(item))
            arrayHelpers.push(action)
            return push(steps.main)
          }}
          left={<OnBackButton onClick={() => push(steps.main)} />}
        />
      )}
      preview={({ push }) => (
        <ThreadListPreview
          total={threads.length}
          renderItem={renderItem}
          left={<OnBackButton onClick={() => push(steps.main)} />}
        />
      )}
    />
  )
}

export default ({ isOpen, toggle, ...props }: Props) => {
  return (
    <Dialog open={isOpen} onClose={toggle}>
      <View {...props} isOpen={isOpen} toggle={toggle} />
    </Dialog>
  )
}
