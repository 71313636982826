// @flow
import values from 'lodash/values'
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'

import { filterTypes } from '@edison/webmail-core/utils/constants'

import type { State as FilterState } from './types'
import type { State, Selector } from 'types/state'

export const selectFilterState = (state: State) => state.filters

export function getFilterState(): Selector<FilterState> {
  return createSelector(selectFilterState, (state: FilterState) => state)
}

export function isFilterCreating(): Selector<boolean> {
  return createSelector(
    getLoadingStatus('FILTER_CREATE'),
    (isCreating: boolean) => isCreating
  )
}

const VISIBLE_FILTE_TYPES = [filterTypes.CUSTOM, filterTypes.CLEANUP]
export const getUserFiltersSelector = createSelector(
  selectFilterState,
  filters => {
    return values(filters).filter(item =>
      VISIBLE_FILTE_TYPES.includes(item.filterType)
    )
  }
)
