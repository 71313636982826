// @flow
import moment from 'moment'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { createReducer } from 'utils/redux'
import {
  getDomainDetailActions,
  updateCustomDomainAssetsActions,
  fetchCustomDomainAssetsActions,
  uploadCustomDomainIconOrLogoActions,
  purchaseDomainActions,
  verifyDomainActions,
  getDnsSetupInfoActions,
  searchDomainsActions,
  connectDomainActions,
  disconnectDomainActions,
  fetchSubaccountsAction,
  createSubaccountAction,
  deleteSubaccountAction,
  subaccountInvitationLinkActions,
  subaccountResetPasswordLinkActions,
  clearSearchResults,
  getDnsRecordsActions,
  updateDnsRecordsActions,
  clearDnsRecordsErrors,
} from './actions'
import { SUBACCOUNT_STATUS } from '@edison/webmail-ui/utils/constants'

import type {
  Domain,
  DnsInfo,
  ReadOnlyDnsInfo,
  Subaccount,
} from '@edison/webmail-core/types/custom-domains'
import type {
  CustomDomainActions,
  UpdateCustomDomainAssetsSuccess,
  FetchCustomDomainAssetsSuccess,
  UploadCustomDomainIconOrLogoSuccess,
  CustomDomainDetailSuccess,
  CustomDomainPurchaseSuccess,
  CustomDomainVerifySuccess,
  CustomDomainVerifyRequest,
  CustomDomainDnsSetupInfoSuccess,
  CustomDomainSearchRequest,
  CustomDomainSearchSuccess,
  CustomDomainSearchFailure,
  CustomDomainConnectSuccess,
  CustomDomainDisconnectSuccess,
  SubaccountListSuccess,
  SubaccountCreateSuccess,
  SubaccountDeleteSuccess,
  SubaccountInvitationLinkSuccess,
  SubaccountResetPasswordLinkSuccess,
  ClearDomainSearchResults,
  GetDnsRecordsSuccess,
  UpdateDnsRecordsSuccess,
  UpdateDnsRecordsFailure,
  ClearDnsRecordsErrors,
} from './types'

export type State = {|
  // Current custom domain if the user already has one
  current: ?Domain,
  isAdmin: boolean,
  setup: {|
    // DNS information for users to setup their DNS (for user-owned domains only)
    dnsInfo: $ReadOnlyArray<DnsInfo>,
    // Unix time that the last verification request was sent
    lastVerifyAt: number,
  |},
  // All subaccounts for this domain
  subaccounts: {
    [accountId: string]: Subaccount,
  },
  resetPasswordLinks: {
    [accountId: string]: string,
  },
  // Domain search (for hosted domains only)
  search: {|
    requestId: ?string,
    limit: number,
    username: string,
    domains: $ReadOnlyArray<{|
      domain: string,
      available: boolean,
      currency: string,
      price: number,
    |}>,
    // For the two OnMail suggested usernames
    onmailUsernameAvailability: {
      [username: string]: boolean,
    },
    isInvalidDomain: boolean,
  |},
  aliases: $ReadOnlyArray<string>,
  // For users to manage their own DNS records
  records: {
    errors: ?Object, // handle errors from GoDaddy
    values: $ReadOnlyArray<ReadOnlyDnsInfo>,
  },
|}

export const initialState: State = {
  current: null,
  isAdmin: false,
  aliases: [],
  setup: {
    dnsInfo: [],
    lastVerifyAt: 0,
  },
  subaccounts: {},
  resetPasswordLinks: {},
  search: {
    requestId: null,
    domains: [],
    limit: 10,
    username: '',
    onmailUsernameAvailability: {},
    isInvalidDomain: false,
  },
  records: {
    errors: null,
    values: [],
  },
}

export default createReducer<State, CustomDomainActions>(initialState, {
  [updateCustomDomainAssetsActions.success.toString()]: updateCustomDomainAssetsSuccess,
  [uploadCustomDomainIconOrLogoActions.success.toString()]: uploadCustomDomainIconOrLogoSuccess,
  [fetchCustomDomainAssetsActions.success.toString()]: fetchCustomDomainAssetsSuccess,
  [getDomainDetailActions.success.toString()]: getDomainDetailSuccess,
  [connectDomainActions.success.toString()]: getDomainDetailSuccess,
  [purchaseDomainActions.success.toString()]: purchaseDomainSuccess,
  [verifyDomainActions.success.toString()]: verifyDomainSuccess,
  [verifyDomainActions.request.toString()]: verifyDomainRequest,
  [getDnsSetupInfoActions.success.toString()]: getDnsSetupInfoSuccess,
  [searchDomainsActions.request.toString()]: searchDomainsRequest,
  [searchDomainsActions.success.toString()]: setSearchResults,
  [searchDomainsActions.failure.toString()]: searchDomainsFailure,
  [disconnectDomainActions.success.toString()]: disconnectDomainSuccess,
  [getDnsRecordsActions.success.toString()]: getDnsRecordsSuccess,
  [updateDnsRecordsActions.success.toString()]: updateDnsRecordsSuccess,
  [updateDnsRecordsActions.failure.toString()]: updateDnsRecordsFailure,

  // Sub-Accounts
  [fetchSubaccountsAction.success.toString()]: fetchSubaccountsSuccess,
  [createSubaccountAction.success.toString()]: createSubaccountSuccess,
  [deleteSubaccountAction.success.toString()]: deleteSubaccountSuccess,
  [subaccountInvitationLinkActions.success.toString()]: fetchSubaccountInvitationLinkSuccess,
  [subaccountResetPasswordLinkActions.success.toString()]: resetPasswordLinkSuccess,
  [clearSearchResults.toString()]: clearDomainSearchResults,
  [clearDnsRecordsErrors.toString()]: _clearDnsRecordsErrors,
})

function fetchCustomDomainAssetsSuccess(
  state: State,
  action: FetchCustomDomainAssetsSuccess
) {
  const { companyName, color, iconUrl, logoUrl } = action.payload

  return {
    ...state,
    current: {
      ...get(state, 'current', {}),
      assets: {
        companyName,
        color,
        iconUrl,
        logoUrl,
      },
    },
  }
}

function uploadCustomDomainIconOrLogoSuccess(
  state: State,
  action: UploadCustomDomainIconOrLogoSuccess
) {
  const { name, refreshUrl } = action.payload
  return {
    ...state,
    current: {
      ...state.current,
      assets: {
        ...state.current.assets,
        [name]: refreshUrl,
      },
    },
  }
}

function updateCustomDomainAssetsSuccess(
  state: State,
  action: UpdateCustomDomainAssetsSuccess
) {
  const { companyName, color } = action.payload

  return {
    ...state,
    current: {
      ...state.current,
      assets: {
        ...state.current.assets,
        companyName,
        color,
      },
    },
  }
}

function getDomainDetailSuccess(
  state: State,
  action: CustomDomainDetailSuccess | CustomDomainConnectSuccess
) {
  const { domain, isAdmin, aliases } = action.payload

  return {
    ...state,
    isAdmin,
    aliases: aliases ? aliases : state.aliases,
    current: domain,
  }
}

function disconnectDomainSuccess(
  state: State,
  action: CustomDomainDisconnectSuccess
) {
  const { isAdmin } = state
  // Reset all the state, except role
  return {
    ...initialState,
    isAdmin,
  }
}

function purchaseDomainSuccess(
  state: State,
  action: CustomDomainPurchaseSuccess
) {
  const { domain } = action.payload

  return {
    ...state,
    current: domain,
  }
}

function verifyDomainSuccess(state: State, action: CustomDomainVerifySuccess) {
  const { records } = action.payload

  return {
    ...state,
    setup: {
      ...state.setup,
      dnsInfo: records,
    },
  }
}

function verifyDomainRequest(state: State, action: CustomDomainVerifyRequest) {
  return {
    ...state,
    setup: {
      ...state.setup,
      lastVerifyAt: moment().unix(),
    },
  }
}

function getDnsSetupInfoSuccess(
  state: State,
  action: CustomDomainDnsSetupInfoSuccess
) {
  const { info } = action.payload
  return {
    ...state,
    setup: {
      ...state.setup,
      dnsInfo: info,
    },
  }
}

function setSearchResults(state: State, action: CustomDomainSearchSuccess) {
  const { username, domains, onmailUsernames } = action.payload
  const requestId = get(action, 'meta.request.id')

  // Ignore if there is already a pending request
  if (requestId !== state.search.requestId) {
    return state
  }

  return {
    ...state,
    search: {
      ...state.search,
      requestId: null,
      username,
      domains,
      onmailUsernameAvailability: onmailUsernames,
    },
  }
}

function searchDomainsRequest(state: State, action: CustomDomainSearchRequest) {
  const { limit } = action.payload
  const requestId = get(action, 'meta.request.id')

  return {
    ...state,
    search: {
      ...state.search,
      requestId,
      limit,
      isInvalidDomain: false,
    },
  }
}

function searchDomainsFailure(state: State, action: CustomDomainSearchFailure) {
  return {
    ...state,
    search: {
      ...state.search,
      isInvalidDomain: true,
      requestId: null,
    },
  }
}

function fetchSubaccountsSuccess(state: State, action: SubaccountListSuccess) {
  const subaccountList = action.payload

  return {
    ...state,
    subaccounts: {
      ...state.subaccounts,
      ...subaccountList.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: { ...curr },
        }),
        {}
      ),
    },
  }
}

function createSubaccountSuccess(
  state: State,
  action: SubaccountCreateSuccess
) {
  const newSubaccount = action.payload

  return {
    ...state,
    subaccounts: {
      ...state.subaccounts,
      [newSubaccount.id]: { ...newSubaccount },
    },
  }
}

function deleteSubaccountSuccess(
  state: State,
  action: SubaccountDeleteSuccess
) {
  // ID for the deleted subaccount
  const { id } = action.payload
  const { subaccounts } = state

  if (id in subaccounts) {
    return {
      ...state,
      subaccounts: {
        ...subaccounts,
        [id]: {
          ...subaccounts[id],
          status: SUBACCOUNT_STATUS.SOFT_DELETED,
        },
      },
    }
  }

  return state
}

function resetPasswordLinkSuccess(
  state: State,
  action: SubaccountResetPasswordLinkSuccess
) {
  const { id, resetPasswordLink } = action.payload
  const { resetPasswordLinks } = state

  return {
    ...state,
    resetPasswordLinks: {
      ...resetPasswordLinks,
      [id]: resetPasswordLink,
    },
  }
}

function fetchSubaccountInvitationLinkSuccess(
  state: State,
  action: SubaccountInvitationLinkSuccess
) {
  const { id, invitationLink } = action.payload
  const { subaccounts } = state

  if (id in subaccounts) {
    return {
      ...state,
      subaccounts: {
        ...subaccounts,
        [id]: {
          ...subaccounts[id],
          invitationLink,
        },
      },
    }
  }
  return state
}

function clearDomainSearchResults(
  state: State,
  action: ClearDomainSearchResults
) {
  return {
    ...state,
    search: initialState.search,
  }
}

function getDnsRecordsSuccess(state: State, action: GetDnsRecordsSuccess) {
  const { records } = action.payload

  return setDnsRecords(state, records)
}

function updateDnsRecordsSuccess(
  state: State,
  action: UpdateDnsRecordsSuccess
) {
  const { records } = action.payload

  return setDnsRecords(state, records)
}

function updateDnsRecordsFailure(
  state: State,
  action: UpdateDnsRecordsFailure
) {
  const { errors } = action.payload

  if (isNil(errors)) {
    return state
  }

  return {
    ...state,
    records: {
      ...state.records,
      errors,
    },
  }
}

function _clearDnsRecordsErrors(state: State, action: ClearDnsRecordsErrors) {
  return {
    ...state,
    records: {
      ...state.records,
      errors: initialState.records.errors,
    },
  }
}

function setDnsRecords(state, records) {
  return {
    ...state,
    records: {
      ...state.records,
      values: records,
    },
  }
}
