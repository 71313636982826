// @flow
import i18next from 'i18next'
import * as client from '@edison/webmail-core/api'

import { getAuth } from 'core/auth/selectors'
import { showNotification } from 'core/toasts/actions'

import { toastVariants } from 'common/toasts'
import { createAction } from 'utils/redux'
import { labelNames } from 'utils/constants'

import type {
  FilterListRequest,
  FilterListSuccess,
  FilterListFailure,
  FilterCreateRequest,
  FilterCreateSuccess,
  FilterCreateFailure,
  FilterDeleteRequest,
  FilterDeleteSuccess,
  FilterDeleteFailure,
  FilterUpdateRequest,
  FilterUpdateSuccess,
  FilterUpdateFailure,
  FilterExecuteRequest,
  FilterExecuteSuccess,
  FilterExecuteFailure,
} from './types'
import type { Filter } from '@edison/webmail-core/types/filters'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchFiltersActions: {
  request: ActionCreator<FilterListRequest>,
  success: ActionCreator<FilterListSuccess>,
  failure: ActionCreator<FilterListFailure>,
} = {
  request: createAction('FILTER_LIST_REQUEST'),
  success: createAction('FILTER_LIST_SUCCESS'),
  failure: createAction('FILTER_LIST_FAILURE'),
}

/**
 * Return the list of filters
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchFilters(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchFiltersActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(fetchFiltersActions.request())
      const res = await client.filters.list({ auth })
      dispatch(fetchFiltersActions.success([...res.result]))
    } catch (e) {
      dispatch(fetchFiltersActions.failure({ message: e.message }))
    }
  }
}

export const createFilterActions: {
  request: ActionCreator<FilterCreateRequest>,
  success: ActionCreator<FilterCreateSuccess>,
  failure: ActionCreator<FilterCreateFailure>,
} = {
  request: createAction('FILTER_CREATE_REQUEST'),
  success: createAction('FILTER_CREATE_SUCCESS'),
  failure: createAction('FILTER_CREATE_FAILURE'),
}

/**
 * Create a filter
 *
 * @public
 * @param {Filter} filter - filter object
 * @returns {ThunkAction}
 */
export function createFilter(filter: Filter): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(createFilterActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(createFilterActions.request())
      const res = await client.filters.create(filter, { auth })
      const newFilter = { id: res.result.id.toString(), ...filter }
      dispatch(createFilterActions.success(newFilter))
      return newFilter
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.filter.error.create'),
          toastVariants.error
        )
      )
      dispatch(createFilterActions.failure({ message: e.message }))
    }
  }
}

export const deleteFilterActions: {
  request: ActionCreator<FilterDeleteRequest>,
  success: ActionCreator<FilterDeleteSuccess>,
  failure: ActionCreator<FilterDeleteFailure>,
} = {
  request: createAction('FILTER_DELETE_REQUEST'),
  success: createAction('FILTER_DELETE_SUCCESS'),
  failure: createAction('FILTER_DELETE_FAILURE'),
}

/**
 * Delete a filter
 *
 * @public
 * @param {string} filterId - filter object
 * @returns {ThunkAction}
 */
export function deleteFilter(filterId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(deleteFilterActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(deleteFilterActions.request(filterId))
      const res = await client.filters.deleteFilter(filterId, { auth })
      dispatch(deleteFilterActions.success(filterId))
      return res.result
    } catch (e) {
      dispatch(deleteFilterActions.failure({ message: e.message }))
    }
  }
}

export const updateFilterActions: {
  request: ActionCreator<FilterUpdateRequest>,
  success: ActionCreator<FilterUpdateSuccess>,
  failure: ActionCreator<FilterUpdateFailure>,
} = {
  request: createAction('FILTER_UPDATE_REQUEST'),
  success: createAction('FILTER_UPDATE_SUCCESS'),
  failure: createAction('FILTER_UPDATE_FAILURE'),
}

export function updateFilter(
  filterId: string,
  { criteria, action }: Filter
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(updateFilterActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      const filter = { id: filterId, criteria, action }
      dispatch(updateFilterActions.request())
      await client.filters.update(filterId, { criteria, action }, { auth })
      dispatch(updateFilterActions.success(filter))
      return filter
    } catch (e) {
      dispatch(
        showNotification(
          i18next.t('settings.filter.error.update'),
          toastVariants.error
        )
      )
      dispatch(updateFilterActions.failure({ message: e.message }))
    }
  }
}

export const executeFilterActions: {
  request: ActionCreator<FilterExecuteRequest>,
  success: ActionCreator<FilterExecuteSuccess>,
  failure: ActionCreator<FilterExecuteFailure>,
} = {
  request: createAction('FILTER_EXECUTE_REQUEST'),
  success: createAction('FILTER_EXECUTE_SUCCESS'),
  failure: createAction('FILTER_EXECUTE_FAILURE'),
}

export function executeFilter({
  criteria,
  action,
  id,
}: Filter & { id: string }): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(executeFilterActions.failure({ message: 'User not logged in' }))
      return
    }

    // Skip forward action
    const { forward, ...otherAction } = action
    const filter = {
      criteria: {
        ...criteria,
        query: [
          // Don't apply to SPAM and TRASH
          criteria.query,
          `(-label:${labelNames.spam})`,
          `(-label:${labelNames.trash})`,
        ]
          .filter(Boolean)
          .join(' AND ')
          .trim(),
      },
      action: otherAction,
    }
    try {
      dispatch(executeFilterActions.request())
      await client.filters.execute(filter, { auth })
      dispatch(executeFilterActions.success(id))
    } catch (e) {
      dispatch(executeFilterActions.failure({ message: e.message }))
    }
  }
}
