import SplitInboxPillItem from '@edison/webmail-ui/components/Pill/SpiltInboxPill'
import {
  getSplitInboxState,
  getSplitMetaState,
} from 'core/split-inboxes/selectors'
import toPairs from 'lodash/toPairs'
import React, { useEffect, useRef } from 'react'
import { useIsDisableSpilt } from 'core/search/hooks'
import { useSelector } from 'react-redux'

const SPILT_INBOX_NAME_MAP = {
  EMS_INTL_OTHER: 'Other',
  PRIMARY: 'Primary',
}

function useGetInitSpiltBox() {
  const spiltInbox = useSelector(getSplitInboxState)
  const splitMeta = useSelector(getSplitMetaState)
  const result = []
  for (const [key, value] of toPairs(splitMeta)) {
    if (spiltInbox[key]) {
      result.push(spiltInbox[key])
    } else {
      result.push({ ...value, name: SPILT_INBOX_NAME_MAP[value.id] })
    }
  }
  return result.sort((a, b) => (a.idx < b.idx ? -1 : 1))
}

export function SplitInboxPill(props) {
  const { onSelect, condition, isClearAll } = props
  const spiltInbox = useGetInitSpiltBox()
  const disable = useIsDisableSpilt()
  const ref = useRef()
  useEffect(() => {
    if (isClearAll && ref.current) {
      ref.current.clear()
    }
  }, [isClearAll])
  return (
    <SplitInboxPillItem
      ref={ref}
      disable={disable}
      spiltInbox={spiltInbox}
      onSelect={splitInbox => {
        const nextCondition = {
          ...condition,
          split: splitInbox.map(item => item.labelId || item.id),
        }
        onSelect(nextCondition)
      }}
    />
  )
}
