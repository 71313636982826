// @flow
import { documentVisibility, isPromiseFulfilled } from 'utils'

type TimerConfig = {|
  default: number,
  step: number,
  maxRetries: number,
  delayOnError?: boolean,
  delayOnInvisible?: boolean,
|}
export function createIntervalJob(fn: Function, timer: TimerConfig) {
  const DEFAULT_TIMEOUT = timer.default
  const MAX_TIMEOUT = timer.default + timer.step * timer.maxRetries

  let jobPromise = Promise.resolve()
  let timeout = DEFAULT_TIMEOUT
  let timeoutId = null
  let isStop = false
  let isError = false
  let isOnline = true

  const docVisibility = documentVisibility()

  function delay(input) {
    return Math.min(input + timer.step, MAX_TIMEOUT)
  }

  function stop() {
    isStop = true
    clearTimeout(timeoutId)
  }

  function start() {
    stop()

    if (!isOnline) {
      return
    }

    let isDelayed = false
    const { delayOnError = false, delayOnInvisible = true } = timer

    // Reset the status flag each time
    isStop = false
    isError = delayOnError ? isError : false

    if (docVisibility.isVisible()) {
      if (!isError && timeout > DEFAULT_TIMEOUT) {
        // Reset the timeout when the request succeed or
        // the webpage is visibile
        timeout = DEFAULT_TIMEOUT
      }
    } else if (delayOnInvisible) {
      // Delay when invisible
      isDelayed = true
      timeout = delay(timeout)
    }

    jobPromise = (async () => {
      try {
        await fn()
        isError = false
        if (docVisibility.isVisible() && timeout > DEFAULT_TIMEOUT) {
          timeout = DEFAULT_TIMEOUT
        }
      } catch (e) {
        isError = true
        if (delayOnError && !isDelayed) {
          // Delay when the request failed
          timeout = delay(timeout)
        }
      }
    })().then(() => {
      if (!isStop && timeout < MAX_TIMEOUT) {
        timeoutId = setTimeout(start, timeout)
      } else {
        stop()
      }
    })
  }

  function onlineHandler() {
    isOnline = true
    jobPromise.then(start)
  }

  function offlineHandler() {
    isOnline = false
    jobPromise.then(stop)
  }

  function visibilityChangeHandler() {
    isPromiseFulfilled(jobPromise).then(isFulfilled => {
      if (isFulfilled && docVisibility.isVisible()) {
        jobPromise.then(start)
      }
    })
  }

  return {
    stop: () => {
      jobPromise.then(stop)
    },
    start: () => {
      jobPromise.then(start)
    },
    addEventListner: () => {
      window.addEventListener('online', onlineHandler)
      window.addEventListener('offline', offlineHandler)
      docVisibility.addEventListener(visibilityChangeHandler)
    },
    removeEventListener: () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
      docVisibility.removeEventListener(visibilityChangeHandler)
    },
  }
}
