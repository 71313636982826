// @flow
import React, { useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, generatePath } from 'react-router-dom'
import isNil from 'lodash/isNil'

import { getAccountUnreadCount } from 'core/labels/selectors'
import { useCurrentAuth, useOrderId } from 'core/auth/hooks'
import { useRetrofitAccounts } from 'core/retrofit/hooks'
import { getModalStatus } from 'core/modals/selectors'
import { useLocalStorageFlag, localStorageFlags } from 'core/flags'
import {
  labelNames,
  retrofitAccountFilter,
  routePaths,
  modalTypes,
} from 'utils/constants'

import AccountSwitcher, {
  AccountItem,
} from '@edison/webmail-ui/components/Retrofit/AccountSwitcher'

const accountLoadingModalStatusSelector = getModalStatus(
  modalTypes.accountLoading
)

const View = () => {
  const history = useHistory()
  const userId = useOrderId()
  const list = useAccountList()
  const { active, setActive } = useRetrofitAccounts()
  const newRetrofitUserFlag = useLocalStorageFlag(
    localStorageFlags.newRetrofitUserKey
  )

  const accountLoadingModalStatus = useSelector(
    accountLoadingModalStatusSelector
  )

  const showProTip = useMemo(() => {
    return !accountLoadingModalStatus && newRetrofitUserFlag.value
  }, [newRetrofitUserFlag.value, accountLoadingModalStatus])

  return (
    <AccountSwitcher
      showProTip={showProTip}
      current={active ? active.emailAddress : ''}
      onAddNewAccount={() => {
        history.push(generatePath(routePaths.retrofitAdd, { userId }))
      }}
      onCloseProTip={() => newRetrofitUserFlag.toggle()}
    >
      {list.map(({ email, unread, ecUUID, isActive, labelUUID }, index) => (
        <AccountItem
          key={index}
          email={email}
          unread={unread}
          active={isActive}
          onClick={() => {
            setActive(ecUUID, labelUUID)
          }}
        />
      ))}
    </AccountSwitcher>
  )
}

View.displayName = 'AccountSwitcher'

export default View

export const useAccountList = () => {
  const { t } = useTranslation()
  const auth = useCurrentAuth()
  const unreadCount = useSelector(getAccountUnreadCount)
  const { active, accounts } = useRetrofitAccounts()

  const isActive = useCallback(
    ecUUID => {
      if (ecUUID === retrofitAccountFilter.ALL) {
        return isNil(active)
      } else {
        return !isNil(active) && active.ecUUID === ecUUID
      }
    },
    [active]
  )

  return useMemo(
    () =>
      [
        {
          ecUUID: retrofitAccountFilter.ALL,
          email: t('retrofit.accounts.switcher.default'),
          unread: unreadCount[labelNames.allAccounts] || 0,
        },
        {
          ecUUID: retrofitAccountFilter.ONMAIL,
          labelUUID: labelNames.onmail,
          email: auth ? auth.user : 'Unkonwn',
          unread: unreadCount[labelNames.onmail] || 0,
        },
        ...accounts.map(({ ecUUID, labelUUID, emailAddress }) => ({
          ecUUID,
          email: emailAddress,
          labelUUID,
          unread: unreadCount[labelUUID] || 0,
        })),
      ].reduce(
        (prev, curr) => [
          ...prev,
          {
            ...curr,
            isActive: isActive(curr.ecUUID),
          },
        ],
        []
      ),
    [isActive, accounts, auth, unreadCount]
  )
}
