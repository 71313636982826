import type { CalendarColorsResource } from '@edison/webmail-core/types/calendar'
import cloneDeep from 'lodash/cloneDeep'
export class ReduxColors {
  colors: CalendarColorsResource

  constructor(colors?: CalendarColorsResource) {
    this.colors = colors || {
      calendar: {},
      event: {},
      kind: 'calendar#colors',
      updated: 0,
    }
  }
  toJSON(): CalendarColorsResource {
    return cloneDeep(this.colors)
  }
  clone() {
    return new ReduxColors(this.colors)
  }

  isEmpty() {
    return (
      Object.keys(this.colors.calendar) === 0 ||
      Object.keys(this.colors.event).length === 0
    )
  }

  get updated() {
    return this.colors.updated
  }

  static isNew(oldSelf: ReduxColors, newColors: CalendarColorsResource) {
    return oldSelf.updated < newColors.updated
  }

  static ifNewerReturnNewColors(
    oldSelf: ReduxColors,
    newColors: CalendarColorsResource
  ) {
    if (ReduxColors.isNew(oldSelf, newColors)) {
      return new ReduxColors(newColors)
    }
  }

  _getColor(color: { dark: string, light: string }, isLight: boolean = true) {
    if (color) {
      if (isLight) {
        return color.light
      } else {
        return color.dark
      }
    }
  }

  getCalendarColorById(colorId: string, isLight: boolean = true) {
    return this._getColor(this.colors.calendar[colorId], isLight)
  }

  getEventColorById(colorId: string, isLight: boolean = true) {
    return this._getColor(this.colors.event[colorId], isLight)
  }

  _flattenColors(
    colors: { [colorId: string]: { dark: string, light: string } },
    isLight: boolean
  ) {
    const mapped: { [colorId: string]: string } = {}
    for (const key of Object.keys(colors)) {
      const color = colors[key]
      if (isLight) {
        mapped[key] = color.light
      } else {
        mapped[key] = color.dark
      }
    }
    return mapped
  }

  getCalendarBackgroundColors(isLight: boolean = true) {
    return this._flattenColors(this.colors.calendar, isLight)
  }

  getEventBackgroundColors(isLight: boolean = true) {
    return this._flattenColors(this.colors.event, isLight)
  }
}
