// @flow
import React from 'react'
import { useAppPassword } from 'core/auth/hooks'
import Dialog from '@material-ui/core/Dialog'

import { PasswordAuth, NameSetup, Detail } from './Steps'

type Props = {
  isOpen: boolean,
  toggle: () => void,
}

const ASPModal = ({ isOpen, toggle }: Props) => {
  const {
    step,
    stepNames,
    password,
    onCreate,
    onRemove,
    onGrantPassword,
  } = useAppPassword()

  const steps = {
    [stepNames.PASSWORD]: PasswordAuth,
    [stepNames.NAME]: NameSetup,
    [stepNames.DETAIL]: Detail,
  }

  const Children = steps[step.value] || steps[stepNames.PASSWORD]

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth="sm"
      open={isOpen}
      onClose={toggle}
    >
      <Children
        password={password}
        onClose={toggle}
        // Used to create ASP with name
        onSubmit={onCreate}
        // Used to delete specified ASP
        onRemove={onRemove}
        // Used to fetch ASP list with password auth
        onGrantPassword={onGrantPassword}
        // Used to jump to the create form
        onCreate={() => step.set(stepNames.NAME)}
      />
    </Dialog>
  )
}

export default ASPModal
