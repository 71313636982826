import React from 'react'
import { useTranslation } from 'react-i18next'
import { ForwardToInboxIcon } from '@edison/webmail-ui/components/Icons'

const ForwardView = ({ zeroImage, isBottom, hasWraper, onPress }) => {
  const { t } = useTranslation()
  const content = (
    <div
      className={`${
        isBottom ? 'absolute bottom-0 ' : ''
      }w-full py-5 flex flex-col items-center`}
    >
      <ForwardToInboxIcon
        style={{ fontSize: '2rem', color: zeroImage ? '#fff' : '#999' }}
      />
      <div
        className={`text-center py-1 text-sm ${
          zeroImage ? 'text-white font-semibold' : 'text-light'
        }`}
        style={{ width: 360 }}
      >
        {t('settings.splitInbox.forward.content')}
      </div>
      <div
        className={`text-center py-5 text-sm font-semibold cursor-pointer ${
          zeroImage
            ? 'text-white text-xl'
            : 'text-primary hover:text-primary-active'
        }`}
        onClick={() => {
          onPress && onPress()
          window.open(
            'https://support.onmail.com/hc/en-us/articles/21200305589005',
            '_blank'
          )
        }}
      >
        {t('settings.splitInbox.forward.link')}
      </div>
    </div>
  )
  if (hasWraper) {
    return (
      <div
        className={`relative w-full h-full flex items-center justify-center`}
      >
        {content}
      </div>
    )
  }
  return content
}

export default React.memo(ForwardView)
