// @flow
import { createReducer } from 'utils/redux'
import {
  fetchPrimaryCalendarAction,
  fetchUserSecondaryCalendarsAction,
  updateCalendarAction,
} from './actions-calendars'
import { clearCalendarUserDataAction } from './actions-common'
import { ReduxCalendar } from './modal-redux-calendar'
import type { ClearCalendarUserData } from './types-common'
import type {
  CalendarsActions,
  CalendarsState,
  FetchPrimaryCalendarRequestSuccess,
  FetchUserSecondaryCalendarsRequestSuccess,
  UpdateCalendarRequest,
} from './types-calendars'
import { UpdateCalendarRequestFailure } from './types-calendars'
import { ReduxColors } from './modal-redux-colors'

export const initCalendarsState: CalendarsState = {
  primary: new ReduxCalendar(),
  secondary: [],
}

function fetchPrimaryCalendarSuccess(
  state: CalendarsState,
  action: FetchPrimaryCalendarRequestSuccess
) {
  const newCalendar = ReduxCalendar.ifNewerReturnNewCalendar(state.primary, {
    calendar: action.payload.calendar,
    metaData: action.payload.metaData,
    colors: new ReduxColors(action.payload.colors),
  })
  if (newCalendar) {
    return {
      ...state,
      primary: newCalendar,
    }
  }
  return state
}
function fetchSecondaryCalendarSuccess(
  state: CalendarsState,
  action: FetchUserSecondaryCalendarsRequestSuccess
) {
  return {
    ...state,
    secondary: action.payload.map(data => {
      data.colors = new ReduxColors(data.colors)
      return new ReduxCalendar(data)
    }),
  }
}

const updateCalendarRequest = (
  state: CalendarsState,
  action: UpdateCalendarRequest
) => {
  const calendarRedux = action.payload
  if (calendarRedux.isPrimary) {
    return {
      ...state,
      primary: calendarRedux.clone(),
    }
  } else {
    return {
      ...state,
      secondary: state.secondary.map((calendar: ReduxCalendar) => {
        if (calendar.isCalendarById(calendarRedux.globalId)) {
          return calendarRedux.clone()
        }
        return calendar
      }),
    }
  }
}
const onUpdateCalendarFailed = (
  state: CalendarsState,
  action: UpdateCalendarRequestFailure
) => {
  const { oldCalendar: calendarRedux } = action.payload
  if (!calendarRedux) {
    return state
  }
  if (calendarRedux.isPrimary) {
    return {
      ...state,
      primary: calendarRedux.clone(),
    }
  } else {
    return {
      ...state,
      secondary: state.secondary.map((calendar: ReduxCalendar) => {
        if (calendar.isCalendarById(calendarRedux.globalId)) {
          return calendarRedux.clone()
        }
        return calendar
      }),
    }
  }
}
const onClearUserData = (
  state: CalendarsState,
  action: ClearCalendarUserData
) => {
  return initCalendarsState
}
export default createReducer<CalendarsState, CalendarsActions>(
  initCalendarsState,
  {
    [fetchPrimaryCalendarAction.success.toString()]: fetchPrimaryCalendarSuccess,
    [fetchUserSecondaryCalendarsAction.success.toString()]: fetchSecondaryCalendarSuccess,
    [updateCalendarAction.request.toString()]: updateCalendarRequest,
    [updateCalendarAction.failure.toString()]: onUpdateCalendarFailed,
    [clearCalendarUserDataAction.toString()]: onClearUserData,
  }
)
