// @flow
import Search from '@edison/webmail-ui/components/Search'
import * as analytics from 'core/analytics/actions'
import { useLabelInRoute } from 'core/labels/hooks'
import * as actions from 'core/search/actions'
import {
  useCache,
  useGetPlaceholder,
  useIsExistQuery,
  useIsInbox,
  useSearchBadge,
} from 'core/search/hooks'
import * as selectors from 'core/search/selectors'
import * as splitInboxesSelectors from 'core/split-inboxes/selectors'
import * as threadActions from 'core/threads/actions'
import * as threadSelectors from 'core/threads/selectors'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RouterHistory } from 'react-router-dom'
import { useLocation, withRouter } from 'react-router-dom'
import { SimpleHelmet } from 'screens/Helmets'
import type { Dispatch } from 'types/redux'
import MoreInTips from './MoreInTips'
import { PillList } from './Pill'
import RecommendSearch from './RecommendSearch'
import ThreadList from './ThreadList'

type Props = {
  history: RouterHistory,
}

const getSearchQuery = selectors.getSearchQuery()
const isSearchLoading = selectors.isSearchLoading()
const getActiveLabel = threadSelectors.getActiveLabel()
const getSplitInboxLabels = splitInboxesSelectors.getSplitInboxLabels()

const SearchView = ({ history }: Props) => {
  const location = useLocation()
  const dispatch: Dispatch = useDispatch()
  const query = useSelector(getSearchQuery)
  const isLoading = useSelector(isSearchLoading)
  const activeLabel = useSelector(getActiveLabel)
  const placeholder = useGetPlaceholder()
  const isExistQuery = useIsExistQuery()
  const isInbox = useIsInbox()
  const { cacheSearchQuery } = useCache()
  const count = useSelector(selectors.getSearchCount())
  const searchResultCount = useMemo(() => {
    if (isInbox && !isExistQuery) {
      return 0
    }
    return count
  }, [count, isInbox, isExistQuery])
  const splitInboxLabels = useSelector(getSplitInboxLabels)
  const { options, onClose } = useSearchBadge()
  const textAreaRef = useRef()
  const ref = useRef()
  const onChange = newQuery => {
    if (!query.length && !newQuery.trim().length) {
      return
    }
    dispatch(actions.focus())
    dispatch(actions.setQuery({ query: newQuery }))
  }
  const backUrl = useLabelInRoute(
    activeLabel,
    splitInboxLabels.includes(activeLabel)
  )

  const onCancel = () => {
    history.replace({ ...location, pathname: backUrl })
    dispatch(actions.setQuery({ query: '' }))
  }

  const onBlur = () => {
    cacheSearchQuery(query)
    dispatch(actions.blur())
  }

  const onFocus = () => {
    dispatch(actions.focus())
  }

  useEffect(() => {
    return () => {
      dispatch(actions.resetSearchStateAction())
      dispatch(threadActions.resetSelectThread())
    }
  }, [])

  const onHandleEnter = useCallback(() => {
    ref.current && ref.current.onSelect()
    dispatch(analytics.search.userSearchSelectAutocomplete(query))
    onBlur()
  }, [query])

  const onHandleBackspace = useCallback(() => {
    if (!query && options.length) {
      const { item, type } = options[options.length - 1]
      onClose(item, type)
    }
  }, [query, options])

  return (
    <>
      <SimpleHelmet title="Search" />
      <Search
        ref={textAreaRef}
        isLoading={isLoading}
        count={searchResultCount}
        options={options}
        onClose={onClose}
        tips={<MoreInTips />}
        pill={<PillList />}
        placeholder={placeholder}
        query={query}
        onHandleEnter={onHandleEnter}
        onHandleBackspace={onHandleBackspace}
        onChange={onChange}
        tokens={renderProps => (
          <RecommendSearch ref={ref} renderProps={renderProps} />
        )}
        onCancel={onCancel}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <ThreadList />
      </Search>
    </>
  )
}

export default withRouter(SearchView)
