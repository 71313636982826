// @flow
import { createSelector } from 'reselect'

import { getLoadingStatus } from 'core/loading/selectors'

import type { State, Selector } from 'types/state'
import type { FeatureFlags } from './types'

export const getBetaFeaturesState = (state: State) => state.betaFeatures

export const getBetaFeatures: Selector<FeatureFlags> = createSelector(
  getBetaFeaturesState,
  state => state.features
)

export function makeIsFeatureAvailable(feature: string): Selector<boolean> {
  return createSelector(
    getBetaFeaturesState,
    state => !!state.features[feature]
  )
}

export const isBetaFeaturesLoading = getLoadingStatus('BETA_FEATURE_LIST', true)

export function makeIsFeatureNew(feature: string): Selector<boolean> {
  return createSelector(
    getBetaFeaturesState,
    state => !!state.newFeatures[feature]
  )
}
