// @flow
import i18next from 'i18next'
import { batch } from 'react-redux'

import * as retrofitApis from '@edison/webmail-core/api/retrofit'
import { fetchLabels, createTemporaryRetrofitLabel } from 'core/labels/actions'
import { getLabelState } from 'core/labels/selectors'
import { refreshThreads } from 'core/threads/actions'
import { getActiveLabel } from 'core/threads/selectors'
import { getAuth } from 'core/auth/selectors'
import * as selectors from './selectors'
import { createAction } from 'utils/redux'

import type { ConnectionStatus } from '@edison/webmail-core/utils/constants'
import type { ActionCreator, ThunkAction } from 'types/redux'
import type {
  FetchAllAccountsRequest,
  FetchAllAccountsSuccess,
  FetchAllAccountsFailure,
  FetchAccountRequest,
  FetchAccountSuccess,
  FetchAccountFailure,
  FetchSyncStatusRequest,
  FetchSyncStatusSuccess,
  FetchSyncStatusFailure,
  RemoveAccountRequest,
  RemoveAccountSuccess,
  RemoveAccountFailure,
  SetRetrofitAccount,
  StartSyncProgress,
  StopSyncProgress,
} from './types'

export const setFilterAccountAction: ActionCreator<SetRetrofitAccount> = createAction(
  'SET_RETROFIT_ACCOUNT'
)

export function setFilterAccount(ecUUID: string): ThunkAction {
  return (dispatch, getState) => {
    const activeLabel = getActiveLabel()(getState())
    const activeRetrofit = selectors.getActiveAccount(getState())

    if (activeRetrofit && activeRetrofit.ecUUID === ecUUID) {
      return
    }

    const { labelUUID } = activeRetrofit || {}

    dispatch(setFilterAccountAction({ ecUUID, activeLabel, labelUUID }))
    return Promise.all([
      dispatch(fetchLabels()),
      dispatch(refreshThreads(activeLabel)),
    ])
  }
}

export const fetchAllAccountsActions: {
  request: ActionCreator<FetchAllAccountsRequest>,
  success: ActionCreator<FetchAllAccountsSuccess>,
  failure: ActionCreator<FetchAllAccountsFailure>,
} = {
  request: createAction('RETROFIT_FETCH_ALL_ACCOUNTS_REQUEST'),
  success: createAction('RETROFIT_FETCH_ALL_ACCOUNTS_SUCCESS'),
  failure: createAction('RETROFIT_FETCH_ALL_ACCOUNTS_FAILURE'),
}

export function fetchAllAccounts(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const labelState = getLabelState(state)
    const isLoading = selectors.isAllAccountsLoading(state)

    if (!auth) {
      dispatch(
        fetchAllAccountsActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    if (isLoading) {
      // Skip while the last request is not finished
      return
    }

    try {
      dispatch(fetchAllAccountsActions.request())
      const res = await retrofitApis.getAccounts({ auth })
      const { authorizations } = res.result

      batch(() => {
        for (let each of authorizations) {
          const { labelUUID } = each

          if (!(labelUUID in labelState)) {
            dispatch(createTemporaryRetrofitLabel({ id: labelUUID }))
          }
        }

        dispatch(
          fetchAllAccountsActions.success({
            accounts: authorizations || [],
          })
        )
      })
    } catch (e) {
      dispatch(fetchAllAccountsActions.failure({ message: e.message }))
    }
  }
}

export const fetchAccountActions: {
  request: ActionCreator<FetchAccountRequest>,
  success: ActionCreator<FetchAccountSuccess>,
  failure: ActionCreator<FetchAccountFailure>,
} = {
  request: createAction('RETROFIT_FETCH_ACCOUNT_REQUEST'),
  success: createAction('RETROFIT_FETCH_ACCOUNT_SUCCESS'),
  failure: createAction('RETROFIT_FETCH_ACCOUNT_FAILURE'),
}

export function fetchAccount(ecuuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(
        fetchAccountActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    try {
      dispatch(fetchAccountActions.request())
      const res = await retrofitApis.getAccount(ecuuid, { auth })
      dispatch(fetchAccountActions.success({ account: res.result }))
    } catch (e) {
      dispatch(fetchAccountActions.failure({ message: e.message }))
    }
  }
}

export const fetchSyncStatusActions: {
  request: ActionCreator<FetchSyncStatusRequest>,
  success: ActionCreator<FetchSyncStatusSuccess>,
  failure: ActionCreator<FetchSyncStatusFailure>,
} = {
  request: createAction('RETROFIT_FETCH_SYNC_STATUS_REQUEST'),
  success: createAction('RETROFIT_FETCH_SYNC_STATUS_SUCCESS'),
  failure: createAction('RETROFIT_FETCH_SYNC_STATUS_FAILURE'),
}

export function fetchSyncStatus(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const isLoading = selectors.isSyncStatusLoading(getState())

    if (!auth) {
      dispatch(
        fetchSyncStatusActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return
    }

    if (isLoading) {
      // Skip while the last request is not finished
      return
    }

    try {
      dispatch(fetchSyncStatusActions.request())
      const res = await retrofitApis.getSyncStatus({ auth })
      dispatch(fetchSyncStatusActions.success(res.result))
    } catch (e) {
      dispatch(fetchSyncStatusActions.failure({ message: e.message }))
      throw e
    }
  }
}

export const removeAccountActions: {
  request: ActionCreator<RemoveAccountRequest>,
  success: ActionCreator<RemoveAccountSuccess>,
  failure: ActionCreator<RemoveAccountFailure>,
} = {
  request: createAction('RETROFIT_REMOVE_ACCOUNT_REQUEST'),
  success: createAction('RETROFIT_REMOVE_ACCOUNT_SUCCESS'),
  failure: createAction('RETROFIT_REMOVE_ACCOUNT_FAILURE'),
}

export function removeAccount(ecUUID: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (!auth) {
      dispatch(
        removeAccountActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(removeAccountActions.request())
      await retrofitApis.removeAccount(ecUUID, { auth })
      dispatch(removeAccountActions.success({ ecUUID }))
      return true
    } catch (e) {
      dispatch(removeAccountActions.failure({ message: e.message }))
      return false
    }
  }
}

/**
 * Compare the retrofit account status with the one at remote
 */
export function checkRemoteAccountStatus(
  ecUUID: string,
  expected: ConnectionStatus
): ThunkAction {
  return async (dispatch, getState) => {
    await dispatch(fetchAccount(ecUUID))

    const account = selectors.makeGetAccount(ecUUID)(getState())

    const isExpected = account ? account.status === expected : false

    return isExpected
  }
}

export const syncProgress: {
  start: ActionCreator<StartSyncProgress>,
  stop: ActionCreator<StopSyncProgress>,
} = {
  start: createAction('SYNC_PROGRESS_START'),
  stop: createAction('SYNC_PROGRESS_STOP'),
}
