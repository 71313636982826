// @flow
import { createSelector } from 'reselect'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'

import { getOrderId } from 'core/auth/selectors'

import * as localStorage from 'common/storage/localStorage'
import { userTutorialKey } from './constants'
import { tutorialsDecoder } from './utils'

import type { UserTutorialFlag } from './constants'
import type { Selector } from 'types/state'

const getTutorialFlags = () => {
  try {
    const val = localStorage.get(userTutorialKey)

    return mapValues(JSON.parse(val), item => tutorialsDecoder(item))
  } catch (e) {
    return {}
  }
}

export const getTutorialFlag = (flag: UserTutorialFlag): Selector<boolean> => {
  return createSelector(getTutorialFlags, getOrderId, (flags, orderId) => {
    return get(flags, `${orderId || ''}.${flag}`) === true
  })
}
