import React, { useMemo, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import Highlight from '@edison/webmail-ui/components/Highlight'
import cn from 'classnames'

const Subject = ({ subject, highlightWord, className }, ref) => {
  const { t } = useTranslation()
  const children = useMemo(
    () => (
      <span ref={ref}>
        <Highlight
          className={cn('inline-block', className)}
          keyword={highlightWord}
          highlightClassName="text-primary font-bold"
        >
          {subject || t('thread.subject.none')}
        </Highlight>
      </span>
    ),
    [subject]
  )
  return children
}

export default forwardRef(Subject)
