// @flow
import React, { forwardRef, useMemo } from 'react'
import { useThreadBatchActions, useActiveLabel } from 'core/threads/hooks'
import { labelNames } from 'utils/constants'

import EmptyTrashBar from '@edison/webmail-ui/components/EmptyTrashBar'
import { getTotalCountByLabel } from '../../core/labels/selectors'
import { useSelector } from 'react-redux'

export default forwardRef<Object, HTMLDivElement>((props, ref) => {
  const activeLabel = useActiveLabel()
  const batchActions = useThreadBatchActions(true)
  const threadsTotalSelector = useMemo(
    () => getTotalCountByLabel(activeLabel),
    [activeLabel]
  )
  const total = useSelector(threadsTotalSelector)
  if (activeLabel !== labelNames.trash || total === 0) {
    return null
  }

  return (
    <div ref={ref} {...props}>
      <EmptyTrashBar onClick={batchActions.deleteForever} threadCount={total} />
    </div>
  )
})
