// @flow
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import isNil from 'lodash/isNil'
import first from 'lodash/first'
import trim from 'lodash/trim'
import get from 'lodash/get'
import qs from 'qs'
import { withAuth } from '@edison/webmail-core/api/utils'
import { getAuth } from 'core/auth/selectors'
import { getContactByEmail } from 'core/contacts/selectors'
import Avatar, { IconAvatar } from '@edison/webmail-ui/components/Avatar'
import AlternateEmail from '@material-ui/icons/AlternateEmail'
import { WM_API_HOST } from 'utils/constants'
import { getAvatarUrl, QueueMap } from 'utils'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

// To identify the avatar url for specified domain
const _v = btoa(window.location.host)
const caches = new Map()
const queueMap = new QueueMap({ concurrency: 1 })

const readImage = async (src, auth, rest) => {
  if (caches.has(src)) {
    return caches.get(src)
  }
  let [url, search] = src.split('?')
  search = qs.stringify({
    ...qs.parse(search),
    _v,
  })
  const res = await fetch(
    `${url}?${search}`,
    withAuth(auth)({
      ...rest,
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    })
  )
  const json = await res.json()
  caches.set(src, json.result)
  return json.result
}

type Props = {
  src: string,
  alt: string,
  className?: string,
  rounded?: boolean,
  mode?: 'cover' | 'contain',
}

export default function AuthAvatar({ src, alt, ...rest }: Props) {
  const authAvatar = useAuthAvatar(src, alt)

  return <Avatar {...rest} src={authAvatar.src} alt={authAvatar.alt} />
}

export function OnMailAvatar(props) {
  const { t } = useTranslation()
  const src = t('onmail-avatar.png')
  return <Avatar {...props} src={src} alt="onMail" />
}

export function DomainAvatar(props) {
  return (
    <IconAvatar
      icon={<AlternateEmail color="primary" />}
      containerProps={{ className: cn('bg-gray-divider', props.className) }}
    />
  )
}

const ContactAvatar = ({ email, name, className, isSizeCls }) => {
  const contactSelector = useMemo(() => getContactByEmail(email), [email])
  const contact = useSelector(contactSelector)
  const avatar = get(contact, 'avatar')
  const src = !!avatar ? avatar : getAvatarUrl(email)

  return (
    <AuthAvatar
      src={src}
      isSizeCls={isSizeCls}
      className={className}
      alt={(name || '').trim() || email}
    />
  )
}

export function AuthEmailAvatar({
  name,
  email,
  className,
  isSizeCls = false,
}: {
  name?: string,
  email: string,
  className?: string,
}) {
  if (isNil(email)) {
    return <AuthAvatar src="" alt="?" className={className} />
  } else {
    return (
      <ContactAvatar
        isSizeCls={isSizeCls}
        email={email}
        name={name}
        className={className}
      />
    )
  }
}

export const useAuthAvatar = (
  src: string,
  alt: string
): { src: string, alt: string } => {
  const [avatarUrl, setAvatar] = useState<string>('')
  const auth = useSelector(getAuth())

  const altStr = useMemo(
    () =>
      first(
        alt
          .toString()
          .split(',')
          .map(item => trim(item))
      )
        .split(' ')
        .slice(0, 2)
        .filter(Boolean)
        .map(item => item.charAt(0))
        .join(''),
    [alt]
  )

  useEffect(() => {
    // Initial state when image.src has changed
    setAvatar('')

    if (!src || isNil(auth)) return

    const isAuthImage = Boolean(/(^http(s)?:\/\/?.+wmapi)|(^\/v)/.exec(src))

    if (isAuthImage) {
      let imageSrc = src
      if (/^\/v/.exec(src)) {
        imageSrc = `${WM_API_HOST}${src}`
      }
      if (caches.has(imageSrc)) {
        setAvatar(caches.get(imageSrc) || '')
      } else {
        queueMap.enqueue(imageSrc, () =>
          readImage(imageSrc, auth)
            .then(res => setAvatar(res || ''))
            .catch(console.error)
        )
      }
    } else setAvatar(src)
  }, [src])

  return { src: avatarUrl, alt: altStr }
}
