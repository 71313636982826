import moment from 'moment'
import escapeRegExp from 'lodash/escapeRegExp'
import { QUOTATION_DATE_FORMAT } from 'utils/responseTemplate'
import escapeStringRegexp from 'escape-string-regexp'

//the quotation has a static format 'On ${replyMessageDateFormat}'
export function formatSnippet(snippet, { replyMessage, largeAttachments }) {
  if (largeAttachments.length) {
    const index = snippet.indexOf(largeAttachments[0].name)
    if (~index) {
      snippet = snippet.slice(0, index)
    }
  }

  if (!replyMessage) return snippet
  // time format maybe not correct
  const replyMessageDate = moment(replyMessage.date * 1000).format(
    QUOTATION_DATE_FORMAT
  )
  const index = snippet.indexOf(`On ${replyMessageDate}`)
  if (~index) return snippet.slice(0, index)
  return snippet
}

function getTextNode(node) {
  let textNodes = []
  const helper = input => {
    if (input) {
      if (input.nodeName === '#text' && input.data.trim()) {
        textNodes.push(input)
      } else {
        // Skip the highlight element
        if (input.nodeName === 'SPAN' && input.className === 'hl') {
          return
        }
        input.childNodes.forEach(helper)
      }
    }
    return
  }
  helper(node)
  return textNodes
}

const highlightNode = document.createElement('span')
highlightNode.setAttribute('class', 'hl')

export function highlightHTML(ref, keywords) {
  let textNodes = getTextNode(ref)

  for (let keyword of keywords) {
    const kwLength = keyword.length
    const kwRegex = new RegExp(
      `(?:[^\\w]|^)(${escapeRegExp(keyword)})(?:[^\\w]|$)`,
      'gi'
    )

    textNodes = textNodes.flatMap(textNode => {
      const text = textNode.data
      let [keywordNodes, nextTextNodes] = [[], []]
      let [m, lastIndex] = [null, 0]

      while ((m = kwRegex.exec(text)) !== null) {
        const [fullMatch, captured] = m
        const currIndex = m.index + fullMatch.indexOf(captured)

        nextTextNodes.push(
          document.createTextNode(text.slice(lastIndex, currIndex))
        )

        const hlNode = highlightNode.cloneNode(true)
        hlNode.innerText = captured
        keywordNodes.push(hlNode)

        lastIndex = currIndex + kwLength

        if (m.index === kwRegex.lastIndex) {
          kwRegex.lastIndex = lastIndex
        }
      }

      nextTextNodes.push(document.createTextNode(text.slice(lastIndex)))

      textNode.replaceWith(
        ...nextTextNodes.flatMap((node, index) =>
          index < keywordNodes.length ? [node, keywordNodes[index]] : node
        )
      )

      return nextTextNodes
    })
  }
}

export function getMessageText(message) {
  if (message.text) {
    return message.text
  } else if (message.html) {
    return getHtmlText(message.html)
  } else {
    return ''
  }
}

export function getHtmlText(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return doc.body.innerText
}
export function isMessageContainKeywords(message, keywords) {
  if (!keywords.length) return false
  const regex = new RegExp(keywords.map(escapeStringRegexp).join('|'), 'gi')
  return regex.test(getMessageText(message))
}

export function getKeywordLengthInDocument(doc, keywords) {
  if (!keywords.length) return 0
  const regex = new RegExp(keywords.map(escapeStringRegexp).join('|'), 'gi')
  const result = doc.body.innerText.match(regex)
  if (!result) return 0
  else return result.length
}

export function insertLargeAttachmentPlaceholder(html) {
  if (!html.includes('class="webmail_quote"')) return html

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const quotationNode = doc.querySelector('body > div.webmail_quote')

  const largeImagePlaceholder = doc.createElement('div')
  largeImagePlaceholder.setAttribute('id', 'large_attachment_placeholder')
  largeImagePlaceholder.innerText = 'b9e95ee68f5057159e055933112cff17'

  if (quotationNode) {
    quotationNode.before(largeImagePlaceholder)
    return doc.body.innerHTML
  }

  return html
}

export function clearLargeAttachmentPlaceholder(html) {
  if (!html.includes('class="webmail_quote"')) return html
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const node = doc.querySelector('#large_attachment_placeholder')
  if (node) {
    node.remove()
    return doc.body.innerHTML
  }
  return html
}

export function removeLargeAttachmentTemplate(html) {
  if (html.includes('webmail_upload_view')) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    const largeAttachmentNodes = doc.querySelectorAll(
      'body > table.webmail_upload_view'
    )
    if (largeAttachmentNodes.length) {
      largeAttachmentNodes.forEach(item => item.remove())
      return doc.body.innerHTML
    } else {
      return html
    }
  } else {
    return html
  }
}

export function isHTMLEmpty(html) {
  const div = document.createElement('div')
  div.innerHTML = html
  return !div.querySelector('img') && !div.innerText.trim()
}
