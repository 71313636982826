// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import i18next from 'i18next'

import Quote, {
  SmartFolderQuote,
} from '@edison/webmail-ui/components/InboxZero/InboxQuote'

import * as selectors from './selectors'
import { isInboxZeroQuoteEnable } from 'core/settings/selectors'
import { useActiveLabel } from 'core/threads/hooks'

import { smartFolders, labelRouteNames } from 'utils/constants'

const inboxZeroQuoteSelector = selectors.getInboxZeroQuote()
const inboxZeroQuoteAuthorSelector = selectors.getInboxZeroQuoteAuthor()

export const useExternalImage = () => {
  const activeLabel = useActiveLabel()

  const inboxZeroImage = useSelector(selectors.getUnsplashImages)

  return useMemo(() => {
    if (smartFolders.includes(activeLabel)) {
      const src = getSmartFolderImage(activeLabel)
      return { viewports: [], src }
    } else {
      return inboxZeroImage
    }
  }, [activeLabel, inboxZeroImage.src])
}

export const useInboxQuote = () => {
  const activeLabel = useActiveLabel()

  const enable = useSelector(isInboxZeroQuoteEnable)
  const inboxZeroQuote = useSelector(inboxZeroQuoteSelector)
  const inboxZeroQuoteAuthor = useSelector(inboxZeroQuoteAuthorSelector)

  return useMemo(() => {
    if (smartFolders.includes(activeLabel)) {
      return <SmartFolderQuote folder={labelRouteNames[activeLabel]} />
    } else if (enable) {
      return <Quote content={inboxZeroQuote} author={inboxZeroQuoteAuthor} />
    }

    return null
  }, [activeLabel])
}

function getSmartFolderImage(folder: string): string {
  const date = new Date().getDate()
  const index = Math.floor((date % 30) / 10)
  // In a month
  // 1 - 9, display the first image
  // 10 - 19, display the second image
  // 20 - 29, display the third image
  return i18next.t(`inboxZero.${labelRouteNames[folder]}.background.${index}`)
}
