// @flow
import type { SettingsActions } from './types-settings'
import type { ColorsActions } from './types-colors'
import type { CalendarsActions } from './types-calendars'
import type { EventsActions } from './types-events'
import type { RecurringEventModifyTypes } from './types-common'
import type { ThunkAction } from '../../types/redux'
import { fetchSettingsList } from './actions-settings'
import {
  fetchPrimaryCalendar,
  clearPrimaryCalendar,
  fetchUserSecondaryCalendars,
  updateCalendar as updateCalendarAPI,
} from './actions-calendars'
import {
  fetchUIScopeEventsByCalendarId,
  updateUIScopeEventRange,
  addEvent as addEventRedux,
  deleteEvent as deleteEventRedux,
  updateEvent as updateEventRedux,
  updateAndAddEvent,
  updateInstance,
  selectUIScopeEvent,
  deselectUIScopeEvent,
  updateEventSimpleData,
  updateEvenOrInstanceAttendee,
} from './actions-events'
import { getEventById, getSelectedUIScopeEvent } from './selectors-events'
import { ReduxEvent } from './modal-redux-event'
import { ServerError } from './actions-common'
import { recurringEventModifyType } from './constants-events'
import { getCalendarColors } from './selectors-colors'
import { fetchColors } from './actions-colors'
import {
  isOnlySimpleDataDifferent,
  needToResetAttendeesRSVPStatus,
  resetAPIAttendeeRSVPState,
} from '../../utils/calendar'
import { getAllAliasesSelector } from '../custom-domains/selectors'
import { CALENDAR_EVENT_RSVP_STATUS } from '../../utils/constants'
export {
  selectICSEvent,
  deselectICSEvent,
  fetchICSEventData,
  updateEvenOrInstanceAttendee,
} from './actions-events'

export type CalendarActions =
  | SettingsActions
  | ColorsActions
  | CalendarsActions
  | EventsActions

export const fetchSettings = fetchSettingsList
export const fetchCalendars = (): ThunkAction => {
  return async (dispatch, getState) => {
    const colors = getCalendarColors()(getState())
    if (colors.isEmpty()) {
      await dispatch(fetchColors())
    }
    const promises = [
      dispatch(fetchPrimaryCalendar()),
      dispatch(fetchUserSecondaryCalendars()),
    ]
    return Promise.all(promises)
  }
}
export const clearCalendarById = clearPrimaryCalendar
export const fetchEvents = (calendarIds: string[]): ThunkAction => {
  return async (dispatch, getState) => {
    calendarIds.forEach(calendarId => {
      dispatch(fetchUIScopeEventsByCalendarId(calendarId))
    })
  }
}
export const addEvent = addEventRedux
export const deleteEvent = (
  oldEventOrInstance: ReduxEvent,
  deleteType: RecurringEventModifyTypes
): ThunkAction => {
  return async (dispatch, getState) => {
    if (!oldEventOrInstance) {
      return Promise.reject(new Error(ServerError))
    }
    dispatch(deSelectEvent(oldEventOrInstance))
    if (
      !oldEventOrInstance.isRecurringEvent &&
      !oldEventOrInstance.isInstance
    ) {
      return dispatch(deleteEventRedux(oldEventOrInstance))
    }
    if (oldEventOrInstance.isInstance && oldEventOrInstance.isOrphan) {
      //If is orphan instance, we only allow single delete
      deleteType = recurringEventModifyType.single
    }
    const oldParentEventData = getEventById({
      calendarId: oldEventOrInstance.calendarId,
      eventId: oldEventOrInstance.eventId,
    })(getState())
    if (
      deleteType === recurringEventModifyType.single &&
      oldEventOrInstance.isInstance
    ) {
      const userAliases = getAllAliasesSelector(getState())
      const originalInstance = oldEventOrInstance.clone()
      if (!oldEventOrInstance.organizer.self) {
        oldEventOrInstance.setAttendeeRSVPStatusByUser(
          userAliases,
          CALENDAR_EVENT_RSVP_STATUS.declined.rfcValue
        )
      }
      oldEventOrInstance.cancel()
      return dispatch(
        updateInstance({
          instanceData: oldEventOrInstance,
          oldInstanceData: originalInstance,
          sendUpdates: 'all',
        })
      )
    } else if (
      oldParentEventData &&
      deleteType === recurringEventModifyType.all
    ) {
      return dispatch(deleteEventRedux(oldParentEventData))
    } else if (
      deleteType === recurringEventModifyType.thisAndFollowing &&
      oldEventOrInstance.isInstance
    ) {
      if (oldParentEventData && oldEventOrInstance.start) {
        if (
          oldParentEventData.start &&
          oldParentEventData.start.timestamp >=
            oldEventOrInstance.start.timestamp
        ) {
          return dispatch(deleteEventRedux(oldParentEventData))
        }
        const newEventData = oldParentEventData.clone()
        const newUntil = oldEventOrInstance.start.toJSUTCDate()
        if (newUntil) {
          newEventData.updateUntilExclusive(newUntil)
          return dispatch(
            updateEventRedux({
              eventData: newEventData.convertFromInstanceToEvent(),
              sendUpdates: 'all',
              updateType: recurringEventModifyType.thisAndFollowing,
            })
          )
        }
      }
    }
    return Promise.reject(new Error(ServerError))
  }
}
export const updateEvent = (
  originalEvent: ReduxEvent,
  eventData: ReduxEvent,
  updateType: RecurringEventModifyTypes
): ThunkAction => {
  return async (dispatch, getState) => {
    if (!eventData) {
      console.error('No eventData')
      return Promise.reject(new Error(ServerError))
    }
    if (!originalEvent) {
      console.error('OriginalEvent not found')
      return Promise.reject(new Error(ServerError))
    }
    dispatch(deSelectEvent(eventData))
    const userAliases = getAllAliasesSelector(getState())
    if (!originalEvent.isRecurringEvent && !eventData.isInstance) {
      eventData.originalStartTime = eventData.start
      eventData.originalEndTime = eventData.end
      eventData.increaseUpdateTime()
      if (needToResetAttendeesRSVPStatus(originalEvent, eventData)) {
        eventData.attendees = resetAPIAttendeeRSVPState(
          eventData.attendees,
          eventData.organizer.self ? userAliases : [eventData.organizer.email]
        )
      }
      return dispatch(updateEventRedux({ eventData, sendUpdates: 'all' }))
    }
    if (eventData.isInstance && eventData.isOrphan) {
      //If is orphan instance, we only allow single update
      updateType = recurringEventModifyType.single
    }
    const originalParentEvent: ReduxEvent = getEventById({
      calendarId: originalEvent.calendarId,
      eventId: originalEvent.eventId,
    })(getState())
    if (!originalParentEvent) {
      return Promise.reject(new Error(ServerError))
    }
    // const allDayChanged =
    //   originalParentEvent.isAllDayEvent !== eventData.isAllDayEvent
    // const eventsRedux = getEventsState()(getState())
    // const oldInstances = eventsRedux.getInstancesByCalendarAndEventId({
    //   calendarId: originalEvent.calendarId,
    //   eventId: originalEvent.eventId,
    // })
    if (
      updateType === recurringEventModifyType.single &&
      eventData.isInstance
    ) {
      if (needToResetAttendeesRSVPStatus(originalEvent, eventData)) {
        eventData.attendees = resetAPIAttendeeRSVPState(
          eventData.attendees,
          eventData.organizer.self ? userAliases : [eventData.organizer.email]
        )
      }
      eventData.increaseUpdateTime()
      return dispatch(
        updateInstance({
          instanceData: eventData,
          oldInstanceData: originalEvent,
          sendUpdates: 'all',
        })
      )
    } else if (updateType === recurringEventModifyType.all) {
      originalParentEvent.increaseUpdateTime()
      if (isOnlySimpleDataDifferent(originalEvent, eventData)) {
        originalParentEvent.mergeEventSimpleData(eventData)
        return dispatch(updateEventSimpleData(originalParentEvent))
      }
      originalParentEvent.mergeEventData(eventData)
      originalParentEvent.start.mergeTime(eventData.start)
      originalParentEvent.end.mergeTime(eventData.end)
      if (
        originalParentEvent.isAllDayEvent !== eventData.isAllDayEvent ||
        !originalParentEvent.isRecurringEvent
      ) {
        originalParentEvent.start = eventData.start
        originalParentEvent.end = eventData.end
        originalParentEvent.originalStartTime = eventData.start
        originalParentEvent.originalEndTime = eventData.end
      }
      if (originalParentEvent.isRecurringEvent) {
        originalParentEvent.updateRecurrenceRepeatDay()
      }
      if (needToResetAttendeesRSVPStatus(originalEvent, eventData)) {
        originalParentEvent.attendees = resetAPIAttendeeRSVPState(
          originalParentEvent.attendees,
          originalParentEvent.organizer.self
            ? userAliases
            : [originalParentEvent.organizer.email]
        )
      }
      return dispatch(
        updateEventRedux({
          eventData: originalParentEvent,
          sendUpdates: 'all',
          updateType,
        })
      )
    } else if (
      updateType === recurringEventModifyType.thisAndFollowing &&
      eventData.isInstance
    ) {
      if (eventData.start) {
        if (
          originalParentEvent.start &&
          originalParentEvent.firstInstanceId === eventData.instanceId &&
          !eventData.differentFromEvent
        ) {
          if (isOnlySimpleDataDifferent(originalEvent, eventData)) {
            originalParentEvent.mergeEventSimpleData(eventData)
            return dispatch(updateEventSimpleData(originalParentEvent))
          }
          originalParentEvent.mergeEventData(eventData)
          originalParentEvent.start = eventData.start
          originalParentEvent.end = eventData.end
          if (originalParentEvent.isAllDayEvent !== eventData.isAllDayEvent) {
            originalParentEvent.originalStartTime = eventData.start
          }
          if (originalParentEvent.isRecurringEvent) {
            originalParentEvent.updateRecurrenceRepeatDay()
          }
          if (needToResetAttendeesRSVPStatus(originalEvent, eventData)) {
            originalParentEvent.attendees = resetAPIAttendeeRSVPState(
              originalParentEvent.attendees,
              originalParentEvent.organizer.self
                ? userAliases
                : [originalParentEvent.organizer.email]
            )
          }
          originalParentEvent.increaseUpdateTime()
          return dispatch(
            updateEventRedux({
              eventData: originalParentEvent,
              sendUpdates: 'all',
              updateType: recurringEventModifyType.all,
            })
          )
        }
        const newUntil = originalEvent.start.toJSUTCDate()
        if (newUntil) {
          originalParentEvent.updateUntilExclusive(newUntil)
          originalParentEvent.increaseUpdateTime()
          if (
            eventData.rruleString.length === 0 ||
            eventData.start.timestamp < originalEvent.start.timestamp ||
            (eventData.rruleUntil > 0 &&
              eventData.rruleUntil <= eventData.start.timestamp)
          ) {
            return dispatch(
              updateAndAddEvent({
                calendarId: eventData.calendarId,
                updateEvent: originalParentEvent,
                newEvent: eventData.toAPINewEventInsertJSON({ userAliases }),
                breakEventLink: true,
                sendUpdates: 'all',
              })
            )
          } else {
            return dispatch(
              updateAndAddEvent({
                calendarId: eventData.calendarId,
                updateEvent: originalParentEvent,
                newEvent: ReduxEvent.generateAPINewDerivedRecurringEventJSON({
                  eventData,
                  originalParentEvent: originalParentEvent.originalStartTime,
                  userAliases,
                }),
                breakEventLink: false,
                sendUpdates: 'all',
              })
            )
          }
        }
      }
    }
    return Promise.reject(new Error(ServerError))
  }
}

export const updateRSVPStatus = (
  eventOrInstance: ReduxEvent,
  attendeeData: { email: string, responseStatus: string },
  updateType: RecurringEventModifyTypes
) => {
  return async (dispatch, getState) => {
    if (!eventOrInstance || !attendeeData) {
      return Promise.reject()
    }

    if (
      !eventOrInstance.isInstance ||
      updateType === recurringEventModifyType.single
    ) {
      let attendee = eventOrInstance.getAttendeeByEmail(attendeeData.email)
      if (!attendee) {
        attendee = { email: attendeeData.email }
      }
      attendee.responseStatus = attendeeData.responseStatus
      if (attendee.properties) {
        attendee.properties = {}
      }
      attendee.properties.PARTSTAT = [attendeeData.responseStatus]
      return dispatch(
        updateEvenOrInstanceAttendee({ eventOrInstance, attendee })
      )
    }
    if (updateType === recurringEventModifyType.all) {
      const event = getEventById({
        calendarId: eventOrInstance.calendarId,
        eventId: eventOrInstance.eventId,
      })(getState())
      if (event) {
        const attendee = event.getAttendeeByEmail(attendeeData.email)
        if (!attendee) {
          return Promise.reject()
        }
        attendee.responseStatus = attendeeData.responseStatus
        if (attendee.properties) {
          attendee.properties = {}
        }
        attendee.properties.PARTSTAT = [attendeeData.responseStatus]
        return dispatch(
          updateEvenOrInstanceAttendee({
            eventOrInstance: event,
            attendee,
          })
        )
      }
    }
    return Promise.reject()
  }
}

export const selectEvent = selectUIScopeEvent

export const deSelectEvent = (eventData: ReduxEvent): ThunkAction => {
  return async (dispatch, getState) => {
    const selectedEvent: ReduxEvent | null = getSelectedUIScopeEvent()(
      getState()
    )
    if (selectedEvent) {
      if (
        eventData.isInstance &&
        selectedEvent.isInstance &&
        selectedEvent.instanceId === eventData.instanceId
      ) {
        dispatch(deselectUIScopeEvent())
      } else if (
        !eventData.isInstance &&
        !selectedEvent.isInstance &&
        selectedEvent.eventId === eventData.eventId
      ) {
        dispatch(deselectUIScopeEvent())
      }
    }
  }
}

export const updateRange = updateUIScopeEventRange

// export const addToCalendar = (eventData: ReduxEvent): ThunkAction => {
//   return async (dispatch, getState) => {
//     const newEvent = eventData.clone()
//     newEvent.confirmEvent()
//     return dispatch(updateEventOrInstanceStatus(newEvent))
//   }
// }
export const updateCalendar = updateCalendarAPI
