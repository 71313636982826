// @flow
import React, { useRef, useMemo } from 'react'
import isNil from 'lodash/isNil'
import range from 'lodash/range'
import { useDispatch, useSelector } from 'react-redux'
import ThreadList from '@edison/webmail-ui/components/ThreadList'
import { selectThread, batchSelectThread } from 'core/threads/actions'
import ThreadListItem from './ThreadListItem'
import {
  hasAttachments,
  hasSifts,
  hasPriceAlerts,
  hasSpamMessages,
} from 'utils'
import { useVirtualizedList } from '@edison/webmail-ui/hooks/animations'
import type { Node } from 'react'
import type { RowType } from '@edison/webmail-ui/components/ThreadList'
import type { Thread } from '@edison/webmail-core/types/threads'
import type { Dispatch } from 'types/redux'
import { getThreadIdsMessages } from '../../core/threads/selectors'

type Props = {
  threads: $ReadOnlyArray<Thread>,
  isLoading: boolean,
  total: number,
  scrollToIndex?: ?number,
  actions: (thread: Thread) => { [actionName: string]: boolean },
  labelDisplayFilter?: string[],
  cards?: Node,
  bar?: Node,
  rowTypes?: $ReadOnlyArray<RowType>,
  loadMore: ({ startIndex: number, stopIndex: number }) => void,
  partition?: boolean,
  onClickItem?: (id: string) => mixed,
  removedIndexes?: $ReadOnlyArray<number>,
  enableQuickActionTutorial?: boolean,
  highlightWord?: string,
  hasCheckBox?: boolean,
  footer?: Node,
}

const View = ({
  threads,
  isLoading,
  total,
  actions,
  labelDisplayFilter = [],
  cards,
  bar,
  rowTypes,
  partition = false,
  onClickItem,
  removedIndexes = [],
  enableQuickActionTutorial = false,
  highlightWord,
  hasCheckBox = true,
  ...rest
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const lastSelectedIdRef = useRef()
  const threadIds = useMemo(() => threads.map(item => item.id), [threads])
  const threadMessages = useSelector(
    useMemo(() => getThreadIdsMessages(threadIds), [threadIds])
  )

  // Extra partitions inside the thread list
  const partitions = useMemo(() => {
    return [
      // Cards
      { type: 'headerCards', node: cards, include: !isNil(cards) },
      { type: 'headerBar', node: bar, include: !isNil(bar) },
    ].filter(item => item.include)
  }, [cards, bar])

  // Total items in the list after taking into account the partitions
  const _total =
    Math.max(isLoading && !total ? 5 : total, threads.length) +
    partitions.length

  // Convert list index to thread index, after taking into account partitions
  // in the list
  const listToThreadIndex = (index: number): number => {
    return index - partitions.length
  }
  const formattedRemovedIndexes = useMemo(() => {
    return removedIndexes.map(index => index + partitions.length)
  }, [removedIndexes, partitions])

  // Type for each row, including partitions to determine height of each row
  const _rowTypes: $ReadOnlyArray<RowType> = useMemo(() => {
    let rows = range(_total).map(listIndex => {
      if (partitions[listIndex]) {
        return partitions[listIndex].type
      }

      const threadIndex = listToThreadIndex(listIndex)
      const thread = threads[threadIndex]

      if (isNil(thread)) {
        return 'default'
      }

      if (!isNil(rowTypes)) {
        return rowTypes[threadIndex]
      }
      const threadWithMessages = {
        ...thread,
        messages: threadMessages[thread.id],
      }
      if (hasSpamMessages(threadWithMessages)) return 'default'
      return hasAttachments(threadWithMessages) ||
        hasSifts(threadWithMessages) ||
        hasPriceAlerts(threadWithMessages)
        ? 'chips'
        : 'default'
    })

    return rows
  }, [threads, rowTypes, partitions, threadMessages])

  const isLoaded = ({ index }) => !isNil(threads[listToThreadIndex(index)])

  const renderItem = ({ index, key, style, registerChild }) => {
    if (partitions[index]) {
      return React.cloneElement(partitions[index].node, {
        ref: registerChild,
        style,
        key,
      })
    }

    const threadIndex = listToThreadIndex(index)
    const thread = threads[threadIndex] || {}
    const { id, _className } = thread
    const isLoading = !threads[threadIndex]

    return (
      <ThreadListItem
        key={id}
        highlightWord={highlightWord}
        hasCheckBox={hasCheckBox}
        className={_className}
        isLoading={isLoading}
        registerChild={registerChild}
        index={threadIndex}
        style={style}
        thread={thread}
        actions={actions}
        labelDisplayFilter={labelDisplayFilter}
        // INFO: Only enable tutorial for quick action on the first thread list item
        enableQuickActionTutorial={index === 0 && enableQuickActionTutorial}
        onSelect={(value, shiftKey) => {
          if (shiftKey) {
            if (lastSelectedIdRef.current) {
              const start = threads.findIndex(
                item => item.id === lastSelectedIdRef.current
              )
              if (!~start) {
                dispatch(selectThread({ id, value }))
              } else {
                const end = threadIndex
                const startIndex = start < end ? start : end
                const endIndex = start < end ? end : start
                const ids = threads
                  .slice(startIndex, endIndex + 1)
                  .map(item => item.id)
                dispatch(batchSelectThread({ ids, value }))
              }
            }
          } else {
            dispatch(selectThread({ id, value }))
          }
          lastSelectedIdRef.current = id
        }}
        onClick={() => onClickItem && onClickItem(id)}
      />
    )
  }

  return (
    <ThreadList
      {...rest}
      rowTypes={_rowTypes}
      renderItem={renderItem}
      total={_total}
      isLoaded={isLoaded}
      removedIndexes={formattedRemovedIndexes}
    />
  )
}

export default ({ threads, ...rest }) => {
  const { data, removedIndexes } = useVirtualizedList(threads, ['id'])

  return <View {...rest} threads={data} removedIndexes={removedIndexes} />
}
