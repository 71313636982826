// @flow
// $FlowFixMe
import firebase from 'firebase/app'

import { remoteConfigDefaultValues, onboardingEvents } from './constants'

import { getPremiumPlansById } from 'core/premium/selectors'
import { firebaseConfig, signupFlows } from 'utils/constants'
import { reportException } from 'utils/logs'

import type { ThunkAction } from 'types/redux'

export function initializeFirebase(): ThunkAction {
  return async (dispatch, getState) => {
    try {
      firebase.initializeApp(firebaseConfig)
      initializeAnalytics()
      initializeRemoteConfig()
    } catch (e) {
      console.error('Initialize firebase failed!')
    }
  }
}

export function initializeAnalytics() {
  try {
    firebase.analytics()
  } catch (e) {
    console.error('Initialize analytics failed!')
  }
}

export async function initializeRemoteConfig() {
  try {
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.defaultConfig = remoteConfigDefaultValues
    remoteConfig.settings.minimumFetchIntervalMillis = 60 // 1 minutes = 60s

    await remoteConfig.fetchAndActivate()
  } catch (e) {
    console.error('Initialize remote config failed!')
  }
}

// Helpers
const createLogger = type => payload => logEvent({ type, payload })
const logEvent = event => {
  return async (dispatch, getState) => {
    const { type, payload } = event
    try {
      firebase.analytics().logEvent(type, payload)
    } catch (e) {
      console.error('Log event failed!')
    }
  }
}

// GENERAL
export const general = {
  pageView,
}

function pageView(location: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('page_view')

    dispatch(logger({ page_location: location }))
  }
}

function makeSimpleEventReport(event: string) {
  return function(...params) {
    return (dispatch, getState) => {
      const logger = createLogger(event)
      dispatch(logger(...params))
    }
  }
}

export const onbrdEventReport = {
  onbrdWelcomeScrLoginSelected: makeSimpleEventReport(
    onboardingEvents.welcome.loginSelected
  ),
  onbrdWelcomeScrSignUpSelected: makeSimpleEventReport(
    onboardingEvents.welcome.signUpSelected
  ),
  onbrdChooseAddressScrView: makeSimpleEventReport(
    onboardingEvents.chooseAddress.view
  ),
  onbrdChooseAddressScrInputSelected: makeSimpleEventReport(
    onboardingEvents.chooseAddress.inputSelected
  ),
  onbrdWelcomeScrContinueSelected: makeSimpleEventReport(
    onboardingEvents.chooseAddress.continueSelected
  ),
  onbrdCreatePwdScrView: makeSimpleEventReport(
    onboardingEvents.setPassword.view
  ),
  onbrdCreatePwdScrInput1Selected: makeSimpleEventReport(
    onboardingEvents.setPassword.input1Selected
  ),
  onbrdCreatePwdScrInput2Selected: makeSimpleEventReport(
    onboardingEvents.setPassword.input2Selected
  ),
  onbrdCreatePwdScrContinueSelected: makeSimpleEventReport(
    onboardingEvents.setPassword.continueSelected
  ),
  onbrdAddNameScrView: makeSimpleEventReport(onboardingEvents.setUserName.view),
  onbrdAddNameScrFirstNameSelected: makeSimpleEventReport(
    onboardingEvents.setUserName.firstNameSelected
  ),
  onbrdAddNameScrLastNameSelected: makeSimpleEventReport(
    onboardingEvents.setUserName.lastNameSelected
  ),
  onbrdAddNameScrAcceptTermsSelected: makeSimpleEventReport(
    onboardingEvents.setUserName.acceptTermsSelected
  ),
  onbrdAddNameScrContinueSelected: makeSimpleEventReport(
    onboardingEvents.setUserName.continueSelected
  ),
  onbrdRecoveryPhoneScrView: makeSimpleEventReport(
    onboardingEvents.recoveryPhone.view
  ),
  onbrdRecoveryPhoneScrInputSelected: makeSimpleEventReport(
    onboardingEvents.recoveryPhone.inputSelected
  ),

  onbrdRecoveryPhoneScrSendCodeSelected: makeSimpleEventReport(
    onboardingEvents.recoveryPhone.sendCodeSelected
  ),
  onbrdVerifyPhoneScrView: makeSimpleEventReport(
    onboardingEvents.verifyPhone.view
  ),
  onbrdVerifyPhoneScrInputSelected: makeSimpleEventReport(
    onboardingEvents.verifyPhone.inputSelected
  ),
  onbrdVerifyPhoneScrSendCodeSelected: makeSimpleEventReport(
    onboardingEvents.verifyPhone.sendCodeSelected
  ),
  onbrdDataPolicyScrView: makeSimpleEventReport(
    onboardingEvents.dataPolicy.view
  ),
  onbrdDataPolicyScrAcceptSelected: makeSimpleEventReport(
    onboardingEvents.dataPolicy.acceptSelected
  ),
  onbrdDataPolicyScrDeclineSelected: makeSimpleEventReport(
    onboardingEvents.dataPolicy.declineSelected
  ),
  onbrdRecoveryEmailScrView: makeSimpleEventReport(
    onboardingEvents.recoveryEmail.view
  ),
  onbrdRecoveryEmailScrInput1Selected: makeSimpleEventReport(
    onboardingEvents.recoveryEmail.input1Selected
  ),
  onbrdRecoveryEmailScrInput2Selected: makeSimpleEventReport(
    onboardingEvents.recoveryEmail.input2Selected
  ),
  onbrdRecoveryEmailScrSendCodeSelected: makeSimpleEventReport(
    onboardingEvents.recoveryEmail.sendCodeSelected
  ),
  onbrdVerifyEmailScrView: makeSimpleEventReport(
    onboardingEvents.verifyEmail.view
  ),
  onbrdVerifyEmailScrInputSelected: makeSimpleEventReport(
    onboardingEvents.verifyEmail.inputSelected
  ),
  onbrdVerifyEmailScrSendCodeSelected: makeSimpleEventReport(
    onboardingEvents.verifyEmail.sendCodeSelected
  ),
  onbrdSubscriptionScrView: makeSimpleEventReport(
    onboardingEvents.subscription.view
  ),
  onbrdSubscriptionScrFreeSelected: makeSimpleEventReport(
    onboardingEvents.subscription.freeSelected
  ),
  onbrdSubscriptionScrPersonalSelected: makeSimpleEventReport(
    onboardingEvents.subscription.personalSelected
  ),
  onbrdSubscriptionScrProfessionalSelected: makeSimpleEventReport(
    onboardingEvents.subscription.professionalSelected
  ),
  onbrdCompleted: makeSimpleEventReport(onboardingEvents.completed.view),
}

// ONBOARDING
export const onboarding = {
  userInvitation,
  userChooseEmail,
  userChoosePassword,
  userPayment,
  userProfile,
  userDataOptIn,
  userRecovery,
  userPricing,
  userWelcome,
  userAltName,
  userAltCustomDomain,
  setUserId,
  userOnboardStep,
  userCaptcha,
  userAccountInfo,
}

function userAccountInfo(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_account_info')
    dispatch(logger({ flow }))
  }
}

function userInvitation(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_invitation')
    dispatch(logger({ flow }))
  }
}

function userChooseEmail(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_email')
    dispatch(logger({ flow }))
  }
}

function userChoosePassword(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_password')
    dispatch(logger({ flow }))
  }
}

function userPayment(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_payment')
    dispatch(logger({ flow }))
  }
}

function userProfile(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_profile')
    dispatch(logger({ flow }))
  }
}

function userDataOptIn(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_opt_in')
    dispatch(logger({ flow }))
  }
}

function userRecovery(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_recovery')
    dispatch(logger({ flow }))
  }
}

function userPricing(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_pricing')
    dispatch(logger({ flow }))
  }
}

function userWelcome(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_welcome')
    dispatch(logger({ flow }))
  }
}

function userAltName(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('alt_onboard_name')
    dispatch(logger({ flow }))
  }
}

function userAltCustomDomain(flow: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('alt_onboard_custom_domain')
    dispatch(logger({ flow }))
  }
}

function setUserId(analyticsId: string): ThunkAction {
  return (dispatch, getState) => {
    try {
      firebase.analytics().setUserId(analyticsId)
    } catch (e) {
      console.error('Cannot set Firebase user ID')
      console.error(e)
    }
  }
}

function userCaptcha(email: string, passed: boolean): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('onboard_captcha')
    dispatch(logger({ email, passed }))
  }
}

function userOnboardStep(
  step: { id: string },
  { flow = signupFlows.A }: { flow: string } = {}
): ThunkAction {
  const stepEvent = {
    base: () => userAccountInfo(flow),
    invitation: () => userInvitation(flow),
    email: () => userChooseEmail(flow),
    password: () => userChoosePassword(flow),
    payment: () => userPayment(flow),
    profile: () => userProfile(flow),
    dataOptIn: () => userDataOptIn(flow),
    recoveryEmail: () => userRecovery(flow),
    pricing: () => userPricing(flow),
    welcome: () => userWelcome(flow),
    name: () => userAltName(flow),
    search: () => userAltCustomDomain(flow),
  }

  return (dispatch, getState) => {
    const event = stepEvent[step.id]
    if (event) {
      dispatch(event())
    }
  }
}

// PREMIUM
export const premium = {
  userPurchasePlan,
  userPurchaseDomain,
  userPaywallTrigger,
  userPaywallCta,
  userUpgradePrompt,
  userPreviewBill,
  userDowngradeWarning,
  userDowngrade,
  userUnsubscribe,
  userToggleInterval,
  setUserPlan,
  userUpgradeFromFree,
}

function userPurchasePlan(planId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState()
    const plansById = getPremiumPlansById(state)
    const plan = plansById[planId]

    const logger = createLogger('purchase')

    if (!plan.unitPrice || !plan.currency) {
      console.log('Invalid plan information when logging')
      return
    }

    dispatch(
      logger({
        value: plan.unitPrice,
        currency: plan.currency,
      })
    )

    // Additional requirement to track event in Facebook pixel
    if (window.fbq) {
      window.fbq('track', 'Purchase', {
        value: plan.unitPrice,
        currency: plan.currency,
      })
    } else {
      // Log to sentry that Facebook pixel didn't track
      reportException('FACEBOOK_PIXEL_ERROR', 'Facebook pixel not found.')
    }
  }
}

function userPurchaseDomain(price: string, currency: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('purchase')
    dispatch(logger({ value: price, currency: currency }))
  }
}

function userPaywallTrigger(feature: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_paywall_trigger')
    dispatch(logger({ feature }))
  }
}

function userPaywallCta(feature: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_paywall_cta')
    dispatch(logger({ feature }))
  }
}

function userUpgradePrompt(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_upgrade_prompt')
    dispatch(logger())
  }
}

function userPreviewBill(planId: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_preview_bill')
    dispatch(logger({ plan_id: planId }))
  }
}

function userDowngradeWarning(planId: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_downgrade_warning')
    dispatch(logger({ plan_id: planId }))
  }
}

function userDowngrade(planId: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_downgrade')
    dispatch(logger({ plan_id: planId }))
  }
}

function userUnsubscribe(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_unsubscribe')
    dispatch(logger())
  }
}

function userToggleInterval(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_toggle_interval')
    dispatch(logger())
  }
}

function setUserPlan(planId: string): ThunkAction {
  return (dispatch, getState) => {
    firebase.analytics().setUserProperties({ plan_id: planId })
  }
}

function userUpgradeFromFree(planId: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('premium_user_upgrade_from_free')
    dispatch(logger({ plan_id: planId }))
  }
}

// APPROVE SENDERS
export const approveSenders = {
  userApproveSender,
  userBlockSender,
  userUnblockSender,
}

function userApproveSender(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('approve_senders_accept')
    dispatch(logger())
  }
}

function userBlockSender(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('approve_senders_block')
    dispatch(logger())
  }
}

function userUnblockSender(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('approve_senders_unblock')
    dispatch(logger())
  }
}

export const threadActions = {
  userThreadQuickAction,
  userThreadAction,
  userThreadBatchAction,
}

function userThreadQuickAction(type: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('thread_quick_action')
    dispatch(logger({ type }))
  }
}

function userThreadAction(type: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('thread_list_action')
    dispatch(logger({ type }))
  }
}

function userThreadBatchAction(type: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('thread_batch_action')
    dispatch(logger({ type }))
  }
}

// SEARCH
export const search = {
  userSearchViewThread,
  userSearchSelectAutocomplete,
  userSearchSetPrefix,
  userSearchRecommendation,
  userSearchScroll,
}

function userSearchViewThread(query: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('search_view_thread')
    dispatch(logger({ query }))
  }
}

function userSearchSelectAutocomplete(query: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('search_select_autocomplete')
    dispatch(logger({ query }))
  }
}

function userSearchSetPrefix(prefix: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('search_set_prefix')
    dispatch(logger({ prefix }))
  }
}

function userSearchRecommendation(type: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('search_recommendation')
    dispatch(logger({ type }))
  }
}

function userSearchScroll(scrollTop: number): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('search_scroll')
    dispatch(logger({ scroll: scrollTop }))
  }
}

export const calendar = {
  useCalendarView,
  useRSVPEventAction,
  useCreateEventAction,
  useUpdateEventAction,
  useDeleteEventAction,
  useToggleCalendar,
  useUpdateCalendarAction,
}

function useCalendarView(view: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_view')
    dispatch(logger({ view }))
  }
}

function useRSVPEventAction(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_rsvp_event')
    dispatch(logger())
  }
}
function useCreateEventAction(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_create_event')
    dispatch(logger())
  }
}

function useUpdateEventAction(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_update_event')
    dispatch(logger())
  }
}

function useDeleteEventAction(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_delete_event')
    dispatch(logger())
  }
}

function useToggleCalendar(status: boolean): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_visible_toggle')
    dispatch(logger({ status }))
  }
}

function useUpdateCalendarAction(): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('calendar_update_action')
    dispatch(logger())
  }
}

// SIFT_CARD
export const siftCard = {
  useReportSiftCardOpen,
}

function useReportSiftCardOpen(type: string): ThunkAction {
  return (dispatch, getState) => {
    const logger = createLogger('enter_sift_card')
    dispatch(logger({ type }))
  }
}
