import { useDispatch, useSelector } from 'react-redux'
import NormalAttachmentItem from 'common/AttachmentItem/NormalAttachmentItem'

import React, { forwardRef, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import AttachmentChip from '@edison/webmail-ui/components/AttachmentChip'
import AttachmentIcon from '@edison/webmail-ui/components/AttachmentIcon'
import Strip from '@edison/webmail-ui/components/EmailInput/Strip'
import Dot from '@edison/webmail-ui/components/Dot'
import { usePreviewModal } from 'core/previews/hooks'
import {
  convertAttachmentToAttachmentItem,
  convertAttachmentToICSItem,
} from 'utils'
import { isDownloadAllloading } from 'core/attachments/selectors'
import DownloadIcon from '@edison/webmail-ui/components/Icons/Download'
import { downloadAll } from 'core/attachments/actions'
import { getSearchKeywords } from 'core/search/selectors'
import { highlightHTML } from 'utils/htmlProcess'
import { isAttachmentICS } from '../../../utils/calendar'

const searchKeywordsSelector = getSearchKeywords('attachments')

const TrimedAttachmentItem = forwardRef(({ attachments, index }, ref) => {
  const previews = usePreviewModal()
  const { name, size, contentType } = attachments[index]

  return (
    <AttachmentChip
      ref={ref}
      thumbnail={
        <AttachmentIcon
          fontSize="small"
          contentType={contentType}
          fileName={name}
        />
      }
      fileName={name}
      size={size}
      onClick={() => {
        previews.showModal({ attachments, index })
      }}
    />
  )
})

export default ({ trimed, attachments, message }) => {
  const messageId = message.id
  const dispatch = useDispatch()
  const ref = useRef(null)
  const { t } = useTranslation()
  const keywords = useSelector(searchKeywordsSelector)
  const isLoading = useSelector(isDownloadAllloading)
  const attachmentItems = attachments.map(attachment => {
    if (!isAttachmentICS(attachment)) {
      return convertAttachmentToAttachmentItem(attachment, message.id)
    } else {
      return convertAttachmentToICSItem(attachment, message)
    }
  })

  useEffect(() => {
    if (ref.current && keywords.length) {
      highlightHTML(ref.current, keywords)
    }
  }, [messageId])

  return (
    <div ref={ref}>
      {trimed ? (
        <Strip>
          {attachments.map((attachment, index) => (
            <TrimedAttachmentItem
              key={attachment.id}
              attachments={attachmentItems}
              index={index}
            />
          ))}
        </Strip>
      ) : (
        <div>
          <div className="flex flex-wrap -m-2">
            {attachments.map((attachment, index) => (
              <NormalAttachmentItem
                key={attachment.id}
                attachments={attachmentItems}
                index={index}
              />
            ))}
          </div>
          {attachments.length > 1 && (
            <div className="pt-2">
              <button
                onClick={() => dispatch(downloadAll(messageId))}
                className="text-gray-light text-xs hover:text-gray-medium"
              >
                <DownloadIcon style={{ fontSize: 16 }} />
                &nbsp;&nbsp;
                {isLoading ? (
                  <>
                    Downloading <Dot />
                  </>
                ) : (
                  t('button.downloadAll')
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
