// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { LabelPicker } from '@edison/webmail-ui/components/Labels'
import { getThreadLabelIds } from 'core/metadata/selectors'
import {
  useLabels,
  useLabelValidator,
  useLabelPickerActions,
} from 'core/labels/hooks'

import type { Node, ElementConfig } from 'react'

type Props = {
  trigger?: Node,
  // Current assigned labels
  labelIds: $ReadOnlyArray<string>,
  onChange: $PropertyType<ElementConfig<typeof LabelPicker>, 'onChange'>,
  onAddLabel: $PropertyType<ElementConfig<typeof LabelPicker>, 'onAddLabel'>,
}

const View = ({ labelIds = [], ...rest }: Props) => {
  const { custom } = useLabels()
  const labelValidator = useLabelValidator()

  return (
    <LabelPicker
      validate={values => {
        let errors = {}
        const nameError = labelValidator.name(values.name)
        if (!!nameError) {
          errors.name = nameError
        }
        return errors
      }}
      labels={custom.map(each => ({
        ...each,
        checked: labelIds.includes(each.id),
      }))}
      {...rest}
    />
  )
}

View.displayName = 'LabelPicker'

export default View

type ThreadLabelPickerProps = {
  threadId: string,
  trigger?: Node,
}

export const ThreadLabelPicker = ({
  threadId,
  trigger,
  ...props
}: ThreadLabelPickerProps) => {
  const threadLabelsSelector = useMemo(() => getThreadLabelIds(threadId), [
    threadId,
  ])
  const threadLabels = useSelector(threadLabelsSelector)

  const labelPickerActions = useLabelPickerActions(threadId)

  return (
    <View
      trigger={trigger}
      labelIds={threadLabels}
      onAddLabel={name => labelPickerActions.add(name)}
      onChange={values =>
        labelPickerActions.change(
          threadLabels,
          values.map(({ id }) => id).filter(Boolean)
        )
      }
      {...props}
    />
  )
}
