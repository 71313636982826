// @flow
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import isNil from 'lodash/isNil'
// $FlowFixMe
import SwipeableViews from 'react-swipeable-views'
import { fetchContacts, fetchContactSuggestions } from 'core/contacts/actions'

import * as selectors from 'core/contacts/selectors'

import List from './List'
import Details from './Details'

import type { Dispatch } from 'types/redux'

const VIEW_INDEX = {
  LIST: 0,
  DETAIL: 1,
}

const getContacts = selectors.getContacts()
const contactStatusSelector = selectors.isContactListLoading()

export type Recipient = {
  avatar: string,
  name: string,
  email: string,
}

type Props = {
  showSuggestions?: boolean,
  recipients: Array<Recipient>,
  onAddRecipient: Recipient => void,
  onSelectThread: (threadId: string) => void,
  selectedThread: string,
}

const View = (
  {
    recipients,
    onAddRecipient,
    onSelectThread,
    showSuggestions = true,
    selectedThread,
  }: Props,
  ref
) => {
  const [selectedRecipient, setSelectedRecipient] = useState<?Recipient>(
    recipients.length === 1 && !showSuggestions ? recipients[0] : null
  )

  const contacts = useSelector(getContacts)
  const isContactListLoading = useSelector(contactStatusSelector)

  const dispatch: Dispatch = useDispatch()

  useEffect(() => {
    if (contacts.length === 0 && !isContactListLoading) {
      dispatch(fetchContacts())
    }
    if (showSuggestions) {
      dispatch(fetchContactSuggestions())
    }
  }, [])

  useEffect(() => {
    if (recipients.length === 0) {
      setSelectedRecipient(null)
    }
  }, [recipients.length])

  useImperativeHandle(ref, () => ({
    setSelectedRecipient,
  }))

  return (
    <SwipeableViews
      axis={'x'}
      index={isNil(selectedRecipient) ? VIEW_INDEX.LIST : VIEW_INDEX.DETAIL}
      style={{ overflowX: 'inherit', height: '100%' }}
      containerStyle={{ height: '100%' }}
    >
      <div className="py-6 px-2">
        <List
          index={VIEW_INDEX.LIST}
          showSuggestions={showSuggestions}
          contacts={recipients}
          onClick={index => {
            setSelectedRecipient(recipients[index])
          }}
          onAddRecipient={onAddRecipient}
        />
      </div>
      <div index={VIEW_INDEX.DETAIL} className="h-full">
        {!!selectedRecipient && (
          <Details
            {...selectedRecipient}
            index={VIEW_INDEX.DETAIL}
            onClose={() => setSelectedRecipient(null)}
            onSelectThread={onSelectThread}
            selectedThread={selectedThread}
          />
        )}
      </div>
    </SwipeableViews>
  )
}

export default forwardRef<Props, { [key: string]: any }>(View)
