import React, { memo, useState, useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AttachmentTabList from '@edison/webmail-ui/components/AttachmentTabList'
import AttachmentList from '@edison/webmail-ui/components/AttachmentTabList/List'
import NoResultsFound from '@edison/webmail-ui/components/NoResultsFound'
import moment from 'moment-timezone'

import * as api from '@edison/webmail-core/api/search'
import { getAuth } from 'core/auth/selectors'
import { getAllLinksAttachemntsSelector } from 'core/attachment-links/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { useRetrofitSyncProgress } from 'core/retrofit/hooks'

import { searchAttachmentTabs } from 'utils/constants'
import AttachmentTabListItem from 'common/AttachmentItem/AttachmentTabListItem'

import CircularLoader from '@edison/webmail-ui/components/CircularLoader'
import { ImportingNotices } from '@edison/webmail-ui/components/Retrofit'
import { fetchAllAttachmentLinks } from 'core/attachment-links/actions'
import { useDebouncedCallback } from 'use-debounce'
import escapeStringRegexp from 'escape-string-regexp'

const PAGE_SIZE = 6

const Attachments = memo(({ value: type, query, ...rest }) => {
  const auth = useSelector(useMemo(() => getAuth(), []))

  const [state, setState] = useState(() => ({
    list: [],
    nextPageToken: '',
    loading: false,
    loaded: false,
  }))

  const fetchAttachments = async (query, nextPageToken) => {
    let pageToken = nextPageToken
    let results = []
    const fetch = async extraSize => {
      const result = await api.searchAttachments({
        auth,
        type,
        pageToken,
        pageSize: PAGE_SIZE,
        fileName: query,
        timezone: moment.tz.guess(),
      })
      const { attachments, nextPageToken } = result.result
      pageToken = nextPageToken
      results = results.concat(attachments)
      if (!pageToken) return
      if (extraSize - attachments.length <= 0) return
      return fetch(extraSize - attachments.length)
    }
    await fetch(PAGE_SIZE)

    return { attachments: results, nextPageToken: pageToken }
  }
  const [debouncedFetchList, , callpending] = useDebouncedCallback(q => {
    setState({ list: [], pageToken: '', loading: true, loaded: false })
    fetchAttachments(q).then(result => {
      const { attachments, nextPageToken } = result
      setState({
        list: attachments,
        nextPageToken,
        loading: false,
        loaded: true,
      })
    })
  }, 500)
  const { list, nextPageToken, loading, loaded } = state

  const handleChangePreviewIndex = currIndex => {
    const { list, nextPageToken, loading } = state
    if (currIndex >= list.length - 2 && nextPageToken && !loading) {
      loadMore()
    }
  }

  const renderItem = ({ columnIndex, key, rowIndex, style }, index) => {
    return (
      <div key={key} style={style} className="p-4">
        {!loaded || nextPageToken || list[index] ? (
          <AttachmentTabListItem
            attachments={list}
            index={index}
            onChangeIndex={handleChangePreviewIndex}
          />
        ) : null}
      </div>
    )
  }

  const loadMore = ({ startIndex, stopIndex }) => {
    if (nextPageToken && !loading) {
      setState(state => ({ ...state, loading: true }))
      fetchAttachments(query, nextPageToken).then(result => {
        const { attachments, nextPageToken } = result
        setState(state => ({
          ...state,
          list: state.list.concat(attachments),
          nextPageToken,
          loading: false,
        }))
      })
    }
  }
  const isLoaded = ({ index }) => list[index]

  useEffect(() => {
    debouncedFetchList(query)
  }, [query])

  useEffect(() => {
    callpending()
  }, [])

  if (loaded && !list.length) {
    return <NoResultsFound />
  }

  return (
    <AttachmentList
      {...rest}
      renderItem={renderItem}
      total={nextPageToken || !loaded ? list.length + 5 : list.length}
      isLoaded={isLoaded}
      loadMore={loadMore}
    />
  )
})

const LinksAttachments = ({ query, ...rest }) => {
  const dispatch: Dispatch = useDispatch()
  const attachments = useSelector(getAllLinksAttachemntsSelector)

  const loading = useSelector(
    useMemo(() => getLoadingStatus('ATTACHMENTLINK_LIST'), [])
  )
  useEffect(() => {
    dispatch(fetchAllAttachmentLinks())
  }, [])

  if (loading && !attachments.length) return <CircularLoader fullHeight />

  const list = query.trim()
    ? attachments.filter(item =>
        item.name.match(new RegExp(escapeStringRegexp(query), 'i'))
      )
    : attachments
  if (!list.length) {
    return <NoResultsFound />
  }

  const renderItem = ({ columnIndex, key, rowIndex, style }, index) => {
    return (
      <div key={key} style={style} className="p-4">
        {list[index] ? (
          <AttachmentTabListItem attachments={list} index={index} />
        ) : null}
      </div>
    )
  }
  const isLoaded = ({ index }) => list[index]

  return (
    <AttachmentList
      {...rest}
      renderItem={renderItem}
      total={list.length}
      isLoaded={isLoaded}
      loadMore={() => {}}
    />
  )
}

export default props => {
  const tabs = Object.values(searchAttachmentTabs)
  const { isFullSync } = useRetrofitSyncProgress()

  const hints = useMemo(() => {
    return !isFullSync ? <ImportingNotices /> : null
  }, [isFullSync])

  return (
    <AttachmentTabList tabs={tabs}>
      {activeTab =>
        React.createElement(
          tabs[activeTab].value === searchAttachmentTabs.links.value
            ? LinksAttachments
            : Attachments,
          { ...tabs[activeTab], ...props, hints: hints }
        )
      }
    </AttachmentTabList>
  )
}
