// @flow
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import * as auth from 'core/auth'
import { routePaths } from 'utils/constants'

import Dialog from '@material-ui/core/Dialog'
import DeleteAccountConfirm from '@edison/webmail-ui/components/DeleteAccountConfirm'
import { Form as PasswordForm } from 'screens/PasswordAuthModal'

import type { Dispatch } from 'types/redux'

type Props = {
  email: string,
  isOpen: boolean,
  onClose: () => void,
}
export default ({ email, isOpen, onClose }: Props) => {
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const [extraAuth, setExtraAuth] = useState(null)
  const isLoading = useSelector(auth.selectors.isDeleteAccountLoading)
  const orderId = auth.hooks.useOrderId()

  const handleOnPasswordAuth = auth => {
    if (!!auth) setExtraAuth(auth)
  }

  const handleOnConfirm = () => {
    dispatch(
      auth.actions.deleteAccount(email, orderId, { auth: extraAuth })
    ).then(res => {
      if (res) {
        onClose()
        history.replace({
          pathname: routePaths.goodbye,
          search: '?deleted=1',
        })
      }
    })
  }

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      {!!extraAuth ? (
        <DeleteAccountConfirm
          email={email}
          onClose={onClose}
          isLoading={isLoading}
          onConfirm={handleOnConfirm}
        />
      ) : (
        <PasswordForm onConfirm={handleOnPasswordAuth} onClose={onClose} />
      )}
    </Dialog>
  )
}
