// @flow
import { createSelector } from 'reselect'
import { getLoadingStatus } from '../loading/selectors'

import type { State as CredentialState } from './types'
import type { State, Selector } from 'types/state'
import type { Credential } from '@edison/webmail-core/types/credentials'

export const selectCredentialState = (state: State) => state.credentials

export function getCredentials(): Selector<Array<Credential>> {
  return createSelector(
    selectCredentialState,
    (credentials: CredentialState) => credentials
  )
}

export function isCredentialListLoading() {
  return getLoadingStatus('CREDENTIAL_LIST')
}

export function isCredentialConnectLoading() {
  return getLoadingStatus('CONNECT_GOOGLE')
}
