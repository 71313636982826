import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getThreadTrashMessageIds } from 'core/metadata/selectors'
import { deleteMessages } from 'core/metadata/actions'

export default function TrashedMessageTip({ threadId, onView, onDelete }) {
  const trashMessageIds = useSelector(
    useMemo(() => getThreadTrashMessageIds(threadId), [threadId])
  )
  const count = useMemo(() => trashMessageIds.length, [trashMessageIds])
  const dispatch = useDispatch()
  if (!trashMessageIds.length) return null
  return (
    <div className="flex justify-between items-center h-10 p-4 bg-secondary text-sm">
      <Trans i18nKey="thread.tip.deletedMessages" count={count}>
        {{ count }} deleted message in this conversation.{' '}
        <div>
          <button className="text-primary font-semibold" onClick={onView}>
            View Message
          </button>{' '}
          or{' '}
          <button
            className="text-primary font-semibold"
            onClick={() => dispatch(deleteMessages(threadId, trashMessageIds))}
          >
            Delete Forever
          </button>
        </div>
      </Trans>
    </div>
  )
}
