// @flow
import * as settings from './selectors-settings'
import * as colors from './selectors-colors'
import * as calendars from './selectors-calendars'
import * as events from './selectors-events'

export default {
  settings,
  colors,
  calendars,
  events,
}

export const getCalendars = calendars.getCalendars
export const getPrimaryCalendar = calendars.getPrimaryCalendar
export const getSettings = settings.getCalendarSettingsSelector
export const getEventsState = events.getEventsState
export const getEvents = events.getUIScopeEvents
export const getICSEvent = events.getSelectedICSEvent
export const getSelectedEvent = events.getSelectedUIScopeEvent
export const getRange = events.getUIEventsRange
export const getColors = colors.getCalendarColors
export const getEvent = events.getEventById
export const getInstanceById = events.getInstanceById
export const getICSEventDataByKey = events.getICSEventDataByKey
