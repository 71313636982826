import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'
import { fetchOriginalMessage } from 'core/messages/actions'
import { getMessage } from 'core/messages/selectors'
import DialogContent from '@material-ui/core/DialogContent'
import Progress from '@material-ui/core/CircularProgress'
import DownloadIcon from '@edison/webmail-ui/components/Icons/Download'
import sanitize from 'sanitize-filename'
import Fab from '@material-ui/core/Fab'
import { useTranslation } from 'react-i18next'
import Tooltip from '@edison/webmail-ui/components/Tooltip'

const messageStorage = {}

export default function ShowOriginalDialog({ isOpen, toggle, messageId }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { subject } = useSelector(
    useMemo(() => getMessage(messageId), [messageId])
  )

  const urlRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isOpen) return
    if (messageStorage[messageId]) return

    setLoading(true)
    dispatch(fetchOriginalMessage(messageId))
      .then(result => {
        messageStorage[messageId] = result
      })
      .finally(() => {
        setLoading(false)
      })
  }, [messageId, isOpen])

  useEffect(
    () => () => {
      urlRef.current && URL.revokeObjectURL(urlRef.current)
    },
    []
  )

  const handleDownload = () => {
    const fileName = sanitize(`${subject || t('thread.subject.none')}.eml`)

    if (!urlRef.current) {
      const blob = new Blob([messageStorage[messageId]], {
        type: 'application/octet-stream',
      })
      urlRef.current = URL.createObjectURL(blob)
    }

    const anchor = document.createElement('a')
    anchor.setAttribute('href', urlRef.current)

    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('download', fileName)

    anchor.click()
  }
  return (
    <Dialog maxWidth="lg" fullWidth open={isOpen} onClose={toggle}>
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <Progress />{' '}
        </div>
      ) : (
        <DialogContent>
          <div className="flex">
            <div
              className="whitespace-pre-wrap flex-1"
              style={{ wordBreak: 'break-word' }}
            >
              {messageStorage[messageId]}
            </div>
            <div className="flex-0">
              <Tooltip title={t('button.download')}>
                <Fab
                  disableRipple
                  disableFocusRipple
                  style={{
                    position: 'sticky',
                    top: 0,
                    boxShadow: `0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)`,
                  }}
                  onClick={handleDownload}
                >
                  <DownloadIcon />
                </Fab>
              </Tooltip>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}
