import { MAX_ATTACHMENT_TOTAL_SIZE } from 'utils/constants'

export function hasFilesExceedMaxSize(attachments, files = []) {
  const totalSize = files
    .concat(attachments.filter(item => !item.error))
    .map(each => each.size)

    .reduce((a, b) => a + b, 0)
  return totalSize > MAX_ATTACHMENT_TOTAL_SIZE
}

export let existedDraftMessageIds = new Set()
