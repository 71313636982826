// @flow
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { createSelector } from 'reselect'
import {
  RESPONSE_TYPE_NONE,
  RESPONSE_TYPE_REPLY,
  RESPONSE_TYPE_FORWARD,
  composeStatus,
  autoSelectEmail,
} from 'utils/constants'

import { getLargeAttachmentState } from 'core/large-attachments/selectors'
import { getAttachmentState } from 'core/attachments/selectors'
import md5 from 'md5'
import { getAuth } from 'core/auth/selectors'
import {
  getProfileInSettings,
  getDefaultSenderEmailSelector,
  getSenderNameSelector,
} from 'core/settings/selectors'
import { getMessageState } from 'core/messages/selectors'
import { getAliases } from 'core/custom-domains/selectors'
import { getActivedAccounts } from 'core/retrofit/selectors'
import type { Draft } from '@edison/webmail-core/types/compose'
import type { State, Selector } from 'types/state'
import type { State as ComposeState } from './types'

import { MAX_ATTACHMENT_TOTAL_SIZE } from 'utils/constants'
import {
  hasLargeAttachmentSuccessComplete,
  hasAttachmentSuccessComplete,
  formatComposeRecipients,
} from 'utils'
import { CONTENT_DISPOSITION_ATTACHMENT } from '@edison/webmail-core/utils/constants'
import { hasFilesExceedMaxSize } from './helpers'

export const selectComposeState = (state: State) => state.compose

const _getDraftState: Selector<ComposeState> = createSelector(
  selectComposeState,
  (state: ComposeState) => state
)

export function getDraftState(): Selector<ComposeState> {
  return _getDraftState
}

export function getDrafts(): Selector<Array<Draft>> {
  return createSelector(selectComposeState, (state: ComposeState) =>
    Object.values(state)
  )
}

export function getDraft(id: string): Selector<Draft> {
  return createSelector(selectComposeState, compose => compose[id])
}

export function getResponseType(draftId: string) {
  return createSelector(getDraft(draftId), draft => {
    if (!draft || draftId.startsWith('new') || !draft.responseMessageId)
      return RESPONSE_TYPE_NONE

    return draft.subject.match(/^\s?re:/i)
      ? RESPONSE_TYPE_REPLY
      : RESPONSE_TYPE_FORWARD
  })
}

export function isDraftDisabled(draftId: string) {
  return createSelector(getDraft(draftId), (draft: Draft) => {
    if (!draft) return false
    return (
      draft.status === composeStatus.sending ||
      draft.status === composeStatus.deleting
    )
  })
}

export function getDraftAttachments(draftId: string) {
  return createSelector(
    getDraft(draftId),
    getAttachmentState(),
    (draft, attachmentState) => {
      if (!draft) return []
      const { attachmentUuids } = draft
      return attachmentUuids
        .filter(uuid => attachmentState[uuid])
        .map(uuid => attachmentState[uuid])
    }
  )
}

export function getDraftLargeAttachments(
  draftId: string
): Selector<Array<LargeAttachment>> {
  return createSelector(
    getDraft(draftId),
    getLargeAttachmentState(),
    (draft, largeAttachmentState) => {
      if (!draft) return []
      const { largeAttachmentUuids } = draft
      return largeAttachmentUuids
        .filter(uuid => largeAttachmentState[uuid])
        .map(uuid => largeAttachmentState[uuid])
    }
  )
}

export function getResponseMessage(draftId) {
  return createSelector(
    getDraft(draftId),
    getMessageState(),
    (draft, messages) => {
      if (!draft) return null
      if (!draft.responseMessageId) return null
      return messages[draft.responseMessageId]
    }
  )
}

export function getFrom(): Selector<object> {
  return createSelector(getAuth(), getProfileInSettings(), (auth, profile) => ({
    name: profile.senderName || auth.user.split('@')[0],
    email: auth.user,
  }))
}

export function getComposeBody(draftId: string): Selector<Draft> {
  return createSelector(
    getDraft(draftId),
    getDraftFrom(draftId),
    getResponseMessage(draftId),
    getDraftRealAttachments(draftId),
    getDraftLargeAttachments(draftId),
    getResponseType(draftId),
    (
      draft,
      from,
      responseMessage,
      attachments,
      largeAttachments,
      responseType
    ) => {
      const { responseMessageId, threadId } = draft
      let extraData = {}

      if (responseMessage) {
        extraData = {
          inReplyTo: responseMessage.messageId,
          references: responseMessage.references.concat(
            responseMessage.messageId
          ),
          forwardMessageId: responseMessageId,
          threadId,
        }
      }
      const { to, cc, bcc, subject, messageId, html, password, composeId } =
        draft

      return {
        cids: uniqBy(
          attachments
            .filter(hasAttachmentSuccessComplete)
            .map(({ id: cid, contentDisposition }) => ({
              cid,
              contentDisposition,
            })),
          'cid'
        ),

        html,
        draftId: messageId,
        composeId,
        ...formatComposeRecipients({ to, cc, bcc }),
        subject: subject.trim(),
        from,
        attachmentIds: uniq(
          largeAttachments
            .filter(item => item.aid && hasLargeAttachmentSuccessComplete(item))
            .map(item => item.aid)
        ),
        password: password ? md5(password) : '',
        largeAttachmentFlag:
          hasFilesExceedMaxSize(attachments) || largeAttachments.length
            ? true
            : false,
        ...extraData,
      }
    }
  )
}

export function getDraftRealAttachments(draftId: string) {
  return createSelector(
    getDraft(draftId),
    getDraftAttachments(draftId),
    (draft, attachments) => {
      const { html } = draft || {}
      return attachments.filter(
        item =>
          !(
            item.contentDisposition !== CONTENT_DISPOSITION_ATTACHMENT &&
            !html.includes(`"cid:${item.id}"`) &&
            !html.includes(`"${item.uuid}"`)
          )
      )
    }
  )
}
export function hasDraftAttachmentSizeExceed(
  draftId: string
): Selector<Boolean> {
  return createSelector(
    getDraftRealAttachments(draftId),
    getDraftLargeAttachments(draftId),
    (realAttachments, largeAttachments) => {
      if (largeAttachments.length) return false

      //check all inline image is exceed the max size
      if (
        realAttachments.length &&
        realAttachments
          .filter(item => !item.error)
          .filter(
            item => item.contentDisposition !== CONTENT_DISPOSITION_ATTACHMENT
          )
          .reduce((prev, curr) => prev + curr.size, 0) >
          MAX_ATTACHMENT_TOTAL_SIZE
      ) {
        return true
      }
      return false
    }
  )
}

export function getDraftByLargeAttachmentUuid(
  attachmentUuid: string
): Selector<Draft> {
  return createSelector(getDrafts(), drafts => {
    return drafts.find(item =>
      item.largeAttachmentUuids.includes(attachmentUuid)
    )
  })
}

export function getSendedDrafts(): Selector<Array<Draft>> {
  return createSelector(getDrafts(), drafts => {
    return drafts.filter(item => item.status === composeStatus.sended)
  })
}

export function getDraftSize(draftId: string): Selector<number> {
  return createSelector(
    getDraft(draftId),
    getDraftRealAttachments(draftId),
    getDraftLargeAttachments(draftId),
    (draft, attachments, largeAttachments) => {
      const totalSize =
        attachments.reduce((prev, curr) => prev + curr.size, 0) +
        largeAttachments.reduce((prev, curr) => prev + curr.size, 0)
      return totalSize + (draft?.html?.length || 0) + 200 * 1024
    }
  )
}

export function getDraftFrom(draftId: string): Selector {
  return createSelector(
    getDraftFromEmail(draftId),
    getSenderNameSelector,
    (email, senderName) => {
      return { name: senderName || email.split('@')[0], email }
    }
  )
}
export const getFromListSelector = createSelector(
  getAliases,
  getActivedAccounts,
  getAuth(),
  (aliases, accounts, auth) => {
    return uniq(
      [auth.user]
        .concat(aliases || [])
        .concat(accounts.map(item => item.emailAddress))
        .concat([autoSelectEmail])
    )
  }
)

export function getDraftFromEmail(draftId: string): Selector<string> {
  return createSelector(
    getDraft(draftId),
    getDefaultSenderEmailSelector,
    getFromListSelector,
    getAuth(),
    (draft, defaultSenderEmail, fromList) => {
      const { from } = draft
      if (from && fromList.includes(from)) return from
      // if (activeAccount) return activeAccount.emailAddress
      return defaultSenderEmail
    }
  )
}
