import React, { useMemo, forwardRef } from 'react'
import AttachmentItem from './AttachmentItem'
import LargeAttachmentItem from './LargeAttachmentItem'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDraft,
  getDraftAttachments,
  getDraftLargeAttachments,
} from 'core/compose/selectors'
import {
  removeDraftAttachment,
  removeDraftLargeAttachment,
} from 'core/compose/actions'
import { retry } from 'core/attachments/actions'

import {
  hasLargeAttachmentSuccessComplete,
  convertAttachmentToAttachmentItem,
  convertLargeAttachmentToAttachmentItem,
} from 'utils'
import { CONTENT_DISPOSITION_ATTACHMENT } from '@edison/webmail-core/utils/constants'

export default forwardRef(({ draftId }, ref) => {
  const attachments = useSelector(
    useMemo(() => getDraftAttachments(draftId), [draftId])
  )
  const largeAttachments = useSelector(
    useMemo(() => getDraftLargeAttachments(draftId), [draftId])
  )
  const { messageId } = useSelector(useMemo(() => getDraft(draftId), [draftId]))
  const dispatch = useDispatch()

  const filteredAttachments = useMemo(
    () =>
      attachments.filter(
        item => item.contentDisposition === CONTENT_DISPOSITION_ATTACHMENT
      ),
    [attachments]
  )

  const filteredLargeAttachments = useMemo(
    () => largeAttachments.filter(hasLargeAttachmentSuccessComplete),
    [largeAttachments]
  )

  if (!filteredAttachments.length && !filteredLargeAttachments.length)
    return null
  return (
    <div className="flex-shrink-0 flex flex-wrap my-2 -m-2" ref={ref}>
      {filteredAttachments.map(attachment => (
        <AttachmentItem
          key={attachment.uuid}
          {...convertAttachmentToAttachmentItem(attachment, messageId)}
          onRemove={() => {
            dispatch(removeDraftAttachment(draftId, attachment.uuid))
          }}
          onRetry={() => dispatch(retry(draftId, attachment.uuid))}
        />
      ))}

      {filteredLargeAttachments.map(item => (
        <LargeAttachmentItem
          {...convertLargeAttachmentToAttachmentItem(item)}
          messageId={messageId}
          key={item.uuid}
          onRemove={() => {
            dispatch(removeDraftLargeAttachment(draftId, item.uuid))
          }}
        />
      ))}
    </div>
  )
})
