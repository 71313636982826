import React, { useRef, useMemo, useEffect } from 'react'
import DetailModal from '@edison/webmail-ui/components/DetailModal'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import get from 'lodash/get'
import qs from 'qs'
import { fetchUsage } from 'core/usage/actions'
import { checkAndShowFileStoragePaywall } from 'core/premium/actions'
import { isAuthenticated } from 'core/auth/selectors'
import { getDraft } from 'core/compose/selectors'
import { useThreadActions } from 'core/threads/hooks'
import Contacts from 'common/Contacts'
import { getFilteredContacts } from 'core/contacts/selectors'

import Compose from 'screens/Compose'
import { composeStatus, draftTypes } from 'utils/constants'
import { useDebouncedCallback } from 'use-debounce'
import { useDetailModalGoBack } from 'hooks/useGoBack'
import type { Dispatch } from 'types/redux'
import {
  createOrFetchDraft,
  updateDraft,
  debouncedSaveDraft,
  discardDraft,
  getOriginalDraftIdAction,
} from 'core/compose/actions'
import { isEmail } from '@edison/webmail-ui/utils'
import * as URI from 'uri-js'
import { formatMessageText } from 'utils/responseTemplate'
import { useAppInitialization } from 'core/initialization/hooks'
import useOnMailTheme from 'hooks/useOnMailTheme'

const DEFAULT_DRAFT = {
  extendInfo: {
    type: draftTypes.DEFAULT,
  },
}

const layoutFlags = {
  [draftTypes.DEFAULT]: {
    contact: true,
  },
  [draftTypes.CLAIM_EMAIL]: {
    contact: false,
  },
}
//the contacts need be loaded at first
const filteredContactsSelector = getFilteredContacts()

const ComposeModal = () => {
  const history = useHistory()
  const location = useLocation()
  const { isDarkTheme } = useOnMailTheme()

  const query = useMemo(() => qs.parse(location.search.slice(1)), [
    location.search,
  ])
  const dispatch: Dispatch = useDispatch()

  //use 'new_xxx' format to new draft, make every draft unique
  const draftId = useMemo(
    () => dispatch(getOriginalDraftIdAction(query.compose)),
    [query.compose]
  )

  const draft = useSelector(useMemo(() => getDraft(draftId), [draftId]))

  const threadActions = useThreadActions({ debounced: false })({ id: draftId })
  const contactsRef = useRef()
  const goBack = useDetailModalGoBack('compose')
  // Claim Email
  const priceDropId = get(query, 'priceDropId')
  // the to params is starts with 'mailto:'
  //todos: headers need be considered.
  const { to, subject, body } = URI.parse(get(query, 'to', ''))

  const contacts = useSelector(filteredContactsSelector)
  const draftType = draftTypes.DEFAULT

  const [debouncedDiscardDraft] = useDebouncedCallback(
    draftId => dispatch(discardDraft(draftId)),
    300,
    { leading: true, trailing: false }
  )

  useEffect(() => {
    if (query.compose === 'new') {
      dispatch(fetchUsage()).then(() =>
        dispatch(checkAndShowFileStoragePaywall(0.9))
      )
    }
  }, [query.compose])
  useEffect(() => {
    // If price drop ID is invalid
    // Replace the compose with default
    if (draftType === draftTypes.DEFAULT && !!priceDropId) {
      window.history.replaceState(
        null,
        null,
        `${location.pathname}?${qs.stringify({
          ...query,
          priceDropId: undefined,
        })}`
      )
    }
  }, [draftType])

  useEffect(() => {
    if (draftId) {
      let rawDraft = { ...DEFAULT_DRAFT }
      if (to) {
        const recipients = to.map(email => {
          if (isEmail(email)) {
            const contact = contacts.find(item => item.email === email)
            if (contact) return { ...contact }
          }
          return { name: '', email }
        })
        rawDraft = {
          ...rawDraft,
          to: recipients,
          subject: subject || '',
          html: body ? formatMessageText(body) : '',
        }
      }

      dispatch(createOrFetchDraft(draftId, rawDraft))
    }
  }, [draftId])

  const draftThreadId = draft ? draft.threadId : null

  useEffect(() => {
    if (draftThreadId) {
      history.replace({
        search: `?${qs.stringify({ ...query, compose: draftThreadId })}`,
      })
    }
  }, [draftThreadId])

  const draftStatus = draft ? draft.status : null

  useEffect(() => {
    if (
      [
        composeStatus.deleting,
        composeStatus.sending,
        composeStatus.deleted,
        composeStatus.sended,
      ].includes(draftStatus)
    ) {
      const { compose, ...rest } = query
      history.replace({ search: `?${qs.stringify(rest)}` })
    }
  }, [draftStatus])

  // useEffect(() => {
  //   if (navigator.registerProtocolHandler) {
  //     const { protocol, host, pathname } = window.location
  //     const mailToPath = url.format({
  //       protocol,
  //       host,
  //       pathname,
  //       search: 'compose=new&to=%s',
  //     })
  //     navigator.registerProtocolHandler('mailto', mailToPath, 'OnMail')
  //   }
  // }, [])

  const { contact: showContact } = get(
    layoutFlags,
    draftType,
    layoutFlags[draftTypes.DEFAULT]
  )

  return (
    <DetailModal
      isOpen
      hideModal={goBack}
      actions={
        !!draft
          ? [
              {
                ...threadActions.discardDraft,
                onClick: () => debouncedDiscardDraft(draftId),
              },
            ]
          : []
      }
    >
      {!!draft && (
        <div className="flex h-screen">
          <div
            className="z-10 dark:shadow-deep-r"
            style={{
              boxShadow: isDarkTheme
                ? undefined
                : '0 0px 20px rgba(0,0,0,.035), 0 0px 20px rgba(0,0,0,.035)',
              width: 'calc(100% - 400px)',
            }}
          >
            <Compose
              draftId={draftId}
              onSelectRecipient={recipient => {
                if (!!contactsRef.current) {
                  contactsRef.current.setSelectedRecipient(recipient)
                }
              }}
            />
          </div>
          {showContact && (
            <div className="z-0 overflow-x-hidden" style={{ width: 400 }}>
              <Contacts
                ref={contactsRef}
                recipients={[...draft.to, ...draft.cc, ...draft.bcc]}
                showSuggestions
                onAddRecipient={newRecipient => {
                  const element = document.activeElement
                  const id = element.getAttribute('id')
                  let key = 'to'
                  if (
                    element.tagName === 'INPUT' &&
                    ['to', 'cc', 'bcc'].includes(id)
                  ) {
                    key = id
                  }
                  dispatch(
                    updateDraft({
                      id: draftId,
                      [key]: [...draft[key], newRecipient],
                    })
                  )
                  dispatch(debouncedSaveDraft(draftId))
                }}
                onSelectThread={threadId =>
                  history.push({
                    search: `?${qs.stringify({ ...query, thread: threadId })}`,
                  })
                }
              />
            </div>
          )}
        </div>
      )}
    </DetailModal>
  )
}

export default () => {
  const authed = useSelector(useMemo(() => isAuthenticated(), []))
  const location = useLocation()
  const { isInitialized } = useAppInitialization()

  const query = useMemo(() => qs.parse(location.search.slice(1)), [
    location.search,
  ])
  if (!isInitialized || !authed || !query.compose) return null

  return <ComposeModal />
}
