// @flow
import qs from 'qs'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import invert from 'lodash/invert'
import _values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import fromPairs from 'lodash/fromPairs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory, generatePath } from 'react-router-dom'
import { useEffect, useState, useCallback, useMemo } from 'react'

import * as apiClient from '@edison/webmail-core/api/retrofit'
import {
  betaFeatures,
  commonErrorCode,
  connectionTypes,
  connectionStatus,
  connectionSyncStatus,
  syncAccountStatus,
  supportedProviders,
  imapConnectionTypes,
} from '@edison/webmail-core/utils/constants'
import { showLoading, hideLoading } from 'core/toasts/actions'
import { getAuth } from 'core/auth/selectors'
import {
  getFeatureFlags,
  isAllowUnlimitedImportAccounts,
} from 'core/premium/selectors'
import * as actions from './actions'
import * as selectors from './selectors'
import { showPaywallModal } from 'core/modals/actions'
import { useQueryParams } from 'common/url'
import { useToast, toastVariants } from 'common/toasts'
import { useDomain } from 'core/custom-domains/hooks'
import { useOrderId } from 'core/auth/hooks'
import { useBetaFeature } from 'core/beta-features/hooks'
import {
  toastTypes,
  retrofitAccountFilter,
  providerLogos,
  paywallTypes,
  routePaths,
  providerMappings,
} from 'utils/constants'
import { parseOnMailHost } from 'utils'

import type { RetrofitAccount } from '@edison/webmail-core/types/retrofit'
import type { ConnectionType } from '@edison/webmail-core/utils/constants'
import type {
  Values as FormValues,
  AdvancedConfig,
} from '@edison/webmail-ui/components/Retrofit/IMAPForm'
import type { Dispatch } from 'types/redux'

/**
 * Fetches the OAuth URL for a third-party provider from backend.
 *
 * @param {ConnectionType} connectionType
 */
export const useAuthorizationUrl = () => {
  const auth = useAuth()
  const userId = useOrderId()
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState('')

  const redirectUrl = useRedirectUrl()
  const { referrer, error: errorUrl } = useReferrer()

  const redirectToIMAP = () => {
    history.push(generatePath(routePaths.retrofitIMAP, { userId }))
  }
  const redirectToInstructions = (provider: ConnectionType) => {
    history.push({
      pathname: generatePath(routePaths.retrofitIMAPInstructions, { userId }),
      search: `?${qs.stringify({ provider })}`,
    })
  }

  const getUrl = useCallback((connectionType: ConnectionType) => {
    if (!auth || isNil(connectionType)) {
      return
    }

    // Redirect to IMAP connection form
    if (connectionType === connectionTypes.DEFAULT) {
      redirectToIMAP()
      return
    }

    if (
      [
        connectionTypes.GMAIL_IMAP,
        connectionTypes.ICLOUD_IMAP,
        connectionTypes.YAHOO_IMAP,
        connectionTypes.AOL_IMAP,
      ].includes(connectionType)
    ) {
      const invertedProviderMapping = invert(providerMappings)
      redirectToInstructions(invertedProviderMapping[connectionType])
      return
    }

    setLoading(true)
    return apiClient
      .getAuthUrl(connectionType, redirectUrl, { auth })
      .then(res => {
        const { authorizationUrl } = res.result
        setUrl(authorizationUrl)

        // Redirect to oauth init path
        const params = qs.stringify({
          referrer,
          url: authorizationUrl,
          error: errorUrl,
        })

        window.location.href = `${redirectUrl}/init?${params}`
      })
      .catch(err => {
        if (err && err.status === commonErrorCode.FEATURE_NOT_ENABLE) {
          // Catch the error due to premium feature restriction
          dispatch(showPaywallModal(paywallTypes.retrofit))
        }
        setError(err)
      })
      .finally(() => setLoading(false))
  }, [])

  return { isLoading, error, url, getUrl }
}

/**
 * Fetches the OAuth URL for a third-party provider from backend for
 * reconnection.
 *
 * @param {string} ecUUID - ID of email connection to reconnect
 */
export const useReconnectUrl = (ecUUID: string) => {
  const auth = useAuth()
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch: Dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState('')

  const { account } = useRetrofitAccount(ecUUID)
  const redirectUrl = useRedirectUrl()
  const { referrer, error: errorUrl } = useReferrer()

  const getUrl = useCallback(
    (connectionType: ConnectionType) => {
      // IMAP reconnect
      if (imapConnectionTypes.includes(Number(connectionType))) {
        const userId = get(match, 'params.userId')
        history.push({
          search: qs.stringify({ connection: ecUUID }),
          pathname: generatePath(routePaths.retrofitIMAP, { userId }),
        })
        return
      }

      // Other oauth provider reconnect
      if (!auth || isNil(ecUUID)) {
        return
      }

      setLoading(true)
      // Check the remote status for the account before
      // Redirect to reconnect URL
      return dispatch(
        actions.checkRemoteAccountStatus(ecUUID, connectionStatus.ACTIVE)
      )
        .then(isExpected => {
          if (isExpected) {
            return
          } else {
            return apiClient
              .getAuthUrl(connectionType, redirectUrl, {
                auth,
                emailAddress: account.emailAddress,
              })
              .then(res => {
                const { authorizationUrl } = res.result
                setUrl(authorizationUrl)

                // Redirect to oauth init path
                const params = qs.stringify({
                  referrer,
                  url: authorizationUrl,
                  error: errorUrl,
                })

                window.location.href = `${redirectUrl}/init?${params}`
              })
          }
        })
        .catch(err => {
          if (err && err.status === commonErrorCode.FEATURE_NOT_ENABLE) {
            // Catch the error due to premium feature restriction
            dispatch(showPaywallModal(paywallTypes.retrofit))
          }
          setError(err)
        })
        .finally(() => setLoading(false))
    },
    [ecUUID]
  )

  return { isLoading, error, url, getUrl }
}

const AUTH_REFERRER = 'auth_referrer'
const AUTH_ERROR = 'auth_error'

/**
 * Obtains the auth URL from query parameters and initialize the redirect to
 * the auth URL.
 */
export const useAuthInitialization = () => {
  const { url, referrer, error } = useQueryParams()

  useEffect(() => {
    if (url) {
      if (referrer) {
        // Write referrer into local storage to read for later
        localStorage.setItem(AUTH_REFERRER, referrer)
      }

      if (error) {
        // Write error redirect URL into local storage to read for later
        localStorage.setItem(AUTH_ERROR, error)
      }

      // Redirect to provider
      window.location.replace(url)
    }
  }, [])
}

/**
 * Handles the callback from OAuth provider, and redirect back to referrer
 * with code and state as query parameters.
 */
export const useAuthCallback = () => {
  const { error } = useQueryParams()

  useEffect(() => {
    const referrer = localStorage.getItem(AUTH_REFERRER)
    const errorUrl = localStorage.getItem(AUTH_ERROR)
    const params = qs.stringify({ error })

    // Redirect back to referrer
    if (referrer) {
      // Clean up local storage
      localStorage.removeItem(AUTH_REFERRER)
      localStorage.removeItem(AUTH_ERROR)

      if (error && errorUrl) {
        // Error state
        window.location.replace(`${errorUrl}?${params}`)
      } else {
        // Normal referrer state
        window.location.replace(referrer)
      }
    } else {
      // TODO: Bad error, just redirect back to the OAuth error screen
      console.log('Redirect back to OnMail error screen')
    }
  }, [])
}

/**
 * Returns all accounts, and provide an interface for component to fetch all
 * accounts.
 */
export const useRetrofitAccounts = () => {
  const history = useHistory()
  const queryParams = useQueryParams()
  const dispatch: Dispatch = useDispatch()

  const active = useSelector(selectors.getActiveAccount)
  const accounts = useSelector(selectors.getConnectedAccounts)

  const flags = useSelector(getFeatureFlags)
  const unlimitedImportAccounts = useSelector(isAllowUnlimitedImportAccounts)
  const canImport =
    unlimitedImportAccounts || accounts.length < flags.totalImportAccountsLimit

  // Fetch list of accounts again from server
  const fetchAll = () => {
    dispatch(actions.fetchAllAccounts())
  }

  // Set the active account
  const setActive = async (ecUUID: string) => {
    dispatch(showLoading())
    await dispatch(actions.setFilterAccount(ecUUID))
    dispatch(hideLoading())
  }

  // Set the active account to query param
  const setActiveQueryParam = useCallback(
    (ecUUID: string) => {
      let nextQueryParams = { ...queryParams, retrofit: undefined }

      if (ecUUID !== retrofitAccountFilter.ALL) {
        nextQueryParams['retrofit'] = ecUUID
      }

      history.replace({ search: qs.stringify(nextQueryParams) })
    },
    [queryParams]
  )

  return {
    accounts,
    active,
    fetchAll,
    setActive,
    setActiveQueryParam,
    canImport,
  }
}

/**
 * Returns account details and operations for retrofit accounts:
 * 1. Edit
 * 2. Remove
 * 3. Refresh - fetch account details again from server
 */
export const useRetrofitAccount = (ecUUID: string) => {
  const dispatch: Dispatch = useDispatch()
  const account = useSelector(
    useMemo(() => selectors.makeGetAccount(ecUUID), [ecUUID])
  )
  const isRemoving = useSelector(selectors.isRemoveAccountLoading)

  const edit = () => {
    console.log('EDIT account: ', ecUUID)
  }

  const remove = () => {
    return dispatch(actions.removeAccount(ecUUID))
  }

  // Fetch account detail again from server
  const fetchDetail = () => {
    dispatch(actions.fetchAccount(ecUUID))
  }

  return { account, edit, remove, fetchDetail, isRemoving }
}

/**
 * Returns methods useful for checking the sync progress of Retrofit at the
 * moment.
 */
export const useRetrofitSyncProgress = ({
  startSync = false,
}: { startSync?: boolean } = {}) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const toast = useToast(toastTypes.notification)
  const accounts = useSelector(selectors.getAccounts)
  const syncProgress = useSelector(selectors.getSyncProgress)
  const syncStatuses = useSelector(selectors.getSyncStatuses)
  const newAccounts = useSelector(selectors.getNewAccounts)
  const auth = useAuth()

  const isPaused = useSelector(selectors.isSyncProgressPaused)
  const isPulling = useSelector(selectors.isSyncProgressPulling)
  const isLoading = useSelector(selectors.isSyncStatusLoading)

  const activeAccounts: $ReadOnlyArray<RetrofitAccount> = accounts.filter(
    account => account.status === connectionStatus.ACTIVE
  )

  const dismissBanner = async (ecUUIDs: $ReadOnlyArray<string>) => {
    if (!auth) {
      return
    }

    try {
      await Promise.all(
        ecUUIDs.map(ecUUID => apiClient.dismissBanner(ecUUID, { auth }))
      )
      await dispatch(actions.fetchSyncStatus())
    } catch (e) {
      console.error('Dismiss banner not persisted.', ecUUIDs)
      toast.showToast(t('serverError'), toastVariants.error)
    }
  }

  // See it as full sync only when the status is complete
  const isSyncing = fromPairs<string, boolean>(
    activeAccounts.map(({ ecUUID }) => [
      ecUUID,
      syncStatuses[ecUUID]
        ? syncStatuses[ecUUID].syncInEndFullSync ===
          connectionSyncStatus.INCOMPLETE
        : false,
    ])
  )

  const isFullSync = activeAccounts.every(({ ecUUID }) => !isSyncing[ecUUID])

  const isSyncError = fromPairs<string, boolean>(
    activeAccounts.map(({ ecUUID }) => {
      let isError = false
      if (ecUUID in syncStatuses) {
        const { syncStatus } = syncStatuses[ecUUID]
        isError =
          syncStatus !== syncAccountStatus.INITIALIZING &&
          syncStatus !== syncAccountStatus.SYNCING
      }
      return [ecUUID, isError]
    })
  )

  const syncErrorMessages: { [ecUUID: string]: string } = mapValues(
    isSyncError,
    (isError, ecUUID) => {
      const status = syncStatuses[ecUUID]
      if (isError && status) {
        let message = ''
        switch (status.syncStatus) {
          case syncAccountStatus.THROTTLED:
            message = t('retrofit.syncProgress.tooltip.error.throttled')
            break
          case syncAccountStatus.IMAP_ACCESS_NOT_GRANTED:
            message = t('retrofit.syncProgress.tooltip.error.imapDenied')
            break
          default:
            message = t('retrofit.syncProgress.tooltip.error')
        }
        return message
      }

      return ''
    }
  )

  useEffect(() => {
    if (isLoading) {
      return
    }
    // Stop the interval when the whole sync progress is done
    if (startSync && isPulling && isFullSync) {
      dispatch(actions.syncProgress.stop())
    }
  }, [isLoading])

  useEffect(() => {
    if (startSync && accounts.length) {
      dispatch(actions.syncProgress.start())
    }

    return () => {
      if (startSync) {
        dispatch(actions.syncProgress.stop())
      }
    }
  }, [accounts.length > 0])

  return {
    activeAccounts,
    syncStatuses,
    newAccounts,
    dismissBanner,
    isPaused,
    isSyncing,
    isFullSync,
    isSyncError,
    syncErrorMessages,
    progress: fromPairs<string, number>(
      activeAccounts.map(({ ecUUID }) => [
        ecUUID,
        syncProgress.accounts[ecUUID] || 0,
      ])
    ),
    totalProgress: syncProgress.total,
  }
}

export const useIMAPForm = () => {
  const dispatch: Dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { userId } = match.params

  const [isAdvanced, setAdvanced] = useState(false)
  const [isCreating, setCreating] = useState(false)
  const [advancedConfig, setAdvancedConfig] = useState<?AdvancedConfig>()
  const { t } = useTranslation()
  const toast = useToast(toastTypes.notification)
  const auth = useAuth()

  const providers = useSupportedProviders()

  const flags = useSelector(getFeatureFlags)

  const onCreate = (
    values: FormValues,
    inputProvider?: ConnectionType
  ): Promise<mixed> => {
    setCreating(true)
    if (!auth) {
      setCreating(false)
      return Promise.resolve()
    }

    const provider = providers.includes(inputProvider)
      ? inputProvider
      : connectionTypes.DEFAULT

    return new Promise((resolve, reject) => {
      if (isAdvanced) {
        resolve(mapValues(advancedConfig, (val, key) => values[key] || val))
      } else {
        apiClient
          .getImapConfig(values.emailAddress, { auth, provider })
          .then(res => {
            // $FlowFixMe
            const imapConfig: AdvancedConfig = res.result
            setAdvancedConfig(imapConfig)
            return resolve(imapConfig)
          })
          .catch(reject)
      }
    })
      .then((imapConfig: ?AdvancedConfig) =>
        // $FlowFixMe
        apiClient.createImap({ ...values, ...(imapConfig || {}) }, { auth })
      )
      .then(() =>
        dispatch(actions.fetchAllAccounts()).then(() =>
          history.replace(generatePath(routePaths.retrofitSuccess, { userId }))
        )
      )
      .catch(err => {
        if (err && err.status === commonErrorCode.FEATURE_NOT_ENABLE) {
          // Catch the error due to premium feature restriction
          return dispatch(showPaywallModal(paywallTypes.retrofit))
        } else {
          throw err
        }
      })
      .catch(err => {
        let message = t('retrofit.imap.failure')
        let [field, fieldMessage] = ['', '']
        if (err && err.status) {
          switch (err.status) {
            case commonErrorCode.INVALID_IMAP_SETTINGS:
              fieldMessage = t('retrofit.imap.failure.config')
              field = 'imapHost'
              break
            case commonErrorCode.INVALID_SMTP_SETTINGS:
              fieldMessage = t('retrofit.imap.failure.config')
              field = 'smtpHost'
              break
            case commonErrorCode.INVALID_PASSWORD:
              fieldMessage = t('retrofit.imap.failure.password')
              field = 'password'
              break
            case commonErrorCode.MAX_CONNECTIONS:
              message = t('retrofit.error.quota', {
                maxCount: flags.totalImportAccountsLimit,
              })
              break
            case commonErrorCode.EXCEED_LIMIT:
              message = t('retrofit.imap.failure.exceeded')
              break
            case commonErrorCode.OAUTH_ACCESS_DENIED:
              message = t('retrofit.imap.failure.denied')
              break
            default:
          }
        }
        toast.showToast(message, toastVariants.error)
        setAdvanced(true)
        if (field && fieldMessage) {
          return Promise.reject({ field, message: fieldMessage })
        }
      })
      .finally(() => setCreating(false))
  }

  return {
    advancedConfig,
    isCreating,
    isAdvanced,
    onCreate,
  }
}

export const useProviderLogos = () => {
  function getLogo(account: RetrofitAccount) {
    const { connectionType } = account

    return providerLogos[connectionType]
  }

  return {
    getLogo,
    logos: providerLogos,
  }
}

export const useProviderNames = () => {
  const { t } = useTranslation()

  return {
    [connectionTypes.DEFAULT.toString()]: t('retrofit.provider.other.name'),
    [connectionTypes.GMAIL_OAUTH.toString()]: t(
      'retrofit.provider.googleOAuth.name'
    ),
    [connectionTypes.YAHOO_OAUTH.toString()]: t('retrofit.provider.yahoo.name'),
    [connectionTypes.OUTLOOK_OAUTH.toString()]: t(
      'retrofit.provider.outlook.name'
    ),
    [connectionTypes.GMAIL_IMAP.toString()]: t('retrofit.provider.google.name'),
    [connectionTypes.ICLOUD_IMAP.toString()]: t(
      'retrofit.provider.icloud.name'
    ),
    [connectionTypes.YAHOO_IMAP.toString()]: t('retrofit.provider.yahoo.name'),
    [connectionTypes.AOL_IMAP.toString()]: t('retrofit.provider.aol.name'),
  }
}

const useAuth = () => {
  const auth = useSelector(getAuth())
  return auth
}

const useRedirectUrl = () => {
  const { onmailDomain } = useDomain()
  const parsed = parseOnMailHost(window.location.host)

  let redirectUrl
  if (process.env.NODE_ENV !== 'development') {
    if (parsed.preview) {
      redirectUrl = `${window.location.origin}${routePaths.oauth}`
    } else {
      redirectUrl = `https://mail.${onmailDomain}${routePaths.oauth}`
    }
  } else {
    redirectUrl = `https://localhost:3000${routePaths.oauth}`
  }

  return redirectUrl
}

const useReferrer = () => {
  const userId = useOrderId()

  // Form the OAuth path for performing OAuth initialization and callbacks
  const origin = window.location.origin
  const referrer = `${origin}${generatePath(routePaths.retrofitSuccess, {
    userId,
  })}`
  const error = `${origin}${generatePath(routePaths.retrofitError, {
    userId,
  })}`

  return { referrer, error }
}

/**
 * returns a list of provider that can be imported
 * Which is filterd by the beta feature flags
 */
export const useSupportedProviders = () => {
  const gmailOAuthBeta = useBetaFeature(betaFeatures.retrofitGmailOAuth)

  return useMemo<$ReadOnlyArray<ConnectionType>>(
    () =>
      supportedProviders.filter(feature =>
        feature === connectionTypes.GMAIL_OAUTH ? gmailOAuthBeta.isEnable : true
      ),
    [gmailOAuthBeta.isEnable]
  )
}

export const useErrorRetrofitAccount = (): ?RetrofitAccount => {
  const { accounts } = useRetrofitAccounts()
  const syncStatus = useSelector(selectors.getSyncStatuses)

  const inactiveAccount = accounts.find(
    account => account.status === connectionStatus.INACTIVE
  )

  if (inactiveAccount) {
    return inactiveAccount
  }

  const errorStatuses = new Set([
    syncAccountStatus.INVALID_CREDENTIAL,
    syncAccountStatus.IMAP_ACCESS_NOT_GRANTED,
  ])

  const syncErrorAccount = _values(syncStatus).find(account =>
    errorStatuses.has(account.syncStatus)
  )

  if (syncErrorAccount) {
    return accounts.find(account => account.ecUUID === syncErrorAccount.ecUUID)
  }

  return null
}

export function useCurrentActiveAccount() {
  const { active } = useRetrofitAccounts()
  const currentActiveAccount = active
    ? [
        {
          emailAddress: active.emailAddress,
          ecUUID: active.ecUUID,
          labelUUID: active.labelUUID,
        },
      ]
    : []
  return useMemo(() => currentActiveAccount, [active])
}
