// @flow
import { createSelector } from 'reselect'

import type { Attachment } from '@edison/webmail-core/types/attachment'
import { getLoadingStatus } from 'core/loading/selectors'
import type { State as AttachmentState } from './types'
import type { State, Selector } from 'types/state'

export const selectAttachmentState = (state: State) => state.attachments

export function getAttachmentState(): Selector<State> {
  return createSelector(
    selectAttachmentState,
    (state: AttachmentState) => state
  )
}
export function getAllAttachmentLinks(): Selector<Array<Attachment>> {
  return createSelector(selectAttachmentState, (state: AttachmentState) =>
    Object.values(state)
  )
}

export function getAttachments(
  uuids: Array<string>
): Selector<Array<Attachment>> {
  return createSelector(selectAttachmentState, (state: AttachmentState) =>
    uuids.filter(uuid => state[uuid]).map(uuid => state[uuid])
  )
}

export function getAttachment(uuid: string): Selector<Attachment> {
  return createSelector(
    selectAttachmentState,
    (state: AttachmentState) => state[uuid]
  )
}

export const isDownloadAllloading: Selector<boolean> = getLoadingStatus(
  'DOWNLOAD_ALL'
)
