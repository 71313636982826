// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type {
  State,
  ShowModal,
  HideModal,
  RemoveModal,
  HideAllModal,
  RemoveAllModals,
} from './types'

const initialState: State = []

export default createReducer<State, ShowModal | HideModal>(initialState, {
  [actions.showAction.toString()]: showModal,
  [actions.hideAction.toString()]: hideModal,
  [actions.hideAllModalsAction.toString()]: hideAllModal,
  [actions.removeAction.toString()]: removeModal,
  [actions.removeAllModalsAction.toString()]: removeAllModals,
})

function showModal(state: State, action: ShowModal) {
  const { key, props = {} } = action.payload
  return [...state.filter(each => each.isOpen), { key, props, isOpen: true }]
}
function hideAllModal(state: State, action: HideAllModal) {
  const { exceptionKeys } = action.payload
  return state
    .filter(each => each.isOpen && !exceptionKeys.includes(each.key))
    .map(each => {
      return {
        ...each,
        isOpen: false,
      }
    })
}
function hideModal(state: State, action: HideModal) {
  const { key } = action.payload
  return state
    .filter(each => each.isOpen)
    .map(each => {
      const isOpen = each.key === key ? false : each.isOpen
      return {
        ...each,
        isOpen,
      }
    })
}

function removeModal(state: State, action: RemoveModal) {
  const { key } = action.payload

  return state.filter(each => each.isOpen || each.key !== key)
}

function removeAllModals(state: State, action: RemoveAllModals) {
  const { exceptionKeys } = action.payload
  return state.filter(each => each.isOpen || exceptionKeys(each.key))
}
