// @flow
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import values from 'lodash/values'
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'

import {
  getRetrofitState,
  getActiveAccount,
  getDeletedAccounts,
  getConnectedAccounts,
} from 'core/retrofit/selectors'
import { getExtraInfoState, getViewThreadsState } from 'core/metadata/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { labelTypes, labelNames, retrofitAccountFilter } from 'utils/constants'

import type { Label } from '@edison/webmail-core/types/labels'
import type { ExtraInfoState, ViewThreadsState } from 'core/metadata/types'
import type { State as LabelState } from './types'
import type { State, Selector } from 'types/state'

export const getLabelState = (state: State) => state.labels

/**
 * Returns the object with active retrofit labels from label state indexed by label ID
 */
export const getActiveRetrofitLabelsById: Selector<LabelState> = createSelector(
  getLabelState,
  getConnectedAccounts,
  (labelState, activeAccounts) => {
    let labels = {}
    for (let { labelUUID } of activeAccounts) {
      if (labelUUID in labelState) {
        labels[labelUUID] = { ...labelState[labelUUID] }
      }
    }

    return labels
  }
)

/**
 * Returns the object with deleted retrofit labels from label state indexed by label ID
 */
export const getDeletedRetrofitLabelsById: Selector<LabelState> = createSelector(
  getLabelState,
  getDeletedAccounts,
  (labelState, deletedAccounts) => {
    let labels = {}
    for (let { labelUUID } of deletedAccounts) {
      if (labelUUID in labelState) {
        labels[labelUUID] = { ...labelState[labelUUID] }
      }
    }

    return labels
  }
)

/*
 * Return the object of label which filtered by active retrofit account
 */
export const getLabelsWithRetrofitFilter: Selector<LabelState> = createSelector(
  getLabelState,
  getActiveRetrofitLabelsById,
  getDeletedRetrofitLabelsById,
  getRetrofitState,
  (
    labelState: LabelState,
    activeRetrofitLabels,
    deletedRetrofitLabels,
    retrofitState
  ) => {
    let nonRetrofitLabels = {}

    for (let labelId in labelState) {
      const label = labelState[labelId]
      // allAccounts and onmail label should be used as retrofit account
      if ([labelNames.allAccounts, labelNames.onmail].includes(label.id)) {
        continue
      } else if (label.type !== labelTypes.RETROFIT) {
        nonRetrofitLabels[label.id] = { ...label }
      }
    }

    let filteredRetrofits = {}
    const { filterBy, accounts } = retrofitState

    switch (filterBy) {
      case retrofitAccountFilter.ALL:
        const allAccountLabel = labelState[labelNames.allAccounts]
        // Mocked `allLabel` is the symbol to show all messags
        // `deletedRetrofitLabels` is used to filtered out the messages
        // from the disconnected accounts
        filteredRetrofits = {
          ...deletedRetrofitLabels,
          [labelNames.allAccounts]: allAccountLabel,
        }
        break
      case retrofitAccountFilter.ONMAIL:
        const onmailLabel = labelState[labelNames.onmail]
        // Mocked `OnMailLabel` is the symbol to show messages only from OnMail
        // Retrofit related labels is used to filtered out the messages from
        // 3-party accounts
        filteredRetrofits = {
          ...activeRetrofitLabels,
          ...deletedRetrofitLabels,
          [labelNames.onmail]: onmailLabel,
        }
        break
      default:
        // Only append the corresponding active account label
        const { labelUUID } = accounts[filterBy]
        if (labelUUID in activeRetrofitLabels) {
          filteredRetrofits = {
            [labelUUID]: { ...activeRetrofitLabels[labelUUID] },
          }
        }
    }

    return {
      ...nonRetrofitLabels,
      ...filteredRetrofits,
    }
  }
)

export function getAllLabels(): Selector<Array<Label>> {
  return createSelector(getLabelState, (state: LabelState) => values(state))
}

export function getAllLabelsState(): Selector<LabelState> {
  return createSelector(getLabelState, (state: LabelState) => state)
}

export function getSystemLabels(): Selector<Array<Label>> {
  return createSelector(getAllLabels(), (labels: Array<Label>) =>
    labels.filter(each => each.type === labelTypes.SYSTEM)
  )
}

export function getCustomLabels(): Selector<Array<Label>> {
  return createSelector(getAllLabels(), (labels: Array<Label>) =>
    labels
      .filter(each => each.type === labelTypes.CUSTOM)
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
  )
}

export function getCustomLabelsMap(): Selector<{ [key: string]: Label }> {
  return createSelector(getCustomLabels(), (labels: Array<Label>) =>
    labels.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {})
  )
}

export function getSplitInboxLabels(): Selector<Array<Label>> {
  return createSelector(getAllLabels(), (labels: Array<Label>) =>
    labels.filter(label => label.type === labelTypes.SPLIT_INBOXES)
  )
}

export function getLabelById(labelId: string): Selector<Label> {
  return createSelector(getLabelState, (state: LabelState) => state[labelId])
}

export function isLabelCreating(): Selector<boolean> {
  return createSelector(
    getLoadingStatus('LABEL_CREATE'),
    (isCreating: boolean) => isCreating
  )
}

export function getLabelNamesById(): Selector<{ [id: string]: string }> {
  return createSelector(getLabelState, (state: LabelState) =>
    values(state).reduce((prev, { id, name }) => ({ ...prev, [id]: name }), {})
  )
}

const _getDisplayLabelsById = createSelector(
  getLabelState,
  getCustomLabels(),
  (state: LabelState, custom: Array<Label>) => {
    return keyBy(
      [
        ...[
          // Display labels to the user
          labelNames.archive,
          labelNames.drafts,
          labelNames.trash,
          labelNames.spam,
        ]
          .map(id => state[id])
          .map(label => {
            // Archive is known as Done in UI
            if (label && label.id === labelNames.archive) {
              return { ...label, name: 'DONE' }
            }

            return label ? label : {}
          }),
        ...custom,
      ],
      ({ id }) => id
    )
  }
)

export function getDisplayLabelsById(): Selector<{ [labelId: string]: Label }> {
  return _getDisplayLabelsById
}

export function isLabelStateLoaded(): Selector<boolean> {
  return createSelector(getAllLabels(), labels => !!labels.length)
}

// Label Unread Count
const _getLabelUnreadCount = createSelector(
  getExtraInfoState(),
  getLabelState,
  (extraInfo: ExtraInfoState, labelState: LabelState) => {
    return {
      ...extraInfo.unread,
      ...mapValues(labelState, (_, labelId) => extraInfo.unread[labelId] || 0),
    }
  }
)

export function getLabelUnreadCount(): Selector<{ [labelId: string]: number }> {
  return _getLabelUnreadCount
}

// Label Total Count
const _getLabelTotalCount = createSelector(
  getLabelState,
  getExtraInfoState(),
  getViewThreadsState(),
  (
    labelState: LabelState,
    extraInfo: ExtraInfoState,
    viewThreads: ViewThreadsState
  ) => {
    return {
      ...extraInfo.total,
      ...mapValues(
        labelState,
        (_, labelId) =>
          get(extraInfo.total, labelId) ||
          get(viewThreads, labelId, []).length ||
          0
      ),
    }
  }
)

export function getLabelTotalCount(): Selector<{ [labelId: string]: number }> {
  return _getLabelTotalCount
}

export function getTotalCountByLabel(labelId: string): Selector<number> {
  return createSelector(_getLabelTotalCount, total => total[labelId] || 0)
}

export const getAccountUnreadCount: Selector<{
  [labelId: string]: number,
}> = createSelector(
  getActiveAccount,
  getExtraInfoState(),
  getLabelState,
  (activeAccount, extraInfo, labelState) => {
    let activeAccountLabel
    if (!activeAccount) {
      activeAccountLabel = labelNames.allAccounts
    } else if (activeAccount.labelUUID === retrofitAccountFilter.ONMAIL) {
      activeAccountLabel = labelNames.onmail
    } else {
      activeAccountLabel = activeAccount.labelUUID
    }

    const retrofitLabels: $ReadOnlyArray<string> = values(labelState)
      .filter(({ type }) => type === labelTypes.RETROFIT)
      .map(({ id }) => id)

    return [
      labelNames.allAccounts,
      labelNames.onmail,
      ...retrofitLabels,
    ].reduce<{ [string]: number }>(
      (prev, curr) => ({
        ...prev,
        [curr]:
          curr === activeAccountLabel
            ? extraInfo.unread[labelNames.unread]
            : extraInfo.unread[curr] || 0,
      }),
      {}
    )
  }
)
