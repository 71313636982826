// @flow
import moment from 'moment'
import head from 'lodash/head'
import uniq from 'lodash/uniq'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import * as localStorage from './localStorage'

import {
  threadDetailContactDrawerOpenKey,
  userTutorialKey,
} from 'core/flags/constants'
import { calendarResolution } from '../../core/calendar/constants-events'
import type { CalendarResolutionTypes } from '../../core/calendar/types-events'
import { cacheSearchQuery, cacheRecentViewEmails } from './LRUCache'
export const userKey = '_user_'
export const csrfKey = '_csrf_'
export const logoutKey = '_logout_'
export const reduxStateKey = 'edison-webmail-state'
export const deleteAttachmentLinkTipKey =
  'edison-webmail-delete-attachment-link'
export const sendWithUploadingAttachmentTipKey =
  'edison-webmail-send-with-uploading-attachment'
export const blockSenderPromptKey = 'block-sender-prompt'
export const superSessionKey = 'onmail-ssid'
export const listViewKey = 'edison-webmail-list-view'
export const themePreferKey = 'edison-webmail-theme-prefer'
export const upgradeTipKey = 'edison-hide-upgrade-tip'
export const splitInboxProTipKey = 'edison-hide-split-inbox-tip'
export const calendarViewResolutionKey =
  'edison-webmail-calendar-view-resolution'

export const recentViewEmailsKey = 'edison-webmail-recent-view-emails'
export const recentSearchQueryKey = 'edison-webmail-recent-search-query'

export const forwardOpenedKey = 'edison-webmail-forward-opened-key'

type DiffJSONValue<T> = {
  key: string,
  prev: T,
  curr: T,
}

export const loadJSON = (key: string, defaultValue: any = {}) => {
  let res = defaultValue
  try {
    res = JSON.parse(localStorage.get(key)) || defaultValue
  } catch (e) {
    res = defaultValue
  }
  return res
}

const loadCsrfTokens = () => loadJSON(csrfKey)

export function diffJSON(
  prev: string,
  curr: string
): $ReadOnlyArray<DiffJSONValue<any>> {
  const [prevVal, currVal] = [prev, curr].map(input => {
    try {
      return JSON.parse(input) || {}
    } catch (e) {
      return {}
    }
  })

  let diff = {}
  for (let key in prevVal) {
    if (!(key in currVal) || prevVal[key] !== currVal[key]) {
      diff[key] = {
        key,
        prev: prevVal[key],
        curr: currVal[key],
      }
    }
  }

  return values(diff)
}

export function clearUserData(orderId: ?string) {
  if (orderId) {
    clearUser(orderId)
    clearCsrfToken(orderId)
    cleartListViews(orderId)
    clearUserTutorial(orderId)
    clearViewEmails(orderId)
    clearSearchQuery(orderId)
  }
}

export function clearAllUsersData() {
  clearUsers()
  clearCsrfTokens()
  clearSuperSession()
  clearAllListViews()
  clearAllUsersTutorial()
  clearAllViewEmails()
  clearAllSearchQuery()
}

export function persistCsrfToken(orderId: string, token: string) {
  let csrfs = loadCsrfTokens()
  csrfs[orderId] = token
  localStorage.set(csrfKey, JSON.stringify(csrfs))
}

export function getCsrfToken(orderId: string): ?string {
  let csrfs = loadCsrfTokens()
  return csrfs[orderId] || null
}

export function getCsrfDiffInfo(
  prev: string,
  curr: string
): ?DiffJSONValue<string> {
  const diffs = diffJSON(prev, curr)
  // Only one CSRF diff at a time
  return head(diffs)
}

export function clearCsrfToken(orderId: string) {
  let csrfs = loadCsrfTokens()
  delete csrfs[orderId]
  localStorage.set(csrfKey, JSON.stringify(csrfs))
}

export function clearCsrfTokens() {
  localStorage.clear(csrfKey)
}

export function clearViewEmails(orderId: string) {
  let viewEmail = loadJSON(recentViewEmailsKey)
  delete viewEmail[orderId]
  localStorage.set(recentViewEmailsKey, JSON.stringify(viewEmail))
}

export function clearViewEmailsById(orderId: string, threadIds: string[]) {
  const viewEmails = loadJSON(recentViewEmailsKey, { [orderId]: [] })
  const orderIdEmail = viewEmails[orderId]
  const lruCacheEmails = cacheRecentViewEmails()
  threadIds.forEach(id => lruCacheEmails.delete(id))
  if (orderIdEmail && orderIdEmail.length) {
    const cacheThreadIds = orderIdEmail.filter(id => !threadIds.includes(id))
    const cacheIds = JSON.stringify({
      ...viewEmails,
      [orderId]: cacheThreadIds,
    })
    localStorage.set(recentViewEmailsKey, cacheIds)
  }
}

export function clearAllViewEmails() {
  localStorage.clear(recentViewEmailsKey)
}

export function clearSearchQuery(orderId: string) {
  let query = loadJSON(recentSearchQueryKey)
  delete query[orderId]
  localStorage.set(recentSearchQueryKey, JSON.stringify(query))
}
export function clearAllSearchQuery() {
  localStorage.clear(recentSearchQueryKey)
}

export function markLogout(orderId?: string) {
  localStorage.set(
    logoutKey,
    JSON.stringify({
      orderId: !isNil(orderId) ? orderId : 'all',
      updateTime: moment().unix(),
    })
  )
}

export function getLogoutInfo() {
  try {
    const info = JSON.parse(localStorage.get(logoutKey))
    return info
  } catch (e) {
    return null
  }
}

const loadUsers = () => loadJSON(userKey)

export function persistUsers(users: { [orderId: string]: string }) {
  localStorage.set(userKey, JSON.stringify(users))
}

export function persistUser(orderId: string, email: string) {
  const users = loadUsers()
  users[orderId] = email
  localStorage.set(userKey, JSON.stringify(users))
}

export function getUser(orderId: string): ?string {
  const users = loadUsers()
  return users[orderId] || null
}

export function clearUser(orderId: string) {
  let users = loadUsers()
  delete users[orderId]
  localStorage.set(userKey, JSON.stringify(users))
}

export function clearUsers() {
  localStorage.clear(userKey)
}

export function persistSuperSessionId(superSessionId: string) {
  localStorage.set(superSessionKey, superSessionId)
}

export function clearSuperSession() {
  localStorage.clear(superSessionKey)
}

export function getSuperSessionId() {
  return localStorage.get(superSessionKey) || null
}

export function clearUserTutorial(orderId: string) {
  try {
    const vals = localStorage.get(userTutorialKey)
    const { [orderId]: _, ...rest } = JSON.parse(vals)
    localStorage.set(userTutorialKey, JSON.stringify(rest))
  } catch (e) {}
}

export function clearAllUsersTutorial() {
  localStorage.clear(userTutorialKey)
}

let calendarViewResolutionCache =
  localStorage.get(calendarViewResolutionKey) || calendarResolution.Week
export const setCalendarViewResolution = (
  viewType: CalendarResolutionTypes
) => {
  calendarViewResolutionCache = viewType
  localStorage.set(calendarViewResolutionKey, viewType)
}
export const getCalendarViewResolution = (): CalendarResolutionTypes => {
  return calendarViewResolutionCache
}

let threadDetailContactDrawerOpenCache =
  localStorage.get(threadDetailContactDrawerOpenKey) || undefined
export const setThreadDetailContactDrawerOpen = (open: boolean | undefined) => {
  threadDetailContactDrawerOpenCache = open ? '1' : '0'
  localStorage.set(
    threadDetailContactDrawerOpenKey,
    threadDetailContactDrawerOpenCache
  )
}
export const getThreadDetailContactDrawerOpen = (): boolean | undefined => {
  if (threadDetailContactDrawerOpenCache !== undefined) {
    return threadDetailContactDrawerOpenCache === '1'
  }
  return undefined
}

export function setRecentViewEmails(id: string, orderId = 0) {
  if (!id) return
  const lruCacheEmails = cacheRecentViewEmails()
  lruCacheEmails.set(id, id)
  const ids = []
  for (const id of lruCacheEmails.getAllValues()) {
    ids.push(id)
  }
  const emailsIdCache = loadJSON(recentViewEmailsKey, { [orderId]: [] })
  localStorage.set(
    recentViewEmailsKey,
    JSON.stringify({ ...emailsIdCache, [orderId]: ids })
  )
}

export function getRecentViewEmails(orderId = 0) {
  const lruCacheEmails = cacheRecentViewEmails(orderId)
  const value = lruCacheEmails.getAllValues()
  const result = { ids: [] }
  if (value.length) {
    for (const id of value) {
      result.ids.push(id)
    }
  }
  return result
}

export function setRecentSearch(query: string, orderId = 0) {
  if (!query.trim().length) return
  const lruCacheSearchQuery = cacheSearchQuery()
  lruCacheSearchQuery.set(query, query)
  const result = []
  for (const query of lruCacheSearchQuery.getAllValues()) {
    result.push(query)
  }
  const recentSearchQueryCache = loadJSON(recentSearchQueryKey, {
    [orderId]: [],
  })
  localStorage.set(
    recentSearchQueryKey,
    JSON.stringify({ ...recentSearchQueryCache, [orderId]: result })
  )
}

export function clearRecentSearch(content: string, orderId = 0) {
  const cache = loadJSON(recentSearchQueryKey)
  const lruCacheSearchQuery = cacheSearchQuery()
  if (cache[orderId]) {
    cache[orderId] = cache[orderId].filter(item => item !== content)
    localStorage.set(recentSearchQueryKey, JSON.stringify(cache))
    lruCacheSearchQuery.delete(content)
  }
}

export function getRecentSearch(orderId = 0) {
  const lruCacheSearchQuery = cacheSearchQuery(orderId)
  const value = lruCacheSearchQuery.getAllValues()
  const result = []
  if (value.length) {
    for (const item of value) {
      result.push({ content: item, id: item })
    }
  }
  return result
}

/**
 * Persists DeleteAttachmentTip state in browser storage.
 *
 * @public
 */
export function persistDeleteAttachmentTip(showTip: boolean) {
  localStorage.set(deleteAttachmentLinkTipKey, showTip)
}
/**
 * Returns the persisted DeleteAttachmentTip information in browser storage.
 *
 * @public
 */
export function loadPersistedDeleteAttachmentTip() {
  const s = localStorage.get(deleteAttachmentLinkTipKey) || null
  return s
}

export function clearDeleteAttachmentTip() {
  localStorage.clear(deleteAttachmentLinkTipKey)
}

/**
 * Persists DeleteAttachmentTip state in browser storage.
 *
 * @public
 */
export function persistSendWithUploadingAttachmentTip(showTip: boolean) {
  localStorage.set(sendWithUploadingAttachmentTipKey, showTip)
}
/**
 * Returns the persisted DeleteAttachmentTip information in browser storage.
 *
 * @public
 */
export function loadSendWithUploadingAttachmentTip() {
  return localStorage.get(sendWithUploadingAttachmentTipKey) || null
}

export function clearSendWithUploadingAttachmentTip() {
  localStorage.clear(sendWithUploadingAttachmentTipKey)
}

/**
 * Persists BlockSenderPrompt state in browser storage
 *
 * @public
 * @param {boolean} value - Don't show again or not
 */
export function persistBlockSenderPrompt(value: boolean) {
  localStorage.set(blockSenderPromptKey, value)
}

/**
 * Returns persisted BlockSenderPrompt state in browser storage
 *
 * @public
 * @default false
 */
export function loadBlockSenderPromptState() {
  return Boolean(localStorage.get(blockSenderPromptKey))
}

// User flag storage

export const createFlagStorage = (key: string) => ({
  setFlag: () => localStorage.set(key, 1),
  getFlag: () => localStorage.get(key) === '1',
  clearFlag: () => localStorage.clear(key),
})

const upgradeTipFlag = createFlagStorage(upgradeTipKey)
export const toggleUpgradeTipFlag = () => {
  const curr = upgradeTipFlag.getFlag()
  if (!!curr) {
    upgradeTipFlag.clearFlag()
  } else {
    upgradeTipFlag.setFlag()
  }
}
export const getUpgradeTipFlag = upgradeTipFlag.getFlag

const splitInboxTipFlag = createFlagStorage(splitInboxProTipKey)
export const hideSplitInboxTip = splitInboxTipFlag.setFlag
export const getSplitInboxTipFlag = splitInboxTipFlag.getFlag

export const setThemePrefer = (theme: string) =>
  localStorage.set(themePreferKey, theme)
export const getThemePrefer = () => localStorage.get(themePreferKey)
export const clearThemePrefer = () => localStorage.clear(themePreferKey)

// View config for splits

/**
 * Returns a list of label ID which is with list mode
 *
 * @public
 * @returns {Array<string>}
 */
export function getListViews(orderId: ?string): Array<string> {
  const all = loadJSON(listViewKey)
  return (all[orderId] || '').split('|').filter(Boolean)
}

/**
 * Clears all the list view config
 *
 * @public
 * @returns {boolean}
 */
export function clearAllListViews(): boolean {
  try {
    localStorage.clear(listViewKey)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Clears the list views by given order ID
 *
 * @public
 * @param {?string} orderId - User session order
 * @returns {boolean}
 */
export function cleartListViews(orderId: string): boolean {
  try {
    const { [orderId]: _, ...rest } = loadJSON(listViewKey)
    if (isEmpty(rest)) {
      localStorage.clear(listViewKey)
    } else {
      localStorage.set(listViewKey, JSON.stringify(rest))
    }
    return true
  } catch (e) {
    return false
  }
}

/**
 * Set the list views by given label IDs
 *
 * @public
 * @param {Array<string>} labelIds - The labels with list list mode
 * @returns {boolean}
 */
export function setListViews(
  orderId: ?string,
  labelIds: $ReadOnlyArray<string>
): boolean {
  try {
    if (orderId) {
      const all = loadJSON(listViewKey)
      if (labelIds.length) {
        const next = {
          ...all,
          [orderId]: uniq(labelIds).join('|'),
        }

        localStorage.set(listViewKey, JSON.stringify(next))
      } else {
        cleartListViews(orderId)
      }
    }
    return true
  } catch (e) {
    return false
  }
}

/**
 * Toggle the thread list mode by given label ID
 *
 * @public
 * @param {string} label - The label to toggle
 * @param {boolean} value - List mode or not
 * @returns {boolean}
 */
export function toggleListView(
  orderId: ?string,
  label: string,
  value: boolean
): boolean {
  let next = getListViews(orderId)
  if (value) {
    next = [...next, label]
  } else {
    next = next.filter(each => each !== label)
  }

  if (orderId) {
    return setListViews(orderId, next)
  } else return true
}

export function getForwardOpened() {
  return localStorage.get(forwardOpenedKey) || false
}
export function updateForwardOpened() {
  return localStorage.set(forwardOpenedKey, true)
}
