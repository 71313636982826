import { useMemo, useEffect } from 'react'

export default blob => {
  const src = useMemo(() => (blob ? URL.createObjectURL(blob) : ''), [blob])

  useEffect(() => {
    if (src) {
      return () => {
        URL.revokeObjectURL(src)
      }
    }
  }, [src])

  return src
}
