// @flow
import React, {
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import Dialog from '@edison/webmail-ui/components/HeroDialog'
import {
  getAttachmentPreview,
  getLargeAttachmentPreview,
} from 'core/previews/selectors'
import {
  fetchAttachmentPreviewLink,
  fetchLargeAttachmentPreviewLink,
  download,
} from 'core/previews/actions'

import Preview from '@edison/webmail-ui/components/Preview'
import NotAvailable from '@edison/webmail-ui/components/Preview/NotAvailable'
import Image from '@edison/webmail-ui/components/Preview/Image'
import PDF from '@edison/webmail-ui/components/Preview/PDF'
import Loading from '@edison/webmail-ui/components/Preview/Loading'

import {
  generateLargeAttachmentImagePreviewUrl,
  isEnablePreviewAttachment,
} from 'utils'
import { imagePreviewSizeOptions, routePaths } from 'utils/constants'
import type { Dispatch } from 'types/redux'
import { useOrderId } from '../../core/auth/hooks'
import ICSAttachmentPreview from './ICSAttachmentPreview'
import { hideAll } from '../../core/modals/actions'

export const AttachmentPreview = ({ attachment, ...rest }) => {
  const { id, preview, message } = attachment
  const [loaded, setLoaded] = useState(false)
  const previewId = `${message}_${id}`
  const src = useSelector(
    useMemo(
      () =>
        getAttachmentPreview(
          previewId,
          preview === 'img' ? imagePreviewSizeOptions.full : undefined
        ),
      [previewId]
    )
  )
  const dispatch: Dispatch = useDispatch()
  useEffect(() => {
    if (preview && !src) {
      dispatch(
        fetchAttachmentPreviewLink(
          attachment,
          preview === 'img' ? imagePreviewSizeOptions.full : undefined
        )
      ).finally(() => {
        setLoaded(true)
      })
    }
  }, [])

  if (!preview) {
    return <NotAvailable {...rest} />
  }
  if (!loaded && !src) return <Loading {...rest} />

  if (src) {
    return preview === 'img' ? (
      <Image src={src} {...rest} />
    ) : preview === 'pdf' ? (
      <PDF src={src} {...rest} />
    ) : (
      <NotAvailable {...rest} />
    )
  }
  return <NotAvailable {...rest} />
}
export const LargeAttachmentPreview = ({ attachment, password, ...rest }) => {
  const { id, preview } = attachment
  const previewId = id
  const [loaded, setLoaded] = useState(false)

  const src = useSelector(
    useMemo(() => getLargeAttachmentPreview(previewId), [previewId])
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (preview && !src) {
      dispatch(
        fetchLargeAttachmentPreviewLink(attachment, password)
      ).finally(() => setLoaded(true))
    }
  }, [])
  if (!preview) {
    return <NotAvailable {...rest} />
  }
  if (!loaded && !src) return <Loading {...rest} />

  if (src) {
    return preview === 'img' ? (
      <Image
        src={generateLargeAttachmentImagePreviewUrl(
          src,
          imagePreviewSizeOptions.full
        )}
        {...rest}
      />
    ) : preview === 'pdf' ? (
      <PDF src={src} {...rest} />
    ) : (
      <NotAvailable {...rest} />
    )
  }
  return <NotAvailable {...rest} />
}

const renderAttachmentPreview = ({
  attachments,
  index,
  onClose,
  onICSPreviewClose,
  handleDownload,
}) => {
  if (attachments[index].preview === 'ics') {
    return (
      <ICSAttachmentPreview
        attachment={attachments[index]}
        onDownload={() => handleDownload(index)}
        onClose={onICSPreviewClose}
      />
    )
  } else if (isEnablePreviewAttachment(attachments[index])) {
    if (attachments[index].type === 'large') {
      return (
        <LargeAttachmentPreview
          attachment={attachments[index]}
          onClose={onClose}
          onDownload={() => handleDownload(index)}
        />
      )
    } else {
      return (
        <AttachmentPreview
          attachment={attachments[index]}
          onClose={onClose}
          onDownload={() => handleDownload(index)}
        />
      )
    }
  } else {
    return (
      <NotAvailable
        onClose={onClose}
        onDownload={() => handleDownload(index)}
      />
    )
  }
}

const PreviewDialog = ({
  attachments,
  index: defaultIndex,
  onChangeIndex,
  onDownload,
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const orderId = useOrderId()

  const handleDownload = index => {
    if (onDownload) {
      return onDownload(index)
    }
    dispatch(download(attachments[index]))
  }

  const handleOpenInNewWindow = index => {
    const attachment = attachments[index]
    const { message, id } = attachment
    window.open(
      generatePath(
        attachment.type === 'large'
          ? routePaths.largeAttachmentPreview
          : routePaths.attachmentPreview,
        {
          userId: orderId,
          message,
          attachment: id,
        }
      ),
      '_blank'
    )
  }

  const onICSPreviewClose = useCallback(() => {
    dispatch(hideAll())
    onClose()
  }, [onClose, attachments])

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      BackdropProps={{
        invisible: true,
      }}
    >
      {/* avoid flash when close dialog */}
      {isOpen && (
        <Preview
          attachments={attachments}
          defaultIndex={defaultIndex}
          onChangeIndex={onChangeIndex}
          onOpenInNewWindow={handleOpenInNewWindow}
          onDownload={handleDownload}
          onClose={onClose}
        >
          {({ index }) => (
            <Fragment key={index}>
              {renderAttachmentPreview({
                attachments,
                index,
                onClose,
                onICSPreviewClose,
                handleDownload,
              })}
            </Fragment>
          )}
        </Preview>
      )}
    </Dialog>
  )
}

export default PreviewDialog
