// @flow
import isNil from 'lodash/isNil'
import { createReducer } from 'utils/redux'
import * as actions from './actions'
import { premiumFreePlanId } from 'utils/constants'

import type {
  PremiumPlan,
  CurrentPremiumPlan,
  BillingHistoryItem,
  PaymentMethod,
  PremiumPreview,
} from '@edison/webmail-core/types/premium'
import type {
  PremiumActions,
  FetchPlansSuccess,
  FetchCurrentPlanSuccess,
  PurchasePlanSuccess,
  ChangePlanSuccess,
  UnsubscribePlanSuccess,
  FetchBillingHistorySuccess,
  FetchPaymentMethodSuccess,
  UpdatePaymentMethodSuccess,
  PremiumPreviewSuccess,
} from './types'

export type State = {|
  currentPlan: ?CurrentPremiumPlan,
  paymentMethod: ?PaymentMethod,
  latestSubscription: ?{
    // Plan that user purchased in the latest subscription
    plan: CurrentPremiumPlan,
    // Stripe subscription object for user
    subscription: Object,
  },
  plans: {
    [planId: string]: PremiumPlan,
  },
  order: [],
  billingHistory: $ReadOnlyArray<BillingHistoryItem>,
  preview: {
    [planId: string]: PremiumPreview,
  },
|}

const initialState: State = {
  currentPlan: null,
  paymentMethod: null,
  latestSubscription: null,
  plans: {},
  order: [],
  billingHistory: [],
  preview: {},
}

export default createReducer<State, PremiumActions>(initialState, {
  [actions.fetchPlansActions.success.toString()]: fetchPlansSuccess,
  [actions.fetchCurrentPlanActions.success.toString()]: fetchCurrentPlanSuccess,
  [actions.purchasePlanActions.success.toString()]: purchasePlanSuccess,
  [actions.changePlanActions.success.toString()]: changePlanSuccess,
  [actions.unsubscribeActions.success.toString()]: unsubscribeSuccess,
  [actions.fetchBillingHistoryActions.success.toString()]: fetchBillingHistorySuccess,
  [actions.fetchPaymentMethodActions.success.toString()]: fetchPaymentMethodSuccess,
  [actions.updatePaymentMethodActions.success.toString()]: updatePaymenMethodSuccess,
  [actions.premiumPreviewActions.success.toString()]: premiumPreviewSuccess,
})

function fetchPlansSuccess(state: State, action: FetchPlansSuccess) {
  const { plans } = action.payload

  return {
    ...state,
    order: plans.map(plan => plan.id),
    plans: plans.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
  }
}

function fetchCurrentPlanSuccess(
  state: State,
  action: FetchCurrentPlanSuccess
) {
  const { plan } = action.payload

  return {
    ...state,
    currentPlan: plan,
  }
}

function purchasePlanSuccess(state: State, action: PurchasePlanSuccess) {
  const { plan } = action.payload

  return {
    ...state,
    currentPlan: plan,
  }
}

function changePlanSuccess(state: State, action: ChangePlanSuccess) {
  const { plan } = action.payload

  return {
    ...state,
    currentPlan: plan,
  }
}

function unsubscribeSuccess(state: State, action: UnsubscribePlanSuccess) {
  const latestSubscription = action.payload
  if (isNil(latestSubscription)) {
    return {
      ...state,
      currentPlan: state.plans[premiumFreePlanId],
    }
  }
  const { plan } = latestSubscription
  return {
    ...state,
    currentPlan: plan,
    latestSubscription,
  }
}

function fetchBillingHistorySuccess(
  state: State,
  action: FetchBillingHistorySuccess
) {
  const { billingHistory } = action.payload

  return {
    ...state,
    billingHistory,
  }
}

function fetchPaymentMethodSuccess(
  state: State,
  action: FetchPaymentMethodSuccess
) {
  const { paymentMethod, latestSubscription } = action.payload

  return {
    ...state,
    paymentMethod,
    latestSubscription,
  }
}

function updatePaymenMethodSuccess(
  state: State,
  action: UpdatePaymentMethodSuccess
) {
  const { paymentMethod } = action.payload

  return {
    ...state,
    paymentMethod,
  }
}

function premiumPreviewSuccess(state: State, action: PremiumPreviewSuccess) {
  const { planId, preview } = action.payload

  return {
    ...state,
    preview: {
      ...state.preview,
      [planId]: preview,
    },
  }
}
