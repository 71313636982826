// @flow
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import max from 'lodash/max'
import values from 'lodash/values'
import isNil from 'lodash/isNil'
import moment from 'moment'

import * as selectors from './selectors'
import { generateFeatureFlags } from './flags'
import { formatPrice, formatSize } from 'utils'
import {
  premiumCopyId,
  premiumFreePlanId,
  premiumPlanRanks,
} from 'utils/constants'

import type {
  PremiumPlan,
  CurrentPremiumPlan,
} from '@edison/webmail-core/types/premium'

export function usePlanDescription(
  plan: ?CurrentPremiumPlan,
  expiration?: number,
  pending: ?PremiumPlan
) {
  const { t } = useTranslation()

  if (isNil(plan)) {
    return 'Plan description is unavailable at this time.'
  }

  const features = generateFeatureFlags(plan.features)
  let res = [
    <Trans
      i18nKey={`premium.plans.${premiumCopyId[plan.id]}.description`}
      values={{
        name: plan.name,
        storage: formatSize(features.totalStorageLimit),
        price: formatPrice(plan.unitPrice, plan.currency || 'USD'),
        priceUnit: isNil(plan.interval)
          ? t('premium.plans.priceUnit.long.month')
          : t(`premium.plans.priceUnit.long.${plan.interval}`),
        singleFileSizeLimit: formatSize(features.singleFileSizeLimit),
      }}
    >
      <span className="font-bold" />
    </Trans>,
  ]

  if (!isNil(pending)) {
    const interval = {
      month: t('premium.plans.priceUnit.monthly'),
      year: t('premium.plans.priceUnit.yearly'),
    }
    res.push(' ')
    if (pending.id === premiumFreePlanId) {
      res.push(
        t('premium.plans.unsubscribe', {
          expiration: expiration ? formatDate(expiration) : 'unknown',
        })
      )
    } else {
      res.push(
        <Trans
          i18nKey="premium.plans.pending"
          values={{
            name: pending.name,
            startDate: expiration ? formatDate(expiration) : 'unknown',
            interval: interval[pending.interval],
          }}
        >
          <span className="font-bold" />
        </Trans>
      )
    }
  } else if (plan.id !== premiumFreePlanId) {
    res.push(' ')
    res.push(
      <Trans
        i18nKey="premium.plans.renewal"
        values={{
          expiration: expiration ? formatDate(expiration) : 'unknown',
        }}
      >
        <span className="font-bold" />
      </Trans>
    )
  }

  return res
}

export function useCurrentPlan() {
  const { t } = useTranslation()
  const plan = useSelector(selectors.getCurrentPlan)
  const pending = useSelector(selectors.getLatestSubscriptionPending)
  const maxPlanRank = max(values(premiumPlanRanks))
  const description = usePlanDescription(plan, get(plan, 'expiration'), pending)

  const id = plan ? plan.id : premiumFreePlanId
  const name = plan ? plan.name : t('premium.plans.hobby.name')
  return {
    id,
    name,
    title: t(`premium.plans.title`, { name }),
    description,
    canUpgrade: plan && premiumPlanRanks[plan.id] < maxPlanRank,
  }
}

export function useLatestSubscription() {
  const { t } = useTranslation()
  const plan = useSelector(selectors.getLatestSubscriptionPlan)
  const pending = useSelector(selectors.getLatestSubscriptionPending)
  const description = usePlanDescription(plan, get(plan, 'expiration'), pending)

  if (plan) {
    const name = plan.name
    return {
      name,
      title: t(`premium.plans.title`, { name }),
      description,
    }
  } else {
    return null
  }
}

const formatDate = timestamp => moment.unix(timestamp).format('MMMM D, YYYY')
