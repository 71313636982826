// @flow
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Tabs from '@edison/webmail-ui/components/Tabs'

import type { Node } from 'react'

/**
 * Connect react-router to Material UI tabs.
 *
 * @public
 */
const RouteTabs = ({ children, ...rest }: { children: Node }) => {
  const history = useHistory()
  const location = useLocation()

  return (
    <Tabs
      value={location.pathname}
      onChange={(event, value) => {
        history.push(value)
      }}
      {...rest}
    >
      {children}
    </Tabs>
  )
}

export default RouteTabs
