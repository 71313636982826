// @flow
import React from 'react'

import { useScheduledBreak } from 'core/inbox-break/hooks'

import { RemoveBreakConfirmation } from '@edison/webmail-ui/components/InboxBreak'

type Props = {
  id: string,
  isOpen: boolean,
  toggle: () => void,
}
const View = ({ id, isOpen, toggle }: Props) => {
  const schedule = useScheduledBreak(id)

  function handleOnConfirm() {
    schedule.onRemove().then(success => {
      // Close the modal on success
      if (success) {
        toggle()
      }
    })
  }

  return (
    <RemoveBreakConfirmation
      open={isOpen}
      onClose={toggle}
      onConfirm={handleOnConfirm}
      isLoading={schedule.isRemoving}
    />
  )
}

View.displayName = 'BreakRemoveConfirmModal'

export default View
