// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getLoadingStatus } from '../loading/selectors'
import { getFromListSelector } from 'core/compose/selectors'
import { getAuth } from 'core/auth/selectors'

import { fields } from '@edison/webmail-ui/screens/Settings/fields'
import { quickActionSettingMappings, autoSelectEmail } from 'utils/constants'
import { endpoints as settingsEndpoints } from '@edison/webmail-core/api/settings'

import type { State as SettingsState } from './reducers'
import type { State, Selector } from 'types/state'
import type {
  Settings,
  Account,
  Profile,
  DataExportState,
} from '@edison/webmail-core/types/settings'
import type { QuickActionType } from '@edison/webmail-ui/components/QuickAction'
import type { Mfa } from '@edison/webmail-core/types/mfa'

export const selectSettingsState = (state: State): SettingsState =>
  state.settings

const _getSettings: Selector<Settings> = createSelector(
  selectSettingsState,
  (settings: SettingsState) => settings.base
)
export function getSettings(): Selector<Settings> {
  return _getSettings
}

const _getAccountInSettings: Selector<Account> = createSelector(
  getSettings(),
  settings => get(settings, 'account', {})
)
export function getAccountInSettings(): Selector<Account> {
  return _getAccountInSettings
}

const _getProfileInSettings: Selector<Profile> = createSelector(
  getAccountInSettings(),
  (account: Account) => get(account, 'profile', {})
)
export function getProfileInSettings() {
  return _getProfileInSettings
}

export const getSenderNameSelector = createSelector(
  _getProfileInSettings,
  profile => get(profile, 'senderName', '')
)

export const getSenderEmailSelector = createSelector(_getSettings, settings =>
  get(settings, 'account.profile.email', '')
)

export const isAutoSelectEmail = createSelector(
  getSenderEmailSelector,
  email => email === autoSelectEmail
)

export const getDataExportState: Selector<DataExportState> = createSelector(
  _getSettings,
  settings => get(settings, 'account.dataExportState', 'none')
)

export const getDefaultSenderEmailSelector = createSelector(
  getSenderEmailSelector,
  getFromListSelector,
  getAuth(),
  (email, fromList, auth) => {
    if (email && [...fromList, autoSelectEmail].includes(email)) return email
    return auth.user
  }
)

export function isSettingsLoading() {
  return getLoadingStatus('SETTINGS')
}

export function isSettingsUpdating() {
  return getLoadingStatus('SETTINGS_UPDATE')
}

export const getIsPatchSettingsLoading: Selector<boolean> = getLoadingStatus(
  'SETTINGS_PATCH'
)

export function isSettingsLoaded(): Selector<boolean> {
  return createSelector(getSettings(), settings => !!settings.general)
}
export function isSettingsAvatarUploading() {
  return getLoadingStatus('SETTINGS_UPLOAD_AVATAR')
}

export function isSignatureEnable(): Selector<boolean> {
  return createSelector(getSettings(), settings =>
    get(settings, ['signatureExt', 'enableSignature'], false)
  )
}

export function getSignatureContent(): Selector<string> {
  return createSelector(getSettings(), settings =>
    get(settings, ['signature', 'content'], '')
  )
}
const INC_VIA_ONMIAL = `<div style="color: rgb(153,153,153)">Sent via <a href="https://www.onmail.com?referrer=signature" target="_blank">OnMail</a></div>`
export function getAccountSignatureContent(account): Selector<string> {
  return createSelector(getSettings(), getAuth(), (settings, auth) => {
    const signatureExt = get(settings, 'signatureExt')
    if (!signatureExt) return ''
    const { signatures, customizePerAccount } = signatureExt

    const email = auth.user

    const signatureItem = signatures.find(item => {
      if (customizePerAccount) {
        return (
          item.account === account ||
          (account === email ? !item.account : false)
        )
      } else {
        return item.account === email || !item.account
      }
    })
    if (!signatureItem) {
      return INC_VIA_ONMIAL
    }
    const { content, incViaOnMail } = signatureItem
    return incViaOnMail ? [content, INC_VIA_ONMIAL].join('<br>') : content
  })
}

export function getAllSignatureExtensions(): Selector<string> {
  return createSelector(getSettings(), settings => {
    const signatureExt = get(settings, 'signatureExt')
    if (!signatureExt) return ''
    const { signatures } = signatureExt
    return signatures.reduce((prev, { content }) => prev + content, '')
  })
}

export function getVacationResponderContent(): Selector<string> {
  return createSelector(getSettings(), settings =>
    get(settings, ['vacationResponder', 'content'], '')
  )
}

export function getUndoDuration(): Selector<number> {
  return createSelector(getSettings(), settings => {
    const duration = get(settings, ['general', 'display', 'undoDuration'], 5000)
    return duration < 5000 ? 5000 : duration
  })
}

export const isEnableDisplayExternalImagesSelector = createSelector(
  getSettings(),
  settings => {
    return get(
      settings,
      ['general', 'display', 'enableDisplayExternalImages'],
      true
    )
  }
)
export function isSmartReplyEnable(): Selector<boolean> {
  return createSelector(getSettings(), settings =>
    get(settings, ['general', 'display', 'enableSmartReply'], false)
  )
}

export const getForwardEmails: Selector<
  $PropertyType<SettingsState, 'forwardEmails'>
> = createSelector(selectSettingsState, settingsState =>
  settingsState.forwardEmails.sort((a, b) => {
    if (a.verified === b.verified) {
      return a.email < b.email ? -1 : 1
    } else {
      return a.verified ? -1 : 1
    }
  })
)

export const getVerifiedForwardEmails: Selector<
  $PropertyType<SettingsState, 'forwardEmails'>
> = createSelector(selectSettingsState, settingsState =>
  settingsState.forwardEmails
    .filter(email => email.verified)
    .sort((a, b) => (a.email < b.email ? -1 : 1))
)

export const isAddForwardEmailLoading: Selector<boolean> = getLoadingStatus(
  'ADD_FORWARD_EMAIL'
)

export const isVerifyForwardEmailLoading: Selector<boolean> = getLoadingStatus(
  'VERIFY_FORWARD_EMAIL',
  true
)

export const isRecoveryEmailLoading: Selector<boolean> = createSelector(
  getLoadingStatus('UPDATE_RECOVERY_EMAIL'),
  getLoadingStatus('RESEND_RECOVERY_VERIFY_EMAIL'),
  (isUpdating, isResending) => isUpdating || isResending
)

export const getMfaSelector: Selector<Mfa> = createSelector(
  selectSettingsState,
  state => state.mfa
)

export const isInboxZeroBackgroundUploading: Selector<boolean> = getLoadingStatus(
  'UPLOAD_INBOX_ZERO_BACKGROUND'
)

export const isInboxZeroBackgroundDeleting: Selector<boolean> = getLoadingStatus(
  'DELETE_INBOX_ZERO_BACKGROUND'
)

export const isInboxZeroQuoteEnable: Selector<boolean> = createSelector(
  getSettings(),
  (state: Settings) => {
    return get(state, fields.general.display.enableEmptyInboxDailyQuote, true)
  }
)

export const getInboxZeroBackgroundSettings: Selector<{
  enable: boolean,
  name: string,
  image: {
    full: string,
    thumbnail: string,
  },
}> = createSelector(getSettings(), (state: Settings) => {
  const enable = get(state, fields.general.display.backgroundImageMode, true)
  const image = get(state, fields.general.display.backgroundImageFilename, '')

  let src = null
  if (image) {
    const { url } = settingsEndpoints.getInboxZeroBackground
    src = new URL(window.location)
    src.pathname = url(image)
  }

  const [full, thumbnail] = [undefined, 'medium'].map(size => {
    if (!src) return ''

    const PARAM_NAME = 'imgsize'

    if (size) {
      src.searchParams.set(PARAM_NAME, size)
      return src.toString()
    } else {
      src.searchParams.delete(PARAM_NAME)
      return src.toString()
    }
  })

  return {
    image: {
      full,
      thumbnail,
    },
    name: image,
    enable,
  }
})

export const isSenderCardsDisable: Selector<boolean> = createSelector(
  getSettings(),
  (state: Settings) => {
    return get(state, fields.general.autoAcceptNewContacts, false)
  }
)

export const getQuickActionSetting: Selector<QuickActionType> = createSelector(
  getSettings(),
  (state: Settings) => {
    const value = get(state, fields.general.quickAction, 0)
    return quickActionSettingMappings[value.toString()]
  }
)
