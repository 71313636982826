// @flow

/*
 * Common hooks used for reading data across all core modules
 */

import { useSelector } from 'react-redux'
import * as labelsHooks from 'core/labels/hooks'

import * as threadsSelectors from 'core/threads/selectors'
import * as cleanupSelectors from 'core/cleanup/selectors'
import * as contactsSelectors from 'core/contacts/selectors'
import * as emailNudgesSelectors from 'core/email-nudges/selectors'
import { getLabelUnreadConfig } from '../utils'
import { getLabelUnreadCount } from './labels/selectors'
import * as settingsSelectors from 'core/settings/selectors'

const isInboxZeroSelector = threadsSelectors.isInboxZero()
const hasPendingContactsSelector = contactsSelectors.hasPendingContacts()

const threadUnreadSelector = getLabelUnreadCount()

export const useShowUnreadIcon = () => {
  const labelThreadUnread = useSelector(threadUnreadSelector)

  const label = labelsHooks.useRouteLabel()

  let count = 0
  if (!!label && label in labelThreadUnread) {
    const { display, displayTotal } = getLabelUnreadConfig(label)
    // Don't display the folder with total
    if (display && !displayTotal) {
      count = labelThreadUnread[label] || 0
    }
  }

  return count > 0
}
export const useCardListCondition = () => {
  const hasPendingContacts = useSelector(hasPendingContactsSelector)
  const hasCleanup = useSelector(cleanupSelectors.hasCleanupSelector)
  const hasEmailNudge = useSelector(emailNudgesSelectors.hasEmailNudge)
  const hasSuggestedBlock = useSelector(contactsSelectors.hasSuggestedBlock)

  const hasCard =
    hasPendingContacts || hasEmailNudge || hasSuggestedBlock || hasCleanup

  return {
    hasCard,
    hasPendingContacts,
    hasEmailNudge,
    hasCleanup,
    hasSuggestedBlock,
  }
}

export const useInboxZeroConditon = () => {
  const { hasCard } = useCardListCondition()
  const routeLabel = labelsHooks.useRouteLabel()

  const isInboxZero = useSelector(isInboxZeroSelector)
  const { enable } = useSelector(
    settingsSelectors.getInboxZeroBackgroundSettings
  )

  return enable && !!routeLabel && isInboxZero && !hasCard
}
