// @flow
import * as yup from 'yup'
import moment from 'moment'
import get from 'lodash/get'
import has from 'lodash/has'
import mapValues from 'lodash/mapValues'
import { createSelector } from 'reselect'

import { getThreadsById } from '../threads/selectors'
import { siftsParser } from 'core/sifts/parsers'
import { getLoadingStatus } from 'core/loading/selectors'

import type { Selector } from 'types/state'
import type { State } from 'types/state'
import type { State as SiftsState } from './reducers'

const getSiftsState = (state: State) => state.sifts

export const getSiftsByThreadId: Selector<{
  [threadId: string]: $ReadOnlyArray<{
    domain: string,
    payload: Object,
  }>,
}> = createSelector(getThreadsById, byId => {
  return mapValues(byId, thread =>
    siftsParser(
      get(thread, 'messages', []).flatMap(
        message => get(message, 'extendInfo.sift', []) || []
      )
    )
  )
})

const _isLoadingVendor: Selector<boolean> = getLoadingStatus('SIFTS_GET_VENDOR')
export function isLoading() {
  return _isLoadingVendor
}

const _getVendorsById: Selector<
  $PropertyType<SiftsState, 'vendors'>
> = createSelector(getSiftsState, state => state.vendors)
export function getVendorsById() {
  return _getVendorsById
}

export const fields = {
  flight: (payload: Object) => {
    return {
      title: get(payload, 'title'),
      departure: {
        location: get(payload, 'departureCity'),
        code: get(payload, 'departureCode', '--'),
        time: moment(get(payload, 'startTime')).format('hh:mma'),
      },
      arrival: {
        location: get(payload, 'arrivalCity'),
        code: get(payload, 'arrivalCode', '--'),
        time: moment(get(payload, 'endTime')).format('hh:mma'),
      },
      flightNumber: get(payload, 'flightNumber'),
      date: get(payload, 'subtitle'),
      reservationCode: get(payload, 'reservationNumber'),
      phoneNumber: undefined,
      vendorId: get(payload, 'vendor'),
    }
  },
  hotel: (payload: Object) => {
    return {
      title: get(payload, 'title'),
      checkin: {
        date: moment(get(payload, 'startTime')).format('MMM DD'),
        day: moment(get(payload, 'startTime')).format('dddd'),
      },
      checkout: {
        date: moment(get(payload, 'endTime')).format('MMM DD'),
        day: moment(get(payload, 'endTime')).format('dddd'),
      },
      vendor: get(payload, 'provider'),
      reservationCode: get(payload, 'reservationNumber'),
      phoneNumber: get(payload, 'telephone'),
      address: get(payload, 'address'),
      vendorId: get(payload, 'vendor'),
    }
  },
  rentalcar: (payload: Object) => {
    const vendorId = get(payload, 'vendor')
    const destination = get(payload, 'destination')

    return {
      title: get(payload, 'title'),
      pickupTime: get(payload, 'pickupTime'),
      dropoffTime: get(payload, 'dropoffTime'),
      provider: get(payload, 'provider'),
      reservationNumber: get(payload, 'reservationNumber'),
      phoneNumber: get(payload, 'phoneNumber'),
      destination,
      vendorId,
    }
  },
  train: (payload: Object) => {
    return {
      title: get(payload, 'title'),
      departure: {
        location: get(payload, 'departureCity'),
        code: get(payload, 'departureCity', '').slice(0, 3),
        time: moment(get(payload, 'startTime')).format('h:mma'),
      },
      arrival: {
        location: get(payload, 'arrivalCity'),
        code: get(payload, 'arrivalCity', '').slice(0, 3),
        time: moment(get(payload, 'endTime')).format('h:mma'),
      },
      reservationCode: get(payload, 'reservationNumber'),
      vendor: get(payload, 'provider'),
      date: moment(get(payload, 'startTime')).format('dddd, MMM D'),
      vendorId: get(payload, 'vendor'),
    }
  },
  boardingpass: (payload: Object) => {},
  shipment: (payload: Object) => {
    return {
      title: get(payload, 'broker'),
      provider: get(payload, 'shipperName'),
      items: get(payload, 'items', []),
      // Hide the "Date" field in package sift card
      // Refer to: https://easilydo.atlassian.net/browse/EWM-3230
      // shipDate: get(payload, 'endTime', 'Unknown'),
      trackingNumber: get(payload, 'trackingNumber'),
      onClick: createOpenTabHandler(get(payload, 'trackingUrl')),
      vendorId: get(payload, 'vendor'),
    }
  },
  purchase: (payload: Object) => {
    const date = get(payload, 'date')

    return {
      title: get(payload, 'title'),
      date: (typeof date === 'number'
        ? moment.unix(date)
        : moment(date)
      ).format('MMM D, YYYY'),
      subtotal: get(payload, 'price'),
      trackingNumber: get(payload, 'orderNumber'),
      tax: get(payload, 'tax'),
      total: get(payload, 'total'),
      items: get(payload, 'items', []),
      vendorId: get(payload, 'vendor'),
    }
  },
  restaurant: (payload: Object) => {
    const startTime = get(payload, 'startTime')
    const date = startTime ? formatMultilineDate(startTime) : undefined

    return {
      title: get(payload, 'title'),
      reservationCode: get(payload, 'reservationCode'),
      date,
      address: get(payload, 'address'),
      phoneNumber: get(payload, 'phoneNumber'),
      reservationName: has(payload, 'partySize')
        ? `Reservation for ${get(payload, 'partySize')}`
        : '',
      vendorId: get(payload, 'vendor'),
    }
  },
  event: (payload: Object) => {
    const startTime = get(payload, 'startTime')
    const date = startTime ? formatMultilineDate(startTime) : undefined
    return {
      title: get(payload, 'title'),
      date,
      address: get(payload, 'locationName'),
      phoneNumber: get(payload, 'phoneNumber'),
      onClick: createOpenTabHandler(get(payload, 'ticketUrl')),
      vendorId: get(payload, 'vendor'),
    }
  },
  bill: (payload: Object) => {
    return {
      title: get(payload, 'title'),
      date: moment(get(payload, 'dueDate')).format('MMM D, YYYY'),
      // TODO: This is an array?
      price: get(payload, 'price'),
      items: get(payload, 'items', []),
      onClick: createOpenTabHandler(get(payload, 'paymentUrl')),
      vendorId: get(payload, 'vendor'),
    }
  },
  cruise: (payload: Object) => {
    return {
      title: get(payload, 'title'),
      departure: {
        date: moment(get(payload, 'startTime')).format('MMM D'),
        time: moment(get(payload, 'startTime')).format('h:mm a'),
      },
      vendor: get(payload, 'vendorName'),
      reservationCode: get(payload, 'reservationNumber'),
      phoneNumber: get(payload, 'phone'),
      address: get(payload, 'address'),
      vendorId: get(payload, 'vendor'),
    }
  },
}

function formatMultilineDate(
  date,
  { fmt1 = 'dddd, MMM D', fmt2 = 'h:mma' } = {}
) {
  return [moment(date).format(fmt1), moment(date).format(fmt2)]
}

function createOpenTabHandler(url) {
  let isValid = yup
    .string()
    .url()
    .isValidSync(url)

  // https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
  //
  // Yup.string().url() should cover most cases
  // Here is to cover the corner cases
  if (!isValid) {
    try {
      new URL(url)
      isValid = true
    } catch (e) {}
  }

  let onClick
  if (url && isValid) {
    onClick = () => {
      window.open(url, '_blank')
    }
  }

  return onClick
}
