import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { usePreviewModal } from 'core/previews/hooks'
import { getAttachmentPreview } from 'core/previews/selectors'
import ComposeAttachmentItem from '@edison/webmail-ui/components/ComposeAttachmentItem'

import { useLazyLoadImage } from '@edison/webmail-ui/hooks/lazyImage'
import AttachmentTabItem from '@edison/webmail-ui/components/AttachmentTabList/Item'
import { imagePreviewSizeOptions } from 'utils/constants'
import { isEnableThumbnailAttachment } from 'utils'
import { fetchAttachmentPreviewLink } from '../../core/previews/actions'

const useLazyloadSrc = (
  rootRef,
  attachment,
  size = imagePreviewSizeOptions.small
) => {
  const [loading, setLoading] = useState(false)
  const { id, message } = attachment
  const src = useSelector(
    useMemo(() => getAttachmentPreview(`${message}_${id}`, size), [
      message,
      id,
      size,
    ])
  )

  const dispatch = useDispatch()
  useLazyLoadImage(rootRef, () => {
    if (isEnableThumbnailAttachment(attachment) && !src) {
      setLoading(true)
      dispatch(fetchAttachmentPreviewLink(attachment, size))
    }
  })

  return { loading: loading, src }
}

export const AttachmentImage = props => {
  const {
    id,
    url,
    preview,
    fileName,
    size = imagePreviewSizeOptions.small,
    ...otherProps
  } = props
  const rootRef = useRef()
  const { src } = useLazyloadSrc(rootRef, props, size)
  const { t } = useTranslation()

  return (
    <img
      {...otherProps}
      ref={rootRef}
      src={src || t('placeholder-photo.png')}
      alt={props.fileName}
    />
  )
}

export default ({ attachments, index, ...rest }) => {
  const attachment = attachments[index]
  const rootRef = useRef()
  const { src } = useLazyloadSrc(rootRef, attachment)
  const previews = usePreviewModal()

  const { name, contentType, size } = attachment
  return (
    <ComposeAttachmentItem
      {...rest}
      name={name}
      contentType={contentType}
      size={size}
      src={src}
      ref={rootRef}
      onClick={() => previews.showModal({ attachments, index })}
    />
  )
}

export const AttachmentTabListItem = ({ attachments, index, ...props }) => {
  const attachment = attachments[index]
  const rootRef = useRef()
  const { src, loading } = useLazyloadSrc(
    rootRef,
    attachment,
    imagePreviewSizeOptions.medium
  )
  const previews = usePreviewModal()

  return (
    <AttachmentTabItem
      {...props}
      loading={loading}
      attachment={attachment}
      src={src}
      ref={rootRef}
      onClick={() => previews.showModal({ attachments, index })}
    />
  )
}
