import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHiddenThreadDetail } from 'hooks/useHandleDetailModal'
import { useTranslation } from 'react-i18next'
import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import {
  useIsCurrentViewInInbox,
  useThreadActions,
  useThreadDetailActionFlags,
} from 'core/threads/hooks'
import { isEnableThreadAction } from 'core/messages/selectors'
import { getThreadsById } from 'core/threads/selectors'
import { useIsInSearch } from 'core/threads/hooks'
import MoreAction from './MoreAction'
import { printThread } from 'core/messages/actions'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@edison/webmail-ui/components/Tooltip'
import { ThreadLabelPicker } from 'common/Labels/Picker'
import { modalTypes } from '../../../utils/constants'
import { useModal } from '../../../common/modals'

const Action = ({ threadId }) => {
  const threadsById = useSelector(getThreadsById)
  const thread = threadsById[threadId]
  const dispatch = useDispatch()
  const order = useMemo(() => {
    return [
      actionNodes.trash.name,
      actionNodes.untrash.name,
      actionNodes.markAsSpam.name,
      actionNodes.deleteForever.name,
    ]
  })
  const flags = useThreadDetailActionFlags()(thread)
  const threadActions = useThreadActions({ debounced: false })(thread)
  const enabled = useSelector(
    useMemo(() => isEnableThreadAction(threadId), [threadId])
  )
  const hiddenThreadDetail = useHiddenThreadDetail('replace')
  const moveToInboxModal = useModal(modalTypes.moveToSplitInbox)
  const onOpenMoveToSplitModal = useCallback(() => {
    moveToInboxModal.showModal({
      selectedThreadIds: [threadId],
      isBatchAction: false,
    })
  }, [moveToInboxModal, threadId])
  const isInInbox = useIsCurrentViewInInbox()
  const isInSearch = useIsInSearch()
  const actions = useMemo(() => {
    const ret = [
      ...order
        .filter(action => flags[action])
        .map(action => ({
          ...threadActions[action],
          onClick: () => {
            threadActions[action].onClick()
            hiddenThreadDetail()
          },
        })),
    ]
    if (isInInbox || isInSearch) {
      ret.push({
        title: actionNodes.moveToSplit.title,
        onClick: onOpenMoveToSplitModal,
      })
    }
    ret.push({
      title: actionNodes.addLabel.title,
      Wrapper: ({ children }) => (
        <ThreadLabelPicker
          trigger={children}
          threadId={threadId}
          popoverProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          }}
        />
      ),
    })
    ret.push({
      title: 'Print All',
      name: 'print',
      onClick: () => dispatch(printThread(threadId)),
    })
    return ret
  }, [
    dispatch,
    order,
    threadId,
    isInInbox,
    onOpenMoveToSplitModal,
    flags,
    hiddenThreadDetail,
    threadActions,
  ])

  return <MoreAction menus={actions} disabled={!enabled} />
}

export default ({ threadId }) => {
  const threadsById = useSelector(getThreadsById)
  const thread = threadsById[threadId]
  const { t } = useTranslation()
  return !!thread ? (
    <Action threadId={threadId} />
  ) : (
    <Tooltip title={t('button.more')}>
      <IconButton
        disableRipple
        disableFocusRipple
        size="small"
        aria-controls="more-menu"
        disabled
      >
        <MoreVertIcon />
      </IconButton>
    </Tooltip>
  )
}
