// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Recommendations, {
  NoResults,
  Recommendation,
  RecommendationContainer,
} from '@edison/webmail-ui/components/Search/Recommendations'
import cn from 'classnames'
import * as analytics from 'core/analytics/actions'
import { getPreviewProps } from 'core/large-attachments/helpers'
import { usePreviewModal } from 'core/previews/hooks'
import { fetchSearchRecommendations } from 'core/search/actions'
import {
  useIsInbox,
  useIsExistQuery,
  MAX_RECOMEND_SEARCH_COUNT,
} from 'core/search/hooks'
import { getSearchRecommendations } from 'core/search/selectors'
import type { Dispatch } from 'types/redux'
import { attachmentsType, imagePreviewSizeOptions } from 'utils/constants'
const DEFAULT_HEIGHT = 300
const OBSERVATION_SECOND = 1000
const FREQUENCY = 50
const OBSERVATIONS_COUNT = OBSERVATION_SECOND / FREQUENCY

const View = (props: Props) => {
  const { threads, isLoading } = props
  const isInbox = useIsInbox()
  const previews = usePreviewModal()
  const dispatch: Dispatch = useDispatch()
  const [top, setTop] = useState(DEFAULT_HEIGHT)
  const heightChangeCountRef = useRef({
    previousHeight: DEFAULT_HEIGHT,
    count: 0,
  })
  const { files, images } = useSelector(getSearchRecommendations())
  const recentViewAttachments = [...files, ...images]
    .sort((a, b) => (a.date < b.date ? 1 : -1))
    .slice(0, 10)
  const isExistQuery = useIsExistQuery()
  const isEmpty = !threads.length
  const isShowAttachments = !isExistQuery && isInbox
  const isEmptyNode = useMemo(() => {
    if (!isExistQuery && isInbox) {
      return null
    }
    if (!isInbox) {
      return <NoResults />
    }
    return isEmpty && !isLoading ? <NoResults /> : <React.Fragment />
  }, [isEmpty, isExistQuery, isInbox, isLoading])

  useEffect(() => {
    dispatch(fetchSearchRecommendations())
  }, [])

  useEffect(() => {
    if (threads.length && !isLoading) {
      let timer = null
      const onHeightChange = () => {
        // INFO: Not good mehthod. Due to it hard to know threadList When can figure out its accurate height
        // So only to use setInterval, MutationObserver API also can't get accurate height
        const threadListNode = document.querySelector('#searchThreadList')
        const virtualizeScrolldNode =
          threadListNode && threadListNode.firstChild
        const height =
          virtualizeScrolldNode && virtualizeScrolldNode.clientHeight
        if (height && heightChangeCountRef.current.previousHeight !== height) {
          heightChangeCountRef.current.previousHeight = height
          heightChangeCountRef.current.count = 0
          setTop(height)
        } else {
          heightChangeCountRef.current.count++
        }
        if (heightChangeCountRef.current.count >= OBSERVATIONS_COUNT) {
          clearInterval(timer)
        }
      }
      onHeightChange()
      timer = setInterval(onHeightChange, FREQUENCY)
      return () => clearInterval(timer)
    }
  }, [threads])

  if (isLoading) {
    return null
  }
  if (threads.length && !isInbox) {
    return null
  }
  if (isInbox && isExistQuery && !isEmpty) {
    return null
  }
  if (threads.length > MAX_RECOMEND_SEARCH_COUNT) {
    return null
  }

  return (
    <div className={cn('mt-4 relative')} style={!isEmpty ? { top } : {}}>
      <Recommendations>
        {isEmptyNode}
        {isShowAttachments ? (
          <RecommendationContainer>
            {recentViewAttachments.map((file, index) => {
              const { image: thumbnail } = getPreviewProps(
                dispatch,
                file,
                imagePreviewSizeOptions.medium
              )
              const onClick = () => {
                if (file.attachmentsType === attachmentsType.file) {
                  dispatch(analytics.search.userSearchRecommendation('file'))
                  previews.showModal({
                    attachments: recentViewAttachments,
                    index,
                  })
                } else {
                  dispatch(analytics.search.userSearchRecommendation('image'))
                  previews.showModal({
                    attachments: recentViewAttachments,
                    index,
                  })
                }
              }
              return (
                <Recommendation
                  thumbnail={thumbnail}
                  index={index}
                  file={file}
                  onClick={onClick}
                />
              )
            })}
          </RecommendationContainer>
        ) : null}
      </Recommendations>
    </div>
  )
}

View.displayName = 'SearchRecommendations'
export default View
