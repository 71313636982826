import md5 from 'md5'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

import PasswordForm from '@edison/webmail-ui/components/AttachmentPasswordForm'
import { useDispatch } from 'react-redux'
import * as client from '@edison/webmail-core/api'
import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'
import { getMetaDataFromAttachmentUrl } from 'utils'
import { usePreviewModal } from 'core/previews/hooks'

export default function AttachmentPasswordModal({
  isOpen,
  toggle,
  attachment,
}) {
  const dispatch = useDispatch()
  const previews = usePreviewModal()
  const { t } = useTranslation()
  const { messageHeaderId: mid, aid, url } = attachment

  return (
    <Dialog fullWidth open={isOpen} onClose={toggle}>
      <DialogTitle>
        {t('settings.attachmentLinks.passwordProtection')}
      </DialogTitle>
      <DialogContent>
        <div className="text-gray-medium text-sm">
          {t('settings.attachmentLinks.passwordProtection.message')}
        </div>
      </DialogContent>

      <PasswordForm
        defaultPassword={''}
        onChangePassword={async password => {
          const { email, uid } = getMetaDataFromAttachmentUrl(url)

          try {
            previews.largeAttachment.show(attachment, mid, {
              email,
              uid,
              password,
              onDownload: () =>
                window.open(
                  client.largeAttachments.download({
                    email,
                    uid,
                    mid,
                    aid,
                    password: password ? md5(password) : '',
                    action: 'download',
                  }),
                  '_blank'
                ),
            })
            toggle()
          } catch (e) {
            dispatch(showNotification(e.message, toastVariants.error))
          }
        }}
        onCancel={toggle}
      />
    </Dialog>
  )
}
