// @flow
import { GalleryBody } from '@edison/webmail-ui/components/ContactsGallery/GalleryBody'
import {
  GalleryBodySkeleton,
  GallerySubjectSkeleton,
} from '@edison/webmail-ui/components/Skeletons/GalleryBody'
import { useModal } from 'common/modals'
import * as contactsActions from 'core/contacts/actions'
import { getFormattedContact } from 'core/contacts/helpers'
import { useNextCardContact } from 'core/contacts/hooks'
import * as contactsSelectors from 'core/contacts/selectors'
import { fetchThread } from 'core/messages/actions'
import { getMessageState as makeMessageStateSelector } from 'core/messages/selectors'
import { useKeyboardNavigation } from 'hooks/useKeyboardNavigation'
import curry from 'lodash/curry'
import findIndex from 'lodash/findIndex'
import first from 'lodash/first'
import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isStringEqual } from 'utils'
import { cardTypes, modalTypes } from 'utils/constants'
import Actions from './Actions'
import { EmptyBody, GallerySubject, MessageItem } from './Body'
import { galleryViewMap } from './constant'

import type { Contact } from '@edison/webmail-core/types/contacts'
import type { SplitInbox } from '@edison/webmail-core/types/split-inboxes'
import type { GalleryView } from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import type { Dispatch } from 'types/redux'

const getPendingThreads = contactsSelectors.getPendingThreads()
const getMessageState = makeMessageStateSelector()
const getContactStatusUpdating = contactsSelectors.isContactStatusUpdating()
const getContactPendingThreadsLoading = contactsSelectors.isContactPendingThreadsLoading()

type Props = {
  selectedContactId?: string,
  contacts: $ReadOnlyArray<Contact>,
  onAccept: (
    id: string,
    options?: {
      isBlockSender?: boolean,
      isNeedUpdateSelected?: boolean,
      isClearSelected?: boolean,
    }
  ) => void,
  onBlock: (
    id: string,
    options?: {
      isBlockSender?: boolean,
      isNeedUpdateSelected?: boolean,
      isClearSelected?: boolean,
    }
  ) => void,
  total: number,
  onMoveToSplit: (split: SplitInbox, id: string) => void,
  onNextNavigate: (view: GalleryView, currentCard: any) => void,
}

export default ({
  selectedContactId,
  contacts,
  onAccept,
  onBlock,
  onMoveToSplit,
  onNextNavigate,
  total,
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const approveSenderModal = useModal(modalTypes.approveSender)
  const contactGalleryModal = useModal(modalTypes.contactsGallery)
  const { hasNextCard, nextGalleryView, nextCardData } = useNextCardContact(
    cardTypes.senders
  )
  const [currIdx, setCurrIdx] = useState(() => {
    if (!selectedContactId) {
      return 0
    }
    const idx = findIndex(contacts, ({ id }) => id === selectedContactId)

    return idx === -1 ? 0 : idx
  })
  const [loading, setLoading] = useState({ thread: false, message: false })
  const isContactStatusUpdating = useSelector(getContactStatusUpdating)
  const isContactPendingThreadsLoading = useSelector(
    getContactPendingThreadsLoading
  )
  const currContact = contacts[currIdx]
  const { email } = getFormattedContact(currContact)

  const messageState = useSelector(getMessageState)
  const pendingThreadsList = useSelector(getPendingThreads)
  const pendingThreads = get(pendingThreadsList, email, [])

  const { thread, message } = useMemo(() => {
    let thread, message

    thread = first(pendingThreads.sort((a, b) => b - a))

    if (!thread) {
      return { thread, message }
    }

    message = first(
      thread.messageIds
        .map(id => messageState[id])
        .filter(Boolean)
        .filter(item => {
          const { from } = item

          return isStringEqual(email, from.email)
        })
        .sort((a, b) => b - a)
    )

    return { thread, message }
  }, [email, messageState, pendingThreadsList])

  useEffect(() => {
    if (contacts.length - 1 < currIdx) {
      setCurrIdx(contacts.length - 1)
    }
  }, [contacts])

  useEffect(() => {
    if (email) {
      if (!thread && !loading.thread) {
        setLoading(state => ({ ...state, thread: true }))
        dispatch(contactsActions.fetchPendingThreads(email)).then(() => {
          setLoading(state => ({ ...state, thread: false }))
        })
      }

      if (
        thread &&
        (!message || (!message.text && !message.html)) &&
        !loading.message
      ) {
        setLoading(state => ({ ...state, message: true }))
        dispatch(fetchThread(thread.id)).then(() => {
          setLoading(state => ({ ...state, message: false }))
        })
      }
    }
  }, [email, thread, message])

  useKeyboardNavigation(
    {
      onPrevious: () => handleOnNavigate(currIdx - 1),
      onNext: () => handleOnNavigate(currIdx + 1),
    },
    [currIdx]
  )

  const handleOnNavigate = (nextIdx: number) => {
    if (nextIdx > contacts.length - 1 && hasNextCard) {
      onNextNavigate(nextGalleryView, {
        [galleryViewMap[nextGalleryView]]: nextCardData,
      })
      return
    }
    const idx = Math.max(0, Math.min(nextIdx, contacts.length))
    setCurrIdx(idx)
  }

  const isNeedGoNextCard = (nextIdx: number) => {
    if (nextIdx > contacts.length - 1 && hasNextCard) {
      onNextNavigate(nextGalleryView, {
        [galleryViewMap[nextGalleryView]]: nextCardData,
      })
    }
  }

  const handleOnContactAction = (
    action: (
      id: string,
      options?: {
        isBlockSender?: boolean,
        isNeedUpdateSelected?: boolean,
        isClearSelected?: boolean,
      }
    ) => void,
    options
  ) => {
    const contact = contacts[currIdx]

    if (contact) {
      action(contact.id, options)
    }
  }

  const handleOnClickDetail = () => {
    const contactId = currContact.id
    approveSenderModal.showModal({
      email,
      id: contactId,
      onClose: () => {
        contactGalleryModal.showModal({ contactId })
      },
    })
    contactGalleryModal.hideModal()
  }

  return (
    <GalleryBody
      isLoading={isContactPendingThreadsLoading}
      isNext={currIdx !== contacts.length - 1 || !!hasNextCard}
      currIdx={currIdx}
      total={total}
      onNext={() => handleOnNavigate(currIdx + 1)}
      onPrevious={() => handleOnNavigate(currIdx - 1)}
      action={
        <Actions
          disabled={
            isContactStatusUpdating || loading.thread || loading.message
          }
          onAccept={() => {
            handleOnContactAction(onAccept)
            isNeedGoNextCard(currIdx + 1)
          }}
          onBlock={isBlockSender => {
            handleOnContactAction(onBlock, { isBlockSender })
            isNeedGoNextCard(currIdx + 1)
          }}
          onMoveToSplit={split => {
            handleOnContactAction(curry(onMoveToSplit)(split))
            isNeedGoNextCard(currIdx + 1)
          }}
        />
      }
    >
      {isContactPendingThreadsLoading && <GallerySubjectSkeleton />}
      {thread && !isContactPendingThreadsLoading && (
        <>
          <GallerySubject
            subject={thread.subject}
            onClick={handleOnClickDetail}
            threadsCount={pendingThreads.length}
          />
          {message && <MessageItem message={message} viewport="mobile" />}
        </>
      )}
      {!thread && !isContactPendingThreadsLoading && !loading.thread && (
        <EmptyBody />
      )}
      {isContactPendingThreadsLoading && <GalleryBodySkeleton />}
    </GalleryBody>
  )
}
