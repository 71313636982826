// @flow
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import { combineReducers } from 'redux'
import { createReducer } from 'utils/redux'
import {
  fetchSettingsActions,
  updateSettingsActions,
  resetSettingsActions,
  patchSettingsActions,
  fetchForwardEmailsActions,
  addForwardEmailActions,
  deleteForwardEmailActions,
  updateRecoveryEmailActions,
  updateMfa,
  fetchMfaActions,
  uploadInboxZeroBackgroundActions,
  deleteInboxZeroBackgroundActions,
  updateRecoveryMethodActions,
  removeRecoveryMethodActions,
} from './actions'

import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import type {
  SettingsActions,
  SettingsSuccess,
  SettingsPatchSuccess,
  FetchForwardEmailsSuccess,
  AddForwardEmailSuccess,
  DeleteForwardEmailSuccess,
  UpdateRecoveryEmailSuccess,
  UpdateMfa,
  FetchMfaSuccess,
  UploadInboxZeroBackgroundSuccess,
  DeleteInboxZeroBackgroundSuccess,
  RecoveryMethodUpdateSuccess,
  RecoveryMethodRemoveSuccess,
} from './types'
import type { Settings } from '@edison/webmail-core/types/settings'
import type { Mfa } from '@edison/webmail-core/types/mfa'

type BaseState = $Shape<Settings>

const baseReducer = createReducer<BaseState, SettingsActions>(
  {},
  {
    [fetchSettingsActions.success.toString()]: settingsSuccess,
    [updateSettingsActions.success.toString()]: settingsSuccess,
    [resetSettingsActions.success.toString()]: settingsSuccess,
    [patchSettingsActions.success.toString()]: patchSettingsSuccess,
    [updateRecoveryEmailActions.success.toString()]: updateRecoveryEmailSuccess,
    [uploadInboxZeroBackgroundActions.success.toString()]: uploadInboxZeroBackground,
    [deleteInboxZeroBackgroundActions.success.toString()]: deleteInboxZeroBackground,
    [updateRecoveryMethodActions.success.toString()]: updateRecoveryMethod,
    [removeRecoveryMethodActions.success.toString()]: removeRecoveryMethod,
  }
)

function settingsSuccess(state: BaseState, action: SettingsSuccess) {
  const { base } = action.payload
  return base
}

function patchSettingsSuccess(state: BaseState, action: SettingsPatchSuccess) {
  const toPatch = action.payload
  let nextState = cloneDeep(state)

  for (let { key, value } of toPatch) {
    set(nextState, key, value)
  }

  return nextState
}

function updateRecoveryEmailSuccess(
  state: BaseState,
  action: UpdateRecoveryEmailSuccess
) {
  const { email, verified } = action.payload

  return {
    ...state,
    account: {
      ...state.account,
      recovery: {
        ...state.account.recovery,
        email,
        verified,
      },
    },
  }
}

function uploadInboxZeroBackground(
  state: BaseState,
  action: UploadInboxZeroBackgroundSuccess
) {
  const { backgroundImageMode, backgroundImageFilename } = action.payload

  return {
    ...state,
    general: {
      ...state.general,
      display: {
        ...state.general.display,
        backgroundImageMode,
        backgroundImageFilename,
      },
    },
  }
}

function deleteInboxZeroBackground(
  state: BaseState,
  action: DeleteInboxZeroBackgroundSuccess
) {
  return {
    ...state,
    general: {
      ...state.general,
      display: {
        ...state.general.display,
        backgroundImageFilename: '',
      },
    },
  }
}

function updateRecoveryMethod(
  state: BaseState,
  action: RecoveryMethodUpdateSuccess
) {
  const method = action.payload

  const { recovery } = state.account
  let nextRecovery = { ...recovery }

  if (method.type === recoveryMethods.phone) {
    nextRecovery.phoneNumber = method.value
  } else if (method.type === recoveryMethods.email) {
    nextRecovery.email = method.value
    nextRecovery.verified = true
  }

  return {
    ...state,
    account: {
      ...state.account,
      recovery: nextRecovery,
    },
  }
}

function removeRecoveryMethod(
  state: BaseState,
  action: RecoveryMethodRemoveSuccess
) {
  const { recoveryMethod } = action.payload

  const { recovery } = state.account
  let nextRecovery = { ...recovery }

  if (recoveryMethod === recoveryMethods.phone) {
    nextRecovery.phoneNumber = ''
  } else if (recoveryMethod === recoveryMethods.email) {
    nextRecovery.email = ''
  }

  return {
    ...state,
    account: {
      ...state.account,
      recovery: nextRecovery,
    },
  }
}

type ForwardEmailsState = $ReadOnlyArray<{
  email: string,
  verified: boolean,
}>
const forwardEmailsReducer = createReducer<ForwardEmailsState, SettingsActions>(
  [],
  {
    [fetchForwardEmailsActions.success.toString()]: fetchForwardEmailsSuccess,
    [addForwardEmailActions.success.toString()]: addForwardEmailSuccess,
    [deleteForwardEmailActions.success.toString()]: deleteForwardEmailSuccess,
  }
)

function fetchForwardEmailsSuccess(
  state: ForwardEmailsState,
  action: FetchForwardEmailsSuccess
) {
  const list = action.payload
  return [...list]
}

function addForwardEmailSuccess(
  state: ForwardEmailsState,
  action: AddForwardEmailSuccess
) {
  const newForwardEmail = action.payload
  return [
    ...state.filter(({ email }) => email !== newForwardEmail.email),
    newForwardEmail,
  ]
}

function deleteForwardEmailSuccess(
  state: ForwardEmailsState,
  action: DeleteForwardEmailSuccess
) {
  const deleted = action.payload
  return state.filter(
    ({ email }) => email.toLowerCase() !== deleted.toLowerCase()
  )
}

type MfaState = $Shape<Mfa>

const mfaReducer = createReducer<MfaState, SettingsActions>(null, {
  [fetchMfaActions.success.toString()]: fetchMfaSuccess,
  [updateMfa]: updateMfaSuccess,
})

function fetchMfaSuccess(state: MfaState, action: FetchMfaSuccess) {
  return action.payload
}

function updateMfaSuccess(state: MfaState, action: UpdateMfa) {
  return {
    ...state,
    ...action.payload,
  }
}

export type State = {
  base: BaseState,
  forwardEmails: ForwardEmailsState,
  mfa: MfaState,
}

export default combineReducers<_, SettingsActions>({
  base: baseReducer,
  forwardEmails: forwardEmailsReducer,
  mfa: mfaReducer,
})
