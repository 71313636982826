//@ flow

import { combineReducers } from 'redux'
import settingsReducer from './reducers-settings'
import colorsReducer from './reducers-colors'
import calendarsReducer from './reducers-calendars'
import eventsReducer from './reducers-events'

export default combineReducers({
  settings: settingsReducer,
  colors: colorsReducer,
  calendars: calendarsReducer,
  events: eventsReducer,
})
