// @flow
import SparkMD5 from 'spark-md5'
import i18next from 'i18next'
import { batch } from 'react-redux'
import * as client from '@edison/webmail-core/api'
import { getAuth } from 'core/auth/selectors'
import { show as showModal } from 'core/modals/actions'
import { showNotification, show } from 'core/toasts/actions'
import { toastTypes, modalTypes } from 'utils/constants'
import { onLine, waitOnLine } from 'utils/onLine'
import { debouncedSaveDraft, updateDraft } from 'core/compose/actions'
import {
  getDraft,
  getDraftLargeAttachments,
  getDraftByLargeAttachmentUuid,
} from 'core/compose/selectors'
import { getNotDeletedAttachments } from './selectors'
import { getMessage } from 'core/messages/selectors'
import {
  waitTime,
  getMetaDataFromAttachmentUrl,
  hasLargeAttachmentComplete,
  hasLargeAttachmentSuccessComplete,
} from 'utils'
import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'
import PQueue from 'p-queue'

import type {
  CreateUploadRequest,
  CreateUploadSuccess,
  CreateUploadFailure,
  UploadPartRequest,
  UploadPartSuccess,
  UploadPartFailure,
  FetchUploadRequest,
  FetchUploadSuccess,
  FetchUploadFailure,
  DeleteUploadRequest,
  DeleteUploadSuccess,
  DeleteUploadFailure,
  RemoveUpload,
  AddUploads,
  RemoveUploads,
  UpdateUploads,
} from './types'
import type { LargeAttachment } from '@edison/webmail-core/types/large-attachments'
import type { MessageLargeAttachment } from '@edison/webmail-core/types/messages'
import { getLargeAttachment } from './selectors'
import type { ThunkAction, ActionCreator } from 'types/redux'
import {
  largeAttachmentStatus,
  largeAttachmentScanStatus,
  INSECURE_SCAN_STATUS,
} from '@edison/webmail-ui/utils/constants'

const CONCURRENT_UPLOAD_COUNT = 3
const queue = new PQueue({ concurrency: CONCURRENT_UPLOAD_COUNT })

export const removeUpload: ActionCreator<RemoveUpload> = createAction(
  'REMOVE_UPLOAD'
)
export const addUploads: ActionCreator<AddUploads> = createAction('ADD_UPLOADS')
export const updateUpload: ActionCreator<UpdateUpload> = createAction(
  'UPDATE_UPLOAD'
)
export const removeUploads: ActionCreator<RemoveUploads> = createAction(
  'REMOVE_UPLOADS'
)

export const updateUploads: ActionCreator<UpdateUploads> = createAction(
  'UPDATE_UPLOADS'
)

export const deleteUploadActions: {
  request: ActionCreator<DeleteUploadRequest>,
  success: ActionCreator<DeleteUploadSuccess>,
  failure: ActionCreator<DeleteUploadFailure>,
} = {
  request: createAction('DELETE_UPLOAD_REQUEST'),
  success: createAction('DELETE_UPLOAD_SUCCESS'),
  failure: createAction('DELETE_UPLOAD_FAILURE'),
}

/**
 * Get large attachment info
 *
 * @public
 * @returns {ThunkAction}
 */
export function deleteUpload(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(deleteUploadActions.failure({ message: 'User not logged in' }))
      return
    }
    const { aid } = getLargeAttachment(attachmentUuid)(getState())
    try {
      dispatch(deleteUploadActions.request({ uuid: attachmentUuid }))
      await client.largeAttachments.deleteUpload(aid, auth)

      dispatch(deleteUploadActions.success({ uuid: attachmentUuid }))
    } catch (e) {
      if (e.message) {
        // dispatch(showNotification(e.message, toastVariants.error))

        dispatch(deleteUploadActions.failure({ message: e.message }))
      }
    }
  }
}
export const fetchUploadActions: {
  request: ActionCreator<FetchUploadRequest>,
  success: ActionCreator<FetchUploadSuccess>,
  failure: ActionCreator<FetchUploadFailure>,
} = {
  request: createAction('FETCH_UPLOAD_REQUEST'),
  success: createAction('FETCH_UPLOAD_SUCCESS'),
  failure: createAction('FETCH_UPLOAD_FAILURE'),
}

/**
 * Get large attachment info
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchUpload(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(fetchUploadActions.failure({ message: 'User not logged in' }))
      return
    }
    const { aid } = getLargeAttachment(attachmentUuid)(getState())

    dispatch(fetchUploadActions.request())
    const res = await client.largeAttachments.fetchUpload(aid, auth)
    const { status, scanStatus } = res.result
    dispatch(
      fetchUploadActions.success({ uuid: attachmentUuid, status, scanStatus })
    )
  }
}

export const createUploadActions: {
  request: ActionCreator<CreateUploadRequest>,
  success: ActionCreator<CreateUploadSuccess>,
  failure: ActionCreator<CreateUploadFailure>,
} = {
  request: createAction('CREATE_UPLOAD_REQUEST'),
  success: createAction('CREATE_UPLOAD_SUCCESS'),
  failure: createAction('CREATE_UPLOAD_FAILURE'),
}

/**
 * Create a upload
 *
 * @public
 * @param {File} file - file object
 * @returns {ThunkAction}
 */
export function createUpload(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(createUploadActions.failure({ message: 'User not logged in' }))
      return
    }
    const { name, size, contentType } = getLargeAttachment(attachmentUuid)(
      getState()
    )

    dispatch(createUploadActions.request({ uuid: attachmentUuid }))
    const res = await client.largeAttachments.createUpload(
      {
        name,
        size,
        contentType,
      },

      { auth }
    )
    const { attachmentId, chunkSize } = res.result

    dispatch(
      createUploadActions.success({
        uuid: attachmentUuid,
        aid: attachmentId,
        chunkSize: chunkSize || size,
      })
    )
  }
}

export const uploadPartActions: {
  request: ActionCreator<UploadPartRequest>,
  success: ActionCreator<UploadPartSuccess>,
  failure: ActionCreator<UploadPartFailure>,
} = {
  request: createAction('UPLOAD_PART_REQUEST'),
  success: createAction('UPLOAD_PART_SUCCESS'),
  failure: createAction('UPLOAD_PART_FAILURE'),
}

/**
 * Upload part
 *
 * @public
 * @param {string} attachmentId - Attachment Id
 * @param {Blob} blobData - Blob data to upload
 * @returns {ThunkAction}
 */
export function uploadPart(
  attachmentUuid: string,
  blobData: Blob
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(uploadPartActions.failure({ message: 'User not logged in' }))
      return
    }
    const attachment = getLargeAttachment(attachmentUuid)(getState())

    if (!attachment) {
      dispatch(
        uploadPartActions.failure({ message: 'Attachment Id not exist' })
      )
      return
    }
    const { aid } = attachment

    const hash = await calculateBlobMd5(blobData)

    const promise = client.largeAttachments.uploadPart(
      { aid, seq: attachment.parts.length + 1, blobData, hash },
      { auth },
      {
        'upload.onprogress': event => {
          dispatch(
            updateUpload({
              uuid: attachmentUuid,
              chunkLoaded: event.loaded,
            })
          )
        },
      }
    )
    dispatch(
      uploadPartActions.request({ uuid: attachmentUuid, xhr: promise.xhr })
    )

    const res = await promise
    const { etag } = res.result
    if (etag !== hash) {
      throw Error('upload check error')
    }
    dispatch(uploadPartActions.success({ uuid: attachmentUuid, hash }))
  }
}

export function uploadLargeAttachment(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    try {
      const { aid, error, status } = getLargeAttachment(attachmentUuid)(
        getState()
      )
      if (status === largeAttachmentStatus.DELETING) return
      if (error) {
        dispatch(updateUpload({ uuid: attachmentUuid, error: null }))
      }
      if (!aid) {
        await dispatch(createUpload(attachmentUuid))
      }

      const { chunkSize, file, parts } = getLargeAttachment(attachmentUuid)(
        getState()
      )

      const { size, type } = file
      const count = Math.ceil(size / chunkSize)

      for (let i = parts.length; i < count; i++) {
        //check deleted
        const { status, error } = getLargeAttachment(attachmentUuid)(getState())
        if (!status || status === largeAttachmentStatus.DELETING || error) {
          return
        }
        const blobData = file.slice(i * chunkSize, (i + 1) * chunkSize, type)
        try {
          await dispatch(uploadPart(attachmentUuid, blobData))
        } catch (e) {
          if (e.name === 'AbortError') return
          if (e.message === 'timeout') throw new Error('Upload timeout')

          if (e.message === 'upload check error') {
            i = i - 1
          } else if (e.status === 150005) {
            return
          } else if (e.status === 150201) {
            dispatch(
              updateUpload({
                uuid: attachmentUuid,
                status: largeAttachmentStatus.DONE,
                scanStatus: largeAttachmentScanStatus.CLEAN,
                chunkSize: size,
                parts: [{}],
              })
            )
            return
          } else {
            throw e
          }
        }
      }

      let currAttachment = getLargeAttachment(attachmentUuid)(getState())
      if (!currAttachment) return
      do {
        const { status, error } = currAttachment
        if (status === largeAttachmentStatus.DELETING || error) {
          return
        }
        await waitTime(2000)
        await dispatch(fetchUpload(attachmentUuid))
        currAttachment = getLargeAttachment(attachmentUuid)(getState())
        if (
          currAttachment &&
          INSECURE_SCAN_STATUS.includes(currAttachment.scanStatus)
        )
          return
      } while (currAttachment && !hasLargeAttachmentComplete(currAttachment))
    } catch (e) {
      if (!onLine) {
        await waitOnLine()
        return dispatch(uploadLargeAttachment(attachmentUuid))
      } else {
        const { status } = getLargeAttachment(attachmentUuid)(getState())
        if (status === largeAttachmentStatus.DELETING) return
        dispatch(
          show({
            key: 'largeAttachmentError',
            props: {
              message: e.message || i18next.t('toast.default.error'),
              type: toastTypes.notification,
              variant: toastVariants.error,
            },
          })
        )
        dispatch(updateUpload({ uuid: attachmentUuid, error: e }))
      }
    }
  }
}

function calculateBlobMd5(blobData: Blob) {
  return new Promise((resolve, reject) => {
    var a = new FileReader()
    a.readAsArrayBuffer(blobData)
    a.onloadend = () => {
      resolve(SparkMD5.ArrayBuffer.hash(a.result))
    }
  })
}

export function uploadLargeAttachments(
  draftId: string,
  largeAttachments: Array<LargeAttachment>
) {
  return async (dispatch, getState) => {
    batch(() => {
      dispatch(addUploads(largeAttachments))

      dispatch(
        uploadLargeAttachmentUuids(
          draftId,
          largeAttachments.map(item => item.uuid)
        )
      )
    })
  }
}

export function uploadLargeAttachmentUuids(
  draftId: string,
  largeAttachmentUuids: Array<string>
) {
  return async (dispatch, getState) => {
    batch(() => {
      largeAttachmentUuids.forEach(uuid => {
        const promise = queue
          .add(() => dispatch(uploadLargeAttachment(uuid)))
          .then(() => {
            const currAttachment = getLargeAttachment(uuid)(getState())
            if (hasLargeAttachmentSuccessComplete(currAttachment)) {
              //force save again avoid if click send
              dispatch(updateDraft({ id: draftId, saved: false }))
              dispatch(debouncedSaveDraft(draftId))
            }
          })

        dispatch(
          updateUpload({
            uuid,
            promise,
          })
        )
      })
    })
  }
}

export function retryUpload(draftId, largeAttachmentUuid): ThunkAction {
  return (dispatch, getState) => {
    batch(() => {
      dispatch(updateUpload({ uuid: largeAttachmentUuid, error: null }))
      dispatch(uploadLargeAttachmentUuids(draftId, [largeAttachmentUuid]))
    })
  }
}

export function discardUpload(
  draftId: string,
  attachmentUuid: string
): ThunkAction {
  return async (dispatch, getState) => {
    const { aid, xhr } = getLargeAttachment(attachmentUuid)(getState())
    if (xhr) {
      xhr.abort()
    }
    batch(() => {
      dispatch(removeUpload(attachmentUuid))
      if (aid) {
        dispatch(deleteUpload(attachmentUuid))
      }

      const { largeAttachmentUuids } = getDraft(draftId)(getState())

      dispatch(
        updateDraft({
          id: draftId,
          largeAttachmentUuids: largeAttachmentUuids.filter(
            item => item !== attachmentUuid
          ),
        })
      )
      aid && dispatch(debouncedSaveDraft(draftId))
    })
  }
}
export function discardUploads(attachmentUuids): ThunkAction {
  return async (dispatch, getState) => {
    attachmentUuids.forEach(item => {
      const { xhr } = getLargeAttachment(item)(getState())
      if (xhr) {
        xhr.abort()
      }
    })
    dispatch(removeUploads(attachmentUuids))
  }
}

export function preview(attachment): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    const { name, contentType, messageHeaderId: mid, aid, url } = attachment
    const { email, uid } = getMetaDataFromAttachmentUrl(url)

    try {
      const res = await client.largeAttachments.authDownload(
        {
          mid,
          aid,
          uid,
          email,
          action: 'preview',
          pvformat: attachment.preview,
        },
        { auth }
      )
      dispatch(
        showModal({
          key: modalTypes.preview,
          props: {
            src: res.result,
            name,
            contentType,
            previewType: attachment.preview,
            messageId: mid,
            attachment: {
              isLargeAttachment: true,
              entity: attachment,
              email,
              uid,
            },
            onDownload: async () => {
              try {
                const res = await client.largeAttachments.authDownload(
                  { mid, aid, uid, email, action: 'preview' },
                  { auth }
                )
                window.open(res.result, '_blank')
              } catch (e) {
                dispatch(
                  showNotification(
                    e.message || 'The attachment link is invalid!',
                    toastVariants.error
                  )
                )
              }
            },
          },
        })
      )
    } catch (e) {
      if (e.status === 404) {
        dispatch(
          showNotification(
            e.message || 'The attachment link is invalid!',
            toastVariants.error
          )
        )
        return
      }
      dispatch(
        showModal({
          key: modalTypes.attachmentPassword,
          props: { attachment },
        })
      )
    }
  }
}

export function download(attachment: MessageLargeAttachment): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    const { messageHeaderId: mid, aid, url } = attachment
    const { email, uid } = getMetaDataFromAttachmentUrl(url)

    try {
      const res = await client.largeAttachments.authDownload(
        { mid, aid, uid, email, action: 'preview' },
        { auth }
      )
      window.open(res.result, '_blank')
    } catch (e) {
      dispatch(
        showNotification(
          e.message || 'The attachment link is invalid!',
          toastVariants.error
        )
      )
    }
  }
}

export function downloadAll(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const { largeAttachments } = getMessage(messageId)(state)
    const auth = getAuth()(state)
    const { url, messageHeaderId: mid } = largeAttachments[0]
    const { email, uid } = getMetaDataFromAttachmentUrl(url)

    try {
      const res = await client.largeAttachments.authDownloadAll(
        { mid, uid, email, action: 'preview' },
        { auth }
      )
      window.open(res.result, '_blank')
    } catch (e) {
      if (e.status === 404) {
        dispatch(
          showNotification(
            'The attachment link is invalid!',
            toastVariants.error
          )
        )
        return
      }
      dispatch(
        showModal({
          key: modalTypes.downloadAllPassword,
          props: { mid, uid, email },
        })
      )
    }
  }
}

export function retryAllUpload(draftId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const attachments = getDraftLargeAttachments(draftId)(state).filter(
      item => item.error
    )
    batch(() => {
      dispatch(
        updateUploads(
          attachments.map(item => ({ uuid: item.uuid, error: null }))
        )
      )
      dispatch(
        uploadLargeAttachmentUuids(
          draftId,
          attachments.map(item => item.uuid)
        )
      )
    })
  }
}

export function retryAttachment(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const draft = getDraftByLargeAttachmentUuid(attachmentUuid)(state)
    if (!draft) {
      throw Error("can't find the attachment draft")
    }
    dispatch(retryUpload(draft.id, attachmentUuid))
  }
}

export function discardAttachment(attachmentUuid: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const draft = getDraftByLargeAttachmentUuid(attachmentUuid)(state)
    if (!draft) {
      throw Error("can't find the attachment draft")
    }
    dispatch(discardUpload(draft.id, attachmentUuid))
  }
}

export function retryAllAttachments(): ThunkAction {
  return async (dispatch, getState) => {
    const attachments = getNotDeletedAttachments()(getState()).filter(
      item => item.error
    )
    batch(() => {
      attachments.forEach(({ uuid }) => dispatch(retryAttachment(uuid)))
    })
  }
}
