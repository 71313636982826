// @flow
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { grantPasswordAuth } from 'core/auth/actions'
import { isPasswordAuthLoading } from 'core/auth/selectors'

import Dialog from '@material-ui/core/Dialog'
import {
  AppPasswordLayout,
  PasswordForm,
} from '@edison/webmail-ui/components/AppSpecificPassword'

import type { ExtraAuth } from '@edison/webmail-core/types'
import type { Dispatch } from 'types/redux'

type FormProps = {
  onClose: () => void,
  onConfirm: (auth: ExtraAuth) => void,
}
export const Form = ({ onClose, onConfirm }: FormProps) => {
  const { t } = useTranslation()
  const disaptch: Dispatch = useDispatch()

  const isLoading = useSelector(isPasswordAuthLoading)

  function handleOnSubmit({ password }) {
    disaptch(grantPasswordAuth(password)).then((auth: ?ExtraAuth) => {
      if (!!auth) {
        onConfirm(auth)
      }
    })
  }

  return (
    <AppPasswordLayout
      onClose={onClose}
      title={t('twoFactorAuthentication.inputPasswordStep.primaryTitle')}
      subtitle={t('twoFactorAuthentication.inputPasswordStep.secondaryTitle')}
    >
      <PasswordForm
        onBack={onClose}
        isLoading={isLoading}
        onSubmit={handleOnSubmit}
      />
    </AppPasswordLayout>
  )
}

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: (auth: ExtraAuth) => void,
}
export default ({ isOpen, onClose, onConfirm }: Props) => {
  function handleOnConfirm(...params) {
    onConfirm(...params)
    onClose()
  }

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <Form onClose={onClose} onConfirm={handleOnConfirm} />
    </Dialog>
  )
}
