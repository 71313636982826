// @flow
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import truncate from 'lodash/truncate'
import cn from 'classnames'

import { getSplitInboxes } from 'core/split-inboxes/selectors'

import Popover from '@material-ui/core/Popover'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Button from '@edison/webmail-ui/components/Button'

const splitInboxesSelector = getSplitInboxes()

type Props = {
  disabled: boolean,
  onAccept: boolean => void,
  onBlock: boolean => void,
  onMoveToSplit: Object => void,
  onlyBlockSender?: boolean,
}

export default ({
  disabled,
  onAccept,
  onBlock,
  onMoveToSplit,
  onlyBlockSender = false,
}: Props) => {
  const { t } = useTranslation()
  const dropdownRef = useRef()
  const blockRef = useRef()
  const blockDropdownRef = useRef()
  const splits = useSelector(splitInboxesSelector)

  const onBlockDomain = () => {
    onBlock(false)
    if (blockRef.current) {
      blockRef.current.closeDropdown()
    }
  }

  return (
    <div className="sticky bottom-0 w-full p-4">
      <div
        ref={blockDropdownRef}
        style={{ width: 200 }}
        className="border mx-8 inline-block relative items-center"
      >
        <Button
          color="secondary"
          className={cn('flex justify-center w-full bg-card')}
          disabled={disabled}
          onClick={() => onBlock(true)}
        >
          {t('button.block')}
        </Button>
        {onlyBlockSender ? null : (
          <ExpandMenu
            ref={blockRef}
            color="secondary"
            className="bg-card border-l-0"
            disabled={disabled}
            dropdownRef={blockDropdownRef}
            style={{ width: 60, position: 'absolute', top: 0, right: 0 }}
          >
            <>
              <button
                onClick={onBlockDomain}
                className="w-full text-left block px-6 py-2 text-medium hover:text-dark hover:bg-divider"
              >
                {t('button.blockDomain')}
              </button>
              <button
                onClick={() => onBlock(true)}
                className="w-full text-left block px-6 py-2 text-medium hover:text-dark hover:bg-divider"
              >
                {t('button.blockSender')}
              </button>
            </>
          </ExpandMenu>
        )}
      </div>
      <div
        ref={dropdownRef}
        style={{ width: 200 }}
        className="border inline-block relative items-center flex-1"
      >
        <Button
          className={cn(`flex justify-center w-full`)}
          disabled={disabled}
          onClick={onAccept}
        >
          {t('button.accept')}
        </Button>
        {splits.length > 0 && (
          <ExpandMenu
            disabled={disabled}
            dropdownRef={dropdownRef}
            style={{ width: 60, position: 'absolute', top: 0, right: 0 }}
          >
            {splits.map(split => (
              <button
                onClick={() => onMoveToSplit(split)}
                className="w-full text-left block px-6 py-2 text-medium hover:text-dark hover:bg-divider"
              >
                {t('approveSender.acceptAndMoveTo', {
                  name: truncate(split.name, { length: 14 }),
                })}
              </button>
            ))}
          </ExpandMenu>
        )}
      </div>
    </div>
  )
}

const ExpandMenu = forwardRef(
  (
    {
      className,
      disabled,
      dropdownRef,
      children,
      color = 'primary',
      style = {},
    },
    ref
  ) => {
    const [showDropdown, setShowDropdown] = useState(false)

    useEffect(() => {
      if (disabled) {
        setShowDropdown(false)
      }
    }, [disabled])

    useImperativeHandle(ref, () => ({
      closeDropdown: () => setShowDropdown(false),
    }))

    return (
      <>
        <Button
          style={style}
          color={color}
          disabled={disabled}
          className={cn('flex justify-center', className)}
          onClick={() => setShowDropdown(state => !state)}
        >
          <ExpandMore />
        </Button>
        <Popover
          open={showDropdown && !disabled}
          anchorEl={dropdownRef.current}
          onClose={() => setShowDropdown(false)}
          PaperProps={{
            className:
              'shadow-hover dark:shadow-lg-t bg-app !dark:bg-secondary !rounded-none',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div className="pb-2" style={{ width: 320 }}>
            {children}
          </div>
        </Popover>
      </>
    )
  }
)
