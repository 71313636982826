// @flow
import uuid from 'uuid'

import type { Store, Dispatch, ActionMeta } from 'types/redux'

export const promises = {}
const timers = {}
const requests = {}

export const DEBOUNCE = 'DEBOUNCE'

export default (store: Store) => (dispatch: Dispatch) => (action: {
  type: string,
  payload: mixed,
  meta: ActionMeta,
}) => {
  const { type, payload, meta } = action

  if (type !== DEBOUNCE) return dispatch(action)

  const { key, wait, flush } = meta.debounce
  const requestId = uuid()
  promises[key] = promises[key] || Promise.resolve()

  requests[key] = requestId
  // if offline need wait until network online
  //todo: move wait on line to internal requests promise

  const currPromise = () => (payload ? dispatch(payload) : Promise.resolve())

  if (timers[key]) {
    clearTimeout(timers[key])
    timers[key] = null
  }

  if (flush) {
    promises[key] = promises[key].then(currPromise)
    return promises[key]
  }

  return new Promise<void>(resolve => {
    timers[key] = setTimeout(() => {
      promises[key] = promises[key].then(() => {
        if (requests[key] === requestId) {
          return currPromise()
        }
      })
      resolve(promises[key])
    }, wait)
  })
}

/**
 * Creates a debounced thunk to be used with the debounce middleware.
 *
 * @public
 * @param {ThunkAction} thunk
 */
export function debounced<T>(
  action: T,
  {
    key,
    wait = 300,
    flush = false,
  }: {
    key: string,
    wait: number,
    flush: boolean,
  }
) {
  return {
    type: DEBOUNCE,
    payload: action,
    meta: {
      debounce: {
        key,
        wait,
        flush,
      },
    },
  }
}
