//@flow
import { checkAndFetchThread } from 'core/messages/actions'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LoadedMessageList } from 'screens/ThreadDetail'
import { CardBodyContainer } from '@edison/webmail-ui/components/Cards/CardModal'

export default function SuggestedCardDetail({
  threadId,
  onViewInCalendar,
  onPrevious,
}) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkAndFetchThread(threadId, false))
  }, [])
  return (
    <div className="bg-card">
      <CardBodyContainer onPrevious={onPrevious}>
        <LoadedMessageList
          isModalMode
          isFilterPendingMessage={false}
          onViewInCalendar={onViewInCalendar}
          threadId={threadId}
          onSelectRecipient={() => {}}
          key={threadId}
        />
      </CardBodyContainer>
    </div>
  )
}
