// @flow
import { useRef, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import values from 'lodash/values'
import invert from 'lodash/invert'
import * as Yup from 'yup'
import * as client from '@edison/webmail-core/api/settings'
import getCaptchaToken from '@edison/webmail-core/captcha'
import { useToast, toastVariants } from 'common/toasts'
import { RequestError } from '@edison/requests'

import { useOrderId } from 'core/auth/hooks'
import {
  patchSettings,
  resendReocveryVerifyEmail,
  updateRecoveryMethod,
  removeRecoveryMethod,
} from './actions'
import {
  getSettings,
  isRecoveryEmailLoading,
  getQuickActionSetting,
} from './selectors'
import { useDomain } from 'core/custom-domains/hooks'
import { isDomainAdmin, getAliases } from 'core/custom-domains/selectors'
import { isAccountLocked } from 'core/premium/selectors'
import {
  NAVS,
  routePaths,
  toastTypes,
  quickActionSettingMappings,
} from 'utils/constants'
import { stateWrapper } from 'utils'
import { fields } from '@edison/webmail-ui/screens/Settings/fields'
import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import type { RecoveryMethod } from '@edison/webmail-ui/utils/constants'
import type { QuickActionType } from '@edison/webmail-ui/components/QuickAction'
import type { ExtraAuth } from '@edison/webmail-core/types'
import type { Dispatch } from 'types/redux'

const settingsSelector = getSettings()

export const useNavs = () => {
  const orderId = useOrderId()
  const isAdmin = useSelector(isDomainAdmin)
  const isLocked = useSelector(isAccountLocked)
  const backToInboxText = useTranslation().t('button.backToInbox')

  const navs: $ReadOnlyArray<{
    key: string,
    name: string,
    value: string,
  }> = useMemo(() => {
    return values(NAVS).filter(nav => {
      if (isLocked) return nav.key === NAVS.account.key
      else if (isAdmin) return true
      else return nav.key !== NAVS.domain.key
    })
  }, [isAdmin, isLocked])

  const fixedNavs = useMemo(() => {
    if (!isLocked)
      return {
        key: 'home',
        name: backToInboxText,
        value: generatePath(routePaths.main, {
          userId: orderId,
          label: 'inbox',
        }),
      }
    else
      return {
        key: 'logout',
        name: 'Logout',
        value: routePaths.logout,
      }
  }, [isLocked, orderId])

  return {
    navs,
    fixedNavs,
  }
}

export const useRecoveryEmail = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const recoveryEmailSent = useToast(toastTypes.notification)
  const settings = useSelector(settingsSelector)
  const isSending = useSelector(isRecoveryEmailLoading)

  const isVerified = get(settings, 'account.recovery.verified', false)
  const email = get(settings, 'account.recovery.email', '')
  const resend = useCallback(async () => {
    const success = await dispatch(resendReocveryVerifyEmail())

    if (success) {
      recoveryEmailSent.showToast(
        t('settings.recovery.email.verificationSent'),
        toastVariants.success
      )
    }

    return success
  }, [email])

  return {
    isSending,
    isVerified,
    email,
    resend,
  }
}

export const useQuickAction = (): ({
  configured: boolean,
  value: QuickActionType,
  onChange: (next: QuickActionType) => void,
}) => {
  const dispatch: Dispatch = useDispatch()
  const quickAction = useSelector(getQuickActionSetting)

  const onChange = nextAction => {
    const invertedMappings = invert(quickActionSettingMappings)
    const value = parseInt(invertedMappings[nextAction])

    if (quickAction !== nextAction) {
      dispatch(patchSettings([{ key: fields.general.quickAction, value }]))
    }
  }

  return {
    configured: quickAction !== null,
    value: quickAction,
    onChange,
  }
}

export const useRecoveryEmailValidator = () => {
  const { t } = useTranslation()
  const alias = useSelector(getAliases)
  const { onmailDomain } = useDomain()
  const { email, isVerified: verified } = useRecoveryEmail()

  function isContainedSelf(input) {
    return alias.some(item => input === item)
  }

  return value => {
    const _value = value.trim().toLowerCase()
    if (!_value) return t('required')

    if (isContainedSelf(_value)) return t('settings.general.recoveryEmail.self')

    if (verified && email.toLowerCase() === _value)
      return t('settings.general.recoveryEmail.duplicated')

    if (_value.endsWith(`@${onmailDomain}`))
      return t('settings.general.recoveryEmail.domain')

    if (
      !Yup.string()
        .trim()
        .email()
        .required()
        .isValidSync(value)
    ) {
      return t('invalidEmail')
    }
  }
}

export const useRecoveryPhoneValidator = () => {
  const ref = useRef()

  const validate = value => {
    if (values.length === 0) {
      return 'Required'
    } else if (ref.current && !ref.current.isValidNumber()) {
      return 'Invalid phone number'
    }
  }

  return { ref, validate }
}

export const useRecoveryMethods = () => {
  const settings = useSelector(settingsSelector)
  const methods = get(settings, 'account.recovery', {})
  const getMethod = (name: $PropertyType<RecoveryMethod, 'type'>) => {
    if (name === recoveryMethods.phone) {
      return methods['phoneNumber']
    } else {
      return methods[name]
    }
  }

  return {
    methods,
    getMethod,
  }
}

export const useRecoveryMethodForm = (auth: ?ExtraAuth = null) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()

  const existingMethods = useRecoveryMethods()

  const [isLoading, setLoading] = useState(false)
  const [state, setState] = useState<{
    auth: ?ExtraAuth,
    unsetMethod: ?$PropertyType<RecoveryMethod, 'type'>,
    method: ?RecoveryMethod,
  }>({ auth, method: {}, unsetMethod: null })

  const toast = useToast(toastTypes.notification)

  const sendCode = async (method: RecoveryMethod) => {
    setLoading(true)

    try {
      // Fetch reCAPTCHA token
      const captcha = await getCaptchaToken(`${method.type}_recovery_send`)

      // Send code by given recovery method
      if (method.type === recoveryMethods.phone) {
        await client.sendSMSCode(
          { phoneNumber: method.value },
          { captcha: captcha.token }
        )
      } else if (method.type === recoveryMethods.email) {
        await client.sendEmailCode(
          { email: method.value },
          { captcha: captcha.token }
        )
      } else {
        // Error when the method is invalid
        throw new Error('Invalid recovery method')
      }

      return true
    } catch (e) {
      console.error(e)

      let toastMessage = t('recoveryMethod.error.sendCode.default')
      if (e instanceof RequestError) {
        if (e.status === 409) {
          toast.showToast(
            t('recoveryMethod.error.sendCode.limit'),
            toastVariants.error
          )
          return false
        } else {
          toastMessage = toastMessage + '(' + e.status + ')'
        }
      }

      toast.showToast(toastMessage, toastVariants.error)
      return false
    } finally {
      setLoading(false)
    }
  }

  const verifyCode = async code => {
    const { method } = state

    if (!method) {
      throw new Error('Invalid recovery method')
    }

    let token
    // Verify the code based on the given recovery method
    if (method.type === recoveryMethods.phone) {
      const res = await client.verifySMSCode({
        phoneNumber: method.value,
        code,
      })
      token = res.result.phoneAuthToken
    } else if (method.type === recoveryMethods.email) {
      const res = await client.verifyEmailCode({ email: method.value, code })
      token = res.result.emailAuthToken
    }

    // Validate the token fromr response
    if (token) return token
    else throw new Error('Invalid response from code verification')
  }

  const updateMethod = async code => {
    setLoading(true)

    const { method, auth } = state

    let token
    try {
      token = await verifyCode(code)
    } catch (e) {
      console.error(e)
      toast.showToast(
        t('recoveryMethod.error.verifyCode.default'),
        toastVariants.error
      )
    }

    if (!token || !auth || !method) {
      return false
    }

    const res = await dispatch(updateRecoveryMethod(method, token, { auth }))

    setLoading(false)
    return res
  }

  const removeMethod = async (
    method: $PropertyType<RecoveryMethod, 'type'>,
    {
      auth,
    }: {
      auth: ExtraAuth,
    }
  ) => {
    setLoading(true)

    const res = await dispatch(removeRecoveryMethod(method, { auth }))

    setLoading(false)
    return res
  }

  const checkUnsetMethod = () => {
    const unset = values(recoveryMethods).find(key => {
      const val = existingMethods.getMethod(key)

      return val.length === 0
    })

    if (unset) {
      setState(state => ({ ...state, unsetMethod: unset }))
      return true
    }

    return false
  }

  return {
    auth: stateWrapper('auth', state, setState),
    form: stateWrapper('method', state, setState),
    unsetMethod: stateWrapper('unsetMethod', state, setState),
    isLoading,
    setLoading,
    sendCode,
    verifyCode,
    updateMethod,
    removeMethod,
    checkUnsetMethod,
  }
}
