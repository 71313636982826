// @flow
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'

import ActionToastBar from '@edison/webmail-ui/components/ActionToastBar'
import * as actionNodes from '@edison/webmail-ui/components/ThreadListItem/thread-actions'
import {
  isThreadDragging,
  getSelectedUniqueLabels,
  getSelectedThreadIds,
} from 'core/threads/selectors'

import { useModal } from 'common/modals'
import LabelPicker from 'common/Labels/Popover'
import {
  useThreadBatchActions,
  useThreadBatchActionFlags,
  useActiveLabel,
} from 'core/threads/hooks'
import { useSelector } from 'utils/redux'
import { modalTypes, routePaths } from 'utils/constants'
import { useDispatch } from 'react-redux'
import * as actions from '../../core/threads/actions'

const selectedThreadsSelector = getSelectedThreadIds()
const selectedLabelsSelector = getSelectedUniqueLabels()

type Props = {
  withIn?: string,
}

const ThreadActionBar = ({ withIn }: Props) => {
  const dispatch = useDispatch()
  const matched = useRouteMatch(routePaths.splitInbox)
  const splitInboxId = matched && matched.params.splitInboxId
  const selected = useSelector(selectedThreadsSelector)
  const uniqueLabels = useSelector(selectedLabelsSelector)
  const [anchorEl, setAnchorEl] = useState(null)
  const ref = useRef()
  const { showModal } = useModal(modalTypes.labelDetail)
  const batchActions = useThreadBatchActions()
  const activeLabel = useActiveLabel()
  const preActiveLabel = useRef(activeLabel)
  // when switch navigation, we should resetSelectThread before the history change
  // but we can't know the redux when complete its status update in memory
  // let it to change, when activeLabel change we don't show ActionToastBar
  useEffect(() => {
    preActiveLabel.current = activeLabel
  })
  const { main, menu, disabled } = useThreadBatchActionFlags(splitInboxId)
  const setLabelPopoverAnchor = () => setAnchorEl(ref.current)

  const isDragging = useSelector(isThreadDragging)
  const onNewLabelAppliedToThreads = useCallback(() => {
    dispatch(
      actions.batchSelectThread({
        ids: Array.from(selected),
        value: false,
      })
    )
  }, [selected])

  if (preActiveLabel.current !== activeLabel) {
    return null
  }

  return (
    <>
      <ActionToastBar
        isOpen={selected.size > 0 && !isDragging}
        actions={main.map(id => ({
          label: actionNodes[id].title,
          icon: actionNodes[id].icon,
          onClick: batchActions[id],
          disabled: disabled.includes(id),
        }))}
        menuRef={ref}
        menuActions={menu.map(id => ({
          label: actionNodes[id].title,
          onClick:
            id === actionNodes.addLabel.name
              ? setLabelPopoverAnchor
              : batchActions[id],
        }))}
      />
      <LabelPicker
        labelIds={uniqueLabels}
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onChange={labels => {
          const labelIds = labels.map(label => label.id).filter(Boolean)
          batchActions.addLabel(labelIds)
        }}
        onNewLabel={name => {
          setAnchorEl(null)
          showModal({
            name,
            applyTo: Array.from(selected),
            onActionComplete: onNewLabelAppliedToThreads,
          })
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      />
    </>
  )
}

export default ThreadActionBar
