// @flow
import omit from 'lodash/omit'
import moment from 'moment'
import { createReducer } from 'utils/redux'
import { labelNames } from 'utils/constants'
import { convertHtmlToText } from 'utils'
import * as actions from './actions'
import * as composeActions from '../compose/actions'
import type {
  State,
  AttachmentLinkListSuccess,
  DeleteAttachmentLinkRequest,
  BatchDeleteAttachmentLinkRequest,
  ResetPasswordSuccess,
  AttachmentLinkActions,
} from './types'
import {
  DeleteDraftSuccess,
  BatchDeleteDraftSuccess,
  SaveDraftSuccess,
  SendDraftSuccess,
} from '../compose/types'

export const initialState: State = {}

const reducer = createReducer<State, AttachmentLinkActions>(initialState, {
  [actions.attachmentLinkListActions.success.toString()]: attachmentList,

  [actions.deleteAttachmentLinkActions.request.toString()]: deleteAttachmentLink,
  [actions.batchDeleteAttachmentLinksActions.request.toString()]: batchDeleteAttachmentLinks,
  [actions.resetPasswordActions.success.toString()]: resetPassword,

  //compose
  [composeActions.deleteDraftActions.success.toString()]: deleteDraft,
  [composeActions.batchDeleteDraftActions.success.toString()]: batchDeleteDraft,
  [composeActions.saveDraftActions.success.toString()]: saveDraft,
  [composeActions.sendDraftActions.success.toString()]: sendDraft,
})

function attachmentList(
  state: State,
  action: AttachmentLinkListSuccess
): State {
  const attachmentList = action.payload
  return {
    ...attachmentList.reduce(
      (prev, curr) => ({ ...prev, [curr.messageId]: curr }),
      {}
    ),
  }
}

function deleteAttachmentLink(
  state: State,
  action: DeleteAttachmentLinkRequest
): State {
  const { messageId } = action.payload
  return omit(state, [messageId])
}

function batchDeleteAttachmentLinks(
  state: State,
  action: BatchDeleteAttachmentLinkRequest
): State {
  const { messageIds } = action.payload
  return omit(state, messageIds)
}

function resetPassword(state: State, action: ResetPasswordSuccess): State {
  const { messageId, password } = action.payload
  if (!state[messageId]) return state
  return {
    ...state,
    [messageId]: {
      ...state[messageId],
      password,
    },
  }
}

function deleteDraft(state: State, action: DeleteDraftSuccess) {
  const { messageId } = action.payload
  const { [messageId]: _, ...otherThreads } = state
  return otherThreads
}

function saveDraft(state: State, action: SaveDraftSuccess) {
  const {
    draft: { html, subject, from, to, cc, bcc, responseMessageId },
    messageId,
    threadId,
    oldMessageId,
    largeAttachments,
  } = action.payload

  const { [oldMessageId]: _, ...otherStates } = state

  if (!largeAttachments.length) return otherStates

  return {
    ...otherStates,
    [messageId]: {
      attachments: largeAttachments,
      bcc,
      cc,
      date: moment().unix(),
      from,
      labelIds: [labelNames.drafts],
      messageId,
      snippet: convertHtmlToText(html),
      subject,
      threadId,
      to,
      inReplyTo: responseMessageId,
    },
  }
}

function batchDeleteDraft(state: State, action: BatchDeleteDraftSuccess) {
  return omit(state, Object.keys(action.payload.threadMessageIds))
}

function sendDraft(state: State, action: SendDraftSuccess) {
  const {
    draft: {
      messageId: oldMessageId,
      to,
      cc,
      bcc,
      subject,
      html,
      responseMessageId,
    },
    messageId,
    threadId,
    largeAttachments,
  } = action.payload
  if (!state[oldMessageId]) return state
  const { [oldMessageId]: _, ...otherStates } = state
  if (!largeAttachments.length) return otherStates

  return {
    ...otherStates,
    [messageId]: {
      ...state[oldMessageId],
      messageId,
      threadId,
      attachments: largeAttachments,
      to,
      cc,
      bcc,
      subject,
      snippet: convertHtmlToText(html),
      date: moment().unix(),
      inReplyTo: responseMessageId,
    },
  }
}

export default reducer
