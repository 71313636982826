// @flow
import React, { useMemo, useState, useEffect } from 'react'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Fuse from 'fuse.js'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'
import { routePaths, contactTypes } from 'utils/constants'

import {
  getBlockedContacts,
  getPendingContacts,
  getAcceptedContacts,
} from 'core/contacts/selectors'
import { isSenderCardsDisable } from 'core/settings/selectors'
import { fetchContacts } from 'core/contacts/actions'

import { Layout, Header } from '@edison/webmail-ui/screens/Contacts'
import { Tab } from '@edison/webmail-ui/components/Tabs'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'
import NoResultsFound from '@edison/webmail-ui/components/NoResultsFound'
import { SimpleHelmet } from 'screens/Helmets'
import RouteTabs from 'common/RouteTabs'
import ApprovedContacts from './ApprovedContacts'
import BlockedContacts from './BlockedContacts'
import PendingContacts from './PendingContacts'
import type { Dispatch } from 'types/redux'
import AccountMenu from '../Main/components/AccountMenu'

const acceptedSelector = getAcceptedContacts()
const blockedSelector = getBlockedContacts()
const pendingSelector = getPendingContacts()

const ChildrenSwitch = ({ isLoading, isEmpty, children }) => {
  if (isLoading) {
    return <CircularLoader fullWidth fullHeight />
  } else if (isEmpty) {
    return <NoResultsFound />
  } else return children
}

const debouncedSetInputPendingToFalse = debounce(
  setInputPending => {
    setInputPending(false)
  },
  500,
  { leading: false, trailing: true }
)

const CONTACT_STATUS = {
  approved: 'approved',
  blocked: 'blocked',
  pending: 'pending',
}

const CONTACT_MAPPINGS = {
  [CONTACT_STATUS.approved]: contactTypes.NORMAL,
  [CONTACT_STATUS.blocked]: contactTypes.BLOCK,
  [CONTACT_STATUS.pending]: contactTypes.PENDING,
}

const ContactsScreen = () => {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const subsectionMatch = useRouteMatch(routePaths.contactSection)
  const dispatch: Dispatch = useDispatch()
  const [keyword, setKeyword] = useState('')
  const [inputPending, setInputPending] = useState(false)
  const approved = useSelector(acceptedSelector)
  const blocked = useSelector(blockedSelector)
  const pending = useSelector(pendingSelector)

  const disabledSenderCards = useSelector(isSenderCardsDisable)

  const activeTab = get(subsectionMatch, 'params.activeTab', '')
  const sources = { approved, blocked, pending }
  const data = get(sources, activeTab, [])

  const idList = useContactSearch(data, (keyword || '').trim(), inputPending)
  const isSearchEmpty = !!keyword && data.length !== 0 && idList.length === 0

  const [loadingState, setLoadingState] = useState({})

  useEffect(() => {
    setKeyword('')
    const isLoading = loadingState[activeTab]
    const status = CONTACT_MAPPINGS[activeTab]
    if (!isNil(status) && !isLoading) {
      setLoadingState(state => ({ ...state, [activeTab]: true }))
      dispatch(
        fetchContacts(status, activeTab === CONTACT_STATUS.blocked)
      ).then(() => setLoadingState(state => ({ ...state, [activeTab]: false })))
    }
  }, [activeTab])

  useEffect(() => {
    if ((keyword || '').trim().length > 0 && data.length > 0) {
      setInputPending(true)
      debouncedSetInputPendingToFalse(setInputPending)
    }
  }, [keyword])
  useEffect(() => {
    return () => {
      debouncedSetInputPendingToFalse.cancel()
    }
  }, [])

  const routes = [
    {
      key: CONTACT_STATUS.approved,
      Component: ApprovedContacts,
      label: t('contacts.subsection.approved'),
    },
    {
      key: CONTACT_STATUS.blocked,
      Component: BlockedContacts,
      label: t('contacts.subsection.blocked'),
    },
    {
      key: CONTACT_STATUS.pending,
      Component: PendingContacts,
      label: t('contacts.subsection.pending'),
    },
  ]
    .filter(({ key }) =>
      disabledSenderCards ? key !== CONTACT_STATUS.pending : true
    )
    .map(({ key, ...rest }) => ({ ...rest, path: `${match.url}/${key}` }))

  return (
    <Layout
      header={
        <>
          <Header
            keyword={keyword}
            onChange={e => setKeyword(e.target.value)}
            avatar={<AccountMenu />}
          />
          <RouteTabs>
            {routes.map(({ label, path }) => (
              <Tab key={path} value={path} label={label} />
            ))}
          </RouteTabs>
        </>
      }
    >
      <SimpleHelmet title="Contacts" />
      <ChildrenSwitch isLoading={inputPending} isEmpty={isSearchEmpty}>
        <Switch>
          {routes.map(({ path, Component }) => (
            <Route path={path} render={() => <Component ids={idList} />} />
          ))}
          <Route
            path={match.url}
            render={() => <Redirect to={routes[0].path} />}
          />
        </Switch>
      </ChildrenSwitch>
    </Layout>
  )
}

function useContactSearch(source, keyword, disabled) {
  const list = useMemo(
    () =>
      source.length > 0
        ? new Fuse(source, {
            threshold: 0.1,
            shouldSort: true,
            keys: ['emails.email', 'firstName', 'lastName'],
          })
        : null,
    [source]
  )

  return useMemo(() => {
    if (disabled) {
      return []
    } else if (!!keyword && list) {
      return list.search(keyword).map(item => item.id)
    } else {
      return source.map(item => item.id)
    }
  }, [list, keyword, disabled])
}

export default ContactsScreen
