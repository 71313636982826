// @flow
import React, { useEffect } from 'react'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'

import { PaymentWithCalculator } from '@edison/webmail-ui/components/Stripe'
import BillCalculator from 'common/BillCalculator'
import * as premium from 'core/premium'
import { usePlanDescription } from 'core/premium/hooks'
import stripeInit from './stripe'

import type { Dispatch } from 'types/redux'

type Props = {
  planId: string,
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ planId, isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const isUpdateLoading = useSelector(premium.selectors.getUpdatePlanLoading)
  const isPreviewLoading = useSelector(premium.selectors.getPreviewLoading)
  const previewsById = useSelector(premium.selectors.getPremiumPreviewById)
  const paymentMethod = useSelector(premium.selectors.getPaymentMethod)

  const preview = previewsById[planId]
  const description = usePlanDescription(
    get(preview, 'plan'),
    get(preview, 'dueDate', 0),
    get(preview, 'plan')
  )

  const isLoading = isPreviewLoading || isUpdateLoading

  const onSubmit = async () => {
    toggle()
    await dispatch(premium.actions.changePlan(planId))
    dispatch(premium.actions.fetchCurrentPlan())
    dispatch(premium.actions.fetchPaymentMethod())
  }

  useEffect(() => {
    dispatch(premium.actions.premiumPreview(planId))
  }, [planId])

  return (
    <PaymentWithCalculator
      stripe={stripeInit}
      interval={get(preview, 'plan.interval')}
      description={description}
      onSubmit={onSubmit}
      isOpen={isOpen}
      onCancel={() => !isLoading && isOpen && toggle()}
      toggle={toggle}
      isLoading={isLoading}
      paymentMethod={
        paymentMethod
          ? `${paymentMethod.cardType} ${paymentMethod.lastFourNum}`
          : undefined
      }
      calculator={<BillCalculator planId={planId} />}
    />
  )
}

View.displayName = 'PaymentUpgrade'

export default View
