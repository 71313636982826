// @flow
import PQueue from 'p-queue'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { nudgeStatus, nudgeTypes } from '@edison/webmail-core/utils/constants'
import { updateMessageNudge } from 'core/email-nudges/actions'
import { getUpdateNudgeLoading } from 'core/email-nudges/selectors'
import { reply } from 'core/messages/actions'
import { batchGetThreads } from 'core/threads/actions'
import { getFetchedThreadIds } from 'core/threads/selectors'

import type { StackedProps } from '@edison/webmail-ui/components/Cards/Card'
import Card, {
  CardAction,
  CardSubject,
  INITIAL_CARD_PROPS,
} from '@edison/webmail-ui/components/Cards/Card'
import { getDiffDay } from '@edison/webmail-ui/components/EmailNudge'
import SenderCardSkeleton from '@edison/webmail-ui/components/Skeletons/SenderCard'
import { AuthEmailAvatar } from 'common/AuthAvatar'
import { useModal } from 'common/modals'
import Subject from 'common/ThreadList/Subject'
import { useShowThreadDetail } from 'hooks/useHandleDetailModal'
import type { Dispatch } from 'types/redux'
import { modalTypes } from 'utils/constants'

const queue = new PQueue({ concurrency: 1 })

const nudgeTextTypes = {
  [nudgeTypes.REPLY]: 'nudge.reply_interval',
  [nudgeTypes.FOLLOWUP]: 'nudge.followup_interval',
}

type Props = {
  id: string,
  sender: {
    name: string,
    email: string,
  },
  date: number,
  threadId: string,
  subject: string,
  suggestionType: number,
} & StackedProps

export default ({
  sender,
  date,
  subject,
  id,
  threadId,
  suggestionType,
  ...stackedProps
}: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const isLoading = useSelector(getUpdateNudgeLoading)
  const threadIds = useSelector(getFetchedThreadIds)
  const contactsGalleryModal = useModal(modalTypes.contactsGallery)
  const showThreadDetail = useShowThreadDetail()
  const diffDay = getDiffDay(date)
  const [debounceAction, cancelDebounce] = useDebouncedCallback(
    id => {
      queue.add(() => dispatch(batchGetThreads([id])))
    },
    500,
    []
  )

  useEffect(() => {
    debounceAction(threadId)

    return () => {
      cancelDebounce()
    }
  }, [threadId])

  function handleOnReview() {
    contactsGalleryModal.showModal({
      emailNudge: {
        threadId,
        messageId: id,
      },
      cardView: 'emailNudge',
    })
  }

  const onReply = () => {
    showThreadDetail(threadId)
  }

  const isFetched = useMemo(() => {
    return threadIds.includes(threadId)
  }, [threadIds, threadId])

  const actions = useMemo(
    () => [
      <CardAction
        disabled={isLoading}
        color="secondary"
        onClick={() =>
          dispatch(updateMessageNudge(nudgeStatus.DISMISSED, { threadId }))
        }
      >
        {t('button.dismiss')}
      </CardAction>,
      <CardAction
        disabled={isLoading}
        onClick={() => dispatch(reply(id)).then(onReply)}
      >
        {suggestionType === nudgeTypes.FOLLOWUP
          ? t('button.followup')
          : t('button.reply')}
      </CardAction>,
    ],
    [isLoading, suggestionType, threadId, id]
  )

  const props = useMemo(() => {
    let props = {
      ...INITIAL_CARD_PROPS,
      actions,
      isLoading: !isFetched,
      onClick: handleOnReview,
      subject: (
        <CardSubject>
          <Subject subject={subject} />
        </CardSubject>
      ),
      placeholder: <SenderCardSkeleton />,
      info: {
        avatar: <AuthEmailAvatar email={sender.email} />,
        subtitle: sender.name || sender.email,
        title: t(nudgeTextTypes[suggestionType], {
          postProcess: 'interval',
          count: diffDay,
        }),
      },
    }
    return props
  }, [isFetched, actions, sender, diffDay, suggestionType])

  return <Card {...stackedProps} {...props} />
}
