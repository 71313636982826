// @flow
import React, { useState } from 'react'
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from 'react-redux'

import StripeForm from '@edison/webmail-ui/components/Stripe'
import * as premium from 'core/premium'
import stripeInit from './stripe'
import { formatPriceUnit } from 'utils'
import { createPaymentMethod } from 'utils/stripe'

type Props = {
  planId: string,
  isOpen: boolean,
  toggle: () => void,
  onSubmit: (paymentMethod: Object) => mixed,
}

const View = ({ planId, isOpen, toggle, onSubmit }: Props) => {
  const [isLoading, setLoading] = useState(false)
  const plansById = useSelector(premium.selectors.getPremiumPlansById)
  const plan = plansById[planId]

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={() => !isLoading && isOpen && toggle()}
    >
      <StripeForm
        isNew
        plan={{
          name: plan.name,
          priceUnit: formatPriceUnit(plan),
          renewDate: moment(plan)
            .add(1, plan.interval)
            .format('MMMM D, YYYY'),
        }}
        stripe={stripeInit}
        onSubmit={async ({ stripe, cardElement, name, postalCode }) => {
          setLoading(true)
          try {
            const paymentMethod = await createPaymentMethod(
              stripe,
              cardElement,
              name,
              postalCode
            )
            onSubmit(paymentMethod)
          } catch (e) {
            // TODO: Handle error creating payment method
          } finally {
            setLoading(false)
          }
        }}
        isLoading={!stripeInit || isLoading}
        onCancel={() => !isLoading && isOpen && toggle()}
      />
    </Dialog>
  )
}

View.displayName = 'PaymentSignUp'

export default View
