// @flow
import { createReducer } from 'utils/redux'

import { fetchBetaFeaturesActions } from './actions'

import type {
  FeatureFlags,
  BetaFeatureActions,
  BetaFeatureListSuccess,
} from './types'

export type State = {
  +features: FeatureFlags,
  +newFeatures: FeatureFlags,
}

const initalState: State = {
  features: {},
  newFeatures: {},
}

export default createReducer<State, BetaFeatureActions>(initalState, {
  [fetchBetaFeaturesActions.success.toString()]: fetchBetaFeaturesSuccess,
})

function fetchBetaFeaturesSuccess(
  state: State,
  action: BetaFeatureListSuccess
) {
  const { features, newFeatures } = action.payload

  return {
    features,
    newFeatures,
  }
}
