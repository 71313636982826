// @flow
import values from 'lodash/values'
import fromPairs from 'lodash/fromPairs'
import { createSelector } from 'reselect'

import {
  connectionStatus,
  connectionSyncStatus,
} from '@edison/webmail-core/utils/constants'
import { getAuth } from 'core/auth/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import {
  labelNames,
  retrofitAccountFilter,
  mockedRetrofitAccounts,
} from 'utils/constants'

import type {
  RetrofitAccount,
  RetrofitSyncStatus,
} from '@edison/webmail-core/types/retrofit'
import type { State, Selector } from 'types/state'
import type { State as RetrofitState } from './reducers'

export const getRetrofitState = (state: State) => state.retrofit

/**
 * Returns the list of third-party accounts owned by the user. Ordering will
 * be done at the component layer instead.
 */
export const getAccounts: Selector<
  $ReadOnlyArray<RetrofitAccount>
> = createSelector(getRetrofitState, (state: RetrofitState) => {
  return values(state.accounts)
})

/**
 * Returns the list of thrid-party accounts with `ACTIVE` connection status
 */
export const getConnectedAccounts: Selector<
  $ReadOnlyArray<RetrofitAccount>
> = createSelector(getAccounts, accounts =>
  accounts.filter(
    ({ status }) =>
      status === connectionStatus.ACTIVE || status === connectionStatus.INACTIVE
  )
)

/**
 * Returns the list of third-party accounts with `SOFT_DELETE` connection status
 */
export const getDeletedAccounts: Selector<
  $ReadOnlyArray<RetrofitAccount>
> = createSelector(getAccounts, accounts =>
  accounts.filter(({ status }) => status === connectionStatus.SOFT_DELETE)
)

/**
 * Factory to create a selector that returns a single account's information.
 */
export const makeGetAccount = (ecUUID: string): Selector<RetrofitAccount> =>
  createSelector(getRetrofitState, (state: RetrofitState) => {
    return state.accounts[ecUUID]
  })
/**
 * Returns current account that user has chosen to filter data by.
 *
 * If user did not select a filter account, we return NULL to indicate that
 * no filters should be applied.
 */
export const getActiveAccount: Selector<?RetrofitAccount> = createSelector(
  getRetrofitState,
  getAuth(),
  (state, auth): ?RetrofitAccount => {
    const { filterBy, accounts } = state

    switch (filterBy) {
      case retrofitAccountFilter.ALL:
        return null
      case retrofitAccountFilter.ONMAIL:
        const onmailAccount =
          mockedRetrofitAccounts[retrofitAccountFilter.ONMAIL]

        // Modify the object directly to avoid rerender
        onmailAccount.name = auth ? auth.user : onmailAccount.name
        onmailAccount.emailAddress = auth
          ? auth.user
          : onmailAccount.emailAddress

        return onmailAccount
      default:
        return accounts[filterBy]
    }
  }
)

/**
 * Returns all sync statuses key by ID.
 */
export const getSyncStatuses: Selector<{
  [ecUUID: string]: RetrofitSyncStatus,
}> = createSelector(
  getRetrofitState,
  (state: RetrofitState) => state.sync.accounts
)

export const getSyncProgress: Selector<{
  total: number,
  accounts: { [ecUUID: string]: number },
}> = createSelector(
  getRetrofitState,
  (state: RetrofitState) => state.sync.progress
)

export const isRemoveAccountLoading: Selector<boolean> = getLoadingStatus(
  'RETROFIT_REMOVE_ACCOUNT'
)

/**
 * Accounts that users have not seen the banner yet.
 */
export const getNewAccounts: Selector<
  $ReadOnlyArray<RetrofitAccount>
> = createSelector(getRetrofitState, state => {
  const { sync, accounts } = state
  return values(sync)
    .filter(
      ({ syncInEndFullSync, userConsent }) =>
        syncInEndFullSync === connectionSyncStatus.COMPLETE && !userConsent
    )
    .map(({ ecUUID }) => accounts[ecUUID])
    .filter(Boolean)
})

export const isSyncStatusLoading: Selector<boolean> = getLoadingStatus(
  'RETROFIT_FETCH_SYNC_STATUS'
)

export const isAllAccountsLoading: Selector<boolean> = getLoadingStatus(
  'RETROFIT_FETCH_ALL_ACCOUNTS'
)

/**
 * Returns the list of thrid-party accounts with `ACTIVE` status
 */
export const getActivedAccounts: Selector<
  $ReadOnlyArray<RetrofitAccount>
> = createSelector(getAccounts, accounts =>
  accounts.filter(({ status }) => status === connectionStatus.ACTIVE)
)

export const isSyncProgressPulling: Selector<boolean> = createSelector(
  getRetrofitState,
  state => state.syncStatus.pulling
)

export const isSyncProgressPaused: Selector<boolean> = createSelector(
  getRetrofitState,
  state => state.syncStatus.paused
)

export const getAccountsByLabel: Selector<{
  [labelId: string]: RetrofitAccount,
}> = createSelector(getAccounts, accounts =>
  fromPairs<string, RetrofitAccount>(
    accounts.map(account => [account.labelUUID, account])
  )
)

export function getActiveAccountLabel(activeLabel: string): Selector<string> {
  return createSelector(getActiveAccount, activeAccount => {
    return activeAccount && activeLabel !== labelNames.drafts
      ? activeAccount.labelUUID
      : labelNames.allAccounts
  })
}
