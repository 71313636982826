// @flow
import { Component } from 'react'

import { reportException } from 'utils/logs'

import type { Node } from 'react'

type Props = {
  fallback: ({ eventId: ?string }) => Node,
  children: Node,
  needRefresh?: boolean,
}

type State = {
  eventId: ?string,
  hasError?: boolean,
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  async componentDidCatch(error: any, info: any) {
    // TODO: Detect the error is caused by the caches updated
    if (this.props.needRefresh) {
      window.location.reload()
    } else {
      const eventId = await reportException(error, info)
      this.setState({ eventId })
    }
  }

  render() {
    const { hasError, eventId } = this.state
    const { fallback, children } = this.props

    if (hasError) {
      return fallback({ eventId })
    }

    return children
  }
}

export default ErrorBoundary
