// @flow
import React, { createContext, useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'

import { useOrderId } from 'core/auth/hooks'

import { createFlagStorage } from 'common/storage'
import * as localStorage from 'common/storage/localStorage'
import { tutorialsDecoder, tutorialsEncoder } from './utils'
import { localStorageFlags } from './constants'

import type { Node } from 'react'
import type { UserTutorialFlag } from './constants'

type Props = { children: Node }

type Flags = {
  [key: string]: boolean,
}

type RegisterValueType = {
  value: boolean,
  clear: () => void,
  toggle: () => void,
  set: (value?: any) => void,
}

export type FlagsContextType = {
  flags: Flags,
  setFlags: (flags: Flags) => void,
  register: (flag: string) => RegisterValueType,
}

export const FlagsContext = createContext<FlagsContextType>({})

export const FlagsContextProvider = ({ children }: Props) => {
  const [flags, setFlags] = useState<Flags>({})

  useEffect(() => {
    const handler = e => {
      const { key, newValue } = e
      const value = newValue === '1'

      if (key in flags && value !== flags[key]) {
        setFlags(state => ({ ...state, [key]: value }))
      }
    }
    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [flags])

  const register = (flag: string) => {
    const flagStorage = createFlagStorage(flag)

    const set = () => {
      flagStorage.setFlag()
      setFlags(state => ({ ...state, [flag]: true }))
    }

    const clear = () => {
      if (flagStorage.getFlag()) {
        flagStorage.clearFlag()
        setFlags(state => ({ ...state, [flag]: false }))
      }
    }

    const toggle = () => {
      const currFlagValue = flags[flag]
      if (currFlagValue) {
        flagStorage.clearFlag()
      } else {
        flagStorage.setFlag()
      }
      setFlags({ ...flags, [flag]: !currFlagValue })
    }

    // Returns if the flag is registered
    if (flag in flags) {
      return { value: flags[flag], toggle, set, clear }
    }

    // Register the flag to the context
    const localStorageFlagValue = flagStorage.getFlag()
    setFlags(state => ({ ...state, [flag]: localStorageFlagValue }))
    return { value: localStorageFlagValue, toggle, set, clear }
  }

  const value = {
    flags,
    setFlags,
    register,
  }

  return <FlagsContext.Provider value={value}>{children}</FlagsContext.Provider>
}

type UserTutorialValues = {
  flags: { [UserTutorialFlag]: boolean },
  setFlags: ({ [UserTutorialFlag]: boolean }) => void,
}

export const UserTutorialContext = createContext<UserTutorialValues>({})

export const UserTutorialContextProvider = ({ children }: Props) => {
  const orderId = useOrderId()
  const [flags, setFlags] = useState<{ [UserTutorialFlag]: boolean }>({})

  const getPersistedState = () => {
    try {
      const state = localStorage.get(localStorageFlags.userTutorialKey)

      return JSON.parse(state || {})
    } catch (e) {
      return {}
    }
  }

  const persistState = (input: string) => {
    if (orderId) {
      try {
        const { [orderId]: _, ...restState } = getPersistedState()

        if (isEmpty(input) && isEmpty(restState)) {
          localStorage.clear(localStorageFlags.userTutorialKey)
        } else {
          const state = JSON.stringify({
            ...restState,
            [orderId]: input || undefined,
          })
          localStorage.set(localStorageFlags.userTutorialKey, state)
        }
      } catch (e) {}
    }
  }

  useEffect(() => {
    function handler(e) {
      if (!e.key || e.key === localStorageFlags.userTutorialKey) {
        if (orderId) {
          const state = getPersistedState()

          const mask = state[orderId]
          setFlags(tutorialsDecoder(mask))
        }
      }
    }

    handler({})

    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [orderId])

  const handleOnChange = flags => {
    setFlags(flags)

    const mask = tutorialsEncoder(flags)
    persistState(mask)
  }

  return (
    <UserTutorialContext.Provider value={{ flags, setFlags: handleOnChange }}>
      {children}
    </UserTutorialContext.Provider>
  )
}
