// @flow
import moment from 'moment'
import omit from 'lodash/omit'
import toPairs from 'lodash/toPairs'
import { createReducer } from 'utils/redux'
import { composeStatus, COMPOSE_DEFAULT_HTML } from 'utils/constants'
import * as actions from './actions'
import { metadataHistory } from 'core/metadata/actions'
import type { MetadataHistory } from 'core/metadata/types'
import type {
  ComposeActions,
  FetchDraftSuccess,
  SaveDraftSuccess,
  SaveDraftFailure,
  SendDraftSuccess,
  SendDraftFailure,
  DeleteDraftSuccess,
  DeleteDraftFailure,
  UpdateDraft,
  RemoveDraft,
  CreateDraft,
  ActiveDraft,
  AfterDeleteDraftFailure,
  AfterSendDraftFailure,
  BeforeSendDraft,
  BatchDeleteDraftRequest,
  UndoSendDraftSuccess,
  DelaySendDraftSuccess,
  DelaySendDraftFailure,
} from './types'

import type { MessageListSuccess, BatchGetSuccess } from 'core/messages/types'

import type { State } from './types'
import { fetchThreadActions, batchGetActions } from '../messages/actions'

const initialState: State = {}
type Action = ComposeActions

export default createReducer<State, Action>(initialState, {
  [actions.fetchDraftActions.success.toString()]: fetchDraftSuccess,
  [actions.deleteDraftActions.success.toString()]: deleteDraftSuccess,
  [actions.deleteDraftActions.failure.toString()]: deleteDraftFailure,
  [actions.saveDraftActions.request.toString()]: saveDraftRequest,
  [actions.saveDraftActions.success.toString()]: saveDraftSuccess,

  [actions.saveDraftActions.failure.toString()]: saveDraftFailure,
  [actions.sendDraftActions.success.toString()]: sendDraftSuccess,
  [actions.sendDraftActions.failure.toString()]: sendDraftFailure,

  [actions.createDraft.toString()]: createDraft,
  [actions.removeDraft.toString()]: removeDraft,
  [actions.updateDraft.toString()]: updateDraft,
  [actions.activeDraft.toString()]: activeDraft,

  [actions.beforeSendDraft.toString()]: beforeSendDraft,
  [actions.beforeDeleteDraft.toString()]: beforeDeleteDraft,
  [actions.afterDeleteDraftFailure.toString()]: afterDeleteDraftFailure,
  [actions.afterSendDraftFailure.toString()]: afterSendDraftFailure,

  [actions.delaySendDraftActions.success.toString()]: delaySendDraftSuccess,
  [actions.delaySendDraftActions.failure.toString()]: delaySendDraftFailure,

  [actions.undoSendDraftActions.success.toString()]: undoSendDraftSuccess,

  [actions.batchDeleteDraftActions.request.toString()]: batchDeleteDrafts,
  // messages

  [fetchThreadActions.success.toString()]: fetchThread,
  [batchGetActions.success.toString()]: batchGetMessages,
  [metadataHistory.toString()]: clearDraftByHistory,
})

// Compose reducers
function clearDraftByHistory(state: State, action: MetadataHistory) {
  const { deletedMessages } = action.payload
  let toRemove = []

  for (let [key, draft] of toPairs(state)) {
    if (deletedMessages.includes(draft.messageId)) {
      toRemove.push(key)
    }
  }

  return omit(state, toRemove)
}

function updateDraft(state: State, action: UpdateDraft) {
  const { id, ...props } = action.payload
  if (!state[id]) return state

  //sync the status with same draft.
  const draft = state[id]

  let needUpdated = false
  for (let key in props) {
    if (draft[key] !== props[key]) {
      needUpdated = true
    }
  }
  if (!needUpdated) return state

  if (
    draft.threadId !== id &&
    state[draft.threadId] &&
    !state[draft.threadId].responseMessageId
  ) {
    state[draft.threadId] = {
      ...state[draft.threadId],
      ...props,
      saved: props.saved || false,
      updated: true,
    }
  }
  return {
    ...state,
    [id]: {
      ...state[id],
      ...props,
      saved: props.saved || false,
      updated: true,
    },
  }
}

function createDraft(state: State, action: CreateDraft) {
  const { id, threadId, responseMessageId } = action.payload
  const inactivedIds = responseMessageId
    ? Object.keys(state).filter(
        draftId => draftId !== id && state[draftId].threadId === threadId
      )
    : []
  return {
    ...state,
    ...inactivedIds.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: {
          ...state[curr],
          actived: false,
        },
      }),
      {}
    ),
    [id]: {
      id,
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      actived: true,
      saved: false,
      attachmentUuids: [],
      largeAttachmentUuids: [],
      ...action.payload,
      //default html can't be empty string avoid parse error
      html: action.payload.html || COMPOSE_DEFAULT_HTML,
    },
  }
}

function removeDraft(state: State, action: RemoveDraft) {
  const draftId = action.payload
  if (!state[draftId]) return state
  return {
    ...state,
    [draftId]: {
      ...state[draftId],
      status: composeStatus.deleted,
    },
  }
}

function fetchDraftSuccess(state: State, action: FetchDraftSuccess) {
  const { draft } = action.payload
  return {
    ...state,
    [draft.id]: draft,
  }
}

function saveDraftRequest(state: State, action: SaveDraftRequest) {
  const { draftId } = action.payload
  if (!state[draftId] || isPendingStatus(state[draftId].status)) {
    return state
  }
  return syncDraftStatus(state, draftId, {
    status: composeStatus.saving,
    error: null,
    updated: false,
  })
}

function saveDraftSuccess(state: State, action: SaveDraftSuccess) {
  const {
    draft: { id, ...otherDraftProps },

    oldDraftId,
  } = action.payload

  const { updated } = state[id] || state[oldDraftId]

  const prevDraft = state[oldDraftId]

  if (oldDraftId && oldDraftId !== id) {
    state[oldDraftId] = {
      ...prevDraft,
      id: oldDraftId,
      ...otherDraftProps,
      status: isPendingStatus(prevDraft.status)
        ? prevDraft.status
        : composeStatus.saved,
      saved: updated ? false : true,
    }
  }

  return {
    ...state,

    [id]: {
      ...prevDraft,

      id,
      ...otherDraftProps,
      status: isPendingStatus(prevDraft.status)
        ? prevDraft.status
        : composeStatus.saved,
      saved: updated ? false : true,
    },
  }
}

function saveDraftFailure(state: State, action: SaveDraftFailure) {
  const { draftId, error } = action.payload

  return syncDraftStatus(state, draftId, { status: null, error })
}

function deleteDraftSuccess(state: State, action: DeleteDraftSuccess) {
  const { draftId } = action.payload
  return syncDraftStatus(state, draftId, {
    status: composeStatus.deleted,
    error: null,
  })
}

function deleteDraftFailure(state: State, action: DeleteDraftFailure) {
  const { draftId, error } = action.payload

  return syncDraftStatus(state, draftId, { status: null, error })
}

function sendDraftSuccess(state: State, action: SendDraftSuccess) {
  const {
    draft: { id },
    threadId,
  } = action.payload
  return syncDraftStatus(state, id, {
    status: composeStatus.sended,
    error: null,
    sentThreadId: threadId,
  })
}
function sendDraftFailure(state: State, action: SendDraftFailure) {
  const { draftId, error } = action.payload
  return syncDraftStatus(state, draftId, { status: null, error })
}

function fetchThread(state: State, action: MessageListSuccess) {
  const { drafts } = action.payload

  return {
    ...state,
    ...drafts.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr,
      }),
      {}
    ),
  }
}

function batchGetMessages(state: State, action: BatchGetSuccess) {
  const { drafts } = action.payload

  return {
    ...state,
    ...drafts.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.id]: curr,
      }),
      {}
    ),
  }
}

function activeDraft(state: State, action: ActiveDraft) {
  const id = action.payload
  if (!state[id]) return state
  const { threadId } = state[id]

  const inactivedIds = Object.keys(state).filter(
    draftId => draftId !== id && state[draftId].threadId === threadId
  )
  return {
    ...state,
    ...inactivedIds.reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: {
          ...state[curr],
          actived: false,
        },
      }),
      {}
    ),
    [id]: {
      ...state[id],
      actived: true,
    },
  }
}

function beforeSendDraft(state: State, action: BeforeSendDraft) {
  const { draftId } = action.payload
  return syncDraftStatus(state, draftId, {
    status: composeStatus.sending,
    error: null,
  })
}

function beforeDeleteDraft(state: State, action: BeforeDeleteDraft) {
  const { draftId } = action.payload
  return syncDraftStatus(state, draftId, {
    status: composeStatus.deleting,
    error: null,
  })
}

function afterDeleteDraftFailure(
  state: State,
  action: AfterDeleteDraftFailure
) {
  const { draftId } = action.payload
  return syncDraftStatus(state, draftId, { status: null, error: null })
}

function afterSendDraftFailure(state: State, action: AfterSendDraftFailure) {
  const { draftId } = action.payload
  return syncDraftStatus(state, draftId, { status: null, error: null })
}

function isPendingStatus(status) {
  return [
    composeStatus.sending,
    composeStatus.deleting,
    composeStatus.sended,
    composeStatus.deleted,
  ].includes(status)
}

function syncDraftStatus(state, draftId, props) {
  if (!state[draftId]) return state
  const { threadId } = state[draftId]
  if (
    draftId !== threadId &&
    state[threadId] &&
    !state[threadId].responseMessageId
  ) {
    state[threadId] = {
      ...state[threadId],
      ...props,
    }
  }
  return {
    ...state,
    [draftId]: {
      ...state[draftId],
      ...props,
    },
  }
}

function delaySendDraftSuccess(
  state: State,
  action: DelaySendDraftSuccess
): State {
  const { schedulerId, draftId } = action.payload
  return syncDraftStatus(state, draftId, {
    status: composeStatus.sended,
    error: null,
    schedulerId,
    date: moment().unix(),
  })
}

function delaySendDraftFailure(
  state: State,
  action: DelaySendDraftFailure
): State {
  const { draftId, error } = action.payload
  return syncDraftStatus(state, draftId, {
    status: null,
    error,
  })
}

function undoSendDraftSuccess(
  state: State,
  action: UndoSendDraftSuccess
): State {
  const { draftId } = action.payload

  return syncDraftStatus(state, draftId, {
    status: null,
    error: null,
    schedulerId: null,
  })
}

function batchDeleteDrafts(state: State, action: BatchDeleteDraftRequest) {
  const { draftIds } = action.payload
  return {
    ...state,
    ...draftIds
      .map(id => ({ ...state[id], status: composeStatus.deleted, error: null }))
      .reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
  }
}
