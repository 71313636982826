// @flow
import React from 'react'
import moment from 'moment-timezone'
import { modalTypes } from 'utils/constants'

import { useModal } from 'common/modals'
import {
  useCurrentBreak,
  useScheduledBreaks,
  useScheduledBreak,
} from 'core/inbox-break/hooks'

import { ScheduledBreakForm } from '@edison/webmail-ui/components/InboxBreak'

type Props = {
  isOpen: boolean,
  toggle: () => void,
  id?: string,
}

const View = ({ isOpen, toggle, id }: Props) => {
  const timezoneModal = useModal(modalTypes.timezoneConfirm)
  const { onFetchStatus } = useCurrentBreak()
  const { onCreate, isCreating } = useScheduledBreaks()

  const { schedule, isUpdating, onUpdate, onRemove } = useScheduledBreak(id)

  const isEdit = !!schedule
  const isLoading = isCreating || isUpdating

  function handleOnSubmit(values) {
    const currTimezone = moment.tz.guess()
    const handler = isEdit ? onUpdate : onCreate

    const timezoneConfirm = new Promise(resolve => {
      if (isEdit && values.timezone !== currTimezone) {
        timezoneModal.showModal({
          timezone: values.timezone,
          onClose: () => {
            timezoneModal.hideModal()
            resolve()
          },
          onConfirm: () => {
            timezoneModal.hideModal()
            resolve(currTimezone)
          },
        })
        return
      } else {
        resolve()
      }
    })

    timezoneConfirm
      .then((timezone = values.timezone || currTimezone) =>
        handler({ ...values, timezone })
      )
      .then(success => {
        // Close the modal on success
        if (success) {
          toggle()
          onFetchStatus()
        }
      })
  }

  function handleOnRemove() {
    toggle()
    onRemove()
  }

  return (
    <ScheduledBreakForm
      open={isOpen}
      onClose={toggle}
      isEdit={!!schedule}
      isLoading={isLoading}
      initialValues={schedule}
      onRemove={handleOnRemove}
      onSubmit={handleOnSubmit}
    />
  )
}

View.displayName = 'ScheduledBreakModal'

export default View
