//@flow

import RRule, { Frequency } from '@ruoxijiang/rrule'
import { getMonthRecurrenceDay, getYearRecurrenceDay } from '@edison/datetime'
import type Moment from 'moment'

export const updateRepeatDay = (rruleOptions: any, m: Moment): string => {
  if (rruleOptions.freq === Frequency.MONTHLY) {
    const day = getMonthRecurrenceDay(m.utc())
    if (day >= -1 && day <= 1) {
      rruleOptions.bymonthday = day
      return new RRule(rruleOptions).toString()
    }
  } else if (rruleOptions.freq === Frequency.YEARLY) {
    const day = getYearRecurrenceDay(m.utc())
    if (day >= -1 && day <= 1) {
      rruleOptions.byyearday = day
      return new RRule(rruleOptions).toString()
    }
  }
  return rruleOptions
}
