export class MfaError extends Error {
  constructor(message: string, token, digits, method) {
    super(message)
    this.token = token
    this.digits = digits
    this.method = +method
  }
}

export class InvalidMfaCodeError extends Error {}
