// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getThread } from 'core/threads/selectors'

import { AuthHelmet } from './index'

type Props = {
  threadId: string,
}
const View = ({ threadId }: Props) => {
  const { t } = useTranslation()
  const threadSelector = useMemo(() => getThread(threadId), [threadId])
  const thread = useSelector(threadSelector)

  if (!thread) {
    return null
  }

  const { subject } = thread
  return (
    <AuthHelmet>
      <title>{subject || t('thread.subject.none')}</title>
    </AuthHelmet>
  )
}

View.displayName = 'ThreadHelmet'

export default View
