// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type {
  State,
  ShowToast,
  HideToast,
  ToastActions,
  CleanUpToasts,
  CleanHiddenToasts,
} from './types'

const initialState: State = []

export default createReducer<State, ToastActions>(initialState, {
  [actions.show.toString()]: showToast,
  [actions.hide.toString()]: hideToast,
  [actions.cleanHidden.toString()]: cleanHiddenToasts,
  [actions.cleanUp.toString()]: cleanUpToasts,
})

function showToast(state: State, action: ShowToast) {
  const { key, props = {} } = action.payload
  return [...state, { key, props, isOpen: true }]
}

function hideToast(state: State, action: HideToast) {
  const { key } = action.payload
  return state
    .filter(({ isOpen }) => isOpen)
    .map(each => {
      const isOpen = each.key === key ? false : each.isOpen
      return {
        ...each,
        isOpen,
      }
    })
}

function cleanHiddenToasts(state: State, action: CleanHiddenToasts) {
  return state.filter(each => each.isOpen)
}

function cleanUpToasts(state: State, action: CleanUpToasts) {
  return initialState
}
