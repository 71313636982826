// @flow
import mapValues from 'lodash/mapValues'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RecommendOptionType } from '@edison/webmail-ui/components/Search/Recommend'
import { BageType } from '@edison/webmail-ui/components/Search/SearchBadge'
import {
  clearRecentSearch,
  clearViewEmailsById,
  getRecentSearch,
  getRecentViewEmails,
  setRecentSearch,
  setRecentViewEmails,
} from 'common/storage'
import * as analytics from 'core/analytics/actions'
import { getFilteredContacts } from 'core/contacts/selectors'
import * as labelSelectors from 'core/labels/selectors'
import { getCustomLabels } from 'core/labels/selectors'
import { getThreadLabelIdsMap } from 'core/metadata/selectors'
import { useCurrentActiveAccount } from 'core/retrofit/hooks'
import {
  getAccounts,
  getActiveAccount,
  getDeletedAccounts,
} from 'core/retrofit/selectors'
import * as splitInboxesSelectors from 'core/split-inboxes/selectors'
import * as threadActions from 'core/threads/actions'
import { useNoActionFlags, useSearchActionFlags } from 'core/threads/hooks'
import * as threadSelectors from 'core/threads/selectors'
import intersection from 'lodash/intersection'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import { useParams } from 'react-router-dom'
import { useGetRecipientAvatar } from 'screens/Search/Pill/EmailPill'
import type { Dispatch } from 'types/redux'
import {
  displayTitles,
  labelNames,
  priceAlertLabelNames,
  retrofitAccountFilter,
  searchPrefixes,
  siftLabelNames,
} from 'utils/constants'
import * as actions from './actions'
import { getIsInitalCondition, initalCondition, isMatchQuery } from './helpers'
import * as selectors from './selectors'

/**
 * Populate the search prefixes with retrofit accounts
 */
export const useSearchPrefixes = (): ({
  prefixes: $ReadOnlyArray<{
    key: string,
    value: string,
    label: string,
    onClick: () => void,
  }>,
}) => {
  const dispatch: Dispatch = useDispatch()
  const activeAccount = useSelector(getActiveAccount)
  const deletedAccounts = useSelector(getDeletedAccounts)
  const allRetrofitAccounts = useSelector(getAccounts)
  const activePrefix = useSelector(selectors.getSearchPrefix())

  let retrofitPrefix = ''
  if (activeAccount) {
    if (activeAccount.ecUUID === retrofitAccountFilter.ONMAIL) {
      retrofitPrefix = allRetrofitAccounts
        .map(({ labelUUID }) => `(NOT label:${labelUUID})`)
        .join(' AND ')
    } else {
      retrofitPrefix = `(label:${activeAccount.labelUUID})`
    }
  } else {
    retrofitPrefix = deletedAccounts
      .map(({ labelUUID }) => `(NOT label:${labelUUID})`)
      .join(' AND ')
  }

  const setPrefix = prefix => {
    dispatch(actions.setPrefix(prefix))
    dispatch(analytics.search.userSearchSetPrefix(prefix.value))
  }

  const prefixes = mapValues(searchPrefixes, ({ value, ...rest }) => {
    const prefix = {
      ...rest,
      value: [retrofitPrefix, value].filter(Boolean).join(' AND '),
    }
    return {
      ...prefix,
      onClick: () => setPrefix(prefix),
    }
  })

  useEffect(() => {
    const next = prefixes[activePrefix.key]

    if (next.value !== activePrefix.value) {
      setPrefix(next)
    }
  }, [retrofitPrefix])

  return {
    prefixes: [
      prefixes.emails,
      prefixes.attachments,
      prefixes.travel,
      prefixes.receipt,
    ].filter(({ key }) => key !== activePrefix.key),
  }
}

const getActiveLabel = threadSelectors.getActiveLabel()
const getSplitInboxLabels = splitInboxesSelectors.getSplitInboxLabels()
const getLabelById = labelSelectors.getLabelNamesById()
const getSearchQuery = selectors.getSearchQuery()
const getSelectContactQuery = selectors.getSelectContactQuery()
const getSearchCondition = selectors.getSearchCondition()
const getSelectedLabels = selectors.getSelectedLabels()
const getSearchThreadIds = selectors.getSearchThreadIds()
export const MAX_RECOMEND_SEARCH_COUNT = 3

export function useIsDisableSpilt() {
  const activeLabel = useSelector(getActiveLabel)
  const condition = useSelector(getSearchCondition)
  const selectedLabels = useSelector(getSelectedLabels)
  const disableFolder = [labelNames.trash, labelNames.archive, labelNames.spam]
  if (!disableFolder.includes(activeLabel)) {
    return false
  }
  if (!selectedLabels.length && !condition.label.length) {
    return true
  }
  return false
}

export function useGetLabelName() {
  const activeLabel = useSelector(getActiveLabel)
  const labelNameById = useSelector(getLabelById)
  const isInbox = useIsInbox()
  let labelName = ''
  if (
    isInbox ||
    !!values(siftLabelNames).includes(activeLabel) ||
    !!values(priceAlertLabelNames).includes(activeLabel)
  ) {
  } else if (activeLabel in displayTitles) {
    labelName = displayTitles[activeLabel]
  } else {
    labelName = labelNameById[activeLabel]
  }
  return labelName
}

export function useGetPlaceholder() {
  const selectContactQuery = useSelector(getSelectContactQuery)
  const selectedLabels = useSelector(getSelectedLabels)
  const labelName = useGetLabelName()
  const placeholder = !!labelName ? `Search in ${labelName}` : 'Search'
  return selectContactQuery.length || selectedLabels.length ? '' : placeholder
}

export function useIsInbox() {
  const activeLabel = useSelector(getActiveLabel)
  const splitInboxLabels = useSelector(getSplitInboxLabels)
  return (
    activeLabel === labelNames.inbox ||
    activeLabel === labelNames.primary ||
    activeLabel === labelNames.other ||
    splitInboxLabels.includes(activeLabel)
  )
}

export function useMatchContacts() {
  const contacts = useSelector(getFilteredContacts())
  const query = useSelector(getSearchQuery)
  const selectContactQuery = useSelector(getSelectContactQuery)
  const selectContactQueryEmails = selectContactQuery.map(item => item.email)
  if (!query.trim().length) return []
  return contacts
    .filter(
      item => isMatchQuery(item.name, query) || isMatchQuery(item.email, query)
    )
    .filter(item => !selectContactQueryEmails.includes(item.email))
}

export function useMatchLabels() {
  const query = useSelector(getSearchQuery)
  const selectedLabels = useSelector(getSelectedLabels)
  const labels = useGetSearchMenuLabel()
  const labelsName = selectedLabels.map(item => item.name)
  if (!query.trim().length) return []
  return labels
    .filter(item => isMatchQuery(item.name, query))
    .filter(item => !labelsName.includes(item.name))
}

export function useMatchRecentSearch() {
  const recentSearch = getRecentSearch()
  const query = useSelector(getSearchQuery)
  const selectContactQuery = useSelector(getSelectContactQuery)
  const selectedLabels = useSelector(getSelectedLabels)
  if ((selectContactQuery.length || selectedLabels.length) && !query) {
    return []
  }
  return recentSearch.filter(
    item => item.content !== query && isMatchQuery(item.content, query)
  )
}

export function useMatchResult() {
  const query = useSelector(getSearchQuery)
  const matchContacts = useMatchContacts()
  const matchLabels = useMatchLabels()
  const selectContactQuery = useSelector(getSelectContactQuery)
  const selectedLabels = useSelector(getSelectedLabels)
  const recentSearch = useMatchRecentSearch()
  const getRecipientAvatar = useGetRecipientAvatar()
  if (
    !query.trim().length &&
    !selectedLabels.length &&
    !selectContactQuery.length
  ) {
    return {
      matchRecentSearch: recentSearch
        .slice(0, MAX_RECOMEND_SEARCH_COUNT)
        .map(item => ({ item, type: RecommendOptionType.Query })),
      matchContacts: [],
      matchLabels: [],
    }
  }
  return {
    matchRecentSearch: recentSearch
      .slice(0, MAX_RECOMEND_SEARCH_COUNT)
      .map(item => ({ item, type: RecommendOptionType.Query })),
    matchContacts: matchContacts
      .slice(0, MAX_RECOMEND_SEARCH_COUNT)
      .map(item => ({
        ...item,
        id: item.email,
        avatar: getRecipientAvatar(item, '!w-12 !h-12'),
      }))
      .map(item => ({ item, type: RecommendOptionType.Contact })),
    matchLabels: matchLabels
      .slice(0, MAX_RECOMEND_SEARCH_COUNT)
      .map(item => ({
        id: item.id,
        content: item.name,
      }))
      .map(item => ({ item, type: RecommendOptionType.Label })),
  }
}

export function useIsShowRecentViewEmails() {
  const { recentViewEmails } = useCache()
  const isInbox = useIsInbox()
  return recentViewEmails.ids.length && isInbox
}

const systemLabels = [
  labelNames.inbox,
  labelNames.archive,
  labelNames.sent,
  labelNames.drafts,
  labelNames.trash,
  labelNames.spam,
].map(labelName => ({
  id: labelName,
  value: labelName,
  name: displayTitles[labelName],
}))

export function useGetLabelOptions() {
  const customLabels = useSelector(getCustomLabels())
  const allLabels = [...systemLabels, ...customLabels]
  return useMemo(() => allLabels, [customLabels])
}

export function useGetSearchMenuLabel() {
  const activeLabel = useSelector(getActiveLabel)
  const labelOptions = useGetLabelOptions()
  const isInbox = useIsInbox()
  return useMemo(
    () =>
      isInbox
        ? labelOptions
        : labelOptions.filter(item => item.id !== activeLabel),
    [isInbox, labelOptions, activeLabel]
  )
}

export function useIsExistQuery() {
  const query = useSelector(getSearchQuery)
  const condition = useSelector(getSearchCondition)
  const initalCondition = useGetInitalCondition()
  const selectedLabels = useSelector(getSelectedLabels)
  const selectContactQuery = useSelector(getSelectContactQuery)
  const isInitalCondition = getIsInitalCondition(condition, initalCondition)
  const isExistQuery =
    !isInitalCondition ||
    !!query ||
    !!selectContactQuery.length ||
    !!selectedLabels.length
  return isExistQuery
}

export function useSearchBadge() {
  const dispatch = useDispatch()
  const query = useSelector(getSearchQuery)
  const condition = useSelector(getSearchCondition)
  const isInitalCondition = getIsInitalCondition(condition)
  const selectContactQuery = useSelector(getSelectContactQuery)
  const selectedLabels = useSelector(getSelectedLabels)
  const getRecipientAvatar = useGetRecipientAvatar()
  const isInbox = useIsInbox()
  const { recentViewEmails } = useCache()
  const options = useMemo(() => {
    return [
      ...selectContactQuery.map(contact => ({
        item: {
          ...contact,
          avater: getRecipientAvatar(contact, '!w-8 !h-8 absolute left-3'),
        },
        type: BageType.Contact,
      })),
      ...selectedLabels.map(label => ({ item: label, type: BageType.Label })),
    ]
  }, [selectContactQuery, selectedLabels])

  const onDeleteContact = useCallback(
    contact => {
      const isExistOtherQuery =
        !isInitalCondition || selectedLabels.length || query.length
      dispatch(actions.setSelectContactQuery({ contact, isAdd: false }))
      const isResetDefaultEmails =
        isInbox && !isExistOtherQuery && selectContactQuery.length === 1
      if (isResetDefaultEmails) {
        dispatch(threadActions.resetSelectThread())
        dispatch(actions.setSearchThreadIds({ ids: recentViewEmails.ids }))
      } else {
        dispatch(actions.searchThreads(query, {}, condition))
      }
    },
    [query, selectedLabels, isInitalCondition, isInbox]
  )

  const onDeleteLabels = useCallback(
    label => {
      const isExistOtherQuery =
        !isInitalCondition || selectContactQuery.length || query.length
      const isResetDefaultEmails =
        isInbox && !isExistOtherQuery && selectedLabels.length === 1
      dispatch(actions.setSelectedLabels({ ...label, isAdd: false }))
      if (isResetDefaultEmails) {
        dispatch(threadActions.resetSelectThread())
        dispatch(actions.setSearchThreadIds({ ids: recentViewEmails.ids }))
      } else {
        dispatch(actions.searchThreads(query, {}, condition))
      }
    },
    [[query, selectedLabels, isInitalCondition, isInbox]]
  )

  const onClose = useCallback(
    (item, type) => {
      if (type === BageType.Contact) {
        onDeleteContact(item)
      } else {
        onDeleteLabels(item)
      }
    },
    [query]
  )
  return { options, onClose }
}

export function useCache() {
  const { userId } = useParams()
  const orderId = isNil(userId) ? 0 : userId
  return {
    cacheViewEmail: id => setRecentViewEmails(id, orderId),
    recentViewEmails: getRecentViewEmails(orderId),
    cacheSearchQuery: query => setRecentSearch(query, orderId),
    recentSearchQuery: getRecentSearch(orderId),
    clearSearchQuery: query => clearRecentSearch(query, orderId),
    clearViewEmails: threadIds => clearViewEmailsById(orderId, threadIds),
  }
}

export function useQueryLabels() {
  const condition = useSelector(getSearchCondition)
  const selectedLabels = useSelector(getSelectedLabels)
  return [...condition.label, ...selectedLabels.map(item => item.id)]
}

export function useIsOnlyQueryDraftLabel() {
  const queryLabels = useQueryLabels()
  return queryLabels.length === 1 && queryLabels.includes(labelNames.drafts)
}

export function useHasQuerySpamLabel() {
  const queryLabels = useQueryLabels()
  return queryLabels.includes(labelNames.spam)
}

export function useGetInitalCondition() {
  const currentActiveAccount = useCurrentActiveAccount()
  if (!currentActiveAccount.length) {
    return initalCondition
  }
  return {
    ...initalCondition,
    accountLabel: currentActiveAccount.map(item => item.labelUUID),
  }
}

export function useGetSearchActionFlags() {
  const isExistQuery = useIsExistQuery()
  const isInbox = useIsInbox()
  const searchActions = useSearchActionFlags()
  const noActions = useNoActionFlags()
  if (!isExistQuery && isInbox) {
    return noActions
  }
  return searchActions
}

export function useCurrentQueryLabels() {
  const condition = useSelector(getSearchCondition)
  const selectedLabels = useSelector(getSelectedLabels)
  const activeLabel = useSelector(getActiveLabel)
  const isInbox = useIsInbox()
  const queryLabels = [
    ...condition.label,
    ...selectedLabels.map(item => item.id),
  ]
  if (isInbox && !queryLabels.length) {
    return []
  }
  return queryLabels.length ? queryLabels : [activeLabel]
}

const filterLabelId = [labelNames.trash, labelNames.spam]

export function useGetThreads() {
  const isInbox = useIsInbox()
  const isExistQuery = useIsExistQuery()
  const resultIds = useSelector(getSearchThreadIds)
  const currentQueryLabels = useCurrentQueryLabels()
  const searchThreads = useSelector(
    useMemo(() => threadSelectors.getThreadsByIds(resultIds), [resultIds])
  )
  const { ids = [] } = getRecentViewEmails()
  const cacheThreads = useSelector(
    useMemo(() => threadSelectors.getThreadsByIds(ids), [ids])
  )
  const threadLabelIds = useSelector(getThreadLabelIdsMap())

  if (!isExistQuery && isInbox) {
    return cacheThreads
  }

  if (!currentQueryLabels.length) {
    return searchThreads.filter(thread => {
      const threadLabelId = threadLabelIds[thread.id] || []
      if (
        threadLabelId.includes(labelNames.spam) ||
        threadLabelId.includes(labelNames.trash)
      ) {
        return false
      }
      return true
    })
  }
  const queryhasSpamOrTrash = intersection(currentQueryLabels, filterLabelId)
  return searchThreads.filter(thread => {
    const threadLabelId = threadLabelIds[thread.id] || []
    const threadHasSpamoOrTrash = intersection(threadLabelId, filterLabelId)
    if (!queryhasSpamOrTrash.length && threadHasSpamoOrTrash.length) {
      return false
    }
    if (threadLabelId.some(labelId => currentQueryLabels.includes(labelId))) {
      return true
    }
    return false
  })
}
