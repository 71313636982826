// @flow
import type { Contact } from '@edison/webmail-core/types/contacts'
import type { SplitInbox } from '@edison/webmail-core/types/split-inboxes'
import { GalleryCleanup } from '@edison/webmail-ui/components/ContactsGallery/GalleryBody'
import type { GalleryView } from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import GalleryModal, {
  Header,
} from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import * as actions from 'core/contacts/actions'
import { getFormattedContact } from 'core/contacts/helpers'
import { useAllCard, useNextCardContact } from 'core/contacts/hooks'
import * as selectors from 'core/contacts/selectors'
import { userTutorialFlags } from 'core/flags/constants'
import { useUserTutorial } from 'core/flags/hooks'
import { show as showModal } from 'core/modals/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EmailNudgeCard from 'screens/EmailNudgeCard/Modal'
import SuggestedBlockCard from 'screens/SuggestedBlockCard/Modal'
import CleanupCard from 'screens/SuggestedCleanup/Calendar'
import type { Dispatch } from 'types/redux'
import { getDomainByEmail } from 'utils'
import { modalTypes, cardTypes } from 'utils/constants'
import GalleryBody from './GalleryBody'
import ListBody from './GalleryList'
import { galleryViewMap } from './constant'

const getPendingContacts = selectors.getPendingContacts()
const contactStatusUpdatingSelector = selectors.isContactStatusUpdating()

type Props = {
  contactId?: string,
  isOpen: boolean,
  onClose: () => any,
  emailNudge?: string,
  cardView: GalleryView,
  suggestBlockContact?: {
    id: string,
    emails: string,
  },
  cleanup?: {
    type: string,
  },
}

export default ({
  contactId,
  emailNudge,
  suggestBlockContact,
  isOpen,
  cleanup,
  onClose,
  cardView = 'gallery',
}: Props) => {
  const dispatch: Dispatch = useDispatch()
  const [selected, setSelected] = useState(new Set())
  const [selectAll, setSelectAll] = useState(false)
  const [view, setView] = useState<GalleryView>(cardView)
  const isContactStatusUpdating = useSelector(contactStatusUpdatingSelector)
  const allCard = useAllCard()
  const { hasNextCard, nextCardData, nextGalleryView } = useNextCardContact(
    cardTypes.senders
  )
  const [
    {
      currentContact,
      currentEmailNudge,
      currentSuggestBlockContact,
      currentCleanup,
    },
    setCurrentCard,
  ] = useState({
    currentContact: {
      id: contactId,
    },
    currentEmailNudge: emailNudge,
    currentSuggestBlockContact: suggestBlockContact,
    currentCleanup: cleanup,
  })
  const pendings = useSelector(getPendingContacts)
  const contactsById = useSelector(selectors.getContactsById)
  const tutorial = useUserTutorial()
  const firstActionFlag = tutorial.getFlag(userTutorialFlags.contactAction)
  const handleSelectedContacts = (specifiedId: ?string) => {
    let ids = []
    if (specifiedId?.length) {
      ids = [specifiedId]
    } else {
      if (selected.size) {
        ids = [...selected]
      } else if (selectAll) {
        ids = [...pendings].map(item => item.id)
      }
    }
    return ids
      .map(id => {
        const { email } = getFormattedContact(contactsById[id])
        return email
      })
      .filter(Boolean)
  }

  const toggleOnSelectAll = () => {
    if (selectAll) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }
    setSelected(new Set())

    if (view === 'gallery') {
      setView('list')
    }
  }

  const handleOnSelect = (id: string) => {
    let nextSelected = new Set(selected)
    if (selectAll) {
      nextSelected = new Set(
        pendings.map(item => item.id).filter(each => each !== id)
      )
    } else if (nextSelected.has(id)) {
      nextSelected.delete(id)
    } else {
      nextSelected.add(id)
    }

    if (selectAll && nextSelected.size !== pendings.length) {
      toggleOnSelectAll()
    } else if (!selectAll && nextSelected.size === pendings.length) {
      toggleOnSelectAll()
      return
    }

    setSelected(nextSelected)
  }

  const handleOnAccept = (id?: string, options) => {
    const { isNeedUpdateSelected = false, isClearSelected = false } =
      options || {}
    if (isClearSelected) {
      setSelected(new Set())
    } else if (isNeedUpdateSelected && selected.has(id)) {
      selected.delete(id)
      setSelected(selected)
    }
    const toAccept = handleSelectedContacts(id)

    dispatch(actions.batchApproveSenders(toAccept))

    if (hasNextCard && selectAll) {
      setCurrentCard(data => ({
        ...data,
        [galleryViewMap[nextGalleryView]]: nextCardData,
      }))
      setView(nextGalleryView)
    }
  }

  const handleOnBlock = (id?: string, options) => {
    const {
      isNeedUpdateSelected = false,
      isClearSelected = false,
      isBlockSender = false,
    } = options || {}
    if (isClearSelected) {
      setSelected(new Set())
    } else if (isNeedUpdateSelected && selected.has(id)) {
      selected.delete(id)
      setSelected(selected)
    }
    if (isBlockSender) {
      const toBlock = handleSelectedContacts(id)
      dispatch(actions.showBatchBlockPrompt(toBlock))
    } else {
      const { email } = getFormattedContact(contactsById[id])
      dispatch(
        showModal({
          key: modalTypes.blockDomain,
          props: {
            email,
            domain: getDomainByEmail(email),
          },
        })
      )
    }
  }

  const handleOnAcceptAndMoveTo = (split: SplitInbox, id?: string) => {
    const toAccept = handleSelectedContacts(id)
    dispatch(actions.batchApproveAndMoveTo(toAccept, split))
  }
  const handleOnChangeView = (nextView: GalleryView) => {
    setView(nextView)
  }

  useEffect(() => {
    if (pendings.length === 0 && firstActionFlag) {
      onClose()
    }
  }, [pendings])

  const onNextNavigate = (view, currentCard, isResetSelected = false) => {
    setCurrentCard(data => ({
      ...data,
      ...currentCard,
    }))
    setView(view)
    if (isResetSelected) {
      setSelectAll(false)
      setSelected(new Set())
    }
  }

  return (
    <GalleryModal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Header
          onChangeView={handleOnChangeView}
          disabled={isContactStatusUpdating}
          selected={selectAll ? pendings.length : selected.size}
          selectAll={selectAll}
          onSelectAll={toggleOnSelectAll}
          view={view}
          viewDisabled={selectAll || selected.size > 0}
          onClose={onClose}
        />
      }
    >
      <Content
        total={allCard.length}
        onNextNavigate={onNextNavigate}
        cleanup={currentCleanup}
        suggestedBlock={currentSuggestBlockContact}
        view={view}
        emailNudge={currentEmailNudge}
        contacts={pendings}
        selected={selected}
        selectAll={selectAll}
        onSelect={handleOnSelect}
        selectedContactId={currentContact.id}
        onAccept={handleOnAccept}
        onBlock={handleOnBlock}
        onMoveToSplit={handleOnAcceptAndMoveTo}
        onClose={onClose}
      />
    </GalleryModal>
  )
}

type ContentProps = {
  view: GalleryView,
  total: number,
  onNextNavigate: (view: GalleryView, currentCard: any) => void,
  contacts: $ReadOnlyArray<Contact>,
  selectedContactId?: string,
  onAccept: (id?: string) => void,
  onBlock: (id?: string) => void,
  onMoveToSplit: (split: SplitInbox, id?: string) => void,
  onClose: () => void,
  cleanup: any,
  suggestedBlock: any,
  emailNudge: any,
  selected: Set<string>,
  selectAll: boolean,
  onSelect: any,
}

const Content = ({
  view,
  emailNudge,
  onNextNavigate,
  contacts,
  selectedContactId,
  onAccept,
  onBlock,
  onMoveToSplit,
  cleanup,
  onClose,
  suggestedBlock,
  selected,
  selectAll,
  onSelect,
  total,
}: ContentProps) => {
  switch (true) {
    case total === 0:
      return <GalleryCleanup onComplete={onClose} />
    case view === 'list':
      return (
        <ListBody
          onNextNavigate={onNextNavigate}
          ids={contacts.map(item => item.id)}
          selected={selected}
          selectAll={selectAll}
          onSelect={onSelect}
          onAccept={onAccept}
          onBlock={onBlock}
          onMoveToSplit={onMoveToSplit}
        />
      )
    case view === 'gallery':
      return (
        <GalleryBody
          total={total}
          onNextNavigate={onNextNavigate}
          selectedContactId={selectedContactId}
          contacts={contacts}
          onAccept={onAccept}
          onBlock={onBlock}
          onMoveToSplit={onMoveToSplit}
        />
      )
    case view === 'emailNudge':
      return (
        <EmailNudgeCard
          {...emailNudge}
          total={total}
          onNextNavigate={onNextNavigate}
          onClose={onClose}
        />
      )
    case view === 'suggestedBlock':
      return (
        <SuggestedBlockCard
          {...suggestedBlock}
          total={total}
          onClose={onClose}
          onNextNavigate={onNextNavigate}
        />
      )
    case view === 'cleanup':
      return (
        <CleanupCard
          {...cleanup}
          onClose={onClose}
          total={total}
          onNextNavigate={onNextNavigate}
        />
      )
    default:
      return null
  }
}
