// @flow
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import i18next from 'i18next'

import * as client from '@edison/webmail-core/api/premium'
import * as authClient from '@edison/webmail-core/api/auth'
import { getCaptchaTokenV2 } from '@edison/webmail-core/captcha'
import { getAuth, getInvite } from 'core/auth/selectors'
import { getOnmailDomain } from 'core/custom-domains/selectors'
import { getPaymentMethod } from 'core/premium/selectors'
import { createAction } from 'utils/redux'
import { showNotification } from 'core/toasts/actions'
import { toastVariants } from 'common/toasts'
import { getFeatureFlags, getPremiumPlans } from './selectors'
import { showPaywallModal } from 'core/modals/actions'
import { getUsageRate, getRemainingSizeSelector } from 'core/usage/selectors'
import {
  persistCsrfToken,
  persistUser,
  persistSuperSessionId,
} from 'common/storage'
import { premium as premiumEvents, onboarding } from 'core/analytics/actions'
import { convertToSignUpRecoveryMethod } from 'utils'
import {
  createPaymentMethod,
  confirmPaymentMethod,
  requireConfirmation,
  requirePaymentMethod,
} from 'utils/stripe'
import { paywallTypes } from 'utils/constants'
import { reportException } from 'utils/logs'

import type { ThunkAction, ActionCreator } from 'types/redux'
import type {
  FetchPlansRequest,
  FetchPlansSuccess,
  FetchPlansFailure,
  FetchCurrentPlanRequest,
  FetchCurrentPlanSuccess,
  FetchCurrentPlanFailure,
  PurchasePlanRequest,
  PurchasePlanSuccess,
  PurchasePlanFailure,
  ChangePlanRequest,
  ChangePlanSuccess,
  ChangePlanFailure,
  UnsubscribePlanRequest,
  UnsubscribePlanSuccess,
  UnsubscribePlanFailure,
  FetchBillingHistoryRequest,
  FetchBillingHistorySuccess,
  FetchBillingHistoryFailure,
  FetchPaymentMethodRequest,
  FetchPaymentMethodSuccess,
  FetchPaymentMethodFailure,
  UpdatePaymentMethodRequest,
  UpdatePaymentMethodSuccess,
  UpdatePaymentMethodFailure,
  PremiumSignUpRequest,
  PremiumSignUpSuccess,
  PremiumSignUpFailure,
  PremiumPreviewRequest,
  PremiumPreviewSuccess,
  PremiumPreviewFailure,
} from './types'

export const fetchPlansActions: {
  request: ActionCreator<FetchPlansRequest>,
  success: ActionCreator<FetchPlansSuccess>,
  failure: ActionCreator<FetchPlansFailure>,
} = {
  request: createAction('FETCH_PLANS_REQUEST'),
  success: createAction('FETCH_PLANS_SUCCESS'),
  failure: createAction('FETCH_PLANS_FAILURE'),
}

export function fetchPlans(): ThunkAction {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchPlansActions.request())
      const res = await client.listPlans()
      const plans = res.result
      dispatch(fetchPlansActions.success({ plans }))
    } catch (e) {
      dispatch(fetchPlansActions.failure({ message: e.message }))
    }
  }
}

export const fetchCurrentPlanActions: {
  request: ActionCreator<FetchCurrentPlanRequest>,
  success: ActionCreator<FetchCurrentPlanSuccess>,
  failure: ActionCreator<FetchCurrentPlanFailure>,
} = {
  request: createAction('FETCH_CURRENT_PLAN_REQUEST'),
  success: createAction('FETCH_CURRENT_PLAN_SUCCESS'),
  failure: createAction('FETCH_CURRENT_PLAN_FAILURE'),
}

export function fetchCurrentPlan(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        fetchCurrentPlanActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }
    try {
      dispatch(fetchCurrentPlanActions.request())
      const res = await client.currentPlan({ auth })
      const plan = res.result
      dispatch(premiumEvents.setUserPlan(plan.id))
      dispatch(fetchCurrentPlanActions.success({ plan }))
      return true
    } catch (e) {
      dispatch(fetchCurrentPlanActions.failure({ message: e.message }))
      return false
    }
  }
}

export const purchasePlanActions: {
  request: ActionCreator<PurchasePlanRequest>,
  success: ActionCreator<PurchasePlanSuccess>,
  failure: ActionCreator<PurchasePlanFailure>,
} = {
  request: createAction('PURCHASE_PLAN_REQUEST'),
  success: createAction('PURCHASE_PLAN_SUCCESS'),
  failure: createAction('PURCHASE_PLAN_FAILURE'),
}
export function purchasePlan(
  planId: string,
  name: string,
  postalCode: string,
  stripe: any,
  element: any
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        purchasePlanActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(purchasePlanActions.request())
      const paymentMethod = await createPaymentMethod(
        stripe,
        element,
        name,
        postalCode
      )

      const res = await client.purchasePlan(planId, paymentMethod.id, { auth })
      const { subscription, plan, success } = res

      // Require additional actions from customer to verify their payment method
      // Stripe will open a new modal for customers to authenticate their payment
      // method.
      const requireMoreAction = !success && requireConfirmation(subscription)
      if (requireMoreAction) {
        // Trigger confirm payment method confirmation flow with Stripe
        await confirmPaymentMethod(stripe, subscription, paymentMethod)

        // Call backend API again to verify payment
        const verifyRes = await client.verifySubscription(subscription.id, {
          auth,
        })

        // If backend verification fails again - we stop the user from proceeding.
        if (!verifyRes.success) {
          throw new Error(i18next.t('premium.payment.error'))
        }

        dispatch(purchasePlanActions.success({ plan: verifyRes.plan }))
        return true
      }

      if (requirePaymentMethod(subscription)) {
        throw new Error(
          get(
            subscription,
            'latest_invoice.payment_intent.last_payment_error.message'
          )
        )
      }

      if (!success) {
        throw new Error(i18next.t('premium.payment.error'))
      }

      dispatch(premiumEvents.userPurchasePlan(planId))
      dispatch(premiumEvents.userUpgradeFromFree(planId))
      dispatch(purchasePlanActions.success({ plan }))
      return true
    } catch (e) {
      dispatch(purchasePlanActions.failure({ message: e.message }))
      dispatch(showNotification(e.message, toastVariants.error))
      return false
    }
  }
}

export const changePlanActions: {
  request: ActionCreator<ChangePlanRequest>,
  success: ActionCreator<ChangePlanSuccess>,
  failure: ActionCreator<ChangePlanFailure>,
} = {
  request: createAction('CHANGE_PLAN_REQUEST'),
  success: createAction('CHANGE_PLAN_SUCCESS'),
  failure: createAction('CHANGE_PLAN_FAILURE'),
}
export function changePlan(planId: string): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState()
    const auth = getAuth()(state)
    if (auth === null) {
      return dispatch(
        changePlanActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }
    try {
      dispatch(changePlanActions.request())
      const res = await client.changePlan(planId, { auth })
      const { plan, subscription, success } = res

      // Require additional actions from customer to verify their payment method
      // Stripe will open a new modal for customers to authenticate their payment
      // method.
      if (requirePaymentMethod(subscription)) {
        throw new Error(
          get(
            subscription,
            'latest_invoice.payment_intent.last_payment_error.message'
          )
        )
      }

      if (!success) {
        throw new Error(i18next.t('premium.payment.error'))
      }

      dispatch(changePlanActions.success({ plan }))
    } catch (e) {
      dispatch(
        showNotification(
          e.message || i18next.t('serverError'),
          toastVariants.error
        )
      )
      dispatch(changePlanActions.failure({ message: e.message }))
    }
  }
}

export const unsubscribeActions: {
  request: ActionCreator<UnsubscribePlanRequest>,
  success: ActionCreator<UnsubscribePlanSuccess>,
  failure: ActionCreator<UnsubscribePlanFailure>,
} = {
  request: createAction('UNSUBSCRIBE_PLAN_REQUEST'),
  success: createAction('UNSUBSCRIBE_PLAN_SUCCESS'),
  failure: createAction('UNSUBSCRIBE_PLAN_FAILURE'),
}
export function unsubscribe(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        unsubscribeActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }

    const getLatestSubscription = async () => {
      try {
        const res = await client.latestSubscription({ auth })
        return res.result
      } catch (e) {
        // null if does not exist
        return null
      }
    }

    try {
      dispatch(unsubscribeActions.request())
      await client.unsubscribe({ auth })
      const latestSubscription = await getLatestSubscription()
      dispatch(unsubscribeActions.success(latestSubscription))
    } catch (e) {
      dispatch(unsubscribeActions.failure({ message: e.message }))
    }
  }
}

export const fetchBillingHistoryActions: {
  request: ActionCreator<FetchBillingHistoryRequest>,
  success: ActionCreator<FetchBillingHistorySuccess>,
  failure: ActionCreator<FetchBillingHistoryFailure>,
} = {
  request: createAction('FETCH_BILLING_HISTORY_REQUEST'),
  success: createAction('FETCH_BILLING_HISTORY_SUCCESS'),
  failure: createAction('FETCH_BILLING_HISTORY_FAILURE'),
}
export function fetchBillingHistory(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        fetchBillingHistoryActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }
    try {
      dispatch(fetchBillingHistoryActions.request())
      const res = await client.billingHistory({ auth })
      const billingHistory = res.result
      dispatch(fetchBillingHistoryActions.success({ billingHistory }))
    } catch (e) {
      dispatch(fetchBillingHistoryActions.failure({ message: e.message }))
    }
  }
}

export const fetchPaymentMethodActions: {
  request: ActionCreator<FetchPaymentMethodRequest>,
  success: ActionCreator<FetchPaymentMethodSuccess>,
  failure: ActionCreator<FetchPaymentMethodFailure>,
} = {
  request: createAction('FETCH_PAYMENT_METHOD_REQUEST'),
  success: createAction('FETCH_PAYMENT_METHOD_SUCCESS'),
  failure: createAction('FETCH_PAYMENT_METHOD_FAILURE'),
}
export function fetchPaymentMethod(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        fetchPaymentMethodActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }

    const getPaymentMethod = async () => {
      try {
        const res = await client.paymentMethod({ auth })
        return res.result
      } catch (e) {
        // null if does not exist
        return null
      }
    }

    const getLatestSubscription = async () => {
      try {
        const res = await client.latestSubscription({ auth })
        return res.result
      } catch (e) {
        // null if does not exist
        return null
      }
    }

    try {
      dispatch(fetchPaymentMethodActions.request())
      const [paymentMethod, latestSubscription] = await Promise.all([
        getPaymentMethod(),
        getLatestSubscription(),
      ])
      dispatch(
        fetchPaymentMethodActions.success({
          paymentMethod: paymentMethod,
          latestSubscription: latestSubscription,
        })
      )
    } catch (e) {
      dispatch(fetchPaymentMethodActions.failure({ message: e.message }))
    }
  }
}

export const updatePaymentMethodActions: {
  request: ActionCreator<UpdatePaymentMethodRequest>,
  success: ActionCreator<UpdatePaymentMethodSuccess>,
  failure: ActionCreator<UpdatePaymentMethodFailure>,
} = {
  request: createAction('UPDATE_PAYMENT_METHOD_REQUEST'),
  success: createAction('UPDATE_PAYMENT_METHOD_SUCCESS'),
  failure: createAction('UPDATE_PAYMENT_METHOD_FAILURE'),
}
export function updatePaymentMethod(
  name: string,
  postalCode: string,
  stripe: any,
  element: any
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    const oldPaymentMethod = getPaymentMethod(getState())

    if (auth === null) {
      dispatch(
        updatePaymentMethodActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
      return false
    }

    try {
      dispatch(updatePaymentMethodActions.request())
      const paymentMethod = await createPaymentMethod(
        stripe,
        element,
        name,
        postalCode
      )

      const res = await client.updatePaymentMethod(paymentMethod.id, { auth })

      // Payment method needs verification if it was invalid previously
      if (!!oldPaymentMethod && !oldPaymentMethod.isValid) {
        // 1. Get latest subscription
        let latestSubscription
        try {
          latestSubscription = await client.latestSubscription({ auth })
        } catch (e) {}

        // 2. If latest subscription requires more action, trigger Stripe flow
        if (
          // Skip the confirmation if we can't fetch the latest subscription
          //
          // https://easilydo.atlassian.net/browse/EWM-2239
          !isNil(latestSubscription) &&
          requireConfirmation(latestSubscription.result.subscription)
        ) {
          await confirmPaymentMethod(
            stripe,
            latestSubscription.result.subscription,
            paymentMethod
          )

          // Call verify subscription again to let backend verify payment method
          await client.verifySubscription(
            latestSubscription.result.subscription.id,
            { auth }
          )
        }
      }

      dispatch(
        updatePaymentMethodActions.success({
          paymentMethod: res.result,
        })
      )

      return true
    } catch (e) {
      dispatch(updatePaymentMethodActions.failure({ message: e.message }))
      dispatch(showNotification(e.message, toastVariants.error))
      return false
    }
  }
}

export const premiumSignupActions: {
  request: ActionCreator<PremiumSignUpRequest>,
  success: ActionCreator<PremiumSignUpSuccess>,
  failure: ActionCreator<PremiumSignUpFailure>,
} = {
  request: createAction('PREMIUM_SIGNUP_REQUEST'),
  success: createAction('PREMIUM_SIGNUP_SUCCESS'),
  failure: createAction('PREMIUM_SIGNUP_FAILURE'),
}

// Signup errors
export class InvitationCodeError extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'InvitationCodeError'
  }
}

export function signup(
  planId: string,
  stripe: any,
  paymentMethod: Object,
  {
    email,
    password,
    recoveryMethod,
    firstName,
    lastName,
    birthday,
    tnc,
    optIn,
    price,
    currency,
    freeTrial = false,
  }: {
    email: string,
    password: string,
    recoveryMethod: Object,
    firstName: string,
    lastName: string,
    birthday: string,
    tnc: boolean,
    optIn: boolean,
    price?: string,
    currency?: string,
    freeTrial?: boolean,
  }
): ThunkAction {
  return async (dispatch, getState) => {
    try {
      dispatch(premiumSignupActions.request({ email }))

      let captcha = {}
      try {
        captcha = await getCaptchaTokenV2()
        dispatch(onboarding.userCaptcha(email, true))
      } catch (e) {
        dispatch(onboarding.userCaptcha(email, false))
        throw e
      }
      const params = {
        email,
        password,
        captcha: captcha.token,
        recoveryMethod: convertToSignUpRecoveryMethod(recoveryMethod),
        firstName,
        lastName,
        birthday,
        optIn,
        tnc,
        price,
        currency,
        freeTrial,
      }

      // Verify invite code before signup requests
      const invite = getInvite(getState())
      if (invite) {
        const verify = await authClient.verifyInvitation(invite)
        if (!verify) {
          throw new InvitationCodeError('Invalid invitation code.')
        }
      }

      const res = await client.signup(planId, paymentMethod.id, params)

      let {
        featureSubscription,
        domainSubscription,
        csrf,
        token,
        orderId,
        sessionId,
      } = res

      // Requires card authentication
      const requireMoreAction =
        requireConfirmation(featureSubscription) ||
        requireConfirmation(domainSubscription)
      if (requireMoreAction) {
        // Trigger confirm payment method confirmation flow with Stripe
        if (!isNil(featureSubscription)) {
          await confirmPaymentMethod(stripe, featureSubscription, paymentMethod)
        }
        if (!isNil(domainSubscription)) {
          await confirmPaymentMethod(stripe, domainSubscription, paymentMethod)
        }

        // Get a new captcha for the sign up request
        const captcha = await getCaptchaTokenV2()

        // Call backend API again to sign up with existing subscription ID
        const verifyRes = await client.signup(planId, paymentMethod.id, {
          ...params,
          captcha: captcha.token,
          featureSubscriptionId: featureSubscription.id,
          domainSubscriptionId: domainSubscription
            ? domainSubscription.id
            : undefined,
        })

        // If backend verification fails again - we stop the user from proceeding.
        if (!verifyRes.success) {
          throw new client.PaymentMethodError()
        }

        featureSubscription = verifyRes.featureSubscription
        domainSubscription = verifyRes.domainSubscription
        csrf = verifyRes.csrf
        token = verifyRes.token
        orderId = verifyRes.orderId
        sessionId = verifyRes.sessionId
      }

      // Invalid payment method - decline user from moving forward
      if (
        requirePaymentMethod(featureSubscription) ||
        requirePaymentMethod(domainSubscription)
      ) {
        const featureSubError = get(
          featureSubscription,
          'latest_invoice.payment_intent.last_payment_error.message'
        )
        const domainSubError = get(
          domainSubscription,
          'latest_invoice.payment_intent.last_payment_error.message'
        )

        throw new client.PaymentMethodError(featureSubError || domainSubError)
      }

      const onmailDomain = getOnmailDomain(getState())
      const [userName, userDomain] = email.split('@')
      const fullEmail = [userName, userDomain || onmailDomain]
        .join('@')
        .toLowerCase()

      persistSuperSessionId(sessionId)
      persistCsrfToken(orderId, csrf)
      persistUser(orderId, fullEmail)

      dispatch(premiumEvents.userPurchasePlan(planId))
      if (price && currency) {
        dispatch(premiumEvents.userPurchaseDomain(price, currency))
      }

      // Invalidate invitation code
      try {
        if (invite) {
          await authClient.invalidateInvitation(invite)
        }
      } catch (e) {
        // Nothing can be done even if this happens, just log the error
        reportException(e, {
          message: 'Invite code not invalidated',
          payload: invite,
        })
      }

      dispatch(
        premiumSignupActions.success({
          user: fullEmail,
          token,
        })
      )
      return { orderId }
    } catch (e) {
      dispatch(premiumSignupActions.failure({ message: e.message }))

      let message
      if (e instanceof client.PaymentMethodError) {
        if (e.message) {
          message = i18next.t('premium.payment.error.stripe', {
            message: e.message,
          })
        } else {
          message = i18next.t('premium.payment.error.default')
        }
      } else {
        switch (e.status) {
          case 403:
            message = i18next.t('auth.signup.recaptcha.failure')
            break
          default:
            message = i18next.t('auth.signup.failure')
        }
      }

      dispatch(showNotification(message, toastVariants.error))

      throw e
    }
  }
}

export const premiumPreviewActions: {
  request: ActionCreator<PremiumPreviewRequest>,
  success: ActionCreator<PremiumPreviewSuccess>,
  failure: ActionCreator<PremiumPreviewFailure>,
} = {
  request: createAction('PREMIUM_PREVIEW_REQUEST'),
  success: createAction('PREMIUM_PREVIEW_SUCCESS'),
  failure: createAction('PREMIUM_PREVIEW_FAILURE'),
}

export function premiumPreview(
  planId: string,
  { qty }: { qty?: number } = {}
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      return dispatch(
        premiumPreviewActions.failure({
          message: i18next.t('notAuthenticated'),
        })
      )
    }

    try {
      dispatch(premiumPreviewActions.request())
      const res = await client.preview(planId, { auth, qty })
      const preview = res.result
      dispatch(premiumPreviewActions.success({ planId, preview }))
      return true
    } catch (e) {
      dispatch(premiumPreviewActions.failure({ message: e.message }))
      return false
    }
  }
}

export function checkAndShowFileStoragePaywall(
  targetRate: number
): ThunkAction {
  return (dispatch, getState) => {
    //After user successfully sends an email that is still within the storage limit

    const rate = getUsageRate()(getState())

    if (rate >= targetRate) {
      dispatch(showPaywallModal(paywallTypes.fileStorage))
      return true
    } else {
      return false
    }
  }
}

export function checkAndShowPasswordProtectionPaywall(): ThunkAction {
  return (dispatch, getState) => {
    const planFeatures = getFeatureFlags(getState())

    if (!get(planFeatures, 'allowLargeAttachmentsPassword', false)) {
      dispatch(showPaywallModal(paywallTypes.passwordProtectedLargeFileLinks))
      return true
    }
    return false
  }
}

export function checkAndShowSingleFileSizeLimitPaywall(
  size: number
): ThunkAction {
  return (dispatch, getState) => {
    if (!size) return false

    const planFeatures = getFeatureFlags(getState())
    const limit = planFeatures.singleFileSizeLimit
    if (size > limit) {
      dispatch(showPaywallModal(paywallTypes.singleFileSizeLimit))
      return true
    } else {
      return false
    }
  }
}

export function checkRemainingSizeAndShowFileStoragePaywall(
  size: number
): ThunkAction {
  return (dispatch, getState) => {
    //After user successfully sends an email that is still within the storage limit

    const remainingSize = getRemainingSizeSelector(getState())

    if (remainingSize < size) {
      dispatch(showPaywallModal(paywallTypes.fileStorage))
      return true
    } else {
      return false
    }
  }
}

export function checkAndFetchPlans(): ThunkAction {
  return (dispatch, getState) => {
    const plans = getPremiumPlans(getState())
    if (plans && plans.length) return
    dispatch(fetchPlans())
  }
}
