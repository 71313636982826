// @flow
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { useTranslation } from 'react-i18next'

import SubAccountDetail, {
  TextButton,
  PasswordResetSection,
  PasswordResetLink,
  DangerZoneSection,
  BannedMessage,
} from '@edison/webmail-ui/screens/Settings/components/CustomDomain/SubAccountDetail'
import DeletionConfirm from '@edison/webmail-ui/screens/Settings/components/CustomDomain/DeletionConfirm'
import * as customDomains from 'core/custom-domains'

import { SUBACCOUNT_STATUS } from 'utils/constants'

import type { Dispatch } from 'types/redux'

type Props = {
  id: string,
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ id, isOpen, toggle }: Props) => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const [deletion, setDeletion] = useState(false)
  const isLoading = useSelector(customDomains.selectors.getSubaccountsLoading)
  const isSubaccountDeleting = useSelector(
    customDomains.selectors.deleteSubaccountLoading
  )
  const subaccounts = useSelector(customDomains.selectors.getSubaccountsById)
  const resetPasswordLinks = useSelector(
    customDomains.selectors.getResetPasswordLinks
  )
  const isGeneratingLink = useSelector(
    customDomains.selectors.getResetPasswordLinkLoading
  )

  const subaccount = subaccounts[id]
  const resetPasswordLink = resetPasswordLinks[id]

  const isBanned = subaccount.status === SUBACCOUNT_STATUS.BANNED

  useEffect(() => {
    setDeletion(false)
  }, [id])

  const onGenerateLink = () => {
    dispatch(customDomains.actions.fetchResetPasswordLink(id))
  }

  const onTriggerDelete = () => {
    setDeletion(true)
  }

  const onDelete = () => {
    dispatch(customDomains.actions.deleteSubaccount(id)).then(isSuccess => {
      if (isSuccess) {
        toggle()
      }
    })
  }

  const subaccountDetail = (
    <SubAccountDetail email={subaccount.email} onClose={toggle}>
      {isBanned ? (
        <BannedMessage />
      ) : (
        <PasswordResetSection>
          {!!resetPasswordLink ? (
            <PasswordResetLink link={resetPasswordLink} />
          ) : (
            <TextButton disabled={isGeneratingLink} onClick={onGenerateLink}>
              {t('settings.customDomain.subAccountDetail.passwordReset.button')}
            </TextButton>
          )}
        </PasswordResetSection>
      )}
      <DangerZoneSection>
        <TextButton color="error" onClick={onTriggerDelete}>
          {t('settings.customDomain.subAccountDetail.dangerZone.button')}
        </TextButton>
      </DangerZoneSection>
    </SubAccountDetail>
  )

  const deletionConfirm = (
    <DeletionConfirm
      email={subaccount.email}
      onDelete={onDelete}
      disabled={isSubaccountDeleting}
      onClose={() => setDeletion(false)}
    />
  )
  const content = !deletion ? subaccountDetail : deletionConfirm

  return (
    <Dialog fullWidth open={isOpen}>
      {!isLoading && content}
    </Dialog>
  )
}

export default View
