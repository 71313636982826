// @flow
import i18next from 'i18next'
import * as client from '@edison/webmail-core/api/credentials'
import { createAction } from 'utils/redux'

import { toastVariants } from 'common/toasts'
import { showNotification } from 'core/toasts/actions'
import { getAuth } from 'core/auth/selectors'
import { getProfileInSettings } from 'core/settings/selectors'

import type {
  CredentialListRequest,
  CredentialListSuccess,
  CredentialListFailure,
  CredentialSetDefaultRequest,
  CredentialSetDefaultSuccess,
  CredentialSetDefaultFailure,
  CredentialRemoveRequest,
  CredentialRemoveSuccess,
  CredentialRemoveFailure,
  ConnectGoogleRequest,
  ConnectGoogleSuccess,
  ConnectGoogleFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const fetchCredentialsActions: {
  request: ActionCreator<CredentialListRequest>,
  success: ActionCreator<CredentialListSuccess>,
  failure: ActionCreator<CredentialListFailure>,
} = {
  request: createAction('CREDENTIAL_LIST_REQUEST'),
  success: createAction('CREDENTIAL_LIST_SUCCESS'),
  failure: createAction('CREDENTIAL_LIST_FAILURE'),
}

/**
 * Fetch data for a list of credentials from backend API
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchCredentials(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        fetchCredentialsActions.failure({ message: 'User not logged in' })
      )
      dispatch(
        showNotification(i18next.t('notAuthenticated'), toastVariants.error)
      )
      return
    }

    try {
      dispatch(fetchCredentialsActions.request())
      const res = await client.list({ auth })
      const { credentials } = res.result

      dispatch(
        fetchCredentialsActions.success({
          credentials,
        })
      )
    } catch (e) {
      dispatch(fetchCredentialsActions.failure({ message: e.message }))
      dispatch(showNotification(e.message, toastVariants.error))
    }
  }
}

export const setCredentialDefaultActions: {
  request: ActionCreator<CredentialSetDefaultRequest>,
  success: ActionCreator<CredentialSetDefaultSuccess>,
  failure: ActionCreator<CredentialSetDefaultFailure>,
} = {
  request: createAction('CREDENTIAL_SET_DEFAULT_REQUEST'),
  success: createAction('CREDENTIAL_SET_DEFAULT_SUCCESS'),
  failure: createAction('CREDENTIAL_SET_DEFAULT_FAILURE'),
}

/**
 * Set specified third-party email as default sender
 *
 * @public
 * @param {string} email
 * @returns {ThunkAction}
 */
export function setCredentialDefault(email: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        setCredentialDefaultActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(setCredentialDefaultActions.request())
      await client.setDefault({ auth, email })
      dispatch(setCredentialDefaultActions.success())

      dispatch(
        showNotification(
          i18next.t('settings.smtpProxy.setDefault.success', { email }),
          toastVariants.primary
        )
      )
      dispatch(fetchCredentials())
    } catch (e) {
      dispatch(setCredentialDefaultActions.failure({ message: e.message }))
      dispatch(showNotification(i18next.t('serverError'), toastVariants.error))
    }
  }
}

export const removeCredentialActions: {
  request: ActionCreator<CredentialRemoveRequest>,
  success: ActionCreator<CredentialRemoveSuccess>,
  failure: ActionCreator<CredentialRemoveFailure>,
} = {
  request: createAction('CREDENTIAL_REMOVE_REQUEST'),
  success: createAction('CREDENTIAL_REMOVE_SUCCESS'),
  failure: createAction('CREDENTIAL_REMOVE_FAILURE'),
}

/**
 * Remove specified connected email fron credentials
 *
 * @public
 * @param {string} email
 * @returns {ThunkAction}
 */
export function removeCredential(email: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        removeCredentialActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(removeCredentialActions.request())
      await client.remove({ auth, email })
      dispatch(removeCredentialActions.success())

      dispatch(
        showNotification(
          i18next.t('settings.smtpProxy.remove.success'),
          toastVariants.primary
        )
      )
      dispatch(fetchCredentials())
    } catch (e) {
      dispatch(removeCredentialActions.failure({ message: e.message }))
      dispatch(showNotification(i18next.t('serverError'), toastVariants.error))
    }
  }
}

export const connectGoogleActions: {
  request: ActionCreator<ConnectGoogleRequest>,
  success: ActionCreator<ConnectGoogleSuccess>,
  failure: ActionCreator<ConnectGoogleFailure>,
} = {
  request: createAction('CONNECT_GOOGLE_REQUEST'),
  success: createAction('CONNECT_GOOGLE_SUCCESS'),
  failure: createAction('CONNECT_GOOGLE_FAILURE'),
}

export function connectGoogle(code: string, redirectUri: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(connectGoogleActions.failure({ message: 'User not logged in' }))
      return
    }

    const profile = getProfileInSettings()(getState())
    try {
      dispatch(connectGoogleActions.request())
      await client.google({
        auth,
        code,
        redirectUri,
        nickname: profile.senderName,
      })
      dispatch(connectGoogleActions.success())

      dispatch(
        showNotification(
          i18next.t('settings.smtpProxy.providers.google.success'),
          toastVariants.success
        )
      )
      dispatch(fetchCredentials())
    } catch (e) {
      dispatch(connectGoogleActions.failure({ message: e.message }))
      dispatch(showNotification(i18next.t('serverError'), toastVariants.error))
    }
  }
}
