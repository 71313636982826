// @flow
import omit from 'lodash/omit'
import isNil from 'lodash/isNil'
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type {
  RecentReplyMessage,
  CreatedScheduledBreak,
} from '@edison/webmail-core/types/inbox-break'
import type {
  InboxBreakActions,
  FetchBreakStatusSuccess,
  FetchBreakSettingsSuccess,
  UpdateBreakSettingSuccess,
  CreateScheduledBreakSuccess,
  UpdateScheduledBreakSuccess,
  RemoveScheduledBreakRequest,
  RemoveScheduledBreakFailure,
  FetchRecentMessagesSuccess,
  RemoveRecentMessageRequest,
  RemoveRecentMessageFailure,
  ExecuteBreakSuccess,
  ToggleFullscreenAlertFlag,
} from './types'

export type State = {
  enable: boolean,
  breakUntil: number,
  breakTimezone: string,
  enableAutoReply: boolean,
  replyMessage: string,
  statusTimer: ?TimeoutID,
  schedules: {
    +[key: string]: CreatedScheduledBreak,
  },
  recentMessages: {
    +[key: string]: RecentReplyMessage,
  },
  flags: {
    +[key: string]: boolean,
  },
}

const INITIAL_VALUES: State = {
  enable: false,
  breakUntil: 0,
  breakTimezone: '',
  replyMessage: '',
  enableAutoReply: false,
  statusTimer: undefined,
  schedules: {},
  recentMessages: {},
  flags: {
    fullscreenAlert: false,
  },
}

const settings = {
  status: function(state: State, action: FetchBreakStatusSuccess) {
    const { enable, breakUntil, breakTimezone, statusTimer } = action.payload

    const next = {
      ...state,
      enable,
      breakUntil,
      statusTimer,
      breakTimezone,
    }

    if (!enable) {
      return flags.toggle(next, 'fullscreenAlert', false)
    }

    return next
  },
  list: function(
    state: State,
    action: FetchBreakSettingsSuccess | UpdateBreakSettingSuccess
  ) {
    const { enableAutoReply, replyMessage, schedules } = action.payload

    return {
      ...state,
      replyMessage,
      enableAutoReply,
      schedules: schedules.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {}
      ),
    }
  },
  execute: function(state: State, action: ExecuteBreakSuccess) {
    const { enable, enableAutoReply, replyMessage } = action.payload

    const next = {
      ...state,
      enable,
      replyMessage,
      enableAutoReply,
    }

    if (!enable) {
      return flags.toggle(next, 'fullscreenAlert', false)
    }

    return next
  },
}

const scheduledBreaks = {
  update: function(
    state: State,
    action: CreateScheduledBreakSuccess | UpdateScheduledBreakSuccess
  ) {
    const schedule = action.payload
    const { schedules } = state

    return {
      ...state,
      schedules: {
        ...schedules,
        [schedule.id]: schedule,
      },
    }
  },
  removeRequest: function(state: State, action: RemoveScheduledBreakRequest) {
    const { id } = action.payload

    return {
      ...state,
      schedules: omit(state.schedules, id),
    }
  },
  removeFailure: function(state: State, action: RemoveScheduledBreakFailure) {
    const { backup } = action.payload
    const { schedules } = state

    if (isNil(backup)) {
      return state
    }

    return {
      ...state,
      schedules: {
        ...schedules,
        [backup.id]: backup,
      },
    }
  },
}

const recentMessages = {
  list: function(state: State, action: FetchRecentMessagesSuccess) {
    const recentMessages = action.payload

    return {
      ...state,
      recentMessages: recentMessages.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {}
      ),
    }
  },
  removeRequest: function(state: State, action: RemoveRecentMessageRequest) {
    const { id } = action.payload

    return {
      ...state,
      recentMessages: omit(state.recentMessages, id),
    }
  },

  removeFailure: function(state: State, action: RemoveRecentMessageFailure) {
    const { backup } = action.payload
    const { recentMessages } = state

    if (isNil(backup)) {
      return state
    }

    return {
      ...state,
      recentMessages: {
        ...recentMessages,
        [backup.id]: backup,
      },
    }
  },
}

const flags = {
  toggle: function(state: State, flag: string, value?: boolean) {
    const { flags } = state

    if (flag in flags) {
      return {
        ...state,
        flags: {
          ...flags,
          [flag]: isNil(value) ? !flags[flag] : value,
        },
      }
    }
    return state
  },
  fullscreenAlert: function(state: State, action: ToggleFullscreenAlertFlag) {
    return flags.toggle(state, 'fullscreenAlert')
  },
}

const reducer = createReducer<State, InboxBreakActions>(INITIAL_VALUES, {
  // Settings
  [actions.fetchBreakStatusActions.success.toString()]: settings.status,
  [actions.fetchBreakSettingsActions.success.toString()]: settings.list,
  [actions.updateBreakSettingActions.success.toString()]: settings.list,
  // Scheduled breaks
  [actions.createScheduledBreakActions.success.toString()]: scheduledBreaks.update,
  [actions.updateScheduledBreakActions.success.toString()]: scheduledBreaks.update,
  [actions.removeScheduledBreakActions.request.toString()]: scheduledBreaks.removeRequest,
  [actions.removeScheduledBreakActions.failure.toString()]: scheduledBreaks.removeFailure,
  // Recent messages
  [actions.fetchRecentMessagesActions.success.toString()]: recentMessages.list,
  [actions.removeRecentMessageActions.request.toString()]: recentMessages.removeRequest,
  [actions.removeRecentMessageActions.failure.toString()]: recentMessages.removeFailure,

  // Execute
  [actions.executeBreakActions.success.toString()]: settings.execute,

  // Flags
  [actions.toggleFullscreenAlertFlag.toString()]: flags.fullscreenAlert,
})

export default reducer
