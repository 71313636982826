// @flow
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createResource } from '@edison/webmail-core/api/utils'
import isNil from 'lodash/isNil'

import { getAuth } from 'core/auth/selectors'
import { readFile } from '@edison/webmail-core/utils/file'
import { isImage as isImageHelper } from '@edison/webmail-ui/utils'
import useBlob from 'hooks/useBlob'
import type { Node } from 'react'

type Props = {
  src: string,
  alt: string,
  cache?: string,
  children?: Node,
}

const Img = ({ src, alt, children, cache = 'default', ...res }: Props) => {
  const [state, setState] = useState('pending')
  const [blob, setBlob] = useState(null)
  const imgSrc = useBlob(blob)
  const auth = useSelector(getAuth())

  useEffect(() => {
    setState('pending')
    if (!isNil(auth) && !!src) {
      const fileResource = createResource(readFile)
      fileResource
        .read(src, { auth }, { cache })
        .then(blob => {
          if (isImageHelper(blob.type, alt)) {
            setBlob(blob)
            setState('done')
          } else setState('error')
        })
        .catch(e => setState('error'))
      return () => fileResource.abort()
    }
  }, [src])

  return state === 'done' ? (
    <img {...res} src={imgSrc} alt={alt} />
  ) : (
    <div {...res}>{children}</div>
  )
}

export default Img

export const ImagePlaceholder = (props: Object) => {
  const { t } = useTranslation()
  return <img {...props} src={t('placeholder-photo.png')} alt="Placeholder" />
}
