import { SinglePillItem } from '@edison/webmail-ui/components/Pill'
import React, { useEffect, useState } from 'react'

const DEFALUT_TEXT = 'Unread'

export function UnreadPill(props) {
  const { onClick, condition, isClearAll } = props
  const [unread, setUnread] = useState(false)
  useEffect(() => {
    if (isClearAll) {
      setUnread(false)
    }
  }, [isClearAll])
  return (
    <SinglePillItem
      active={unread}
      onClick={() => {
        setUnread(!unread)
        onClick({
          ...condition,
          isUnread: !unread,
        })
      }}
    >
      {DEFALUT_TEXT}
    </SinglePillItem>
  )
}
