// @flow
import React, { useState, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'
import { useHistory, generatePath, Redirect } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import { AccountLoading } from '@edison/webmail-ui/components/Retrofit'
import CircularLoader from '@edison/webmail-ui/components/CircularLoader'

import { useLocalStorageFlag, localStorageFlags } from 'core/flags'
import { useOrderId } from 'core/auth/hooks'
import { fetchAllAccounts } from 'core/retrofit/actions'
import { kochavaEventsThirdPartyAccountAdded } from 'core/kochava/actions'
import {
  useRetrofitSyncProgress,
  useRetrofitAccounts,
} from 'core/retrofit/hooks'
import { useModal } from 'common/modals'
import { routePaths, modalTypes } from 'utils/constants'

import type { Dispatch } from 'types/redux'

export const AccountLoadingModal = ({
  toggle,
  isOpen,
}: {
  toggle: () => void,
  isOpen: boolean,
}) => {
  const userId = useOrderId()
  const history = useHistory()
  const dispatch = useDispatch()

  const { activeAccounts } = useRetrofitSyncProgress()

  const continueUrl = generatePath(routePaths.retrofitAdd, { userId })

  useEffect(() => {
    dispatch(kochavaEventsThirdPartyAccountAdded(activeAccounts.length))
  }, [])

  const emails = activeAccounts
    .sort((a, b) => (a.insertTime > b.insertTime ? -1 : 1))
    .map(({ emailAddress }) => emailAddress)

  const handleOnClick = () => {
    toggle()
    history.push(continueUrl)
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={toggle}>
      <AccountLoading
        emails={emails}
        onClose={toggle}
        onClick={handleOnClick}
      />
    </Dialog>
  )
}

const View = () => {
  const userId = useOrderId()
  const { activeAccounts } = useRetrofitSyncProgress()
  const accountLoadingModal = useModal(modalTypes.accountLoading)

  const newRetrofitUserFlag = useLocalStorageFlag(
    localStorageFlags.newRetrofitUserKey
  )

  const homeUrl = generatePath(routePaths.main, { userId, label: 'inbox' })

  useEffect(() => {
    if (!isEmpty(activeAccounts)) {
      // Show the pro tip after user connects a 3th-party account
      if (!newRetrofitUserFlag.value) {
        newRetrofitUserFlag.set()
      }

      accountLoadingModal.showModal()
    }
  }, [])

  return <Redirect to={homeUrl} />
}

View.displayName = 'AccountLoadingView'

export default () => {
  const dispatch: Dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const { accounts } = useRetrofitAccounts()

  useEffect(() => {
    new Promise(resolve => {
      if (accounts.length === 0) {
        dispatch(fetchAllAccounts()).then(resolve)
      } else {
        resolve()
      }
    }).then(() => {
      setLoading(false)
    })
  }, [])

  if (isLoading) {
    return <CircularLoader fullWidth fullHeight />
  } else {
    return <View />
  }
}
