// @flow
import { createAction } from 'utils/redux'
import { getAuth } from 'core/auth/selectors'
import { AuthMissing, ServerError } from './actions-common'
import {
  updateCalendar as updateCalendarAPI,
  updateCalendarInUserCalendarList,
  clearCalendar,
  getCalendar,
  getCalendarFromUserCalendarList,
  getUserCalendarList,
} from '@edison/webmail-core/api/calendar'
import * as analytics from 'core/analytics/actions'
import { getCalendarColors } from './selectors-colors'
import { fetchColors } from './actions-colors'
import { getCalendarById } from './selectors-calendars'
import { calendarsActionTypes } from './constants-calendars'
import type { ThunkAction } from 'types/redux'
import type { RequestAPIActionGen } from './actions-common'
import type {
  ReduxCalendarUpdateData,
  ClearPrimaryCalendarRequest,
  ClearPrimaryCalendarRequestFailure,
  ClearPrimaryCalendarRequestSuccess,
  FetchPrimaryCalendarRequest,
  FetchPrimaryCalendarRequestFailure,
  FetchPrimaryCalendarRequestSuccess,
  UpdateCalendarRequest,
  UpdateCalendarRequestFailure,
  UpdateCalendarRequestSuccess,
  FetchUserSecondaryCalendarsRequest,
  FetchUserSecondaryCalendarsRequestSuccess,
  FetchUserSecondaryCalendarsRequestFailure,
} from './types-calendars'
import type { CalendarCalendarListResource } from '@edison/webmail-core/types/calendar'
import { showNotification } from '../toasts/actions'
import { toastVariants } from '../../common/toasts'

export const fetchPrimaryCalendarAction: RequestAPIActionGen<
  FetchPrimaryCalendarRequest,
  FetchPrimaryCalendarRequestSuccess,
  FetchPrimaryCalendarRequestFailure
> = {
  request: createAction(calendarsActionTypes.fetchPrimaryCalendarRequest),
  success: createAction(
    calendarsActionTypes.fetchPrimaryCalendarRequestSuccess
  ),
  failure: createAction(
    calendarsActionTypes.fetchPrimaryCalendarRequestFailure
  ),
}
export const fetchPrimaryCalendar = (): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(fetchPrimaryCalendarAction.failure({ message: AuthMissing }))
      return Promise.reject()
    }
    try {
      let colors = getCalendarColors()(getState())
      if (colors.isEmpty()) {
        await dispatch(fetchColors())
        colors = getCalendarColors()(getState())
      }
      dispatch(fetchPrimaryCalendarAction.request())
      const calendarResp = await getCalendar({ auth, calendarId: 'primary' })
      const calendarMetaResp = await getCalendarFromUserCalendarList({
        auth,
        calendarId: 'primary',
      })
      dispatch(
        fetchPrimaryCalendarAction.success({
          calendar: calendarResp.result,
          metaData: calendarMetaResp.result,
          colors,
        })
      )
      return Promise.resolve()
    } catch (e) {
      dispatch(fetchPrimaryCalendarAction.failure({ message: e.message }))
      return Promise.reject(e)
    }
  }
}

export const clearPrimaryCalendarAction: RequestAPIActionGen<
  ClearPrimaryCalendarRequest,
  ClearPrimaryCalendarRequestSuccess,
  ClearPrimaryCalendarRequestFailure
> = {
  request: createAction(calendarsActionTypes.clearPrimaryCalendarRequest),
  success: createAction(
    calendarsActionTypes.clearPrimaryCalendarRequestSuccess
  ),
  failure: createAction(
    calendarsActionTypes.clearPrimaryCalendarRequestFailure
  ),
}

export const clearPrimaryCalendar = (): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(clearPrimaryCalendarAction.failure({ message: AuthMissing }))
      return
    }
    try {
      dispatch(clearPrimaryCalendarAction.request())
      await clearCalendar({ auth, calendarId: 'primary' })
      dispatch(clearPrimaryCalendarAction.success())
    } catch (e) {
      dispatch(clearPrimaryCalendarAction.failure({ message: e.message }))
    }
  }
}
export const fetchUserSecondaryCalendarsAction: RequestAPIActionGen<
  FetchUserSecondaryCalendarsRequest,
  FetchUserSecondaryCalendarsRequestSuccess,
  FetchUserSecondaryCalendarsRequestFailure
> = {
  request: createAction(calendarsActionTypes.fetchSecondaryCalendarsRequest),
  success: createAction(
    calendarsActionTypes.fetchSecondaryCalendarsRequestSuccess
  ),
  failure: createAction(
    calendarsActionTypes.fetchSecondaryCalendarsRequestFailure
  ),
}

export const fetchUserSecondaryCalendars = (): ThunkAction => {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())
    if (auth === null) {
      dispatch(
        fetchUserSecondaryCalendarsAction.failure({ message: AuthMissing })
      )
      return Promise.reject()
    }
    try {
      let colors = getCalendarColors()(getState())
      if (colors.isEmpty()) {
        await dispatch(fetchColors())
        colors = getCalendarColors()(getState())
      }
      dispatch(fetchUserSecondaryCalendarsAction.request())
      const calendarMetas = await getUserCalendarList({ auth })
      const total = calendarMetas.result.items.length
      let count = 0
      const ret = []
      const ifComplete = () => {
        if (count === total) {
          dispatch(fetchUserSecondaryCalendarsAction.success(ret))
          return Promise.resolve()
        }
      }
      calendarMetas.result.items.forEach(
        (calMeta: CalendarCalendarListResource) => {
          if (calMeta.primary) {
            count++
            ifComplete()
            return
          }
          getCalendar({ auth, calendarId: calMeta.id }).then(
            result => {
              count++
              ret.push({
                metaData: calMeta,
                calendar: result.result,
                colors,
              })
              ifComplete()
            },
            () => {
              count++
              ifComplete()
            }
          )
        }
      )
    } catch (e) {
      dispatch(
        fetchUserSecondaryCalendarsAction.failure({ message: e.message })
      )
      return Promise.reject(e)
    }
  }
}

export const updateCalendarAction: RequestAPIActionGen<
  UpdateCalendarRequest,
  UpdateCalendarRequestSuccess,
  UpdateCalendarRequestFailure
> = {
  request: createAction(calendarsActionTypes.updateCalendarRequest),
  success: createAction(calendarsActionTypes.updateCalendarRequestSuccess),
  failure: createAction(calendarsActionTypes.updateCalendarRequestFailure),
}
export const updateCalendar = (
  calendarId: string,
  data: ReduxCalendarUpdateData
): ThunkAction => {
  return async (dispatch, getState) => {
    let auth = getAuth()(getState())
    if (auth === null) {
      dispatch(showNotification(AuthMissing, toastVariants.error))
      dispatch(updateCalendarAction.failure({ message: AuthMissing }))
      return
    }
    const calendar = getCalendarById(calendarId)(getState())
    if (!calendar) {
      dispatch(
        updateCalendarAction.failure({ message: 'Cannot find calendar' })
      )
      return
    }

    const oldCalendar = calendar.clone()
    const apiRequests = []
    const apis = calendar.updateAndSortByAPI(data)
    if (apis.calendarAPI.dataChanged) {
      delete apis.calendarAPI.dataChanged
      apiRequests.push(
        updateCalendarAPI({ auth, calendar: apis.calendarAPI, calendarId })
      )
    }
    if (apis.calendarListAPI.dataChanged) {
      delete apis.calendarListAPI.dataChanged
      apiRequests.push(
        updateCalendarInUserCalendarList({
          auth,
          calendar: apis.calendarListAPI,
          calendarId,
        })
      )
    }
    if (apiRequests.length > 0) {
      dispatch(updateCalendarAction.request(calendar))
      Promise.all(apiRequests).then(
        () => {
          dispatch(updateCalendarAction.success())
          dispatch(analytics.calendar.useUpdateCalendarAction())

          // INFO: log the toggle action when the calendar is selected or not
          if (
            data.selected !== undefined &&
            oldCalendar.selected !== data.selected
          ) {
            dispatch(analytics.calendar.useToggleCalendar(data.selected))
          }
        },
        e => {
          dispatch(showNotification(ServerError, toastVariants.error))
          dispatch(
            updateCalendarAction.failure({
              message: e.message,
              calendar: oldCalendar,
            })
          )
        }
      )
    } else {
      dispatch(updateCalendarAction.success())
    }
  }
}
