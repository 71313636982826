import {
  cleanupStatus,
  cleanupTypes,
} from '@edison/webmail-core/utils/constants'
import CardModalContent, {
  PlaceholderBottom,
  ModelMode,
} from '@edison/webmail-ui/components/Cards/CardModal'
import { Bottom } from '@edison/webmail-ui/screens/SuggestedCleanup/Calendar'
import ThreadList from '@edison/webmail-ui/components/ThreadList'
import { listCleanup, updateCleanup } from 'core/cleanup/actions'
import { getCleanup } from 'core/cleanup/selectors'
import { useCard } from 'core/contacts/hooks'
import { getPendingContacts } from 'core/contacts/selectors'
import { getEmailNudges } from 'core/email-nudges/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { getThreadIdsLabelIdsMap } from 'core/metadata/selectors'
import { batchGetThreads } from 'core/threads/actions'
import { useKeyboardNavigation } from 'hooks/useKeyboardNavigation'
import get from 'lodash/get'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ThreadListItem from 'screens/ContactDetailModal/ThreadListItem'
import SuggestedCardDetail from 'screens/SuggestedBlockCard/SugeestedBlockBody'
import { cardTypes, labelNames } from 'utils/constants'

const Body = ({ type, onClick }) => {
  const cleanupDetail = useSelector(useMemo(() => getCleanup(type), [type]))
  const threadIds = get(cleanupDetail, 'threadIds', [])
  const threadIdsLabelIdsMap = useSelector(
    useMemo(() => getThreadIdsLabelIdsMap(threadIds), [threadIds])
  )

  const inboxThreadIds = useMemo(
    () =>
      threadIds.filter(
        item =>
          threadIdsLabelIdsMap[item] &&
          threadIdsLabelIdsMap[item].includes(labelNames.inbox)
      ),
    [threadIds, threadIdsLabelIdsMap]
  )

  const nextPageToken = get(cleanupDetail, 'nextPageToken', '')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (threadIds.length) return
    setLoading(true)
    dispatch(listCleanup(type)).then(() => setLoading(false))
  }, [])

  useEffect(() => {
    setLoading(true)
    !!threadIds.length &&
      dispatch(batchGetThreads(threadIds)).then(() => setLoading(false))
  }, [threadIds])

  function renderItem({ index, style, key }) {
    return (
      <ThreadListItem
        isShowLables
        classname="bg-app dark:bg-card"
        onClick={onClick}
        style={style}
        key={inboxThreadIds[index]}
        threadId={inboxThreadIds[index]}
      />
    )
  }

  const loadMore = () => {
    if (nextPageToken && !loading) {
      setLoading(true)
      dispatch(listCleanup(type, nextPageToken)).then(() => setLoading(false))
    }
  }

  if (!loading && !inboxThreadIds.length)
    return (
      <div className="px-10">
        Opps, there is no any message you need handle.
      </div>
    )
  return (
    <ThreadList
      total={
        nextPageToken
          ? inboxThreadIds.length + 5
          : loading
          ? 5
          : inboxThreadIds.length
      }
      renderItem={renderItem}
      loadMore={loadMore}
      isLoaded={index => !!inboxThreadIds[index]}
    />
  )
}

const actions = {
  'Mark as Done': {
    addLabelIds: [labelNames.archive],
    removeLabelIds: [labelNames.inbox],
  },
  'Mark as Read': { removeLabelIds: [labelNames.unread] },
  Delete: {
    addLabelIds: [labelNames.trash],
    removeLabelIds: [labelNames.inbox],
  },
}

const cleanupTypeNames = {
  [cleanupTypes.CALENDAR]: 'Calendar',
  [cleanupTypes.ZOOM]: 'ZOOM',
  [cleanupTypes.WEBEX]: 'Webex',
}

export default function index({ onClose, type, total, onNextNavigate }) {
  const dispatch = useDispatch()
  const updating = useSelector(
    useMemo(() => getLoadingStatus('CLEANUP_UPDATE'), [])
  )
  const { t } = useTranslation()
  const [cleanupType, setCleanType] = useState(type)
  const [threadId, setThreadId] = useState('')
  const cleanup = useSelector(
    useMemo(() => getCleanup(cleanupType), [cleanupType])
  )
  const pending = useSelector(getPendingContacts())
  const ref = useRef()
  const nudges = useSelector(getEmailNudges)
  const {
    onNextCard,
    onPreviousCard,
    currIdx,
    hasNext,
    hasPrevious,
  } = useCard(cardTypes.cleanup, cleanupType, onNextNavigate, ({ type }) =>
    setCleanType(type)
  )

  const onLeftArrow = () => {
    if (ref.current && ref.current.mode === ModelMode.DETAIL) {
      ref.current.setModeList()
    } else {
      hasPrevious && onPreviousCard()
    }
  }

  const onRightArrow = () => {
    if (ref.current && ref.current.mode === ModelMode.LIST) {
      hasNext && onNextCard()
    }
  }

  useKeyboardNavigation(
    {
      onPrevious: () => onLeftArrow(),
      onNext: () => onRightArrow(),
    },
    [hasPrevious, hasNext, onPreviousCard, onNextCard]
  )

  const onClick = threadId => {
    setThreadId(threadId)
  }

  const autoCloseWithoutNextCard = () => {
    if (hasNext) {
      onNextCard()
    } else {
      onClose()
    }
  }

  const onIgnore = () => {
    dispatch(updateCleanup(type, cleanupStatus.IGNORED)).then(() =>
      autoCloseWithoutNextCard()
    )
  }

  const onAction = (action, createFilter) => {
    dispatch(
      updateCleanup(type, cleanupStatus.EXECUTED, {
        action: actions[action],
        createFilter,
      })
    ).then(() => autoCloseWithoutNextCard())
  }

  return (
    <CardModalContent
      ref={ref}
      total={total}
      style={{ height: 'calc(100% - 180px)' }}
      currIdx={currIdx + pending.length + nudges.length}
      onClose={onClose}
      onPrevious={onPreviousCard}
      onNext={onNextCard}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      title={t('suggested.clean.modal.title')}
      bottom={
        get(cleanup, 'threadIds', []).length ? (
          <Bottom
            actions={['Delete', 'Mark as Done', 'Mark as Read']}
            cleanupType={cleanupTypeNames[type]}
            disabled={updating}
            onIgnore={onIgnore}
            onAction={onAction}
          />
        ) : (
          <PlaceholderBottom />
        )
      }
      cardDetail={({ setModeList }) => (
        <SuggestedCardDetail
          onViewInCalendar={onClose}
          threadId={threadId}
          onPrevious={setModeList}
        />
      )}
    >
      {({ setModeDetail }) =>
        cleanup && (
          <Body
            onClick={threadId => {
              setModeDetail()
              onClick(threadId)
            }}
            type={cleanupType}
          />
        )
      }
    </CardModalContent>
  )
}
