// @flow
import { createSelector } from 'reselect'

import type { State, Selector } from 'types/state'
import type { State as SignatureImageState } from './types'
import type { SignatureImage } from '@edison/webmail-core/types/signature-images'

export const selectSignatureImagesState = (state: State) =>
  state.signatureImages

export function getAllSignatureImages(): Selector<Array<SignatureImage>> {
  return createSelector(
    selectSignatureImagesState,
    (state: SignatureImageState) => Object.values(state)
  )
}

export function getAllSignatureImagesById(): Selector<Array<string>> {
  return createSelector(
    selectSignatureImagesState,
    (state: SignatureImageState) => Object.keys(state)
  )
}
