// @flow
import React, { useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isNil from 'lodash/isNil'
import _values from 'lodash/values'

import AddDNSRecordModal, {
  NameserverForm,
  AForm,
  CNAMEForm,
  MXForm,
  TXTForm,
  SRVForm,
  CAAForm,
  AAAAForm,
  ErrorMessage,
} from '@edison/webmail-ui/components/DomainDNS/AddDNSRecordModal'
import * as customDomains from 'core/custom-domains'
import { useDnsRecords } from 'core/custom-domains/hooks'

import type { Dispatch } from 'types/redux'
import type { DnsInfo } from '@edison/webmail-core/types/custom-domains'

type Props = {
  isOpen: boolean,
  toggle: () => mixed,
}

const View = ({ isOpen, toggle }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const writeRecords = useSelector(customDomains.selectors.getDnsWriteRecords)
  const isLoading = useSelector(customDomains.selectors.updateDnsRecordsLoading)
  const errors = useSelector(customDomains.selectors.updateDnsRecordsErrors)
  const { types, validation } = useDnsRecords()
  const [type, setType] = useState(types.NS)
  const forms = useMemo(
    () => ({
      [types.NS]: NameserverForm,
      [types.A]: AForm,
      [types.CNAME]: CNAMEForm,
      [types.MX]: MXForm,
      [types.TXT]: TXTForm,
      [types.SRV]: SRVForm,
      [types.CAA]: CAAForm,
      [types.AAAA]: AAAAForm,
    }),
    []
  )

  const error = errors && (
    <ErrorMessage message={errors.message} fields={errors.fields} />
  )

  const onConfirm = (record: DnsInfo) => {
    dispatch(
      customDomains.actions.updateDnsRecords([...writeRecords, record])
    ).then(success => {
      if (success) {
        toggle()
      }
    })
  }

  // Multiplex based on form type
  const Form = forms[type]
  const validationSchema = validation[type]

  return (
    <AddDNSRecordModal
      isLoading={isLoading}
      isOpen={isOpen}
      toggle={toggle}
      error={error}
      type={type}
      types={_values(types).map(item => ({ label: item, value: item }))}
      onChangeType={setType}
    >
      {!isNil(Form) && (
        <Form
          isLoading={isLoading}
          onSubmit={onConfirm}
          onCancel={() => isOpen && toggle()}
          validationSchema={validationSchema}
        />
      )}
    </AddDNSRecordModal>
  )
}

View.displayName = 'AddDNSRecordModal'

export default View
