// @flow
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import values from 'lodash/values'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'
import { createSelector } from 'reselect'
import { matchPath } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import * as storage from 'common/storage'
import { getLoadingStatus } from 'core/loading/selectors'
import { isStringEqual } from 'utils'
import {
  routePaths,
  usernameRequireInvite,
  usernameLengths,
  usernameTypes,
  usernameMinLength,
} from 'utils/constants'

import type { SessionAccount, ASPEntity } from '@edison/webmail-core/types/auth'
import type { State as AuthState } from './types'
import type { State, Selector } from 'types/state'

export const selectAuthState = (state: State) => state.auth

export function isAuthenticated(): Selector<boolean> {
  return createSelector(
    selectAuthState,
    (auth: AuthState) => auth.info !== null
  )
}

export function getAuth(): Selector<$PropertyType<AuthState, 'info'>> {
  return createSelector(selectAuthState, (auth: AuthState) => auth.info)
}

export const getAuthUser: Selector<string> = createSelector(getAuth(), auth =>
  get(auth, 'user', '')
)

export function isUsernameTaken(): Selector<?boolean> {
  return createSelector(
    selectAuthState,
    (auth: AuthState) => auth.signup.usernameTaken
  )
}

export function isVerifyingUsername(init: boolean = false): Selector<boolean> {
  return createSelector(
    selectAuthState,
    getLoadingStatus('AUTH_CHECK_USERNAME', init),
    (autState, isLoading) => {
      return isLoading || !isNil(get(autState, 'requestIds.check'))
    }
  )
}

const _isSignupLoading = createSelector(
  getLoadingStatus('AUTH_SIGNUP'),
  getLoadingStatus('SUBACCOUNT_SIGNUP'),
  (isAuthSignup: boolean, isSubaccountSignup: boolean) => {
    return isAuthSignup || isSubaccountSignup
  }
)

export function isSignupLoading(): Selector<boolean> {
  return _isSignupLoading
}

export function isValidatingInvite(): Selector<boolean> {
  return getLoadingStatus('AUTH_VERIFY_INVITATION', true)
}

export const getTokenExpiry: Selector<?number> = createSelector(
  selectAuthState,
  auth => {
    const token = get(auth, 'info.token')
    if (isNil(token)) {
      return null
    }

    try {
      return jwtDecode(token).exp
    } catch (e) {
      return null
    }
  }
)

export const getTokenRefreshLoading: Selector<boolean> = getLoadingStatus(
  'AUTH_REFRESH'
)

export const getInitialRefresh: Selector<boolean> = createSelector(
  selectAuthState,
  state => state.refreshed
)

export const getUsernameMinLength: Selector<number> = createSelector(
  selectAuthState,
  state => {
    if (state.invitation) {
      const usernameType = get(state, 'invitation.type')

      // Use the defined username length for the defined types
      return get(usernameLengths[usernameType], 'min', usernameMinLength)
    }

    // Select the minimum length of username allowed across all plans
    // that does not require an invite.
    const minLengths = Object.keys(usernameRequireInvite)
      .filter(type => !usernameRequireInvite[type])
      .map(type => usernameLengths[type].min)

    return Math.min(...minLengths)
  }
)

export const getUsernamePremiumBounds: Selector<{
  min: number,
  max: number,
}> = createSelector(selectAuthState, state => ({
  min: usernameLengths[usernameTypes.extraShort].min,
  max: usernameLengths[usernameTypes.regular].min,
}))

export const getInvite: Selector<?{
  code: string,
  email: string,
}> = createSelector(selectAuthState, state => {
  if (!state.invitation) {
    return null
  }

  return {
    code: get(state, 'invitation.code'),
    email: get(state, 'invitation.email'),
  }
})

export const isDeleteAccountLoading = getLoadingStatus('DELETE_ACCOUNT')

export const isForgotPasswordLoading = getLoadingStatus('AUTH_FORGOT_PASSWORD')
export const isResetPasswordLoading = getLoadingStatus('AUTH_RESET_PASSWORD')

export const isLoginLoading = getLoadingStatus('AUTH_LOGIN')

export const getSessionAccounts: Selector<
  $ReadOnlyArray<SessionAccount>
> = createSelector(selectAuthState, state =>
  orderBy(
    // $FlowFixMe
    values(state.session.accounts),
    account => toNumber(account.orderId),
    ['asc']
  )
)

export function makeGetSessionAccount(
  orderId: string
): Selector<?SessionAccount> {
  return createSelector(
    selectAuthState,
    state => state.session.accounts[orderId]
  )
}

export const getOrderId: Selector<?string> = createSelector(
  state => state.router,
  (router: any) => {
    const match = matchPath(router.location.pathname, {
      path: routePaths.AUTH_PREFIX,
    })

    const orderId: ?string = isNil(match) ? null : match.params.userId
    return orderId
  }
)

export const isSuperSessionLoading = getLoadingStatus('GET_SESSION', true)

export function makeIsValidSession(orderId: string): Selector<boolean> {
  return createSelector(
    makeGetSessionAccount(orderId),
    () => storage.getUser(orderId),
    () => storage.getCsrfToken(orderId),
    (account, user, csrf) => {
      return Boolean(
        account &&
          account.validSession &&
          user &&
          csrf &&
          isStringEqual(account.emailAddress, user)
      )
    }
  )
}

export const getAppPasswords: Selector<
  $ReadOnlyArray<ASPEntity>
> = createSelector(selectAuthState, (state: AuthState) => {
  return values(state.appPasswords).sort((a, b) =>
    a.createTime > b.createTime ? -1 : 1
  )
})

export const isPasswordAuthLoading = getLoadingStatus('PASSWORD_AUTH')
export const isAppPasswordCreating = getLoadingStatus('CREATE_APP_PASSWORD')
