import last from 'lodash/last'
import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getNewMessageToastMessages } from 'core/messages/selectors'
import { batchGetMessages } from 'core/messages/actions'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Button from '@edison/webmail-ui/components/Button'
import Sender from 'common/Sender'
import Slide from '@material-ui/core/Slide'

function NewMessageToast({ messages }) {
  const dispatch = useDispatch()

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <div className="fixed z-10" style={{ bottom: 100, left: '50%' }}>
        <Button
          className="inline-flex items-center shadow !py-3 rounded-full text-xs"
          style={{ maxWidth: 300, transform: 'translateX(-50%)' }}
          onClick={() => {
            const messageIds = messages.map(item => item.id)

            dispatch(batchGetMessages(messageIds))
          }}
        >
          <span className="truncate mr-2">
            New Message From <Sender {...last(messages).from} noMe />
          </span>
          <ArrowDownwardIcon />
        </Button>
      </div>
    </Slide>
  )
}

export default ({ threadId }) => {
  const messages = useSelector(
    useMemo(() => getNewMessageToastMessages(threadId), [threadId])
  )

  return messages.length ? <NewMessageToast messages={messages} /> : null
}
