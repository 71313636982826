// @flow
import { createSelector } from 'reselect'
import { largeAttachmentStatus } from '@edison/webmail-ui/utils/constants'
import { hasLargeAttachmentSuccessComplete } from 'utils'
import { getDrafts } from 'core/compose/selectors'
import { composeStatus } from 'utils/constants'
import type { LargeAttachment } from '@edison/webmail-core/types/large-attachments'
import type { State as LargeAttachmentState } from './types'
import type { State, Selector } from 'types/state'

export const selectLargeAttachmentState = (state: State) =>
  state.largeAttachments

export function getAllAttachments(): Selector<Array<LargeAttachment>> {
  return createSelector(
    selectLargeAttachmentState,
    (state: LargeAttachmentState) => Object.values(state)
  )
}

export function getLargeAttachmentState(): Selector<LargeAttachmentState> {
  return createSelector(
    selectLargeAttachmentState,
    (state: LargeAttachmentState) => state
  )
}

export function getLargeAttachment(
  attachmentUuid: string
): Selector<LargeAttachment> {
  return createSelector(
    selectLargeAttachmentState,
    (state: LargeAttachmentState) => state[attachmentUuid]
  )
}

export function getAttachmentsByIds(
  attachmentUuids: Array<string>
): Selector<Array<LargeAttachment>> {
  return createSelector(
    selectLargeAttachmentState,
    (state: LargeAttachmentState) =>
      attachmentUuids.filter(item => item).map(item => state[item])
  )
}

export function getNotDeletedAttachments(): Selector<Array<LargeAttachment>> {
  return createSelector(
    getAllAttachments(),
    attachments =>
      attachments.filter(
        ({ status }) => status !== largeAttachmentStatus.DELETING
      )
  )
}

export function getPendingAttachments(): Selector<Array<LargeAttachment>> {
  return createSelector(
    getNotDeletedAttachments(),
    getDrafts(),
    (attachments, drafts) => {
      return attachments.filter(item => {
        const draft = drafts.find(draft =>
          draft.largeAttachmentUuids.includes(item.uuid)
        )
        if (!draft) return false
        if (
          draft &&
          [composeStatus.deleted, composeStatus.sended].includes(draft.status)
        )
          return false
        return !hasLargeAttachmentSuccessComplete(item)
      })
    }
  )
}
