// @flow
import React from 'react'
import { useSelector } from 'react-redux'

import * as selectors from 'core/auth/selectors'
import { usernameMinLength } from 'utils/constants'

import ChangeEmailConfirmation from '@edison/webmail-ui/components/PremiumPlans/ChangeEmailConfirmation'
import { InlineEmailAddress } from 'screens/SignUp/ChooseEmailAddress'

const usernameTakenSelector = selectors.isUsernameTaken()
const usernameVerifyingSelector = selectors.isVerifyingUsername()

type Props = {
  email: string,
  isOpen: boolean,
  toggle: () => void,
  onConfirm: string => void,
}

export default ({ email, isOpen, toggle, onConfirm }: Props) => {
  const isUsernameTaken = useSelector(usernameTakenSelector)
  const isVerifyingUsername = useSelector(usernameVerifyingSelector)

  return (
    <ChangeEmailConfirmation
      email={email}
      isOpen={isOpen}
      toggle={toggle}
      onConfirm={onConfirm}
      minLength={usernameMinLength}
      isValid={!(isUsernameTaken || isVerifyingUsername)}
    >
      <InlineEmailAddress minLength={usernameMinLength} />
    </ChangeEmailConfirmation>
  )
}
