// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import isNil from 'lodash/isNil'

import { actions, selectors } from 'core/contacts'
import ContactForm from '@edison/webmail-ui/components/ContactForm'
import AuthAvatar from 'common/AuthAvatar'

import type { Contact } from '@edison/webmail-core/types/contacts'
import type { Dispatch } from 'types/redux'

const contactUpdatingSelector = selectors.isContactUpdateLoading()

type Props = {
  isOpen: boolean,
  onClose: () => void,
  contact: Contact,
}

const ContactsModalForm = ({ isOpen, onClose, contact }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const isSubmitting = useSelector(contactUpdatingSelector)
  const name = [contact.firstName, contact.lastName]
    .filter(each => !!each)
    .join(' ')

  async function handleSubmit(newContact) {
    if (isNil(contact)) {
      console.error('Invalided contact ID')
      return
    }

    let avatarUrl = get(contact, 'avatar', '')
    const currAvatar = get(newContact, 'avatar', '')

    if (avatarUrl !== currAvatar) {
      avatarUrl = await dispatch(actions.uploadAvatar(contact.id, currAvatar))
    }

    const { emails, phones, websites } = newContact
    await dispatch(
      actions.updateContact({
        ...newContact,
        avatar: avatarUrl,
        emails: emails.filter(item => !!item.email),
        phones: phones.filter(item => !!item.phone),
        websites: websites.filter(item => !!item.website),
      })
    )
    return onClose()
  }

  return (
    <ContactForm
      enableToSubmit={!isSubmitting}
      onClose={onClose}
      onSubmit={handleSubmit}
      contact={contact}
      renderAvatar={({ src }) => (
        <AuthAvatar className="!w-24 !h-24 text-4xl" src={src} alt={name} />
      )}
    />
  )
}
export default ContactsModalForm
