import Recommend, {
  RecommendOptionType,
} from '@edison/webmail-ui/components/Search/Recommend'
import * as actions from 'core/search/actions'
import { useMatchResult, useCache } from 'core/search/hooks'
import * as selectors from 'core/search/selectors'
import React, { forwardRef, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const getSearchQuery = selectors.getSearchQuery()
const getHideSearchRecommend = selectors.getHideSearchRecommend()

function RecommendSearch(props, ref) {
  const { renderProps } = props
  const { blur } = renderProps
  const dispatch = useDispatch()
  const [, update] = useState(0)
  const { matchRecentSearch, matchContacts, matchLabels } = useMatchResult()
  const query = useSelector(getSearchQuery)
  const { clearSearchQuery } = useCache()
  const hideSearchRecommend = useSelector(getHideSearchRecommend)
  const onSelect = (item, type) => {
    blur()
    const lastSpaceIndex = query.lastIndexOf(' ')
    const nextQuery =
      lastSpaceIndex === -1 ? '' : query.slice(0, lastSpaceIndex)
    if (type === RecommendOptionType.Contact) {
      dispatch(
        actions.setSelectContactQuery({
          contact: item,
        })
      )
    } else if (type === RecommendOptionType.Label) {
      dispatch(actions.setSelectedLabels(item))
    } else {
      dispatch(actions.setQuery({ query: item }))
      return
    }
    dispatch(
      actions.setQuery({
        query: nextQuery,
      })
    )
  }

  const options = useMemo(
    () =>
      hideSearchRecommend
        ? []
        : [...matchRecentSearch, ...matchLabels, ...matchContacts],
    [matchRecentSearch, matchLabels, matchContacts, hideSearchRecommend]
  )

  return (
    <Recommend
      ref={ref}
      onSelect={onSelect}
      onClose={content => {
        clearSearchQuery(content)
        update(num => num + 1)
      }}
      query={query}
      wholeQuery={query}
      options={options}
    />
  )
}

export default forwardRef(RecommendSearch)
