// @flow
import React from 'react'
import { useDispatch } from 'react-redux'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

import { actions } from 'core/labels'

import Dialog from '@material-ui/core/Dialog'
import LabelPrompt from '@edison/webmail-ui/components/Labels/LabelPrompt'

import type { Dispatch } from 'types/redux'

type Props = {
  name: string,
  labelId: string,
  isOpen: boolean,
  toggle: () => void,
}

export default ({ isOpen, toggle, labelId, name }: Props) => {
  const dispatch: Dispatch = useDispatch()
  const { hideModal: hideDetailModal } = useModal(modalTypes.labelDetail)

  function handleConfirm() {
    toggle()
    // Need to close the detail modal
    hideDetailModal()
    return dispatch(actions.deleteLabel(labelId))
  }

  return (
    <Dialog open={isOpen} onClose={toggle}>
      <LabelPrompt name={name} onConfirm={handleConfirm} onClose={toggle} />
    </Dialog>
  )
}
