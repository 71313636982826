//@flow
export const eventsActionTypes: {
  SelectICSEvent: 'CALENDAR_SELECT_ICS_EVENT',
  DeselectICSEvent: 'CALENDAR_DESELECT_ICS_EVENT',
  SelectUIScopeEvent: 'CALENDAR_SELECT_UI_SCOPE_EVENT',
  DeselectUIScopeEvent: 'CALENDAR_DESELECT_UI_SCOPE_EVENT',
  UpdateUIScopeEventRange: 'CALENDAR_UPDATE_UI_SCOPE_EVENT_RANGE',
  FetchICSEventDataRequest: 'CALENDAR_FETCH_ICS_EVENT_DATA_REQUEST',
  FetchICSEventDataSuccess: 'CALENDAR_FETCH_ICS_EVENT_DATA_SUCCESS',
  FetchICSEventDataFailure: 'CALENDAR_FETCH_ICS_EVENT_DATA_FAILURE',
  FetchEventsRequest: 'CALENDAR_FETCH_EVENTS_REQUEST',
  FetchEventsRequestSuccess: 'CALENDAR_FETCH_EVENTS_SUCCESS',
  FetchEventsRequestFailure: 'CALENDAR_FETCH_EVENTS_FAILURE',
  FetchEventOrInstanceRequest: 'CALENDAR_FETCH_EVENT_OR_INSTANCE_REQUEST',
  FetchEventOrInstanceRequestSuccess: 'CALENDAR_FETCH_EVENT_OR_INSTANCE_SUCCESS',
  FetchEventOrInstanceRequestFailure: 'CALENDAR_FETCH_EVENT_OR_INSTANCE_FAILURE',
  AddSimpleEventRequest: 'CALENDAR_ADD_SIMPLE_EVENT_REQUEST',
  AddSimpleEventRequestSuccess: 'CALENDAR_ADD_SIMPLE_EVENT_SUCCESS',
  AddSimpleEventRequestFailure: 'CALENDAR_ADD_SIMPLE_EVENT_FAILURE',
  AddEventRequest: 'CALENDAR_AddEvent_REQUEST',
  AddEventRequestSuccess: 'CALENDAR_ADD_EVENT_SUCCESS',
  AddEventRequestFailure: 'CALENDAR_ADD_EVENT_FAILURE',
  DeleteSingleEventInstanceRequest: 'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_REQUEST',
  DeleteSingleEventInstanceRequestSuccess: 'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_SUCCESS',
  DeleteSingleEventInstanceRequestFailure: 'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_FAILURE',
  DeleteEventRequest: 'CALENDAR_DELETE_EVENT_REQUEST',
  DeleteEventRequestSuccess: 'CALENDAR_DELETE_EVENT_SUCCESS',
  DeleteEventRequestFailure: 'CALENDAR_DELETE_EVENT_FAILURE',
  DeleteCurrentAndFollowingEventInstancesRequest: 'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_REQUEST',
  DeleteCurrentAndFollowingEventInstancesRequestSuccess: 'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_SUCCESS',
  DeleteCurrentAndFollowingEventInstancesRequestFailure: 'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_FAILURE',
  UpdateSingleEventInstanceRequest: 'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_REQUEST',
  UpdateSingleEventInstanceRequestSuccess: 'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_SUCCESS',
  UpdateSingleEventInstanceRequestFailure: 'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_FAILURE',
  UpdateEventOrInstanceStatusRequest: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_REQUEST',
  UpdateEventOrInstanceStatusSuccess: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_SUCCESS',
  UpdateEventOrInstanceStatusFailure: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_FAILURE',
  UpdateEventSimpleDataRequest: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_REQUEST',
  UpdateEventSimpleDataSuccess: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_SUCCESS',
  UpdateEventSimpleDataFailure: 'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_FAILURE',
  UpdateEventRequest: 'CALENDAR_UPDATE_EVENT_REQUEST',
  UpdateEventRequestSuccess: 'CALENDAR_UPDATE_EVENT_SUCCESS',
  UpdateEventRequestFailure: 'CALENDAR_UPDATE_EVENT_FAILURE',
  UpdateAndAddEventRequest: 'CALENDAR_UPDATE_AND_ADD_EVENT_REQUEST',
  UpdateAndAddEventRequestSuccess: 'CALENDAR_UPDATE_AND_ADD_EVENT_SUCCESS',
  UpdateAndAddEventRequestFailure: 'CALENDAR_UPDATE_AND_ADD_EVENT_FAILURE',
  UpdateRecurringEventAndInstanceRequest: 'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_REQUEST',
  UpdateRecurringEventAndInstanceSuccess: 'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_SUCCESS',
  UpdateRecurringEventAndInstanceFailure: 'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_FAILURE',
  UpdateCurrentAndFollowingEventInstancesRequest: 'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_REQUEST',
  UpdateCurrentAndFollowingEventInstancesRequestSuccess: 'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_SUCCESS',
  UpdateCurrentAndFollowingEventInstancesRequestFailure: 'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_FAILURE',
  UpdateEventAttendeeDataRequest: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_REQUEST',
  UpdateEventAttendeeDataSuccess: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_SUCCESS',
  UpdateEventAttendeeDataFailure: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_FAILURE',
} = {
  SelectICSEvent: 'CALENDAR_SELECT_ICS_EVENT',
  DeselectICSEvent: 'CALENDAR_DESELECT_ICS_EVENT',
  SelectUIScopeEvent: 'CALENDAR_SELECT_UI_SCOPE_EVENT',
  DeselectUIScopeEvent: 'CALENDAR_DESELECT_UI_SCOPE_EVENT',
  UpdateUIScopeEventRange: 'CALENDAR_UPDATE_UI_SCOPE_EVENT_RANGE',
  FetchICSEventDataRequest: 'CALENDAR_FETCH_ICS_EVENT_DATA_REQUEST',
  FetchICSEventDataSuccess: 'CALENDAR_FETCH_ICS_EVENT_DATA_SUCCESS',
  FetchICSEventDataFailure: 'CALENDAR_FETCH_ICS_EVENT_DATA_FAILURE',
  FetchEventsRequest: 'CALENDAR_FETCH_EVENTS_REQUEST',
  FetchEventsRequestSuccess: 'CALENDAR_FETCH_EVENTS_SUCCESS',
  FetchEventsRequestFailure: 'CALENDAR_FETCH_EVENTS_FAILURE',
  FetchEventOrInstanceRequest: 'CALENDAR_FETCH_EVENT_OR_INSTANCE_REQUEST',
  FetchEventOrInstanceRequestSuccess:
    'CALENDAR_FETCH_EVENT_OR_INSTANCE_SUCCESS',
  FetchEventOrInstanceRequestFailure:
    'CALENDAR_FETCH_EVENT_OR_INSTANCE_FAILURE',
  AddSimpleEventRequest: 'CALENDAR_ADD_SIMPLE_EVENT_REQUEST',
  AddSimpleEventRequestSuccess: 'CALENDAR_ADD_SIMPLE_EVENT_SUCCESS',
  AddSimpleEventRequestFailure: 'CALENDAR_ADD_SIMPLE_EVENT_FAILURE',
  AddEventRequest: 'CALENDAR_AddEvent_REQUEST',
  AddEventRequestSuccess: 'CALENDAR_ADD_EVENT_SUCCESS',
  AddEventRequestFailure: 'CALENDAR_ADD_EVENT_FAILURE',
  DeleteSingleEventInstanceRequest:
    'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_REQUEST',
  DeleteSingleEventInstanceRequestSuccess:
    'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_SUCCESS',
  DeleteSingleEventInstanceRequestFailure:
    'CALENDAR_DELETE_SINGLE_EVENT_INSTANCE_FAILURE',
  DeleteEventRequest: 'CALENDAR_DELETE_EVENT_REQUEST',
  DeleteEventRequestSuccess: 'CALENDAR_DELETE_EVENT_SUCCESS',
  DeleteEventRequestFailure: 'CALENDAR_DELETE_EVENT_FAILURE',
  DeleteCurrentAndFollowingEventInstancesRequest:
    'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_REQUEST',
  DeleteCurrentAndFollowingEventInstancesRequestSuccess:
    'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_SUCCESS',
  DeleteCurrentAndFollowingEventInstancesRequestFailure:
    'CALENDAR_DELETE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_FAILURE',
  UpdateSingleEventInstanceRequest:
    'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_REQUEST',
  UpdateSingleEventInstanceRequestSuccess:
    'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_SUCCESS',
  UpdateSingleEventInstanceRequestFailure:
    'CALENDAR_UPDATE_SINGLE_EVENT_INSTANCE_FAILURE',
  UpdateEventRequest: 'CALENDAR_UPDATE_EVENT_REQUEST',
  UpdateEventRequestSuccess: 'CALENDAR_UPDATE_EVENT_SUCCESS',
  UpdateEventRequestFailure: 'CALENDAR_UPDATE_EVENT_FAILURE',
  UpdateEventOrInstanceStatusRequest:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_REQUEST',
  UpdateEventOrInstanceStatusSuccess:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_SUCCESS',
  UpdateEventOrInstanceStatusFailure:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_STATUS_FAILURE',
  UpdateEventSimpleDataRequest:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_REQUEST',
  UpdateEventSimpleDataSuccess:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_SUCCESS',
  UpdateEventSimpleDataFailure:
    'CALENDAR_UPDATE_EVENT_OR_INSTANCE_SIMPLE_DATA_FAILURE',
  UpdateAndAddEventRequest: 'CALENDAR_UPDATE_AND_ADD_EVENT_REQUEST',
  UpdateAndAddEventRequestSuccess: 'CALENDAR_UPDATE_AND_ADD_EVENT_SUCCESS',
  UpdateAndAddEventRequestFailure: 'CALENDAR_UPDATE_AND_ADD_EVENT_FAILURE',
  UpdateRecurringEventAndInstanceRequest:
    'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_REQUEST',
  UpdateRecurringEventAndInstanceSuccess:
    'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_SUCCESS',
  UpdateRecurringEventAndInstanceFailure:
    'CALENDAR_UPDATE_RECURRING_EVENT_AND_INSTANCE_FAILURE',
  UpdateCurrentAndFollowingEventInstancesRequest:
    'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_REQUEST',
  UpdateCurrentAndFollowingEventInstancesRequestSuccess:
    'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_SUCCESS',
  UpdateCurrentAndFollowingEventInstancesRequestFailure:
    'CALENDAR_UPDATE_CURRENT_AND_FOLLOWING_EVENT_INSTANCES_FAILURE',
  UpdateEventAttendeeDataRequest: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_REQUEST',
  UpdateEventAttendeeDataSuccess: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_SUCCESS',
  UpdateEventAttendeeDataFailure: 'CALENDAR_UPDATE_EVENT_ATTENDEE_DATA_FAILURE',
}
export const recurringEventModifyType: {
  single: 'single',
  all: 'all',
  thisAndFollowing: 'thisAndFollowing',
} = {
  single: 'single',
  all: 'all',
  thisAndFollowing: 'thisAndFollowing',
}
export const calendarResolution: {
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Year: 'year',
} = {
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Year: 'year',
}
