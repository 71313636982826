// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import cn from 'classnames'
import { cardTypes } from 'utils/constants'
import {
  isContactListLoading,
  isSuggestedBlockListLoading,
  getPendingContacts,
  getSuggestedBlockContacts,
} from 'core/contacts/selectors'
import { getFormattedContact } from 'core/contacts/helpers'
import { isNudgeListLoading, getEmailNudges } from 'core/email-nudges/selectors'
import { isInboxZero } from 'core/threads/selectors'
import {
  getCleanupSelector,
  isFetchCleanupListLoading,
} from 'core/cleanup/selectors'
import CardSkeleton from '@edison/webmail-ui/components/Skeletons/SenderCard'
import Container from '@edison/webmail-ui/components/ApproveSender'
import CardsContainer, {
  COLUMN_WIDTH,
  ROW_HEIGHT,
} from '@edison/webmail-ui/components/Cards'
import SenderCard from 'screens/ApproveSender/Card'
import EmailNudgeCard from 'screens/EmailNudgeCard'
import SuggestedBlockCard from 'screens/SuggestedBlockCard'
import CleanupCard from './CleanupCard'

const contactListLoadingSelector = isContactListLoading()
const pendingContactsSelector = getPendingContacts()
const inboxZeroSelector = isInboxZero()

const Childrens = {
  [cardTypes.suggestedBlock]: SuggestedBlockCard,
  [cardTypes.nudges]: EmailNudgeCard,
  [cardTypes.senders]: SenderCard,
  [cardTypes.cleanup]: CleanupCard,
}

const Views = () => {
  const nudges = useSelector(getEmailNudges)
  const senders = useSelector(pendingContactsSelector)
  const suggestedBlock = useSelector(getSuggestedBlockContacts)
  const isInboxZero = useSelector(inboxZeroSelector)
  const cleanup = useSelector(getCleanupSelector)

  const isNudgeLoading = useSelector(isNudgeListLoading)
  const isContactLoading = useSelector(contactListLoadingSelector)
  const isSuggestedBlockLoading = useSelector(isSuggestedBlockListLoading)
  const isFetchCleanupLoading = useSelector(isFetchCleanupListLoading)

  const isLoading =
    isNudgeLoading ||
    isContactLoading ||
    isSuggestedBlockLoading ||
    isFetchCleanupLoading

  const { data, groups } = useMemo(() => {
    const data = {
      [cardTypes.senders]: senders.map((sender, idx) => {
        const { email, name } = getFormattedContact(sender)

        return {
          idx,
          name,
          email,
          id: sender.id,
          type: cardTypes.senders,
        }
      }),
      [cardTypes.nudges]: nudges.map(nudge => ({
        ...nudge,
        type: cardTypes.nudges,
      })),

      [cardTypes.cleanup]: [
        ...cleanup.map(item => ({
          ...item,
          id: item.type,
          type: cardTypes.cleanup,
        })),
        ...suggestedBlock.map(suggested => ({
          ...suggested,
          type: cardTypes.suggestedBlock,
        })),
      ],
    }

    const groups = [
      cardTypes.senders,
      cardTypes.nudges,
      cardTypes.cleanup,
    ].filter(type => get(data, type, []).length > 0)

    return { data, groups }
  }, [senders, nudges, suggestedBlock, cleanup])

  if (
    senders.length === 0 &&
    nudges.length === 0 &&
    suggestedBlock.length === 0 &&
    cleanup.length === 0
  ) {
    return null
  }

  return (
    <Container invert={isInboxZero}>
      {isLoading ? (
        <CardSkeleton style={{ width: COLUMN_WIDTH, height: ROW_HEIGHT }} />
      ) : (
        <CardsContainer
          enableCollapsed={groups.length > 1}
          data={data}
          groups={groups}
          invert={isInboxZero}
          overscanColumnCount={1}
          cellRenderer={({
            key,
            data,
            listRef,
            getStackedCount,
            columnIndex,
            style,
            ...rest
          }) => {
            const { type, id, _className } = data
            const stackedCount = getStackedCount(type)
            const Cell = get(Childrens, type, React.Fragment)

            const children = (
              <Cell
                {...data}
                {...rest}
                listRef={listRef}
                invert={isInboxZero}
                stack={stackedCount}
                baseWidth={COLUMN_WIDTH}
              />
            )

            return (
              <div
                key={`${type}${id}`}
                className={cn('card-list-item', _className)}
                style={style}
              >
                {children}
              </div>
            )
          }}
        />
      )}
    </Container>
  )
}

Views.displayName = 'SmartCards'

export default Views
