// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'

import { getSiftsByThreadId } from 'core/sifts/selectors'
import { getThreadAttachments } from 'core/threads/selectors'

import SiftChip from '@edison/webmail-ui/components/Sift/Chip'
import LabelStrip from '@edison/webmail-ui/components/Labels/LabelStrip'
import AttachmentChip from '@edison/webmail-ui/components/AttachmentChip'
import AttachmentIcon from '@edison/webmail-ui/components/AttachmentIcon'

import { useModal } from 'common/modals'
import { usePreviewModal } from 'core/previews/hooks'
import { modalTypes } from 'utils/constants'

export default ({ threadId }: { threadId: string }) => {
  // Sift
  const siftsByThreadId = useSelector(getSiftsByThreadId)
  const threadSifts = (siftsByThreadId[threadId] || []).filter(
    ({ payload }) => !isEmpty(payload)
  )
  const { showModal: showSiftModal } = useModal(modalTypes.siftDetail)

  const threadAttachments = useSelector(
    useMemo(() => getThreadAttachments(threadId), [threadId])
  )

  const previews = usePreviewModal()

  if (every([threadAttachments, threadSifts], each => each.length === 0)) {
    return null
  }

  return (
    <LabelStrip>
      {threadSifts.map(({ domain, payload }, index) => (
        <SiftChip
          key={`${threadId}_SIFT_${index}`}
          type={domain}
          title={payload.title}
          subtitle={payload.subtitle}
          onClick={e => {
            e.stopPropagation()
            showSiftModal({ domain, payload, threadId })
          }}
        />
      ))}

      {threadAttachments.map((attachment, index) => {
        const { contentType, name, size } = attachment
        const thumbnail = (
          <AttachmentIcon
            fontSize="small"
            contentType={contentType}
            fileName={attachment.name}
          />
        )

        return (
          <AttachmentChip
            key={`${threadId}_${index}`}
            thumbnail={thumbnail}
            fileName={name}
            size={size}
            onClick={() => {
              previews.showModal({ attachments: threadAttachments, index })
            }}
          />
        )
      })}
    </LabelStrip>
  )
}
