// @flow
import { createReducer } from 'utils/redux'
import { fetchCredentialsActions } from './actions'

import type { State, CredentialActions, CredentialListSuccess } from './types'

export const initialState = []

export default createReducer<State, CredentialActions>(initialState, {
  [fetchCredentialsActions.success.toString()]: credentialListSuccess,
})

function credentialListSuccess(state: State, action: CredentialListSuccess) {
  const { credentials } = action.payload
  return [...credentials]
}
