// @flow
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  getCurrentDomainAssets,
  getCurrentWebDomain,
  getOnmailDomain,
  isInOnmail,
  getDomainSearchResults,
} from './selectors'
import * as premium from 'core/premium'
import { formatPrice } from 'utils'
import { defaultFavicon, domainRegex } from 'utils/constants'

import type { Assets } from '@edison/webmail-core/types/custom-domains'

/**
 * Returns the assets for current domain
 *
 */
export const useCurrentAssets = (
  defaultAssets: { iconUrl?: string, logoUrl?: string } = {}
): Assets => {
  const { t } = useTranslation()
  const assets = useSelector(getCurrentDomainAssets)
  const defaultIcon = !!defaultAssets.iconUrl
    ? defaultAssets.iconUrl
    : defaultFavicon
  const defaultLogo = !!defaultAssets.logoUrl
    ? defaultAssets.logoUrl
    : t('onmail.png')

  const { companyName, color } = assets
  const iconUrl = !!assets.iconUrl ? assets.iconUrl : defaultIcon
  const logoUrl = !!assets.logoUrl ? assets.logoUrl : defaultLogo

  return {
    companyName,
    color,
    iconUrl,
    logoUrl,
  }
}

export const useDomain = (): {
  onmailDomain: string,
  webDomain: string,
  isInOnmail: boolean,
} => {
  const domain = useSelector(getCurrentWebDomain)
  const onmailDomain = useSelector(getOnmailDomain)
  const _isInOnmail = useSelector(isInOnmail)

  return {
    onmailDomain,
    webDomain: domain,
    isInOnmail: _isInOnmail,
  }
}

export const useDomainSearch = (
  username: string,
  isUsernameValid: boolean,
  onPurchase: (username: string, domain: string) => mixed
) => {
  const searchResults = useSelector(getDomainSearchResults)
  const basicPlan = useSelector(premium.selectors.getBasicPlan)

  const basicPlanPrice =
    basicPlan && basicPlan.unitPrice && basicPlan.currency
      ? `${formatPrice(basicPlan.unitPrice, basicPlan.currency)}/mth`
      : ''

  const isDomainAvailable = (
    item:
      | $ElementType<$PropertyType<typeof searchResults, 'freeDomains'>, 0>
      | $ElementType<$PropertyType<typeof searchResults, 'premiumDomains'>, 0>
  ) => {
    return (
      isUsernameValid &&
      !!item.domain &&
      domainRegex.test(item.domain) &&
      item.available
    )
  }

  const freeDomains: $ReadOnlyArray<{
    name: string,
    domain: string,
    price: string,
    isAvailable: boolean,
    onPurchase: () => mixed,
  }> = searchResults.freeDomains.map(item => ({
    name: username,
    domain: item.domain,
    price: basicPlanPrice,
    isAvailable: isDomainAvailable(item),
    onPurchase: () => onPurchase(username, item.domain),
  }))

  const premiumDomains: $ReadOnlyArray<{
    name: string,
    domain: string,
    price: string,
    premiumFee: string,
    isAvailable: boolean,
    onPurchase: () => mixed,
  }> = searchResults.premiumDomains.map(item => ({
    name: username,
    domain: item.domain,
    price: basicPlanPrice,
    premiumFee: `${formatPrice(item.price, item.currency)}`,
    isAvailable: isDomainAvailable(item),
    onPurchase: () => onPurchase(username, item.domain),
  }))

  return { freeDomains, premiumDomains }
}

// Available record types, fields, and validations
export const useDnsRecords = () => {
  const types = {
    NS: 'NS',
    A: 'A',
    CNAME: 'CNAME',
    MX: 'MX',
    TXT: 'TXT',
    SRV: 'SRV',
    CAA: 'CAA',
    AAAA: 'AAAA',
  }

  const [type, setType] = useState<$Values<typeof types>>(types.NS)

  const validation = {
    [types.NS]: yup.object().shape({
      name: yup.string().required(),
      data: yup.string().required(),
      ttl: yup.number().required(),
    }),
    [types.A]: yup.object().shape({
      name: yup.string().required(),
      data: yup.string().required(),
      ttl: yup.number().required(),
    }),
    [types.CNAME]: yup.object().shape({
      name: yup.string().required(),
      data: yup.string().required(),
      ttl: yup.number().required(),
    }),
    [types.MX]: yup.object().shape({
      name: yup
        .string()
        .test({
          name: 'is-root',
          message: 'Cannot use @',
          test: value => {
            return value !== '@'
          },
        })
        .required(),
      data: yup.string().required(),
      priority: yup.number().required(),
      ttl: yup.number().required(),
    }),
    [types.TXT]: yup.object().shape({
      name: yup.string().required(),
      data: yup.string().required(),
      ttl: yup.number().required(),
    }),
    [types.SRV]: yup.object().shape({
      service: yup.string().required(),
      protocol: yup.string().required(),
      data: yup.string().required(),
      name: yup.string().required(),
      priority: yup.number().required(),
      weight: yup.number().required(),
      port: yup.number().required(),
      ttl: yup.number().required(),
    }),
    [types.CAA]: yup.object().shape({
      flags: yup.string().required(),
      tag: yup.string().required(),
      data: yup.string().required(),
      name: yup.string().required(),
      ttl: yup.number().required(),
    }),
    [types.AAAA]: yup.object().shape({
      data: yup.string().required(),
      name: yup.string().required(),
      ttl: yup.number().required(),
    }),
  }

  // Fields and order of displaying them
  const fields = {
    [types.NS]: ['name', 'data', 'ttl'],
    [types.A]: ['name', 'data', 'ttl'],
    [types.CNAME]: ['name', 'data', 'ttl'],
    [types.MX]: ['name', 'data', 'priority', 'ttl'],
    [types.TXT]: ['name', 'data', 'ttl'],
    [types.SRV]: [
      'service',
      'protocol',
      'data',
      'name',
      'priority',
      'weight',
      'port',
      'ttl',
    ],
    [types.CAA]: ['flags', 'tag', 'data', 'name', 'ttl'],
    [types.AAAA]: ['data', 'name', 'ttl'],
  }

  return { type, setType, types, validation, fields }
}
