//@flow

import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import uuid from 'uuid'
import {
  convertRFCDateTime,
  convertRFCDate,
  convertMomentToInstanceDateId,
  convertMomentToInstanceDateTimeId,
} from '@edison/datetime'
import { Recurrence } from './modal-rrule'
import momentTimezone from 'moment-timezone'
import type {
  CalendarEventInsertResource,
  CalendarEventsResource,
  CalendarEventTimeData,
  CalendarEventUpdateResource,
  CalendarReminderOverrides,
  CalendarAttendee,
} from '@edison/webmail-core/types/calendar'
import type { UnixTimestampInSeconds } from './types-common'
import {
  extractCalendarEventId,
  extractCalendarInstanceDateTimeId,
} from '@edison/webmail-core/utils/calendar'
import { updateRepeatDay } from '../../utils/rrule'
import {
  isAttendeeCurrentUser,
  resetAPIAttendeeRSVPState,
  updateEventAttendeeRSVPStatus,
} from '../../utils/calendar'
import { stringArrayIncludes } from '@edison/functools'
export class ReduxEventTimeData {
  data: CalendarEventTimeData

  constructor(data?: CalendarEventTimeData) {
    this.data = data || {}
  }

  toJSON() {
    return cloneDeep(this.data)
  }

  get date() {
    return this.data.date
  }

  get dateTime() {
    return this.data.dateTime
  }

  get timeZone() {
    return this.data.timeZone
  }

  get timestamp(): UnixTimestampInSeconds {
    if (this.data.date) {
      return this.toMomentDate()
        .utc(true)
        .unix()
    } else {
      return this.toMomentDate().unix()
    }
  }
  get isAllDay() {
    return this.data && !!this.date && !this.dateTime
  }
  mergeTime(timeData: ReduxEventTimeData) {
    if (this.isAllDay || !timeData || timeData.isAllDay) {
      return
    }
    let timeMoment = timeData.toMomentDate()
    const currentMoment = this.toMomentDate()
      .hour(timeMoment.hour())
      .minute(timeMoment.minute())
    this.data.dateTime = currentMoment.format()
  }
  isEndDateChanged(timeData: CalendarEventTimeData, isAllDay: boolean) {
    const time = new ReduxEventTimeData(timeData)
    let timeMoment = time.toMomentDate()
    if (isAllDay) {
      timeMoment = timeMoment.date(timeMoment.date() + 1)
    }
    return (
      timeMoment.format('YYYYMMDDZ') !== this.toMomentDate().format('YYYYMMDDZ')
    )
  }
  isStartDateChanged(timeData: CalendarEventTimeData) {
    const time = new ReduxEventTimeData(timeData)
    return (
      time.toMomentDate().format('YYYYMMDDZ') !==
      this.toMomentDate().format('YYYYMMDDZ')
    )
  }

  toMomentDate() {
    if (this.data.date) {
      return convertRFCDate(this.data.date)
    } else {
      return convertRFCDateTime(this.data.dateTime)
    }
  }

  toJSDate() {
    return this.toMomentDate().toDate()
  }
  toUTCMoment() {
    if (this.data.date) {
      return this.toMomentDate().utc(true)
    } else {
      return this.toMomentDate().utc()
    }
  }

  toJSUTCDate() {
    return this.toUTCMoment().toDate()
  }
  syncDatetimeWithTimezone() {
    if (!this.isAllDay) {
      const timeZone = this.timeZone
      if (timeZone) {
        try {
          this.data.dateTime = momentTimezone(this.data.dateTime)
            .tz(timeZone)
            .format()
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}

export type ReduxEventJSON = {
  calendarId: string,
  isOrphan?: boolean,
} & CalendarEventsResource

type ReduxEventProp = {
  calendarId: string,
  start: ReduxEventTimeData,
  end: ReduxEventTimeData,
  originalStartTime?: ReduxEventTimeData,
  originalEndTime?: ReduxEventTimeData,
  recurrence?: Recurrence,
  isOrphan?: boolean,
} & $Rest<
  ReduxEventJSON,
  {|
    start: CalendarEventTimeData,
    end: CalendarEventTimeData,
    originalStartTime?: CalendarEventTimeData,
    originalEndTime?: CalendarEventTimeData,
    recurrence?: string[],
  |}
>

export class ReduxEvent {
  event: ReduxEventProp

  constructor(data: ReduxEventJSON) {
    this.event = ReduxEvent.convertEventData(data)
  }

  get calendarId(): string {
    if (!this.isEmpty()) {
      return this.event.calendarId
    }
    return ''
  }

  isEmpty() {
    return Object.keys(this.event).length === 0
  }

  toJSON(): ReduxEventJSON {
    const ret = {
      ...this.event,
      start: this.event.start.toJSON(),
      end: this.event.end.toJSON(),
      recurrence: this.event.recurrence.toJSON(),
      attendees: (this.event.attendees || []).map(attendee =>
        cloneDeep(attendee)
      ),
      extendedProperties: this.event.extendedProperties
        ? cloneDeep(this.event.extendedProperties)
        : { shared: {}, private: {} },
    }
    if (ret.originalStartTime && this.event.originalStartTime) {
      ret.originalStartTime = this.event.originalStartTime.toJSON()
    }
    if (ret.originalEndTime && this.event.originalEndTime) {
      ret.originalEndTime = this.event.originalEndTime.toJSON()
    }
    // $FlowFixMe
    return ret
  }

  clone() {
    return new ReduxEvent(this.toJSON())
  }

  toApiJSON() {
    const ret = this.toJSON()
    delete ret.calendarId
    delete ret.differentFromOriginalEvent
    delete ret.isOrphan
    delete ret.iCalUID
    // TODO: Work around for backend not supporting popup notification
    // Currently, calendar default reminder is popup
    if (ret.reminders && ret.reminders.useDefault) {
      ret.reminders.useDefault = false
      ret.reminders.overrides = [
        {
          method: 'email',
          minutes: 10,
        },
      ]
    }
    return ret
  }
  static generateAPINewDerivedRecurringEventJSON({
    eventData,
    originalStartTime,
    userAliases,
  }: {
    eventData: ReduxEvent,
    originalStartTime: ReduxEventTimeData | void,
    userAliases: string[],
  }): CalendarEventInsertResource {
    const event = eventData.clone()
    event.updateRecurrenceRepeatDay()
    if (event.rruleUntil > 0 && event.rruleUntil <= event.start.timestamp) {
      event.removeUntil()
      // const untilString = Recurrence.extractUntilString(event.rruleString)
      // if (untilString.length > 0) {
      //   event.rruleString = event.rruleString.replace(`;${untilString}`, '')
      // }
    }
    // event.updateAllEventExDates(oldInstances)
    // const firstInstanceExDate = `${event.start
    //   .toUTCMoment()
    //   .format('YYYYMMDD[T]')}${event.originalStartTime
    //   .toUTCMoment()
    //   .format('HHmmss[Z]')}`
    // event.removeExDate(firstInstanceExDate)
    const ret = event.toApiJSON()
    if (event.organizer.self) {
      ret.attendees = resetAPIAttendeeRSVPState(
        ret.attendees,
        event.organizer.self ? userAliases : [event.organizer.email]
      )
    }
    if (originalStartTime) {
      ret.originalStartTime = originalStartTime.toJSON()
    }
    ret.id = event.newDerivedRecurringId
    // const rruleStrings = Recurrence.extractExRDateString(event.rruleString)
    // ret.recurrence = rruleStrings.withoutExDates
    //   .split('\n')
    //   .filter(str => !!str)
    delete ret.kind
    delete ret.recurringEventId
    delete ret.isDeleted
    // $FlowFixMe
    return ret
  }
  toAPINewEventInsertJSON({
    newId,
    userAliases,
  }: {
    newId?: string,
    userAliases: string[],
  }): CalendarEventInsertResource {
    this.updateRecurrenceRepeatDay()
    if (this.rruleUntil > 0 && this.rruleUntil <= this.start.timestamp) {
      this.removeUntil()
      // const untilString = Recurrence.extractUntilString(this.rruleString)
      // if (untilString.length > 0) {
      //   this.rruleString = this.rruleString.replace(`;${untilString}`, '')
      // }
    }
    const ret = this.toApiJSON()
    if (this.organizer.self) {
      ret.attendees = resetAPIAttendeeRSVPState(
        ret.attendees,
        this.organizer.self ? userAliases : [this.organizer.email]
      )
    }
    if (newId) {
      ret.id = newId
    } else {
      ret.id = uuid().replaceAll('-', '')
    }
    delete ret.kind
    delete ret.recurringEventId
    delete ret.extendedProperties
    delete ret.originalStartTime
    delete ret.originalEndTime
    delete ret.isDeleted
    // $FlowFixMe
    return ret
  }

  toAPIUpdateJSON(preserveId?: boolean): CalendarEventUpdateResource {
    const ret = this.toApiJSON()
    if (this.isInstance) {
      delete ret.recurrence
    }
    if (!!preserveId) {
      delete ret.id
    }
    delete ret.kind
    // $FlowFixMe
    return ret
  }
  convertFromInstanceToEvent(): ReduxEvent {
    const ret = this.toJSON()
    delete ret.iCalUID
    if (this.isInstance) {
      // $FlowFixMe
      ret.id = ret.recurringEventId || ret.id
      delete ret.recurringEventId
      delete ret.differentFromOriginalEvent
    }
    return new ReduxEvent(ret)
  }
  mergeEventData(event: ReduxEvent) {
    if (!this.isEmpty()) {
      this.recurrence = event.recurrence
      this.endTimeUnspecified = event.endTimeUnspecified
      this.reminders = cloneDeep(event.reminders)
      this.mergeEventSimpleData(event)
    }
  }
  mergeEventSimpleData(event: ReduxEvent) {
    if (!this.isEmpty()) {
      this.summary = event.summary
      this.description = event.description
      this.attendees = event.attendees
    }
  }

  static isDiff(oldSelf: ReduxEvent, newSelf: ReduxEvent) {
    return !isEqual(oldSelf.toJSON(), newSelf.toJSON())
  }

  static convertEventData(data: ReduxEventJSON): ReduxEventProp {
    const {
      start,
      end,
      originalStartTime,
      originalEndTime,
      recurrence,
      attendees,
      ...rest
    } = data
    return {
      ...rest,
      attendees: Array.isArray(attendees) ? attendees : [],
      start: new ReduxEventTimeData(start),
      end: new ReduxEventTimeData(end),
      recurrence: new Recurrence(Array.isArray(recurrence) ? recurrence : []),
      originalStartTime: originalStartTime
        ? new ReduxEventTimeData(data.originalStartTime)
        : undefined,
      originalEndTime: originalEndTime
        ? new ReduxEventTimeData(originalEndTime)
        : undefined,
    }
  }

  get updated(): UnixTimestampInSeconds {
    return this.event.updated || 0
  }
  set updated(time: UnixTimestampInSeconds) {
    if (!this.isEmpty()) {
      this.event.updated = time
    }
  }
  increaseUpdateTime() {
    //Since we will use server event/instance.updated time,
    //this is only needed so our update time checks will pass.
    //So we don't run into issue where current update time is greater than browser current timestamp
    if (!this.isEmpty()) {
      this.event.updated++
    }
  }

  get isInstance() {
    return !this.isEmpty() && !!this.event.recurringEventId
  }

  get isRecurringEvent() {
    return (
      !this.isInstance &&
      this.event.recurrence &&
      this.event.recurrence.recurrence.length > 0
    )
  }
  get isOrphan(): boolean {
    if (!this.isEmpty()) {
      return !!this.event.isOrphan
    }
    return false
  }
  set isOrphan(val: boolean) {
    if (!this.isEmpty()) {
      this.event.isOrphan = val
    }
  }

  // get uiRRule() {
  //   if (!this.isEmpty()) {
  //     if (
  //       this.event.extendedProperties &&
  //       this.event.extendedProperties.shared &&
  //       this.event.extendedProperties.shared.rrule
  //     ) {
  //       return this.event.extendedProperties.shared.rrule
  //     }
  //   }
  //   return ''
  // }
  // set uiRRule(val: string) {
  //   if (!this.isEmpty()) {
  //     if (!this.event.extendedProperties) {
  //       this.event.extendedProperties = { private: {}, shared: {} }
  //     }
  //     if (!this.event.extendedProperties.shared) {
  //       this.event.extendedProperties.shared = {}
  //     }
  //     this.event.extendedProperties.shared.rrule = val
  //     this.event.updated = moment().unix()
  //   }
  // }

  get rruleString() {
    if (this.event.recurrence) {
      return this.event.recurrence.rruleOnly()
    }
    return ''
  }

  set rruleString(rule: string) {
    if (typeof rule === 'string' && rule.length > 0) {
      this.event.recurrence.updateRRule(rule)
    }
  }
  get recurrence(): string[] {
    if (!this.isEmpty() && this.event.recurrence) {
      return this.event.recurrence.recurrence
    }
    return []
  }
  set recurrence(val: string[]) {
    if (!this.isEmpty()) {
      this.event.recurrence = new Recurrence(val)
    }
  }
  get differentFromEvent() {
    if (!this.isEmpty()) {
      return this.event.differentFromOriginalEvent
    }
    return false
  }
  set differentFromEvent(val: boolean) {
    if (!this.isEmpty()) {
      this.event.differentFromOriginalEvent = val
    }
  }
  // updateRRuleException(oldStart: ReduxEventTimeData) {
  //   if (this.isEmpty()) {
  //     console.error('Event is empty')
  //     return
  //   }
  //   if (!oldStart) {
  //     console.error('Event has no start', this.toJSON())
  //     return
  //   }
  //   const exDate = oldStart.toJSUTCDate()
  //   const newRule = Recurrence.newRRuleWithExRDate(this.rruleString, exDate)
  //   this.uiRRule = newRule.toString()
  // }
  // removeExDate(UTCexDate: string) {
  //   const rruleStrings = Recurrence.extractExRDateString(this.rruleString)
  //   const exDatesString = rruleStrings.exDates
  //     .replace('EXDATE:', '')
  //     .split(',')
  //     .filter(exDate => {
  //       return exDate !== UTCexDate
  //     })
  //     .join(',')
  //   const updateTime = this.updated
  //   if (exDatesString.length > 0) {
  //     this.recurrence = [rruleStrings.withoutExDates, `EXDATE:${exDatesString}`]
  //   } else {
  //     this.recurrence = [rruleStrings.withoutExDates]
  //   }
  //   this.updated = updateTime
  // }
  // updateAllEventExDates(oldInstances: ReduxEvent[]) {
  //   //instance eventId can be different from this eventId because we might be updating thisAndFollowing Event
  //   const rruleStrings = Recurrence.extractExRDateString(this.rruleString)
  //   this.recurrence = rruleStrings.withoutExDates
  //     .split('\n')
  //     .filter(str => !!str)
  //   oldInstances.forEach((instance: ReduxEvent) => {
  //     if (instance.isAllDayEvent !== this.isAllDayEvent) {
  //       return
  //     }
  //     let keep =
  //       this.start &&
  //       instance.start &&
  //       this.start.timestamp <= instance.start.timestamp
  //     if (keep) {
  //       if (this.rruleUntil > 0) {
  //         keep =
  //           instance.cancelled && instance.start.timestamp <= this.rruleUntil
  //       } else {
  //         keep = instance.cancelled
  //       }
  //     }
  //     if (keep && instance.originalStartTime) {
  //       this.updateRRuleException(instance.originalStartTime)
  //     }
  //   })
  // }
  updateUntilExclusive(untilInUTC: Date) {
    this.updateUntil(new Date(untilInUTC.getTime() - 1))
    if (untilInUTC.getTime() - 1 <= this.start.timestamp * 1000) {
      if (this.organizer && this.organizer.self) {
        this.cancel()
      } else {
        this.isSoftDelete = true
      }
    }
  }

  updateUntil(untilInUTC: Date) {
    if (!this.isEmpty()) {
      this.event.recurrence.until = untilInUTC
      this.endTimeUnspecified = false
    }
  }
  removeUntil() {
    if (!this.isEmpty()) {
      this.event.recurrence.until = undefined
    }
  }
  get rruleUntil(): UnixTimestampInSeconds {
    if (!this.isEmpty()) {
      const until = this.event.recurrence.until
      if (until) {
        return Math.floor(until.valueOf() / 1000)
      }
    }
    return 0
  }
  get endTimeUnspecified() {
    if (!this.isEmpty()) {
      if (this.isRecurringEvent) {
        return this.rruleUntil === 0
      }
    }
    return false
  }
  set endTimeUnspecified(val: boolean) {
    if (!this.isEmpty()) {
      this.event.endTimeUnspecified = val
    }
  }
  get id() {
    return this.event.id
  }

  get eventId(): string {
    if (this.isEmpty()) {
      return ''
    }
    if (!this.isInstance) {
      return this.event.id || ''
    } else {
      return this.event.recurringEventId || ''
    }
  }
  get firstInstanceId(): string {
    if (this.isEmpty()) {
      return ''
    }
    if (!this.isRecurringEvent) {
      return ''
    }
    if (this.isInstance) {
      return ''
    }
    const eventId = this.originalEventId
    if (this.isAllDayEvent) {
      return `${eventId}_${convertMomentToInstanceDateId(
        this.originalStartTime.toMomentDate()
      )}`
    } else {
      return `${eventId}_${convertMomentToInstanceDateTimeId(
        this.originalStartTime.toMomentDate()
      )}`
    }
  }
  get originalEventId(): string {
    return this.eventId.split('_')[0]
  }
  get recurringEventId() {
    if (!this.isEmpty()) {
      return this.event.recurringEventId
    }
    return ''
  }
  linkToEvent(eventId: string) {
    if (eventId && !this.isEmpty()) {
      this.event.recurringEventId = eventId
    }
  }

  get instanceId() {
    if (this.isEmpty()) {
      return ''
    }
    if (this.isInstance) {
      return this.event.id || ''
    }
    return ''
  }
  get newDerivedRecurringId() {
    if (this.isEmpty()) {
      return ''
    }
    if (this.isInstance) {
      const originalEventId = extractCalendarEventId(this.instanceId)
      return `${originalEventId}_R${extractCalendarInstanceDateTimeId(
        this.instanceId
      ).replace('Z', '')}`
    }
    return ''
  }
  get treeMapId() {
    if (!this.isEmpty()) {
      if (this.isInstance) {
        return `${this.calendarId}-${this.eventId}-${this.instanceId}`
      } else {
        return `${this.calendarId}-${this.eventId}`
      }
    }
    return ''
  }
  get originalStartTime(): ReduxEventTimeData | null {
    if (!this.isEmpty()) {
      if (this.event && this.event.originalStartTime) {
        return this.event.originalStartTime
      } else {
        return this.start
      }
    }
    return null
  }
  set originalStartTime(start: ReduxEventTimeData) {
    if (!this.isEmpty() && start) {
      this.event.originalStartTime = new ReduxEventTimeData(start.toJSON())
    }
  }
  get originalEndTime(): ReduxEventTimeData | null {
    if (!this.isEmpty()) {
      if (this.event && this.event.originalEndTime) {
        return this.event.originalEndTime
      } else {
        return this.end
      }
    }
    return null
  }
  set originalEndTime(end: ReduxEventTimeData) {
    if (!this.isEmpty() && end) {
      this.event.originalEndTime = new ReduxEventTimeData(end.toJSON())
    }
  }
  get is0Duration() {
    if (!this.isAllDayEvent) {
      return this.start.timestamp === this.end.timestamp
    }
    return false
  }

  get start(): ReduxEventTimeData | null {
    if (!this.isEmpty()) {
      return this.event.start
    }
    return null
  }
  set start(start: ReduxEventTimeData) {
    if (!this.isEmpty()) {
      this.event.start = new ReduxEventTimeData(start.toJSON())
    }
  }
  updateRecurrenceRepeatDay() {
    if (this.rruleString.length > 0) {
      this.rruleString = updateRepeatDay(
        this.rruleString,
        this.isAllDayEvent
          ? this.start.toMomentDate().utc(true)
          : this.start.toMomentDate()
      )
    }
  }
  updateStartData(data: CalendarEventTimeData) {
    if (!this.isEmpty()) {
      this.start = new ReduxEventTimeData(data)
    }
  }

  get end(): ReduxEventTimeData | null {
    if (!this.isEmpty()) {
      return this.event.end
    }
    return null
  }
  set end(end: ReduxEventTimeData) {
    if (!this.isEmpty()) {
      this.event.end = new ReduxEventTimeData(end.toJSON())
    }
  }
  withInRange({
    start,
    end,
    currentTimeZone,
  }: {
    start: UnixTimestampInSeconds,
    end: UnixTimestampInSeconds,
    currentTimeZone: string,
  }) {
    if (this.isEmpty()) {
      return false
    }
    if (!this.start || !this.end) {
      return false
    }
    const allDayStart = momentTimezone
      .tz(start * 1000, currentTimeZone)
      .utc(true)
      .unix()
    const allDayEnd = momentTimezone
      .tz(end * 1000, currentTimeZone)
      .utc(true)
      .unix()
    const rangeStart = this.isAllDayEvent ? allDayStart : start
    const rangeEnd = this.isAllDayEvent ? allDayEnd : start
    if (this.isInstance) {
      return (
        (this.start.timestamp >= rangeStart &&
          this.start.timestamp <= rangeEnd) ||
        (this.end.timestamp >= rangeStart && this.start.timestamp <= rangeEnd)
      )
    } else {
      if (this.isRecurringEvent) {
        return (
          (this.endTimeUnspecified && this.start.timestamp <= rangeEnd) ||
          (this.start.timestamp <= rangeEnd && this.rruleUntil >= rangeStart)
        )
      } else {
        return (
          (this.start.timestamp >= rangeStart &&
            this.start.timestamp < rangeEnd) ||
          (this.end.timestamp > rangeStart && this.end.timestamp <= rangeEnd)
        )
      }
    }
  }
  updateEndData(data: CalendarEventTimeData) {
    if (!this.isEmpty()) {
      this.event.end = new ReduxEventTimeData(data)
    }
  }
  eventLongerOrEqualThan24hours() {
    if (!this.isEmpty()) {
      return (
        this.event.end.timestamp - this.event.start.timestamp >= 24 * 60 * 60
      )
    }
    return false
  }
  get isAllDayEvent() {
    if (!this.isEmpty()) {
      return !!this.event.start.date
    }
    return false
  }

  get cancelled(): boolean {
    if (!this.isEmpty()) {
      return this.event.status === 'cancelled'
    }
    return true
  }
  get isTentative(): boolean {
    if (!this.isEmpty()) {
      return this.event.status === 'tentative'
    }
    return false
  }
  get isGhost(): boolean {
    if (!this.isEmpty()) {
      return this.event.status === 'tentative'
    }
    return false
  }
  confirmEvent() {
    if (!this.isEmpty()) {
      this.event.status = 'confirmed'
    }
  }
  get status() {
    if (!this.isEmpty()) {
      return this.event.status
    }
    return ''
  }

  get summary() {
    let ret = ''
    if (!this.isEmpty()) {
      ret = this.event.summary
    }
    if (typeof ret !== 'string') {
      ret = ''
    }
    return ret
  }

  set summary(val: string) {
    if (!this.isEmpty()) {
      this.event.summary = val
    }
  }
  get organizer() {
    if (!this.isEmpty()) {
      return this.event.organizer || {}
    }
    return {}
  }

  get description() {
    if (!this.isEmpty()) {
      return this.event.description || ''
    }
    return ''
  }

  set description(val: string) {
    if (!this.isEmpty()) {
      this.event.description = val
    }
  }
  get reminders() {
    if (!this.isEmpty()) {
      return this.event.reminders || { overrides: [], useDefault: true }
    }
    return { overrides: [], useDefault: true }
  }
  set reminders(val: {
    useDefault: boolean,
    overrides: CalendarReminderOverrides[],
  }) {
    if (!this.isEmpty()) {
      this.event.reminders = { ...val }
    }
  }
  get location() {
    if (!this.isEmpty() && this.event.location) {
      return this.event.location.value
    }
    return ''
  }

  get attendees() {
    if (!this.isEmpty() && Array.isArray(this.event.attendees)) {
      return this.event.attendees.slice()
    }
    return []
  }
  set attendees(val) {
    if (!this.isEmpty() && Array.isArray(val)) {
      this.event.attendees = val.slice()
    }
  }
  getAttendeeByEmail(email: string) {
    return this.getAttendeeByEmails([email])
  }
  getAttendeeByEmails(emails: string[]) {
    return this.attendees.find(attendee =>
      stringArrayIncludes({
        strArray: emails,
        matchStr: attendee.email,
        options: { ignoreCase: true },
      })
    )
  }
  setAttendeeRSVPStatusByUser(userAliases: string[], rsvpStatus) {
    if (!this.isEmpty()) {
      this.attendees.find(attendee => {
        if (isAttendeeCurrentUser(attendee, userAliases)) {
          updateEventAttendeeRSVPStatus(attendee, rsvpStatus)
          return true
        }
        return false
      })
    }
  }
  updateAttendee(attendee: CalendarAttendee) {
    if (!attendee || !attendee.email) {
      console.error(`update attendee missing data`)
      return
    }
    const attendees = this.attendees
    let isNewAttendee = true
    for (let i = 0; i < attendees.length; i++) {
      if (attendees[i].email === attendee.email) {
        attendees[i] = cloneDeep(attendee)
        isNewAttendee = false
        break
      }
    }
    if (isNewAttendee) {
      attendees.push(cloneDeep(attendee))
    }
    this.attendees = attendees
  }
  get createdTimestamp(): UnixTimestampInSeconds {
    if (!this.isEmpty()) {
      return this.event.created || 0
    }
    return 0
  }
  get isSoftDelete(): boolean {
    if (!this.isEmpty()) {
      return !!this.event.isDeleted
    }
    return false
  }
  set isSoftDelete(val: boolean) {
    if (!this.isEmpty()) {
      this.event.isDeleted = val
    }
  }

  cancel() {
    if (!this.isEmpty()) {
      this.event.status = 'cancelled'
    }
  }
  confirm() {
    if (!this.isEmpty()) {
      this.event.status = 'confirmed'
    }
  }

  get isUpdated() {
    if (!this.isEmpty()) {
      return !!this.event.isUpdated
    }
    return false
  }

  incrementSequence() {
    if (!this.isEmpty()) {
      if (typeof this.event.sequence === 'number') {
        this.event.sequence++
      } else {
        this.event.sequence = 0
      }
      //Updated field is not updated because this is an automatic update, not user triggered
    }
  }

  decreaseSequence() {
    if (!this.isEmpty()) {
      if (typeof this.event.sequence === 'number') {
        this.event.sequence--
      } else {
        this.event.sequence = 0
      }
      //Updated field is not updated because this is an automatic update, not user triggered
    }
  }
}
