// @flow
import { createSelector } from 'reselect'
import { selectCalendarState } from './selector-common'
import type { CalendarState } from './types'
import type { Selector } from 'types/state'
import type { CalendarsState } from './types-calendars'
import type { ReduxCalendar } from './modal-redux-calendar'

export const getCalendarsSelector: Selector<CalendarsState> = createSelector(
  selectCalendarState,
  (state: CalendarState) => {
    return state.calendars
  }
)

export const getCalendarById = (
  calendarId: string
): Selector<ReduxCalendar | void> =>
  createSelector(getCalendarsSelector, (state: CalendarsState) => {
    if (calendarId === 'primary' || state.primary.id === calendarId) {
      return state.primary
    } else {
      return state.secondary.find((calendar: ReduxCalendar) => {
        return calendar.isCalendarById(calendarId)
      })
    }
  })

export const getCalendars = (): Selector<ReduxCalendar[]> =>
  createSelector(getCalendarsSelector, (state: CalendarsState) => {
    const ret = state.secondary.slice()
    if (state.primary) {
      ret.unshift(state.primary)
    }
    return ret
  })

export const getPrimaryCalendar = (): Selector<ReduxCalendar | void> =>
  createSelector(getCalendarById('primary'), (state: ReduxCalendar | void) => {
    return state
  })

export const getPrimaryCalendarSummary = (): Selector<string> => {
  return createSelector(getPrimaryCalendar(), (state: ReduxCalendar | void) => {
    return state ? state.summary : ''
  })
}
