// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { recoveryMethods } from '@edison/webmail-ui/utils/constants'

import { getSMSEnabledCountries } from 'core/analytics/selectors'
import { getMfaSelector } from 'core/settings/selectors'
import {
  useRecoveryMethodForm,
  useRecoveryEmailValidator,
} from 'core/settings/hooks'

import Dialog from '@material-ui/core/Dialog'
import Container, {
  AddEmailForm,
  AddPhoneNumberForm,
  CodeVerifyForm,
  AddedSuccess,
} from '@edison/webmail-ui/components/RecoveryMethod'
import { Form as PasswordForm } from 'screens/PasswordAuthModal'

import type { ExtraAuth } from '@edison/webmail-core/types'
import type { RecoveryMethod } from '@edison/webmail-ui/utils/constants'

type Props = {
  auth: ?ExtraAuth,
  isOpen: boolean,
  onClose: () => void,
  recoveryMethod: $PropertyType<RecoveryMethod, 'type'>,
}

const AddRecoveryMethodModal = ({
  auth: extraAuth,
  isOpen,
  onClose,
  recoveryMethod,
}: Props) => {
  const mfa = useSelector(getMfaSelector)

  const {
    isLoading,
    auth,
    form,
    sendCode,
    updateMethod,
  } = useRecoveryMethodForm(extraAuth)
  const recoveryEmailValidator = useRecoveryEmailValidator()

  const enabledCountries = useSelector(getSMSEnabledCountries)

  const handleOnSubmitEmail = ({ email }, { push }) => {
    const nextMethod = {
      type: recoveryMethod,
      value: email,
    }

    sendCode(nextMethod).then(success => {
      if (success) {
        form.set(nextMethod)
        push('verify')
      }
    })
  }

  const handleOnSubmitPhoneNumber = ({ phoneNumber }, { push }) => {
    const nextMethod = {
      type: recoveryMethod,
      value: phoneNumber,
    }

    sendCode(nextMethod).then(success => {
      if (success) {
        form.set(nextMethod)
        push('verify')
      }
    })
  }

  const handleOnVerify = ({ code }, { push }) => {
    updateMethod(code).then(success => {
      if (success) {
        push('success')
      }
    })
  }

  const handleOnResend = () => {
    sendCode(form.value)
  }

  const steps = [
    {
      id: 'password-auth',
      render: ({ push }) => (
        <PasswordForm
          onClose={onClose}
          onConfirm={extraAuth => {
            if (extraAuth) {
              auth.set(extraAuth)
              push('add')
            }
          }}
        />
      ),
    },
    {
      id: 'add',
      render: renderProps => {
        if (recoveryMethod === recoveryMethods.email) {
          return (
            <AddEmailForm
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={values => handleOnSubmitEmail(values, renderProps)}
              validator={recoveryEmailValidator}
            />
          )
        } else
          return (
            <AddPhoneNumberForm
              enabledCountries={enabledCountries}
              initialValues={{ phoneNumber: mfa.phoneNumber }}
              isLoading={isLoading}
              onClose={onClose}
              onSubmit={values =>
                handleOnSubmitPhoneNumber(values, renderProps)
              }
            />
          )
      },
    },
    {
      id: 'verify',
      render: renderProps => {
        return (
          <CodeVerifyForm
            isLoading={isLoading}
            method={form.value}
            onSubmit={values => handleOnVerify(values, renderProps)}
            onClose={onClose}
            onResend={handleOnResend}
          />
        )
      },
    },
    {
      id: 'success',
      render: () => (
        <AddedSuccess recoveryMethod={recoveryMethod} onClick={onClose} />
      ),
    },
  ]

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <Container
        initialStep={!!auth.value ? 'add' : 'password-auth'}
        steps={steps}
      />
    </Dialog>
  )
}

export default AddRecoveryMethodModal
