// @flow
import React from 'react'

import { useCurrentBreak } from 'core/inbox-break/hooks'

import { EndBreakConfirmation } from '@edison/webmail-ui/components/InboxBreak'

type Props = {
  isOpen: boolean,
  toggle: () => void,
}

const View = ({ isOpen, toggle }: Props) => {
  const currBreak = useCurrentBreak()

  function handleOnConfirm() {
    currBreak.onToggle({ enable: false }).then(() => {
      toggle()
    })
  }

  return (
    <EndBreakConfirmation
      open={isOpen}
      onClose={toggle}
      onConfirm={handleOnConfirm}
      isLoading={currBreak.isExecuting}
    />
  )
}

View.displayName = 'EndBreakConfirmModal'

export default View
