// @flow
import uniq from 'lodash/uniq'
import union from 'lodash/union'
import pullAll from 'lodash/pullAll'
import {
  createReducer,
  createEntityState,
  updateEntities,
  updateIds,
} from 'utils/redux'
import * as actions from './actions'
import { setFilterAccountAction } from '../retrofit/actions'
import { fetchThreadActions } from '../messages/actions'
import { batchUpdateMessageLabels } from '../metadata/actions'
import { saveDraftActions, deleteDraftActions } from '../compose/actions'
import { labelNames, defaultPaginationState } from 'utils/constants'

import type { EntityState, PaginationState } from 'types/redux'
import type { Thread } from '@edison/webmail-core/types/threads'
import type {
  ThreadActions,
  ThreadSetActiveLabel,
  ThreadListRequest,
  ThreadListSuccess,
  ThreadListFailure,
  ThreadHistorySuccess,
  ThreadSelect,
  ThreadBatchSelect,
  ThreadSelectUniverse,
  ThreadBatchGetSuccess,
  SetLastHistoryId,
  ThreadDrag,
  ThreadSelectReset,
} from './types'
import type { MessageListSuccess } from '../messages/types'
import type {
  MetadataHistory,
  MessageLabelsBatchUpdate,
} from '../metadata/types'
import type { SetRetrofitAccount } from '../retrofit/types'
import type { SaveDraftSuccess, DeleteDraftSuccess } from '../compose/types'

type Action = ThreadActions

export type State = {|
  // Default entity states
  ...EntityState<Thread>,
  pagination: PaginationState,
  +historyId: ?string,
  +activeLabel: string,
  +selectedThreadIds: $ReadOnlyArray<string>,
  +selectedUniverse: boolean,
  +isDragging: boolean,
  +requestIds: {
    list: ?string,
  },
|}

export const initialState: State = {
  ...createEntityState<Thread>(),
  pagination: defaultPaginationState,
  activeLabel: labelNames.inbox,
  selectedThreadIds: [],
  selectedUniverse: false,
  historyId: null,
  isDragging: false,
  requestIds: {
    list: null,
  },
}

const reducer = createReducer<State, Action>(initialState, {
  // Active Label
  [actions.setActiveThreadLabel.toString()]: setActiveLabel,
  // Thread
  [actions.dragThread.toString()]: dragThread,
  [actions.selectThread.toString()]: selectThread,
  [actions.batchSelectThread.toString()]: batchSelectThread,
  [actions.selectUniverseThread.toString()]: selectUniverseThread,
  [actions.resetSelectThread.toString()]: resetSelectThread,
  [actions.fetchThreadsActions.request.toString()]: threadListRequest,
  [actions.fetchThreadsActions.success.toString()]: threadListSuccess,
  [actions.fetchThreadsActions.failure.toString()]: threadListFailure,
  [actions.batchGetThreadsActions.success.toString()]: threadBatchGetSuccess,
  [actions.fetchThreadHistoryActions.success.toString()]: threadHistorySuccess,
  // History ID
  [actions.setLastHistoryId.toString()]: setLastHistoryId,
  // Message
  [fetchThreadActions.success.toString()]: fetchThread,
  // Retrofit
  [setFilterAccountAction.toString()]: filterAccountUpdate,

  [saveDraftActions.success.toString()]: saveDraft,
  [deleteDraftActions.success.toString()]: deleteDraft,
  [batchUpdateMessageLabels.toString()]: updateLabel,
  [setFilterAccountAction.toString()]: resetSelectThread,
})

function updateLabel(state: State, action: MessageLabelsBatchUpdate) {
  const { threads, add, remove } = action.payload
  const messages = threads.flatMap(({ messageIds }) => messageIds)

  let next = { ...state.entities }
  for (let id of messages) {
    if (id in next) {
      const message = next[id]
      const { labelIds = [] } = message
      next[id] = {
        ...message,
        labelIds: [...labelIds, ...add].filter(
          labelId => !remove.includes(labelId)
        ),
      }
    }
  }
  return {
    ...state,
    entities: next,
  }
}

function saveDraft(state: State, action: SaveDraftSuccess) {
  const {
    threadId,
    snippet,
    from,
    date,
    inReplyTo,
    draft: { subject },
  } = action.payload

  return {
    ...state,
    entities: {
      ...state.entities,
      [threadId]: {
        id: threadId,
        subject: inReplyTo ? state.entities[threadId].subject : subject,
        snippet,
        from: [from],
        date,
      },
    },
    ids: state.ids.includes(threadId) ? state.ids : state.ids.concat(threadId),
  }
}

function deleteDraft(state: State, action: DeleteDraftSuccess) {
  const { threadId, messages } = action.payload
  if (!messages.length) {
    return state
  } else {
    const message = messages[messages.length - 1]
    return {
      ...state,
      entities: {
        ...state.entities,
        [threadId]: {
          id: threadId,
          subject: message.subject,
          snippet: message.snippet,
          from: message.from,
          date: message.date,
        },
      },
    }
  }
}
function setLastHistoryId(
  state: State,
  action: MetadataHistory | SetLastHistoryId
) {
  const { lastHistoryId } = action.payload
  if (!!lastHistoryId) {
    return {
      ...state,
      historyId: lastHistoryId,
    }
  }
  return state
}

function setActiveLabel(state: State, action: ThreadSetActiveLabel) {
  const { labelId } = action.payload
  const { selectedThreadIds, selectedUniverse, pagination } = initialState

  return {
    ...state,
    activeLabel: labelId,
    // Reset the selected threads and pagination
    pagination,
    selectedUniverse,
    selectedThreadIds,
  }
}

function threadListRequest(state: State, action: ThreadListRequest): State {
  const { request } = action.meta

  return {
    ...state,
    requestIds: {
      ...state.requestIds,
      list: request.id,
    },
  }
}

function threadListSuccess(state: State, action: ThreadListSuccess): State {
  const { threads, pagination } = action.payload

  const entities = updateEntities(
    state.entities,
    threads.map(({ messages, ...rest }) => rest)
  )
  const ids = updateIds(state.ids, threads)

  return {
    ...state,
    ids,
    entities,
    requestIds: {
      ...state.requestIds,
      list: null,
    },
    pagination: {
      ...state.pagination,
      ...pagination,
    },
  }
}

function threadListFailure(state: State, action: ThreadListFailure): State {
  const { request } = action.meta
  const { requestIds } = state

  if (request.id !== requestIds.list) return state

  return {
    ...state,
    requestIds: {
      ...requestIds,
      list: null,
    },
  }
}

function threadBatchGetSuccess(
  state: State,
  action: ThreadBatchGetSuccess
): State {
  const { threads } = action.payload
  //messages store in the messages reducer
  const entities = updateEntities(
    state.entities,
    threads.map(({ messages, ...otherProps }) => otherProps)
  )
  const ids = updateIds(state.ids, threads)
  return {
    ...state,
    ids,
    entities,
  }
}

function threadHistorySuccess(
  state: State,
  action: ThreadHistorySuccess
): State {
  const { history, lastHistoryId } = action.payload

  if (lastHistoryId === state.historyId) {
    // Bypass the process if no new thread
    return state
  }

  const entities = history.reduce(
    (prev, { thread: { id, messages, ...otherProps } }) => {
      return {
        ...prev,
        [id]: {
          id,
          ...otherProps,
        },
      }
    },
    state.entities
  )
  const threads: Array<Thread> = history.map(each => each.thread)
  const ids = updateIds(state.ids, threads)

  return {
    ...state,
    entities,
    ids,
    historyId: lastHistoryId,
  }
}

function dragThread(state: State, action: ThreadDrag) {
  const { isDragging } = action.payload
  return {
    ...state,
    isDragging,
  }
}

function selectThread(state: State, action: ThreadSelect) {
  const { id, value } = action.payload
  const { selectedThreadIds } = state

  const selected = value
    ? uniq([...selectedThreadIds, id])
    : selectedThreadIds.filter(threadId => threadId !== id)

  const selectedUniverse = selected.length ? state.selectedUniverse : false

  return {
    ...state,
    selectedUniverse,
    selectedThreadIds: selected,
  }
}

function batchSelectThread(state: State, action: ThreadBatchSelect) {
  const { ids, value } = action.payload
  const { selectedThreadIds } = state

  const selected = value
    ? union([...selectedThreadIds], ids)
    : pullAll([...selectedThreadIds], [...ids])

  const selectedUniverse = selected.length ? state.selectedUniverse : false

  return {
    ...state,
    selectedUniverse,
    selectedThreadIds: selected,
  }
}

function selectUniverseThread(state: State, action: ThreadSelectUniverse) {
  const { value } = action.payload

  return {
    ...state,
    selectedUniverse: value,
  }
}

function resetSelectThread(state: State, action: ThreadSelectReset) {
  const { selectedThreadIds, selectedUniverse } = initialState
  return {
    ...state,
    selectedUniverse,
    selectedThreadIds,
  }
}

function fetchThread(state: State, action: MessageListSuccess) {
  const { thread } = action.payload
  const { id } = thread
  const originalThread = state.entities[id]
  const newThread = {
    ...thread,
    // Always replace the thread entity to update the newest state
    // Label should not replaced when read thread
    labelIds: Boolean(originalThread)
      ? originalThread.labelIds
      : thread.labelIds,
  }
  const ids = updateIds(state.ids, [thread])
  return {
    ...state,
    ids,
    entities: {
      ...state.entities,
      [id]: newThread,
    },
  }
}

function filterAccountUpdate(state: State, action: SetRetrofitAccount) {
  return {
    ...state,
    pagination: defaultPaginationState,
  }
}

export default reducer
