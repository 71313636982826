// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'
import get from 'lodash/get'

import { useLabelValidator } from 'core/labels/hooks'
import {
  actions as labelActions,
  selectors as labelSelectors,
} from 'core/labels'

import { SelectAction } from '@edison/webmail-ui/components/Filter/ActionItem'
import LabelSelector, {
  LabelField,
  CreateButton,
} from '@edison/webmail-ui/components/Labels/LabelSelector'

import type { Dispatch } from 'types/redux'

const getAllLabels = labelSelectors.getAllLabelsState()

export default ({
  name,
  value,
  options,
  placeholder,
}: {
  value: any,
  name: string,
  placeholder: string,
  options: Array<{ [key: string]: any }>,
}) => {
  const dispatch: Dispatch = useDispatch()
  const allLabels = useSelector(getAllLabels)
  const labelValidator = useLabelValidator()

  function handleOnCreate(name, callback) {
    return dispatch(labelActions.createLabel(name)).then(res => {
      if (!isNil(res)) {
        callback(res.id)
      }
    })
  }

  return (
    <SelectAction
      name={name}
      value={value}
      label={get(allLabels, `${value}.name`, 'Unkown')}
      placeholder={placeholder}
      renderOptions={({ onClick }) => (
        <LabelSelector
          validate={values => {
            let errors = {}
            const nameError = labelValidator.name(values.name)
            if (!!nameError) {
              errors.name = nameError
            }

            return errors
          }}
          labels={options}
          renderField={({ index, formikProps }) => (
            <LabelField
              multi={false}
              index={index}
              onClick={label => onClick(label.id)}
            />
          )}
          renderButton={({ name, formikProps }) => {
            const error = get(formikProps, 'errors.name')
            return isNil(error) ? (
              <CreateButton
                name={name}
                onClick={() => handleOnCreate(name, onClick)}
              />
            ) : null
          }}
        />
      )}
    />
  )
}
