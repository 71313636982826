// @flow
import * as client from '@edison/webmail-core/api/cleanup'
import { createAction } from 'utils/redux'

import { getAuth } from 'core/auth/selectors'

import type {
  CleanupSearchRequest,
  CleanupSearchSuccess,
  CleanupSearchFailure,
  CleanupListRequest,
  CleanupListSuccess,
  CleanupListFailure,
  CleanupUpdateRequest,
  CleanupUpdateSuccess,
  CleanupUpdateFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const cleanupSearchActions: {
  request: ActionCreator<CleanupSearchRequest>,
  success: ActionCreator<CleanupSearchSuccess>,
  failure: ActionCreator<CleanupSearchFailure>,
} = {
  request: createAction('CLEANUP_SEARCH_REQUEST'),
  success: createAction('CLEANUP_SEARCH_SUCCESS'),
  failure: createAction('CLEANUP_SEARCH_FAILURE'),
}

export function searchCleanup(): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    try {
      dispatch(cleanupSearchActions.request())
      const res = await client.search(auth)
      dispatch(cleanupSearchActions.success(res.result))
    } catch (e) {
      dispatch(cleanupSearchActions.failure({ message: e.message }))
    }
  }
}

export const cleanupListActions: {
  request: ActionCreator<CleanupListRequest>,
  success: ActionCreator<CleanupListSuccess>,
  failure: ActionCreator<CleanupListFailure>,
} = {
  request: createAction('CLEANUP_LIST_REQUEST'),
  success: createAction('CLEANUP_LIST_SUCCESS'),
  failure: createAction('CLEANUP_LIST_FAILURE'),
}

export function listCleanup(type, pageToken, size = 20): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    try {
      dispatch(cleanupListActions.request())
      const res = await client.list(type, auth, { size, pageToken })
      dispatch(cleanupListActions.success(res.result))
    } catch (e) {
      dispatch(cleanupListActions.failure({ message: e.message }))
    }
  }
}

export const cleanupUpdateActions: {
  request: ActionCreator<CleanupUpdateRequest>,
  success: ActionCreator<CleanupUpdateSuccess>,
  failure: ActionCreator<CleanupUpdateFailure>,
} = {
  request: createAction('CLEANUP_UPDATE_REQUEST'),
  success: createAction('CLEANUP_UPDATE_SUCCESS'),
  failure: createAction('CLEANUP_UPDATE_FAILURE'),
}

export function updateCleanup(type, status, options): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    try {
      dispatch(cleanupUpdateActions.request())
      await client.update(type, status, auth, options)
      dispatch(cleanupUpdateActions.success({ type }))
    } catch (e) {
      dispatch(cleanupUpdateActions.failure({ message: e.message }))
    }
  }
}
