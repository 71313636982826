// @flow
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAuthUser } from 'core/auth/selectors'

import Helmet from 'react-helmet'

import type { Node } from 'react'

type Props = {
  children: Node,
}

export const AuthHelmet = ({ children }: Props) => {
  const { t } = useTranslation()
  const authUser = useSelector(getAuthUser)

  const template = useMemo(
    () => ['%s', authUser, t('defaultTitle')].filter(Boolean).join(' - '),
    [authUser]
  )

  return (
    <Helmet titleTemplate={template} defaultTitle={t('defaultTitle')}>
      {children}
    </Helmet>
  )
}

export const SimpleHelmet = ({ title }: { title: string }) => {
  return (
    <AuthHelmet>
      <title>{title}</title>
    </AuthHelmet>
  )
}
