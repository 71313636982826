// @flow
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import get from 'lodash/get'
import { feedViewCaches } from './caches'
import ForwardView from 'common/ThreadList/ForwardView'
import { getForwardOpened, updateForwardOpened } from 'common/storage'

import {
  getMessagesState,
  getLatestThreadMessageId,
} from 'core/metadata/selectors'
import { getSplitInboxByLabelId } from 'core/split-inboxes/selectors'
import { getMessageState } from 'core/messages/selectors'
import {
  isInboxZero,
  getThreadPagination,
  isThreadListLoading,
  isBatchGetLoading,
} from 'core/threads/selectors'
import { fetchThreads, batchGetThreadFeeds } from 'core/threads/actions'
import { useRouteLabel } from 'core/labels/hooks'
import { useActiveLabelThreads } from 'core/threads/hooks'
import { useRetrofitAccounts } from 'core/retrofit/hooks'
import {
  inboxViews,
  THREAD_PREVIEW_BATCH_NUM,
  labelNames,
} from 'utils/constants'

import ThreadFeedLayout from '@edison/webmail-ui/components/ThreadFeedLayout'
import ThreadFeedList from '@edison/webmail-ui/components/ThreadFeedList'
import ThreadFeedPromo from '@edison/webmail-ui/components/ThreadFeedItem/PromoItem'
import ThreadZero from 'screens/InboxZero/ThreadZero'
import BatchActionHeader from 'common/ThreadList/BatchActionHeader'
import ThreadFeedItem from './ThreadFeedItem'

import type { Node } from 'react'
import type { Dispatch } from 'types/redux'

const messageMetaSelector = getMessagesState()
const messageStateSelector = getMessageState()
const isInboxZeroSelector = isInboxZero()

const ThreadFeedView = ({ header, cards }: { cards?: Node, header: Node }) => {
  const dispatch: Dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)
  const [forwardOpened, setForwardOpened] = useState(getForwardOpened())
  const isInboxZero = useSelector(isInboxZeroSelector)
  const activeLabel = useRouteLabel() || ''

  const { active: activeRetrofit } = useRetrofitAccounts()
  const threadPagination = useSelector(getThreadPagination)
  const messageMeta = useSelector(messageMetaSelector)
  const messageById = useSelector(messageStateSelector)
  const { ids: threadIds, entities: threads } = useActiveLabelThreads()
  const latestThreadMessageSelector = useMemo(
    () => getLatestThreadMessageId(threadIds),
    [threadIds]
  )
  const latestThreadMessage = useSelector(latestThreadMessageSelector)
  const isMessageLoaded = id => get(messageById, `${id}.loaded`, false)

  const messages = useMemo(
    () =>
      latestThreadMessage.map((id, index) => ({
        id,
        isFetched: id in messageById,
        isLoaded: isMessageLoaded(id),
        threadId: get(messageMeta, `${id}.threadId`),
      })),
    [messageById, threadIds]
  )

  const total = threadPagination.total

  const threadListLoading = useSelector(isThreadListLoading)
  const batchGetLoading = useSelector(isBatchGetLoading)
  const isLoading = isInboxZero ? false : threadListLoading || batchGetLoading

  const showForward = useMemo(() => {
    return (
      !forwardOpened &&
      messages.length === total &&
      threads.filter(t => get(t, 'from[0].email', '') !== 'jeffp@onmail.com')
        .length <= 3 &&
      (activeLabel === labelNames.primary || activeLabel === labelNames.other)
    )
  }, [forwardOpened, threads, activeLabel, messages, total])

  const [scrollToIndex, setScrollToIndex] = useState<?number>(undefined)
  const [isLoadMore, setLoadMore] = useState(false)
  const loadMore = ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number,
    stopIndex: number,
  }) => {
    if (
      stopIndex > 0 &&
      startIndex <= total &&
      total !== messages.length &&
      !isLoading
    ) {
      setLoadMore(true)
    }
  }

  const [debouncedBatchGet, cancelDebounced] = useDebouncedCallback(
    async () => {
      if (threadPagination.next) {
        await dispatch(
          fetchThreads(activeLabel || '', {
            size: THREAD_PREVIEW_BATCH_NUM,
            pageToken: threadPagination.next,
          })
        )
      }
      setLoadMore(false)
    },
    300,
    [activeLabel, threadPagination.next]
  )

  useEffect(() => {
    if (isLoadMore) {
      debouncedBatchGet()
    }
  }, [isLoadMore])

  useEffect(() => {
    if (mounted) {
      dispatch(batchGetThreadFeeds(threadIds))
    }
  }, [threadIds, mounted])

  useEffect(() => {
    cancelDebounced()
    setLoadMore(false)
    setScrollToIndex(0)
    setTimeout(() => setScrollToIndex(undefined), 200)

    if (!isLoading) {
      debouncedBatchGet()
    }
  }, [activeLabel, activeRetrofit])

  useEffect(() => {
    // Clear the feed view caches on mount/unmount
    setTimeout(() => {
      feedViewCaches.clear()
    }, 0)

    return () => feedViewCaches.clear()
  }, [activeLabel])

  useEffect(() => {
    setMounted(true)
  }, [])

  function isLoaded(index) {
    if (index >= total) return true
    else return get(messages, `${index}.isFetched`, false)
  }

  const renderItem = useCallback(({ index, data, style }) => {
    return (
      <ThreadFeedItem
        key={index}
        index={index}
        style={style}
        isFetched={data.isFetched}
        isLoaded={data.isLoaded}
        threadId={data.threadId}
        messageId={data.id}
      />
    )
  }, [])

  const splitInboxByLabel = useSelector(getSplitInboxByLabelId)
  const promo = useMemo(() => {
    const splitName =
      activeLabel in splitInboxByLabel
        ? splitInboxByLabel[activeLabel].name
        : undefined
    return <ThreadFeedPromo title={splitName} />
  }, [activeLabel])

  const forwardAction = useCallback(() => {
    updateForwardOpened()
    setForwardOpened(true)
  })

  return (
    <ThreadFeedLayout
      isEmpty={isInboxZero}
      header={header}
      cards={cards}
      fallback={
        <ThreadZero showForward={showForward} onPress={forwardAction} />
      }
      actions={
        <BatchActionHeader threads={threads} view={inboxViews.PREVIEW} />
      }
    >
      {({ setShadow, listRef }) => (
        <ThreadFeedList
          promo={promo}
          cards={cards}
          total={total}
          data={messages}
          listRef={listRef}
          loadMore={loadMore}
          isLoading={isLoading}
          cellRenderer={renderItem}
          scrollToIndex={scrollToIndex}
          isLoaded={({ index }) => isLoaded(index)}
          onScroll={({ scrollTop }) => {
            setShadow(scrollTop > 0)
          }}
          itemKey={(data, index) => get(data, 'id', index)}
          footer={showForward && <ForwardView onPress={forwardAction} />}
        />
      )}
    </ThreadFeedLayout>
  )
}

export default ThreadFeedView
