// @flow
import { createReducer } from 'utils/redux'
import * as actions from './actions'

import type { SiftsGetVendorSuccess } from './types'

export type State = {
  vendors: {
    [vendorId: string]: {
      id: string,
      name: string,
      url: string,
      // Background color
      background: string,
      // Vendor logo URL
      logo: string,
    },
  },
}

const initialState: State = {
  vendors: {},
}

const reducer = createReducer<State, *>(initialState, {
  [actions.fetchVendorActions.success.toString()]: fetchVendorSuccess,
})

function fetchVendorSuccess(state: State, action: SiftsGetVendorSuccess) {
  const {
    vendorId,
    vendorName,
    websiteUrl,
    logoUrl,
    backgroundColor,
  } = action.payload
  return {
    ...state,
    vendors: {
      ...state.vendors,
      [vendorId]: {
        id: vendorId,
        name: vendorName,
        background: `#${backgroundColor}`,
        url: websiteUrl,
        logo: logoUrl,
      },
    },
  }
}

export default reducer
