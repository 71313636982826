// @flow
import md5 from 'md5'
import * as client from '@edison/webmail-core/api'

import { getAuth } from 'core/auth/selectors'
import { showNotification } from 'core/toasts/actions'

import { createAction } from 'utils/redux'
import { toastVariants } from 'common/toasts'
import type {
  AttachmentLinkListRequest,
  AttachmentLinkListSuccess,
  AttachmentLinkListFailure,
  DeleteAttachmentLinkRequest,
  DeleteAttachmentLinkSuccess,
  DeleteAttachmentLinkFailure,
  BatchDeleteAttachmentLinkRequest,
  BatchDeleteAttachmentLinkSuccess,
  BatchDeleteAttachmentLinkFailure,
  ResetPasswordRequest,
  ResetPasswordSuccess,
  ResetPasswordFailure,
} from './types'
import type { ThunkAction, ActionCreator } from 'types/redux'

export const attachmentLinkListActions: {
  request: ActionCreator<AttachmentLinkListRequest>,
  success: ActionCreator<AttachmentLinkListSuccess>,
  failure: ActionCreator<AttachmentLinkListFailure>,
} = {
  request: createAction('ATTACHMENTLINK_LIST_REQUEST'),
  success: createAction('ATTACHMENTLINK_LIST_SUCCESS'),
  failure: createAction('ATTACHMENTLINK_LIST_FAILURE'),
}

/**
 * Get all attachment links
 *
 * @public
 * @returns {ThunkAction}
 */
export function fetchAllAttachmentLinks(keyword: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        attachmentLinkListActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(attachmentLinkListActions.request())
      const res = await client.attachmentLinks.list(keyword, auth)
      dispatch(attachmentLinkListActions.success(res.result || []))
    } catch (e) {
      dispatch(showNotification(e.message, toastVariants.error))
      dispatch(attachmentLinkListActions.failure({ message: e.message }))
    }
  }
}

export const deleteAttachmentLinkActions: {
  request: ActionCreator<DeleteAttachmentLinkRequest>,
  success: ActionCreator<DeleteAttachmentLinkSuccess>,
  failure: ActionCreator<DeleteAttachmentLinkFailure>,
} = {
  request: createAction('DELETE_ATTACHMENTLINK_REQUEST'),
  success: createAction('DELETE_ATTACHMENTLINK_SUCCESS'),
  failure: createAction('DELETE_ATTACHMENTLINK_FAILURE'),
}

/**
 * Delete attachmentLink
 *
 * @public
 * @param {string} messageId - message Id
 * @returns {ThunkAction}
 */
export function deleteAttachmentLink(messageId: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        deleteAttachmentLinkActions.failure({ message: 'User not logged in' })
      )
      return
    }

    try {
      dispatch(deleteAttachmentLinkActions.request({ messageId }))
      await client.attachmentLinks.deleteAttachmentLink(messageId, auth)

      dispatch(deleteAttachmentLinkActions.success({ messageId }))
    } catch (e) {
      dispatch(showNotification(e.message, toastVariants.error))
      dispatch(deleteAttachmentLinkActions.failure({ message: e.message }))
    }
  }
}

export const batchDeleteAttachmentLinksActions: {
  request: ActionCreator<BatchDeleteAttachmentLinkRequest>,
  success: ActionCreator<BatchDeleteAttachmentLinkSuccess>,
  failure: ActionCreator<BatchDeleteAttachmentLinkFailure>,
} = {
  request: createAction('BATCH_DELETE_ATTACHMENTLINKS_REQUEST'),
  success: createAction('BATCH_DELETE_ATTACHMENTLINKS_SUCCESS'),
  failure: createAction('BATCH_DELETE_ATTACHMENTLINKS_FAILURE'),
}

/**
 * Delete attachmentLink
 *
 * @public
 * @param {Array<string>} messageIds - file object
 * @returns {ThunkAction}
 */
export function batchDeleteAttachmentLinks(messageIds: string): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(
        batchDeleteAttachmentLinksActions.failure({
          message: 'User not logged in',
        })
      )
      return
    }

    try {
      dispatch(batchDeleteAttachmentLinksActions.request({ messageIds }))
      await client.attachmentLinks.batchDeleteAttachmentLink(messageIds, auth)

      dispatch(batchDeleteAttachmentLinksActions.success({ messageIds }))
    } catch (e) {
      dispatch(showNotification(e.message, toastVariants.error))
      dispatch(
        batchDeleteAttachmentLinksActions.failure({ message: e.message })
      )
    }
  }
}

export const resetPasswordActions: {
  request: ActionCreator<ResetPasswordRequest>,
  success: ActionCreator<ResetPasswordSuccess>,
  failure: ActionCreator<ResetPasswordFailure>,
} = {
  request: createAction('RESET_PASSWORD_REQUEST'),
  success: createAction('RESET_PASSWORD_SUCCESS'),
  failure: createAction('RESET_PASSWORD_FAILURE'),
}

/**
 * Reset password
 *
 * @public
 * @param {string} attachmentId - Attachment Id
 * @param {Blob} blobData - Blob data to upload
 * @returns {ThunkAction}
 */
export function resetPassword(
  messageId: string,
  password: string
): ThunkAction {
  return async (dispatch, getState) => {
    const auth = getAuth()(getState())

    if (auth === null) {
      dispatch(resetPasswordActions.failure({ message: 'User not logged in' }))
      return
    }

    try {
      dispatch(resetPasswordActions.request())
      await client.attachmentLinks.resetPassword(
        messageId,
        password ? md5(password) : '',
        auth
      )
      dispatch(resetPasswordActions.success({ messageId, password }))
      dispatch(
        showNotification(
          'Successfully changed password.',
          toastVariants.success
        )
      )
    } catch (e) {
      dispatch(showNotification(e.message, toastVariants.error))

      dispatch(resetPasswordActions.failure({ message: e.message }))
    }
  }
}
