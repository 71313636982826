// @flow
import React, { useState, useCallback, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { useModal } from 'common/modals'
import { modalTypes } from 'utils/constants'

import { getMessageState } from 'core/messages/selectors'
import { showLoading, hideLoading } from 'core/toasts/actions'
import * as selectors from 'core/contacts/selectors'
import * as actions from 'core/contacts/actions'
import { getFormattedContactById } from 'core/contacts/helpers'

import { InfiniteLoader } from 'react-virtualized'
import Contacts, { PendingContact } from '@edison/webmail-ui/screens/Contacts'
import ContactPopper from '@edison/webmail-ui/screens/Contacts/ContactPopper'
import EmptyList from '@edison/webmail-ui/screens/Settings/components/EmptyList'

import type { Dispatch } from 'types/redux'

const messageStateSelector = getMessageState()
const pendingThreadsSelector = selectors.getPendingThreads()

const PendingMessagePoppup = ({
  anchorEl,
  id,
}: {
  anchorEl?: any,
  id?: string,
}) => {
  const messages = useSelector(messageStateSelector)
  const message = get(messages, id)

  if (isNil(message)) return null
  const { from, to, subject, snippet } = message

  return (
    <ContactPopper
      text={snippet}
      subject={subject}
      anchorEl={anchorEl}
      name={from.name || from.email}
      recipients={to.map(item => item.name || item.email).join(', ')}
    />
  )
}

type Props = {
  ids: $ReadOnlyArray<string>,
}
export default ({ ids }: Props) => {
  const { t } = useTranslation()
  const contactsRef = useRef(new Set())
  const dispatch: Dispatch = useDispatch()
  const [popupProps, setPopupProps] = useState(null)
  const messages = useSelector(messageStateSelector)
  const pendingThreads = useSelector(pendingThreadsSelector)
  const pendingMessageByEmail = useSelector(selectors.getPendingMessageId)
  const contactsById = useSelector(selectors.getContactsById)
  const pendingContact = useModal(modalTypes.approveSender)

  const pendingMessages = useMemo(() => {
    let res = {}
    for (let email in pendingMessageByEmail) {
      const messageId = pendingMessageByEmail[email]
      if (messageId in messages) {
        res[messageId] = messages[messageId]
      }
    }
    return res
  }, [pendingMessageByEmail, messages])

  const [debouncedBatchGet] = useDebouncedCallback(
    async (startIndex, stopIndex) => {
      const toFetch = ids
        .slice(startIndex, stopIndex + 1)
        .map(id => get(contactsById, `${id}.emails[0].email`, ''))
        .filter(Boolean)
        .filter(
          (email, i) =>
            !contactsRef.current.has(email) ||
            isLoaded({ index: startIndex + i })
        )

      contactsRef.current = new Set([
        ...Array.from(contactsRef.current),
        ...toFetch,
      ])
      dispatch(actions.fetchPendingMessage(toFetch))
    },
    500
  )

  const [debouncedMouseOver, cancelMouseOver] = useDebouncedCallback(params => {
    setPopupProps(params)
  }, 500)

  const handleOnClick = useCallback(
    email => {
      if (email in pendingThreads) {
        pendingContact.showModal({ email })
      } else {
        Promise.all([
          dispatch(showLoading()),
          dispatch(actions.fetchPendingThreads(email)),
        ]).then(() => {
          dispatch(hideLoading())
          pendingContact.showModal({ email })
        })
      }
    },
    [pendingThreads]
  )

  const renderItem = useCallback(
    ({ index, style, key }) => {
      const { email, name, avatar } = getFormattedContactById(
        contactsById,
        ids[index]
      )
      const { id, snippet, ssFlag, rrbFlag } = get(
        messages,
        pendingMessageByEmail[email],
        {}
      )

      return (
        <PendingContact
          key={id}
          name={name}
          style={style}
          email={email}
          avatar={avatar}
          ssFlag={ssFlag}
          rrbFlag={rrbFlag}
          snippet={snippet}
          onClick={() => handleOnClick(email)}
          onAccept={() => dispatch(actions.approveSender(email))}
          onBlock={() => dispatch(actions.showBlockPrompt(email, true))}
          onMouseOver={ref => debouncedMouseOver({ anchorEl: ref, id })}
          onMouseLeave={() => {
            cancelMouseOver()
            setPopupProps(null)
          }}
        />
      )
    },
    [contactsById, ids, pendingMessages]
  )

  const count = ids.length

  if (count === 0) {
    return (
      <EmptyList
        image={t('empty-pending-contacts.png')}
        title={t('contacts.pending.empty.title')}
        subtitle={t('contacts.pending.empty.subtitle')}
      />
    )
  }

  const isLoaded = ({ index }) => {
    const email = get(contactsById, `${ids[index]}.emails[0].email`, '')
    return email in pendingMessageByEmail
  }

  const loadMore = ({ startIndex, stopIndex }) => {
    debouncedBatchGet(startIndex, stopIndex)
  }

  return (
    <>
      <InfiniteLoader
        threshold={2}
        rowCount={count}
        loadMoreRows={loadMore}
        isRowLoaded={isLoaded}
      >
        {({ onRowsRendered, registerChild }) => (
          <Contacts
            count={count}
            ref={registerChild}
            rowRenderer={renderItem}
            onRowsRendered={onRowsRendered}
          />
        )}
      </InfiniteLoader>
      <PendingMessagePoppup {...(popupProps || {})} />
    </>
  )
}
