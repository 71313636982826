// @flow
import values from 'lodash/values'
import orderBy from 'lodash/orderBy'
import unionBy from 'lodash/unionBy'
import { createSelector } from 'reselect'
import { getThreadMessagesState } from 'core/metadata/selectors'
import { getDraftLargeAttachments } from 'core/compose/selectors'
import type { LargeAttachment } from '@edison/webmail-core/types/large-attachments'
import type { AttachmentLink } from '@edison/webmail-core/types/attachment-links'
import type { State as AttachmentLinkState } from './types'
import type { State, Selector } from 'types/state'

export const selectAttachmentLinksState = (state: State) =>
  state.attachmentLinks

export function getAllAttachmentLinks(): Selector<Array<AttachmentLink>> {
  return createSelector(
    selectAttachmentLinksState,
    getThreadMessagesState(),
    (state: AttachmentLinkState, threads) =>
      // some threads may be not loaded
      orderBy(
        values(state).filter(
          ({ threadId, messageId }) =>
            !(threadId in threads) ||
            threads[threadId].messageIds.includes(messageId)
        ),
        ['date'],
        ['desc']
      )
  )
}
//filter the current draft used attachments
export function getRecentUploadAttachments(
  draftId: string
): Selector<Array<LargeAttachment>> {
  return createSelector(
    getAllAttachmentLinks(),
    getDraftLargeAttachments(draftId),
    (attachmentLinks, largeAttachments) => {
      const usedLargeAttachmentIds = largeAttachments.map(item => item.aid)
      return unionBy(
        ...attachmentLinks.map(item => item.attachments),
        'aid'
      ).filter(item => !usedLargeAttachmentIds.includes(item.aid))
    }
  )
}

export const getAllLinksAttachemntsSelector: Selector<
  Array<LargeAttachment>
> = createSelector(getAllAttachmentLinks(), links =>
  links
    .map(item =>
      (item.attachments || []).map(attachment => ({
        ...attachment,
        date: item.date,
        password: item.password,
        extendInfo: {
          from: [item.from],
        },
        id: attachment.aid,
        type: 'large',
      }))
    )
    .reduce((prev, curr) => [...prev, ...curr], [])
)
