//@flow
import type Moment from 'moment'
import type { CalendarResolutionTypes } from '../core/calendar/types-events'
import type { UnixTimestampInSeconds } from '../core/calendar/types'
import type {
  CalendarAttendee,
  CalendarEventResponseStatus,
} from '@edison/webmail-core/types/calendar'
import cloneDeep from 'lodash/cloneDeep'
import { ReduxEvent } from '../core/calendar/modal-redux-event'
import {
  convertMomentToInstanceDateId,
  convertMomentToInstanceDateTimeId,
  convertRFCDate,
  convertRFCDateTime,
} from '@edison/datetime'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { rrulestr } from '@ruoxijiang/rrule'
import { extractCalendarEventId } from '@edison/webmail-core/utils'
import { generatePath } from 'react-router-dom'
import { checkCurrentUser } from './index'
import { stringArrayIncludes } from '@edison/functools'
import { CALENDAR_EVENT_RSVP_STATUS } from './constants'

export const icsContentTypes = {
  'application/ics': true,
  'text/calendar': true,
  'text/ics': true,
  'application/calendar': true,
}
export const icsFileExtension = /\.ICS$/i

export const isAttachmentICS = ({
  contentType,
  fileName,
}: {
  contentType: string | string[],
  fileName: string,
}) => {
  const type =
    Array.isArray(contentType) && contentType.length > 0
      ? contentType[0]
      : contentType
  if (icsContentTypes[type.toLocaleLowerCase()]) {
    return true
  }
  return fileName.search(icsFileExtension) !== -1
}

export const filterDuplicateICSAttachment = attachments => {
  const seenICSAttachments = {}
  return attachments.filter(attachment => {
    if (!isAttachmentICS(attachment)) {
      return true
    }
    const id = `${attachment.fileName}${attachment.size}`
    if (seenICSAttachments[id]) {
      return false
    }
    seenICSAttachments[id] = true
    return true
  })
}

export const calendarEventResponseStatusToRSVPStatus = (
  responseStatus: CalendarEventResponseStatus
): 'yes' | 'no' | 'maybe' | '' => {
  if (typeof responseStatus === 'string') {
    const tmp = responseStatus.toLocaleUpperCase()
    if (tmp === CALENDAR_EVENT_RSVP_STATUS.accepted.rfcValue) {
      return 'yes'
    } else if (tmp === CALENDAR_EVENT_RSVP_STATUS.declined.rfcValue) {
      return 'no'
    } else if (tmp === CALENDAR_EVENT_RSVP_STATUS.tentative.rfcValue) {
      return 'maybe'
    }
  }
  return ''
}
export const convertToCalendarEventResponseStatus = (
  rsvpStatus: 'yes' | 'no' | 'maybe'
): 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'NEEDS-ACTION' => {
  if (typeof rsvpStatus === 'string') {
    const tmp = rsvpStatus.toLocaleLowerCase()
    if (tmp === 'yes' || tmp === 'accepted') {
      return CALENDAR_EVENT_RSVP_STATUS.accepted.rfcValue
    } else if (tmp === 'no' || tmp === 'declined') {
      return CALENDAR_EVENT_RSVP_STATUS.declined.rfcValue
    } else if (tmp === 'maybe' || tmp === 'tentative') {
      return CALENDAR_EVENT_RSVP_STATUS.tentative.rfcValue
    }
  }
  return CALENDAR_EVENT_RSVP_STATUS.needsAction.rfcValue
}
export const updateEventAttendeeRSVPStatus = (
  attendee: CalendarAttendee,
  rsvpStatus:
    | 'yes'
    | 'no'
    | 'maybe'
    | 'ACCEPTED'
    | 'DECLINED'
    | 'TENTATIVE'
    | 'NEEDS-ACTION'
): CalendarAttendee | null => {
  if (attendee) {
    const rfcRSVP = convertToCalendarEventResponseStatus(rsvpStatus)
    attendee.responseStatus = rfcRSVP
    if (!attendee.properties) {
      attendee.properties = {}
    }
    attendee.properties.PARTSTAT = [rfcRSVP]
    return attendee
  }
  return null
}

export const resetAPIAttendeeRSVPState = (
  attendees: CalendarAttendee[],
  organizerEmails: string[]
) => {
  const ret = []
  if (Array.isArray(attendees)) {
    attendees.forEach(attendee => {
      if (attendee) {
        if (
          !stringArrayIncludes({
            strArray: organizerEmails,
            matchStr: attendee.email,
            options: { ignoreCase: true },
          })
        ) {
          const properties = cloneDeep(attendee.properties) || {}
          properties.PARTSTAT = [
            CALENDAR_EVENT_RSVP_STATUS.needsAction.rfcValue,
          ]
          ret.push({
            email: attendee.email,
            responseStatus: CALENDAR_EVENT_RSVP_STATUS.needsAction.rfcValue,
            properties,
          })
        } else {
          ret.push(cloneDeep(attendee))
        }
      }
    })
  }
  return ret
}

export const isOnlySimpleDataDifferent = (
  oldEvent: ReduxEvent,
  newEvent: ReduxEvent
) => {
  if (oldEvent.isAllDayEvent !== newEvent.isAllDayEvent) {
    return false
  }
  if (
    oldEvent.start.timestamp !== newEvent.start.timestamp ||
    oldEvent.end.timestamp !== newEvent.end.timestamp
  ) {
    return false
  }
  if (oldEvent.recurrence.join('\n') !== newEvent.recurrence.join('\n')) {
    return false
  }
  if (
    JSON.stringify(oldEvent.reminders) !== JSON.stringify(newEvent.reminders)
  ) {
    return false
  }
  return true
}
export const needToResetAttendeesRSVPStatus = (
  oldEvent: ReduxEvent,
  newEvent: ReduxEvent
) => {
  if (!oldEvent.organizer.self || !newEvent.organizer.self) {
    return false
  }
  if (oldEvent.isAllDayEvent !== newEvent.isAllDayEvent) {
    return true
  }
  if (
    oldEvent.start.timestamp !== newEvent.start.timestamp ||
    oldEvent.end.timestamp !== newEvent.end.timestamp
  ) {
    return true
  }
  if (oldEvent.recurrence.join('\n') !== newEvent.recurrence.join('\n')) {
    return true
  }
  return false
}
export const isAttendeeCurrentUser = (
  attendee: CalendarAttendee,
  userAliases: string[]
) => {
  if (!attendee) {
    return false
  }
  if (!Array.isArray(userAliases)) {
    return false
  }
  const email = attendee.email
  if (!email) {
    return false
  }
  return checkCurrentUser(userAliases, email)
}
export const encodeEventToICSEventDataBase64 = (event: ReduxEvent) => {
  const idsString = JSON.stringify({
    calendarId: event.calendarId,
    eventId: event.eventId,
    instanceId: event.instanceId,
  })
  let ret = ''
  try {
    ret = btoa(idsString)
  } catch (e) {
    console.error(e)
  }
  return ret
}
export const decodeBase64ICSEventData = (
  base64Str: string
): { calendarId?: string, eventId?: string, instanceId?: string } => {
  let ret = '{}'
  try {
    ret = atob(base64Str)
  } catch (e) {
    console.error(e)
  }
  let data = {}
  try {
    data = JSON.parse(ret)
  } catch (e) {
    console.error(e)
  }
  return data
}
export const generateCalendarUrl = ({
  resolution,
  userId,
  timezone,
  basePath,
  eventOrInstance,
}: {
  resolution: CalendarResolutionTypes,
  userId: string,
  timezone: string,
  basePath: string,
  eventOrInstance: ReduxEvent,
}): string => {
  if (!resolution || !timezone || !basePath || !eventOrInstance) {
    return ''
  }
  const range = { resolution }
  const start = eventOrInstance.start.toMomentDate().tz(timezone)
  range.month = start.month() + 1
  range.year = start.year()
  range.date = start.date()
  const icsEventStr = encodeEventToICSEventDataBase64(eventOrInstance)
  const url = generatePath(basePath, {
    userId,
    ...range,
  })
  if (icsEventStr) {
    return `${url}?icsEvent=${icsEventStr}`
  }
  return url
}
export const getRBTreeTimePairsFromEvent = (event: ReduxEvent) => {
  const timePairs = [{ start: event.start.timestamp, end: event.end.timestamp }]
  if (event.isInstance) {
    let start = event.start.timestamp
    let end = event.end.timestamp
    let skip = true
    if (start !== event.originalStartTime.timestamp) {
      start = event.originalStartTime.timestamp
      skip = false
    }
    if (end !== event.originalEndTime.timestamp) {
      end = event.originalEndTime.timestamp
      skip = false
    }
    if (!skip) {
      timePairs.push({ start, end })
    }
  }
  if (timePairs.length > 1) {
    console.log('original time different')
  }
  return timePairs
}
export const getRecurringEventGenerationMetaData = (event: ReduxEvent) => {
  let dtStart
  let eventDuration = 0
  let allDayEvent = false
  if (event.start && event.start.date) {
    allDayEvent = true
    const startDate = convertRFCDate(event.start.date)
    const endDate = convertRFCDate(event.end.date)
    eventDuration = endDate.unix() - startDate.unix()
    dtStart = `DTSTART:${convertMomentToInstanceDateTimeId(
      moment(Date.UTC(startDate.year(), startDate.month(), startDate.date()))
    )}`
  } else if (event.start && event.start.dateTime) {
    const startDate = convertRFCDateTime(event.start.dateTime)
    const endDate = convertRFCDateTime(event.end.dateTime)
    eventDuration = endDate.unix() - startDate.unix()
    dtStart = `DTSTART;TZID=${event.start.timeZone}:${startDate.format(
      'YYYYMMDD[T]HHmmss'
    )}`
  } else {
    console.error('event.start missing data', event)
    return {}
  }
  // $FlowFixMe
  let rruleString = event.rruleString
  rruleString = `${dtStart}\n${rruleString}`
  return {
    RRULE: rrulestr(rruleString, { forceset: true }),
    eventDuration,
    allDayEvent,
  }
}
const generateInstanceFromRRuleStartAndEvent = ({
  event,
  rruleStart,
  allDayEvent,
  eventDuration,
}: {
  event: ReduxEvent,
  rruleStart: Date,
  allDayEvent: boolean,
  eventDuration: number,
}) => {
  const newStart = allDayEvent ? moment(rruleStart).utc() : moment(rruleStart)
  const newEnd = newStart.clone()
  newEnd.seconds(newEnd.seconds() + eventDuration)
  const newInstanceJSON = event.toJSON()
  newInstanceJSON.recurringEventId = event.id
  const id = extractCalendarEventId(event.id)
  if (allDayEvent) {
    newInstanceJSON.start = {
      date: newStart.format('YYYY-MM-DD'),
      timeZone: event.start.timeZone,
    }
    newInstanceJSON.end = {
      date: newEnd.format('YYYY-MM-DD'),
      timeZone: event.end.timeZone,
    }
    newInstanceJSON.id = `${id}_${convertMomentToInstanceDateId(newStart)}`
  } else {
    let startDateTime = newStart.format()
    try {
      startDateTime = momentTimezone(startDateTime)
        .tz(event.start.timeZone)
        .format()
    } catch (e) {
      console.error(`start timeZone cannot be parsed ${event.start.timeZone}`)
    }
    let endDateTime = newEnd.format()
    try {
      endDateTime = momentTimezone(endDateTime)
        .tz(event.end.timeZone)
        .format()
    } catch (e) {
      console.error(`end timeZone cannot be parsed ${event.end.timeZone}`)
      endDateTime = newEnd.format()
    }
    newInstanceJSON.start = {
      dateTime: startDateTime,
      timeZone: event.start.timeZone,
    }
    newInstanceJSON.end = {
      dateTime: endDateTime,
      timeZone: event.end.timeZone,
    }
    const startUtcOffset = newStart.utcOffset()
    let originalStartTimezone = event.start.timeZone
    if (event.originalStartTime) {
      originalStartTimezone = event.originalStartTime.timeZone
      newStart.utc().hour(event.originalStartTime.toUTCMoment().hour())
      newStart.utc().minute(event.originalStartTime.toUTCMoment().minute())
    }
    newInstanceJSON.id = `${id}_${convertMomentToInstanceDateTimeId(newStart)}`

    newStart.utcOffset(startUtcOffset)
    const endUtcOffset = newEnd.utcOffset()
    let originalEndTimezone = event.end.timeZone
    if (event.originalEndTime) {
      originalEndTimezone = event.originalEndTime.timeZone
      newEnd.utc().hour(event.originalEndTime.toUTCMoment().hour())
      newEnd.utc().minute(event.originalEndTime.toUTCMoment().minute())
    }
    newEnd.utcOffset(endUtcOffset)

    let originalDateTime = newStart.format()
    try {
      originalDateTime = momentTimezone(originalDateTime)
        .tz(originalStartTimezone)
        .format()
    } catch (e) {
      console.error(
        `original start timeZone cannot be parsed ${originalStartTimezone}`
      )
    }
    let originalEndDateTime = newEnd.format()
    try {
      originalEndDateTime = momentTimezone(originalEndDateTime)
        .tz(originalEndTimezone)
        .format()
    } catch (e) {
      console.error(
        `original end timeZone cannot be parsed ${originalEndTimezone}`
      )
    }
    newInstanceJSON.originalStartTime = {
      dateTime: originalDateTime,
      timeZone: originalStartTimezone,
    }
    newInstanceJSON.originalEndTime = {
      dateTime: originalEndDateTime,
      timeZone: originalStartTimezone,
    }
  }
  return newInstanceJSON
}
export const generateNearestInstanceByRecurringEvent = (
  event: ReduxEvent,
  instances: ReduxEvent[],
  start: Moment
): ReduxEvent | null => {
  const {
    allDayEvent,
    RRULE,
    eventDuration,
  } = getRecurringEventGenerationMetaData(event)
  const cutOff = start.unix()
  const nextNoneCancelledInstances = []
  const prevNoneCancelledInstances = []
  instances.forEach((o: ReduxEvent) => {
    if (!o.cancelled) {
      if (o.start.timestamp >= cutOff) {
        nextNoneCancelledInstances.push(o)
      } else {
        prevNoneCancelledInstances.push(o)
      }
    }
  })
  nextNoneCancelledInstances.sort(
    (a, b) => a.start.timestamp - b.start.timestamp
  )
  prevNoneCancelledInstances.sort(
    (a, b) => a.start.timestamp - b.start.timestamp
  )
  const nextNoneCancelledInstance =
    nextNoneCancelledInstances.length > 0 ? nextNoneCancelledInstances[0] : null
  const prevNoneCancelledInstance =
    prevNoneCancelledInstances.lengt > 0
      ? prevNoneCancelledInstances[prevNoneCancelledInstances.length - 1]
      : null

  const findNextInstance = (startMoment: Moment) => {
    const startUTCDate = new Date(
      startMoment
        .clone()
        .startOf('day')
        .unix() * 1000
    )
    const rruleStart = RRULE.after(startUTCDate, true)
    if (!rruleStart) {
      return nextNoneCancelledInstance
    }
    const instanceJSON = generateInstanceFromRRuleStartAndEvent({
      event,
      rruleStart,
      allDayEvent,
      eventDuration,
    })
    const reduxInstance = new ReduxEvent({
      ...instanceJSON,
      calendarId: event.calendarId,
    })
    if (
      nextNoneCancelledInstance &&
      reduxInstance.start.timestamp >= nextNoneCancelledInstance.start.timestamp
    ) {
      return nextNoneCancelledInstance
    }
    const isCancelled = instances.find(
      (o: ReduxEvent) => o.id === reduxInstance.id && o.cancelled
    )
    if (isCancelled || reduxInstance.start.timestamp < cutOff) {
      const newStart = moment.unix(reduxInstance.start.timestamp)
      newStart.add(1, 'd')
      return findNextInstance(newStart)
    } else {
      return reduxInstance
    }
  }
  const findPrevInstance = (startMoment: Moment) => {
    const startUTCDate = new Date(
      startMoment
        .clone()
        .startOf('day')
        .unix() * 1000
    )
    const rruleStart = RRULE.before(startUTCDate, true)
    if (!rruleStart) {
      return prevNoneCancelledInstance
    }
    const instanceJSON = generateInstanceFromRRuleStartAndEvent({
      event,
      rruleStart,
      allDayEvent,
      eventDuration,
    })
    const reduxInstance = new ReduxEvent({
      ...instanceJSON,
      calendarId: event.calendarId,
    })
    if (
      prevNoneCancelledInstance &&
      reduxInstance.start.timestamp <= prevNoneCancelledInstance.start.timestamp
    ) {
      return prevNoneCancelledInstance
    }
    const isCancelled = instances.find(
      (o: ReduxEvent) => o.id === reduxInstance.id && o.cancelled
    )
    if (isCancelled || reduxInstance.start.timestamp > cutOff) {
      const newStart = moment.unix(reduxInstance.start.timestamp)
      newStart.subtract(1, 'd')
      return findPrevInstance(newStart)
    } else {
      return reduxInstance
    }
  }
  let ret = findNextInstance(start)
  if (!ret) {
    ret = findPrevInstance(start)
  }
  if (!ret) {
    const startUTCDate = new Date(
      start
        .clone()
        .startOf('day')
        .unix() * 1000
    )
    let rruleStart = RRULE.after(startUTCDate)
    if (!rruleStart) {
      rruleStart = RRULE.before(startUTCDate)
    }
    if (rruleStart) {
      const instanceJSON = generateInstanceFromRRuleStartAndEvent({
        event,
        rruleStart,
        allDayEvent,
        eventDuration,
      })
      ret = new ReduxEvent({
        ...instanceJSON,
        calendarId: event.calendarId,
      })
    }
  }
  return ret
}
export const generateInstanceByRecurringEvent = (
  event: ReduxEvent,
  instances: ReduxEvent[],
  start: Moment,
  end: Moment
): { [instanceId: string]: ReduxEvent } => {
  const ret: { [instanceId: string]: ReduxEvent } = {}
  instances.forEach(instance => {
    if (!instance.isInstance) {
      console.error(`instance does not have an event id`, instance)
      return
    }
    ret[instance.id] = instance
  })
  if (
    !event ||
    !Array.isArray(event.recurrence) ||
    event.recurrence.length === 0
  ) {
    return ret
  }
  const {
    allDayEvent,
    RRULE,
    eventDuration,
  } = getRecurringEventGenerationMetaData(event)
  const utcStart = start
    .clone()
    .seconds(start.seconds() - eventDuration)
    .utc()
  const utcEnd = end.clone().utc()
  RRULE.between(
    new Date(
      Date.UTC(
        utcStart.year(),
        utcStart.month(),
        utcStart.date(),
        utcStart.hour(),
        utcStart.minute(),
        utcStart.seconds()
      )
    ),
    new Date(
      Date.UTC(
        utcEnd.year(),
        utcEnd.month(),
        utcEnd.date(),
        utcEnd.hour(),
        utcEnd.minute(),
        utcEnd.seconds()
      )
    ),
    true
  ).forEach((rruleStart: Date) => {
    const newInstanceJSON = generateInstanceFromRRuleStartAndEvent({
      event,
      rruleStart,
      allDayEvent,
      eventDuration,
    })
    if (!Object.prototype.hasOwnProperty.call(ret, newInstanceJSON.id)) {
      ret[newInstanceJSON.id] = new ReduxEvent(newInstanceJSON)
    }
  })
  // $FlowFixMe
  return ret
}

export const getCurrentUnixTimestampInSeconds = (): UnixTimestampInSeconds => {
  return Math.floor(Date.now() / 1000)
}
