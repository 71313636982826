import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import CleanupCard from '@edison/webmail-ui/components/Cards/CleanupCard'
import {
  cleanupTypes,
  cleanupStatus,
} from '@edison/webmail-core/utils/constants'
import { updateCleanup } from 'core/cleanup/actions'
import { modalTypes } from '../../utils/constants'
import { useModal } from 'common/modals'

const cleanupType2Type = {
  [cleanupTypes.CALENDAR]: 'calendar',
  [cleanupTypes.ZOOM]: 'calendar',
  [cleanupTypes.WEBEX]: 'calendar',
}
export default ({ id: cleanupType, ...rest }) => {
  const dispatch = useDispatch()
  const contactsGalleryModal = useModal(modalTypes.contactsGallery)
  const handleReivew = () => {
    contactsGalleryModal.showModal({
      cleanup: {
        type: cleanupType,
      },
      cardView: 'cleanup',
    })
  }

  const handleIgnore = useCallback(() => {
    dispatch(updateCleanup(cleanupType, cleanupStatus.IGNORED))
  }, [cleanupType])

  return (
    <CleanupCard
      {...rest}
      onClick={handleReivew}
      onIgnore={handleIgnore}
      onReview={handleReivew}
      type={cleanupType2Type[cleanupType]}
    />
  )
}
