// @flow
import { useSelector, useDispatch } from 'react-redux'
import {
  getAppInitializationStatus,
  getRefreshTokenInitializationStatus,
} from './selectors'
import * as actions from './actions'
import { useOrderId } from 'core/auth/hooks'

import type { Dispatch } from 'types/redux'

// Returns status of app initialization status
export const useAppInitialization = () => {
  const dispatch: Dispatch = useDispatch()
  const isInitialized = useSelector(getAppInitializationStatus)
  const initialize = () => dispatch(actions.initialization())

  return { isInitialized, initialize }
}

// Returns status of refresh token initialization status
export const useRefreshInitialization = () => {
  const dispatch: Dispatch = useDispatch()
  const isInitialized = useSelector(getRefreshTokenInitializationStatus)
  const orderId = useOrderId({ defaultOrderId: null })
  const initialize = ({ domainProtected }: { domainProtected: boolean }) => {
    dispatch(actions.refreshInitialization({ orderId, domainProtected }))
  }

  return { isInitialized, initialize }
}
