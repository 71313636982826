// @flow
import type { GalleryView } from '@edison/webmail-ui/components/ContactsGallery/GalleryModal'
import CardModalContent, {
  Bottom,
  ModelMode,
} from '@edison/webmail-ui/components/Cards/CardModal'
import { getCleanupSelector } from 'core/cleanup/selectors'
import { feedbackSuggestedBlock, showBlockPrompt } from 'core/contacts/actions'
import { useCard } from 'core/contacts/hooks'
import { getPendingContacts } from 'core/contacts/selectors'
import { getEmailNudges } from 'core/email-nudges/selectors'
import { getLoadingStatus } from 'core/loading/selectors'
import { useKeyboardNavigation } from 'hooks/useKeyboardNavigation'
import React, { useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MessageList } from 'screens/ContactDetailModal/MessageListSection'
import { cardTypes, suggestedBlockStatus } from 'utils/constants'
import SuggestedCardDetail from '../SugeestedBlockBody'

type Props = {
  onClose: () => any,
  id: string,
  email: string,
  total: number,
  onNextNavigate: (view: GalleryView, data: any) => void,
}

const pendingSelector = getPendingContacts()

export default function SuggestedBlockModal({
  id,
  email,
  onClose,
  total,
  onNextNavigate,
}: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [threadId, setThreadId] = useState('')
  const loading = useSelector(
    useMemo(() => getLoadingStatus('CONTACT_THREADS'), [])
  )
  const ref = useRef()
  const pending = useSelector(pendingSelector)
  const nudges = useSelector(getEmailNudges)
  const cleanups = useSelector(getCleanupSelector)
  const [contact, setContact] = useState({ id, email })
  const {
    onNextCard,
    onPreviousCard,
    currIdx,
    hasNext,
    hasPrevious,
  } = useCard(cardTypes.suggestedBlock, contact.id, onNextNavigate, contact =>
    setContact(contact)
  )

  const onLeftArrow = () => {
    if (ref.current && ref.current.mode === ModelMode.DETAIL) {
      ref.current.setModeList()
    } else {
      hasPrevious && onPreviousCard()
    }
  }

  const onRightArrow = () => {
    if (ref.current && ref.current.mode === ModelMode.LIST) {
      hasNext && onNextCard()
    }
  }

  useKeyboardNavigation(
    {
      onPrevious: () => onLeftArrow(),
      onNext: () => onRightArrow(),
    },
    [hasPrevious, hasNext, onPreviousCard, onNextCard]
  )

  const indicatorCurrIdx =
    currIdx + pending.length + nudges.length + cleanups.length

  const onBlock = () => {
    dispatch(showBlockPrompt(email, true, autoCloseWithoutNextCard))
  }

  const autoCloseWithoutNextCard = () => {
    if (hasNext) {
      onNextCard()
    } else {
      onClose()
    }
  }

  const onIgnore = () => {
    dispatch(
      feedbackSuggestedBlock(id, suggestedBlockStatus.dismissed)
    ).then(() => autoCloseWithoutNextCard())
  }

  const onClick = threadId => {
    setThreadId(threadId)
  }

  return (
    <CardModalContent
      ref={ref}
      onClose={onClose}
      style={{ height: 'calc(100% - 180px)' }}
      total={total}
      currIdx={indicatorCurrIdx}
      onNext={onNextCard}
      onPrevious={onPreviousCard}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      title={t('suggested.block.modal.title')}
      bottom={
        <Bottom disabled={loading} onBlock={onBlock} onIgnore={onIgnore} />
      }
      cardDetail={({ setModeList }) => (
        <SuggestedCardDetail onPrevious={setModeList} threadId={threadId} />
      )}
    >
      {({ setModeDetail }) => (
        <MessageList
          isShowLables
          classname="bg-app dark:bg-card"
          onClick={threadId => {
            setModeDetail()
            onClick(threadId)
          }}
          contactId={contact.id}
        />
      )}
    </CardModalContent>
  )
}
