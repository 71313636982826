// @flow
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isNil from 'lodash/isNil'

import { fetchContactThreads } from 'core/contacts/actions'
import { getContactThreadsById } from 'core/contacts/selectors'
import { getThread } from 'core/threads/selectors'

import EmailsLayout, {
  Email as EmailItem,
} from '@edison/webmail-ui/components/ContactDetail/EmailsView'

import { dateFormatter } from 'utils'
import Subject from 'common/ThreadList/Subject'

type Props = {
  id: string,
  idType?: 'ID' | 'EMAIL',
  onSelectEmail: (id: string) => void,
  selectedEmail: string,
}

const ThreadItem = ({ id, subject, date, snippet, onClick, isActive }) => {
  const thread = useSelector(useMemo(() => getThread(id), [id]))

  return (
    <EmailItem
      subject={<Subject subject={thread ? thread.subject : subject} />}
      date={dateFormatter(thread ? thread.date : date)}
      snippet={thread ? thread.snippet : snippet}
      onClick={onClick}
      isActive={isActive}
    />
  )
}

export default ({ id, idType = 'ID', selectedEmail, onSelectEmail }: Props) => {
  const dispatch = useDispatch()
  const threads = useSelector(useMemo(() => getContactThreadsById(id), [id]))

  useEffect(() => {
    if (!isNil(id)) {
      dispatch(fetchContactThreads(id, idType))
    }
  }, [id])

  return (
    <EmailsLayout>
      {threads.map(({ id, subject, date, snippet }, index) => (
        <ThreadItem
          key={id}
          id={id}
          subject={subject}
          date={date}
          snippet={snippet}
          onClick={() => onSelectEmail(id)}
          isActive={selectedEmail === id}
        />
      ))}
    </EmailsLayout>
  )
}
