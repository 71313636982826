// @flow
import React, { useMemo } from 'react'
import get from 'lodash/get'
import { useHistory, generatePath, Redirect } from 'react-router-dom'

import { useQueryParams } from 'common/url'
import { useOrderId } from 'core/auth/hooks'

import { routePaths, providerMappings } from 'utils/constants'
import { connectionTypes } from '@edison/webmail-core/utils/constants'

import {
  GmailASPInstructions,
  ICloudASPInstructions,
  YahooASPInstructions,
  AolASPInstructions,
} from '@edison/webmail-ui/components/Retrofit/IMAPInstructions'

const instructions = {
  [connectionTypes.GMAIL_IMAP]: GmailASPInstructions,
  [connectionTypes.ICLOUD_IMAP]: ICloudASPInstructions,
  [connectionTypes.YAHOO_IMAP]: YahooASPInstructions,
  [connectionTypes.AOL_IMAP]: AolASPInstructions,
}

const View = () => {
  const userId = useOrderId()
  const history = useHistory()
  const { provider } = useQueryParams()

  const connectionType = get(providerMappings, provider)
  const ProviderInstructions = !!connectionType
    ? instructions[connectionType]
    : null

  const [fallbackUrl, nextUrl] = useMemo(() => {
    return [
      generatePath(routePaths.retrofitAdd, { userId }),
      generatePath(routePaths.retrofitIMAPProvider, {
        userId,
        provider,
      }),
    ]
  }, [userId, provider])

  if (!ProviderInstructions) {
    return <Redirect to={fallbackUrl} />
  }

  return <ProviderInstructions onContinue={() => history.push(nextUrl)} />
}

View.displayName = 'IMAPInstructions'

export default View
