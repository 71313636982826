// @flow
import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Previous from '@material-ui/icons/NavigateBefore'
import Next from '@material-ui/icons/NavigateNext'

export default ({
  current,
  total,
  onChange,
}: {
  current: number,
  total: number,
  onChange: number => void,
}) => {
  const enableToPrev = current > 0
  const enableToNext = current < total - 1

  // Hide the navigation if there's one thread
  if (total === 1) return null
  return (
    <div className="inline-flex items-center flex-shrink-0 ml-4">
      <span className="text-gray-medium text-sm mr-2">
        {current + 1}/{total}
      </span>

      <IconButton
        size="small"
        disableRipple
        onClick={() => onChange(parseInt(0 + `${current - 1}`))}
        disabled={!enableToPrev}
      >
        <Previous fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        disableRipple
        onClick={() => onChange(current + 1)}
        disabled={!enableToNext}
      >
        <Next fontSize="small" />
      </IconButton>
    </div>
  )
}
